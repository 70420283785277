import React, { useState } from 'react';

import { Breakpoints, Specializations } from '../enums';
import { Coordinates } from '../../types/coordinates';
import { Modal } from '../modal';
import { ReactComponent as Close } from '../../assets/icons/close.svg';
import { classnames } from '../../helpers/utils';
import styles from './map-view.module.scss';
import { useMediaQuery } from '../../hooks/use-media-query';
import { ActualMap } from './actual-map';
import { ReactComponent as Maximize } from '../../assets/icons/maximize-2.svg';

type MapViewProps = {
  mapCenter: Coordinates
  mapContainerClass?: string
  containerClass?: string
  draggableMarker?: boolean
  setCenter?: (coords: Coordinates) => void
  t?: (text: string) => string
  zoom?: number
  pinIconCategory?: Specializations
};

const translPrefix = 'mapView';

const MapView = ({
  mapCenter, mapContainerClass = '', containerClass = '', draggableMarker = false,
  setCenter, t, zoom, pinIconCategory,
}: MapViewProps) => {
  const [amplify, setAmplify] = useState(false);
  const [readyToRenderMap, setReadyToRenderMap] = useState(false);

  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const onClickMapView = () => {
    setAmplify((prevState) => !prevState);
    // Need this because the modal is not yet rendered when the map
    // would load styles, so the map is not displayed correctly
    setTimeout(() => setReadyToRenderMap(true));
  };

  const onCloseMapModal = () => {
    setAmplify((prevState) => !prevState);
    setReadyToRenderMap(false);
  };

  if (amplify) {
    return (
      <Modal>
        <div className={styles.modalContainer}>
          <button
            type="button"
            onClick={() => onCloseMapModal()}
            className={styles.closeButton}
          >
            <Close className={styles.cross} />
          </button>
          {readyToRenderMap && (
            <ActualMap
              mapCenter={mapCenter}
              draggableMarker={draggableMarker}
              desktopControls={!mobile}
              dragging
              touchZoom
              setCenter={setCenter}
              zoom={zoom}
              pinIconCategory={pinIconCategory}
            />
          )}
        </div>
      </Modal>
    );
  }

  return (
    <button
      type="button"
      onClick={onClickMapView}
      className={classnames(styles.smallMapButton, containerClass)}
    >
      <ActualMap
        mapCenter={mapCenter}
        containerClass={mapContainerClass}
        zoom={zoom}
        pinIconCategory={pinIconCategory}

      />
      {draggableMarker && t && (
      <div className={classnames(styles.draggingSign, 'text__body__small__textNeutral10')}>
        {t(`${translPrefix}.draggingSign`)}
        <Maximize className={styles.maximizeIcon} />
      </div>
      )}
    </button>
  );
};
export { MapView };
