import { CancelledByType } from '@mapix/common/src/common/enums';

/* A simplified version of the Property model that's easier to access */
class
MaintenanceTableItem {
  propertyType: string;

  propertyName: string;

  address: string;

  buildingName: string;

  affectedArea: string;

  taskCategory: string;

  taskName: string;

  taskType: string;

  taskId: number;

  activeRequestAssignedId: number;

  createdOn: string;

  startDate: string;

  priority: string;

  assigned: string;

  solvedDate: string;

  cancelledDate: string;

  cancelledBy: CancelledByType;

  applications: string;

  status: string;

  rate: string;

  billedAmount: number | null;

  tenantName: string;

  constructor(params: SerializedTask) {
    this.propertyType = params.property.type;
    this.propertyName = params.property.type;
    this.address = `${params.property.address.civicNumber} ${params.property.address.streetName} \
    ${params.property.address.unitNumber ? params.property.address.unitNumber : ''}, \
    ${params.property.address.city}, ${params.property.address.country} `;
    this.buildingName = params.property.buildingName;
    this.affectedArea = params.unit ? `Unit ${params.unit.unitNumber}` : 'Common Area';
    this.taskCategory = params.category;
    this.taskName = params.name;
    this.taskType = params.taskType ? params.taskType : '-';
    this.taskId = params.id;
    this.activeRequestAssignedId = params.activeRequestAssignedId;
    this.startDate = params.startDate;
    this.createdOn = params.createdAt;
    this.priority = params.priority;
    this.applications = params.applications;
    this.assigned = params.contractorAssignedName ? `${params.contractorAssignedName} ${params.contractorAssignedLastName}` : '-';
    this.solvedDate = params.solvedDate;
    this.cancelledDate = params.cancelledDate;
    this.cancelledBy = params.cancelledBy;
    this.status = params.status;
    this.rate = params.calculatedCost ? `$${params.calculatedCost}` : '-';
    this.billedAmount = params.bill ? params.bill.billedAmount : null;
    this.tenantName = params.tenantName ? `${params.tenantName} ${params.tenantLastName}` : '-';
  }
}

export { MaintenanceTableItem };
