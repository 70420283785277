import React, {
  FormEvent, useState, useEffect, ChangeEvent,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  classnames, HTMLValidationError, checkHTMLErrors, isEmptyObject, addError,
} from '@mapix/common/src/helpers/utils';
import { useHistory, useParams } from 'react-router-dom';
import { Button, ButtonStyle, ButtonType } from '@mapix/common/src/common/button';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import { Select } from '@mapix/common/src/common/select';
import { logger } from 'helpers/logger';
import { Spinner } from '@mapix/common/src/common/spinner';
import { ContractorCategories } from 'common/enums';
import { ReactComponent as CheckCircle } from 'assets/icons/check-circle.svg';
import { ModalResult } from 'common/modal-result';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { MaintenanceController } from 'networking/controllers/maintenance-controller';
import { ContractorController } from 'networking/controllers/contractor-controller';
import { ButtonClose } from '@mapix/common/src/common/button-close';
import { PropertyController } from 'networking/controllers/property-controller';
import { Property } from 'models/property';
import { PhoneInput } from '@mapix/common/src/common/phone-input';
import { UserController } from 'networking/controllers/user-controller';
import styles from './create-contractor.module.scss';

const translPrefix = 'createContractor';

const initialStateContractor: SerializedContractor = {
  id: undefined,
  category: '',
  propertyId: undefined,
  name: '',
  phoneNumber: '',
  countryCode: '',
  email: '',
};

type ParamType = {
  propertyId: string,
  contractorId: string,
};

const CreateEditContractor = () => {
  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});
  const [phoneErrors, setPhoneErrors] = useState({});
  const [fetching, setFetching] = useState(false);
  const history = useHistory();
  const [showError, setShowError] = useState(false);
  const [property, setProperty] = useState<Property | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [contractor, setContractor] = useState<SerializedContractor>(initialStateContractor);
  const { t } = useTranslation();
  const { propertyId, contractorId } = useParams<ParamType>();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, id } = e.target;
    const newContractor = {
      ...contractor,
      [id]: value,
    };
    setContractor(newContractor);
  };

  const onChangePhoneNumber = (phoneNumber: string, countryCode: string) => {
    setContractor((prevState) => (
      { ...prevState, phoneNumber, countryCode }));
  };

  const onClickSelect = (option: string) => {
    const newData = {
      ...contractor,
    };
    newData.category = option;
    setContractor(newData);
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    if (!target.checkValidity() || !contractor.phoneNumber) {
      setFormErrors(checkHTMLErrors(target));
      if (!contractor.phoneNumber) {
        addError(t('error.emptyField'), 'phoneNumber', setPhoneErrors);
      }
      return;
    }

    if (!isEmptyObject(phoneErrors)) {
      return;
    }

    try {
      setFetching(true);
      if (contractorId) {
        await ContractorController
          .editContractor(contractor, Number(propertyId), Number(contractorId));
      } else {
        await MaintenanceController.addContractor(Number(propertyId), contractor);
      }
      setFetching(false);
      setShowModal(true);
    } catch (err: any) {
      setFetching(false);
      setShowError(true);
      logger.error(err);
    }
  };

  const getContractor = async () => {
    try {
      setFetching(true);
      const result = await ContractorController
        .getContractor(Number(propertyId), Number(contractorId));
      setContractor(result);
      setFetching(false);
    } catch (err: any) {
      setFetching(false);
      setShowError(true);
      logger.error(err);
    }
  };

  const getAddress = async () => {
    try {
      setFetching(true);
      const result = await PropertyController.getDetailedProperty(Number(propertyId));
      setProperty(result);
      setFetching(false);
    } catch (err: any) {
      setFetching(false);
      setShowError(true);
      logger.error(err);
    }
  };

  useEffect(() => {
    getAddress();
    if (contractorId) {
      getContractor();
    }
  }, []);

  if (fetching) {
    return (
      <Spinner />
    );
  }

  if (showModal) {
    return (
      <ModalResult
        title={t(`${translPrefix}.successModal.title`)}
        subtitle={t(`${translPrefix}.successModal.subtitle`)}
        Icon={CheckCircle}
        withCheckIcon
        buttonTextRight={t(`${translPrefix}.successModal.ok`)}
        handleButtonRight={() => { history.goBack(); }}
        handleButtonClose={() => { history.goBack(); }}
      />
    );
  }

  return (
    <div className={styles.container}>
      <form onSubmit={onSubmit} noValidate>

        {showError
      && <ErrorMessage message={t('error.errorMessage')} errorStyle={styles.errorMessage} handleClose={() => setShowError(false)} />}

        <div className={styles.header}>
          <div className="text__heading4__textNeutral50">
            {`${contractorId ? t(`${translPrefix}.editContractor`) : t(`${translPrefix}.addContractor`)}`}
          </div>
          <ButtonClose closeFn={() => { history.goBack(); }} closeText={t(`${translPrefix}.buttons.close`)} />
        </div>

        <div className={classnames(styles.addressSubtitle, 'text__heading5__textNeutral50')}>
          {`${property?.type === 'Building' ? t(`${translPrefix}.building`) : t(`${translPrefix}.condoHouse`)} ${property?.fullAddress}`}
        </div>

        <div className={classnames(styles.subtitle, 'text__heading6__textNeutral50')}>{t(`${translPrefix}.generalInfo`)}</div>

        <div className={styles.row}>
          <Select
            options={Object.values(ContractorCategories)}
            onClickOption={(option) => onClickSelect(option)}
            optionTextClass={classnames(styles.options, 'text__body__small__textNeutral50')}
            required
            id="category"
            label={t(`${translPrefix}.formData.category`)}
            value={contractor.category ? `${t(`category.${contractor.category}`)}` : ''}
            placeholder={t(`${translPrefix}.formData.category`)}
            inputStyle={InputStyle.FORM}
            containerClass={classnames(styles.input, styles.marginRight)}
            formError={formErrors}
            translationPrefix="category"
            t={t}
          />
          <Input
            id="name"
            containerClass={styles.input}
            type="text"
            min={1}
            inputStyle={InputStyle.FORM}
            placeholder={t(`${translPrefix}.formData.name`)}
            helperText={t(`${translPrefix}.formData.helperName`)}
            required
            value={contractor.name}
            formError={formErrors}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
            t={t}
          />
        </div>

        <div className={classnames(styles.secondRow, styles.row)}>
          <PhoneInput
            id="phoneNumber"
            containerClass={classnames(styles.input, styles.marginRight)}
            label={t(`${translPrefix}.formData.phone`)}
            helperText={t(`${translPrefix}.formData.helperPhone`)}
            required
            countryCode={contractor ? contractor.countryCode : ''}
            phoneWithoutCode={contractor ? contractor.phoneNumber : ''}
            formError={{ ...formErrors, ...phoneErrors }}
            onChangePhoneNumber={onChangePhoneNumber}
            setPhoneErrors={setPhoneErrors}
            validatePhone={UserController.phoneValidation}
            t={t}
          />
          <Input
            id="email"
            containerClass={styles.input}
            type="email"
            inputStyle={InputStyle.FORM}
            placeholder={t(`${translPrefix}.formData.email`)}
            helperText={t(`${translPrefix}.formData.helperEmail`)}
            required
            value={contractor ? contractor.email : undefined}
            formError={formErrors}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
            t={t}
          />
        </div>

        <div className={styles.footer}>
          <Button
            className={classnames(styles.button, styles.second)}
            buttonType={ButtonType.Submit}
            buttonStyle={ButtonStyle.Primary}
          >
            <div className="text__button__large__surface10">{t(`${translPrefix}.buttons.save`)}</div>
          </Button>
        </div>

      </form>
    </div>
  );
};

export { CreateEditContractor };
