import { PropertyOwner } from 'models/property-owner';
import { classnames } from '@mapix/common/src/helpers/utils';
import { useTranslation } from 'react-i18next';
import styles from './read-only-forms.module.scss';

type ContactInfoProp = {
  owner: PropertyOwner,
  otherOwner?: PropertyOwner,
  isUnit: boolean,
};

const translPrefix = 'contactDetail';
const ContactInfo = ({ owner, otherOwner, isUnit }: ContactInfoProp) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.itemBlock}>
          <div className="text__body__medium__textNeutral40">
            {isUnit ? t(`${translPrefix}.tenant`) : t(`${translPrefix}.landlord`)}
            :
          </div>
          <div className="text__body__medium__textNeutral50">
            {`${owner.name} ${owner.lastname || ''}`}
          </div>
        </div>

        <div className={styles.itemBlock}>
          <div className="text__body__medium__textNeutral40">
            {t(`${translPrefix}.phone`)}
          </div>
          <div className={classnames(styles.strike, 'text__body__medium__textNeutral50')}>
            {`${owner.countryCode}${owner.phoneNumber}`}
          </div>
        </div>

        <div className={styles.itemBlock}>
          <div className="text__body__medium__textNeutral40">
            {t(`${translPrefix}.email`)}
          </div>
          <div className={classnames(styles.strike, 'text__body__medium__textNeutral50')}>
            {owner.email}
          </div>
        </div>

      </div>

      {otherOwner && (otherOwner.email || otherOwner.name || otherOwner.phoneNumber)
      && (
        <div className={classnames(styles.container, styles.margin)}>

          <div className={styles.itemBlock}>
            <div className="text__body__medium__textNeutral40">
              {t(`${translPrefix}.tenant`)}
            </div>
            {otherOwner.name
              ? (
                <div className="text__body__medium__textNeutral50">
                  {otherOwner.name}
                </div>
              )
              : (
                <div className="text__body__medium__textNeutral30">
                  {t(`${translPrefix}.empty`)}
                </div>
              )}

          </div>

          <div className={styles.itemBlock}>
            <div className="text__body__medium__textNeutral40">
              {t(`${translPrefix}.phone`)}
            </div>

            {otherOwner.phoneNumber
              ? (
                <div className={classnames(styles.strike, 'text__body__medium__textNeutral50')}>
                  {`${otherOwner.countryCode}${otherOwner.phoneNumber}`}
                </div>
              )
              : (
                <div className="text__body__medium__textNeutral30">
                  {t(`${translPrefix}.empty`)}
                </div>
              )}
          </div>

          <div className={styles.itemBlock}>
            <div className="text__body__medium__textNeutral40">
              {t(`${translPrefix}.email`)}
            </div>
            {otherOwner.email
              ? (
                <div className={classnames(styles.strike, 'text__body__medium__textNeutral50')}>
                  {otherOwner.email}
                </div>
              )
              : (
                <div className="text__body__medium__textNeutral30">
                  {t(`${translPrefix}.empty`)}
                </div>
              )}
          </div>

        </div>
      )}

    </>
  );
};

export { ContactInfo };
