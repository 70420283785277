import {
  ChangeEvent, Dispatch, FormEvent, useState,
} from 'react';
import { checkNumberInput } from 'helpers/utils';
import {
  addError,
  checkHTMLErrors, classnames, HTMLValidationError, isEmptyObject,
} from '@mapix/common/src/helpers/utils';
import { useTranslation } from 'react-i18next';
import { Select } from '@mapix/common/src/common/select';
import { PropertyStatus } from 'common/enums';
import { Lease } from 'models/lease';
import { Services } from 'models/services';
import { CreateFooter } from 'common/creation';
import { ButtonType } from '@mapix/common/src/common/button';
import { LeaseForm } from '../create-building/lease-form';
import { Action } from './create-property-condo-reducer';
import styles from './create-property-condo.module.scss';

const translPrefix = 'createCondo';
const footerTranslPrefix = 'createCondo.footer';

type CreatePropertyCondoStep2Props = {
  dispatch: Dispatch<Action>,
  fetchedStatus: string,
  fetchedLease: Lease,
};

const CreatePropertyCondoStep2 = ({ dispatch, fetchedStatus, fetchedLease }:
CreatePropertyCondoStep2Props) => {
  const [lease, setLease] = useState<Lease>(fetchedLease);
  const [status, setStatus] = useState<string>(fetchedStatus);
  const [otherText, setOtherText] = useState<string>(fetchedLease.services.other);
  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});
  const [phoneErrors, setPhoneErrors] = useState<HTMLValidationError>({});

  const { t } = useTranslation();

  const onChangeTenant = (e: ChangeEvent<HTMLInputElement>) => {
    const newTenant = { ...lease.tenant, [e.target.name]: e.target.value };
    setLease({ ...lease, tenant: newTenant });
  };

  const onChangePhoneNumber = (phoneNumber: string, countryCode: string) => {
    setLease((prevState) => ({
      ...prevState,
      tenant: { ...prevState.tenant, phoneNumber, countryCode },
    }));
  };

  const onClickService = (key: keyof Services) => {
    const modifiedService = { ...lease.services };
    (modifiedService as any)[key] = !modifiedService[key];

    setLease({ ...lease, services: modifiedService });
  };

  const updateFiles = (field: string, files: FileType[]) => {
    if (field === 'pdf' && files.length > 0) {
      setLease((prevState) => ({ ...prevState, leaseCopy: files[0] }));
    } else {
      setLease((prevState) => ({ ...prevState, [field]: files }));
    }
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    if (!target.checkValidity() || (!lease.tenant.phoneNumber
      && status === PropertyStatus.Rented)) {
      setFormErrors(checkHTMLErrors(target));
      if (!lease.tenant.phoneNumber && status === PropertyStatus.Rented) {
        addError(t('error.emptyField'), 'phoneNumber', setPhoneErrors);
      }
      return;
    }

    if (!isEmptyObject(phoneErrors)) {
      return;
    }

    if (lease.yearlyRental) {
      lease.monthlyRental = Math.floor(lease.yearlyRental / 12);
    }
    dispatch({ type: 'FINISH_STEP_2', status, lease });
    setFormErrors({});
  };

  const onChangeYearlyOrMonthly = (e: ChangeEvent<HTMLInputElement>) => {
    if (checkNumberInput(e)) {
      const { target } = e;
      const newLease = { ...lease };
      if (Number(target.value) === 0) {
        newLease.yearlyRental = 0;
        newLease.monthlyRental = 0;
        setLease(newLease);
      } else {
        setLease((prevState) => ({ ...prevState, [target.name]: Number(target.value) }));
      }
    }
  };

  const onChangeDateFn = (date: string, option: string) => {
    setLease(
      {
        ...lease,
        [option]: date,
      },
    );
  };

  return (
    <form onSubmit={onSubmit} noValidate>
      <div className={classnames(styles.formTitle, 'text__heading6__textNeutral50')}>
        {t(`${translPrefix}.legal`)}
      </div>

      <Select
        required
        formError={formErrors}
        id="status"
        value={status ? `${t(`${translPrefix}.status.${status}`)}` : ''}
        label={t(`${translPrefix}.statusLabel`)}
        placeholder={t(`${translPrefix}.statusLabel`)}
        options={Object.values(PropertyStatus)}
        onClickOption={(option) => setStatus(option)}
        optionTextClass={classnames(styles.options, 'text__body__small__textNeutral50')}
        containerClass={classnames(styles.select, styles.input)}
        translationPrefix={`${translPrefix}.status`}
        t={t}
      />

      {status === PropertyStatus.Rented && (
        <>
          <LeaseForm
            onChangeDateFn={onChangeDateFn}
            isCondo
            lease={lease}
            onChangeLeaseFn={(e) => setLease(
              (prevState) => ({ ...prevState, [e.target.name]: e.target.value }),
            )}
            onChangeTenantFn={onChangeTenant}
            onChangePhoneNumber={onChangePhoneNumber}
            onClickServiceFn={(service: string) => onClickService(service as keyof Services)}
            onChangeFilesFn={(field: string, files: FileType[]) => updateFiles(field, files)}
            onClickSelectFn={(field, value) => setLease({ ...lease, [field]: value })}
            onChangeOtherText={(e) => setOtherText(e.target.value)}
            formErrors={{ ...formErrors, ...phoneErrors }}
            otherText={otherText}
            onChangeYearlyMonthly={onChangeYearlyOrMonthly}
            propertyAvailable
            setPhoneErrors={setPhoneErrors}
          />
        </>
      )}

      <CreateFooter
        nextName={t(`${footerTranslPrefix}.contractors`)}
        showBack
        backFn={() => dispatch({ type: 'GO_BACK' })}
        nextButtonType={ButtonType.Submit}
      />

    </form>
  );
};

export { CreatePropertyCondoStep2 };
