import { Button, ButtonStyle, ButtonType } from '@mapix/common/src/common/button';
import { Checkbox } from '@mapix/common/src/common/checkbox';
import { Breakpoints, Users } from 'common/enums';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import {
  checkHTMLErrors, classnames, HTMLValidationError, isPasswordValid,
} from '@mapix/common/src/helpers/utils';
import { useMediaQuery } from '@mapix/common/src/hooks/use-media-query';
import { ChangeEvent, FocusEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppLink, goToPage, RouteName } from 'routes';
import { GoogleLogin } from '@mapix/common/src/common/google-login';
import { constants } from 'config/constants';
import { UserController } from 'networking/controllers/user-controller';
import { logger } from '@mapix/common/src/helpers/logger';
import styles from './register-form.module.scss';

type PropTypes = {
  registerFn: (user: User) => void,
  isManager?: boolean,
};

type FormType = {
  [key: string]: string,
  email: string,
  password: string,
  repeatPassword: string,
};

const RegisterForm = ({ registerFn, isManager = false }: PropTypes) => {
  const { t } = useTranslation();
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});
  const [formData, setFormData] = useState<FormType>({
    email: '',
    password: '',
    repeatPassword: '',
  });
  const [errorMessage, setErrorMessage] = useState<FormType>({
    email: '',
    password: '',
    repeatPassword: '',
  });

  const redirect = isManager ? constants.googleManagerRedirect : constants.googleTenantRedirect;

  const handleRegister = async (event: any) => {
    event.preventDefault();
    const errorData = {
      email: '',
      password: '',
      repeatPassword: '',
      acceptTerms: '',
    };
    const target = event.target as HTMLFormElement;
    setFormErrors(checkHTMLErrors(target));
    if (target.checkValidity() && !errorMessage.email) {
      if (!isPasswordValid(formData.password)) {
        errorData.password = (t('password.helper'));
      }
      if (formData.password !== formData.repeatPassword) {
        errorData.repeatPassword = (t('password.mustMatch'));
      }
      if (!errorData.password && !errorData.repeatPassword) {
        const user: User = {
          email: formData.email,
          password: formData.password,
        };
        registerFn(user);
      }
      setErrorMessage(errorData);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, id } = event.target;
    const newData = { ...formData };
    const errorData = { ...errorMessage };
    newData[id] = value;
    errorData[id] = '';
    setErrorMessage(errorData);
    setFormData(newData);
  };

  const handleBlur = async (event: FocusEvent<HTMLInputElement, Element>) => {
    const email = event.target.value;
    if (email) {
      try {
        await UserController.availableEmailValidation(email);
        setErrorMessage((prevState) => ({ ...prevState, email: '' }));
      } catch (e) {
        logger.log(e);
        setErrorMessage((prevState) => ({ ...prevState, email: t('error.takenEmail') }));
      }
    }
  };

  return (
    <form onSubmit={handleRegister} noValidate>
      <p className={mobile ? 'text__heading2__textNeutral50' : 'text__heading5__textNeutral50'}>
        {t('registerForm.register')}
      </p>

      <div className={styles.googleContainer}>
        <GoogleLogin
          domain={redirect!}
          googleClientId={constants.googleClientId!}
          signUp
          role={isManager ? Users.Manager : Users.Tenant}
        />
      </div>

      <p className="text__body__medium__textNeutral50">
        {t('registerForm.emailOption')}
      </p>
      <Input
        id="email"
        containerClass={styles.inputEmail}
        label={t('login.email')}
        placeholder={t('login.email')}
        inputStyle={InputStyle.FORM}
        value={formData.email}
        onChange={handleChange}
        formError={formErrors}
        onBlur={handleBlur}
        error={!!errorMessage.email}
        helperText={errorMessage.email}
        type="email"
        required
        t={t}
      />
      <Input
        id="password"
        containerClass={styles.input}
        label={t('password.password')}
        placeholder={t('password.password')}
        inputStyle={InputStyle.FORM}
        type="password"
        value={formData.password}
        onChange={handleChange}
        error={!!errorMessage.password}
        formError={formErrors}
        helperText={errorMessage.password || t('password.helper')}
        required
        t={t}
      />
      <Input
        id="repeatPassword"
        containerClass={styles.input}
        label={t('password.repeatPassword')}
        placeholder={t('password.repeatPassword')}
        inputStyle={InputStyle.FORM}
        type="password"
        value={formData.repeatPassword}
        onChange={handleChange}
        error={!!errorMessage.repeatPassword}
        helperText={errorMessage.repeatPassword}
        formError={formErrors}
        required
        t={t}
      />
      <Checkbox
        id="acceptTerms"
        onChangeFn={() => setAcceptTerms(!acceptTerms)}
        labelClass="text__body__small__textNeutral50"
        labelContent={(
          <>
            {t('registerForm.read')}
            {' '}
            <AppLink
              routeName={RouteName.TermsOfUse}
              className={styles.blue}
            >
              {t('registerForm.terms')}
            </AppLink>
          </>
        )}
        checked={acceptTerms}
        containerClass={styles.checkboxContainer}
        formError={formErrors}
        required
      />
      <Button
        className={classnames(styles.button, 'text__body__small__textNeutral50')}
        buttonStyle={ButtonStyle.PrimaryGradient}
        buttonType={ButtonType.Submit}
      >
        {t('registerForm.register')}

      </Button>
      <div className={styles.row}>
        <p className={classnames(styles.textNeedAccount, 'text__body__medium__textNeutral50')}>
          {t('registerForm.haveAnAccount')}
        </p>
        <Button
          className={classnames(styles.buttonLink, 'text__body__medium__primary')}
          buttonStyle={ButtonStyle.Link}
          onClick={() => goToPage(RouteName.Login)}
        >
          {t('login.loginButton')}
        </Button>
      </div>
    </form>
  );
};

export { RegisterForm };
