import {
  ChangeEvent, FormEvent, useEffect, useState,
} from 'react';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { Spinner } from '../../spinner';
import { Breakpoints, MakePaymentModalState } from '../../enums';
import { CardNonSensitiveData } from '../../../types';
import { Input, InputStyle } from '../../input';
import { ReactComponent as RightIcon } from '../../../assets/icons/help-circle.svg';
import { IconWithTooltip } from '../../icon-with-tooltip';
import { Select } from '../../select';
import {
  addError,
  checkHTMLErrors,
  classnames,
  HTMLValidationError,
  removeError,
} from '../../../helpers/utils';
import styles from './add-card.module.scss';
import inputStyles from '../../input/input.module.scss';
import { BottomButtonsMakePayment } from '../bottom-buttons-make-payment/bottom-buttons-make-payment';

export type NuveiFields = {
  scard: any,
  cardExpiry: any,
  cardCvc: any,
};

type NuveiEvent = {
  field: string,
  empty: boolean,
  complete: boolean,
  allFieldsCompleted: boolean,
  error: {
    id: string,
    message: string,
  },
  cardBrand: string,
};

type NuveiFieldsCompletion = {
  cardNumber: boolean,
  cardExpiry: boolean,
  cardCvc: boolean,
  allFields: boolean
};

const initNuveiFieldsCompletion: NuveiFieldsCompletion = {
  cardNumber: false,
  cardExpiry: false,
  cardCvc: false,
  allFields: false,
};

const countries = ['CA', 'US'];

type AddCardProps = {
  cancel: () => void,
  t: (text: string, object?: {}) => string,
  createPayment: () => void,
  nuveiFields: NuveiFields,
  cardNonSensitiveData: CardNonSensitiveData,
  setCardNonSensitiveData: (value: any) => void,
  contentToShow: MakePaymentModalState
};

const translPrefix = 'addCard';

const AddCard = ({
  cancel, t, createPayment, nuveiFields, cardNonSensitiveData, setCardNonSensitiveData,
  contentToShow,
}: AddCardProps) => {
  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});
  const [fieldCompletion, setFieldCompletion] = useState<NuveiFieldsCompletion>(
    initNuveiFieldsCompletion,
  );

  const addNuveiFieldRequiredErrorMessage = (nuveiField: string, fieldName: string) => {
    if (nuveiField) {
      addError(t('error.genericRequirednessError', { fieldName }), nuveiField, setFormErrors);
    }
  };

  const saveCard = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const target = event.target as HTMLFormElement;

    if (!target.checkValidity() || !fieldCompletion.allFields) {
      setFormErrors(checkHTMLErrors(target));
      addNuveiFieldRequiredErrorMessage(nuveiFields.cardCvc, 'CVV number');
      addNuveiFieldRequiredErrorMessage(nuveiFields.cardExpiry, 'expiration date');
      addNuveiFieldRequiredErrorMessage(nuveiFields.scard, 'card number');
      return;
    }

    createPayment();
  };

  const configureNuveiField = (nuveiField: any, fieldId: string) => {
    nuveiField.on('change', (event: NuveiEvent) => {
      if (event.error && !event.empty) {
        addError('Please enter a valid field', fieldId, setFormErrors);
      } else {
        removeError(fieldId, setFormErrors);
      }

      if (event.complete) {
        setFieldCompletion((prevState) => ({
          ...prevState,
          [fieldId]: true,
          allFields: event.allFieldsCompleted,
        }));
      } else {
        setFieldCompletion((prevState) => ({
          ...prevState,
          [fieldId]: false,
          allFields: event.allFieldsCompleted,
        }));
      }
    });

    nuveiField.attach(document.getElementById(fieldId));
  };

  useEffect(() => {
    configureNuveiField(nuveiFields.scard, 'cardNumber');
    configureNuveiField(nuveiFields.cardCvc, 'cardCvc');
    configureNuveiField(nuveiFields.cardExpiry, 'cardExpiry');
  }, []);

  const getNuveiFieldStyle = (style?: string, small?: boolean) => classnames(
    inputStyles.form,
    small ? styles.nuveiFieldWrapperSmall : styles.nuveiFieldWrapper,
    'text__body__large__textNeutral40',
    style || '',
  );

  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const disablePay = !cardNonSensitiveData.countryAlpha2Code || !cardNonSensitiveData.holderName
  || !fieldCompletion.allFields;

  return (
    <>
      <form onSubmit={saveCard}>
        {mobile && (
        <div className={classnames('text__heading2__textNeutral40')}>
          {t(`${translPrefix}.title`)}
        </div>
        )}
        <div>
          <Input
            id="name"
            containerClass={styles.input}
            label={t(`${translPrefix}.name`)}
            placeholder={t(`${translPrefix}.name`)}
            inputStyle={InputStyle.FORM}
            type="text"
            value={cardNonSensitiveData.holderName}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setCardNonSensitiveData(
              (prevState: CardNonSensitiveData) => (
                { ...prevState, holderName: event.target.value }),
            )}
            formError={formErrors}
            required
            t={t}
          />
          <>
            <div className={styles.row}>
              <div className={getNuveiFieldStyle()}>

                <div id="cardNumber" className={styles.nuveiField} />
                {formErrors.cardNumber && (
                <div
                  id="cardNumber-error"
                  className={classnames(styles.errors, 'text__body__tiny__danger50')}
                >
                  {t('error.genericRequirednessError', { fieldName: 'card number' })}
                </div>
                )}
              </div>
            </div>
            <div className={styles.row}>

              <div className={getNuveiFieldStyle('', true)}>

                <div id="cardExpiry" className={styles.nuveiField} />
                {formErrors.cardExpiry && (
                <div
                  id="cardExpiry-error"
                  className={classnames(styles.errors, 'text__body__tiny__danger50')}
                >
                  {t('error.genericRequirednessError', { fieldName: 'expiration date' })}
                </div>
                )}
              </div>
              <div className={getNuveiFieldStyle(styles.cvvContainer, true)}>
                <div id="cardCvc" className={styles.nuveiField} />
                <IconWithTooltip
                  Icon={RightIcon}
                  text={t(`${translPrefix}.cvvHelp`)}
                />
                {formErrors.cardCvc && (
                <div
                  id="cardCvc-error"
                  className={classnames(styles.errors, 'text__body__tiny__danger50')}
                >
                  {t('error.genericRequirednessError', { fieldName: 'CVV' })}

                </div>
                )}

              </div>
            </div>
          </>

          <div className={styles.row} />
          <Select
            // TODO API CALL TO GET COUNTRY OPTIONS
            options={countries}
            onClickOption={(option: string) => setCardNonSensitiveData(
              (prevState: CardNonSensitiveData) => ({
                ...prevState,
                countryAlpha2Code: option,
              }),
            )}
            optionTextClass={classnames(styles.options, 'text__body__small__textNeutral50')}
            required
            id="country"
            label={t(`${translPrefix}.country`)}
            placeholder={t(`${translPrefix}.country`)}
            value={cardNonSensitiveData.countryAlpha2Code ? t(`${translPrefix}.countryAlpha2Code.${cardNonSensitiveData.countryAlpha2Code}`) : ''}
            inputStyle={InputStyle.FORM}
            containerClass={classnames(styles.input, styles.select)}
            formError={formErrors}
            t={t}
            translationPrefix={`${translPrefix}.countryAlpha2Code`}
          />
        </div>

        <BottomButtonsMakePayment
          onCancel={cancel}
          t={t}
          disabledButtonRight={disablePay}
          textButtonRight={t(`${translPrefix}.pay`)}
          mobile={mobile}
          translPrefix={translPrefix}
        />

      </form>
      { contentToShow === MakePaymentModalState.ShowSpinnerSafecharge && <Spinner />}
    </>
  );
};

export { AddCard };
