import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import { ReactComponent as Filter } from '@mapix/common/src/assets/icons/filter.svg';
import { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from '@mapix/common/src/common/select';
import { MIN_SEARCH_LENGTH } from 'config/constants';
import styles from './search-filters-and-sort.module.scss';

type SearchFiltersAndSortProps<T> = {
  onChangeSearchInput: (event: FormEvent<HTMLInputElement>) => void,
  searchInputValue: string,
  searchInputPlaceholder: string,
  onClickFilterButton: () => void,
  filterButtonText: string,
  onClickOptionSelect: (option: T) => void,
  selectOptions: T[],
  selectedOption: T
  selectTranslPrefix: string,
};

const SearchFiltersAndSort = <Type extends string | number>({
  onChangeSearchInput, searchInputValue, searchInputPlaceholder,
  onClickFilterButton, filterButtonText,
  onClickOptionSelect, selectOptions, selectedOption, selectTranslPrefix,
}: SearchFiltersAndSortProps<Type>) => {
  const { t } = useTranslation();

  return (
    <div className={styles.filtersSearchAndSortWrapper}>
      <div className={styles.filtersAndSearchWrapper}>
        <Input
          id="search"
          containerClass={styles.searchInput}
          withSearchIcon
          placeholder={searchInputPlaceholder}
          onChange={onChangeSearchInput}
          value={searchInputValue}
          textStyle="text__body__xsmall__textNeutral30"
          t={t}
          helperText={searchInputValue ? t('searchCharacterLimit', { limit: MIN_SEARCH_LENGTH }) : ''}
        />

        <Button
          id="filter"
          onClick={onClickFilterButton}
          buttonStyle={ButtonStyle.Secondary}
        >
          <div className="row justify-between ">
            <div className="text__button__medium__primary60">
              {filterButtonText}
            </div>
            <Filter className={styles.filterIcon} />
          </div>
        </Button>
      </div>

      <Select
        id="sort"
        onClickOption={onClickOptionSelect}
        options={selectOptions}
        t={t}
        inputStyle={InputStyle.DROPDOWN_BUTTON}
        containerClass={styles.sortSelect}
        value={t(`${selectTranslPrefix}.${selectedOption}`)}
        textStyle="text__body__medium__primary60"
        translationPrefix={selectTranslPrefix}
      />
    </div>
  );
};

export { SearchFiltersAndSort };
