import {
  EntityType,
  RawRequestStatus, TaskStatus,
} from '@mapix/common/src/common/enums';
import { ErrorType } from '@mapix/common/src/common/error-page';
import { ModalRejection } from '@mapix/common/src/common/modal-rejection';
import { Spinner } from '@mapix/common/src/common/spinner';
import { ReactComponent as Tool } from 'assets/icons/tool.svg';
import { ModalResult } from 'common/modal-result';
import { NewTaskDetail, TaskDetailTabs } from 'common/new-task-detail';
import { TaskViewTab } from 'common/new-task-detail/tabs/task-view';
import { logger } from 'helpers/logger';
import { parseTaskStatus } from 'helpers/utils';
import { MaintenanceController } from 'networking/controllers/maintenance-controller';
import { PublicRequest } from 'networking/types';
import { useEffect, useState } from 'react';
import { StatusTab } from 'common/new-task-detail/tabs/status';
import { useTranslation } from 'react-i18next';
import { TaskMessageRequest } from '@mapix/common/src/types/request';
import { useParams } from 'react-router-dom';
import { RateTab } from '@mapix/common/src/common/new-task-detail-tabs/rate-tab';
import { publicRequestInitialState } from 'initial-states/public-request-initial-state';
import { RouteName, goToPage } from 'routes';
import { AddUpdateModal } from '../../common/add-update-modal';
import styles from './public-request-detail.module.scss';

type ParamType = {
  id: string,
};

const translPrefix = 'newTaskDetail';

enum ModalToShowState {
  None = 'none',
  ChangeContractor = 'changeContractor',
  MoveToInProgressSuccessfull = 'moveToInProgressSuccessfull',
  AddUpdate = 'addUpdate',
}

const PublicRequestDetail = () => {
  const [fetching, setFetching] = useState(true);
  const [publicRequest, setPublicRequest] = useState<PublicRequest>(publicRequestInitialState);
  const [currentTab, setCurrentTab] = useState<TaskDetailTabs>(TaskDetailTabs.Status);

  const [modalToShowState, setModalToShowState] = useState<ModalToShowState>(ModalToShowState.None);

  const { id: publicRequestId } = useParams<ParamType>();

  const { t } = useTranslation();

  const getPublicRequest = async () => {
    try {
      const publicRequestResponse = await MaintenanceController.getPublicRequest(publicRequestId);
      setPublicRequest(publicRequestResponse);
      setFetching(false);
    } catch (err: any) {
      logger.log(err);
      goToPage(RouteName.ErrorPage, { code: ErrorType.ServerError });
    }
  };

  const taskStatus = parseTaskStatus(publicRequest.newTask.status);

  const handleAcceptRateByManager = async () => {
    setFetching(true);

    try {
      const taskUpdated = await MaintenanceController.acceptRatePublicRequest(
        Number(publicRequestId),
      );

      setPublicRequest(taskUpdated);
      setFetching(false);
    } catch (err: any) {
      logger.log(err);
      goToPage(RouteName.ErrorPage, { code: ErrorType.ServerError });
    }
  };

  const getTabContent = () => {
    switch (currentTab) {
      case TaskDetailTabs.Status:
        return (
          <StatusTab
            className={styles.tabsContainer}
            request={publicRequest}
            taskStatus={taskStatus}
            changeContractorFn={() => setModalToShowState(ModalToShowState.ChangeContractor)}
            notifyAddNewUpdate={() => setModalToShowState(ModalToShowState.AddUpdate)}
          />
        );
      case TaskDetailTabs.Rate:
        return (
          <RateTab
            className={styles.tabsContainer}
            entityType={EntityType.Request}
            contractor={publicRequest.contractor}
            manager={publicRequest.manager}
            contractorMessage={publicRequest.contractorMessage}
            managerMessage={publicRequest.managerMessage}
            events={publicRequest.publicRequestEvents}
            requestOrTaskId={publicRequest.id}
            rawStatus={publicRequest.status}
            typeOfCost={{ ...publicRequest }}
            modifyRate={MaintenanceController.modifyRatePublicRequest}
            notifyAcceptRate={handleAcceptRateByManager}
            refreshData={getPublicRequest}
            t={t}
          />
        );
      case TaskDetailTabs.TaskView:
        return (
          <TaskViewTab
            className={styles.tabsContainer}
            task={publicRequest.newTask}
            translPrefix={`${translPrefix}.tabs.taskView`}
            t={t}
          />
        );
      case TaskDetailTabs.Applications:
        return null;
      default:
        return null;
    }
  };

  const showAlert = (option: string) => option === TaskDetailTabs.Rate
  && (publicRequest.status === RawRequestStatus.WAITING_FOR_MANAGER_APPROVAL
    || publicRequest.status === RawRequestStatus.ASSIGNED);

  useEffect(() => {
    getPublicRequest();
  }, []);

  const handleRejectContractor = async (reason: string) => {
    const { id: taskId } = publicRequest.newTask;

    try {
      await MaintenanceController.rejectPublicTask(
        Number(publicRequestId),
        { reasonForRejection: reason },
      );

      goToPage(RouteName.UnpublishedAndUnassignedTaskDetail, { id: taskId });
    } catch (err: any) {
      logger.log(err);
      goToPage(RouteName.ErrorPage, {}, { code: ErrorType.ServerError });
    }
  };

  const disableRightButton = [
    RawRequestStatus.WAITING_FOR_CONTRACTOR_APPROVAL,
    RawRequestStatus.WAITING_FOR_MANAGER_APPROVAL,
    RawRequestStatus.ASSIGNED,
  ].includes(publicRequest.status);

  const withTooltip = taskStatus === TaskStatus.NEGOTIATING;

  if (fetching) {
    return <Spinner />;
  }

  const getTextButtonLeft = () => {
    let translEntry = '';
    switch (taskStatus) {
      case TaskStatus.NEGOTIATING:
        translEntry = 'rejectTask';
        break;
      case TaskStatus.UNASSIGNED:
        translEntry = 'changeAssignedContractor';
        break;
      default:
        break;
    }
    return translEntry ? t(`${translPrefix}.footer.${translEntry}`) : '';
  };

  const getTextButtonRight = () => {
    let translEntry = '';
    switch (taskStatus) {
      case TaskStatus.NEGOTIATING:
        translEntry = 'markAsInProgress';
        break;
      default:
        break;
    }
    return translEntry ? t(`${translPrefix}.footer.${translEntry}`) : '';
  };

  const toolTipText = () => {
    let translEntry = '';
    switch (publicRequest.status) {
      case RawRequestStatus.WAITING_FOR_CONTRACTOR_APPROVAL:
        translEntry = 'waitingForContractorApproval';
        break;
      case RawRequestStatus.WAITING_FOR_MANAGER_APPROVAL:
      case RawRequestStatus.ASSIGNED:
        translEntry = 'waitingForManagerApproval';
        break;
      default:
        break;
    }
    return translEntry ? t(`${translPrefix}.footer.${translEntry}`) : '';
  };

  const getLeftButtonFn = () => {
    switch (taskStatus) {
      case TaskStatus.NEGOTIATING:
        return handleRejectContractor('');
      case TaskStatus.UNASSIGNED:
        return () => setModalToShowState(ModalToShowState.ChangeContractor);
      default:
        return () => {};
    }
  };

  const modalRejectionFn = async (reason: string) => {
    handleRejectContractor(reason);
  };

  const handleMarAskInProgress = async () => {
    const { id: taskId } = publicRequest.newTask;

    try {
      await MaintenanceController.moveTaskToInProgress(+taskId);
      setModalToShowState(ModalToShowState.MoveToInProgressSuccessfull);
    } catch (err: any) {
      logger.log(err);
      goToPage(RouteName.ErrorPage, {}, { code: ErrorType.ServerError });
    }
  };

  const redirectToTaskDetail = () => {
    const { id: taskId } = publicRequest.newTask;
    setModalToShowState(ModalToShowState.None);

    goToPage(RouteName.TaskDetail, { id: taskId });
  };

  const handleConfirmMessage = () => {
    setModalToShowState(ModalToShowState.None);
    getPublicRequest();
  };

  const handleSendMessage = (requestId: number, messageRequest: TaskMessageRequest) => (
    MaintenanceController.sendPublicRequestMessage(requestId, messageRequest)
  );

  const isRateAccepted = () => (
    publicRequest.status === RawRequestStatus.ACCEPTED
  );

  const showCorrespondingModal = () => {
    if (modalToShowState === ModalToShowState.MoveToInProgressSuccessfull) {
      return (
        <ModalResult
          title={t(`${translPrefix}.moveToInProgressByManagerSuccessfullModal.title`)}
          subtitle={t(`${translPrefix}.moveToInProgressByManagerSuccessfullModal.subtitle`)}
          Icon={Tool}
          withCheckIcon
          buttonTextRight={t(`${translPrefix}.moveToInProgressByManagerSuccessfullModal.modalButton`)}
          handleButtonRight={redirectToTaskDetail}
          handleButtonClose={redirectToTaskDetail}
        />
      );
    }

    if (modalToShowState === ModalToShowState.AddUpdate) {
      return (
        <AddUpdateModal
          requestId={Number(publicRequestId)}
          translPrefix={`${translPrefix}.addNewUpdateModal`}
          closeFn={() => setModalToShowState(ModalToShowState.None)}
          notifySendMessage={(requestId: number, messageRequest: TaskMessageRequest) => (
            handleSendMessage(requestId, messageRequest)
          )}
          notifyRefetchData={() => handleConfirmMessage()}
        />
      );
    }

    if (modalToShowState === ModalToShowState.ChangeContractor) {
      return (
        <ModalRejection
          close={() => setModalToShowState(ModalToShowState.None)}
          handleButtonLeft={() => setModalToShowState(ModalToShowState.None)}
          onSubmit={modalRejectionFn}
          translPrefix={`${translPrefix}.changeContractorModal`}
        />
      );
    }

    return null;
  };

  return (
    <>
      {showCorrespondingModal()}

      <NewTaskDetail
        currentTab={currentTab}
        setCurrentTab={setCurrentTab as (tab: string) => void}
        newTask={publicRequest.newTask}
        getTabContent={getTabContent}
        showAlert={showAlert}
        disableLeftButton={
          isRateAccepted()
          || publicRequest.status === RawRequestStatus.WAITING_FOR_CONTRACTOR_APPROVAL
        }
        disableRightButton={disableRightButton}
        withTooltip={withTooltip}
        textButtonLeft={getTextButtonLeft()}
        textButtonRight={getTextButtonRight()}
        translPrefix={translPrefix}
        toolTipText={toolTipText()}
        leftButtonFn={getLeftButtonFn}
        rightButtonFn={handleMarAskInProgress}
      />
    </>
  );
};

export { PublicRequestDetail };
