import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalResult } from 'common/modal-result';
import { RouteName, goToPage } from 'routes';
import { AppContext } from 'context';
import { logger } from 'helpers/logger';
import { ReactComponent as User } from 'assets/icons/user.svg';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { Spinner } from '@mapix/common/src/common/spinner';
import { classnames } from '@mapix/common/src/helpers/utils';
import { Users } from 'common/enums';
import { UserController } from 'networking/controllers/user-controller';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import styles from './verify-account.module.scss';

const VerifyAccount = () => {
  const { t } = useTranslation();
  const translPrefix = 'verifyAccount';
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get('email');
  const { state } = useContext(AppContext);
  const emailVerificationToken = urlParams.get('email_verification_token');
  const [fetching, setFetching] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorServer, setErrorServer] = useState(false);

  const handleLogin = () => {
    if (state.logged) {
      if (state.user.loginRole === Users.Tenant) {
        goToPage(RouteName.HomeTenant);
      } else {
        goToPage(RouteName.Properties);
      }
    } else {
      goToPage(RouteName.Login);
    }
  };

  const verifyAccount = async () => {
    try {
      if (email && emailVerificationToken) {
        setFetching(true);
        const data: VerifyAccount = {
          email,
          emailVerificationToken,
        };
        await UserController.verifyAccount(data);
        setFetching(false);
        setSuccessModal(true);
      } else {
        setErrorServer(true);
      }
    } catch (e) {
      setErrorServer(true);
      setFetching(false);
      logger.error(e as Error);
    }
  };

  if (fetching) {
    return (
      <div className={styles.container}>
        <Spinner fixed={false} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {errorServer && (
      <ErrorMessage
        message={t('error.errorMessage')}
        handleClose={() => { setErrorServer(false); }}
      />
      )}
      {successModal && (
        <ModalResult
          handleButtonRight={handleLogin}
          title={t(`${translPrefix}.success`)}
          buttonTextRight={t(`${translPrefix}.ok`)}
          Icon={User}
          withCheckIcon
          handleButtonClose={handleLogin}
        />
      )}
      <div className={styles.content}>
        <p className="text__body__medium__textNeutral50">
          {t(`${translPrefix}.text`)}
        </p>
        <Button
          buttonStyle={ButtonStyle.Link}
          className={classnames(styles.buttonLink, 'text__body__medium__primary')}
          onClick={verifyAccount}
        >
          {t(`${translPrefix}.link`)}
        </Button>
      </div>
    </div>
  );
};

export { VerifyAccount };
