import { LeaseSerializer } from 'networking/serializers/lease-serializer';
import { PropertyAddressSerializer } from 'networking/serializers/property-address-serializer';

class UnitSerializer {
  static deSerialize(data: RawUnit): SerializedUnit {
    return {
      lease: data.lease ? LeaseSerializer.deSerialize(data.lease) : null,
      status: data.status,
      unitNumber: data.unit_number,
      id: data.id,
      address: data.address ? PropertyAddressSerializer.deSerialize(data.address) : null,
      inProgressTasksCount: data.in_progress_tasks_count,
      newTasksCount: data.new_tasks_count,
      overdueRentalsCount: data.overdue_rentals_count,
      condition: data.condition,
    };
  }

  static serialize(data: SerializedUnit): RawUnit {
    return {
      lease: data.lease ? LeaseSerializer.serialize(data.lease) : null,
      unit_number: data.unitNumber,
      status: data.status,
      id: data.id,
      address: data.address ? PropertyAddressSerializer.serialize(data.address) : null,
    };
  }

  static deSerializeTaskUnit(data: RawTaskUnit): SerializedTaskUnit {
    return {
      id: data.id,
      status: data.status,
      unitNumber: data.unit_number,
      unitPhotos: data.unit_photos,
    };
  }

  static serializeEdit(data: SerializedUnit): RawEditUnit {
    return {
      lease: data.lease ? LeaseSerializer.serializeEditUnit(data.lease) : null,
      unit_number: data.unitNumber,
      status: data.status,
      id: data.id,
    };
  }

  static deSerializeClaimUnit(data: RawClaimUnit): SerializedClaimUnit {
    return {
      id: data.id,
      status: data.status,
      unitNumber: data.unit_number,
    };
  }
}

export { UnitSerializer };
