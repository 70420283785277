import {
  useState, ChangeEvent, SyntheticEvent,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import { Button, ButtonStyle, ButtonType } from '@mapix/common/src/common/button';
import {
  AppLink,
  RouteName,
} from 'routes';
import { Spinner } from '@mapix/common/src/common/spinner';
import { classnames, isPasswordValid } from '@mapix/common/src/helpers/utils';
import { UserController } from 'networking/controllers/user-controller';
import styles from './reset-password.module.scss';

type FormType = {
  [key: string]: string,
  password: string,
  repeatPassword: string,
};
const ResetPassword = () => {
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get('email');
  const passwordResetToken = urlParams.get('password_reset_token');
  const [formData, setFormData] = useState<FormType>({
    password: '',
    repeatPassword: '',
  });
  const [errorMessage, setErrorMessage] = useState<FormType>({
    password: '',
    repeatPassword: '',
  });
  const [fetching, setFetching] = useState(false);
  const [formNumber, setFormNumber] = useState(1);

  const resetPassword = async (event: SyntheticEvent) => {
    event.preventDefault();
    const errorData = {
      password: '',
      repeatPassword: '',
    };
    if (formData.password === '') {
      errorData.password = (t('login.emptyField'));
    } else if (!isPasswordValid(formData.password)) {
      errorData.password = (t('password.helper'));
    }
    if (formData.repeatPassword === '') {
      errorData.repeatPassword = (t('login.emptyField'));
    } else if (formData.password !== formData.repeatPassword) {
      errorData.repeatPassword = (t('password.mustMatch'));
    }
    if (!errorData.password && !errorData.repeatPassword) {
      try {
        setFetching(true);
        const data: ResetPasswordType = {
          email: email || '',
          newPassword: formData.password,
          passwordToken: passwordResetToken || '',
        };
        UserController.resetPassword(data);
        setFetching(false);
        setFormNumber(2);
      } catch (e) {
      // TODO. SHOW ERROR.
      }
    }
    setErrorMessage(errorData);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, id } = event.target;
    const newData = { ...formData };
    const errorData = { ...errorMessage };
    newData[id] = value;
    errorData[id] = '';
    setErrorMessage(errorData);
    setFormData(newData);
  };

  if (fetching) {
    return (
      <div className={styles.spinner}>
        <Spinner fixed={false} />
      </div>
    );
  }
  return (
    <div className={styles.container}>
      { formNumber === 1
        ? (
          <form onSubmit={resetPassword}>
            <div className={styles.content}>
              <p className="text__heading5__textNeutral50">{t('passwordRecovery.title')}</p>
              <p className={classnames('text__body__medium__textNeutral50', styles.subtitle)}>
                {t('passwordRecovery.enterNewPassword')}
              </p>
              <Input
                id="password"
                label={t('passwordRecovery.newPassword')}
                placeholder={t('passwordRecovery.newPassword')}
                inputStyle={InputStyle.FORM}
                type="password"
                value={formData.password}
                onChange={handleChange}
                error={!!errorMessage.password}
                helperText={errorMessage.password || t('password.helper')}
                t={t}
                required
              />
              <Input
                id="repeatPassword"
                containerClass={styles.input}
                label={t('passwordRecovery.repeatNewPassword')}
                placeholder={t('passwordRecovery.repeatNewPassword')}
                inputStyle={InputStyle.FORM}
                type="password"
                value={formData.repeatPassword}
                onChange={handleChange}
                error={!!errorMessage.repeatPassword}
                helperText={errorMessage.repeatPassword}
                t={t}
                required
              />
              <Button
                className={styles.button}
                buttonStyle={ButtonStyle.PrimaryGradient}
                buttonType={ButtonType.Submit}
              >
                {t('passwordRecovery.resetPassword')}
              </Button>
              <AppLink
                className={classnames(styles.buttonLink, 'text__body__medium__primary')}
                routeName={RouteName.Login}
              >
                {t('passwordRecovery.cancel')}
              </AppLink>
            </div>
          </form>
        )
        : (
          <div className={styles.content}>
            <p className="text__body__medium__textNeutral50">
              {t('passwordRecovery.success')}
            </p>
            <AppLink
              className={classnames(styles.buttonLink, 'text__body__medium__primary')}
              routeName={RouteName.Login}
            >
              {t('passwordRecovery.backToLogin')}
            </AppLink>
          </div>
        )}
    </div>
  );
};

export { ResetPassword };
