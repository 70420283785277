import { CertificationSerializer } from '@mapix/common/src/networking/serializers/certification-serializer';
import { CitySerializer } from '@mapix/common/src/networking/serializers/city-serializer';
import { SpecializationSerializer } from '@mapix/common/src/networking/serializers/specialization-serializer';
import { TypeOfCostSerializer } from '@mapix/common/src/networking/serializers/type-of-cost-serializer';
import {
  PublicRequest, PublicRequestIndex, RawPublicRequest, RawPublicRequestIndex,
} from 'networking/types/public-request';
import { RawRequestEvent } from '@mapix/common/src/types';
import { NewTaskSerializer } from './new-task-serializer';
import { MessageSerializer } from './message-serializer';
import { RequestSerializer } from './request-serializer';

class PublicRequestSerializer {
  static deSerializePublicRequest(data: RawPublicRequest): PublicRequest {
    return {
      id: data.id,
      status: data.status,
      createdAt: data.created_at,
      reasonForRejection: data.reason_for_rejection || undefined,
      estimatedEndDate: data.estimated_end_date,
      startDate: data.start_date,
      negotiatingStartDate: data.negotiating_start_date,
      managerMessage: data.manager_message || undefined,
      contractorMessage: data.contractor_message,
      publicRequestEvents: data.public_request_events.map(
        (event: RawRequestEvent) => RequestSerializer.deSerializeRequestEvent(event),
      ),
      taskMessages: data.task_messages
        ? MessageSerializer.deSerializeMessages(data.task_messages)
        : [],
      newTask: NewTaskSerializer.deSerializeNewTask(data.new_task),
      contractor: NewTaskSerializer.deSerializeBasicUserFields(data.contractor),
      manager: NewTaskSerializer.deSerializeBasicUserFields(data.manager),
      ...TypeOfCostSerializer.deSerializeTypeOfCost(data),
    };
  }

  static deSerializePublicRequestIndex(data: RawPublicRequestIndex): PublicRequestIndex {
    return {
      id: data.id,
      calculatedCost: data.calculated_cost,
      contractor: {
        id: data.contractor.id,
        certifications: CertificationSerializer.deserializeContractorCertificationArray(
          data.contractor.certifications,
        ),
        cities: CitySerializer.deSerializeCities(data.contractor.cities),
        lastName: data.contractor.last_name,
        name: data.contractor.name,
        profilePhoto: data.contractor.profile_photo,
        specializations: SpecializationSerializer.deSerializeAllContractorSpecialization(
          data.contractor.specializations,
        ),
      },
      contractorMessage: data.contractor_message,
      createdAt: data.created_at,
      startDate: data.start_date,
      status: data.status,
      ...TypeOfCostSerializer.deSerializeTypeOfCost(data),
    };
  }

  static deSerializePublicRequestIndexArray(data: RawPublicRequestIndex[]): PublicRequestIndex[] {
    return data.map((item) => PublicRequestSerializer.deSerializePublicRequestIndex(item));
  }
}

export { PublicRequestSerializer };
