import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import {
  useState, FormEvent, ChangeEvent,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Alert } from 'assets/icons/alert.svg';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { ModalResult } from 'common/modal-result';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import {
  classnames, checkHTMLErrors,
} from '@mapix/common/src/helpers/utils';
import { ReactComponent as Tool } from 'assets/icons/tool.svg';
import { Contractor } from 'models/contractor';
import { MaintenanceController } from 'networking/controllers/maintenance-controller';
import { Button, ButtonStyle, ButtonType } from '@mapix/common/src/common/button';
import { PhoneInput } from '@mapix/common/src/common/phone-input';

import { UserController } from 'networking/controllers/user-controller';
import styles from './add-contractor-modal.module.scss';

const translPrefix = 'createTask.contractorModal.success';

type AddContractorModalProps = {
  closeFn: () => void,
  category: string,
  propertyId: number,
  onSuccess: (newContractor: Contractor) => void,
};

const initFormData = () => ({
  email: '',
  name: '',
  phoneNumber: '',
  countryCode: '',
});

const AddContractorModal = ({
  closeFn, category, propertyId, onSuccess,
}: AddContractorModalProps) => {
  const { t } = useTranslation();
  const [showError, setShowError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [phoneErrors, setPhoneErrors] = useState({});
  const [formData, setFormData] = useState(initFormData());
  const [submitted, setSubmitted] = useState(false);

  const handleChangeInput = (value: string, id: string) => {
    setFormData((prevState) => ({ ...prevState, [id]: value }));
  };

  const onChangePhoneNumber = (phoneNumber: string, countryCode: string) => {
    setFormData((prevState) => (
      { ...prevState, phoneNumber, countryCode }));
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    if (submitted) {
      return;
    }
    setSubmitted(true);
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    if (!target.checkValidity()) {
      setFormErrors(checkHTMLErrors(target));
    } else {
      try {
        const contractor: Contractor = {
          name: formData.name,
          phoneNumber: formData.phoneNumber,
          email: formData.email,
          countryCode: formData.countryCode,
          category,
          propertyId,

        };
        const response = await MaintenanceController.addContractor(propertyId, contractor);
        if (response) {
          contractor.id = response.id;
        }
        setShowModal(true);
        onSuccess(contractor);
      } catch (ex) {
        setShowError(true);
      }
    }
  };

  if (showModal) {
    return (
      <ModalResult
        title={t(`${translPrefix}.modalTitle`)}
        subtitle={t(`${translPrefix}.modalSubtitle`)}
        Icon={Tool}
        withCheckIcon
        buttonTextRight={t(`${translPrefix}.modalButton`)}
        handleButtonRight={closeFn}
        handleButtonClose={closeFn}
      />
    );
  }
  return (
    <form onSubmit={onSubmit} noValidate>
      <div className={classnames(styles.contractorContainer, 'column')}>
        {showError ? (
          <div className={styles.errorContainer}>
            <Alert className={styles.icon} />
            <p className="text__body__small__danger50">
              {t('createTask.contractorModal.error')}
            </p>
            <button type="button">
              <Close className={styles.close} onClick={closeFn} />
            </button>
          </div>
        ) : (
          <button aria-label="Close" className={styles.cross} type="button" onClick={closeFn}>
            <Cross />
          </button>
        )}
        <div className="text__heading5__textNeutral50">
          {t('createTask.contractorModal.title')}
        </div>
        <div className={classnames(styles.subtitleMargin, 'text__heading6__textNeutral50')}>{`${t('createTask.contractorModal.subtitle')}${category}`}</div>
        <div className={styles.firstRow}>
          <Input
            id="name"
            containerClass={styles.input}
            type="text"
            inputStyle={InputStyle.FORM}
            placeholder={t('createTask.contractorModal.name')}
            helperText={t('createTask.contractorModal.helperName')}
            required
            formError={formErrors}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInput(e.target.value, 'name')}
            t={t}
          />
          <PhoneInput
            id="phoneNumber"
            containerClass={styles.input}
            label={t('createTask.contractorModal.phone')}
            helperText={t('createTask.contractorModal.helperPhone')}
            required
            formError={{ ...formErrors, ...phoneErrors }}
            phoneWithoutCode={formData.phoneNumber}
            countryCode={formData.countryCode}
            onChangePhoneNumber={onChangePhoneNumber}
            setPhoneErrors={setPhoneErrors}
            validatePhone={UserController.phoneValidation}
            t={t}
          />
        </div>
        <Input
          id="email"
          containerClass={styles.input}
          type="email"
          inputStyle={InputStyle.FORM}
          placeholder={t('createTask.contractorModal.email')}
          helperText={t('createTask.contractorModal.helperEmail')}
          required
          formError={formErrors}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInput(e.target.value, 'email')}
          t={t}
        />

        <div className={styles.buttonContainer}>
          <Button
            onClick={closeFn}
            className={classnames(styles.cancel, styles.buttonWidth)}
            buttonStyle={ButtonStyle.Secondary}
          >
            {t('createTask.contractorModal.cancel')}
          </Button>
          <Button
            className={styles.buttonWidth}
            buttonType={ButtonType.Submit}
            buttonStyle={ButtonStyle.Primary}
            disabled={submitted}
          >
            {t('createTask.contractorModal.add')}
          </Button>
        </div>

      </div>
    </form>
  );
};

export { AddContractorModal };
