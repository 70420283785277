import { SimpleUnitSerializer } from 'networking/serializers/simple-unit-serializer';
import { BillSerializer } from 'networking/serializers/bill-serializer';
import {
  CancelTaskData, ModifyCancellationData, ModifyRate, RawCancelTaskData, RawModifyCancellationData,
} from '@mapix/common/src/types';
import { RawModifyRate } from 'networking/types/private-request';
import { TypeOfCostSerializer } from '@mapix/common/src/networking/serializers/type-of-cost-serializer';
import { CreateClaimData, NewClaim, RawNewClaim } from 'networking/types/claim-auxiliar';
import { AddressSerializer } from '@mapix/common/src/networking/serializers/address-serializer';
import { MaintenancePropertySerializer } from './maintenance-property-serializer';
import { MaintenanceTaskEventsSerializer } from './maintenance-task-serializer';
import { MaintenanceUnitSerializer } from './maintenance-unit-serializer';
import { MaintenanceContractorSerializer } from './maintenance-contractor-serializer';
import { UserSerializer } from './user-serializer';
import { UnitSerializer } from './unit-serializer';

class MaintenanceSerializer {
  static deSerialize(data: RawTask): SerializedTask {
    return {
      property: MaintenancePropertySerializer.deSerialize(data.property),
      category: data.category,
      name: data.name,
      id: data.id,
      activeRequestAssignedId: data.active_request_assigned_id,
      createdAt: data.created_at,
      startDate: data.start_date,
      priority: data.priority,
      contractor: data.contractor
        ? MaintenanceContractorSerializer.deSerialize(data.contractor) : null,
      unit: data.unit ? MaintenanceUnitSerializer.deSerialize(data.unit) : null,
      solvedDate: data.solved_date,
      applications: data.amount_of_applications,
      status: data.status,
      taskType: data.task_type,
      calculatedCost: data.calculated_cost,
      contractorAssignedName: data.contractor_assigned_name,
      contractorAssignedLastName: data.contractor_assigned_last_name,
      cancelledDate: data.cancelled_date,
      cancelledBy: data.cancelled_by,
      bill: data.bill ? BillSerializer.deSerialize(data.bill) : null,
      tenantLastName: data.tenant_last_name,
      tenantName: data.tenant_name,
    };
  }

  static deSerializeTask(data: ViewTaskBackend): SerializedTaskView {
    return {
      property: MaintenancePropertySerializer.deSerialize(data.property),
      category: data.category,
      id: data.id,
      startDate: data.start_date,
      contractor: data.contractor
        ? MaintenanceContractorSerializer.deSerialize(data.contractor) : undefined,
      status: data.status,
      unitNumber: data.unit_number,
      description: data.description,
      priority: data.priority,
      photos: data.task_photos,
      cost: data.cost,
      endDate: data.end_date,
      additionalInfo: data.additional_information,
      rejectionDate: data.rejection_date,
      sms: data.sms,
      email: data.email,
      rejectionReason: data.reason_to_reject,
      contactMethod: data.contact_method,
      unit: data.unit ? SimpleUnitSerializer.deSerialize(data.unit) : null,
      manager: data.creator ? UserSerializer.deSerialize(data.creator) : undefined,
      taskEvents: data.task_events ? data.task_events.map(
        (event) => MaintenanceTaskEventsSerializer.deSerialize(event),
      ) : [],
      bill: data.bill ? BillSerializer.deSerialize(data.bill) : null,
    };
  }

  static deSerializeContractrTaskDetail(data: RawTaskDetailContractor): TaskDetailContractor {
    return {
      property: MaintenancePropertySerializer.deSerialize(data.property),
      category: data.category,
      id: data.id,
      status: data.status,
      description: data.description,
      additionalInformation: data.additional_information,
      unit: data.unit ? SimpleUnitSerializer.deSerialize(data.unit) : null,
      manager: data.manager ? UserSerializer.deSerialize(data.manager) : undefined,
      taskPhotos: data.task_photos,
    };
  }

  static serializeSolveATask(data: SolveATask): DeserializedSolveATask {
    return {
      id: data.id,
      end_date: data.endDate,
      additional_comments: data.additionalComments || undefined,
      status: data.status,
    };
  }

  static serializeAcceptTask(data: AcceptATask): AcceptATask {
    return {
      id: data.id,
      priority: data.priority,
      status: data.status,
    };
  }

  static serializeRejectTask(data: RejectTask) {
    return {
      id: data.id,
      status: 'Rejected',
      reason_to_reject: data.reason,
    };
  }

  static serializeRejectTaskInProgressData = (
    data: RejectTaskInProgress,
  ): RawRejectTaskInProgress => ({
    message_for_in_progress_movement_rejection: data.messageForInProgressMovementRejection,
  });

  static serializeRejectTaskSolvedData = (
    data: RejectTaskInSolved,
  ): RawRejectTaskSolved => ({
    message_for_solved_movement_rejection: data.messageForSolvedMovementRejection,
  });

  static serializeTask(data: SerializedCreateTask): DeserializedCreateTask {
    return {
      category: data.category,
      property_id: data.propertyId,
      start_date: data.startDate,
      description: data.description,
      priority: data.priority,
      unit_id: data.unitId,
      contractor_id: data.contractorId,
      additional_information: data.additionalInformation,
      contact_method: data.contactMethod,
      email: data.email,
      sms: data.sms,
      task_photos: data.taskPhoto,
    };
  }

  static serializeCreateClaim(data: CreateClaimData): RawCreateClaimData {
    return {
      category_id: data.category.id,
      property_id: data.propertyId,
      start_date: data.startDate,
      description: data.description,
      unit_id: data.unitId || null,
      claim_photos: data.photos,
      name: data.name,
      other_category: data.otherCategory || null,
    };
  }

  static serializeEditClaim(data: SerializedEditClaim): RawEditClaim {
    return {
      category: data.category,
      start_date: data.startDate,
      description: data.description,
      task_photos: data.photos,
    };
  }

  static deSerializeClaim(data: RawClaim): SerializedClaim {
    return {
      id: data.id,
      category: data.category,
      status: data.status,
      description: data.description,
      startDate: data.start_date,
      reasonToReject: data.reason_to_reject,
      rejectionDate: data.rejection_date,
      user: data.user ? UserSerializer.deSerialize(data.user) : undefined,
      contractor: data.contractor
        ? MaintenanceContractorSerializer.deSerialize(data.contractor) : undefined,
      property: MaintenancePropertySerializer.deSerialize(data.property),
      unit: data.unit ? UnitSerializer.deSerializeClaimUnit(data.unit) : undefined,
      claimEvents: data.task_events ? data.task_events.map(
        (event) => MaintenanceTaskEventsSerializer.deSerialize(event),
      ) : [],
      photos: data.task_photos,
    };
  }

  static deSerializeNewClaim(data: RawNewClaim): NewClaim {
    return {
      id: data.id,
      category: data.category,
      status: data.status,
      description: data.description,
      startDate: data.start_date,
      otherCategory: data.otherCategory,
      propertyId: data.property_id,
      unitId: data.unit_id ?? undefined,
      name: data.name,
      photos: data.claim_photos,
      createdAt: data.created_at,
      address: AddressSerializer.deSerializeAddress(data.address),
      buildingName: data.building_name,
      estimatedEndDate: data.estimated_end_date,
      cancelledDate: data.cancelled_date ?? undefined,
      solvedDate: data.solved_date ?? undefined,
      contractor: data.contractor ? {
        name: data.contractor.name,
        lastName: data.contractor.last_name,
        specializations: data.contractor.specializations,
        profilePhoto: data.contractor.profile_photo,
      } : null,
      reasonForRejection: data.reason_for_rejection ?? undefined,
    };
  }

  static deSerializeNewClaimArray(data: RawNewClaim[]): NewClaim[] {
    return data.map((claim) => this.deSerializeNewClaim(claim));
  }

  static serializeModifyRate(data: ModifyRate): RawModifyRate {
    return {
      message_to_contractor: data.message,
      ...TypeOfCostSerializer.serializeTypeOfCost(data),
    };
  }

  static serializeCancelTaskData(data: CancelTaskData): RawCancelTaskData {
    return {
      reason_for_cancellation: data.reasonForCancellation,
      cancellation_percentage: data.cancellationPercentage as number,
      cancellation_message: data.comments,
    };
  }

  static serializeModifyCancellationTaskData(
    data: ModifyCancellationData,
  ): RawModifyCancellationData {
    return {
      cancellation_percentage: data.cancellationPercentage as number,
      comments: data.comments,
    };
  }

  static serializeRejectClaimData(data: RejectClaim): RawRejectClaim {
    return {
      reason_for_rejection: data.reasonForRejection,
    };
  }

  static serializeAcceptClaimData(data: AcceptClaim): RawAcceptClaim {
    return {
      task_type: data.taskType,
      contractor_id: data.contractorId || null,
      contractor_message: data.contractorMessage || null,
      name: data.name,
      description: data.description,
      priority: data.priority,
    };
  }
}

export { MaintenanceSerializer };
