import { PaginatorContainer } from '@mapix/common/src/types';
import { DateFilter } from 'common/filter-by-date/filter-by-date';
import { ModalFilterItem } from 'common/filter-modal/filter-modal';
import { addDateAndFiltersToURL, addDateFilterToURL, addFiltersToURL } from 'helpers/utils';
import { Bill } from 'models/bill';
import { Paginator } from 'models/paginator';
import { API_ROUTES } from 'networking/api-routes';
import { ApiService } from 'networking/api-service';
import { BillCreateSerializer } from 'networking/serializers/bill-create-serializer';
import { BillSerializer } from 'networking/serializers/bill-serializer';
import { PaginatorSerializer } from 'networking/serializers/paginator-serializer';
import { NewBillDetail, RawNewBillDetail } from 'networking/types/bill-detail';
import { NewBillIndex, RawNewBillIndex } from 'networking/types/new-bill';

class BillsController {
  static async getBills(
    page: number,
    pageSize: number,
    query: string,
    status: string,
    dateFilter: DateFilter,
    filters?: ModalFilterItem[],
  ) {
    const url = `${API_ROUTES
      .GET_BILLS(page, pageSize, status, query)}&${addDateAndFiltersToURL(dateFilter, filters)}`;
    const result = await ApiService.get<RawPaginatorContainer<RawBill>>(url);
    const { results, navigation } = result.data;
    const paginator = PaginatorSerializer.deSerialize(navigation);

    return new Paginator<Bill>(
      paginator,
      results.map((item) => new Bill(BillSerializer.deSerialize(item))),
    );
  }

  static async getPropertyBill(
    page: number,
    pageSize: number,
    query: string,
    status: string,
    propertyId: number,
    filters?: ModalFilterItem[],
    dateFilter?: DateFilter,
  ) {
    const url = `${API_ROUTES
      .GET_PROPERTY_BILLS(page, pageSize, propertyId, status, query)}&${addFiltersToURL(filters)}${addDateFilterToURL(dateFilter)}`;
    const result = await ApiService.get<RawPaginatorContainer<RawBill>>(url);
    const { results, navigation } = result.data;
    const paginator = PaginatorSerializer.deSerialize(navigation);

    return new Paginator<Bill>(
      paginator,
      results.map((item) => new Bill(BillSerializer.deSerialize(item))),
    );
  }

  static async getUnitBills(
    page: number,
    pageSize: number,
    query: string,
    status: string,
    unitId: number,
    filters?: ModalFilterItem[],
    dateFilter?: DateFilter,
  ) {
    const url = `${API_ROUTES
      .GET_UNIT_BILLS(page, pageSize, unitId, status, query)}&${addFiltersToURL(filters)}${addDateFilterToURL(dateFilter)}`;
    const result = await ApiService.get<RawPaginatorContainer<RawBill>>(url);
    const { results, navigation } = result.data;
    const paginator = PaginatorSerializer.deSerialize(navigation);

    return new Paginator<Bill>(
      paginator,
      results.map((item) => new Bill(BillSerializer.deSerialize(item))),
    );
  }

  static async addBills(propertyId: number, bills: SerializedBillCreateArray) {
    return ApiService.post<RawBillCreateArray>(
      API_ROUTES.PROPERTY_BILL(propertyId),
      BillCreateSerializer.serializeArray(bills),
    );
  }

  static async editBills(bill: SerializedBillCreate, billId: number) {
    return ApiService.put<RawBill>(
      API_ROUTES.MANAGE_BILL(billId),
      BillCreateSerializer.serialize(bill),
    );
  }

  static async addBilWithTask(bill: SerializedBillWithTask, propertyId: number) {
    return ApiService.post<RawBillWithTask>(
      API_ROUTES.BILL_WITH_TASK(propertyId, bill.taskId),
      BillCreateSerializer.serializeWithTask(bill),
    );
  }

  static async deleteBill(id: number) {
    return ApiService.delete(API_ROUTES.MANAGE_BILL(id));
  }

  // ----------------- V2 ----------------

  static async getBillsV2(
    page: number,
    pageSize: number,
    status?: string,
    query?: string,
  ): Promise<PaginatorContainer<NewBillIndex>> {
    const { data } = await ApiService.get<RawPaginatorContainer<RawNewBillIndex>>(
      API_ROUTES.NEW_BILLS(page, pageSize, status, query),
    );
    return {
      navigation: PaginatorSerializer.deSerialize(data.navigation),
      results: BillSerializer.deSerializeNewBillIndexArray(data.results),
    };
  }

  static async getBillV2(id: number): Promise<NewBillDetail> {
    const result = await ApiService.get<RawNewBillDetail>(API_ROUTES.NEW_BILL(id));
    return BillSerializer.deSerializeNewBill(result.data);
  }
}

export { BillsController };
