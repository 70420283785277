import { getFullAddress } from 'helpers/utils';
import { PropertyAddress } from './property-address';

class TenantSimpleProperty {
  id: number;

  unitId: number | undefined;

  address: PropertyAddress;

  fullAddress: string;

  constructor(params: SerializedTenantSimpleProperty | null) {
    this.id = params?.id || 0;
    this.unitId = params?.unitId;
    this.address = new PropertyAddress(params?.address);
    this.fullAddress = getFullAddress(this.address);
  }
}

export { TenantSimpleProperty };
