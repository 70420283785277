enum Breakpoints {
  xl = 1400,
  lg = 1200,
  md = 992,
  sm = 768,
  xs = 414,
}

enum Users {
  Manager = 'Manager',
  Tenant = 'Tenant',
  Contractor = 'Contractor',
}

enum TaskType {
  PublicTask = 'public_task',
  PrivateTask = 'private_task',
  UnpublishedTask = 'unpublished_task',
}

enum CancelledByType {
  CancelledByManager = 'cancelled_by_manager',
  CancelledByContractor = 'cancelled_by_contractor',
}

enum RawUsersRejectMovement {
  RejectedInProgressByContractor = 'rejected_in_progress_by_contractor',
  RejectedInProgressByManager = 'rejected_in_progress_by_manager',
  RejectedSolvedByContractor = 'rejected_solved_by_contractor',
  RejectedSolvedByManager = 'rejected_solved_by_manager',
}

enum InputStyle {
  REGULAR = 'REGULAR',
  FORM = 'FORM',
  DROPDOWN_BUTTON = 'DROPDOWN_BUTTON',
}

enum FileState {
  LOADING = 'LOADING',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
}

enum PaymentInformationStatus {
  PENDING = 'Pending',
  IN_PROGRESS = 'In Progress',
  COMPLETED = 'Completed',
}

enum PaymentStates {
  OnTime = 'onTime',
  Late = 'late',
  PaidOnTime = 'paidOnTime',
  PaidLate = 'paidLate',
}

enum CreditCardType {
  Visa = 'visa',
  Mastercard = 'mastercard',
  Amex = 'amex',
}

enum Currency {
  USD = 'USD',
  CAD = 'CAD',
}

// This enum should be used with causion.
// It is preferable to get the categories from backend.
// It should be updated if any category changes
enum Specializations {
  Handyman = 'Handyman',
  GeneralContractor = 'General Contractor',
  Carpentry = 'Carpentry',
  Roofing = 'Roofing',
  Drywaller = 'Drywaller',
  Plaster = 'Plaster',
  Painting = 'Painting',
  WallpaperInstaller = 'Wallpaper Installer',
  Masonry = 'Masonry',
  GlassAndGlazing = 'Glass And Glazing',
  FloorLaying = 'Floor Laying',
  ConcreteSpecialist = 'Concrete Specialist',
  TileSetting = 'Tile Setting',
  Electricity = 'Electricity',
  Plumbing = 'Plumbing',
  ElevatorsAndOtherLiftingDevices = 'Elevators and other lifting devices',
  HeatingAndAirConditioning = 'Heating and Air-Conditioning',
  GardeningAndLandscape = 'Gardening & landscape',
  Excavator = 'Excavator',
  Demolition = 'Demolition',
}

enum MakePaymentModalState {
  ShowMakePaymentInformationForm,
  ShowPaymentMethods,
  ShowAddCard,
  ShowSpinnerSafecharge,
  ShowSuccess,
  ShowNuveiError,
  ShowGenericError,
  ShowSpinner,
}

enum Priority {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  EMERGENCY = 'emergency',
}

enum TaskStatus {
  NEW = 'new',
  REQUEST = 'requests',
  NEGOTIATING = 'negotiating',
  IN_PROGRESS = 'in_progress',
  SOLVED = 'solved',
  REJECTED = 'claim_rejected',
  CANCELLED = 'cancelled',
  APPLICATIONS = 'applications',
  UNASSIGNED = 'unassigned',
  UNPUBLISHED = 'unpublished',
  PUBLIC_TASK = 'public_task',
  RECEIVING_APPLICATIONS = 'receiving_applications',
  REJECTED_CANCELLED = 'rejected_cancelled',
  WAITING_FOR_CONTRACTOR_APPROVAl = 'waiting_for_contractor_approval',
  WAITING_MANAGER_CANCELLED_PERCENTAGE_APPROVAL = 'waiting_manager_cancelled_percentage_approval',
  WAITING_CONTRACTOR_CANCELLED_PERCENTAGE_APPROVAL = 'waiting_contractor_cancelled_percentage_approval',
}

enum RawNewTaskStatus {
  REJECTED = 'claim_rejected',
  AWAITING = 'awaiting',
  WAITING_FOR_CONTRACTOR_ASSIGNATION = 'waiting_for_contractor_assignation',
  WAITING_FOR_CONTRACTOR_APPROVAL = 'waiting_for_contractor_approval',
  NEGOTIATION_PHASE = 'negotiation_phase',
  READY = 'ready',
  SOLVED = 'solved',
  IN_PROGRESS = 'in_progress',
  WAITING_MANAGER_RATE_APPROVAL = 'waiting_manager_rate_approval',
  WAITING_CONTRACTOR_RATE_APPROVAL = 'waiting_contractor_rate_approval',
  WAITING_MANAGER_CANCELLED_PERCENTAGE_APPROVAL = 'waiting_manager_cancelled_percentage_approval',
  WAITING_CONTRACTOR_CANCELLED_PERCENTAGE_APPROVAL = 'waiting_contractor_cancelled_percentage_approval',
  CANCELLED = 'cancelled',
  UNPUBLISHED = 'unpublished',
  STOP_APPLICATIONS_FOR_NEGOTIATION = 'stop_applications_for_negotiation',
  RECEIVING_APPLICATIONS = 'receiving_applications',
}

enum RawNewTaskEvent {
  AWAITING_TO_REJECTED = 'awaiting_to_rejected',
  AWAITING_TO_WAITING_FOR_CONTRACTOR_APPROVAL = 'awaiting_to_waiting_for_contractor_approval',
  WAITING_FOR_CONTRACTOR_APPROVAL_TO_WAITING_FOR_CONTRACTOR_ASSIGNATION = 'waiting_for_contractor_approval_to_waiting_for_contractor_assignation',
  WAITING_FOR_CONTRACTOR_APPROVAL_TO_NEGOTIATION_PHASE = 'waiting_for_contractor_approval_to_negotiation_phase',
  NEGOTIATION_PHASE_TO_WAITING_FOR_CONTRACTOR_ASSIGNATION = 'negotiation_phase_to_waiting_for_contractor_assignation',
  WAITING_FOR_CONTRACTOR_ASSIGNATION_TO_WAITING_FOR_CONTRACTOR_APPROVAL = 'waiting_for_contractor_assignation_to_waiting_for_contractor_approval',
  NEGOTIATION_PHASE_TO_READY = 'negotiation_phase_to_ready',
  READY_TO_IN_PROGRESS = 'ready_to_in_progress',
  IN_PROGRESS_TO_READY = 'in_progress_to_ready',
  IN_PROGRESS_TO_WAITING_MANAGER_RATE_APPROVAL = 'in_progress_to_waiting_manager_rate_approval',
  IN_PROGRESS_TO_WAITING_CONTRACTOR_RATE_APPROVAL = 'in_progress_to_waiting_contractor_rate_approval',
  WAITING_MANAGER_RATE_APPROVAL_TO_WAITING_CONTRACTOR_RATE_APPROVAL = 'waiting_manager_rate_approval_to_waiting_contractor_rate_approval',
  WAITING_CONTRACTOR_RATE_APPROVAL_TO_WAITING_MANAGER_RATE_APPROVAL = 'waiting_contractor_rate_approval_to_waiting_manager_rate_approval',
  WAITING_MANAGER_RATE_APPROVAL_TO_IN_PROGRESS = 'waiting_manager_rate_approval_to_in_progress',
  WAITING_CONTRACTOR_RATE_APPROVAL_TO_IN_PROGRESS = 'waiting_contractor_rate_approval_to_in_progress',
  WAITING_CONTRACTOR_RATE_APPROVAL_TO_WAITING_MANAGER_CANCELLED_PERCENTAGE_APPROVAL = 'waiting_contractor_rate_approval_to_waiting_manager_cancelled_percentage_approval',
  WAITING_CONTRACTOR_RATE_APPROVAL_TO_WAITING_CONTRACTOR_CANCELLED_PERCENTAGE_APPROVAL = 'waiting_contractor_rate_approval_to_waiting_contractor_cancelled_percentage_approval',
  WAITING_MANAGER_RATE_APPROVAL_TO_WAITING_MANAGER_CANCELLED_PERCENTAGE_APPROVAL = 'waiting_manager_rate_approval_to_waiting_manager_cancelled_percentage_approval',
  WAITING_MANAGER_RATE_APPROVAL_TO_WAITING_CONTRACTOR_CANCELLED_PERCENTAGE_APPROVAL = 'waiting_manager_rate_approval_to_waiting_contractor_cancelled_percentage_approval',
  WAITING_CONTRACTOR_CANCELLED_PERCENTAGE_APPROVAL_TO_WAITING_MANAGER_CANCELLED_PERCENTAGE_APPROVAL = 'waiting_contractor_cancelled_percentage_approval_to_waiting_manager_cancelled_percentage_approval',
  WAITING_MANAGER_CANCELLED_PERCENTAGE_APPROVAL_TO_WAITING_CONTRACTOR_CANCELLED_PERCENTAGE_APPROVAL = 'waiting_manager_cancelled_percentage_approval_to_waiting_contractor_cancelled_percentage_approval',
  WAITING_MANAGER_CANCELLED_PERCENTAGE_APPROVAL_TO_CANCELLED = 'waiting_manager_cancelled_percentage_approval_to_cancelled',
  WAITING_CONTRACTOR_CANCELLED_PERCENTAGE_APPROVAL_TO_CANCELLED = 'waiting_contractor_cancelled_percentage_approval_to_cancelled',
  IN_PROGRESS_TO_SOLVED = 'in_progress_to_solved',
  UNPUBLISHED_TO_WAITING_FOR_CONTRACTOR_APPROVAL = 'unpublished_to_waiting_for_contractor_approval',
  RATE_CHANGED_BY_MANAGER = 'rate_changed_by_manager',
  RATE_CHANGED_BY_CONTRACTOR = 'rate_changed_by_contractor',
  PERCENTAGE_CHANGED_BY_MANAGER = 'percentage_changed_by_manager',
  PERCENTAGE_CHANGED_BY_CONTRACTOR = 'percentage_changed_by_contractor',
}

enum RawRequestStatus {
  PENDING = 'pending',
  ASSIGNED = 'assigned',
  WAITING_FOR_MANAGER_APPROVAL = 'waiting_for_manager_approval',
  WAITING_FOR_CONTRACTOR_APPROVAL = 'waiting_for_contractor_approval',
  REJECTED_BY_CONTRACTOR = 'rejected_by_contractor',
  REJECTED_BY_MANAGER = 'rejected_by_manager',
  REJECTED_AUTOMATICALLY = 'rejected_automatically',
  ACCEPTED = 'accepted',
}

enum FileExtension {
  Jpg = '.jpg',
  Jpeg = '.jpeg',
  Png = '.png',
  Pdf = '.pdf',
}

enum EntityType {
  Request = 'request',
  Task = 'task',
}

enum IconColor {
  Purple = 'purple',
  Yellow = 'yellow',
  Red = 'red',
}

enum PillColor {
  Blue,
  Green,
  Purple,
  Red,
  Yellow,
  Gray,
  White,
}

enum RawNewBillStatus {
  AWAITING = 'Awaiting',
  DUE = 'due',
  OVERDUE = 'overdue',
  PAID = 'paid',
}

export {
  Breakpoints, Users, InputStyle, FileState, PaymentInformationStatus, CreditCardType,
  Currency, PaymentStates, Specializations, MakePaymentModalState, Priority, TaskStatus,
  RawNewTaskStatus, RawRequestStatus, RawNewTaskEvent, FileExtension, IconColor,
  PillColor, RawUsersRejectMovement, RawNewBillStatus, EntityType, TaskType, CancelledByType,
};
