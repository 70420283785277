import React, {
  FormEvent, useEffect, useReducer, useState,
} from 'react';
import { ButtonClose } from '@mapix/common/src/common/button-close';
import {
  addOrRemoveFromArrayString, classnames, HTMLValidationError, checkHTMLErrors,
} from '@mapix/common/src/helpers/utils';
import { ReactComponent as Alert } from 'assets/icons/alert.svg';
import { ReactComponent as AlertFilled } from 'assets/icons/alertFilled.svg';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as File } from 'assets/icons/file.svg';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { ButtonType } from '@mapix/common/src/common/button/button';
import { InputDate } from '@mapix/common/src/common/input-date';
import dayjs from 'dayjs';
import { logger } from 'helpers/logger';
import { LeaseController } from 'networking/controllers/lease-controller';
import { ModalResult } from 'common/modal-result';
import { Spinner } from '@mapix/common/src/common/spinner';
import { RentalsController } from 'networking/controllers/rentals-controller';
import { Table } from 'common/table';
import { EndLeaseTableItem } from 'models/end-lease-table-item';
import { PropertyType } from 'common/enums';
import { EndLeaseReducer, initialState } from './end-lease-reducer';
import { RentalActions } from './rental-actions';
import styles from './end-lease.module.scss';

const headers = ['amount', 'dueDate', 'status'];
const rentalProperties = ['rentalAmount', 'dueDate', 'status'];

const translPrefix = 'endLease';

type ActionItemsType = {
  [rentalId: number]: {
    option: string,
    payment: string,
  },
};

const EndLease = () => {
  const [endDate, setEndDate] = useState('');
  const [state, dispatch] = useReducer(EndLeaseReducer, initialState);
  const [fetching, setFetching] = useState(false);
  const [errorServer, setErrorServer] = useState(false);
  const [endAndArchive, setEndAndArchive] = useState(false);
  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});
  const [actionItems, setActionItems] = useState<ActionItemsType>({});

  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const checkAllOptionsSelected = () => {
    if (Object.keys(actionItems).length !== state.data.length) {
      dispatch({ type: 'OPTION_NOT_SELECTED', optionNotSelected: true });
      return false;
    }
    return true;
  };

  const applyRentalAction = (
    selectedOption: string,
    rentalId: number,
    paymentDate: string,
  ) => {
    const auxActionItems = actionItems;
    auxActionItems[rentalId] = { option: selectedOption, payment: paymentDate || '' };
    setActionItems(auxActionItems);
  };

  const reshapeEndLease = () => {
    const rentals: SerializedEndLease = {
      endDate,
      rentalActions: {
        delete: [],
        keepStatus: [],
        registerPayment: {},
      },
    };

    Object.entries(actionItems).forEach(([k, v]) => {
      if (v.option === 'keepStatus') {
        if (rentals.rentalActions.delete.includes(k)) {
          addOrRemoveFromArrayString(rentals.rentalActions.delete, k);
        } if (!rentals.rentalActions.keepStatus.includes(k)) {
          rentals.rentalActions.keepStatus.push(k);
        } if (rentals.rentalActions.registerPayment[k] !== undefined) {
          delete rentals.rentalActions.registerPayment[k];
        }
      } else if (v.option === 'deleteRental') {
        if (rentals.rentalActions.keepStatus.includes(k)) {
          addOrRemoveFromArrayString(rentals.rentalActions.keepStatus, k);
        } if (!rentals.rentalActions.delete.includes(k)) {
          rentals.rentalActions.delete.push(k);
        } if (rentals.rentalActions.registerPayment[k] !== undefined) {
          delete rentals.rentalActions.registerPayment[k];
        }
      } else {
        if (rentals.rentalActions.keepStatus.includes(k)) {
          addOrRemoveFromArrayString(rentals.rentalActions.keepStatus, k);
        } if (rentals.rentalActions.delete.includes(k)) {
          addOrRemoveFromArrayString(rentals.rentalActions.delete, k);
        }
        rentals.rentalActions.registerPayment[k] = v.payment;
      }
    });

    return rentals;
  };

  const endLease = async () => {
    setFormErrors({});
    const rentalActions: SerializedEndLease = reshapeEndLease();

    try {
      setFetching(true);
      if (endAndArchive) {
        await LeaseController.endLeaseAndArchiveProperty(Number(id), rentalActions);
      } else {
        await LeaseController.endLease(Number(id), rentalActions);
      }
      dispatch({ type: 'SUCCESS_END_LEASE_MODAL', sucessEndLeaseModal: true });
      setFetching(false);
    } catch (err: any) {
      setFetching(false);
      dispatch({ type: 'END_MODAL', endModal: false });
      setErrorServer(true);
      logger.error(err as Error);
    }
  };

  const endLeaseButton = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    if (checkAllOptionsSelected() && target.checkValidity()) {
      dispatch({ type: 'END_MODAL', endModal: true });
    } else {
      setFormErrors(checkHTMLErrors(target));
    }
  };

  const iconCells = (item: EndLeaseTableItem) => [<RentalActions
    onClickOption={applyRentalAction}
    rentalId={item.rentalId}
    key={item.rentalId}
    code={actionItems[item.rentalId] ? actionItems[item.rentalId].option : ''}
  />];
  const getLease = async () => {
    try {
      setFetching(true);
      const fetchedLease = await LeaseController.getDetailedLease(Number(id));
      if (fetchedLease !== undefined) {
        dispatch({ type: 'LEASE_FETCHED', lease: fetchedLease });
      }
      setFetching(false);
    } catch (err: any) {
      setFetching(false);
      setErrorServer(true);
      logger.error(err as Error);
    }
  };

  const getRentalsOfALease = async () => {
    try {
      setFetching(true);
      const fetchedRentals = await RentalsController.getRentalsOfALease(Number(id));
      const overduePendingRentals = fetchedRentals.filter((rental) => rental.status === 'pending' || rental.status === 'overdue');
      dispatch({ type: 'RENTALS_FETCHED', data: overduePendingRentals });
      setFetching(false);
    } catch (err: any) {
      setFetching(false);
      setErrorServer(true);
      logger.error(err as Error);
    }
  };

  const nextDate = (day: string) => {
    const newDate = dayjs(day).toDate();
    newDate.setDate(newDate.getDate());
    return newDate;
  };

  useEffect(() => {
    if (urlParams.get('endAndArchive')) {
      setEndAndArchive(true);
    } else {
      setEndAndArchive(false);
    }
    getLease();
    getRentalsOfALease();
  }, []);

  if (state.sucessEndLeaseModal) {
    return (
      <ModalResult
        title={t(`${translPrefix}.successEndModal.title`)}
        subtitle={endAndArchive ? t(`${translPrefix}.successEndModal.subtitleEndAndArchive`) : t(`${translPrefix}.successEndModal.subtitle`)}
        Icon={File}
        buttonTextRight={t(`${translPrefix}.successEndModal.ok`)}
        handleButtonClose={() => { history.goBack(); }}
        handleButtonRight={() => { history.goBack(); }}
        withCheckIcon
      />
    );
  }

  if (state.endModal) {
    return (
      <ModalResult
        title={t(`${translPrefix}.endModal.title`)}
        subtitle={endAndArchive ? t(`${translPrefix}.endModal.subtitleEndAndArchive`) : t(`${translPrefix}.endModal.subtitle`)}
        Icon={File}
        buttonTextLeft={t(`${translPrefix}.endModal.cancel`)}
        buttonTextRight={endAndArchive ? t(`${translPrefix}.endModal.endAndArchive`) : t(`${translPrefix}.endModal.end`)}
        handleButtonClose={() => { dispatch({ type: 'END_MODAL', endModal: false }); }}
        handleButtonRight={() => { endLease(); }}
        handleButtonLeft={() => { dispatch({ type: 'END_MODAL', endModal: false }); }}
        iconStyle={styles.endModal}
      />
    );
  }

  if (fetching) {
    return (<Spinner />);
  }

  return (
    <form className={styles.container} onSubmit={endLeaseButton} noValidate>

      {errorServer
          && <ErrorMessage message={t('error.errorMessage')} handleClose={() => setErrorServer(false)} />}

      <div className={styles.header}>
        <div className={styles.title}>
          <div className={classnames(styles.alertIcon, 'text__heading4__textNeutral50')}>{t(`${translPrefix}.title`)}</div>
          <Alert className={styles.errorContainer} />
        </div>
        <ButtonClose closeFn={() => { history.goBack(); }} closeText={t(`${translPrefix}.buttons.close`)} />
      </div>

      <div className={classnames(styles.addressSubtitle, 'text__heading5__textNeutral50')}>
        {`${state.lease.type === PropertyType.Building ? t('createTask.type.building') : t('createTask.type.condoHouse')} ${state.lease.address}`}
      </div>
      <div className="text__body__medium__textNeutral40">{t(`${translPrefix}.advice`)}</div>

      <div className={styles.endDate}>
        <InputDate
          containerClass={classnames(styles.input)}
          required
          id="endDate"
          name="endDate"
          label={t(`${translPrefix}.endDate`)}
          placeholder={t(`${translPrefix}.endDate`)}
          value={endDate ? dayjs(endDate).toDate() : undefined}
          onChangeFn={(date: Date) => setEndDate(dayjs(date).format('YYYY-MM-DD'))}
          helperText="DD/MM/YYYY"
          min={nextDate(state.lease.startDate)}
          formErrors={formErrors}
          t={t}
        />
      </div>

      {state.data.length !== 0 && (
        <>
          <div className={classnames(styles.assignAction, 'text__heading6__textNeutral40')}>{t(`${translPrefix}.assignAction`)}</div>

          <Table
            headerNames={headers}
            data={state.data}
            dataProperties={rentalProperties}
            dashboardName="endLease"
            iconCell={iconCells}
            uniqueId="rentalId"
          />
          {state.optionNotSelected && (
          <div className={classnames(styles.alertEmail, 'text__body__small__danger50')}>
            <AlertFilled className={styles.alertEmailIcon} />
            {t(`${translPrefix}.alertSelectAllOptions`)}
          </div>
          )}
        </>

      )}
      <div className={styles.footer}>
        <Button
          buttonType={ButtonType.Submit}
          buttonStyle={ButtonStyle.Primary}
          className={classnames(styles.endButton, 'text__button__medium__textNeutral50', endAndArchive ? styles.endAndArchive : '')}
        >
          {endAndArchive ? t(`${translPrefix}.buttons.endAndArchive`) : t(`${translPrefix}.buttons.end`)}
        </Button>
      </div>

    </form>
  );
};

export { EndLease };
