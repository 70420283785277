import { useRef, useState } from 'react';
import { useClickOutside } from '@mapix/common/src/hooks/click-outside';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'assets/icons/chevron-up.svg';
import { useTranslation } from 'react-i18next';
import { RentalActionCodes } from 'common/enums';
import { ReactComponent as Trash } from 'assets/icons/thrash.svg';
import { ModalResult } from 'common/modal-result';
import { RegisterRentalPayment } from 'common/register-rental-payment';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import styles from './rental-actions.module.scss';

type RentalActionsProps = {
  onClickOption: (option: string, rentalId: number, payment: string) => void,
  code?: string,
  rentalId: number,
};

const rentalActions = [
  { code: RentalActionCodes.RegisterPayment },
  { code: RentalActionCodes.KeepStatus },
  { code: RentalActionCodes.DeleteRental },
];

const translPrefix = 'endLease';

const RentalActions = ({
  onClickOption, code, rentalId,
}: RentalActionsProps) => {
  const [show, setShow] = useState(false);
  const [registerPaymentModal, setRegisterPaymentModal] = useState(false);
  const [deleteRentalModal, setDeleteRentalModal] = useState(false);
  const [errorServer, setErrorServer] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string>(code
    || RentalActionCodes.SelectAction);
  const containerRef = useRef(null);
  const { t } = useTranslation();

  useClickOutside(containerRef, () => setShow(false));

  const onClickPaymentDate = (date: string) => {
    setSelectedValue('registerPayment');
    onClickOption('registerPayment', rentalId, date);
  };

  const onClick = (option: string) => {
    setShow(false);
    if (option === 'registerPayment') {
      setRegisterPaymentModal(true);
    } else if (option === 'deleteRental') {
      setDeleteRentalModal(true);
    } else {
      setSelectedValue(option);
      onClickOption(option, rentalId, '');
    }
  };

  const deleteRentalOption = () => {
    setSelectedValue('deleteRental');
    onClickOption('deleteRental', rentalId, '');
    setDeleteRentalModal(false);
  };

  return (

    <div ref={containerRef} className={styles.selectContainer}>

      {errorServer
          && <ErrorMessage message={t('error.errorMessage')} handleClose={() => setErrorServer(false)} />}

      {registerPaymentModal && (
      <RegisterRentalPayment
        paymentFn={onClickPaymentDate}
        closeFn={() => setRegisterPaymentModal(false)}
        rentalId={rentalId}
      />
      )}
      {deleteRentalModal && (
      <ModalResult
        title={t(`${translPrefix}.deleteRental.title`)}
        subtitle={t(`${translPrefix}.deleteRental.subtitle`)}
        Icon={Trash}
        buttonTextRight={t(`${translPrefix}.deleteRental.delete`)}
        buttonTextLeft={t(`${translPrefix}.deleteRental.back`)}
        handleButtonRight={deleteRentalOption}
        handleButtonLeft={() => setDeleteRentalModal(false)}
        handleButtonClose={() => setDeleteRentalModal(false)}
        iconStyle={styles.iconDelete}
      />
      )}
      <Button
        buttonStyle={ButtonStyle.Secondary}
        onClick={() => setShow(true)}
        className={styles.button}
      >
        <div className={styles.buttonContent}>
          {t(`${translPrefix}.rentalActions.${selectedValue}`)}
          {!show
            ? <ChevronDown className={styles.chevron} />
            : <ChevronUp className={styles.chevron} /> }
        </div>
      </Button>
      { show
      && (
      <div className={styles.content}>
        {rentalActions.map((option) => (
          <button key={option.code} onClick={() => onClick(option.code)} type="button" className={styles.item}>
            <div className="text__body__small__textNeutral50">
              {t(`${translPrefix}.rentalActions.${option.code}`)}
            </div>
          </button>
        ))}
      </div>
      )}
    </div>
  );
};

export { RentalActions };
