import L from 'leaflet';
import { renderToString } from 'react-dom/server';
import { classnames } from '../../helpers/utils';
import { ReactComponent as Electricity } from '../../assets/icons/contractor-pins/electricity.svg';
import { ReactComponent as Plumbing } from '../../assets/icons/contractor-pins/plumbing.svg';
import { ReactComponent as Painting } from '../../assets/icons/contractor-pins/painting.svg';
import { ReactComponent as Plaster } from '../../assets/icons/contractor-pins/plaster.svg';
import { ReactComponent as Carpentry } from '../../assets/icons/contractor-pins/carpentry.svg';
import { ReactComponent as ConcreteSpecialist } from '../../assets/icons/contractor-pins/concrete-specialist.svg';
import { ReactComponent as Demolition } from '../../assets/icons/contractor-pins/demolition.svg';
import { ReactComponent as Drywaller } from '../../assets/icons/contractor-pins/drywaller.svg';
import { ReactComponent as ElevatorAndOtherLiftingDevices } from '../../assets/icons/contractor-pins/elevator-and-other-lifting-devices.svg';
import { ReactComponent as Excavator } from '../../assets/icons/contractor-pins/excavator.svg';
import { ReactComponent as FloorLaying } from '../../assets/icons/contractor-pins/floor-laying.svg';
import { ReactComponent as GardeningAndLandscape } from '../../assets/icons/contractor-pins/gardening-and-landscape.svg';
import { ReactComponent as GeneralContractor } from '../../assets/icons/contractor-pins/general-contractor.svg';
import { ReactComponent as GlassAndGlazing } from '../../assets/icons/contractor-pins/glass-and-glazing.svg';
import { ReactComponent as Handyman } from '../../assets/icons/contractor-pins/handyman.svg';
import { ReactComponent as HeatingAndAirConditioning } from '../../assets/icons/contractor-pins/heating-and-air-conditioner.svg';
import { ReactComponent as Masonry } from '../../assets/icons/contractor-pins/masonry.svg';
import { ReactComponent as Roofing } from '../../assets/icons/contractor-pins/roofing.svg';
import { ReactComponent as TileSetting } from '../../assets/icons/contractor-pins/tile-setting.svg';
import { ReactComponent as WallpaperInstaller } from '../../assets/icons/contractor-pins/wallpaper-installer.svg';
import { ReactComponent as DefaultIcon } from '../../assets/icons/contractor-pins/default.svg';
import { Priority, Specializations } from '../enums';
import styles from './pin-icon.module.scss';

const getPinIcon = (className: string, category?: Specializations) => {
  let SpecializationIcon = DefaultIcon;
  switch (category) {
    case Specializations.Electricity:
      SpecializationIcon = Electricity;
      break;
    case Specializations.Plumbing:
      SpecializationIcon = Plumbing;
      break;
    case Specializations.ConcreteSpecialist:
      SpecializationIcon = ConcreteSpecialist;
      break;
    case Specializations.Demolition:
      SpecializationIcon = Demolition;
      break;
    case Specializations.Drywaller:
      SpecializationIcon = Drywaller;
      break;
    case Specializations.ElevatorsAndOtherLiftingDevices:
      SpecializationIcon = ElevatorAndOtherLiftingDevices;
      break;
    case Specializations.Excavator:
      SpecializationIcon = Excavator;
      break;
    case Specializations.FloorLaying:
      SpecializationIcon = FloorLaying;
      break;
    case Specializations.GardeningAndLandscape:
      SpecializationIcon = GardeningAndLandscape;
      break;
    case Specializations.GeneralContractor:
      SpecializationIcon = GeneralContractor;
      break;
    case Specializations.GlassAndGlazing:
      SpecializationIcon = GlassAndGlazing;
      break;
    case Specializations.Handyman:
      SpecializationIcon = Handyman;
      break;
    case Specializations.HeatingAndAirConditioning:
      SpecializationIcon = HeatingAndAirConditioning;
      break;
    case Specializations.Masonry:
      SpecializationIcon = Masonry;
      break;
    case Specializations.Roofing:
      SpecializationIcon = Roofing;
      break;
    case Specializations.TileSetting:
      SpecializationIcon = TileSetting;
      break;
    case Specializations.WallpaperInstaller:
      SpecializationIcon = WallpaperInstaller;
      break;
    case Specializations.Carpentry:
      SpecializationIcon = Carpentry;
      break;
    case Specializations.Painting:
      SpecializationIcon = Painting;
      break;
    case Specializations.Plaster:
      SpecializationIcon = Plaster;
      break;
    default:
      break;
  }
  return <SpecializationIcon className={classnames(className, styles.pin)} />;
};

const getIconClassname = (priority?: Priority) => {
  switch (priority) {
    case Priority.EMERGENCY:
      return styles.red;
    case Priority.HIGH:
      return styles.yellow;
    default:
      return styles.purple;
  }
};

const pinIcon = (category?: Specializations, priority?: Priority) => {
  const icon = getPinIcon(getIconClassname(priority), category);
  const iconHTML = renderToString(icon);

  return new L.DivIcon({
    html: iconHTML,
    className: styles.pin,
    iconSize: new L.Point(30, 50),
  });
};

export { pinIcon };
