import { Checkbox } from '@mapix/common/src/common/checkbox';
import styles from './checkbox-filters.module.scss';

type CheckboxFiltersProps = {
  checkboxesOptions: string[],
  checkboxesSelected: string[],
  onClickCheckbox: (checkbox: string) => void,
};

const CheckboxFilters = ({
  checkboxesOptions, checkboxesSelected,
  onClickCheckbox,
}: CheckboxFiltersProps) => {
  const handleCheckbox = (checkbox: string) => {
    onClickCheckbox(checkbox);
  };

  return (
    <div className={styles.checkboxContainer}>
      {checkboxesOptions.map((checkbox) => (
        <div key={checkbox} className={styles.checkboxWrapper}>
          <Checkbox
            labelContent={checkbox}
            checked={checkboxesSelected.includes(checkbox)}
            onChangeFn={() => handleCheckbox(checkbox)}
            containerClass="text__body__medium__textNeutral40"
          />
        </div>
      ))}
    </div>
  );
};

export { CheckboxFilters };
