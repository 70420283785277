import { Button, ButtonStyle, ButtonType } from '@mapix/common/src/common/button';
import { Property } from 'models/property';
import { Unit } from 'models/unit';
import {
  ChangeEvent, FormEvent, useEffect, useState,
} from 'react';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { useTranslation } from 'react-i18next';
import {
  addError, checkHTMLErrors, HTMLValidationError, isEmptyObject,
} from '@mapix/common/src/helpers/utils';
import { Spinner } from '@mapix/common/src/common/spinner';
import { useHistory, useParams } from 'react-router-dom';
import { PropertyController } from 'networking/controllers/property-controller';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { goToPage, RouteName } from 'routes';
import { ReactComponent as CircleClose } from 'assets/icons/circleClose.svg';
import { ModalResult } from 'common/modal-result';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { CreateFooter } from 'common/creation';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import { EditCommon } from '../edit-property/edit-common';
import styles from './edit-unit.module.scss';

type ParamType = {
  unitId: string,
  propertyId: string,
};

type ModalState = {
  showClose: boolean,
  showOk: boolean
};

const initialModalState: ModalState = {
  showClose: false,
  showOk: false,
};

const UnitTranslPrefix = 'editUnit';

const EditUnit = () => {
  const { t } = useTranslation();
  const { propertyId, unitId } = useParams<ParamType>();
  const [modalState, setModalState] = useState<ModalState>(initialModalState);
  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});
  const [phoneErrors, setPhoneErrors] = useState<HTMLValidationError>({});
  const [errorServer, setErrorServer] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [property, setProperty] = useState<Property>();
  const [unit, setUnit] = useState(new Unit(null));
  const history = useHistory();

  const getProperty = async () => {
    try {
      setFetching(true);
      const result = await PropertyController.getDetailedProperty(Number(propertyId));
      setProperty(result);
      setFetching(false);
    } catch (error: any) {
      setFetching(false);
      if (error.code === 404) {
        goToPage(RouteName.ErrorPage);
      } else {
        setErrorServer(true);
      }
    }
  };

  const getUnit = async () => {
    try {
      setFetching(true);
      const result = await PropertyController.getUnit(Number(propertyId), Number(unitId));
      setUnit(result);
      setFetching(false);
    } catch (err: any) {
      setFetching(false);
      goToPage(RouteName.ErrorPage);
    }
  };

  useEffect(() => {
    getProperty();
    getUnit();
    setFetching(false);
  }, []);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newUnit: Unit = {
      ...unit,
      unitNumber: event.target.value,
    };
    setUnit(newUnit);
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    if (!target.checkValidity() || !unit.lease?.tenant.phoneNumber) {
      setFormErrors(checkHTMLErrors(target));
      if (!unit.lease?.tenant.phoneNumber) {
        addError(t('error.emptyField'), 'phoneNumber', setPhoneErrors);
      }
      return;
    }

    if (!isEmptyObject(phoneErrors)) {
      return;
    }

    try {
      setFetching(true);
      await PropertyController.editUnit(Number(propertyId), unit);
      await getProperty();
      setFetching(false);
      setModalState((prevState: any) => ({ ...prevState, showOk: true }));
    } catch (err: any) {
      setFetching(false);
      setErrorServer(true);
    }
  };

  if (fetching || !property) {
    return (<Spinner />);
  }

  return (
    <div className={styles.container}>
      {modalState.showClose
        && (
        <ModalResult
          Icon={CircleClose}
          title={t('editBuilding.modalClose.title')}
          subtitle={t('editBuilding.modalClose.subtitle')}
          handleButtonRight={() => history.goBack()}
          buttonTextRight={t('editBuilding.modalClose.yes')}
          handleButtonClose={() => setModalState((prevState: any) => ({
            ...prevState, showClose: false,
          }))}
          buttonTextLeft={t('editBuilding.modalClose.cancel')}
          handleButtonLeft={() => setModalState((prevState: any) => ({
            ...prevState,
            showClose: false,
          }))}
          iconStyle={styles.circleCloseIcon}
        />
        )}
      {modalState.showOk && (
        <ModalResult
          Icon={Edit}
          title={t('editUnit.modalOk.title')}
          subtitle={t('editUnit.modalOk.subtitle')}
          handleButtonRight={() => history.goBack()}
          buttonTextRight={t('editUnit.modalOk.ok')}
          handleButtonClose={() => history.goBack()}
          withCheckIcon
        />
      )}
      {errorServer
      && <ErrorMessage message={t('error.errorMessage')} handleClose={() => setErrorServer(false)} />}
      <div className={styles.topContainer}>
        <div className={styles.topText}>
          <h4 className="text__heading4__textNeutral50">
            {t('editUnit.title')}
          </h4>
          <h5 className="text__heading5__textNeutral50">
            {property.fullAddress}
          </h5>
        </div>
        <div className={styles.closeButton}>
          <Button
            buttonStyle={ButtonStyle.Secondary}
            onClick={() => setModalState((prevState: any) => ({
              ...prevState,
              showClose: true,
            }))}
          >
            <div className="row justify-between">
              {t('myProfile.close')}
              <Close className={styles.closeIcon} />
            </div>
          </Button>
        </div>
      </div>
      <form onSubmit={onSubmit} noValidate>
        <Input
          type="text"
          id="unitNumber"
          name="unitNumber"
          label={t(`${UnitTranslPrefix}.unitNumber`)}
          value={unit.unitNumber}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
          inputStyle={InputStyle.FORM}
          containerClass={styles.input}
          t={t}
        />
        <EditCommon
          property={unit}
          setProperty={() => null}
          formErrors={{ ...formErrors, ...phoneErrors }}
          setUnit={setUnit}
          setPhoneErrors={setPhoneErrors}
        />
        <CreateFooter
          nextButtonType={ButtonType.Submit}
          nextName={t('editBuilding.save')}
          Icon={Check}
          iconStyle={styles.checkIcon}
        />
      </form>
    </div>
  );
};
export { EditUnit };
