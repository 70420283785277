import { useTranslation } from 'react-i18next';
import { FormEvent, useEffect, useState } from 'react';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { logger } from 'helpers/logger';
import { Spinner } from '@mapix/common/src/common/spinner';
import { ElectronicDebitCreditAuthorization as CommonElectronicDebitCreditAuthorization } from '@mapix/common/src/common/payment-information/pages/electronic-debit-credit-authorization';
import type { ElectronicDebitCreditAuthorizationType, RawElectronicDebitCreditAuthorization } from '@mapix/common/src/types/payment-information';
import { goToPage, RouteName } from 'routes';
import { checkHTMLErrors, isEmptyObject } from '@mapix/common/src/helpers/utils';
import { PaymentInformationController } from 'networking/controllers/payment-information-controller';
import { ApiError, ErrorStatus } from 'models/api-error';
import { PaymentInformationSerializer } from 'networking/serializers/payment-information-serializer';
import { RawErrorFields } from '@mapix/common/src/types';

const translPrefix = 'paymentInformation.electronicDebitCreditAuthorizationInformation';

const initSalesProfile = (): ElectronicDebitCreditAuthorizationType => ({
  differentAccountDepositsWithdrawals: false,
  accountHolderName: '',
  institutionId: '',
  institution: '',
  transit: '',
  account: '',
  postalCode: '',
  withdrawalAccountHolderName: '',
  withdrawalInstitutionId: '',
  withdrawalInstitution: '',
  withdrawalPostalCode: '',
  withdrawalTransit: '',
});

type SelectOptionsState = {
  institution: string[];
  withdrawalInstitution: string[];
};

const initialSelectOptionsState = {
  institution: [],
  withdrawalInstitution: [],
};

const ElectronicDebitCreditAuthorization = () => {
  // TODO payment information remove this redirection
  goToPage(RouteName.ErrorPage);

  const { t } = useTranslation();
  const [fetching, setFetching] = useState(true);
  const [showError, setShowError] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [electronicDebitCreditAuthorization, setElectronicDebitCreditAuthorization] = useState<
  ElectronicDebitCreditAuthorizationType>(initSalesProfile());
  const [emptyForm, setEmptyForm] = useState(false);
  const [filteredSelectOptions, setFilteredeSelectOptions] = useState<SelectOptionsState>(
    initialSelectOptionsState,
  );
  const [bankInstitutionMap, setBankInstitutionMap] = useState(new Map());

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;

    if (!target.checkValidity()) {
      setFormErrors(checkHTMLErrors(target));
      return;
    }

    if (!isEmptyObject(formErrors)) {
      return;
    }

    setFetching(true);
    try {
      if (electronicDebitCreditAuthorization.institution) {
        electronicDebitCreditAuthorization.institutionId = bankInstitutionMap
          .get(electronicDebitCreditAuthorization.institution);
      }

      if (electronicDebitCreditAuthorization.withdrawalInstitution) {
        electronicDebitCreditAuthorization.withdrawalInstitutionId = bankInstitutionMap
          .get(electronicDebitCreditAuthorization.withdrawalInstitution);
      }
      if (emptyForm) {
        await PaymentInformationController.createElectronicDebitCreditAuthorization(
          electronicDebitCreditAuthorization,
        );
      } else {
        await PaymentInformationController.updateElectronicDebitCreditAuthorization(
          electronicDebitCreditAuthorization,
        );
      }
      setFetching(false);
      goToPage(RouteName.ManagerPaymentInformation);
    } catch (error) {
      if (error instanceof ApiError && typeof error.details === 'object') {
        setFormErrors(PaymentInformationSerializer
          .deSerializeElectronicDebitCreditAuthorizationErrors(
            error.details as RawErrorFields<RawElectronicDebitCreditAuthorization>,
            t,
          ));
      } else {
        setShowError(true);
        logger.error(error as Error);
      }
      setFetching(false);
    }
  };

  const getBankInstitutions = async () => {
    try {
      const bankInstitutions = await PaymentInformationController.getBankInstitutions();
      const actualBankInstitutionMap = new Map(bankInstitutions.map((bank) => [`${bank.number} - ${bank.name}`, bank.id]));
      setBankInstitutionMap(actualBankInstitutionMap);
      const bankInstitutionsOptions = Array.from(actualBankInstitutionMap.keys());
      setFilteredeSelectOptions({
        institution: bankInstitutionsOptions,
        withdrawalInstitution: bankInstitutionsOptions,
      });
    } catch (e) {
      logger.error(e as Error);
    }
  };

  const getElectronicDebitCreditAuthorization = async () => {
    try {
      const actualElectronicAuthorization = await PaymentInformationController
        .getElectronicDebitCreditAuthorization();

      setElectronicDebitCreditAuthorization(actualElectronicAuthorization);
    } catch (e) {
      if (e instanceof ApiError && e.status === ErrorStatus.NotFound) {
        setEmptyForm(true);
      } else {
        logger.error(e as Error);
        setShowError(true);
      }
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    getBankInstitutions();
    getElectronicDebitCreditAuthorization();
  }, []);

  if (fetching) {
    return (<Spinner />);
  }

  return (
    <>
      {showError
      && (
        <ErrorMessage
          message={t('error.errorMessage')}
          handleClose={() => setShowError(false)}
        />
      )}
      <CommonElectronicDebitCreditAuthorization
        t={t}
        backFn={() => goToPage(RouteName.ManagerPaymentInformation)}
        electronicDebitCreditAuthorization={electronicDebitCreditAuthorization}
        setElectronicDebitCreditAuthorization={setElectronicDebitCreditAuthorization}
        formErrors={formErrors}
        onSubmit={onSubmit}
        setFormErrors={setFormErrors}
        translPrefix={translPrefix}
        filteredSelectOptions={filteredSelectOptions}
        goToHomeFn={() => goToPage(RouteName.Home)}
        goToMyProfileFn={() => goToPage(RouteName.MyProfile)}
        goToPaymentInformationFn={() => goToPage(RouteName.ManagerPaymentInformation)}
      />

    </>
  );
};

export { ElectronicDebitCreditAuthorization };
