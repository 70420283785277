/* eslint-disable react/jsx-props-no-spreading */
import { HTMLProps } from 'react';
import { useMediaQuery } from '../../hooks/use-media-query';
import { Breakpoints } from '../enums';
import { classnames, helperTextFn, HTMLValidationError } from '../../helpers/utils';
import styles from './textarea.module.scss';

type InputProps = {
  id: string,
  helperText?: string,
  containerClass?: string,
  error?: boolean,
  disabled?: boolean,
  formError?: HTMLValidationError,
  maxLength?: number,
  value: string,
  placeholder?: string,
  required?: boolean
  t: (text: string) => string,
};

function handleInputClass(error?: boolean, disabled?: boolean) {
  if (disabled) {
    return styles.disabled;
  }
  if (error) {
    return styles.formError;
  }
  return styles.form;
}

const TextArea = ({
  id, label, helperText = '', t,
  containerClass = '', error, formError, maxLength, disabled, value, placeholder = '', required = false, ...props
}: InputProps & HTMLProps<HTMLTextAreaElement>) => {
  /* TODO: Delete this once we unified the error handling */
  const hasFormError = !!(formError && formError[id]);

  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  return (
    <>
      <div className={classnames(styles.textContainer, containerClass)}>
        <textarea
          disabled={disabled}
          required={required}
          type="text"
          id={id}
          maxLength={maxLength}
          {...props}
          className={classnames(
            handleInputClass(error || hasFormError),
            `text__body__${mobile ? 'large' : 'medium'}__textNeutral50`,
          )}
          value={value}
          placeholder={placeholder}
        />

        {(helperText || !required || hasFormError) && (
          <div
            className={classnames(styles.helperLabel, error || hasFormError
              ? 'text__body__tiny__danger50' : 'text__body__tiny__textNeutral40')}
          >
            {hasFormError ? formError[id] : helperTextFn(required, helperText, t, disabled)}
          </div>
        )}

        {maxLength && (
        <div
          className={classnames(styles.maxLength, error || hasFormError
            ? 'text__body__tiny__danger50' : 'text__body__tiny__textNeutral40')}
        >
          {`${value.length} / ${maxLength} `}
        </div>
        )}

      </div>

    </>
  );
};

export { TextArea };
export type { InputProps };
