import { RawNewBillStatus } from '@mapix/common/src/common/enums';
import { NewBillDetail } from 'networking/types/bill-detail';
import { initAddress } from '@mapix/common/src/initial-states/address-initial-state';

export const initBillDetail: NewBillDetail = {
  additionalComments: '',
  address: initAddress,
  billedAmount: 0,
  buildingName: '',
  currency: '',
  id: 0,
  managerLastName: '',
  managerName: '',
  processingFees: 0,
  paymentDate: '',
  totalAmount: 0,
  dueDate: '',
  status: RawNewBillStatus.AWAITING,
};
