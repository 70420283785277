import { classnames } from '@mapix/common/src/helpers/utils';
import { ReactComponent as PropertyImg } from 'assets/images/PropertyEllipse.svg';
import { ReactComponent as CondoHouseImg } from 'assets/images/CondoHouseEllipse.svg';
import { useEffect, useState } from 'react';
import { CreateHeader } from 'common/creation';
import { PropertyType } from 'common/enums';
import { useTranslation } from 'react-i18next';
import { PropertyController } from 'networking/controllers/property-controller';
import { logger } from 'helpers/logger';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { Spinner } from '@mapix/common/src/common/spinner';
import { DetailedDraftProperty } from 'models/detailed-draft-property';
import { useHistory } from 'react-router-dom';
import { CreatePropertyBuilding } from './create-building/create-property-building';
import { CreatePropertyCard } from './create-property-card';
import { CreatePropertyCondo } from './create-condo/create-property-condo';
import styles from './create-property.module.scss';

type CreatePropertyState = {
  code: string,
  propertyType?: PropertyType,
  showError: boolean,
  property?: DetailedDraftProperty
};

const initialState: CreatePropertyState = {
  code: 'INITIAL',
  showError: false,
};

const translPrefix = 'createBuilding';
const genericTranslPrefix = 'createGeneric';
const translError = 'error';

const CreatePropertyContainer = () => {
  const [state, setState] = useState(initialState);
  const history = useHistory();
  const { t } = useTranslation();

  const toggleErrorModal = () => {
    setState((prevState) => ({ ...prevState, showError: !state.showError }));
  };

  const getProperty = async (id: string) => {
    setState((prevState) => ({ ...prevState, code: 'FETCHING' }));
    try {
      const fetchedProperty = await PropertyController.getDraftProperty(Number(id));
      setState((prevState) => (
        {
          ...prevState,
          propertyType: fetchedProperty.type as PropertyType,
          property: new DetailedDraftProperty(fetchedProperty),
          code: 'CREATING',
        }));
    } catch (err: any) {
      logger.error(err);
      toggleErrorModal();
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get('id');

    if (id) {
      getProperty(id);
    }
  }, []);

  const onClickProperty = (type: PropertyType) => {
    setState({ ...state, propertyType: type, code: 'CREATING' });
  };

  const close = () => {
    history.goBack();
  };

  if (state.code === 'FETCHING') {
    return (<Spinner />);
  }

  return (
    <div className={classnames(styles.container, 'column')}>

      {state.showError && (
        <ErrorMessage
          message={t(`${translError}.errorMessage`)}
          handleClose={() => toggleErrorModal()}
        />
      )}

      {state.code === 'INITIAL' && (
        <>
          <CreateHeader
            closeText={t(`${genericTranslPrefix}.close`)}
            closeFn={close}
            title={t(`${translPrefix}.header`)}
          />

          <div className={classnames(styles.subTitle, 'text__heading5__textNeutral50')}>
            {t(`${translPrefix}.title`)}
          </div>
          <div className={classnames(styles.cardRow, 'row')}>
            <CreatePropertyCard
              onClickFn={() => onClickProperty(PropertyType.Building)}
              ImgSvg={PropertyImg}
              title={t(`${translPrefix}.card.building`)}
              subTitle={t(`${translPrefix}.card.buildingSubtitle`)}
            />
            <CreatePropertyCard
              onClickFn={() => onClickProperty(PropertyType.CondoHouse)}
              ImgSvg={CondoHouseImg}
              title={t(`${translPrefix}.card.condo`)}
              subTitle={t(`${translPrefix}.card.condoSubtitle`)}
            />
          </div>
        </>
      )}

      {state.code === 'CREATING' && state.propertyType === PropertyType.Building
      && <CreatePropertyBuilding fetchedProperty={state.property} />}
      {state.code === 'CREATING' && state.propertyType === PropertyType.CondoHouse
      && <CreatePropertyCondo fetchedProperty={state.property} />}

    </div>
  );
};

export { CreatePropertyContainer };
