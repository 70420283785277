import { classnames } from '@mapix/common/src/helpers/utils';
import styles from './privacy-policy.module.scss';

const PrivacyPolicyFr = () => (
  <div className={styles.container}>
    <p className="text__body__medium__textNeutral50">
      Last update: 21/12/2021
    </p>
    <h1 className={classnames(styles.title, 'text__heading2__textNeutral50')}>
      Privacy Policy
    </h1>
    <p className="text__body__medium__textNeutral50">
      This policy describes how PAIR IT TECHNOLOGIES INC. (“Pair It”, “we”) collects, processes,
      and uses information and data about users of the Pair It platform (the “Platform”).
    </p>
    <p className="text__body__medium__textNeutral50">
      The contact details of the person responsible for data processing under this policy
      are as follows:
    </p>
    <div className={styles.withoutSpace}>
      <p className={classnames(styles.bold, 'text__body__medium__textNeutral50')}>
        PAIR IT TECHNOLOGIES INC.
      </p>
      <p className="text__body__medium__textNeutral50">
        1050, Côte du Beaver Hall, #1500
      </p>
      <p className="text__body__medium__textNeutral50">
        Montreal (Quebec) Canada H2Z 0A5
      </p>
      <p className="text__body__medium__textNeutral50">
        info@mapix.io
      </p>
    </div>
    <h2 className="text__heading4__textNeutral50">
      Our Processing of Personal Data
    </h2>
    <p className="text__body__medium__textNeutral50">
      In this policy, the term “Personal Data” refers to any information that
      relates to an identified
      or identifiable living individual. Different pieces of information,
      which collected can lead to the identification of a particular person,
      also constitute personal data.
    </p>
    <p className="text__body__medium__textNeutral50">
      To provide our services and allow you to use the Platform,
      we must collect and process some Personal Data. Our processing is based
      upon the following principles:
    </p>
    <p>
      <ul>
        <li className="text__body__medium__textNeutral50">
          We must process some Personal Data in order to provide you with services;
        </li>
        <li className="text__body__medium__textNeutral50">
          We process Personal Data on behalf of our clients and pursuant to their instructions.
          In most cases, our client is your employer, which will be better suited than us to
          address your concerns about the processing of your Personal Data through the Platform;
        </li>
        <li className="text__body__medium__textNeutral50">
          We process Personal Data for the purpose of our legitimate commercial interests and
          that of our clients when such processing is not likely to infringe on fundamental rights
          or to cause significant harm to individuals.
        </li>
      </ul>
    </p>
    <h2 className="text__heading4__textNeutral50">
      Collected Personal Data
    </h2>
    <p className="text__body__medium__textNeutral50">
      We collect and process the data described below in accordance with this policy.
    </p>
    <p className={classnames(styles.bold, 'text__body__medium__textNeutral50')}>
      Data collected from the User
    </p>
    <p className="text__body__medium__textNeutral50">
      We collect information that users provide to us voluntarily through the
      interface of the Platform (for example, by completing a form) or otherwise.
      This information may also be provided by a third party such as your employer.
    </p>
    <p className="text__body__medium__textNeutral50">
      This information may include the following:
    </p>
    <div className={styles.tableContainer}>
      <table className={styles.tableContent}>
        <tr>
          <td className={classnames(styles.titles, 'text__body__medium__textNeutral50')}>Data Category</td>
          <td className={classnames(styles.titles, 'text__body__medium__textNeutral50')}>Purpose of processing</td>
        </tr>
        <tr>
          <td>
            <ul>
              <li className="text__body__medium__textNeutral50">Name;</li>
              <li className="text__body__medium__textNeutral50">Email address;</li>
              <li className="text__body__medium__textNeutral50">Address;</li>
            </ul>
          </td>
          <td className="text__body__medium__textNeutral50">
            This data serves to identify you and allows
            us to contact you about your use of the Platform.
          </td>
        </tr>
        <tr>
          <td className="text__body__medium__textNeutral50">
            Documents or data that you upload to the Platform;
          </td>
          <td className="text__body__medium__textNeutral50">
            This data is associated with your profile
            in order to allow you to use the features of the Platform.
          </td>
        </tr>
        <tr>
          <td className="text__body__medium__textNeutral50">
            Any information you provide us through the Platform, by email or by phone.
          </td>
          <td className="text__body__medium__textNeutral50">
            This data is used to better understand how you use
            the Platform and to answer your questions, if any.
          </td>
        </tr>
      </table>
    </div>
    <h3 className={classnames(styles.bold, 'text__body__medium__textNeutral50')}>
      Data collected automatically
    </h3>
    <p className="text__body__medium__textNeutral50">
      We automatically collect certain information during your use of the Platform.
      This data may be recorded each time you interact with the Platform and includes:
    </p>
    <div className={styles.tableContainer}>
      <table className={styles.tableContent}>
        <tr>
          <td className={classnames(styles.titles, 'text__body__medium__textNeutral50')}>Category of Data</td>
          <td className={classnames(styles.titles, 'text__body__medium__textNeutral50')}>Purpose of processing</td>
        </tr>
        <tr>
          <td>
            <p className="text__body__medium__textNeutral50">
              Your computer’s IP address;
            </p>
            <p className="text__body__medium__textNeutral50">
              Your browser’s and operating system’s configuration;
            </p>
            <p className="text__body__medium__textNeutral50">
              Your mobile device’s configuration when you access the Platform from a mobile device;
            </p>
          </td>
          <td className="text__body__medium__textNeutral50">
            This data is used to personalize your experience on the Platform,
            for example by loading an interface with a specific language
            or with certain highlighted features.
          </td>
        </tr>
        <tr>
          <td className="text__body__medium__textNeutral50">
            Data about your use of the Platform, such as pages viewed,
            use of features, time spent on pages, events recorded;
          </td>
          <td className="text__body__medium__textNeutral50">
            We use this data to analyze user behavior on the Platform and improve our services.
            We use this data to provide our clients with information about
            Platform use by their employees.
          </td>
        </tr>
      </table>
    </div>
    <h3 className={classnames(styles.bold, 'text__body__medium__textNeutral50')}>
      Cookies
    </h3>
    <p className="text__body__medium__textNeutral50">
      We use cookies in order to improve the performance of the Platform and your experience.
      Cookies are small files stored on your hard drive by the server on which the Platform is
      located that allow us to recognize you and customize content when you use the Platform.
      Certain cookies are automatically erased when you close your browser (session cookies) while
      others are stored indefinitely (permanent cookies). If you prefer not to have cookies recorded
      on your device, you must disable cookies in your browser settings.
      Some features of the Platform may be entirely or partially disabled if cookies are disabled.
    </p>
    <h2 className="text__heading4__textNeutral50">
      Purpose of Processing
    </h2>
    <p className="text__body__medium__textNeutral50">
      We use collected Personal Data as set forth in the table above.
    </p>
    <h3 className={classnames(styles.bold, 'text__body__medium__textNeutral50')}>
      Our role as a service provider
    </h3>
    <p className="text__body__medium__textNeutral50">
      As part of our services, we process Personal Data on behalf or our clients for
      purposes determined by them. To learn more about our processing of Personal
      Data based on instructions from our clients, you must contact our client
      (generally your employer) directly.
    </p>
    <h3 className={classnames(styles.bold, 'text__body__medium__textNeutral50')}>
      Legitimate commercial interests
    </h3>
    <p className="text__body__medium__textNeutral50">
      We process collected Personal Data for the purpose of our legitimate
      interests and that of our clients,
      including to improve the Platform and to prevent fraud by analyzing user behavior.
    </p>
    <p className="text__body__medium__textNeutral50">
      We value your privacy. Except in response to specific instructions from our client,
      our processing of
      Personal Data never involves the monitoring or targeting of a specific
      individual nor the disclosure of
      Personal Data about a specific individual.
    </p>
    <h3 className={classnames(styles.bold, 'text__body__medium__textNeutral50')}>
      Contacting You
    </h3>
    <p className="text__body__medium__textNeutral50">
      We use the personal data you provided us (for example your name and email address)
      to contact you about
      your use of the Platform, but only if you have provided such data and if you agree to receive
      communications from us. We abide by applicable regulations with respect to unwanted
      electronic communications.
      If you no longer wish to receive electronic communications from us, you may
      notify us at any time at the e-mail address provided at the beginning of this policy.
    </p>
    <h3 className={classnames(styles.bold, 'text__body__medium__textNeutral50')}>
      Platform Maintenance
    </h3>
    <p className="text__body__medium__textNeutral50">
      We use data collected through the Platform and data collected from analytics tools to monitor
      usage of the Platform generally, to prevent misuse of the Platform, to identify problems or
      bugs in the Platform and to determine which features we should improve.
    </p>
    <h2 className="text__heading4__textNeutral50">
      Storage and Security
    </h2>
    <p className="text__body__medium__textNeutral50">
      We store the collected Personal Data until our client asks us to destroy it or up to one (1)
      year following the termination of our contract with our client. However, we
      do not make any commitment to store collected Personal Data
      for a specific period.
    </p>
    <p className="text__body__medium__textNeutral50">
      Collected Personal Data is stored electronically on
      servers operated by our hosting provider, Amazon. Data is stored on servers located in
      Ohio or in accordance with our agreement with our hosting provider.
    </p>
    <p className="text__body__medium__textNeutral50">
      Our hosting provider use industry-standard measures to ensure that our data is kept secure.
      To learn more about the security measures at Amazon, visit
      {' '}
      <a
        className="text__body__medium__textNeutral50"
        href="https://aws.amazon.com/privacy/?nc1=h_ls"
      >
        {' '}
        https://aws.amazon.com/privacy/?nc1=h_ls.

      </a>
      {' '}
      Our employees are informed of the confidential nature of Personal Data and undertake
      to handle Personal  Data in accordance with this policy.
    </p>
    <p className="text__body__medium__textNeutral50">
      Collected Personal Data about individuals outside Canada and the United States
      may be transferred to Canada or the United States.
    </p>
    <h2 className="text__heading4__textNeutral50">
      Disclosure of Personal Data
    </h2>
    <p className="text__body__medium__textNeutral50">
      We only disclose your Personal Data in accordance with this policy or if we have
      your consent. Your Personal Data could be disclosed to the
      categories of recipients listed below for the purposes described.
    </p>
    <h3 className={classnames(styles.bold, 'text__body__medium__textNeutral50')}>
      Your Employer
    </h3>
    <p className="text__body__medium__textNeutral50">
      Your employer may access Personal Data recorded on the Platform. Some Personal Data
      may be shared within your organization as determined by your employer or administrative
      users, as the case may be. The Platform allows the export of customer data, which could
      contain Personal Data.
    </p>
    <h3 className={classnames(styles.bold, 'text__body__medium__textNeutral50')}>
      Service Providers
    </h3>
    <p className="text__body__medium__textNeutral50">
      We share some Personal Data with our service providers in order to better offer
      our services. Our service providers agree in writing to keep Personal Data confidential
      and to use same solely to provide us with their services. Personal Data under the
      control of our clients is not shared with our service providers without the client’s consent.
    </p>
    <p className="text__body__medium__textNeutral50">
      Currently, we share Personal Data with the following service providers. Service providers
      are subject to change at our discretion.
    </p>
    <p className="text__body__medium__textNeutral50">
      Name: Amazon
    </p>
    <p className="text__body__medium__textNeutral50">
      Address:
      {' '}
      <a
        className="text__body__medium__textNeutral50"
        href="https://aws.amazon.com/privacy/?nc1=h_ls"
      >
        https://www.mapix.io/
      </a>
    </p>
    <p className="text__body__medium__textNeutral50">
      Purpose: Storage data for supporting Mapix platform
    </p>
    <h3 className={classnames(styles.bold, 'text__body__medium__textNeutral50')}>
      Clients
    </h3>
    <p className="text__body__medium__textNeutral50">
      We share data in an aggregated form which does not identify specific
      individuals with clients and potential clients for marketing purposes.
      This data includes statistics about the Platform users and their behavior
      while using the Platform.
    </p>
    <h3 className={classnames(styles.bold, 'text__body__medium__textNeutral50')}>
      Legal Obligations
    </h3>
    <p className="text__body__medium__textNeutral50">
      We could disclose Personal Data if such disclosure is
      expressly authorized by law or pursuant to an order by a competent authority. We may
      disclose Personal Data in the context of judicial proceedings if necessary to protect our
      rights or that of our users.
    </p>
    <h3 className={classnames(styles.bold, 'text__body__medium__textNeutral50')}>
      Sale of Business
    </h3>
    <p className="text__body__medium__textNeutral50">
      In the event that the sale or restructuring of the business operated by Pair It is
      envisioned (a &quot;Transaction&quot;), we may disclose
      collected Personal Data to the individuals
      or organizations involved before and after the Transaction, whether the
      Transaction is finalized or not. In such case, these individuals or
      organizations shall make a commitment to us to maintain
      the confidentiality of the disclosed Personal Data and to use it exclusively to assess the
      feasibility or desirability of the Transaction, or in accordance with this policy.
    </p>
    <h2 className="text__heading4__textNeutral50">
      Your Rights
    </h2>
    <h3 className={classnames(styles.bold, 'text__body__medium__textNeutral50')}>
      Access to Personal Data
    </h3>
    <p className="text__body__medium__textNeutral50">
      You can access some Personal Data you provided us by accessing your user account
      on the Platform. You may also request access to Personal Data that we hold
      about you in writing at the email address or at the postal address provided
      at the beginning of this policy. To access or modify some Personal Data
      recorded using the Platform, you must contact your employer directly.
    </p>
    <p className="text__body__medium__textNeutral50">
      We will respond to your written request within thirty (30) days of its receipt. We
      will allow you reasonable access to your Personal Data contained in our records in
      accordance with applicable law. We may direct you to your employer in cases where
      your Personal Data is kept and processed by Pair It on behalf of your employer.
    </p>
    <h3 className={classnames(styles.bold, 'text__body__medium__textNeutral50')}>
      Withdrawal of Consent
    </h3>
    <p className="text__body__medium__textNeutral50">
      You may withdraw your consent to the processing of some Personal Data, where
      such processing is based on your consent. If your consent is provided through
      your employer, you must contact your employer directly to withdraw your consent.
    </p>
    <p className="text__body__medium__textNeutral50">
      If you wish to withdraw your consent to the processing of Personal Data over
      and above what is possible through the Platform interface or your device
      settings, you may notify us in writing at the email address indicated at the
      beginning of this policy. The only way to stop all processing of your Personal
      Data is to stop using the Platform entirely.
    </p>
    <h3 className={classnames(styles.bold, 'text__body__medium__textNeutral50')}>
      Complaints
    </h3>
    <p className="text__body__medium__textNeutral50">
      This policy is governed by the laws and regulations in force in Canada and the
      Canadian authorities shall be exclusively competent to hear complaints based on
      this policy or regarding the processing of your Personal Data by Pair It.
    </p>
    <h3 className={classnames(styles.bold, 'text__body__medium__textNeutral50')}>
      Identity Verification
    </h3>
    <p className="text__body__medium__textNeutral50">
      We may verify the identity of a person who wishes to access Personal
      Data. Information provided to verify identity will not be used for other purposes.
    </p>
    <h3 className={classnames(styles.bold, 'text__body__medium__textNeutral50')}>
      Amendment of Policy
    </h3>
    <p className="text__body__medium__textNeutral50">
      This policy may be amended to reflect changes in our Personal Data processing
      practices. If an amendment is made, the new policy will be available using the
      Platform and on our website.
    </p>
    <p className="text__body__medium__textNeutral50">
      We will notify you of any material changes in this policy by email prior
      to the effective policy change. Any notice will be sent to the email address
      you provided in the Platform or posted directly on the Platform.
    </p>
    <h3 className={classnames(styles.bold, 'text__body__medium__textNeutral50')}>
      Additional information
    </h3>
    <p className="text__body__medium__textNeutral50">
      For additional information on how we handle user data, including confidential
      information and Personal Data, you may contact us using contact information
      provided at the beginning of this policy.
    </p>
  </div>
);

export { PrivacyPolicyFr };
