import { ChangeEvent, FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HTMLValidationError, checkHTMLErrors, classnames } from '../../helpers/utils';
import { ReactComponent as Tool } from '../../assets/icons/tool.svg';
import { ReactComponent as Cross } from '../../assets/icons/cross.svg';
import { TextArea } from '../textarea';

import { Button, ButtonStyle, ButtonType } from '../button';
import { Modal } from '../modal';
import styles from './modal-rejection.module.scss';

type ModalProps = {
  close: () => void,
  onSubmit: (reason: string) => void,
  translPrefix: string,
  handleButtonLeft: () => void,
};

const ModalRejection = ({
  close, onSubmit, translPrefix, handleButtonLeft,
}: ModalProps) => {
  const { t } = useTranslation();
  const [reason, setReason] = useState('');
  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setReason(event.target.value);
  };

  const handleSend = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const target = event.target as HTMLFormElement;
    if (!target.checkValidity()) {
      setFormErrors(checkHTMLErrors(target));
      return;
    }
    onSubmit(reason);
  };

  return (
    <Modal>
      <form onSubmit={handleSend} noValidate>
        <div className={styles.modalContainer}>
          <div className={styles.crossContainer}>
            <button type="button" onClick={() => close()}>
              <Cross className={styles.crossIcon} />
            </button>
          </div>
          <div className={styles.centered}>
            <div className={styles.containerIcon}>
              <Tool className={styles.icon} />
            </div>
            <p className="text__heading5__textNeutral50">
              {t(`${translPrefix}.title`)}
            </p>
          </div>
          <p className={classnames(styles.subtitle, 'text__heading6__textNeutral50')}>
            {t(`${translPrefix}.subtitle`)}
          </p>
          <TextArea
            id="message"
            name="message"
            t={t}
            value={reason}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleChange(e)}
            placeholder={t(`${translPrefix}.textAreaPlaceholder`)}
            maxLength={200}
            containerClass={styles.input}
            required
            formError={formErrors}
          />

          <div className={styles.buttons}>
            <Button
              buttonStyle={ButtonStyle.Secondary}
              className={classnames(styles.button, 'text__body__medium__textNeutral10')}
              onClick={handleButtonLeft}
            >
              {t(`${translPrefix}.buttonLeft`)}
            </Button>
            <Button
              className={classnames(styles.button, 'text__body__medium__textNeutral10')}
              buttonType={ButtonType.Submit}
            >
              {t(`${translPrefix}.buttonRight`)}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export { ModalRejection };
