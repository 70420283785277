import { FileType, RawFile } from '../../types';

class FileSerializer {
  static serialize(data: FileType): RawFile {
    return {
      url: data.url,
      filename: data.filename,
    };
  }

  static deSerialize(data: RawFile): FileType {
    return {
      url: data.url,
      filename: data.filename,
    };
  }
}

export { FileSerializer };
