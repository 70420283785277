import {
  useReducer, useState, useEffect, ChangeEvent, MouseEventHandler,
} from 'react';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import { addOrRemoveFromArrayObject, classnames } from '@mapix/common/src/helpers/utils';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { ReactComponent as Filter } from 'assets/icons/filter.svg';
import { ReactComponent as PlusCircle } from 'assets/icons/plus-circle.svg';
import { ReactComponent as Building } from 'assets/icons/building.svg';
import { Table } from 'common/table';
import { logger } from 'helpers/logger';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { PropertyController } from 'networking/controllers/property-controller';
import { ReactComponent as NoResultsIcon } from 'assets/icons/search-no-results-solid.svg';
import { Pagination } from '@mapix/common/src/common/paginator';
import { Modal } from '@mapix/common/src/common/modal';
import { Spinner } from '@mapix/common/src/common/spinner';
import { goToPage, RouteName } from 'routes';
import { FilterModal } from 'common/filter-modal';
import { ModalFilterItem } from 'common/filter-modal/filter-modal';
import { FiltersApplied } from 'common/filters-applied';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { EmptyState } from 'common/empty-state';
import { UnitOfAProperty } from 'models/unit-of-a-property';
import { Tooltip, TooltipPosition } from '@mapix/common/src/common/tooltip';
import { MoreIcon } from 'common/more-icon';
import { ReactComponent as Archive } from 'assets/icons/archive.svg';
import { ModalResult } from 'common/modal-result';
import { PropertyStatus } from 'common/enums';
import { initialState, UnitInfoTabReducer } from './unit-info-tab-reducer';

import styles from './unit-info-tab.module.scss';

type UnitInfoTabProps = {
  id: number,
  condition: string,
  unitsCount: number,
};

const translPrefix = 'unitsInfoTab';

const tableHeaderActive = ['unitNumber', 'status', 'condition', 'tenant', 'tenantContact'];
const UnitInfoTab = ({ id, condition, unitsCount }: UnitInfoTabProps) => {
  const [state, dispatch] = useReducer(UnitInfoTabReducer, initialState);
  const [fetching, setFetching] = useState(false);
  const [errorServer, setErrorServer] = useState(false);
  const { t } = useTranslation();

  const getMoreIcon = (options: string[], optionsFn: MouseEventHandler<HTMLButtonElement>[]) => (
    <MoreIcon iconClass={styles.icons} key="more-icon" options={options} optionsFn={optionsFn} />
  );

  const getUnits = async () => {
    try {
      setFetching(true);
      const response = await PropertyController
        .getUnitsOfAProperty(
          state.paginator?.currentPage || 1,
          10,
          id,
          state.appliedFilters,
          state.query,
        );
      dispatch({ type: 'UNITS_FETCHED', paginator: response });
      setFetching(false);
    } catch (err) {
      logger.error(err as Error);
      setFetching(false);
      setErrorServer(true);
    }
  };

  const archiveProperty = async () => {
    if (state.unitStatus === PropertyStatus.Available) {
      dispatch({ type: 'SHOW_ARCHIVE', showArchive: false });
      try {
        setFetching(true);
        await PropertyController.archiveUnit(id, state.unitId);
        setFetching(false);
        dispatch({ type: 'SUCCESSFULLY_ARCHIVED', successfullyArchived: true });
        getUnits();
      } catch (error: any) {
        setFetching(false);
        setErrorServer(true);
      }
    } else {
      goToPage(RouteName.EndLease, { id: Number(state.leaseId) }, { endAndArchive: true });
    }
  };

  const unarchiveProperty = async () => {
    dispatch({ type: 'SHOW_UNARCHIVE', showUnarchive: false });
    try {
      setFetching(true);
      await PropertyController.unarchiveUnit(id, state.unitId);
      setFetching(false);
      dispatch({ type: 'SUCCESSFULLY_UNARCHIVED', successfullyUnarchived: true });
      getUnits();
    } catch (error: any) {
      setFetching(false);
      setErrorServer(true);
    }
  };

  const goToUnitDashboard = async (item: UnitOfAProperty) => {
    goToPage(RouteName.UnitDetail, { id, unitId: item.id });
  };

  const getIcons = (item: UnitOfAProperty) => {
    const ChevronRightComponent = (
      <ChevronRight
        className={styles.icons}
        key="chevron"
        onClick={(() => goToUnitDashboard(item))}
      />
    );

    const iconsFnArchiveUnit = [() => {
      dispatch({
        type: 'UNIT_DATA', leaseId: item.lease?.id, unitStatus: item.status, unitId: item.id,
      });
      dispatch({ type: 'SHOW_ARCHIVE', showArchive: true });
    }];

    const iconsFnUnarchived = [() => {
      dispatch({
        type: 'UNIT_DATA', leaseId: item.lease?.id, unitStatus: item.status, unitId: item.id,
      });
      dispatch({ type: 'SHOW_UNARCHIVE', showUnarchive: true });
    }];

    const icons = [ChevronRightComponent];
    // if the condition is active, it means that i wanna show the 'archive' option, otherwise not
    if (item.condition === 'active') {
      icons.unshift(getMoreIcon(['Archive'], iconsFnArchiveUnit));
    } if (item.condition === 'archived') {
      icons.unshift(getMoreIcon(['Unarchive'], iconsFnUnarchived));
    }
    return icons;
  };

  useEffect(() => {
    getUnits();
  }, [state.query, state.appliedFilters, state.paginator?.currentPage]);

  const clickPagination = (nextPage: number) => {
    if (nextPage !== state.paginator.currentPage) {
      dispatch({ type: 'PAGE_CHANGED', newPage: nextPage });
    }
  };

  const showModal = () => {
    dispatch({ type: 'MODAL_VISIBILITY', show: !state.show });
  };

  const applyFilters = (updatedFilters: ModalFilterItem[]) => {
    dispatch({ type: 'APPLY_FILTERS', filters: updatedFilters });
  };

  const clearFilters = () => {
    dispatch({ type: 'CLEAR_FILTERS' });
  };

  const removeFilter = (filter: ModalFilterItem) => {
    const updatedFilters = addOrRemoveFromArrayObject(state.appliedFilters, filter, 'code');
    dispatch({ type: 'APPLY_FILTERS', filters: updatedFilters });
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'INPUT_CHANGED', input: e.target.value });
    dispatch({ type: 'SEARCH_QUERY', query: e.target.value });
  };

  const getTable = () => {
    if (!state.paginator || state.code === 'FETCHING' || fetching) {
      return (
        <div className={styles.spinner}><Spinner fixed={false} /></div>
      );
    }
    if (state.paginator.results.length === 0 && state.appliedFilters.length > 0) {
      return (
        <div className={styles.emptyState}>
          <EmptyState
            Icon={NoResultsIcon}
            title={t('emptyState.unitInfoTab.withFilters.title')}
            subtitle={t('emptyState.unitInfoTab.withFilters.subtitle')}
            handleOnClick={clearFilters}
          />
        </div>
      );
    }
    if (state.paginator.results.length === 0 && state.input.trim().length > 0) {
      return (
        <div className={styles.emptyState}>
          <EmptyState
            Icon={NoResultsIcon}
            title={t('emptyState.search')}
          />
        </div>
      );
    }
    if (state.paginator.results.length === 0) {
      return (
        <div className={styles.emptyState}>
          <EmptyState
            Icon={Building}
            title={t('emptyState.unitInfoTab.title')}
            subtitle={t('emptyState.unitInfoTab.subtitle')}
            iconStyle={styles.unitIcon}
            withPlusIcon
          />
        </div>
      );
    }
    return (
      <>
        <Table
          headerNames={tableHeaderActive}
          data={state.paginator.results}
          dataProperties={tableHeaderActive}
          dashboardName={translPrefix}
          iconCell={getIcons}
          uniqueId="id"
          rowClick={((item: UnitOfAProperty) => goToUnitDashboard(item))}
        />

        {state.paginator.lastPage > 1
          && (
            <div className={styles.paginationContainer}>
              <Pagination
                pageLimit={state.paginator.lastPage}
                onClickFn={(newPage: number) => clickPagination(newPage)}
                currentPage={state.paginator.currentPage}
              />
            </div>
          )}
      </>
    );
  };

  const hasReachedMaxOfUnits = state.paginator.total >= unitsCount;

  return (
    <div className={styles.dashboardContainer}>
      {errorServer
      && <ErrorMessage message={t('error.errorMessage')} handleClose={() => setErrorServer(false)} />}
      {state.showArchive && (
      <ModalResult
        title={t('archive.archiveThisUnit')}
        subtitle={state.unitStatus === PropertyStatus.Available ? t('archive.unitInformation') : t('archive.rentedUnitInformation')}
        buttonTextLeft={t('archive.cancel')}
        buttonTextRight={state.unitStatus === PropertyStatus.Available ? t('archive.yesArchive') : t('archive.archiveAndEndLease')}
        handleButtonLeft={() => dispatch({ type: 'SHOW_ARCHIVE', showArchive: false })}
        handleButtonRight={() => archiveProperty()}
        handleButtonClose={() => dispatch({ type: 'SHOW_ARCHIVE', showArchive: false })}
        Icon={Archive}
        iconStyle={styles.archiveIconBlue}
      />
      )}
      {state.showUnarchive && (
      <ModalResult
        title={t('unarchive.unitTitle')}
        subtitle={t('unarchive.unitSubtitle')}
        buttonTextLeft={t('unarchive.cancel')}
        buttonTextRight={t('unarchive.yesUnarchive')}
        handleButtonLeft={() => dispatch({ type: 'SHOW_UNARCHIVE', showUnarchive: false })}
        handleButtonRight={() => unarchiveProperty()}
        handleButtonClose={() => dispatch({ type: 'SHOW_UNARCHIVE', showUnarchive: false })}
        Icon={Archive}
        iconStyle={styles.archiveIconBlue}
      />
      )}
      {state.successfullyArchived && (
      <ModalResult
        title={t('archive.unitArchived')}
        buttonTextRight={t('archive.ok')}
        handleButtonRight={() => dispatch({ type: 'SUCCESSFULLY_ARCHIVED', successfullyArchived: false })}
        handleButtonClose={() => dispatch({ type: 'SUCCESSFULLY_ARCHIVED', successfullyArchived: false })}
        withCheckIcon
        Icon={Archive}
      />
      )}
      {state.successfullyUnarchived && (
      <ModalResult
        title={t('unarchive.activeModal.unitTitle')}
        buttonTextRight={t('unarchive.activeModal.ok')}
        handleButtonRight={() => dispatch({ type: 'SUCCESSFULLY_UNARCHIVED', successfullyUnarchived: false })}
        handleButtonClose={() => dispatch({ type: 'SUCCESSFULLY_UNARCHIVED', successfullyUnarchived: false })}
        withCheckIcon
        Icon={Archive}
      />
      )}
      {state.show && condition === 'active' && (
      <Modal>
        <FilterModal
          appliedFilters={state.appliedFilters}
          filters={state.filters}
          closeFn={showModal}
          applyFn={applyFilters}
          prefix={translPrefix}
        />
      </Modal>
      )}

      <div className={classnames(styles.filterRow, 'row fullWidth')}>
        <Input
          id="filter"
          containerClass={styles.input}
          inputStyle={InputStyle.REGULAR}
          withSearchIcon
          placeholder={condition === 'active' ? t(`${translPrefix}.activeSearch`) : t(`${translPrefix}.archivedSearch`)}
          onChange={onChangeInput}
          value={state.input}
          t={t}
        />

        {condition === 'active' && (
        <Button onClick={() => showModal()} buttonStyle={ButtonStyle.Secondary}>
          <div className="row justify-between">
            {t(`${translPrefix}.filter`)}
            <Filter className={styles.filterIcon} />
          </div>
        </Button>
        )}

        {state.appliedFilters.length > 0 && condition === 'active'
        && (
        <FiltersApplied
          filters={state.appliedFilters}
          clearFn={clearFilters}
          removeOneFilterFn={removeFilter}
          prefix={translPrefix}
        />
        )}
        {condition === 'active' && (
          <div className={styles.buttonsRight}>
            <Tooltip
              position={TooltipPosition.Top}
              hideTooltip={!hasReachedMaxOfUnits}
              textComponent={(
                <Button
                  buttonStyle={ButtonStyle.Primary}
                  onClick={() => goToPage(RouteName.CreateUnit, { propertyId: id })}
                  disabled={hasReachedMaxOfUnits}
                >
                  <div className="row align-justify-center">
                    <PlusCircle
                      className={classnames(styles.plusIcon, {
                        [styles.buttonDisabled]: hasReachedMaxOfUnits,
                      })}
                    />
                    {t(`${translPrefix}.addUnit`)}
                  </div>
                </Button>
              )}
              hoverComponent={(
                <span className={styles.maxNumOfUnitsMsg}>
                  {t('units.maxNumOfUnits')}
                </span>
              )}
            />
          </div>
        )}
      </div>

      {getTable()}
    </div>
  );
};

export { UnitInfoTab };
