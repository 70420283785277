import { LeaseSerializer } from 'networking/serializers/lease-serializer';
import { PropertyAddressSerializer } from './property-address-serializer';
import { PropertyOwnerSerializer } from './property-owner-serializer';

class PropertyTenantSerializer {
  static deSerialize(data: RawPropertyTenant): SerializedPropertyTenant {
    return {
      id: data.id,
      buildingName: data.building_name,
      type: data.type,
      address: PropertyAddressSerializer.deSerialize(data.address),
      owner: PropertyOwnerSerializer.deSerialize(data.owner),
      otherOwner: data.other_owner
        ? PropertyOwnerSerializer.deSerialize(data.other_owner) : undefined,
      lease: data.lease ? LeaseSerializer.deSerialize(data.lease) : undefined,
      manager: data.manager ? PropertyOwnerSerializer.deSerialize(data.manager) : undefined,
    };
  }

  static deSerializePropertiesIdsFromTenant(data: RawTenantSimpleProperty):
  SerializedTenantSimpleProperty {
    return {
      id: data.property_id,
      unitId: data?.unit_id,
      address: PropertyAddressSerializer.deSerialize(data.address),
    };
  }

  static serialize(data: SerializedPropertyTenant): RawPropertyTenant {
    return {
      id: data.id,
      other_owner: data.otherOwner ? PropertyOwnerSerializer.serialize(data.otherOwner) : undefined,
      owner: PropertyOwnerSerializer.serialize(data.owner),
      address: PropertyAddressSerializer.serialize(data.address),
      building_name: data.buildingName,
      type: data.type,
      lease: data.lease ? LeaseSerializer.serialize(data.lease) : undefined,
      manager: data.manager ? PropertyOwnerSerializer.serialize(data.manager) : undefined,
    };
  }
}

export { PropertyTenantSerializer };
