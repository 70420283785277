import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { RawNewTaskStatus, TaskType } from '@mapix/common/src/common/enums';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import { Modal } from '@mapix/common/src/common/modal';
import { Pagination } from '@mapix/common/src/common/paginator';
import { Spinner } from '@mapix/common/src/common/spinner';
import { Tab } from '@mapix/common/src/common/tab';
import { addOrRemoveFromArrayObject, classnames, publicRequestReturnedToNegotiating } from '@mapix/common/src/helpers/utils';
import { ReactComponent as PlusCircle } from 'assets/icons/plus-circle.svg';
import { ReactComponent as NoResultsIcon } from 'assets/icons/search-no-results-solid.svg';
import { ReactComponent as Tool } from 'assets/icons/tool.svg';
import { EmptyState } from 'common/empty-state';
import { MaintenanceTabs } from 'common/enums';
import { FilterModal } from 'common/filter-modal';
import { ModalFilterItem } from 'common/filter-modal/filter-modal';
import { FiltersApplied } from 'common/filters-applied';
import { Table } from 'common/table';
import { MIN_SEARCH_LENGTH } from 'config/constants';
import { logger } from 'helpers/logger';
import { getFiltersByUrl, groupFilters, isPrivateTaskWithoutAssignation } from 'helpers/utils';
import { MaintenanceTableItem } from 'models/maintenance-table-item';
import { MaintenanceController } from 'networking/controllers/maintenance-controller';
import {
  ChangeEvent, useEffect, useReducer,
} from 'react';
import { useTranslation } from 'react-i18next';
import { RouteName, goToPage } from 'routes';
import {
  MaintenanceDashboardReducerV2,
  initDateFilter,
  initialState,
} from './maintenance-dashboard-reducerV2';
import styles from './maintenance-dashboardV2.module.scss';

const MaintenanceDashboardV2 = () => {
  const params = new URLSearchParams(window.location.search);

  const [state, dispatch] = useReducer(
    MaintenanceDashboardReducerV2,
    initialState(getFiltersByUrl(params.toString())),
  );
  const { t } = useTranslation();

  const getTasks = async () => {
    const query = state.query.length < MIN_SEARCH_LENGTH ? '' : state.query;

    try {
      const response = await MaintenanceController
        .getNewTasks(
          state.paginator?.currentPage || 1,
          10,
          state.filterDate,
          state.currentTab,
          query,
          state.appliedFilters,
        );
      dispatch({ type: 'TASKS_FETCHED', paginator: response });
    } catch (err) {
      logger.error(err as Error);
    }
  };

  useEffect(() => {
    getTasks();
  }, [state.currentTab, state.paginator?.currentPage, state.appliedFilters,
    state.query, state.filterDate]);

  const clickTab = (option: string) => {
    groupFilters(option, initDateFilter, []);
    dispatch({ type: 'TAB_CHANGED', newTab: option });
  };

  const clickPagination = (nextPage: number) => {
    if (nextPage !== state.paginator.currentPage) {
      dispatch({ type: 'PAGE_CHANGED', newPage: nextPage });
    }
  };

  const showModal = () => {
    dispatch({ type: 'MODAL_VISIBILITY', showFiltersModal: !state.showFiltersModal });
  };

  const applyFilters = (updatedFilters: ModalFilterItem[]) => {
    groupFilters(state.currentTab, state.filterDate, updatedFilters);
    dispatch({ type: 'APPLY_FILTERS', filters: updatedFilters });
  };

  const clearFilters = () => {
    groupFilters(state.currentTab, state.filterDate, []);
    dispatch({ type: 'CLEAR_FILTERS' });
  };

  const removeFilter = (filter: ModalFilterItem) => {
    const updatedFilters = addOrRemoveFromArrayObject(state.appliedFilters, filter, 'code');
    groupFilters(state.currentTab, state.filterDate, updatedFilters);
    dispatch({ type: 'APPLY_FILTERS', filters: updatedFilters });
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    dispatch({ type: 'INPUT_CHANGED', input: value });

    const queryLength = state.query.length;
    const valueLength = value.length;

    const userIsDeletingString = queryLength > valueLength;

    const queryIsLessThanMinSearchLengthFromBiggerString = (
      valueLength === (MIN_SEARCH_LENGTH - 1)
      && queryLength >= 0
      && userIsDeletingString
    );

    if (valueLength >= MIN_SEARCH_LENGTH
      || queryIsLessThanMinSearchLengthFromBiggerString) {
      dispatch({ type: 'SEARCH_QUERY', query: value });
    }
  };

  const handleRedirect = (item: MaintenanceTableItem) => {
    const {
      taskId, activeRequestAssignedId, taskType, status,
    } = item;

    if (['Unassigned', 'Negotiating'].includes(state.currentTab)) {
      if (item.status === RawNewTaskStatus.UNPUBLISHED
        || isPrivateTaskWithoutAssignation(taskType, status as RawNewTaskStatus)) {
        goToPage(RouteName.UnpublishedAndUnassignedTaskDetail, { id: taskId });
        return;
      }

      // Public task
      if (item.status === RawNewTaskStatus.STOP_APPLICATIONS_FOR_NEGOTIATION
        || publicRequestReturnedToNegotiating(
          item.taskType as TaskType,
          item.status as RawNewTaskStatus,
        )) {
        goToPage(RouteName.PublicRequestDetail, { id: item.activeRequestAssignedId });
        return;
      }

      if (item.status === RawNewTaskStatus.RECEIVING_APPLICATIONS) {
        goToPage(RouteName.UnpublishedAndUnassignedTaskDetail, { id: taskId });
        return;
      }

      goToPage(RouteName.PrivateRequestDetail, { id: activeRequestAssignedId });
    } else if ([MaintenanceTabs.New,
      MaintenanceTabs.RejectedClaims].includes(state.currentTab as MaintenanceTabs)) {
      goToPage(RouteName.TaskDetailClaim, { id: item.taskId });
    } else {
      goToPage(RouteName.TaskDetail, { id: item.taskId });
    }
  };

  const getTable = () => {
    if (!state.paginator || state.code === 'FETCHING') {
      return (
        <div className={styles.spinner}><Spinner fixed={false} /></div>
      );
    }
    if ((state.query !== '' || state.filterDate.startDate !== '') && state.paginator.results.length === 0) {
      return (
        <div className={styles.emptyState}>
          <EmptyState
            Icon={NoResultsIcon}
            title={t('emptyState.search')}
          />
        </div>
      );
    }

    if (state.paginator.results.length === 0) {
      const isClaim = [MaintenanceTabs.New,
        MaintenanceTabs.RejectedClaims].includes(state.currentTab as MaintenanceTabs);

      const emptyStateTitle = isClaim ? t('emptyState.maintenance.titleNew') : t('emptyState.maintenance.title');

      const emptyStateSubtitle = isClaim ? '' : t('emptyState.maintenance.subtitle');

      return (
        <div className={styles.emptyState}>
          <EmptyState
            Icon={Tool}
            title={emptyStateTitle}
            subtitle={emptyStateSubtitle}
            handleOnClick={() => goToPage(RouteName.CreateNewTask)}
            iconStyle={styles.toolIcon}
          />
        </div>
      );
    }

    return (
      <>
        <Table
          headerNames={state.data.dataProperties}
          data={state.paginator.results}
          dataProperties={state.data.dataProperties}
          dashboardName="maintenanceDashboard"
          uniqueId="taskId"
          rowClick={(item: MaintenanceTableItem) => handleRedirect(item)}
        />

        {state.paginator.lastPage > 1
          && (
            <div className={styles.paginationContainer}>
              <Pagination
                pageLimit={state.paginator.lastPage}
                onClickFn={(newPage: number) => clickPagination(newPage)}
                currentPage={state.paginator.currentPage}
              />
            </div>
          )}
      </>
    );
  };

  return (
    <div className={styles.dashboardContainer}>

      {state.showFiltersModal && (
      <Modal>
        <FilterModal
          appliedFilters={state.appliedFilters}
          filters={state.data.filters}
          closeFn={showModal}
          applyFn={applyFilters}
          prefix="maintenanceDashboard"
        />
      </Modal>
      )}

      <div className="text__heading4__textNeutral40">
        {t('maintenanceDashboard.title')}
      </div>
      <p className={classnames(styles.subtitle, 'text__body__small__textNeutral30')}>
        {t('maintenanceDashboard.subTitle')}
      </p>

      <div className="row">
        {Object.entries(MaintenanceTabs).map(([k, v]) => (
          <div key={k} className={styles.tabContainer}>
            <Tab
              onClickFn={() => clickTab(k)}
              name={t(`maintenanceDashboard.tabs.${v.toLowerCase()}`)}
              isActive={state.currentTab === k}
            />
          </div>
        ))}
      </div>

      <div className={classnames(styles.filterRow, 'row fullWidth')}>
        <Input
          id="filter"
          containerClass={styles.input}
          inputStyle={InputStyle.REGULAR}
          withSearchIcon
          placeholder={t(state.data.placeholderText)}
          onChange={onChangeInput}
          value={state.input}
          helperText={state.input ? t('searchCharacterLimit', { limit: MIN_SEARCH_LENGTH }) : ''}
          t={t}
        />

        {/* TODO Filter by Property and Category. Data mocked inside FilterModal */}
        {/* <Button onClick={() => showModal()} buttonStyle={ButtonStyle.Secondary}>
          <div className="row justify-between">
            <div className="text__button__medium__primary60">
              {t('maintenanceDashboard.buttons.filter')}
            </div>
            <Filter className={styles.filterIcon} />
          </div>
        </Button> */}

        {state.appliedFilters.length > 0
        && (
        <FiltersApplied
          filters={state.appliedFilters}
          clearFn={clearFilters}
          removeOneFilterFn={removeFilter}
          prefix="maintenanceDashboard"
        />
        )}

        <div className={styles.rightButtons}>
          <Button
            className={styles.propertyButton}
            buttonStyle={ButtonStyle.Primary}
            onClick={() => goToPage(RouteName.CreateNewTask)}
          >
            <div className="row align-justify-center">
              <PlusCircle className={styles.plusIcon} />
              {t('maintenanceDashboard.buttons.createTask')}
            </div>
          </Button>
        </div>
      </div>

      {getTable()}
    </div>
  );
};

export { MaintenanceDashboardV2 };
