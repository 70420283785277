import { Breakpoints } from 'common/enums';
import { Fragment } from 'react';
import { classnames } from '../../helpers/utils';
import { useMediaQuery } from '../../hooks/use-media-query';
import { ReactComponent as ChevronRight } from '../../assets/icons/chevron-right.svg';
import styles from './breadcrumbs.module.scss';

export type BreadcrumbsItem = {
  name: string,
  goToPageFn: () => void,
};

type BreadcrumbsProps = {
  values: BreadcrumbsItem[]
  containerClass?: string,
};

const handleBreadCumbsStyle = (last: boolean, mobile: boolean) => {
  if (mobile) {
    return classnames(`text__body__medium__${last ? 'primary50' : 'textNeutral40'}`, last ? styles.lastWeight : '');
  }
  return `text__body__small__textNeutral50${last ? ' bold' : ''}`;
};

const Breadcrumbs = ({ values, containerClass }: BreadcrumbsProps) => {
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  return (
    <div className={classnames(styles.breadcrumbContainer, containerClass || '')}>
      {values.map((value, index) => (
        <Fragment key={value.name}>
          <button
            onClick={value.goToPageFn}
            type="button"
            className={handleBreadCumbsStyle(index === values.length - 1, mobile)}
          >
            {value.name}
          </button>
          {index !== values.length - 1 && <ChevronRight className={styles.icon} />}
        </Fragment>
      ))}
    </div>
  );
};

export { Breadcrumbs };
