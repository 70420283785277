import { Unit } from 'models/unit';
import { BillArea, PropertyType } from 'common/enums';
import { SimpleProperty } from 'models/simple-property';

class Bill {
  id: number;

  billedAmount: number;

  dueDate: string;

  currency: string;

  paidOn: string;

  category: string;

  property: SimpleProperty;

  unit: Unit | null;

  fullAddress: string;

  affectedArea: string;

  propertyType: string;

  taskId: number | undefined;

  amount: string;

  status: string;

  additionalComments: string;

  constructor(params: SerializedBill) {
    this.id = params.id;
    this.billedAmount = params.billedAmount;
    this.amount = `$${params.billedAmount} ${params.currency} `;
    this.dueDate = params.dueDate;
    this.currency = params.currency;
    this.paidOn = params.paymentDate;
    this.category = params.category;
    this.property = new SimpleProperty(params.property);
    this.unit = params.unit ? new Unit(params.unit) : null;
    this.taskId = params.taskId;
    this.fullAddress = this.property.fullAddress;
    this.status = params.status;
    this.additionalComments = params.additionalComments;
    if (this.property.type === PropertyType.Building) {
      this.affectedArea = this.unit ? BillArea.Unit : BillArea.CommonArea;
    } else {
      this.affectedArea = '-';
    }
    this.propertyType = this.property.type;
  }
}

export { Bill };
