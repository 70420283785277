import React from 'react';
import { classnames } from '../../helpers/utils';
import { ReactComponent as AlertIcon } from '../../assets/icons/alert.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';
import styles from './pill.module.scss';
import { PillColor } from '../enums';

export enum PillIcon {
  Check,
  Alert,
}

type PillProps = {
  content: string,
  color?: PillColor,
  textStyle?: string,
  icon?: PillIcon,
  containerClass?: string,
};

const getIcon = (icon?: PillIcon) => {
  switch (icon) {
    case PillIcon.Alert:
      return <AlertIcon className={styles.alert} />;
    case PillIcon.Check:
      return <CheckIcon className={styles.icon} />;
    default:
      return '';
  }
};

const handleColor = (color?: PillColor) => {
  switch (color) {
    case PillColor.Blue:
      return styles.blue;
    case PillColor.Green:
      return styles.green;
    case PillColor.Purple:
      return styles.purple;
    case PillColor.Red:
      return styles.red;
    case PillColor.Yellow:
      return styles.yellow;
    case PillColor.Gray:
      return styles.gray;
    default:
      return styles.white;
  }
};

const Pill = ({
  content, color, textStyle, icon, containerClass,
}: PillProps) => (
  <div
    className={classnames(
      handleColor(color),
      styles.pillContainer,
      textStyle || 'text__body__medium__textNeutral10',
      containerClass || '',
    )}
  >
    {icon !== undefined && getIcon(icon)}
    <div>
      <div>
        {content}
      </div>
    </div>
  </div>
);

export { Pill };
