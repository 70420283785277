import { ReactComponent as PlusFilled } from 'assets/icons/plus-filled.svg';
import { classnames } from '@mapix/common/src/helpers/utils';
import styles from './empty-state.module.scss';

type EmptyStateProps = {
  Icon: any,
  title: string,
  subtitle?: string,
  subtitleWithoutLink?: string,
  handleOnClick?: () => void,
  withPlusIcon?: boolean,
  iconStyle?: string,
};

const EmptyState = ({
  Icon, title, subtitle, handleOnClick, withPlusIcon = false, iconStyle = '', subtitleWithoutLink,
}: EmptyStateProps) => (
  <div className={styles.container}>
    <div className={styles.containerCircle}>
      <Icon className={classnames(iconStyle, styles.icon)} />
      {withPlusIcon
        && (
          <div className={styles.containerIcon}>
            <button onClick={handleOnClick} type="button">
              <PlusFilled className={styles.plusIcon} />
            </button>
          </div>
        )}
    </div>
    <p className="text__body__large__textNeutral40">
      {title}
    </p>
    { subtitle
    && (
    <button
      type="button"
      className={classnames(styles.link, 'text__body__medium__info30')}
      onClick={handleOnClick}
    >
      {subtitle}
    </button>
    )}
    { subtitleWithoutLink
    && (
      <p className="text__body__large__textNeutral40">
        {subtitleWithoutLink}
      </p>
    )}
  </div>
);

export { EmptyState };
