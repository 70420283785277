class PropertyOwner {
  [key: string]: string | number | undefined;

  id?: number;

  name: string;

  lastname?: string;

  email: string;

  phoneNumber: string;

  countryCode: string;

  constructor(params: SerializedPropertyOwner | null | undefined) {
    this.name = params?.name || '';
    this.lastname = params?.lastname || '';
    this.email = params?.email || '';
    this.phoneNumber = params?.phoneNumber || '';
    this.id = params?.id;
    this.countryCode = params?.countryCode || '';
  }
}

export { PropertyOwner };
