import { ReactComponent as Tool } from '../../assets/icons/tool.svg';
import { classnames, getTimeZoneFormat } from '../../helpers/utils';
import { useMediaQuery } from '../../hooks/use-media-query';
import { BasicUserFields } from '../../types';
import { Breakpoints } from '../enums';
import { ModalAccentColor, ModalResult } from '../modal-result';
import { SimpleMessage } from '../simple-message';
import styles from './modal-cancelled-task.module.scss';

const translPrefix = 'modalCancelledTask';

type ModalCancelledTaskProps = {
  canceller: BasicUserFields,
  comments: string,
  reason: string,
  completionPercentage: number,
  paymentAmount: number,
  cancelationDate: string,
  onAcceptCancellation: () => void,
  onModifyCancellation: () => void,
  onCloseCancellation: () => void,
  t: (text: string, object?: {}) => string,
  cancelledByManager: boolean,
};

const ModalCancelledTask = ({
  canceller, comments, reason, completionPercentage, cancelledByManager,
  paymentAmount, cancelationDate, onAcceptCancellation, onModifyCancellation,
  onCloseCancellation, t,
}: ModalCancelledTaskProps) => {
  const roleTranslation = t(`${cancelledByManager ? 'manager' : 'contractor'}`).toLowerCase();

  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const content = (
    <div className={styles.contentContainer}>
      <div className={`mb-15 mt-4 ${mobile
        ? 'text__body__medium__textNeutral40' : 'text__body__small__textNeutral40'}`}
      >
        {t(`${translPrefix}.subtitle`)}
      </div>
      <div className={`mb-15 ${mobile
        ? 'text__body__medium__textNeutral40' : 'text__body__small__textNeutral40'}`}
      >
        <span>
          {t(`${translPrefix}.cancelledBy`)}
        </span>
        <span className="bold">
          {` ${canceller.name} ${canceller.lastName}`}
        </span>
      </div>
      <SimpleMessage
        text={comments}
        senderLastName={canceller.lastName}
        senderName={canceller.name}
        senderUrl={canceller.profilePhoto?.url}
        textClass={mobile
          ? 'text__body___small__textNeutral40' : 'text__body__xsmall__textNeutral40'}
      />
      <SimpleMessage
        hideAvatar
        text={reason}
        title={t(`${translPrefix}.reason`)}
        containerClass="mb-10 mt-8"
        messageClass={styles.messageWithTitle}
      />
      <div className={styles.completionAndPaymentWrapper}>
        <SimpleMessage
          hideAvatar
          text={`${completionPercentage}%`}
          title={t(`${translPrefix}.completion`)}
          containerClass="mb-10"
          messageClass={styles.messageWithTitle}
        />
        <SimpleMessage
          hideAvatar
          text={`${paymentAmount}`}
          title={t(`${translPrefix}.payment`)}
          containerClass="mb-10"
          messageClass={styles.messageWithTitle}
        />
      </div>
      <div className={mobile
        ? 'text__body__small__textNeutral30' : 'text__body__xsmall__textNeutral30'}
      >
        {t(`${translPrefix}.calculationDisclaimer`)}
      </div>
      <SimpleMessage
        hideAvatar
        text={getTimeZoneFormat(cancelationDate, 'MMMM Do, YYYY - HH:mm a')}
        title={t(`${translPrefix}.cancelled`)}
        containerClass="mt-16"
        messageClass={classnames(styles.messageWithTitle, mobile ? 'mb-22' : '')}
      />
    </div>
  );

  return (
    <ModalResult
      titleTextStyle={mobile
        ? 'text__body__large__textNeutral50' : 'text__body__medium__textNeutral50'}
      Icon={Tool}
      buttonTextLeft={t('modify')}
      buttonTextRight={t('accept')}
      handleButtonLeft={onModifyCancellation}
      handleButtonRight={onAcceptCancellation}
      handleButtonClose={onCloseCancellation}
      title={t(`${translPrefix}.title`, { role: roleTranslation })}
      content={content}
      modalAccentColor={ModalAccentColor.RED}
      buttonLeftStyle={classnames(styles.buttonContainer, mobile
        ? 'text__body__large__primary60' : 'text__body__medium__primary60')}
      buttonRightStyle={classnames(styles.buttonContainer, mobile
        ? 'text__body__large__textNeutral10' : 'text__body__medium__textNeutral10')}
      withBackground
    />
  );
};

export { ModalCancelledTask };
