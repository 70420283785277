import {
  ChangeEvent, Dispatch, FormEvent, useState,
} from 'react';
import { AddressForm, OwnerForm } from 'common/forms';
import { ResourcesController } from 'networking/controllers/resources-controller';
import { useTranslation } from 'react-i18next';
import {
  addError,
  checkHTMLErrors, classnames, HTMLValidationError, isEmptyObject, removeError,
} from '@mapix/common/src/helpers/utils';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { CreateFooter } from 'common/creation';
import { ButtonType } from '@mapix/common/src/common/button';
import { PropertyOwner } from 'models/property-owner';
import { PropertyAddress } from 'models/property-address';
import { City, Coordinates } from '@mapix/common/src/types';
import styles from './create-property-condo.module.scss';
import { Action } from './create-property-condo-reducer';

type CreatePropertyCondoStep1Props = {
  dispatch: Dispatch<Action>,
  owner: PropertyOwner,
  otherOwner: PropertyOwner,
  address: PropertyAddress,
};

const addressTranslPrefix = 'createBuilding.address';
const ownerTranslPrefix = 'createCondo.owner';
const footerTranslPrefix = 'createCondo.footer';
const otherOwnerTranslPrefix = 'createBuilding.otherOwner';
const translError = 'error';

const CreatePropertyCondoStep1 = ({
  dispatch, owner, otherOwner, address,
}: CreatePropertyCondoStep1Props) => {
  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});
  const [phoneErrors, setPhoneErrors] = useState<HTMLValidationError>({});
  const [errorDialog, setErrorDialog] = useState(false);
  const [fetchingCoordinates, setFetchingCoordinates] = useState(false);

  const { t } = useTranslation();

  const onChange = (e: ChangeEvent<HTMLInputElement>, objectToChange: string) => {
    dispatch({
      type: 'CHANGE_INPUT', field: e.target.name, object: objectToChange, value: e.target.value,
    });
  };

  const onChangeCoordinates = (coords: Coordinates | null) => {
    if (formErrors.invalidAddress && !!coords) {
      removeError('invalidAddress', setFormErrors);
    }

    if (!coords && address.streetName && !address.lat) {
      addError(t('error.invalidAddress'), 'invalidAddress', setFormErrors);
    }

    dispatch({
      type: 'CHANGE_COORDINATES', value: coords ?? [0, 0],
    });
  };

  const onChangePhoneNumber = (phone: string, countryCode: string, field: string) => {
    const result = field.split('-');
    dispatch({
      type: 'CHANGE_INPUT', field: result[1], object: result[0], value: phone,
    });

    dispatch({
      type: 'CHANGE_INPUT', field: 'countryCode', object: result[0], value: countryCode,
    });
  };

  const onClickSelect = (option: City) => {
    dispatch({
      type: 'CHANGE_INPUT', field: 'city', object: 'address', value: option.name,
    });
    dispatch({
      type: 'CHANGE_INPUT', field: 'cityId', object: 'address', value: option.id,
    });
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (fetchingCoordinates) return;

    const invalidCoords = !address.lat && !address.long;

    const target = e.target as HTMLFormElement;
    if (!target.checkValidity() || !owner.phoneNumber || invalidCoords) {
      setFormErrors(checkHTMLErrors(target));
      if (!owner.phoneNumber) {
        addError(t('error.emptyField'), 'landlord-phoneNumber', setPhoneErrors);
      }

      if (invalidCoords) {
        addError(t('error.invalidAddress'), 'invalidAddress', setFormErrors);
      }
      return;
    }

    if (!isEmptyObject(phoneErrors)) {
      return;
    }

    dispatch({
      type: 'FINISH_STEP_1', address, owner, otherOwner,
    });
  };

  return (
    <form onSubmit={onSubmit} noValidate>

      {errorDialog && (
      <ErrorMessage
        handleClose={() => setErrorDialog(false)}
        message={t(`${translError}.errorMessage`)}
      />
      ) }

      <div className={classnames(styles.formTitle, 'text__heading6__textNeutral50')}>
        {t(`${addressTranslPrefix}.condoTitle`)}
      </div>

      <AddressForm
        formErrors={formErrors}
        onChange={onChange}
        address={address}
        onClickSelect={onClickSelect}
        showUnitNumber
        getCities={ResourcesController.getCities}
        onChangeCoordinates={onChangeCoordinates}
        fetchingCoordinates={fetchingCoordinates}
        setFetchingCoordinates={setFetchingCoordinates}
      />

      <div className={classnames(styles.formTitle, 'text__heading6__textNeutral50')}>
        {t(`${ownerTranslPrefix}.title`)}
      </div>

      <OwnerForm
        isRequired
        formErrors={{ ...formErrors, ...phoneErrors }}
        onChange={onChange}
        owner={owner}
        onChangePhoneNumber={onChangePhoneNumber}
        translPrefix={ownerTranslPrefix}
        idPrefix="landlord"
        field="landlord"
        setPhoneErrors={setPhoneErrors}

      />

      <OwnerForm
        formErrors={{ ...formErrors, ...phoneErrors }}
        onChange={onChange}
        onChangePhoneNumber={onChangePhoneNumber}
        owner={otherOwner}
        translPrefix={otherOwnerTranslPrefix}
        idPrefix="otherLandlord"
        field="otherLandlord"
        setPhoneErrors={setPhoneErrors}

      />

      <CreateFooter
        nextName={t(`${footerTranslPrefix}.legal`)}
        nextButtonType={ButtonType.Submit}
      />

    </form>
  );
};

export { CreatePropertyCondoStep1 };
