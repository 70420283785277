import styles from './black-tooltip.module.scss';

type TooltipProps = {
  text: string,
  width?: string
};

const BlackTooltip = ({ text, width = '184px' }: TooltipProps) => (
  <div style={{ width }} className={styles.container}>
    <div className="text__body__medium__textNeutral10">
      {text}
    </div>
  </div>
);

export { BlackTooltip };
