class Services {
  heating: boolean;

  water: boolean;

  stove: boolean;

  electricity: boolean;

  refrigerator: boolean;

  parkingSlot: boolean;

  acceptsPets: boolean;

  furnished: boolean;

  other: string;

  constructor(params: SerializedServices | null) {
    this.heating = params?.heating || false;
    this.water = params?.water || false;
    this.stove = params?.stove || false;
    this.electricity = params?.electricity || false;
    this.refrigerator = params?.refrigerator || false;
    this.parkingSlot = params?.parkingSlot || false;
    this.acceptsPets = params?.acceptsPets || false;
    this.furnished = params?.furnished || false;
    this.other = params?.other || '';
  }
}

export { Services };
