import {
  ChangeEvent, useEffect, useReducer, useState,
} from 'react';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import { addOrRemoveFromArrayObject, classnames } from '@mapix/common/src/helpers/utils';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { ReactComponent as Filter } from 'assets/icons/filter.svg';
import { ReactComponent as PlusCircle } from 'assets/icons/plus-circle.svg';
import { ReactComponent as Users } from 'assets/icons/users.svg';
import { Table } from 'common/table';
import { logger } from 'helpers/logger';
import { ReactComponent as NoResultsIcon } from 'assets/icons/search-no-results-solid.svg';
import { Pagination } from '@mapix/common/src/common/paginator';
import { Modal } from '@mapix/common/src/common/modal';
import { Spinner } from '@mapix/common/src/common/spinner';
import { MoreIcon } from 'common/more-icon';
import { FilterModal } from 'common/filter-modal';
import { ModalFilterItem } from 'common/filter-modal/filter-modal';
import { FiltersApplied } from 'common/filters-applied';
import { goToPage, RouteName } from 'routes';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { EmptyState } from 'common/empty-state';
import { ReactComponent as Trash } from 'assets/icons/thrash.svg';
import { PropertyController } from 'networking/controllers/property-controller';
import { ContractorController } from 'networking/controllers/contractor-controller';
import { ModalResult } from 'common/modal-result';
import { Contractor } from 'models/contractor';
import { Condition } from 'common/enums';
import { mergePhoneNumberObject } from 'helpers/utils';
import styles from './contractors-tab.module.scss';
import { ContractorsTabReducer, initialState } from './contractors-tab-reducer';

type ContractorsTabProps = {
  id: number,
  propertyCondition?: string
};

const translPrefix = 'contractorsTab';

const tableHeader = ['category', 'name', 'phoneNumber', 'email'];

const ContractorsTab = ({ id, propertyCondition }: ContractorsTabProps) => {
  const [state, dispatch] = useReducer(ContractorsTabReducer, initialState);
  const [fetching, setFetching] = useState(false);
  const [contractorId, setContractorId] = useState('');
  const [errorServer, setErrorServer] = useState(false);
  const { t } = useTranslation();

  const iconCells = (contractor: Contractor) => {
    if (propertyCondition !== Condition.Archived) {
      return (
        [<MoreIcon
          iconClass={styles.icons}
          key="more-icon"
          options={[t(`${translPrefix}.edit`), t(`${translPrefix}.delete`)]}
          optionsFn={[
            () => goToPage(RouteName.EditContractor, {
              propertyId: id,
              contractorId: Number(contractor.id),
            }),
            () => dispatch({ type: 'SHOW_DELETE_CONTRACTOR', deleteContractor: true })]}
        />]
      );
    } return [];
  };

  const getContractors = async () => {
    try {
      setFetching(true);
      const response = await PropertyController
        .getContractorsOfAProperty(
          state.paginator?.currentPage || 1,
          10,
          id,
          state.appliedFilters,
          state.query,
        );
      dispatch({ type: 'CONTRACTORS_FETCHED', paginator: response });
      setFetching(false);
    } catch (err) {
      logger.error(err as Error);
      setFetching(false);
      setErrorServer(true);
    }
  };

  useEffect(() => {
    if (!state.deleteContractor) {
      getContractors();
    }
  }, [state.query, state.appliedFilters, state.paginator?.currentPage, state.deleteContractor]);

  const clickPagination = (nextPage: number) => {
    if (nextPage !== state.paginator.currentPage) {
      dispatch({ type: 'PAGE_CHANGED', newPage: nextPage });
    }
  };

  const showModal = () => {
    dispatch({ type: 'MODAL_VISIBILITY', show: !state.show });
  };

  const applyFilters = (updatedFilters: ModalFilterItem[]) => {
    dispatch({ type: 'APPLY_FILTERS', filters: updatedFilters });
  };

  const clearFilters = () => {
    dispatch({ type: 'CLEAR_FILTERS' });
  };

  const removeFilter = (filter: ModalFilterItem) => {
    const updatedFilters = addOrRemoveFromArrayObject(state.appliedFilters, filter, 'code');
    dispatch({ type: 'APPLY_FILTERS', filters: updatedFilters });
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'INPUT_CHANGED', input: e.target.value });
    dispatch({ type: 'SEARCH_QUERY', query: e.target.value });
  };

  const deleteContractor = async () => {
    try {
      setFetching(true);
      await ContractorController.deleteContractor(id, Number(contractorId));
      dispatch({ type: 'SHOW_DELETE_CONTRACTOR', deleteContractor: false });
    } catch (err: any) {
      setFetching(false);
      logger.error(err);
      setErrorServer(true);
    }
  };

  const editContractor = async (item: Contractor) => {
    goToPage(RouteName.EditContractor, { propertyId: id, contractorId: Number(item.id) });
  };

  const getTable = () => {
    if (!state.paginator || state.code === 'FETCHING' || fetching) {
      return (
        <div className={styles.spinner}><Spinner fixed={false} /></div>
      );
    }
    if (state.paginator.results.length === 0 && state.appliedFilters.length > 0) {
      return (
        <div className={styles.emptyState}>
          <EmptyState
            Icon={NoResultsIcon}
            title={t('emptyState.contractors.withFilters.title')}
            subtitle={t('emptyState.contractors.withFilters.subtitle')}
            handleOnClick={clearFilters}
          />
        </div>
      );
    }
    if (state.paginator.results.length === 0 && state.input.trim().length > 0) {
      return (
        <div className={styles.emptyState}>
          <EmptyState
            Icon={NoResultsIcon}
            title={t('emptyState.search')}
          />
        </div>
      );
    }
    if (state.paginator.results.length === 0) {
      return (
        <div className={styles.emptyState}>
          <EmptyState
            Icon={Users}
            title={t('emptyState.contractors.title')}
            subtitle={t('emptyState.contractors.subtitle')}
            iconStyle={styles.userIcon}
            withPlusIcon
          />
        </div>
      );
    }
    return (
      <>
        <Table
          headerNames={tableHeader}
          data={mergePhoneNumberObject(state.paginator.results)}
          dataProperties={tableHeader}
          dashboardName={translPrefix}
          iconCell={iconCells}
          uniqueId="id"
          setId={setContractorId}
          rowClick={propertyCondition !== Condition.Archived
            ? ((item: Contractor) => editContractor(item)) : undefined}
          clickable={propertyCondition !== Condition.Archived}
        />

        {state.paginator.lastPage > 1
          && (
            <div className={styles.paginationContainer}>
              <Pagination
                pageLimit={state.paginator.lastPage}
                onClickFn={(newPage: number) => clickPagination(newPage)}
                currentPage={state.paginator.currentPage}
              />
            </div>
          )}
      </>
    );
  };

  return (
    <div className={styles.dashboardContainer}>
      {errorServer
      && <ErrorMessage message={t('error.errorMessage')} handleClose={() => setErrorServer(false)} />}
      {state.show && (
      <Modal>
        <FilterModal
          appliedFilters={state.appliedFilters}
          filters={state.filters}
          closeFn={showModal}
          applyFn={applyFilters}
          prefix={translPrefix}
        />
      </Modal>
      )}

      <div className={classnames(styles.filterRow, 'row fullWidth')}>
        <Input
          id="filter"
          containerClass={styles.input}
          inputStyle={InputStyle.REGULAR}
          withSearchIcon
          placeholder={t(`${translPrefix}.search`)}
          onChange={onChangeInput}
          value={state.input}
          t={t}
        />

        <Button onClick={() => showModal()} buttonStyle={ButtonStyle.Secondary}>
          <div className="row justify-between">
            <div className="text__button__medium__primary60">
              {t(`${translPrefix}.filter`)}
            </div>
            <Filter className={styles.filterIcon} />
          </div>
        </Button>

        {state.appliedFilters.length > 0
        && (
        <FiltersApplied
          filters={state.appliedFilters}
          clearFn={clearFilters}
          removeOneFilterFn={removeFilter}
          prefix={translPrefix}
        />
        )}
        <div className={styles.buttonsRight}>
          <Button
            className={styles.addContractorButton}
            buttonStyle={ButtonStyle.Primary}
            onClick={() => goToPage(RouteName.CreateContractor, { propertyId: id })}
            disabled={propertyCondition === Condition.Archived}
          >
            <div className="row align-justify-center">
              <PlusCircle className={styles.plusIcon} />
              {t(`${translPrefix}.addContractor`)}
            </div>
          </Button>
        </div>
      </div>

      {getTable()}

      {state.deleteContractor && (
      <ModalResult
        title={t(`${translPrefix}.deleteModal.title`)}
        subtitle={t(`${translPrefix}.deleteModal.subtitle`)}
        Icon={Trash}
        iconStyle={styles.iconDelete}
        buttonTextRight={t(`${translPrefix}.deleteModal.ok`)}
        buttonTextLeft={t(`${translPrefix}.deleteModal.cancel`)}
        handleButtonLeft={() => dispatch({ type: 'SHOW_DELETE_CONTRACTOR', deleteContractor: false })}
        handleButtonRight={deleteContractor}
        handleButtonClose={() => dispatch({ type: 'SHOW_DELETE_CONTRACTOR', deleteContractor: false })}
      />
      )}
    </div>
  );
};

export { ContractorsTab };
