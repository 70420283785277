import { classnames } from '@mapix/common/src/helpers/utils';
import { ContractorCategoryIcon } from '@mapix/common/src/common/contractor-category-icon';
import { AvatarOrInitials } from '@mapix/common/src/common/avatar-or-initials';
import { SimpleMessage } from '@mapix/common/src/common/simple-message';
import { Pill, PillIcon } from '@mapix/common/src/common/pill';
import { PillColor } from '@mapix/common/src/common/enums';
import styles from './contractor-card.module.scss';

type ContractorCardProps = {
  name: string,
  lastName: string,
  categories: string[],
  t: (text: string) => string,
  url?: string,
  warningPillContent?: string
  rejectedMessage?: string;
  containerClass?: string,
};

const translPrefix = 'contractorCard';

const ContractorCard = ({
  name, lastName, t, url, rejectedMessage = '', warningPillContent = '', containerClass, categories,
}: ContractorCardProps) => {
  const showRejectedMessage = () => (
    <SimpleMessage containerClass={styles.rejectedMessage} text={rejectedMessage!} hideAvatar />
  );

  const showPill = () => (
    <span className={styles.warningPill}>
      <Pill
        icon={PillIcon.Alert}
        content={warningPillContent}
        color={PillColor.Yellow}
        textStyle={classnames('text__body__small__textNeutral10')}
      />
    </span>
  );

  return (
    <div className={classnames(styles.cardContainer, containerClass || '')}>
      <AvatarOrInitials
        firstName={name}
        lastName={lastName}
        className={styles.avatar}
        url={url || ''}
      />
      <div className={styles.cardInfo}>
        <div className={styles.nameAndWarningPill}>
          <span
            className={classnames(styles.name, 'text__body__large__textNeutral50')}
          >
            {`${name} ${lastName}`}
          </span>

          <span
            className={classnames(styles.cardRole, 'text__body__medium__textNeutral30')}
          >
            {t(`${translPrefix}.assignedContractor`)}
          </span>

          {warningPillContent && showPill()}
        </div>
        <div>
          {rejectedMessage && showRejectedMessage()}
        </div>
        <div className={styles.categoriesAndButton}>
          <div className={styles.categories}>
            {categories.map((category) => (
              <ContractorCategoryIcon
                key={category}
                category={category}
                className={styles.categoryIcon}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export { ContractorCard };
