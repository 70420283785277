import { useEffect, useReducer } from 'react';
import { CreateHeader } from 'common/creation';
import { goToPage, RouteName } from 'routes';
import { Condition, PropertyType } from 'common/enums';
import { PropertyController } from 'networking/controllers/property-controller';
import { logger } from 'helpers/logger';
import { useTranslation } from 'react-i18next';
import { ModalResult } from 'common/modal-result';
import { ReactComponent as Building } from 'assets/icons/building.svg';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { ReactComponent as FeatherIcon } from 'assets/icons/feather-icons.svg';
import { DetailedDraftProperty } from 'models/detailed-draft-property';
import { CreatePropertyBuildingStep2 } from './create-property-building-step-2';
import { CreatePropertyBuildingStep3 } from './create-property-building-step-3';
import { CreatePropertyBuildingStep1 } from './create-property-building-step-1';
import { CreatePropertyBuildingReducer, initialState } from './create-property-building-reducer';

const translPrefix = 'createBuilding';
const translGenericPrefix = 'createGeneric';
const translError = 'error';

type CreatePropertyBuildingProps = {
  fetchedProperty?: DetailedDraftProperty
};

const CreatePropertyBuilding = ({ fetchedProperty = undefined }: CreatePropertyBuildingProps) => {
  const [state, dispatch] = useReducer(
    CreatePropertyBuildingReducer,
    initialState(fetchedProperty),
  );
  const { t } = useTranslation();

  const invalidNumOfUnits = state.units.length <= state.building.unitsCount;

  const close = () => {
    dispatch({ type: 'DRAFT_MODAL' });
  };

  const saveProperty = async (property: SerializedCreateBuilding) => {
    try {
      await PropertyController.savePropertyBuilding(property);
      dispatch({ type: 'FINISH_MODAL' });
    } catch (err) {
      dispatch({ type: 'FINISH_ERROR' });
      logger.error(err as Error);
    }
  };

  useEffect(() => {
    if (state.finish || state.finishDraft) {
      const property: SerializedCreateBuilding = {
        unitsCount: state.building.unitsCount,
        buildingName: state.building.buildingName,
        address: state.address,
        type: PropertyType.Building,
        condition: state.finish ? Condition.Active : Condition.Draft,
        otherOwner: state.otherOwner.name ? state.otherOwner : undefined,
        owner: state.owner,
        units: state.units,
        contractors: state.contractors,
        id: state.id,
      };
      saveProperty(property);
    }
  }, [state.finish, state.finishDraft]);

  const backToProperties = () => {
    goToPage(RouteName.Properties);
  };

  return (
    <>
      {state.errorModal && (
      <ErrorMessage
        handleClose={() => dispatch({ type: 'CLOSE_ERROR' })}
        message={t(`${translError}.errorMessage`)}
      />
      )}

      {state.modal
      && (
      <ModalResult
        title={t(`${translPrefix}.modalTitle`)}
        subtitle={t(`${translPrefix}.modalSubtitle`)}
        Icon={Building}
        withCheckIcon
        buttonTextRight={t(`${translPrefix}.modalButton`)}
        handleButtonRight={backToProperties}
        handleButtonClose={backToProperties}
      />
      )}

      {state.draftModal
      && (
        <ModalResult
          title={t(`${translPrefix}.draftModalTitle`)}
          subtitle={t(`${translPrefix}.draftModalSubTitle`)}
          Icon={FeatherIcon}
          buttonTextRight={t(`${translPrefix}.draftModalOk`)}
          buttonTextLeft={t(`${translPrefix}.draftModalCancel`)}
          handleButtonLeft={() => goToPage(RouteName.Properties)}
          handleButtonRight={() => dispatch({ type: 'FINISH_DRAFT' })}
          handleButtonClose={() => dispatch({ type: 'DRAFT_MODAL' })}
        />
      )}

      <CreateHeader
        closeText={t(`${translGenericPrefix}.close`)}
        title={t(`${translPrefix}.header`)}
        closeFn={close}
        currentStep={state.step}
        stepper={state.steps.map((step) => t(step))}
      />
      {state.step === 0
      && (
      <CreatePropertyBuildingStep1
        countries={state.countries}
        building={state.building}
        owner={state.owner}
        otherOwner={state.otherOwner}
        address={state.address}
        dispatch={dispatch}
        formErrors={state.formErrors}
        invalidNumOfUnits={invalidNumOfUnits}
      />
      )}
      {state.step === 1
      && (
      <CreatePropertyBuildingStep2
        dispatch={dispatch}
        units={state.units}
        building={state.building}
      />
      )}
      {state.step === 2
      && <CreatePropertyBuildingStep3 dispatch={dispatch} contractors={state.contractors} />}
    </>
  );
};

export { CreatePropertyBuilding };
