import React, { ChangeEvent, useRef } from 'react';
import { ButtonIconWithTextDown } from '@mapix/common/src/common/button-icon-with-text-down';
import { classnames } from '@mapix/common/src/helpers/utils';
import { FileExtension } from '@mapix/common/src/common/enums';
import { useTranslation } from 'react-i18next';
import { formatFileExtensions, getFileExtension, isValidFile } from '@mapix/common/src/helpers/file-utils';
import styles from './file-upload-button.module.scss';

type FileUploadButtonProps = {
  icon: JSX.Element,
  text: string
  className?: string,
  supportedExtensions?: Array<FileExtension>,
  maxFileSizeInMb?: number,
  notifyNewFile: (file: File) => void;
  notifyErrorFileMessage: (errorMessage: string) => void;
};

const FileUploadButton: React.FC<FileUploadButtonProps> = ({
  icon,
  text,
  className = '',
  supportedExtensions = [],
  maxFileSizeInMb = 0,
  notifyNewFile,
  notifyErrorFileMessage,
}) => {
  const { t } = useTranslation();
  const fileRef = useRef<HTMLInputElement>(null);

  const uploadErrors = {
    generic: t('error.errorMessage'),
    fileSize: t('error.fileSizeGeneric', { maxSize: maxFileSizeInMb }),
    fileFormat: t('error.fileFormat', { acceptedFormats: formatFileExtensions(supportedExtensions).toUpperCase() }),
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleOnDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.items[0]?.getAsFile();

    const fileExtension = getFileExtension(file!.name);

    const fileErrorMessage = await isValidFile(
      fileExtension,
      file!.size,
      uploadErrors,
      supportedExtensions,
      maxFileSizeInMb,
    );

    if (fileErrorMessage) {
      notifyErrorFileMessage(fileErrorMessage.toString());
    } else {
      notifyNewFile(file!);
      notifyErrorFileMessage('');
    }
  };

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.item(0);

    const fileExtension = getFileExtension(file!.name);

    const fileErrorMessage = await isValidFile(
      fileExtension,
      file!.size,
      uploadErrors,
      supportedExtensions,
      maxFileSizeInMb,
    );

    if (fileErrorMessage) {
      notifyErrorFileMessage(fileErrorMessage.toString());
    } else {
      notifyNewFile(file!);
      notifyErrorFileMessage('');
      e.target.value = '';
    }
  };

  return (
    <div
      className={classnames(className, styles.uploader)}
      onDragOver={handleDragOver}
      onDrop={handleOnDrop}
    >
      <ButtonIconWithTextDown
        onClick={() => fileRef.current?.click()}
        icon={icon}
        text={text}
      />

      <input type="file" ref={fileRef} accept={formatFileExtensions(supportedExtensions)} onChange={handleInputChange} hidden />
    </div>
  );
};

export { FileUploadButton };
