import { useState } from 'react';
import { AxiosResponse } from 'axios';
import { ReactComponent as Email } from '../../assets/icons/mail.svg';
import { ReactComponent as User } from '../../assets/icons/user.svg';
import { ModalAccentColor, ModalResult } from '../modal-result';
import { Button, ButtonStyle } from '../button';
import styles from './resend-confirm-email-button.module.scss';

type ResendConfirmEmailButtonProps = {
  t: (text: string) => string,
  resendConfirmEmail: () => Promise<AxiosResponse<unknown>>,
  translPrefix: string,
};

const ResendConfirmEmailButton = ({
  t, resendConfirmEmail, translPrefix,
}: ResendConfirmEmailButtonProps) => {
  const [serverError, setServerError] = useState(false);
  const [resendEmailError, setResendEmailError] = useState(false);
  const [showSend, setShowSend] = useState(false);

  const handleResendEmail = async () => {
    try {
      await resendConfirmEmail();
      setShowSend(true);
    } catch (err: any) {
      if (err.code === 4022) {
        setResendEmailError(true);
      }
      setServerError(true);
    }
  };

  return (
    <div className={styles.container}>
      <Button
        buttonStyle={ButtonStyle.Link}
        onClick={() => handleResendEmail()}
        className="text__body__medium__primary"
      >
        {t(`${translPrefix}.resendEmailButton`)}
      </Button>
      {showSend
      && (
      <ModalResult
        title={t(`${translPrefix}.title`)}
        Icon={Email}
        buttonTextRight={t('ok')}
        handleButtonRight={() => setShowSend(false)}
        handleButtonClose={() => setShowSend(false)}
        modalAccentColor={ModalAccentColor.GREEN}
        withBackground
        modalStyle={styles.modal}
      />
      )}
      {serverError
      && (
        <ModalResult
          title={resendEmailError ? t(`${translPrefix}.titleActivationError`) : t('error.errorMessage')}
          content={resendEmailError ? t(`${translPrefix}.subtitleActivationError`) : ''}
          handleButtonClose={() => setServerError(false)}
          handleButtonLeft={() => setServerError(false)}
          Icon={User}
          buttonTextRight={t('ok')}
          handleButtonRight={() => setServerError(false)}
          modalAccentColor={ModalAccentColor.RED}
          modalStyle={styles.modal}
          withBackground
        />
      )}
    </div>
  );
};

export { ResendConfirmEmailButton };
