import { useTranslation } from 'react-i18next';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as Lock } from 'assets/icons/lock.svg';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import { ReactComponent as Alert } from 'assets/icons/alert.svg';
import React, { useContext, useState } from 'react';
import { appActions, AppContext } from 'context';
import { classnames } from '@mapix/common/src/helpers/utils';
import { AppLink, goToPage, RouteName } from 'routes';
import { Menu } from 'common/menu';
import { Modal } from '@mapix/common/src/common/modal';
import { Breakpoints } from 'common/enums';
import { SessionController } from 'networking/controllers/session-controller';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { ModalResult } from 'common/modal-result';
import { Storage, StorageKeys } from 'helpers/storage';
import { ButtonClose } from '@mapix/common/src/common/button-close';
import { ChangePassword } from 'pages/change-password';
import { ReactComponent as DollarSign } from 'assets/icons/dollar.svg';
import styles from './my-profile.module.scss';

const MyProfile = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AppContext);
  const [showError, setShowError] = useState(false);
  const [showModalLogout, setShowModalLogout] = useState(false);
  const [showModalChangePassword, setShowModalChangePassword] = useState(false);

  type List = {
    name: string,
    route: RouteName,
  };
  const listPath: List[] = [
    { name: t('myProfile.home'), route: RouteName.Home },
    { name: t('myProfile.title'), route: RouteName.MyProfile },
  ];

  const mobile = window.matchMedia(`(max-width: ${Breakpoints.sm}px)`).matches;

  const handleLogout = async () => {
    try {
      await SessionController.logout();
      dispatch({ type: appActions.SESSION_EXPIRED });
      Storage.remove(StorageKeys.PropertyKey);
      goToPage(RouteName.Login);
    } catch (exception) {
      setShowError(true);
    }
  };
  return (
    <>
      <div>
        {showModalLogout
      && (
      <ModalResult
        Icon={Logout}
        title={t('myProfile.confirmLogout')}
        handleButtonRight={() => handleLogout()}
        handleButtonLeft={() => setShowModalLogout(false)}
        buttonTextLeft={t('myProfile.cancel')}
        buttonTextRight={t('myProfile.logout')}
        handleButtonClose={() => setShowModalLogout(false)}
        iconStyle={styles.logoutIcon}
      />
      )}
      </div>
      {showModalChangePassword && (
      <Modal>
        <ChangePassword close={() => { setShowModalChangePassword(false); }} />
      </Modal>
      )}
      {showError
      && <ErrorMessage message={t('error.errorMessage')} handleClose={() => setShowError(false)} />}
      <Menu list={listPath} className={styles.menu} />
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <div className={styles.text}>
            <div className={styles.column}>
              <p className={classnames(styles.title, 'text__heading4__textNeutral40')}>
                {t('myProfile.title')}
              </p>
              {state.user.loginRole === 'Manager' && (
              <p className="text__body__small__textNeutral30">
                {t('myProfile.subtitle')}
                .
              </p>
              )}
            </div>
          </div>
          <div className={styles.button}>
            <ButtonClose
              closeText={t('myProfile.close')}
              closeFn={() => goToPage(state.user.loginRole === 'Tenant' ? RouteName.HomeTenant : RouteName.Properties)}
            />
          </div>
        </div>
        {(state.user.name === '' || state.user.phoneNumber === '' || state.user.name === null
          || state.user.phoneNumber === null) && (
          <div className={styles.infoRequired}>
            <div className={styles.alertIcon}>
              <Alert />
            </div>
            <p className="text__body__small__warning50">{t('myProfile.fillInfo')}</p>
          </div>
        )}
        <div className={styles.data}>
          <div className={styles.column}>
            <p className="text__body__medium__textNeutral40">
              {t('myProfile.name')}
              :
            </p>
            <p className={classnames(styles.realData, 'text__body__small__textNeutral50')}>
              {state.user.name}
            </p>
          </div>
          <div className={styles.column}>
            <p className="text__body__medium__textNeutral40">
              {t('myProfile.lastName')}
              :
            </p>
            <p className={classnames(styles.realData, 'text__body__small__textNeutral50')}>
              {state.user.lastName}
            </p>
          </div>
          <div className={styles.column}>
            <p className="text__body__medium__textNeutral40">
              {t('myProfile.contactPhone')}
              :
            </p>
            <p className={classnames(styles.realData, 'text__body__small__textNeutral50')}>
              {`${state.user.countryCode}${state.user.phoneNumber}`}
            </p>
            <p className={classnames(styles.realData, 'text__body__small__textNeutral50')}>
              {`${state.user.additionalCountryCode}${state.user.additionalPhoneNumber}`}
            </p>
          </div>
          <div className={styles.column}>
            <p className="text__body__medium__textNeutral40">
              {t('myProfile.email')}
              :
            </p>
            <p className={classnames(styles.realData, 'text__body__small__textNeutral50')}>
              {state.user.email}
            </p>
          </div>
        </div>
        <div className={styles.buttons}>
          <AppLink
            className={classnames(styles.buttonText, mobile ? '' : styles.editButton)}
            routeName={RouteName.EditProfile}
          >
            <div className="row text__body__medium__primary40">
              {t('myProfile.edit')}
              <Edit className={styles.editIcon} />
            </div>
          </AppLink>
          {!state.user.googleAuth && (
          <button className={styles.buttonText} onClick={() => setShowModalChangePassword(true)} type="button">
            <div className="row text__body__medium__primary40">
              {t('myProfile.changePassword')}
              <Lock className={styles.editIcon} />
            </div>
          </button>
          )}
          { state.user.loginRole === 'Manager'
          // TODO payment information remove this condition
          // We want to take out the payment information functionality for now
          && false
          && (
          <AppLink
            className={classnames(styles.buttonText, mobile ? '' : styles.editButton)}
            routeName={RouteName.ManagerPaymentInformation}
          >
            <div className="row text__body__medium__primary40">
              {t('myProfile.paymentInfo')}
              <DollarSign className={styles.logoutIconSmall} />
            </div>
          </AppLink>
          )}
          <button className={styles.buttonText} onClick={() => setShowModalLogout(true)} type="button">
            <div className="row text__body__medium__primary40">
              {t('myProfile.logout')}
              <Logout className={styles.logoutIconSmall} />
            </div>
          </button>
        </div>
      </div>

    </>

  );
};

export { MyProfile };
