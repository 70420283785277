import { Users } from 'common/enums';
import { withAuth } from 'hocs/auth/with-auth';
import { LayoutType, withLayout } from 'hocs/with-layout';
import { RentalsDashboard } from './rentals-dashboard';
import { RentalDashboardBuilding } from './building';

const WrapAuth = withAuth(RentalsDashboard, Users.Manager);
const WrappedRentalsDashboard = withLayout(LayoutType.HeaderDrawer, WrapAuth);

export { WrappedRentalsDashboard as RentalsDashboard };
export { RentalDashboardBuilding };
