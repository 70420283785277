import {
  ChangeEvent, FormEvent, useState, Fragment,
} from 'react';
import { logger } from 'helpers/logger';
import { TextArea } from '@mapix/common/src/common/textarea';
import { Modal } from '@mapix/common/src/common/modal';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { ReactComponent as Alert } from 'assets/icons/alertFilled.svg';
import { ReactComponent as UploadCloud } from '@mapix/common/src/assets/icons/upload-cloud-arrow.svg';
import {
  FileExtension, FileState,
} from '@mapix/common/src/common/enums';
import { ExtendedFile } from '@mapix/common/src/types';
import { FileUploadButton } from 'common/file-upload-button';
import { UploadItemCompact } from '@mapix/common/src/common/upload-item-compact';
import { constants } from 'config/constants';
import { ImageOverlay, ImageOverlayObject } from '@mapix/common/src/common/image-overlay/image-overlay';
import { ResourcesController } from 'networking/controllers/resources-controller';
import { Button, ButtonStyle, ButtonType } from '@mapix/common/src/common/button';
import { TaskMessageRequest } from '@mapix/common/src/types/request';
import { useTranslation } from 'react-i18next';
import {
  HTMLValidationError, addOrRemoveFromArrayObject, checkHTMLErrors, classnames,
} from '@mapix/common/src/helpers/utils';
import { TaskMessage } from '@mapix/common/src/types/private-request';
import { Spinner } from '@mapix/common/src/common/spinner';
import styles from './add-update-modal.module.scss';

type AddUpdateModalProps = {
  requestId: number,
  translPrefix: string,
  closeFn: () => void;
  notifySendMessage: (
    requestId: number, messageRequest: TaskMessageRequest,
  ) => Promise<TaskMessage>;
  notifyRefetchData: () => void;
};

const initOverlayState: ImageOverlayObject = {
  show: false,
  index: 0,
  photos: [],
};

const AddUpdateModal = ({
  requestId, translPrefix, closeFn, notifySendMessage, notifyRefetchData,
}: AddUpdateModalProps) => {
  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState('');

  const [fetchedFiles, setFetchedFiles] = useState<ExtendedFile[]>([]);
  const [pendingFiles, setPendingFiles] = useState<ExtendedFile[]>([]);

  const [overlayState, setOverlayState] = useState<ImageOverlayObject>(initOverlayState);

  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});
  const [fileError, setFileError] = useState('');

  const { t } = useTranslation();

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const target = event.target as HTMLFormElement;
    if (!target.checkValidity()) {
      setFormErrors(checkHTMLErrors(target));
      return;
    }
    try {
      setLoading(true);

      const messageRequest: TaskMessageRequest = {
        text: message,
        taskMessagePhotos: fetchedFiles.map(
          (file: ExtendedFile) => ({ filename: file.filename, url: file.url }),
        ),
      };

      await notifySendMessage(requestId, messageRequest);
      notifyRefetchData();
    } catch (err: any) {
      logger.error(err);
    } finally {
      setLoading(false);
    }
  };

  const removeFileFromListByUrl = (files: ExtendedFile[], fileToRemove: ExtendedFile) => (
    files.filter((f: ExtendedFile) => f.url !== fileToRemove.url)
  );

  const cancelFileUpload = (file: FileType) => {
    setPendingFiles((prevState) => {
      const cancelledFile = prevState.find((item) => item.filename === file.filename);
      if (cancelledFile) {
        const updatedPrevState = addOrRemoveFromArrayObject(prevState, cancelledFile, 'filename');
        cancelledFile.state = FileState.CANCELLED;
        return [...updatedPrevState, cancelledFile];
      }
      return prevState;
    });
  };

  const handleUploadNewFile = async (currentFile: File) => {
    const file: ExtendedFile = {
      filename: currentFile.name,
      state: FileState.LOADING,
      resource: currentFile,
      url: '',
    };

    setPendingFiles((prevState) => [...prevState, file]);

    try {
      const result = await ResourcesController.uploadFileV2(file);

      const uploadedFile: ExtendedFile = {
        state: FileState.COMPLETED,
        filename: file.filename,
        url: result,
      };

      setFetchedFiles((prevState) => [...prevState, uploadedFile]);

      const pendingFilesUpdated = removeFileFromListByUrl(pendingFiles, uploadedFile);
      setPendingFiles(pendingFilesUpdated);
    } catch (ex: any) {
      logger.error(ex);
    }
  };

  const showOverlay = (index: number, photos: FileType[]) => {
    setOverlayState({ index, photos, show: true });
  };

  const hideOverlay = () => {
    setOverlayState((prevState) => ({ ...prevState, show: false }));
  };

  const showFileError = () => (
    <div className={styles.fileErrorContainer}>
      <p className={classnames('text__body__semi__bold__small__textNeutral40', styles.bold)}>
        {fileError}
      </p>
      <Alert className={styles.alertIcon} />
    </div>
  );

  const removeFile = (file: ExtendedFile) => {
    const pendingFilesUpdated = removeFileFromListByUrl(fetchedFiles, file);
    setFetchedFiles(pendingFilesUpdated);
  };

  const showFetchedFiles = () => (
    fetchedFiles.map((file, index) => (
      <Fragment key={file.url + file.filename}>
        <UploadItemCompact
          onClickFn={() => showOverlay(index, fetchedFiles)}
          removeFn={() => removeFile(file)}
          filename={file.filename}
          url={file.url}
          t={t}
          className={styles.row}
          showMiniPhoto
        />
      </Fragment>
    ))
  );

  const showPendingFiles = () => (
    pendingFiles?.map((file) => (
      <UploadItemCompact
        removeFn={() => cancelFileUpload(file)}
        fileState={file.state}
        filename={file.filename}
        t={t}
        key={file.url + file.filename}
        className={styles.row}
      />
    ))
  );

  const showImageOverlay = () => (
    <ImageOverlay
      photos={overlayState.photos}
      selectedIndex={overlayState.index}
      closeFn={hideOverlay}
      t={t}
    />
  );

  const showSmallSpinner = () => (
    <div className={classnames(styles.button, styles.smallSpinner)}>
      <Spinner small fixed={false} />
    </div>
  );

  const showConfirmButton = () => (
    <Button
      className={classnames(styles.button, 'text__body__medium__textNeutral10')}
      buttonType={ButtonType.Submit}
    >
      {t(`${translPrefix}.buttonRight`)}
    </Button>
  );

  return (
    <>
      {overlayState.show && showImageOverlay()}

      <Modal>
        <form onSubmit={onSubmit} noValidate className={styles.modalContainer}>
          <div className={styles.crossContainer}>
            <button type="button" onClick={closeFn}>
              <Cross className={styles.crossIcon} />
            </button>
          </div>

          <div className={classnames(styles.title, 'text__heading6__textNeutral50')}>
            {t(`${translPrefix}.title`)}
          </div>

          <div className="mt-20 mb-30">
            <TextArea
              id="message"
              required
              value={message}
              type="text"
              placeholder={t(`${translPrefix}.message`)}
              maxLength={200}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setMessage(e.target.value)}
              containerClass={styles.description}
              formError={formErrors}
              t={t}
            />
          </div>

          <div className={styles.filesContainer}>
            {!!fetchedFiles.length && showFetchedFiles()}

            {!!pendingFiles.length && showPendingFiles()}
          </div>

          <FileUploadButton
            icon={<UploadCloud />}
            text={t(`${translPrefix}.uploadPicture`)}
            supportedExtensions={[FileExtension.Jpg, FileExtension.Jpeg, FileExtension.Png]}
            maxFileSizeInMb={constants.imageMaxSize}
            notifyNewFile={(file: File) => handleUploadNewFile(file)}
            notifyErrorFileMessage={(errorMessage: string) => setFileError(errorMessage)}
          />

          { fileError && showFileError()}

          <div className={styles.buttons}>
            <Button
              buttonStyle={ButtonStyle.Secondary}
              className={classnames(styles.button, 'text__body__medium__textNeutral10')}
              onClick={closeFn}
            >
              {t(`${translPrefix}.buttonLeft`)}
            </Button>
            {loading ? showSmallSpinner() : showConfirmButton()}
          </div>
        </form>
      </Modal>
    </>
  );
};

export { AddUpdateModal };
