import { constants } from 'config/constants';
import { classnames } from '../../helpers/utils';
import styles from './spinner.module.scss';

type SpinnerProps = {
  className?: string,
  fixed?: boolean,
  small?: boolean,
};

const handleSpinnerStyle = (fixed: boolean, small: boolean) => {
  if (fixed) {
    return styles.spinnerFixed;
  }
  if (small) {
    return styles.spinnerSmall;
  }
  return styles.spinner;
};

const Spinner = ({ className = '', fixed = true, small = false }: SpinnerProps) => (
  <img
    alt="spinner animation"
    src={`${constants.publicUrl}/splashscreen.gif`}
    data-testid="spinner-test"
    className={classnames(handleSpinnerStyle(fixed, small), className)}
  />
);

export { Spinner };
