import { Bill } from 'models/bill';
import { SimpleUnit } from 'models/simple-unit';
import { Contractor } from './contractor';
import { Property } from './property';
import { TaskEvent } from './task-event';
import { Unit } from './unit';
import { User } from './user';

class Task {
  property: Property;

  category: string;

  id: number;

  startDate: string;

  contractor?: Contractor;

  status: string;

  unitNumber: string;

  description: string;

  priority: string;

  photos: FileType[];

  cost: string;

  endDate: string;

  additionalInformation: string;

  rejectionDate: string;

  rejectionReason?: string;

  contactMethod?: string;

  unit: SimpleUnit;

  manager?: User;

  taskEvents: TaskEvent[];

  bill: Bill | null;

  billedAmount: number | null;

  email: boolean;

  sms: boolean;

  constructor(params: SerializedTaskView | null) {
    this.property = new Property(params?.property || null);
    this.category = params?.category || '';
    this.id = params?.id || -1;
    this.startDate = params?.startDate || '';
    this.contractor = new Contractor(params?.contractor || null);
    this.status = params?.status || '';
    this.unitNumber = params?.unitNumber || '';
    this.description = params?.description || '';
    this.priority = params?.priority || '';
    this.photos = params?.photos || [];
    this.cost = params?.cost || '';
    this.endDate = params?.endDate || '';
    this.additionalInformation = params?.additionalInfo || '';
    this.rejectionDate = params?.rejectionDate || '';
    this.rejectionReason = params?.rejectionReason || '';
    this.contactMethod = params?.contactMethod || '';
    this.unit = params?.unit ? new SimpleUnit(params.unit) : new Unit(null);
    this.manager = params?.manager || new User(null);
    this.taskEvents = params?.taskEvents
      ? params.taskEvents.map((event) => new TaskEvent(event))
      : [];
    this.bill = params?.bill ? new Bill(params.bill) : null;
    this.billedAmount = params?.bill ? params.bill.billedAmount : null;
    this.sms = params?.sms || false;
    this.email = params?.email || false;
  }
}

export { Task };
