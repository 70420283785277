import { useTranslation } from 'react-i18next';
import {
  ChangeEvent, FormEvent, useContext, useState,
} from 'react';
import { AppContext } from 'context';
import {
  classnames, checkHTMLErrors, isEmptyObject, addError,
} from '@mapix/common/src/helpers/utils';
import { AppLink, goToPage, RouteName } from 'routes';
import { Menu } from 'common/menu';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { ReactComponent as User } from 'assets/icons/user.svg';
import { Button, ButtonStyle, ButtonType } from '@mapix/common/src/common/button';
import { ModalResult } from 'common/modal-result';
import { UserController } from 'networking/controllers/user-controller';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { Spinner } from '@mapix/common/src/common/spinner';
import { PhoneInput } from '@mapix/common/src/common/phone-input';
import styles from './edit-profile.module.scss';

type FormType = {
  [key: string]: string,
  email: string,
  name: string,
  lastName: string,
  contactPhone: string,
  contactCountryCode: string,
  additionalContactPhone: string,
  additionalContactCountryCode: string,
};

const EditProfile = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [showError, setShowError] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [phoneErrors, setPhoneErrors] = useState({});
  const { state } = useContext(AppContext);
  type List = {
    name: string,
    route: RouteName,
  };
  const listPath: List[] = [
    { name: t('myProfile.home'), route: RouteName.Home },
    { name: t('myProfile.title'), route: RouteName.MyProfile },
    { name: t('editProfile.title'), route: RouteName.EditProfile },
  ];
  const [formData, setFormData] = useState<FormType>({
    email: state.user.email,
    name: state.user.name,
    lastName: state.user.lastName,
    contactPhone: state.user.phoneNumber,
    contactCountryCode: state.user.countryCode,
    additionalContactPhone: state.user.additionalPhoneNumber || '',
    additionalContactCountryCode: state.user.additionalCountryCode || '',
  });

  const onChangePhoneNumber = (
    phone: string,
    countryCode: string,
    phoneProperty: string,
    countryProperty: string,
  ) => {
    setFormData((prevState) => (
      { ...prevState, [phoneProperty]: phone, [countryProperty]: countryCode }));
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, id } = event.target;
    const newData = { ...formData };
    newData[id] = value;
    setFormData(newData);
  };

  const handleClose = () => {
    setShowModal(false);
    goToPage(RouteName.MyProfile);
  };

  const handleSave = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    if (!target.checkValidity() || !formData.contactPhone) {
      setFormErrors(checkHTMLErrors(target));
      if (!formData.contactPhone) {
        addError(t('error.emptyField'), 'contactPhone', setPhoneErrors);
      }
      return;
    }

    if (!isEmptyObject(phoneErrors)) {
      return;
    }

    const user: UserEdit = {
      name: formData.name,
      lastName: formData.lastName,
      additionalPhoneNumber: formData.additionalContactPhone,
      phoneNumber: formData.contactPhone,
      countryCode: formData.contactCountryCode,
      additionalCountryCode: formData.additionalContactCountryCode,
    };
    try {
      setFetching(true);
      await UserController.editProfile(user);
      setFetching(false);
      setShowModal(true);
    } catch (ex) {
      setShowError(true);
      setFetching(false);
    }
  };

  if (fetching) {
    return (<Spinner />);
  }

  return (
    <>
      {showError
      && <ErrorMessage message={t('error.errorMessage')} handleClose={() => setShowError(false)} />}
      {showModal && (
        <ModalResult
          handleButtonRight={handleClose}
          title={t('editProfile.modal.title')}
          subtitle={state.user.loginRole === 'Manager' ? t('editProfile.modal.subtitleManager') : t('editProfile.modal.subtitleTenant')}
          buttonTextRight={t('editProfile.modal.ok')}
          Icon={User}
          withCheckIcon
          handleButtonClose={handleClose}
        />
      )}
      <Menu list={listPath} className={styles.menu} />
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <div className={styles.text}>
            <div className={styles.column}>
              <p className={classnames(styles.title, 'text__heading4__textNeutral40')}>
                {t('editProfile.title')}
              </p>
              {state.user.loginRole === 'Manager' && (
                <p className={classnames(styles.subtitle, 'text__body__small__textNeutral30')}>
                  {t('myProfile.subtitle')}
                  .
                </p>
              )}
            </div>
          </div>
        </div>
        <p className={classnames(styles.personalInfo, 'text__heading6__textNeutral50')}>{t('editProfile.personalInfo')}</p>
        <form onSubmit={handleSave} noValidate>
          <div className={styles.data}>
            <div className={styles.column}>
              <div className={styles.row}>
                <Input
                  id="name"
                  containerClass={styles.input}
                  label={t('editProfile.name')}
                  placeholder={t('editProfile.name')}
                  inputStyle={InputStyle.FORM}
                  value={formData.name}
                  onChange={handleChange}
                  required
                  formError={formErrors}
                  helperText={t('editProfile.yourName')}
                  t={t}
                />
                <Input
                  id="lastName"
                  containerClass={styles.input}
                  label={t('editProfile.lastName')}
                  placeholder={t('editProfile.lastName')}
                  inputStyle={InputStyle.FORM}
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                  formError={formErrors}
                  helperText={t('editProfile.yourLastName')}
                  t={t}
                />
              </div>
              <div className={styles.row}>
                <PhoneInput
                  id="contactPhone"
                  containerClass={styles.input}
                  label={t('editProfile.contactPhone')}
                  countryCode={formData.contactCountryCode}
                  phoneWithoutCode={formData.contactPhone}
                  onChangePhoneNumber={(phoneNumber, countryCode) => onChangePhoneNumber(
                    phoneNumber,
                    countryCode,
                    'contactPhone',
                    'contactCountryCode',
                  )}
                  setPhoneErrors={setPhoneErrors}
                  validatePhone={UserController.phoneValidation}
                  required
                  formError={{ ...formErrors, ...phoneErrors }}
                  helperText={t('editProfile.contactPhone')}
                  t={t}
                />
                <PhoneInput
                  id="additionalContactPhone"
                  containerClass={styles.input}
                  label={t('editProfile.additionalContactPhone')}
                  countryCode={formData.additionalContactCountryCode}
                  phoneWithoutCode={formData.additionalContactPhone}
                  onChangePhoneNumber={(phoneNumber, countryCode) => onChangePhoneNumber(
                    phoneNumber,
                    countryCode,
                    'additionalContactPhone',
                    'additionalContactCountryCode',
                  )}
                  formError={{ ...formErrors, ...phoneErrors }}
                  t={t}
                  setPhoneErrors={setPhoneErrors}
                  validatePhone={UserController.phoneValidation}
                />
              </div>
            </div>
            <div className={styles.column}>
              <Input
                id="email"
                containerClass={styles.input}
                label={t('editProfile.email')}
                placeholder={t('editProfile.email')}
                inputStyle={InputStyle.FORM}
                value={formData.email}
                onChange={handleChange}
                required
                formError={formErrors}
                type="email"
                disabled
                t={t}
              />
            </div>
          </div>
          <div className={styles.buttons}>
            <AppLink
              className={classnames(styles.button, styles.left)}
              routeName={RouteName.MyProfile}
            >
              <div className="row text__body__large__primary">
                <Close className={styles.closeIcon} />
                {t('editProfile.cancel')}
              </div>
            </AppLink>
            <Button
              buttonStyle={ButtonStyle.Primary}
              className={classnames(styles.button, styles.right)}
              buttonType={ButtonType.Submit}
            >
              <div className={classnames(styles.button, 'text__body__large__primary')}>
                {t('editProfile.save')}
                <Check className={styles.checkIcon} />
              </div>
            </Button>
          </div>
        </form>
      </div>

    </>

  );
};

export { EditProfile };
