import { Contractor } from 'models/contractor';
import { Paginator } from '../../../../models/paginator';
import { ModalFilterItem, ModalFilterObject } from '../../../../common/filter-modal/filter-modal';

const filters: ModalFilterObject[] = [
  {
    title: 'Category',
    type: 'categories',
    items: [
      { name: 'Plumbing', code: 'Plumbing', parentType: 'categories' },
      { name: 'Electricity', code: 'Electricity', parentType: 'categories' },
      { name: 'Extermination', code: 'Extermination', parentType: 'categories' },
      { name: 'Heating repair', code: 'Heating Repair', parentType: 'categories' },
      { name: 'Minor Repair', code: 'Minor Repair', parentType: 'categories' },
      { name: 'Gardening', code: 'Gardening', parentType: 'categories' },
      { name: 'Snow removing', code: 'Snow Removing', parentType: 'categories' },
      { name: 'Superintendent', code: 'Superintendent', parentType: 'categories' },
      { name: 'Other', code: 'Other', parentType: 'categories' },
    ],
  },
];

type ContractorState = {
  filters: ModalFilterObject[],
  paginator: Paginator<Contractor>,
  appliedFilters: ModalFilterItem[],
  code: string,
  show: boolean,
  input: string,
  query: string,
  deleteContractor: boolean,
};

type Action =
  | { type: 'PAGE_CHANGED', newPage: number }
  | { type: 'MODAL_VISIBILITY', show: boolean }
  | { type: 'APPLY_FILTERS', filters: ModalFilterItem[] }
  | { type: 'CLEAR_FILTERS' }
  | { type: 'CONTRACTORS_FETCHED', paginator: Paginator<Contractor> }
  | { type: 'SEARCH_QUERY', query: string }
  | { type: 'INPUT_CHANGED', input: string }
  | { type: 'SHOW_DELETE_CONTRACTOR', deleteContractor: boolean };

const changePage = (
  currentPage: number,
  paginator: Paginator<Contractor>,
): Paginator<Contractor> => {
  const newPaginator = { ...paginator };
  newPaginator.currentPage = currentPage;
  return newPaginator;
};

const initialState = {
  filters,
  code: 'FETCHING',
  paginator: new Paginator(null, []),
  appliedFilters: [],
  show: false,
  query: '',
  input: '',
  deleteContractor: false,
};

function ContractorsTabReducer(state: ContractorState, action: Action): ContractorState {
  switch (action.type) {
    case 'CONTRACTORS_FETCHED':
      return {
        ...state,
        paginator: action.paginator,
        code: 'READY',
      };
    case 'PAGE_CHANGED':
      return {
        ...state,
        paginator: changePage(action.newPage, state.paginator),
        code: 'FETCHING',
      };
    case 'MODAL_VISIBILITY':
      return {
        ...state,
        show: action.show,
      };
    case 'APPLY_FILTERS':
      return {
        ...state,
        show: false,
        appliedFilters: action.filters,
      };
    case 'CLEAR_FILTERS':
      return {
        ...state,
        appliedFilters: [],
      };
    case 'INPUT_CHANGED':
      return {
        ...state,
        input: action.input,
      };
    case 'SEARCH_QUERY':
      return {
        ...state,
        query: action.query,
        code: 'FETCHING',
      };
    case 'SHOW_DELETE_CONTRACTOR':
      return {
        ...state,
        deleteContractor: action.deleteContractor,
      };
    default:
      return {
        ...state,
      };
  }
}

export { ContractorsTabReducer, initialState };
