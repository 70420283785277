import { ChangeEvent, HTMLProps, SetStateAction } from 'react';
import dayjs from 'dayjs';
import { FilteredSelect, FilteredSelectBehaivor } from '../filtered-select';
import { SwitchInput } from '../switch-input';
import { InputDate } from '../input-date';
import { FormBinaryQuestion } from '../form-binary-question';
import {
  addError, emailPattern, emailRegex, HTMLValidationError, removeError,
} from '../../helpers/utils';
import { Input, InputStyle } from '../input';
import { Select } from '../select';
import type { PaymentInformationState } from '../../types';
import { PhoneInput } from '../phone-input';
import styles from './payment-information.module.scss';

export enum SpecialInputs {
  DATE = 'date',
  SWITCH = 'switch',
  BINARY_QUESTION = 'binaryQuestion',
}

export type SpecialInputType = {
  type: SpecialInputs,
  text?: string
};

export type Restrictions = {
  [key: string]: HTMLProps<HTMLInputElement>
  & { patternErrorMessage?: string, regexp?: RegExp }
};

type PaymentInformationFormGeneratorProps = {
  t: (text: string, key?: {}) => string,
  translPrefix: string,
  state: PaymentInformationState,
  selectOptions?: { [key: string]: string[] | number[] },
  filteredSelectCalls?: { [key: string]: () => Promise<string[]> },
  filteredSelectOptions?: { [key: string]: string[] },
  setState: (value: SetStateAction<PaymentInformationState>) => void
  formErrors: HTMLValidationError,
  helpertexts?: { [key: string]: string },
  optionalInputs?: { [key: string]: boolean },
  setPhoneErrors?: (value: SetStateAction<HTMLValidationError>) => void,
  validatePhone?: (phoneNumber: string, countryCode: string) => Promise<RawPhoneValidation>
  specialInputs?: { [key: string]: SpecialInputType },
  disabled?: { [key: string]: boolean }
  restrictions?: Restrictions
  setFormErrors?: (value: SetStateAction<HTMLValidationError>) => void,
  noTranslation?: { [key: string]: boolean },
};

// Country code key is the same as the phone number key but with
// 'CountryCode' instead of 'PhoneNumber' or 'countryCode' instead of 'phoneNumber'
// (e.g. 'phoneNumber' -> 'countryCode')
const getCountryCodeKey = (key: string) => key
  .replace('PhoneNumber', 'CountryCode').replace('phoneNumber', 'countryCode');

const onBlurCheckPattern = (
  e: React.FocusEvent<HTMLInputElement, Element>,
  key: string,
  t: (key: string) => string,
  translPrefix: string,
  setFormErrors?: (value: SetStateAction<HTMLValidationError>) => void,
  regexp?: RegExp,
  errorMessage?: string,
) => {
  if (regexp && setFormErrors) {
    removeError(key, setFormErrors);
    const { value } = e.target;
    if (value && !regexp.test(value)) {
      addError(errorMessage || t(`${translPrefix}.patternErrorMessages.${key}`), key, setFormErrors);
    }
  }
};

const PaymentInformationFormGenerator = ({
  t, translPrefix, state, selectOptions, setState, formErrors, setPhoneErrors, validatePhone,
  helpertexts, optionalInputs, specialInputs, disabled, restrictions, filteredSelectOptions,
  setFormErrors, noTranslation, filteredSelectCalls,
}: PaymentInformationFormGeneratorProps) => {
  const selects = selectOptions ? Object.keys(selectOptions) : [];
  let filteredSelects = filteredSelectCalls ? Object.keys(filteredSelectCalls) : [];
  filteredSelects = filteredSelectOptions
    ? filteredSelects.concat(Object.keys(filteredSelectOptions)) : filteredSelects;

  const handleSelect = (option: string | number, key: string) => {
    setState((prevState: SetStateAction<PaymentInformationState>) => (
      { ...prevState, [key]: option }));
  };

  const handleInput = (event: ChangeEvent<HTMLInputElement>, key: string) => {
    const { value } = event.target;
    if (setFormErrors) {
      removeError(key, setFormErrors);
    }
    setState((prevState: SetStateAction<PaymentInformationState>) => (
      { ...prevState, [key]: value }));
  };

  const handleBooleanInput = (key: string) => {
    setState((prevState: SetStateAction<PaymentInformationState>) => (
      { ...prevState, [key]: !prevState[key as keyof PaymentInformationState] }));
  };

  const onChangePhoneNumber = (phoneNumber: string, countryCode: string, phoneKey: string) => {
    setState((prevState: SetStateAction<PaymentInformationState>) => (
      { ...prevState, [phoneKey]: phoneNumber, [getCountryCodeKey(phoneKey)]: countryCode }));
  };

  const changeDate = (date: Date, key: string) => {
    setState((prevState: SetStateAction<PaymentInformationState>) => (
      { ...prevState, [key]: dayjs(date).format('YYYY-MM-DD') }));
  };

  const getValue = (key: string) => {
    if (state[key as keyof PaymentInformationState]) {
      if (noTranslation?.[key]) {
        return `${state[key as keyof PaymentInformationState]}`;
      }
      return t(`${translPrefix}.selectOptions.${key}.${state[key as keyof PaymentInformationState]}`);
    }
    return '';
  };

  return (
    <>
      {Object.keys(state).map((key) => {
        // This check is needed because the phone input is a special case
        // Phone number shall always contain the substring "PhoneNumber"
        // and similarly for country codes ("CountryCode")
        if (key.toLowerCase().includes('phonenumber')) {
          return (
            <PhoneInput
              key={key}
              id={key}
              formError={formErrors}
              onChangePhoneNumber={
                (phoneNumber, countryCode) => onChangePhoneNumber(phoneNumber, countryCode, key)
                }
              setPhoneErrors={setPhoneErrors!}
              t={t}
              validatePhone={validatePhone!}
              countryCode={state[getCountryCodeKey(key) as keyof PaymentInformationState]}
              phoneWithoutCode={state[key as keyof PaymentInformationState] as string}
              helperText={helpertexts?.[key]}
              label={t(`${translPrefix}.placeholders.${key}`)}
              plainHelperText={!optionalInputs?.[key]}
              disabled={disabled?.[key]}
              onlyCAandUS
            />
          );
        }

        if (specialInputs?.[key]) {
          switch (specialInputs[key].type) {
            case SpecialInputs.SWITCH:
              return (
                <SwitchInput
                  key={key}
                  onChangeFn={() => handleBooleanInput(key)}
                  text={specialInputs[key].text!}
                  value={!!state[key as keyof PaymentInformationState]}
                  containerClass={styles.allRow}
                />
              );
            case SpecialInputs.DATE:
              return (
                <InputDate
                  key={key}
                  name={key}
                  id={key}
                  t={t}
                  label={t(`${translPrefix}.placeholders.${key}`)}
                  helperText={helpertexts?.[key]}
                  value={state[key as keyof PaymentInformationState] && !disabled?.[key]
                    ? dayjs(state[key as keyof PaymentInformationState]).toDate() : undefined}
                  formErrors={formErrors}
                  placeholder={t(`${translPrefix}.placeholders.${key}`)}
                  onChangeFn={(date: Date) => changeDate(date, key)}
                  disabled={disabled?.[key]}
                  plainHelperText={!optionalInputs?.[key]}
                />
              );
            case SpecialInputs.BINARY_QUESTION:
              return (
                <FormBinaryQuestion
                  key={key}
                  name={key}
                  formErrors={formErrors}
                  onChangeFn={() => handleBooleanInput(key)}
                  question={specialInputs[key].text!}
                  t={t}
                  containerClass={styles.allRow}
                  value={!!state[key as keyof PaymentInformationState]}
                />
              );
            default:
              return null;
          }
        }

        if (selects.includes(key)) {
          return (
            <Select<number | string>
              key={key}
              id={key}
              onClickOption={(option) => handleSelect(option, key)}
              value={getValue(key)}
              options={selectOptions?.[key]! as string[] | number[]}
              placeholder={t(`${translPrefix}.placeholders.${key}`)}
              label={t(`${translPrefix}.placeholders.${key}`)}
              helperText={helpertexts?.[key] || t('selectOption')}
              formError={formErrors}
              translationPrefix={noTranslation?.[key] ? undefined : `${translPrefix}.selectOptions.${key}`}
              t={t}
              plainHelperText={!optionalInputs?.[key]}
              disabled={disabled?.[key]}
            />
          );
        }

        if (filteredSelects.includes(key)) {
          if (filteredSelectOptions?.[key]) {
            return (
              <FilteredSelect
                key={key}
                id={key}
                behavior={FilteredSelectBehaivor.STORED_OPTIONS}
                label={t(`${translPrefix}.placeholders.${key}`)}
                placeholder={t(`${translPrefix}.placeholders.${key}`)}
                onSelectOption={(option: string) => handleSelect(option, key)}
                t={t}
                query={state[key as keyof PaymentInformationState]}
                formError={formErrors}
                storedOptions={filteredSelectOptions?.[key]}
                plainHelperText
              />
            );
          }
          return (
            <FilteredSelect
              key={key}
              id={key}
              behavior={FilteredSelectBehaivor.SINGLE_API_CALL}
              getOptionsCall={filteredSelectCalls?.[key]}
              label={t(`${translPrefix}.placeholders.${key}`)}
              placeholder={t(`${translPrefix}.placeholders.${key}`)}
              onSelectOption={(option: string) => handleSelect(option, key)}
              t={t}
              query={state[key as keyof PaymentInformationState]}
              formError={formErrors}
              plainHelperText
            />
          );
        }

        if (key.toLowerCase().includes('countrycode')) {
          return null;
        }

        return (
          <Input
            key={key}
            id={key}
            t={t}
            inputStyle={InputStyle.FORM}
            placeholder={t(`${translPrefix}.placeholders.${key}`)}
            label={t(`${translPrefix}.placeholders.${key}`)}
            helperText={helpertexts?.[key]}
            value={disabled?.[key] ? '' : state[key as keyof PaymentInformationState]}
            formError={formErrors}
            onChange={(event: ChangeEvent<HTMLInputElement>) => handleInput(event, key)}
            plainHelperText={!optionalInputs?.[key]}
            disabled={disabled?.[key]}
            pattern={key.toLowerCase().includes('email') ? emailPattern() : restrictions?.[key]?.regexp?.source}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...restrictions?.[key]}
            onBlur={(e) => onBlurCheckPattern(
              e,
              key,
              t,
              translPrefix,
              setFormErrors,
              key.toLowerCase().includes('email') ? emailRegex : restrictions?.[key]?.regexp,
              key.toLowerCase().includes('email')
                ? t('error.genericRequirednessError', { fieldName: t('login.email') })
                : restrictions?.[key]?.patternErrorMessage,
            )}
          />
        );
      })}
    </>
  );
};

export { PaymentInformationFormGenerator };
