import React, {
  useState, useCallback, ChangeEvent, useRef, useEffect, SetStateAction,
} from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import {
  addError, classnames, helperTextFn, HTMLValidationError, removeError,
} from '../../helpers/utils';
import 'react-phone-input-2/lib/high-res.css';
import styles from './phone-input.module.scss';
import { InputStyle } from '../enums';

type PhoneInputProps = {
  id: string,
  label?: string,
  helperText?: string,
  errorMessage?: string,
  countryCode: string,
  phoneWithoutCode: string,
  t: (text: string) => string,
  containerClass?: string,
  formError?: HTMLValidationError,
  onChangePhoneNumber: (phoneNumber: string, countryCode: string) => void,
  disabled?: boolean,
  plainHelperText?: boolean,
  required?: boolean,
  validatePhone: (phoneNumber: string, countryCode: string) => Promise<RawPhoneValidation>,
  setPhoneErrors: (value: SetStateAction<HTMLValidationError>) => void,
  onlyCAandUS?: boolean,
};

const defaultCountryCode = '+1';

const PhoneInputWrapper = ({
  id, label, helperText = '', errorMessage = '', t,
  containerClass = '', formError, onChangePhoneNumber, disabled, required = false,
  validatePhone, setPhoneErrors, plainHelperText = false, countryCode = '',
  phoneWithoutCode = '', onlyCAandUS = false,
}: PhoneInputProps) => {
  const currentValue = countryCode + phoneWithoutCode;
  const [phoneValue, setPhoneValue] = useState(currentValue);
  const [rawPhoneValue, setRawPhoneValue] = useState(phoneWithoutCode);
  const [countryCodeValue, setCountryCodeValue] = useState(countryCode || defaultCountryCode);
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(containerRef.current?.offsetWidth);
  const hasFormError = !!(formError && formError[id]);

  const handleHelperTextClassName = () => classnames(
    styles.helperLabel,
    hasFormError || errorMessage
      ? 'text__body__tiny__danger50' : 'text__body__tiny__textNeutral40',
  );

  useEffect(() => {
    if (!containerWidth) {
      setTimeout(() => {
        setContainerWidth(() => containerRef.current?.offsetWidth);
      });
    }
  }, []);

  useEffect(() => {
    if (phoneWithoutCode === '' && countryCode === '') {
      setPhoneValue(defaultCountryCode);
    }
  }, [phoneWithoutCode]);

  const validatePhoneFn = async () => {
    if (rawPhoneValue) {
      try {
        await validatePhone(rawPhoneValue, countryCodeValue);
        removeError(id, setPhoneErrors);
      } catch {
        addError(t('error.phoneNumberError'), id, setPhoneErrors);
      }
    }
  };

  // Check the phone when the user changes the flag :
  useEffect(() => {
    validatePhoneFn();
  }, [countryCodeValue]);

  // This callback prevents the flag from changing if the user selected a phone with less priority
  // that uses the same code as one with more priority (e.g. U.S. and Canada)
  // Extracted from https://github.com/bl00mber/react-phone-input-2/issues/377#issuecomment-841388401
  const handlePhoneChange = useCallback((
    value: string,
    data: CountryData,
    _e: ChangeEvent<HTMLInputElement>,
    formattedValue: string,
  ) => {
    // saving and sending unformatted value to backend
    removeError(id, setPhoneErrors);
    const phoneNumber = value.slice(data.dialCode.length);
    onChangePhoneNumber(phoneNumber, `+${data.dialCode}`);

    // saving raw phone number for  future onblur validation
    setRawPhoneValue(phoneNumber);
    setCountryCodeValue(`+${data.dialCode}`);

    // changing the component state correctly
    setPhoneValue(formattedValue);
  }, []);

  return (
    <div className={classnames(styles.wrapper, containerClass)} ref={containerRef}>
      <PhoneInput
        country="ca"
        value={phoneValue}
        onChange={handlePhoneChange}
        inputClass={classnames(styles.input, 'text__body__large__textNeutral50')}
        containerClass={styles.container}
        buttonClass={disabled ? styles.buttonDisabled : styles.button}
        preferredCountries={['ca', 'us']}
        dropdownClass={styles.content}
        disabled={disabled}
        placeholder={disabled ? '' : label}
        onBlur={validatePhoneFn}
        dropdownStyle={{ width: containerWidth }}
        priority={{ ca: 0, us: 1 }}
        onlyCountries={onlyCAandUS ? ['ca', 'us'] : undefined}
        countryCodeEditable={!onlyCAandUS}
      />

      {label && phoneValue ? (
        <label
          className={classnames(styles.formLabel, 'text__body__tiny__textNeutral40')}
          htmlFor={id}
        >
          {label}
        </label>
      ) : null}

      {(helperText || hasFormError || errorMessage || !required) && (
      <div
        className={handleHelperTextClassName()}
      >
        {(hasFormError || errorMessage)
          ? (formError && formError[id]) || errorMessage
          : (helperTextFn(required, helperText, t, disabled, plainHelperText, InputStyle.FORM))}
      </div>
      )}
    </div>
  );
};

export { PhoneInputWrapper as PhoneInput };
