import { FunctionComponent, SVGProps, useState } from 'react';
import { BlackTooltip } from '../black-tooltip';
import styles from './icon-with-tooltip.module.scss';

type IconWithTooltipProps = {
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>,
  text: string,
  iconClass?: string,
};

const IconWithTooltip = ({ Icon, iconClass, text }: IconWithTooltipProps) => {
  const [show, setShow] = useState(false);
  return (
    <div
      onMouseLeave={() => setShow(false)}
      onMouseEnter={() => setShow(true)}
      className={styles.container}
    >
      {show && <BlackTooltip text={text} />}
      <Icon className={iconClass} />
    </div>
  );
};

export { IconWithTooltip };
