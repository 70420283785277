import {
  ReactNode,
} from 'react';
import { Header } from 'common/header';
import { Footer } from 'common/footer';
import { Drawer } from 'common/drawer';
import styles from './layout.module.scss';

enum LayoutType {
  // Add more layout types here
  Home = 'Home',
  OnlyFooter = 'Footer',
  OnlyHeader = 'Header',
  HeaderDrawer = 'HeaderDrawer',
  HeaderFooter = 'HeaderFooter',
}

type LayoutProps = {
  children: ReactNode,
  layoutType: LayoutType,
};

const Layout = ({ layoutType, children }: LayoutProps) => {
  if (layoutType === LayoutType.Home) {
    return (
      <>
        <Header />
        {children}
        <Footer />
      </>
    );
  }
  if (layoutType === LayoutType.OnlyHeader) {
    return (
      <>
        <Header />
        {children}
      </>
    );
  }
  if (layoutType === LayoutType.OnlyFooter) {
    return (
      <>
        {children}
        <Footer />
      </>
    );
  }
  if (layoutType === LayoutType.HeaderDrawer) {
    return (
      <>
        <Header />
        <div className={styles.row}>
          <Drawer />
          {children}
        </div>
      </>
    );
  }
  if (layoutType === LayoutType.HeaderFooter) {
    return (
      <>
        <Header />
        {children}
        <Footer />
      </>
    );
  }
  return null;
};

export { Layout, LayoutType };
