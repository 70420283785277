import { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { goToPage, RouteName } from 'routes';
import { ModalResult } from 'common/modal-result';
import { ReactComponent as House } from 'assets/icons/house.svg';
import { CreateHeader } from 'common/creation';
import { Condition, PropertyType } from 'common/enums';
import { PropertyController } from 'networking/controllers/property-controller';
import { logger } from 'helpers/logger';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { ReactComponent as FeatherIcon } from 'assets/icons/feather-icons.svg';
import { DetailedDraftProperty } from 'models/detailed-draft-property';
import { CreatePropertyCondoStep1 } from './create-property-condo-step-1';
import { CreatePropertyCondoStep2 } from './create-property-condo-step-2';
import { CreatePropertyCondoStep3 } from './create-property-condo-step-3';
import { CreatePropertyCondoReducer, initialState } from './create-property-condo-reducer';

const translPrefix = 'createCondo';
const translGenericPrefix = 'createGeneric';
const translError = 'error';

type CreatePropertyCondoProps = {
  fetchedProperty?: DetailedDraftProperty
};

const CreatePropertyCondo = ({ fetchedProperty = undefined }: CreatePropertyCondoProps) => {
  const [state, dispatch] = useReducer(CreatePropertyCondoReducer, initialState(fetchedProperty));
  const { t } = useTranslation();

  const saveProperty = async (property: SerializedCreateCondo) => {
    try {
      await PropertyController.savePropertyCondo(property);
      if (state.finish) {
        dispatch({ type: 'FINISH_MODAL' });
      } else {
        goToPage(RouteName.Properties);
      }
    } catch (err) {
      dispatch({ type: 'FINISH_ERROR' });
      logger.error(err as Error);
    }
  };

  useEffect(() => {
    if (state.finish || state.finishDraft) {
      const condo: SerializedCreateCondo = {
        address: state.address,
        status: state.status,
        lease: state.legal && state.legal.currency ? state.legal : undefined,
        owner: state.landlord,
        condition: state.finish ? Condition.Active : Condition.Draft,
        type: PropertyType.CondoHouse,
        otherOwner: state.otherLandlord ? state.otherLandlord : undefined,
        contractors: state.contractors,
        id: state.id,
      };
      saveProperty(condo);
    }
  }, [state.finish, state.finishDraft]);

  const close = () => {
    dispatch({ type: 'DRAFT_MODAL' });
  };

  const backToProperties = () => {
    goToPage(RouteName.Properties);
  };

  return (
    <>
      {state.errorModal
      && (
      <ErrorMessage
        handleClose={() => dispatch({ type: 'CLOSE_ERROR' })}
        message={t(`${translError}.errorMessage`)}
      />
      )}
      {state.modal
      && (
        <ModalResult
          title={t(`${translPrefix}.modalTitle`)}
          subtitle={t(`${translPrefix}.modalSubtitle`)}
          Icon={House}
          withCheckIcon
          buttonTextRight={t(`${translPrefix}.modalButton`)}
          handleButtonRight={backToProperties}
          handleButtonClose={backToProperties}
        />
      )}
      {state.draftModal
      && (
        <ModalResult
          title={t(`${translPrefix}.draftModalTitle`)}
          subtitle={t(`${translPrefix}.draftModalSubTitle`)}
          Icon={FeatherIcon}
          buttonTextRight={t(`${translPrefix}.draftModalOk`)}
          buttonTextLeft={t(`${translPrefix}.draftModalCancel`)}
          handleButtonLeft={() => goToPage(RouteName.Properties)}
          handleButtonRight={() => dispatch({ type: 'FINISH_DRAFT' })}
          handleButtonClose={() => dispatch({ type: 'DRAFT_MODAL' })}
        />
      )}
      <CreateHeader
        closeText={t(`${translGenericPrefix}.close`)}
        title={t(`${translPrefix}.header`)}
        closeFn={close}
        currentStep={state.step}
        stepper={state.steps.map((step) => t(step))}
      />
      {state.step === 0 && (
        <CreatePropertyCondoStep1
          address={state.address}
          owner={state.landlord}
          otherOwner={state.otherLandlord}
          dispatch={dispatch}
        />
      )}
      {state.step === 1 && (
        <CreatePropertyCondoStep2
          dispatch={dispatch}
          fetchedLease={state.legal}
          fetchedStatus={state.status}
        />
      )}
      {state.step === 2 && (
        <CreatePropertyCondoStep3 contractors={state.contractors} dispatch={dispatch} />
      )}
    </>
  );
};

export { CreatePropertyCondo };
