import { Breakpoints } from '@mapix/common/src/common/enums';
import { ModalInformation } from '@mapix/common/src/common/modal-information';
import { Spinner } from '@mapix/common/src/common/spinner';
import { classnames } from '@mapix/common/src/helpers/utils';
import { useMediaQuery } from '@mapix/common/src/hooks/use-media-query';
import {
  RentalTenant,
} from '@mapix/common/src/types';
import { MakePaymentRental } from 'common/make-payment-rental';
import { List, Menu } from 'common/menu/menu';
import { logger } from 'helpers/logger';
import { RentalsController } from 'networking/controllers/rentals-controller';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RouteName, goToPage } from 'routes';

import styles from './make-payment-page.module.scss';

const translPrefix = 'tenantCreatePayment';

const MakePaymentPage = () => {
  const { rentalId } = useParams<{ rentalId: string }>();
  const { t } = useTranslation();
  const [rental, setRental] = useState<RentalTenant | null>(null);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [fetching, setFetching] = useState(true);
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const breadCrumbs: List[] = [
    { name: t(`${translPrefix}.breadcrumbHome`), route: RouteName.HomeTenant },
    { name: t(`${translPrefix}.breadcrumbPayment`), route: RouteName.PaymentsTenant },
    { name: t(`${translPrefix}.breadcrumbMakePayment`), route: RouteName.MakePaymentPage },
  ];

  const getRental = async () => {
    try {
      const response = await RentalsController.getTenantRental(Number(rentalId));
      const rentalData: RentalTenant = {
        id: response.id,
        dueDate: response.dueDate,
        paymentDate: response.paymentDate,
        paymentStatus: response.status,
        amount: response.rentalAmount,
        currency: response.currency,
        fullMonth: response.fullMonth,
        leaseExpirationDate: response.leaseExpirationDate,
        extensionBorderRental: response.extensionBorderRental,
      };
      setRental(rentalData);
      setFetching(false);
    } catch (err: any) {
      logger.error(err);
    }
  };

  useEffect(() => {
    getRental();
  }, []);

  if (fetching) {
    return (
      <Spinner />
    );
  }

  return (
    <div className={styles.container}>
      {mobile && <Menu className={styles.menu} list={breadCrumbs} />}
      <MakePaymentRental
        onClose={() => goToPage(RouteName.PaymentsTenant)}
        t={t}
        rental={rental}
        getRentals={getRental}
        toggleShowInfoModal={() => setShowInfoModal(true)}
      />
      {showInfoModal && (
      <ModalInformation
        handleButtonClose={() => setShowInfoModal(false)}
        handleButtonRight={() => setShowInfoModal(false)}
        buttonsWrapperClass={styles.modalButtonWrapper}
        buttonRightClassStyle={styles.modalButton}
        buttonTextRight={t('ok')}
      >
        <div className={classnames('text__body__medium__textNeutral40', styles.modalText)}>
          {t('tenantPayments.card.amountTooltip')}
        </div>
      </ModalInformation>
      )}
    </div>
  );
};

export { MakePaymentPage };
