import { useTranslation } from 'react-i18next';
import {
  ChangeEvent, HTMLProps, useEffect, useState,
} from 'react';
import { PropertyController } from 'networking/controllers/property-controller';
import { SimpleUnit } from 'models/simple-unit';
import { logger } from 'helpers/logger';
import { Input } from '@mapix/common/src/common/input';
import { classnames } from '@mapix/common/src/helpers/utils';
import styles from './unit-number-input.module.scss';

type UnitNumberInputState = {
  code: string,
  show: boolean,
  selectedUnit: SimpleUnit | null,
  allUnits: SimpleUnit[],
  query: string,
};

type UnitNumberInputProps = {
  disabled?: boolean,
  prefix: string,
  propertyId: number,
  onSelectUnitFn: (unit: SimpleUnit | null) => void,
  query?: string,
  formError?: {},
};

const initState = (query?: string) => ({
  code: 'FETCHING',
  show: false,
  selectedUnit: null,
  query: query || '',
  allUnits: [],
});

const UnitNumberInput = ({
  propertyId,
  onSelectUnitFn,
  disabled,
  prefix,
  query,
  formError,
  /* eslint-disable react/jsx-props-no-spreading */
  ...inputProps
}: UnitNumberInputProps & HTMLProps<HTMLInputElement>) => {
  const [units, setUnits] = useState<SimpleUnit[]>([]);
  const [state, setState] = useState<UnitNumberInputState>(initState(query));
  const { t } = useTranslation();

  const getUnitNumbers = async () => {
    try {
      const result = await PropertyController.getUnits(propertyId);
      setUnits(result);
      setState((prevState) => ({ ...prevState, allUnits: result, code: 'READY' }));
    } catch (err: any) {
      logger.error(err);
      setState((prevState) => ({ ...prevState, code: 'ERROR' }));
    }
  };

  useEffect(() => {
    getUnitNumbers();
  }, []);

  useEffect(() => {
    if (state.query === '') {
      setUnits(state.allUnits);
    } else {
      setUnits(state.allUnits.filter((item) => item.unitNumber.includes(state.query)));
    }
  }, [state.query]);

  const showContent = () => {
    setState((prevState) => ({ ...prevState, show: true }));
  };

  const hideContent = () => {
    setState((prevState) => ({ ...prevState, show: false }));
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    onSelectUnitFn(null);
    setState((prevState) => ({ ...prevState, query: e.target.value }));
  };

  const selectItem = (item: SimpleUnit) => {
    setState((prevState) => ({ ...prevState, selectedUnit: item, query: item.unitNumber }));
    onSelectUnitFn(item);
  };

  return (
    <div className={styles.inputContainer}>
      <Input
        {...inputProps}
        disabled={['ERROR', 'FETCHING'].includes(state.code) || disabled}
        error={state.code === 'ERROR'}
        helperText={state.code === 'ERROR' ? `${prefix}.unitNumberSearchError` : ''}
        id="unitNumbersInput"
        placeholder={t(`${prefix}.unitNumberSearch`)}
        withSearchIcon
        containerClass={styles.inputWidth}
        onFocus={showContent}
        onBlur={hideContent}
        value={disabled ? '' : state.query}
        onChange={onChangeInput}
        formError={formError}
        t={t}
      />
      {state.show && (
        <div className={styles.content}>
          {units.map((unit) => (
            <button
              key={unit.id}
              type="button"
              onMouseDown={() => selectItem(unit)}
              className={classnames(styles.item, 'text__body__small__textNeutral40')}
            >
              {unit.unitNumber}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export { UnitNumberInput };
