import { FileSerializer } from '@mapix/common/src/networking/serializers/file-serializer';
import { RawTaskMessage, TaskMessage } from '@mapix/common/src/types';

class MessageSerializer {
  static deSerializeMessage = (data: RawTaskMessage): TaskMessage => ({
    id: data.id,
    text: data.text,
    createdAt: data.created_at,
    sender: data.sender,
    taskMessagePhotos: data.task_message_photos?.map(
      (elem: FileType) => FileSerializer.serialize(elem),
    ) || [],
  });

  static deSerializeMessages = (data: RawTaskMessage[]): TaskMessage[] => (
    data.map((message) => this.deSerializeMessage(message))
  );
}

export { MessageSerializer };
