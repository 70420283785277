import { PropertyAddress } from 'models/property-address';
import { PropertyOwner } from 'models/property-owner';
import { Currency } from 'common/enums';

class EditRental {
  address: PropertyAddress;

  owner: PropertyOwner;

  tenant: PropertyOwner;

  rentalAmount: number;

  dueDate: string;

  paymentDate?: string;

  unitNumber?: number;

  currency?: string;

  constructor(params: SerializedRental | null) {
    this.address = new PropertyAddress(params?.address || null);
    this.owner = new PropertyOwner(params?.owner || null);
    this.tenant = new PropertyOwner(params?.owner || null);
    this.rentalAmount = params?.rentalAmount || 0;
    this.currency = params?.currency || Currency.USD;
    this.dueDate = params?.dueDate || '';
  }
}

export { EditRental };
