import { TenantSimpleProperty } from 'models/tenant-simple-property';
import { User } from 'models/user';
import { Storage, StorageKeys } from 'helpers/storage';
import { appActions } from './action-types';

export type InitialStateType = {
  user: User,
  logged: boolean,
  redirectWhenLogged: string,
  property: TenantSimpleProperty,
};

const initialUser: UserSerialized = {
  id: 0,
  name: '',
  lastName: '',
  email: '',
  type: '',
  phoneNumber: '',
  countryCode: '',
  normalAuth: false,
  googleAuth: false,
  additionalPhoneNumber: '',
  googleToken: '',
  invite: '',
  condition: '',
  active: false,
};

const initialState = {
  user: new User(initialUser),
  logged: false,
  redirectWhenLogged: '',
  property: (Storage.get(StorageKeys.PropertyKey)) || new TenantSimpleProperty(null),
};

type Action =
 {
   type: string,
   user: User,
   redirectWhenLogged: string,
   property: TenantSimpleProperty,
 };

const AppReducer = (state: InitialStateType, action: Action) => {
  switch (action.type) {
    case appActions.USER_LOGGED:
      return {
        ...state,
        user: action.user,
        logged: true,
      };
    case appActions.SESSION_EXPIRED:
      return {
        ...state,
        user: new User(initialUser),
        logged: false,
        property: new TenantSimpleProperty(null),
      };
    case appActions.SAVE_POST_AUTH_REDIRECT:
      return {
        ...state,
        redirectWhenLogged: action.redirectWhenLogged,
      };
    case appActions.REMOVE_POST_AUTH_REDIRECT:
      return {
        ...state,
        redirectWhenLogged: '',
      };
    case appActions.SAVE_PROPERTY_TENANT:
      return {
        ...state,
        property: action.property,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export { initialState, AppReducer };
