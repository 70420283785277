import { getFullAddress } from 'helpers/utils';
import { Lease } from 'models/lease';
import { PropertyAddress } from './property-address';
import { PropertyOwner } from './property-owner';

class PropertyTenant {
  id: number;

  type?: string;

  address: PropertyAddress;

  owner: PropertyOwner;

  otherOwner?: PropertyOwner;

  buildingName?: string;

  lease?: Lease;

  manager?: PropertyOwner;

  fullAddress: string;

  unitNumber?: string;

  constructor(params: SerializedPropertyTenant | null) {
    this.id = params?.id || 0;
    this.type = params?.type || '';
    this.buildingName = params?.buildingName || '';
    this.address = new PropertyAddress(params?.address);
    this.owner = new PropertyOwner(params?.owner);
    this.otherOwner = new PropertyOwner(params?.otherOwner);
    this.lease = params?.lease ? new Lease(params?.lease) : undefined;
    this.manager = params?.manager ? new PropertyOwner(params?.manager) : undefined;
    this.fullAddress = getFullAddress(this.address);
    this.unitNumber = params?.unitNumber || '';
  }
}

export { PropertyTenant };
