import { ReactComponent as ChevronRight } from '@mapix/common/src/assets/icons/chevron-right.svg';
import { ReactComponent as Info } from '@mapix/common/src/assets/icons/info.svg';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { RawNewBillStatus } from '@mapix/common/src/common/enums';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import { Pagination } from '@mapix/common/src/common/paginator';
import { Spinner } from '@mapix/common/src/common/spinner';
import { Tab } from '@mapix/common/src/common/tab';
import { Tooltip, TooltipPosition } from '@mapix/common/src/common/tooltip';
import {
  cropString,
  getTimeZoneFormat,
  resolveAddress,
} from '@mapix/common/src/helpers/utils';
import { useDebounceEffect } from '@mapix/common/src/hooks/use-debounce';
import { ReactComponent as DollarSign } from 'assets/icons/dollar.svg';
import { BillDetailModal } from 'common/bill-detail-modal';
import { EmptyState } from 'common/empty-state';
import { MakePaymentBill } from 'common/make-payment-bill/make-payment-bill';
import { Table } from 'common/table';
import { MIN_SEARCH_LENGTH } from 'config/constants';
import { groupFilters } from 'helpers/utils';
import { BillsController } from 'networking/controllers/bills-controller';
import { NewBillDashboard, NewBillIndex } from 'networking/types/new-bill';
import {
  ChangeEvent, useEffect, useReducer, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { RouteName, goToPage } from 'routes';
import {
  BillsTableReducer, BillsTabs, initialState,
} from './bills-table-reducerV2';
import styles from './bills-tableV2.module.scss';

type BillsTableProps = {
  withTabs?: boolean,
  fixedTableHeader?: string[] | null,
  fixedDataProperties?: string[] | null,
  propertyId?: number | null,
  unitId?: number,
};

const translPrefix = 'billsV2';

const parseAffectedArea = (bill: NewBillIndex, t: (text: string) => string) => {
  const { buildingName, address: { unitNumber } } = bill;
  if (buildingName) {
    return unitNumber ? `${t('unit')} ${unitNumber}` : t('commonArea');
  }
  return '-';
};

const mapData = (
  bills: NewBillIndex[],
  t: (text: string) => string,
): NewBillDashboard[] => bills.map(
  (bill) => ({
    ...bill,
    property: bill.buildingName || t('condoHouse'),
    address: cropString(resolveAddress(bill.address)),
    affectedArea: parseAffectedArea(bill, t),
    task: bill.taskId ? cropString(`${bill.taskName} (${bill.taskId})`) : '-',
    amount: `${bill.currency} ${bill.totalAmount}`,
    paidOn: bill.paymentDate ? getTimeZoneFormat(bill.paymentDate, 'DD/MM/YYYY') : '-',
    dueDate: bill.dueDate ? getTimeZoneFormat(bill.dueDate, 'DD/MM/YYYY') : '-',
  }),
);

const BillsTableV2 = ({
  withTabs = false, fixedTableHeader = null,
  fixedDataProperties = null, propertyId = null,
  unitId,
}: BillsTableProps) => {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(
    BillsTableReducer,
    initialState(),
  );

  const [resetFilterApiCall, setResetFilterApiCall] = useState(false);

  const {
    tab: statusAsSelectedTab, query, paginator:
    { navigation: { currentPage, pageSize } },
  } = state;

  const trimmedQuery = query.trim();
  const trimmedQueryLength = trimmedQuery.length;

  const getBills = async () => {
    dispatch({ type: 'FETCHING' });
    try {
      let response = null;

      const search = trimmedQueryLength < MIN_SEARCH_LENGTH ? '' : trimmedQuery;

      // TODO use the proper api call in each case if necessary
      if (unitId) {
        response = await BillsController.getBillsV2(
          currentPage,
          pageSize,
          statusAsSelectedTab,
          search,
        );
      } else if (!propertyId) {
        response = await BillsController.getBillsV2(
          currentPage,
          pageSize,
          statusAsSelectedTab,
          search,
        );
      } else {
        response = await BillsController.getBillsV2(
          currentPage,
          pageSize,
          statusAsSelectedTab,
          search,
        );
      }
      if (resetFilterApiCall) setResetFilterApiCall(false);
      dispatch({ type: 'BILLS_FETCHED', paginator: response });
    } catch (err) {
      dispatch({ type: 'ERROR' });
    }
  };

  useEffect(() => {
    /* On modal close, fetch again */
    if (!state.registerPayment) {
      getBills();
    }
  }, [state.tab, state.registerPayment, state.paginator.navigation.currentPage]);

  const triggerDebauncedCall = state.code !== 'FETCHING' && (
    trimmedQueryLength >= MIN_SEARCH_LENGTH || resetFilterApiCall);

  useDebounceEffect(() => {
    if (triggerDebauncedCall) {
      getBills();
    }
  }, [state.query]);

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const newQuery = event.currentTarget.value;
    const newTrimmedQueryLength = newQuery.trim().length;
    const userIsDeletingString = trimmedQueryLength > newTrimmedQueryLength;

    const trimmedQueryIsLessThanMinSearchLengthFromBiggerString = (
      newTrimmedQueryLength === (MIN_SEARCH_LENGTH - 1)
      && trimmedQueryLength >= 0
      && userIsDeletingString
    );
    if (trimmedQueryIsLessThanMinSearchLengthFromBiggerString) {
      setResetFilterApiCall(true);
    }
    dispatch({ type: 'SEARCH_QUERY', query: newQuery });
  };

  const tabChange = (tab: string) => {
    groupFilters(tab, undefined, []);
    dispatch({ type: 'TAB_CHANGED', newTab: tab });
  };

  const clickPagination = (nextPage: number) => {
    if (nextPage !== state.paginator.navigation.currentPage) {
      dispatch({ type: 'PAGE_CHANGED', newPage: nextPage });
    }
  };

  const emtpyStateTitleSuffix = trimmedQueryLength ? 'titleNoMatches' : 'titleEmpty';

  const emptyState = () => (
    <div className={styles.emptyState}>
      <EmptyState
        Icon={DollarSign}
        title={t(`${translPrefix}.emptyState.${emtpyStateTitleSuffix}`)}
        subtitle={t(`${translPrefix}.emptyState.subtitle`)}
        iconStyle={styles.dollarIcon}
        withPlusIcon
        handleOnClick={() => goToPage(RouteName.AddBill)}
      />
    </div>
  );

  const showDetail = (item: NewBillIndex) => {
    if (item.taskId) {
      dispatch({ type: 'SHOW_BILL_DETAIL_MODAL', show: true, bill: item });
    } else {
      // TODO redirect to bill detail page (not implemented yet)
    }
  };

  const iconCells = (bill: NewBillIndex) => {
    const makePayment = (
      <div className={styles.paymentButtonContainer}>
        {!bill.payable && (
          <Tooltip
            textComponent={<Info className={styles.infoIcon} />}
            hoverComponent={(
              <div className={`text__body__small__surface10 ${styles.tooltip}`}>
                {t(`${translPrefix}.buttons.billCannotBePaid`)}
              </div>
                  )}
            position={TooltipPosition.Left}
            spanClass={styles.spanClass}
          />
        )}
        <Button
          onClick={() => dispatch({ type: 'SHOW_MAKE_PAYMENT_MODAL', show: true, selectedBill: bill })}
          buttonStyle={ButtonStyle.Primary}
          disabled={!bill.payable}
        >
          <div className={
            !bill.payable ? 'text__button__small__textNeutral30' : 'text__button__small__textNeutral10'
            }
          >
            {t(`${translPrefix}.buttons.makePayment`)}
          </div>
        </Button>
      </div>
    );

    const registerPayment = (
      <div className={styles.paymentButtonContainer}>
        <Button
          onClick={() => dispatch({ type: 'SHOW_REGISTER_PAYMENT', selectedBill: bill })}
          buttonStyle={ButtonStyle.SecondaryOutlined}
          className={styles.registerPayment}
        >
          <div className="text__button__small__primary60">
            {t(`${translPrefix}.buttons.registerPayment`)}
          </div>
        </Button>
      </div>
    );

    return [bill.taskId ? makePayment : registerPayment];
  };

  const chevronRightFn = (bill: NewBillIndex) => [
    <button type="button" onClick={() => showDetail(bill)}>
      <ChevronRight className={styles.chevron} />
    </button>,
  ];

  const billsTable = () => (
    <>
      <Table
        headerNames={fixedTableHeader || state.headers}
        data={mapData(state.paginator.results, t)}
        dataProperties={fixedDataProperties || state.headers}
        dashboardName={translPrefix}
        uniqueId="id"
        iconCell={statusAsSelectedTab !== RawNewBillStatus.PAID
          ? iconCells : chevronRightFn}
        rowClick={showDetail}
      />
      {state.paginator.navigation.lastPage > 1
      && (
        <div className={styles.paginationContainer}>
          <Pagination
            pageLimit={state.paginator.navigation.lastPage}
            onClickFn={(newPage: number) => clickPagination(newPage)}
            currentPage={state.paginator.navigation.currentPage}
          />
        </div>
      )}
    </>
  );

  const contentToShow = () => {
    if (state.code === 'FETCHING') return <Spinner />;

    if (!state.paginator.results.length) return emptyState();

    return billsTable();
  };

  return (
    <>
      <div className={styles.billsContainer}>
        {withTabs
          && (
            <div className="row">
              {Object.values(BillsTabs).map((tab) => (
                <div key={tab} className={styles.tabContainer}>
                  <Tab
                    name={t(`${translPrefix}.tabs.${tab}`)}
                    isActive={state.tab === tab}
                    onClickFn={() => tabChange(tab)}
                  />
                </div>
              ))}
            </div>
          )}

        {state.registerPayment && (
          // TODO change RegisterPayment component to use the new bill structure
          <div />
        )}

        <div className={styles.filterRow}>
          <Input
            id="search"
            containerClass={styles.input}
            inputStyle={InputStyle.REGULAR}
            withSearchIcon
            placeholder={t(`${translPrefix}.inputPlaceholder`)}
            value={state.query}
            onChange={onChangeInput}
            t={t}
            helperText={query ? t('searchCharacterLimit', { limit: MIN_SEARCH_LENGTH }) : ''}
          />

        </div>

        {contentToShow()}

        {state.showMakePaymentModal && (
        <MakePaymentBill
          bill={state.selectedBill!}
          onClose={() => dispatch({ type: 'SHOW_MAKE_PAYMENT_MODAL', show: false })}
          getBills={getBills}
        />
        )}
        {state.showBillDetailModal && (
          <BillDetailModal
            billId={state.selectedBill!.id}
            handleButtonClose={() => dispatch({ type: 'SHOW_BILL_DETAIL_MODAL', show: false })}
          />
        )}
      </div>
    </>
  );
};

export { BillsTableV2 };
