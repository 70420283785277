import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { InputStyle } from '@mapix/common/src/common/input';
import { Select } from '@mapix/common/src/common/select';
import { appActions, AppContext } from 'context';
import { Storage, StorageKeys } from 'helpers/storage';
import { PropertyTenant } from 'models/property-tenant';
import { TenantSimpleProperty } from 'models/tenant-simple-property';
import { PropertyController } from 'networking/controllers/property-controller';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { logger } from 'helpers/logger';
import styles from './select-property.module.scss';

const SelectProperty = () => {
  const [properties, setProperties] = useState<TenantSimpleProperty[]>([]);
  const [errorServer, setErrorServer] = useState(false);
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AppContext);

  const params = new URL(window.location.href).searchParams;
  const propertyId = params.get('propertyId');
  const unitId = params.get('unitId');

  const onClickSelect = (option: string) => {
    const selectedProperty = properties.find((elem) => (elem.fullAddress === option));
    dispatch({ type: appActions.SAVE_PROPERTY_TENANT, property: selectedProperty });
    Storage.set(StorageKeys.PropertyKey, selectedProperty);
  };

  const getProperties = async () => {
    try {
      const newProperties = await PropertyController.getPropertiesIdsFromTenant();
      setProperties(newProperties);
      const property: PropertyTenant = Storage.get(StorageKeys.PropertyKey);
      if (unitId) {
        let selectedUnit = newProperties.find((prop) => prop.unitId === Number(unitId));
        if (!selectedUnit) {
          logger.error(new Error("A UnitId was provided but it didn't match any of the Tenant's properties"));
          [selectedUnit] = newProperties;
        }
        dispatch({ type: appActions.SAVE_PROPERTY_TENANT, property: selectedUnit });
      } else if (propertyId) {
        let selectedProperty = newProperties.find((prop) => prop.id === Number(propertyId));
        if (!selectedProperty) {
          logger.error(new Error("A PropertyId was provided but it didn't match any of the Tenant's properties"));
          [selectedProperty] = newProperties;
        }
        dispatch({ type: appActions.SAVE_PROPERTY_TENANT, property: selectedProperty });
      } else if (!property && !state.property.id) {
        /*
          property is not saved in localstorage and not saved using redux store
          so i have to save an arbitrary property (in this case, the first one).
        */
        dispatch({ type: appActions.SAVE_PROPERTY_TENANT, property: newProperties[0] });
      } else if (!property) {
        /*
        property is not saved in local storage but saved in redux, so i set the value
        of the last one into the first one.
        . */
        Storage.set(StorageKeys.PropertyKey, state.property);
      } else if (!state.property.id) {
        /*
          property not saved using redux store but saved in local storage, so i set the value
        of the last one into the first one.
        */
        dispatch({ type: appActions.SAVE_PROPERTY_TENANT, property });
      }
    } catch (ex) {
      setErrorServer(true);
    }
  };

  useEffect(() => {
    getProperties();
  }, []);

  return (
    <>
      {errorServer
        && <ErrorMessage message={t('error.errorMessage')} handleClose={() => setErrorServer(false)} />}
      <Select
        containerClass={styles.select}
        backgroundBlue
        options={properties.map((item) => (item.fullAddress))}
        onClickOption={(option) => onClickSelect(option)}
        required
        type="text"
        id="property"
        name="property"
        value={state.property?.fullAddress}
        inputStyle={InputStyle.FORM}
        t={t}
      />
    </>
  );
};

export { SelectProperty };
