import { FormEvent, SetStateAction } from 'react';
import { Breakpoints } from '../../enums';
import { useMediaQuery } from '../../../hooks/use-media-query';
import { Checkbox } from '../../checkbox';
import type { DBAinformationType } from '../../../types';
import {
  asciiWithoutSomeSpecialCharacters,
  classnames,
  HTMLValidationError, postalCodeRegex,
} from '../../../helpers/utils';
import { PaymentInformationTemplate } from '../../payment-information-template';
import {
  PaymentInformationFormGenerator, Restrictions, SpecialInputs,
} from '../payment-information-form-generator';
import styles from '../payment-information.module.scss';

const translPrefix = 'paymentInformation.DBAinformation';

const helperTexts = (t: (text: string) => string) => ({
  postalCode: t(`${translPrefix}.helperTexts.postalCode`),
});

const optionalInputs = () => ({
  emailCustomerService: true,
  unit: true,
});

const disabled = (DBAInformation: DBAinformationType) => ({
  visaOrMastercardRiskProgram: !DBAInformation.visaOrMastercard,
  visaOrMastercardDate: !DBAInformation.visaOrMastercard,
  yearsInBusiness: DBAInformation.lessThanOneYearInBusiness,
});

const specialInputs = (t: (text: string) => string) => ({
  visaOrMastercardDate: {
    type: SpecialInputs.DATE,
  },
  visaOrMastercard: {
    type: SpecialInputs.BINARY_QUESTION,
    text: t(`${translPrefix}.texts.visaOrMastercard`),
  },
  previousProcessor: {
    type: SpecialInputs.BINARY_QUESTION,
    text: t(`${translPrefix}.texts.previousProcessor`),
  },
  lessThanOneYearInBusiness: {
    type: SpecialInputs.SWITCH,
    text: t(`${translPrefix}.texts.lessThanOneYearInBusiness`),
  },
});

const restrictions = (): Restrictions => ({
  yearsInBusiness: {
    min: 1,
    max: 99,
    type: 'number',
  },
  civicNumber: {
    maxLength: 32,
    regexp: /^([0-9]).*$/,
  },
  nameOfBusiness: {
    maxLength: 100,
    // Original: '^((?![\[;=*"\]])(?!(\s/))(?!(/\s))(?!((http(s)?)\:\/\/))[\x00-\x7E])+$'
    // This expressions seems to:
    //    a. Disallow ;, =, *, "          (?![[;=*&quot;\]])
    //    c. Disallow slash after a space (?!(\s\/))
    //    b. Disallow slash before space  (?!(\/\s)
    //    d. Disallow the expression      (?!((http(s)?):\/\/))
    //    e. Allow every other character in the ASCII table (non extended) [\x00-\x7E]
    // eslint-disable-next-line no-control-regex
    regexp: /^((?![[;="\]])(?!(\s\/))(?!(\/\s))(?!((http(s)?):\/\/))[\x00-\x7E])+$/,
  },
  streetName: {
    maxLength: 100,
    regexp: asciiWithoutSomeSpecialCharacters(),
  },
  unit: {
    maxLength: 28,
  },
  city: {
    maxLength: 30,
  },
  postalCode: {
    maxLength: 15,
    regexp: postalCodeRegex(),
  },
  productOrService: {
    maxLength: 100,
  },
  authorizedContact: {
    maxLength: 30,
  },
  visaOrMastercardRiskProgram: {
    maxLength: 256,
  },
});

const noTranslation = () => ({
  monthsInBusiness: true,
  province: true,
});

type DBAinformationProps = {
  t: (text: string) => string,
  backFn: () => void,
  DBAInformation: DBAinformationType,
  setDBAInformation: any,
  formErrors: HTMLValidationError,
  validatePhone: (phoneNumber: string, countryCode: string) => Promise<RawPhoneValidation>
  setPhoneErrors: (value: SetStateAction<HTMLValidationError>) => void,
  setFormErrors: (value: SetStateAction<HTMLValidationError>) => void,
  onSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>,
  selectOptions: { [key: string]: string[] | number[] },
  setSameAsLegalInformation: () => void,
  sameAsLegalInfo: boolean,
  goToHomeFn: () => void,
  goToMyProfileFn: () => void,
  goToPaymentInformationFn: () => void,
};

const DBAinformation = ({
  t, backFn, DBAInformation, setDBAInformation, formErrors,
  validatePhone, setPhoneErrors, onSubmit, setFormErrors, selectOptions,
  setSameAsLegalInformation, sameAsLegalInfo, goToHomeFn, goToMyProfileFn, goToPaymentInformationFn,
}: DBAinformationProps) => {
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  return (
    <PaymentInformationTemplate
      t={t}
      backFn={backFn}
      onSubmit={onSubmit}
      title={t(`${translPrefix}.title`)}
      UpperComponent={(
        <Checkbox
          labelContent={t(`${translPrefix}.sameAsLegalInfo`)}
          onChangeFn={() => setSameAsLegalInformation()}
          checked={sameAsLegalInfo}
          labelClass={classnames('text__body__medium__textNeutral40', styles.sameAsLegalInfoLabel)}
          containerClass={styles.sameAsLegalInfo}
          small={mobile}
        />
    )}
      goToHomeFn={goToHomeFn}
      goToMyProfileFn={goToMyProfileFn}
      goToPaymentInformationFn={goToPaymentInformationFn}
    >

      <PaymentInformationFormGenerator
        t={t}
        state={DBAInformation}
        selectOptions={selectOptions}
        translPrefix={translPrefix}
        setState={setDBAInformation}
        formErrors={formErrors}
        validatePhone={validatePhone}
        setPhoneErrors={setPhoneErrors}
        optionalInputs={optionalInputs()}
        specialInputs={specialInputs(t)}
        disabled={disabled(DBAInformation)}
        restrictions={restrictions()}
        setFormErrors={setFormErrors}
        helpertexts={helperTexts(t)}
        noTranslation={noTranslation()}
      />

    </PaymentInformationTemplate>
  );
};

export { DBAinformation };
