import { EndLeaseTableItem } from 'models/end-lease-table-item';
import { LeaseDetail } from 'models/lease-detail';

type EndLeaseState = {
  code: string,
  endModal: boolean,
  sucessEndLeaseModal: boolean,
  data: EndLeaseTableItem[],
  lease: LeaseDetail,
  optionNotSelected: boolean,
};

type Action =
  | { type: 'RENTALS_FETCHED', data: EndLeaseTableItem[] }
  | { type: 'LEASE_FETCHED', lease: LeaseDetail }
  | { type: 'END_MODAL', endModal: boolean }
  | { type: 'SUCCESS_END_LEASE_MODAL', sucessEndLeaseModal: boolean }
  | { type: 'OPTION_NOT_SELECTED', optionNotSelected: boolean };

const initialState = {
  code: 'FETCHING',
  data: [],
  lease: new LeaseDetail(null),
  endModal: false,
  sucessEndLeaseModal: false,
  optionNotSelected: false,
};

function EndLeaseReducer(state: EndLeaseState, action: Action): EndLeaseState {
  switch (action.type) {
    case 'RENTALS_FETCHED':
      return {
        ...state,
        code: 'READY',
        data: action.data,
      };
    case 'LEASE_FETCHED':
      return {
        ...state,
        code: 'READY',
        lease: action.lease,
      };
    case 'END_MODAL':
      return {
        ...state,
        endModal: action.endModal,
      };
    case 'SUCCESS_END_LEASE_MODAL':
      return {
        ...state,
        sucessEndLeaseModal: action.sucessEndLeaseModal,
        code: 'READY',
      };
    case 'OPTION_NOT_SELECTED':
      return {
        ...state,
        optionNotSelected: true,
      };
    default:
      return {
        ...state,
      };
  }
}

export {
  EndLeaseReducer, initialState,
};
