import { useTranslation } from 'react-i18next';
import { Modal } from '@mapix/common/src/common/modal';
import { ReactComponent as BillPayment } from 'assets/images/bill-payment.svg';
import { InputDate } from '@mapix/common/src/common/input-date';
import dayjs from 'dayjs';
import { BillCreate } from 'models/bill-create';
import { FormEvent, useState } from 'react';
import { checkHTMLErrors, classnames, HTMLValidationError } from '@mapix/common/src/helpers/utils';
import { Button, ButtonStyle, ButtonType } from '@mapix/common/src/common/button';
import { BillsController } from 'networking/controllers/bills-controller';
import { logger } from 'helpers/logger';
import { ModalResult } from 'common/modal-result';
import { ReactComponent as Dollar } from 'assets/icons/dollar.svg';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { Select } from '@mapix/common/src/common/select';
import { Currency } from 'common/enums';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import styles from './register-payment.module.scss';

type RegisterPaymentProps = {
  closeFn: () => void,
  bill: BillCreate,
  billId: number
};

type RegisterPaymentState = {
  successModal: boolean,
  formErrors: HTMLValidationError,
  paymentDate?: string,
  error: boolean
};

const initState: RegisterPaymentState = {
  successModal: false,
  formErrors: {},
  error: false,
};

const translPrefix = 'registerPayment';
const RegisterPayment = ({
  closeFn, bill, billId,
}: RegisterPaymentProps) => {
  const [state, setState] = useState<RegisterPaymentState>(initState);
  const { t } = useTranslation();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    if (!target.checkValidity()) {
      setState((prevState) => ({ ...prevState, formErrors: checkHTMLErrors(target) }));
    } else {
      try {
        const registeredBill = { ...bill, paymentDate: state.paymentDate, unitId: undefined };
        await BillsController.editBills(registeredBill, billId);
        setState((prevState) => ({ ...prevState, successModal: true }));
      } catch (err: any) {
        logger.error(err);
        setState((prevState) => ({ ...prevState, error: true }));
      }
    }
  };

  const changeDate = (date: Date) => {
    setState((prevState) => (
      { ...prevState, paymentDate: dayjs(date).format('YYYY-MM-DD') }));
  };

  return (
    <>
      {state.successModal ? (
        <ModalResult
          title={t(`${translPrefix}.successTitle`)}
          subtitle={t(`${translPrefix}.successSubtitle`)}
          Icon={Dollar}
          withCheckIcon
          buttonTextRight={t(`${translPrefix}.successOk`)}
          handleButtonRight={closeFn}
          handleButtonClose={closeFn}
        />
      )
        : (
          <Modal>
            <div className={styles.paymentContainer}>
              <button className={styles.closeButton} type="button" onClick={closeFn}>
                <Cross className={styles.closeIcon} />
              </button>

              <BillPayment className={styles.billImage} />

              <div className="text__heading5__textNeutral50">{t(`${translPrefix}.title`)}</div>

              <form className={styles.formContainer} noValidate onSubmit={onSubmit}>
                <div className={classnames(styles.formTitle, 'text__heading6__texNeutral50')}>
                  {t(`${translPrefix}.paymentDateTitle`)}
                </div>

                <div className="row">

                  <Select
                    onClickOption={() => null}
                    options={Object.values(Currency)}
                    optionTextClass={classnames(styles.options, 'text__body__small__textNeutral50')}
                    disabled
                    id="currency"
                    label={t(`${translPrefix}.currency`)}
                    placeholder={t(`${translPrefix}.currency`)}
                    value={bill.currency}
                    inputStyle={InputStyle.FORM}
                    containerClass={styles.currency}
                    t={t}
                  />

                  <Input
                    containerClass={styles.input}
                    id="billedAmount"
                    helperText="$"
                    inputStyle={InputStyle.FORM}
                    value={bill.billedAmount}
                    type="number"
                    disabled
                    t={t}
                  />

                  <InputDate
                    id="paymentDate"
                    name="paymentDate"
                    label={t(`${translPrefix}.paymentDatePlaceholder`)}
                    placeholder={t(`${translPrefix}.paymentDatePlaceholder`)}
                    onChangeFn={changeDate}
                    formErrors={state.formErrors}
                    required
                    value={state.paymentDate ? dayjs(state.paymentDate).toDate() : undefined}
                    helperText={t(`${translPrefix}.paymentDateHelper`)}
                    containerClass={styles.input}
                    t={t}
                  />

                </div>

                <div className={styles.buttonRow}>
                  <Button
                    onClick={closeFn}
                    className={styles.largeButton}
                    buttonStyle={ButtonStyle.Secondary}
                  >
                    {t(`${translPrefix}.cancel`)}
                  </Button>
                  <Button
                    className={styles.largeButton}
                    buttonType={ButtonType.Submit}
                    buttonStyle={ButtonStyle.Primary}
                  >
                    {t(`${translPrefix}.confirm`)}
                  </Button>
                </div>

                {state.error && (
                <div className="text__body__medium__danger50">{t(`${translPrefix}.error`)}</div>
                )}

              </form>

            </div>
          </Modal>
        )}
    </>
  );
};

export { RegisterPayment };
