import { AppContext } from 'context';
import { MaintenanceController } from 'networking/controllers/maintenance-controller';
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { NewTask } from 'networking/types';
import { useParams } from 'react-router-dom';
import { Spinner } from '@mapix/common/src/common/spinner';
import { logger } from 'helpers/logger';
import { ReactComponent as Tool } from 'assets/icons/tool.svg';
import { NewTaskDetail, TaskDetailTabs } from 'common/new-task-detail';
import dayjs from 'dayjs';
import { RouteName, goToPage } from 'routes';
import { parseTaskStatus } from 'helpers/utils';
import {
  classnames, getRateEventsList, getTotalPriceByTypeOfCost,
} from '@mapix/common/src/helpers/utils';
import { ButtonStyle } from '@mapix/common/src/common/button';
import { SimpleMessage } from '@mapix/common/src/common/simple-message';
import { ModalAccentColor, ModalResult } from '@mapix/common/src/common/modal-result';
import { newTaskInitialState } from 'initial-states/new-task-initial-state';
import {
  EntityType, RawNewTaskStatus, TaskStatus, TaskType,
} from '@mapix/common/src/common/enums';
import { ModalCancelTask } from '@mapix/common/src/common/modal-cancel-task';
import { ModalCancelledTask } from '@mapix/common/src/common/modal-cancelled-task';
import { TaskMessageRequest } from '@mapix/common/src/types/request';
import { AddUpdateModal } from 'common/add-update-modal';
import { BasicUserFields, CancelTaskData, ModifyCancellationData } from '@mapix/common/src/types';
import { RateTab } from '@mapix/common/src/common/new-task-detail-tabs/rate-tab';
import { KeepTaskAsPreviousStatusModal } from './components/modal-keep-task-as-previous-status';
import { StatusTab } from './components/tabs/status-tab';
import styles from './task-detailV2.module.scss';

type ParamType = {
  id: string,
};

enum ModalToShowState {
  None = 'none',
  ContractorMovedTaskToInProgress = 'contractorMovedTaskToInProgress',
  ContractorMovedTaskToSolved = 'contractorMovedTaskToSolved',
  KeepAsNegotiating = 'keepAsNegotiating',
  KeepAsInProgress = 'keepAsInProgress',
  MoveToSolvedSuccessful = 'moveToSolvedSuccessful',
  MovementToSolvedRejection = 'movementToSolvedRejection',
  CancellationManagerSeen = 'cancellationManagerSeen',
  AddUpdate = 'addUpdate',
  CancelTask = 'cancelTask',
  CancelTaskSuccesful = 'cancelTaskSuccesful',
}

const TaskDetailV2 = () => {
  const { state } = useContext(AppContext);
  const { t } = useTranslation();

  const [fetching, setFetching] = useState(false);
  const [task, setTask] = useState<NewTask>(newTaskInitialState);
  const [currentTab, setCurrentTab] = useState<string>(TaskDetailTabs.Status);

  const [modalToShowState, setModalToShowState] = useState<ModalToShowState>(ModalToShowState.None);

  const translPrefix = 'newTaskDetail';

  const { id } = useParams<ParamType>();

  const isModifyingCancelledTask = useRef(false);

  const wasTaskMovedToNewStatusByContractor = (
    taskDetail: NewTask,
    newStatus: RawNewTaskStatus,
  ): boolean => {
    let contractorValidation = false;
    let managerValidation = false;

    if (newStatus === RawNewTaskStatus.IN_PROGRESS) {
      contractorValidation = taskDetail.inProgressContractorValidation;
      managerValidation = taskDetail.inProgressManagerValidation;
    }

    if (newStatus === RawNewTaskStatus.SOLVED) {
      contractorValidation = taskDetail.solvedContractorValidation;
      managerValidation = taskDetail.solvedManagerValidation;
    }

    return (
      contractorValidation && !managerValidation
    );
  };

  const isNewStatusExpirationDateAfterCurrentDate = (
    taskDetail: NewTask,
    newStatus: RawNewTaskStatus,
  ): boolean => {
    let rejectionExpiration = '';

    if (newStatus === RawNewTaskStatus.IN_PROGRESS) {
      rejectionExpiration = taskDetail.inProgressRejectionExpiration;
    }

    if (newStatus === RawNewTaskStatus.SOLVED) {
      rejectionExpiration = taskDetail.solvedRejectionExpiration;
    }

    return dayjs(
      dayjs(rejectionExpiration).toDate(),
    ).isAfter(dayjs(taskDetail.currentServerTime).toDate());
  };

  const hasContractorMovedTaskByNewStatus = (
    taskDetail: NewTask,
    newStatus: RawNewTaskStatus,
  ): boolean => (
    wasTaskMovedToNewStatusByContractor(taskDetail, newStatus)
    && isNewStatusExpirationDateAfterCurrentDate(taskDetail, newStatus)
  );

  const hasContractorRejectedMovementToSolved = (taskDetail: NewTask) => (
    taskDetail.firstTimeSolvedRejectedManagerSeen
  );

  const getTask = async () => {
    try {
      setFetching(true);
      const taskResponse = await MaintenanceController.getNewTaskDetail(id);
      setTask(taskResponse);

      if (taskResponse.status === RawNewTaskStatus.IN_PROGRESS) {
        if (hasContractorMovedTaskByNewStatus(taskResponse, RawNewTaskStatus.IN_PROGRESS)) {
          setModalToShowState(ModalToShowState.ContractorMovedTaskToInProgress);
          return;
        }

        if (hasContractorRejectedMovementToSolved(taskResponse)) {
          setModalToShowState(ModalToShowState.MovementToSolvedRejection);
        }
      }

      if (taskResponse.status === RawNewTaskStatus.WAITING_MANAGER_CANCELLED_PERCENTAGE_APPROVAL) {
        if (taskResponse.noticeCancellationManagerSeen) {
          setModalToShowState(ModalToShowState.CancellationManagerSeen);
        }
      }

      if (taskResponse.status === RawNewTaskStatus.SOLVED
          && hasContractorMovedTaskByNewStatus(taskResponse, RawNewTaskStatus.SOLVED)) {
        setModalToShowState(ModalToShowState.ContractorMovedTaskToSolved);
      }
    } catch (err: any) {
      logger.error(err);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    getTask();
  }, []);

  const handleOpenKeepTaskAsPreviousStatusModal = (status: RawNewTaskStatus) => {
    if (status === RawNewTaskStatus.IN_PROGRESS) {
      setModalToShowState(ModalToShowState.KeepAsNegotiating);
    }

    if (status === RawNewTaskStatus.SOLVED) {
      setModalToShowState(ModalToShowState.KeepAsInProgress);
    }
  };

  const handleConfirmNewStatus = async (newStatus: RawNewTaskStatus) => {
    try {
      let taskResponse: Partial<NewTask> = {};

      if (newStatus === RawNewTaskStatus.IN_PROGRESS) {
        taskResponse = await MaintenanceController.acceptTaskToInProgress(Number(id));
      }

      if (newStatus === RawNewTaskStatus.SOLVED) {
        taskResponse = await MaintenanceController.acceptTaskToSolved(Number(id));
      }

      setTask(taskResponse as NewTask);
    } catch (err: any) {
      logger.error(err);
    } finally {
      setModalToShowState(ModalToShowState.None);
    }
  };

  const handleAcceptRateByManager = async () => {
    setFetching(true);

    try {
      const taskUpdated = await MaintenanceController.acceptRateTask(
        Number(task.id),
      );
      setTask(taskUpdated);
    } catch (err: any) {
      logger.log(err);
    } finally {
      setFetching(false);
    }
  };

  const getManagerData = (): BasicUserFields => {
    const { lastName, name } = state.user;

    return {
      id: state.user.id,
      lastName,
      name,
      profilePhoto: null,
    };
  };

  const getBodyContent = () => {
    switch (currentTab) {
      case TaskDetailTabs.Status:
        return (
          <StatusTab
            className={styles.tabsContainer}
            task={task!}
            translPrefix={`${translPrefix}.tabs`}
            notifyAddNewUpdate={() => setModalToShowState(ModalToShowState.AddUpdate)}
          />
        );
      case TaskDetailTabs.Rate:
        return (
          <RateTab
            className={styles.tabsContainer}
            entityType={EntityType.Task}
            contractor={task!.contractor}
            manager={getManagerData()}
            events={getRateEventsList(task.newTaskEvents)}
            requestOrTaskId={task.id}
            rawStatus={task.status}
            typeOfCost={{ ...task }}
            modifyRate={MaintenanceController.modifyRateTask}
            notifyAcceptRate={handleAcceptRateByManager}
            refreshData={getTask}
            t={t}
          />
        );
      default:
        return null;
    }
  };

  const handleBackKeepTaskAsPreviousStatusModal = (status: RawNewTaskStatus) => {
    if (status === RawNewTaskStatus.IN_PROGRESS) {
      setModalToShowState(ModalToShowState.ContractorMovedTaskToInProgress);
    }

    if (status === RawNewTaskStatus.SOLVED) {
      setModalToShowState(ModalToShowState.ContractorMovedTaskToSolved);
    }
  };

  const handleRejectTaskByStatus = async (message: string) => {
    const { status, id: taskId } = task!;

    if (status === RawNewTaskStatus.IN_PROGRESS) {
      await MaintenanceController.rejectTaskToInProgress(taskId, message);
    }

    if (status === RawNewTaskStatus.SOLVED) {
      await MaintenanceController.rejectTaskToSolved(taskId, message);
    }
  };

  const handleSuccesKeepTaskAsPreviousStatusModal = (
    taskType: TaskType,
    status: RawNewTaskStatus,
  ) => {
    if (status === RawNewTaskStatus.IN_PROGRESS) {
      if (taskType === TaskType.PublicTask) {
        goToPage(RouteName.PublicRequestDetail, { id: task?.activeRequestAssignedId! });
      } else {
        goToPage(RouteName.PrivateRequestDetail, { id: task?.activeRequestAssignedId! });
      }
    }

    if (status === RawNewTaskStatus.SOLVED) {
      setModalToShowState(ModalToShowState.None);
      getTask();
    }
  };

  const refetchTask = () => {
    setModalToShowState(ModalToShowState.None);
    getTask();
  };

  const handleCancelTaskAction = () => {
    setModalToShowState(ModalToShowState.CancelTask);
  };

  const handleCancelTask = async (data: CancelTaskData) => {
    try {
      setFetching(true);
      const taskResponse = await MaintenanceController.cancelNewTask(Number(id), data);

      setCurrentTab(TaskDetailTabs.Status);
      setTask(taskResponse);
      setModalToShowState(ModalToShowState.CancelTaskSuccesful);
    } catch (err) {
      logger.log(err);
    } finally {
      setFetching(false);
    }
  };

  const handleModifyCancelledTask = async (data: ModifyCancellationData) => {
    try {
      setFetching(true);
      const taskResponse = await MaintenanceController.changeCancelPercentage(Number(id), data);

      setTask(taskResponse);
      setModalToShowState(ModalToShowState.None);
    } catch (err) {
      logger.log(err);
    } finally {
      setFetching(false);
    }
  };

  const handleActionCancelledTask = (data: CancelTaskData) => {
    if (isModifyingCancelledTask.current) {
      const requestData: ModifyCancellationData = {
        cancellationPercentage: data.cancellationPercentage,
        comments: data.comments,
      };

      handleModifyCancelledTask(requestData);
      isModifyingCancelledTask.current = false;
    } else {
      handleCancelTask(data);
    }
  };

  const handleAcceptCancellation = async () => {
    try {
      setFetching(true);
      const taskResponse = await MaintenanceController.acceptCancellationPercentage(task!.id);
      setTask(taskResponse);
    } catch (err: any) {
      logger.log(err);
    } finally {
      setFetching(false);
    }
  };

  const handleSolvedTaskAction = async () => {
    try {
      const actualTask = await MaintenanceController.moveTaskToSolved(task!.id);
      setModalToShowState(ModalToShowState.MoveToSolvedSuccessful);
      setTask(actualTask);
    } catch (err: any) {
      logger.error(err as Error);
    }
  };

  const handleSeeBilling = () => {
    goToPage(RouteName.BillsTable);
  };

  const handleModifyCancelation = () => {
    isModifyingCancelledTask.current = true;
    setModalToShowState(ModalToShowState.CancelTask);
  };

  const handleConfirmMessage = () => {
    setModalToShowState(ModalToShowState.None);
    getTask();
  };

  const getTextButtonLeftByTaskStatus = (status: RawNewTaskStatus) => {
    switch (parseTaskStatus(status)) {
      case TaskStatus.IN_PROGRESS:
        return t(`${translPrefix}.footer.cancelTask`);
      case TaskStatus.SOLVED:
      case TaskStatus.CANCELLED:
        return t(`${translPrefix}.footer.seeBilling`);
      case TaskStatus.WAITING_MANAGER_CANCELLED_PERCENTAGE_APPROVAL:
        return t(`${translPrefix}.footer.modifyCancelation`);
      default:
        return '';
    }
  };

  const getTextButtonRightByTaskStatus = (status: RawNewTaskStatus) => {
    switch (parseTaskStatus(status)) {
      case TaskStatus.IN_PROGRESS:
        return t(`${translPrefix}.footer.markAsSolved`);
      case TaskStatus.WAITING_MANAGER_CANCELLED_PERCENTAGE_APPROVAL:
        return t(`${translPrefix}.footer.acceptCancelation`);
      case TaskStatus.SOLVED:
      case TaskStatus.CANCELLED:
        return '';
      default:
        return '';
    }
  };

  const getLeftActionByTaskStatus = (status: RawNewTaskStatus) => {
    switch (parseTaskStatus(status)) {
      case TaskStatus.IN_PROGRESS:
        handleCancelTaskAction();
        break;
      case TaskStatus.WAITING_MANAGER_CANCELLED_PERCENTAGE_APPROVAL:
        handleModifyCancelation();
        break;
      case TaskStatus.SOLVED:
      case TaskStatus.CANCELLED:
        handleSeeBilling();
        break;
      default:
        throw new Error('Invalid task status');
    }
  };

  const getRightActionByTaskStatus = (status: RawNewTaskStatus) => {
    switch (parseTaskStatus(status)) {
      case TaskStatus.IN_PROGRESS:
        handleSolvedTaskAction();
        break;
      case TaskStatus.WAITING_MANAGER_CANCELLED_PERCENTAGE_APPROVAL:
        handleAcceptCancellation();
        break;
      case TaskStatus.SOLVED:
      case TaskStatus.CANCELLED:
        handleSeeBilling();
        break;
      default:
        throw new Error('Invalid task status');
    }
  };

  const keepAsPreviousStatusInnerTranslateValue = (): string => {
    switch (task?.status) {
      case RawNewTaskStatus.IN_PROGRESS:
        return 'keepAsNegotiatingModal';
      case RawNewTaskStatus.SOLVED:
        return 'keepAsInProgressModal';
      default:
        return '';
    }
  };

  const moveToNewStatusByContractorTranslateValue = (): string => {
    switch (task?.status) {
      case RawNewTaskStatus.IN_PROGRESS:
        return 'moveToInProgressByContractorSuccessfullModal';
      case RawNewTaskStatus.SOLVED:
        return 'moveToSolvedByContractorSuccessfullModal';
      default:
        return '';
    }
  };

  const getKeepTaskAsPreviousStatusTitle = () => (
    t(`${translPrefix}.${keepAsPreviousStatusInnerTranslateValue()}.title`)
  );

  const getKeepTaskAsPreviousStatusContent = (currentStatus: RawNewTaskStatus) => {
    if (currentStatus === RawNewTaskStatus.IN_PROGRESS) {
      return t(`${translPrefix}.${keepAsPreviousStatusInnerTranslateValue()}.subtitle`);
    }

    if (currentStatus === RawNewTaskStatus.SOLVED) {
      return (
        <>
          <p>
            {t(`${translPrefix}.${keepAsPreviousStatusInnerTranslateValue()}.subtitle`)}
          </p>
          <p className={classnames('m-block-20', 'text__body__small__textNeutral30')}>
            {t(`${translPrefix}.keepAsInProgressModal.warning`)}
          </p>
          <p>
            {t(`${translPrefix}.keepAsInProgressModal.extraInformation`)}
          </p>
        </>
      );
    }

    return '';
  };

  const showAlert = (option: string) => option === TaskDetailTabs.Rate
  && (task.status === RawNewTaskStatus.WAITING_CONTRACTOR_RATE_APPROVAL
    || task.status === RawNewTaskStatus.WAITING_MANAGER_RATE_APPROVAL);

  const isDisabledRightButtonByStatus = () => {
    if (task.status === RawNewTaskStatus.IN_PROGRESS
      || task.status === RawNewTaskStatus.WAITING_MANAGER_CANCELLED_PERCENTAGE_APPROVAL
    ) {
      return false;
    }

    return true;
  };

  const getKeepTaskAsPreviousStatusLeftButton = () => (
    t(`${translPrefix}.${keepAsPreviousStatusInnerTranslateValue()}.leftButton`)
  );

  const getKeepTaskAsPreviousStatusRightButton = () => (
    t(`${translPrefix}.${keepAsPreviousStatusInnerTranslateValue()}.rightButton`)
  );

  const handleAcceptSolvedRejection = async () => {
    setModalToShowState(ModalToShowState.None);

    try {
      await MaintenanceController.disableSolvedRejectedFlag(task!.id);
    } catch (err: any) {
      logger.log(err);
    }
  };

  const getPaymentValue = (completionPercentage: number) => (
    (getTotalPriceByTypeOfCost({ ...task! }) * Number(completionPercentage)) / 100
  );

  const handleSendMessage = (requestId: number, messageRequest: TaskMessageRequest) => {
    if (task.taskType === TaskType.PrivateTask) {
      return MaintenanceController.sendPrivateRequestMessage(requestId, messageRequest);
    }

    return MaintenanceController.sendPublicRequestMessage(requestId, messageRequest);
  };

  if (fetching) {
    return (<Spinner />);
  }

  const handleModifyCancellation = () => {
    isModifyingCancelledTask.current = true;
    setModalToShowState(ModalToShowState.CancelTask);
  };

  const showViewContent = () => (
    <NewTaskDetail
      currentTab={currentTab as TaskDetailTabs}
      newTask={task!}
      getTabContent={getBodyContent}
      setCurrentTab={setCurrentTab}
      translPrefix={translPrefix}
      showAlert={showAlert}
      disableRightButton={isDisabledRightButtonByStatus()}
      textButtonLeft={getTextButtonLeftByTaskStatus(task!.status)}
      textButtonRight={getTextButtonRightByTaskStatus(task!.status)}
      leftButtonFn={() => getLeftActionByTaskStatus(task!.status)}
      rightButtonFn={() => getRightActionByTaskStatus(task!.status)}
    />
  );

  const solvedRejectionContentModal = () => {
    const { name, profilePhoto, lastName } = task!.contractor!;

    return (
      <>
        <div className={styles.subtitle}>
          {t(`${translPrefix}.movementToSolvedRejectionModal.subtitle`)}
        </div>
        <div>
          <SimpleMessage
            text={task!.messageForSolvedMovementRejection}
            senderName={name}
            senderLastName={lastName}
            senderUrl={profilePhoto?.url}
            containerClass={styles.contractorMessageBox}
          />
        </div>
      </>
    );
  };

  const handleCloseModal = async () => {
    setModalToShowState(ModalToShowState.None);

    try {
      await MaintenanceController.disableNoticeCancellationFlag(task!.id);
    } catch (err: any) {
      logger.log(err);
    }
  };

  const getCancelData = (): CancelTaskData | null => {
    if (task.status === RawNewTaskStatus.WAITING_MANAGER_CANCELLED_PERCENTAGE_APPROVAL) {
      return {
        reasonForCancellation: task.reasonForCancellation,
        cancellationPercentage: task.cancellationPercentage,
        comments: '',
      };
    }

    return null;
  };

  const showCorrespondingModal = () => {
    if (modalToShowState === ModalToShowState.ContractorMovedTaskToInProgress
        || modalToShowState === ModalToShowState.ContractorMovedTaskToSolved) {
      return (
        <ModalResult
          title={t(`${translPrefix}.${moveToNewStatusByContractorTranslateValue()}.title`)}
          content={t(`${translPrefix}.${moveToNewStatusByContractorTranslateValue()}.subtitle`)}
          Icon={Tool}
          cross={false}
          withBackground
          modalStyle={styles.movedToNewStatusModalContainer}
          modalAccentColor={ModalAccentColor.GREEN}
          buttonLeftStyle={styles.movedToNewStatusButton}
          buttonRightStyle={styles.movedToNewStatusButton}
          buttonTextLeft={t(`${translPrefix}.${moveToNewStatusByContractorTranslateValue()}.buttonLeft`)}
          buttonTextRight={t(`${translPrefix}.${moveToNewStatusByContractorTranslateValue()}.buttonRight`)}
          handleButtonLeft={() => handleOpenKeepTaskAsPreviousStatusModal(task!.status)}
          handleButtonRight={() => handleConfirmNewStatus(task!.status)}
        />
      );
    }

    if (modalToShowState === ModalToShowState.KeepAsNegotiating
      || modalToShowState === ModalToShowState.KeepAsInProgress) {
      const { taskType, status } = task!;

      return (
        <KeepTaskAsPreviousStatusModal
          title={getKeepTaskAsPreviousStatusTitle()}
          content={getKeepTaskAsPreviousStatusContent(status)}
          textLeftButton={getKeepTaskAsPreviousStatusLeftButton()}
          textRightButton={getKeepTaskAsPreviousStatusRightButton()}
          rejectTaskFn={(message: string) => handleRejectTaskByStatus(message)}
          closeFn={() => handleBackKeepTaskAsPreviousStatusModal(status)}
          successFn={() => handleSuccesKeepTaskAsPreviousStatusModal(taskType as TaskType, status)}
        />
      );
    }

    if (modalToShowState === ModalToShowState.MoveToSolvedSuccessful) {
      return (
        <ModalResult
          title={t(`${translPrefix}.moveToSolvedSuccessfulModal.title`)}
          content={t(`${translPrefix}.moveToSolvedSuccessfulModal.subtitle`)}
          Icon={Tool}
          withBackground
          modalStyle={styles.movedToNewStatusModalContainer}
          modalAccentColor={ModalAccentColor.GREEN}
          buttonRightStyle={styles.movedToSolvedModalButton}
          buttonTextRight={t('Ok')}
          handleButtonRight={refetchTask}
          handleButtonClose={refetchTask}
        />
      );
    }

    if (modalToShowState === ModalToShowState.AddUpdate) {
      return (
        <AddUpdateModal
          requestId={Number(task?.activeRequestAssignedId)}
          translPrefix={`${translPrefix}.addNewUpdateModal`}
          closeFn={() => setModalToShowState(ModalToShowState.None)}
          notifySendMessage={(requestId: number, messageRequest: TaskMessageRequest) => (
            handleSendMessage(requestId, messageRequest)
          )}
          notifyRefetchData={() => handleConfirmMessage()}
        />
      );
    }

    if (modalToShowState === ModalToShowState.MovementToSolvedRejection) {
      return (
        <ModalResult
          title={t(`${translPrefix}.movementToSolvedRejectionModal.title`)}
          content={solvedRejectionContentModal()}
          cross={false}
          Icon={Tool}
          withBackground
          modalAccentColor={ModalAccentColor.YELLOW}
          buttonStyle={ButtonStyle.Primary}
          buttonTextRight={t(`${translPrefix}.movementToSolvedRejectionModal.modalButton`)}
          modalStyle={styles.inProgressRejectionModalContainer}
          handleButtonRight={handleAcceptSolvedRejection}
        />
      );
    }

    if (modalToShowState === ModalToShowState.CancelTask) {
      return (
        <ModalCancelTask
          cancelData={getCancelData()}
          totalTaskPrice={getTotalPriceByTypeOfCost({ ...task! })}
          cancellationReasonsApiCall={MaintenanceController.getCancellationReasons}
          isModifying={isModifyingCancelledTask.current}
          t={t}
          notifyActionTask={handleActionCancelledTask}
          handleClose={() => setModalToShowState(ModalToShowState.None)}
        />
      );
    }

    if (modalToShowState === ModalToShowState.CancelTaskSuccesful) {
      return (
        <ModalResult
          title={t(`${translPrefix}.cancelTaskSuccessfulModal.title`)}
          content={t(`${translPrefix}.cancelTaskSuccessfulModal.subtitle`)}
          Icon={Tool}
          withBackground
          modalStyle={styles.movedToNewStatusModalContainer}
          modalAccentColor={ModalAccentColor.GREEN}
          buttonTextRight={t('Ok')}
          handleButtonRight={refetchTask}
          handleButtonClose={refetchTask}
        />
      );
    }

    if (modalToShowState === ModalToShowState.CancellationManagerSeen) {
      const { cancellationMessage, cancelledDate, cancellationPercentage } = task;

      return (
        <ModalCancelledTask
          canceller={task.contractor}
          comments={cancellationMessage}
          reason={t(`reasonOptions.${task.reasonForCancellation}`)}
          completionPercentage={cancellationPercentage}
          paymentAmount={getPaymentValue(cancellationPercentage)}
          cancelationDate={cancelledDate}
          t={t}
          cancelledByManager={false}
          onModifyCancellation={() => handleModifyCancellation()}
          onCloseCancellation={() => handleCloseModal()}
          onAcceptCancellation={() => {
            setModalToShowState(ModalToShowState.None);
            handleAcceptCancellation();
          }}
        />
      );
    }

    return null;
  };

  return (
    task.id ? (
      <>
        {showCorrespondingModal()}
        {showViewContent()}
      </>
    ) : null
  );
};

export { TaskDetailV2 };
