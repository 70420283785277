import { logger } from 'helpers/logger';
import { Dispatch, SetStateAction, useState } from 'react';
import { ReactComponent as Close } from '../../assets/icons/close.svg';
import { ReactComponent as DollarSign } from '../../assets/icons/dollar.svg';
import { useMediaQuery } from '../../hooks/use-media-query';
import {
  CardNonSensitiveData,
  OpenOrderResponse,
  PaymentMethod as PaymentMethodType,
} from '../../types';
import { Breakpoints, MakePaymentModalState } from '../enums';
import { Modal } from '../modal';
import { ModalAccentColor, ModalResult } from '../modal-result';
import { Spinner } from '../spinner';
import { AddCard, NuveiFields } from './add-card/add-card';
import { MakePaymentResult, NuveiResponse, createPayment } from './create-payment';
import { MakePaymentInformationValues } from './make-payment-information';
import styles from './make-payment.module.scss';
import { SelectPaymentMethod } from './select-payment';

type PaymentMethodProps = {
  onClose: () => void,
  t: (text: string) => string,
  email: string,
  storedCards: PaymentMethodType[],
  updatePaymentFn: (updatePayment: NuveiResponse, openOrderId: number) => void,
  refreshData: () => void,
  deletePaymentMethod: (id: number) => Promise<void>,
  makePaymentInformation: MakePaymentInformationValues,
  openOrderData: OpenOrderResponse,
  safeCharge: any,
  translPrefix: string,
  contentToShow: MakePaymentModalState,
  setContentToShow: Dispatch<SetStateAction<MakePaymentModalState>>,
  isBill?: boolean,
};

const initCardNonSensitiveData: CardNonSensitiveData = {
  countryAlpha2Code: '',
  holderName: '',
};

const MakePayment = ({
  onClose, t, email, storedCards, updatePaymentFn, refreshData, openOrderData,
  deletePaymentMethod, makePaymentInformation, safeCharge, translPrefix, isBill,
  contentToShow, setContentToShow,
}: PaymentMethodProps) => {
  const [selectedCard, setSelectedCard] = useState<PaymentMethodType | undefined >(undefined);
  const [nuveiFields, setNuveiFields] = useState<NuveiFields>({} as NuveiFields);
  const [cardNonSensitiveData, setCardNonSensitiveData] = useState<
  CardNonSensitiveData>(initCardNonSensitiveData);
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const handleClose = () => {
    if (nuveiFields.cardCvc) nuveiFields.cardCvc.destroy();
    if (nuveiFields.scard) nuveiFields.scard.destroy();
    if (nuveiFields.cardExpiry) nuveiFields.cardExpiry.destroy();
    if (contentToShow === MakePaymentModalState.ShowSuccess) refreshData();
    onClose();
  };

  const storeCreatePayment = async (response: NuveiResponse) => {
    updatePaymentFn(response, openOrderData?.id!);

    if (response.status === 'ERROR' || response.result !== MakePaymentResult.APPROVED) {
      setContentToShow(MakePaymentModalState.ShowNuveiError);
      logger.log(response);
      return;
    }

    setContentToShow(MakePaymentModalState.ShowSuccess);
  };

  const createPaymentCall = () => createPayment({
    safeCharge,
    openOrderData,
    email,
    setShowModal: setContentToShow,
    storeCreatePayment,
    cardNonSensitiveData,
    scard: nuveiFields?.scard || undefined,
    selectedCard,
  });

  const onSubmitPayment = () => {
    setContentToShow(MakePaymentModalState.ShowSpinnerSafecharge);
    createPaymentCall();
  };

  const onPayWithStoredCard = () => {
    setContentToShow(MakePaymentModalState.ShowSpinner);
    createPaymentCall();
  };

  const deletePaymentMethodFn = async (id: number) => {
    try {
      setContentToShow(MakePaymentModalState.ShowSpinner);
      await deletePaymentMethod(id);
      setContentToShow(MakePaymentModalState.ShowPaymentMethods);
    } catch (error) {
      logger.log(error);
      setContentToShow(MakePaymentModalState.ShowGenericError);
    }
  };

  const handleAddCard = () => {
    setContentToShow(MakePaymentModalState.ShowSpinner);
    setSelectedCard(undefined);

    // Instantiate Nuvei Fields
    const ScFields = safeCharge.fields({
      fonts: [{ cssUrl: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap' }],
    });

    const style = {
      base: {
        fontSize: '16px',
        color: '#3f3f3f',
        '::placeholder': {
          color: '#626D71',
        },
      },
      valid: {
        color: '#3f3f3f',
        ':focus': {
          color: '#303238',
        },
      },
      invalid: {
        color: '#3f3f3f',
      },
    };

    setNuveiFields({
      scard: ScFields.create('ccNumber', { style }),
      cardCvc: ScFields.create('ccCvc', { style }),
      cardExpiry: ScFields.create('ccExpiration', { style }),
    });
    setContentToShow(MakePaymentModalState.ShowAddCard);
  };

  if (contentToShow === MakePaymentModalState.ShowSuccess) {
    return (
      <ModalResult
        buttonTextRight={t(`${translPrefix}.createPaymentResult.success.ok`)}
        Icon={DollarSign}
        handleButtonClose={handleClose}
        handleButtonRight={handleClose}
        title={t(`${translPrefix}.createPaymentResult.success.title`)}
        content={t(`${translPrefix}.createPaymentResult.success.subtitle`)}
        withBackground
        modalAccentColor={ModalAccentColor.GREEN}
      />
    );
  }

  if (contentToShow === MakePaymentModalState.ShowNuveiError
    || contentToShow === MakePaymentModalState.ShowGenericError) {
    return (
      <ModalResult
        buttonTextRight={t(`${translPrefix}.createPaymentResult.error.tryAgain`)}
        handleButtonRight={() => setContentToShow(
          MakePaymentModalState.ShowMakePaymentInformationForm,
        )}
        buttonTextLeft={t(`${translPrefix}.createPaymentResult.error.cancel`)}
        handleButtonLeft={() => onClose()}
        handleButtonClose={() => onClose()}
        Icon={DollarSign}
        title={t(`${translPrefix}.createPaymentResult.error.title`)}
        content={t(`${translPrefix}.createPaymentResult.error.${
          MakePaymentModalState.ShowNuveiError ? 'nuveiErrorSubtitle' : 'genericErrorSubtitle'}`)}
        withBackground
        modalAccentColor={ModalAccentColor.RED}
      />
    );
  }

  const getContent = () => {
    if (contentToShow === MakePaymentModalState.ShowAddCard
      || contentToShow === MakePaymentModalState.ShowSpinnerSafecharge) {
      return (
        <AddCard
          t={t}
          cardNonSensitiveData={cardNonSensitiveData}
          setCardNonSensitiveData={setCardNonSensitiveData}
          cancel={handleClose}
          nuveiFields={nuveiFields}
          createPayment={onSubmitPayment}
          contentToShow={contentToShow}
        />
      );
    }

    return (
      <>
        <SelectPaymentMethod
          selectedCard={selectedCard}
          onCardSelect={(card) => setSelectedCard(card)}
          makePaymentInformation={makePaymentInformation}
          onCancel={handleClose}
          onPay={onPayWithStoredCard}
          t={t}
          handleAddCard={handleAddCard}
          cards={storedCards ?? []}
          deletePaymentMethod={deletePaymentMethodFn}
          isBill={isBill}
        />
      </>
    );
  };

  const pageTitle = (
    <div className="text__heading5__textNeutral50">
      {contentToShow === MakePaymentModalState.ShowAddCard
        ? t(`${translPrefix}.payWithNewCard`) : t(`${translPrefix}.payTitle`)}
    </div>
  );

  const getContentWrapper = () => (
    <div className={contentToShow === MakePaymentModalState.ShowSpinnerSafecharge
      ? styles.containerSpinnerSafecharge : styles.container}
    >
      { !mobile && (
        <div className={styles.titleContainer}>
          {pageTitle}
          <Close className={styles.close} onClick={handleClose} />
        </div>
      )}

      {getContent()}
    </div>
  );

  return (
    <>
      {mobile ? (getContentWrapper())
        : (
          <Modal withBackground={contentToShow !== MakePaymentModalState.ShowSpinnerSafecharge}>
            {getContentWrapper()}
          </Modal>
        )}
      {contentToShow === MakePaymentModalState.ShowSpinnerSafecharge && (<Spinner />)}
    </>
  );
};

export { MakePayment };
