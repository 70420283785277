class MaintenanceTaskEventsSerializer {
  static deSerialize(data: RawTaskEvents): SerializedTaskEvents {
    return {
      eventType: data.event_type,

      // this is to change the format from "2022-01-24 19:11:41 UTC" to "24/01/2022 19:11Z"
      // Firefox does not support the "UTC" string at the end of the date, so we have to manually
      // add the timezone 'Z'(Zero Time) https://stackoverflow.com/questions/15109894/new-date-works-differently-in-chrome-and-firefox
      createdAt: data.created_at?.replace(/\sUTC/, 'Z'),

      contractor: data.contractor,
    };
  }
}

export { MaintenanceTaskEventsSerializer };
