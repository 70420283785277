import { Tooltip, TooltipPosition } from '@mapix/common/src/common/tooltip';
import { ReactComponent as Plus } from '@mapix/common/src/assets/icons/plus16.svg';
import { classnames } from '@mapix/common/src/helpers/utils';
import styles from './array-surplus-organizer.module.scss';

type ArraySurplusOrganizerProps = {
  array: string[],
  maxIcons: number,
  organizerFn: (arrayItem: string) => JSX.Element,
  plusContainerClass: string,
};

const ArraySurplusOrganizer = ({
  array, maxIcons, organizerFn, plusContainerClass,
}: ArraySurplusOrganizerProps) => {
  const hoverContent = (
    <div className="text__body__small__textNeutral10">
      {array.slice(maxIcons, array.length).map((item) => (
        <div className={styles.hoverSpecialization}>
          {item}
        </div>
      ))}
    </div>
  );

  const plusIcon = (
    <Plus
      className={classnames(plusContainerClass || '', styles.plusIcon)}
    />
  );

  return (
    <div className={styles.iconRow}>
      {array.slice(0, maxIcons).map(organizerFn)}
      {maxIcons < array.length && (
      <Tooltip
        textComponent={plusIcon}
        hoverComponent={hoverContent}
        position={TooltipPosition.Top}
        containerWidth="fit-content"
      />
      )}
    </div>
  );
};
export { ArraySurplusOrganizer };
