import { PropertyAddress } from 'models/property-address';

class PropertyAddressSerializer {
  static deSerialize(data: RawPropertyAddress): SerializedPropertyAddress {
    return {
      city: data.city,
      civicNumber: data.civic_number,
      country: data.country,
      province: data.province,
      streetName: data.street_name,
      unitNumber: data.unit_number,
      zipCode: data.zip_code,
      cityId: data.city_id,
      lat: data.lat,
      long: data.long,
    };
  }

  static serialize(data: PropertyAddress): RawPropertyAddress {
    return {
      city: data.city,
      civic_number: data.civicNumber,
      country: data.country,
      province: data.province,
      street_name: data.streetName,
      unit_number: data.unitNumber,
      zip_code: data.zipCode,
      city_id: data.cityId,
      lat: data.lat,
      long: data.long,
    };
  }
}

export { PropertyAddressSerializer };
