import { initPaginator } from '@mapix/common/src/initial-states/paginator-initial-state';
import { Paginator, PaginatorContainer } from '@mapix/common/src/types';
import { NewClaim } from 'networking/types/claim-auxiliar';

type TaskState = {
  statusFilters: string[],
  navigation: Paginator,
  tasks: NewClaim[],
  status: string,
  code: string,
  show: boolean,
  input: string,
  query: string,
};

type Action =
  | { type: 'PAGE_CHANGED', newPage: number }
  | { type: 'TASKS_FETCHED', paginator: PaginatorContainer<NewClaim> }
  | { type: 'STATUS_CHANGED', status: string };

const changePage = (
  currentPage: number,
  paginator: Paginator,
): Paginator => {
  const newPaginator = { ...paginator };
  newPaginator.currentPage = currentPage;
  return newPaginator;
};

const initialState: TaskState = {
  statusFilters: ['new_claims', 'in_progress', 'solved', 'rejected_claims', 'cancelled', 'remove'],
  code: 'FETCHING',
  navigation: initPaginator(4),
  tasks: [],
  status: '',
  show: false,
  query: '',
  input: '',
};

function ClaimsReducer(state: TaskState, action: Action): TaskState {
  switch (action.type) {
    case 'TASKS_FETCHED':
      return {
        ...state,
        navigation: action.paginator.navigation,
        tasks: action.paginator.results,
        code: 'READY',
      };
    case 'PAGE_CHANGED':
      return {
        ...state,
        navigation: changePage(action.newPage, state.navigation),
        code: 'FETCHING',
      };
    case 'STATUS_CHANGED':
      return {
        ...state,
        status: action.status,
        code: 'FETCHING',
      };
    default:
      return {
        ...state,
      };
  }
}

export { ClaimsReducer, initialState };
