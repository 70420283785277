import { FormEvent, ReactNode } from 'react';
import { Breadcrumbs, BreadcrumbsItem } from '../breadcrumbs';
import { ReactComponent as ChevronLeft } from '../../assets/icons/chevron-left.svg';
import { classnames } from '../../helpers/utils';
import { Breakpoints } from '../enums';
import { useMediaQuery } from '../../hooks/use-media-query';

import styles from './payment-information-template.module.scss';
import { Button, ButtonStyle, ButtonType } from '../button';

type PaymentInformationProps = {
  t: (text: string) => string,
  children: ReactNode,
  backFn?: () => void,
  onSubmit?: (e: FormEvent<HTMLFormElement>) => Promise<void>,
  title: string,
  subtitle?: string,
  grid?: boolean,
  goToHomeFn: () => void,
  goToMyProfileFn: () => void,
  goToPaymentInformationFn: () => void,
  UpperComponent?: JSX.Element
};

const handleTitleClass = (mobile: boolean) => classnames(
  mobile ? 'text__heading2__textNeutral50' : 'text__heading4__textNeutral40',
  styles.title,
);

const handleSubtitleClass = (mobile: boolean) => classnames(
  mobile ? 'text__body__large__textNeutral40' : 'text__body__medium__textNeutral40',
  styles.subtitle,
);

const handleSaveButtonClass = () => classnames(styles.save, 'text__body__large__textNeutral10');

const handleBackButtonClass = (mobile: boolean) => classnames(
  styles.back,
  mobile ? 'text__button__small__secondary90' : 'text__button__large__primary40',
);

const PaymentInformationTemplate = ({
  t, children, backFn, onSubmit, title, subtitle, grid = true, goToHomeFn,
  goToMyProfileFn, goToPaymentInformationFn, UpperComponent,
}: PaymentInformationProps) => {
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const breadcrumbs = (): BreadcrumbsItem[] => [
    { name: t('navigation.home'), goToPageFn: goToHomeFn },
    { name: t('navigation.profile'), goToPageFn: goToMyProfileFn },
    { name: t('navigation.paymentInformation'), goToPageFn: goToPaymentInformationFn },
  ];

  return (
    <form className={styles.form} noValidate onSubmit={onSubmit}>
      <div className={styles.container}>

        {mobile
      && (
      <Breadcrumbs values={breadcrumbs()} />
      )}

        <div className={handleTitleClass(mobile)}>
          {title}
        </div>

        <div className={handleSubtitleClass(mobile)}>
          {subtitle}
        </div>
        {UpperComponent}
        <div className={grid ? styles.grid : styles.flex}>
          {children}

        </div>
      </div>

      <div className={styles.footerWrapper}>
        {!mobile && (<hr className={styles.divider} />)}
        <div className={styles.footer}>

          {!mobile && (
          <Button
            buttonStyle={ButtonStyle.Back}
            buttonType={ButtonType.Button}
            className={handleBackButtonClass(mobile)}
            onClick={backFn}
          >
            <ChevronLeft className={styles.chevronLeft} />
            {t('back')}
          </Button>
          )}

          <Button
            buttonStyle={ButtonStyle.Primary}
            buttonType={ButtonType.Submit}
            className={handleSaveButtonClass()}
          >
            {t('save')}
          </Button>
        </div>
      </div>

    </form>
  );
};

export { PaymentInformationTemplate };
