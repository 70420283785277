import { useRef, useState } from 'react';
import { Calendar } from 'react-date-range';
import dayjs from 'dayjs';
import {
  classnames,
  HTMLValidationError,
} from '../../helpers/utils';
import { useClickOutside } from '../../hooks/click-outside';
import { Input, InputStyle } from '../input';
import styles from './input-date.module.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

type InputDateProps = {
  id: string,
  name: string,
  label: string,
  placeholder?: string,
  value: Date | undefined,
  disabled?: boolean,
  formErrors?: HTMLValidationError,
  onChangeFn: (date: Date) => void,
  min?: Date,
  max?: Date,
  required?: boolean,
  plainHelperText?: boolean,
  containerClass?: string,
  helperText?: string,
  t: (text: string) => string,
  fromTop?: boolean,
};

const InputDate = ({
  id, label, placeholder, value,
  disabled = false, formErrors, onChangeFn, name, min, max, required = false, containerClass = '',
  helperText, t, plainHelperText, fromTop = false,
}: InputDateProps) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const containerCalendar = useRef(null);
  useClickOutside(containerCalendar, () => setShowCalendar(false));
  return (
    <div className={classnames(styles.inputDate, containerClass)} ref={containerCalendar}>
      <Input
        required={required}
        id={id}
        name={name}
        label={label}
        placeholder={placeholder}
        value={value ? (dayjs(value).format('DD/MM/YYYY')) : ''}
        inputStyle={InputStyle.FORM}
        formError={formErrors}
        disabled={disabled}
        withDateIcon
        onClick={() => setShowCalendar(true)}
        helperText={helperText}
        autoComplete="off"
        plainHelperText={plainHelperText}
        t={t}
        // This value is needed because, otherwise, a warning will be shown indicating
        // onChange or readonly props are missing. Cannot add readonly because, if we do,
        // validation (like being required) would not be performed on the component
        onChange={() => {}}
        inputMode="none"
      />
      {showCalendar && (
      <div className={fromTop ? styles.calendarContentFromTop : styles.calendarContent}>
        <Calendar
          date={value}
          onChange={(e: Date) => onChangeFn((e))}
          showDateDisplay={false}
          color="#654FBB"
          minDate={min}
          maxDate={max}
        />
      </div>
      )}
    </div>
  );
};

export { InputDate };
