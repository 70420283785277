import { Table } from 'common/table';
import { classnames } from '@mapix/common/src/helpers/utils';
import { MaintenanceController } from 'networking/controllers/maintenance-controller';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { goToPage, RouteName } from 'routes';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { ImageOverlay } from '@mapix/common/src/common/image-overlay';
import { ImageOverlayObject } from '@mapix/common/src/common/image-overlay/image-overlay';
import { Spinner } from '@mapix/common/src/common/spinner';
import { getFullAddress } from 'helpers/utils';
import styles from './contractor-task-detail.module.scss';

type ParamType = {
  contractorToken: string,
};

const initOverlayState: ImageOverlayObject = {
  show: false,
  index: 0,
  photos: [],
};

const ContractorTaskDetail = () => {
  const { t } = useTranslation();
  const [fetching, setFetching] = useState(false);
  const [showError, setShowError] = useState(false);
  const [data, setData] = useState<TaskView[]>([]);
  const [overlayState, setOverlayState] = useState<ImageOverlayObject>(initOverlayState);

  const { contractorToken } = useParams<ParamType>();
  const headerTask = ['affectedArea', 'category'];

  const [task, setTask] = useState<TaskDetailContractor | null>(null);

  const showOverlay = (index: number, photos: FileType[]) => {
    setOverlayState({ index, photos, show: true });
  };

  const hideOverlay = () => {
    setOverlayState((prevState) => ({ ...prevState, show: false }));
  };

  const getTask = async () => {
    try {
      setFetching(true);
      const result = await MaintenanceController.getTaskDetailByContractorToken(contractorToken);
      setFetching(false);
      setTask(result);
      const dataAux: any[] = [
        {
          affectedArea: result.unit?.unitNumber ? `${t('unit')} ${result.unit.unitNumber}` : 'taskDetail.commonArea',
          category: result.category,
        },
      ];
      setData(dataAux);
    } catch (ex) {
      setFetching(false);
      goToPage(RouteName.ErrorPage);
    }
  };

  useEffect(() => {
    getTask();
  }, []);

  if (fetching || task === null) {
    return (
      <Spinner />
    );
  }

  const tableInformation = data.length ? [{
    affectedArea: data[0].affectedArea === 'taskDetail.commonArea' ? t(data[0].affectedArea) : data[0].affectedArea,
    category: t(`taskDetail.categories.${data[0].category}`),
  }] : [];

  return (
    <>
      {overlayState.show && (
        <ImageOverlay
          photos={overlayState.photos}
          selectedIndex={overlayState.index}
          closeFn={hideOverlay}
          t={t}
        />
      )}

      {showError
          && <ErrorMessage message={t('error.errorMessage')} handleClose={() => setShowError(false)} />}
      <div className={styles.container}>
        <div className={styles.row}>
          <p className="text__heading4__textNeutral50">
            {t('taskDetail.taskId')}
            {' '}
            { task.id}
          </p>
        </div>
        <div className={classnames(styles.row, styles.address)}>
          <p className="text__heading5__textNeutral50">
            { task.property.type}
            {'- '}
            { getFullAddress(task.property.address)}
          </p>
        </div>
        <div className={styles.table}>
          <Table
            headerNames={headerTask}
            data={tableInformation}
            dataProperties={headerTask}
            dashboardName="taskDetail.newDashboard"
            uniqueId="id"
          />
        </div>
        { (task.status === 'Solved' && task.additionalInformation) && (
        <div className={styles.cardDescription}>
          <p className="text__heading6__textNeutral50">
            {t('taskDetail.additionalComments')}
          </p>
          <p className={classnames(styles.margin, 'text__body__medium__textNeutral40')}>
            { task.additionalInformation}
          </p>
        </div>
        )}
        <div className={styles.cardDescription}>
          <p className="text__heading6__textNeutral50">
            {t('taskDetail.description')}
          </p>
          <p className={classnames(styles.margin, 'text__body__medium__textNeutral40')}>
            { task.description}
          </p>
        </div>
        <div className={styles.cardDescription}>
          <p className="text__heading6__textNeutral50">
            {t('taskDetail.contractorView.additionalData')}
          </p>
          {task.additionalInformation
            ? (
              <p className={classnames(styles.margin, 'text__body__medium__textNeutral40')}>
                { task.additionalInformation}
              </p>
            ) : (
              <p className={classnames(styles.italic, 'text__body__medium__textNeutral40')}>
                {t('taskDetail.contractorView.noAdditionalInformation')}
              </p>
            )}
        </div>
        <div className={styles.card}>
          <p className="text__heading6__textNeutral50">
            {t('taskDetail.contractorView.claimImages')}
          </p>
          <div className={styles.photos}>
            {
              (task.taskPhotos.length !== 0)

                ? task.taskPhotos?.map((elem, index) => (
                  <img
                    role="presentation"
                    onClick={() => showOverlay(index, task?.taskPhotos)}
                    src={elem.url}
                    alt="Task"
                    className={styles.photo}
                  />
                ))
                : (
                  <p className={classnames(styles.italic, 'text__body__medium__textNeutral40')}>
                    {t('taskDetail.emptyPhotos')}
                  </p>
                )
              }
          </div>
        </div>
        <div className={styles.card}>
          <p className="text__heading6__textNeutral50">
            {t('taskDetail.contractorView.contactInfo.title')}
          </p>
          <div className={classnames(styles.contactInfo, styles.row)}>
            <div className={styles.column}>
              <p className="text__body__medium__textNeutral40">
                {` ${t('taskDetail.contractorView.contactInfo.manager')} : `}
              </p>
              <p className={classnames(styles.marginLastRow, 'text__body__medium__textNeutral50')}>
                { task.manager?.name}
              </p>
            </div>
            <div className={styles.column}>
              <p className="text__body__medium__textNeutral40">
                {`${t('taskDetail.contractorView.contactInfo.phone')} :`}
              </p>
              <p className={classnames(styles.marginLastRow, 'text__body__medium__textNeutral50')}>
                { task.manager?.phoneNumber && `${task.manager?.countryCode}${task.manager?.phoneNumber}`}
              </p>
              { task.manager?.additionalPhoneNumber && (
              <p className={classnames(styles.marginSecondPhone, 'text__body__medium__textNeutral50')}>
                { task.manager?.additionalPhoneNumber && `${task.manager?.additionalCountryCode}${task.manager?.additionalPhoneNumber}` }
              </p>
              )}
            </div>
            <div className={styles.column}>
              <p className="text__body__medium__textNeutral40">
                {`${t('taskDetail.contractorView.contactInfo.email')} : `}
              </p>
              <p className={classnames(styles.marginLastRow, 'text__body__medium__textNeutral50')}>
                { task.manager?.email}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { ContractorTaskDetail };
