import { Contractor } from 'models/contractor';
import { API_ROUTES } from 'networking/api-routes';
import { ApiService } from 'networking/api-service';
import { ContractorSerializer } from 'networking/serializers/contractor-serializer';
import { PaginatorSerializer } from 'networking/serializers/paginator-serializer';

class ContractorController {
  static async editContractor(
    contractor: SerializedContractor,
    propertyId: number,
    contractorId: number,
  ) {
    return ApiService.put<RawContractor>(
      API_ROUTES.GET_EDIT_CONTRACTOR(propertyId, contractorId),
      ContractorSerializer.serialize(contractor),
    );
  }

  static async getContractor(propertyId: number, contractorId: number) {
    const result = await ApiService.get<RawContractor>(API_ROUTES
      .GET_EDIT_CONTRACTOR(propertyId, contractorId));
    const serializedContractor = ContractorSerializer.deSerialize(result.data);
    return new Contractor(serializedContractor);
  }

  static async deleteContractor(propertyId: number, contractorId: number) {
    return ApiService.delete(API_ROUTES.GET_EDIT_CONTRACTOR(propertyId, contractorId));
  }

  static async assignContractor(taskId: number, data: AssignContractor) {
    const serializedData = ContractorSerializer.serializeAssignContractor(data);
    return ApiService.post<RawAssignContractor>(
      API_ROUTES.ASSIGN_CONTRACTOR(taskId),
      serializedData,
    );
  }

  static async getContractors(
    pageNumber: number,
    pageSize: number,
    id: number,
    category?: string,
  ) {
    const result = await ApiService.get<RawPaginatorContainer<RawContractorMinimal>>(API_ROUTES
      .GET_CONTRACTORS_BY_PROPERTY(pageNumber, pageSize, id, category));
    const { results, navigation } = result.data;

    return {
      navigation: PaginatorSerializer.deSerialize(navigation),
      results: ContractorSerializer.deSerializeMinimalArray(results),
    };
  }
}

export { ContractorController };
