import React, { SetStateAction, useEffect, useState } from 'react';
import { logger } from 'helpers/logger';
import { Spinner } from '../../spinner';
import { FileState } from '../../enums';
import { UploadItemCompact } from '../../upload-item-compact';
import { DocumentsInformationType, ExtendedFile, FileType } from '../../../types';
import { classnames, getRandomInt, isEmptyObject } from '../../../helpers/utils';
import { Modal } from '../../modal';
import styles from './modal-upload-files.module.scss';
import { ReactComponent as Cross } from '../../../assets/icons/cross.svg';

type ExtendedDocumentInformationType = {
  [Property in keyof DocumentsInformationType]: ExtendedFile | null };

type ModalUploadFilesProps = {
  title: string,
  handleButtonClose: () => void,
  translPrefix: string,
  t: (key: string) => string,
  getDocumentsInformation: () => Promise<DocumentsInformationType>,
  uploadDocument: (key: string) => Promise<string>,
  handleChangeFile: (key: string) => void,
  submitApplication: () => Promise<void>,
};

const mapToExtendedFile = (file: FileType | null): ExtendedFile | null => {
  if (file === null) return null;

  return {
    filename: file.filename,
    state: FileState.LOADING,
    url: file.url,
    retries: 0,
  } as ExtendedFile;
};

const handleUploadFile = (
  key: string,
  completed: boolean,
  setExtendedFiles: (value: SetStateAction<ExtendedDocumentInformationType>) => void,
) => {
  setExtendedFiles((prevState) => ({
    ...prevState,
    [key]: {
      ...prevState[key as keyof ExtendedDocumentInformationType],
      state: completed ? FileState.COMPLETED : FileState.FAILED,
    },
  }));
};

const mapToExtendedFileAndApiCall = (
  files: DocumentsInformationType,
  uploadDocument: (key: string) => Promise<string>,
  setExtendedFiles: (value: SetStateAction<ExtendedDocumentInformationType>) => void,
) => {
  const extendedFiles = {} as ExtendedDocumentInformationType;
  const apiCalls = [] as Promise<string>[];
  const apiCallNames = [] as string[];
  Object.entries(files).forEach(([key, file]) => {
    extendedFiles[key as keyof ExtendedDocumentInformationType] = mapToExtendedFile(file);

    if (file !== null) {
      apiCallNames.push(key);
      apiCalls.push(uploadDocument(key));
    }
  });
  setExtendedFiles(extendedFiles);
  return { apiCallNames, apiCalls };
};

const retryUploadFile = async (
  key: string,
  setExtendedFiles: (value: SetStateAction<ExtendedDocumentInformationType>) => void,
  uploadDocument: (key: string) => Promise<string>,
) => {
  setExtendedFiles((prevState) => ({
    ...prevState,
    [key]: {
      ...prevState[key as keyof ExtendedDocumentInformationType],
      state: FileState.LOADING,
      retries: prevState[key as keyof ExtendedDocumentInformationType]!.retries! + 1,
    },
  }));
  try {
    await uploadDocument(key);
    handleUploadFile(key, true, setExtendedFiles);
  } catch (error) {
    logger.log(error as Error);
    handleUploadFile(key, false, setExtendedFiles);
  }
};

const ModalUploadFiles = ({
  title, handleButtonClose, translPrefix, t, getDocumentsInformation,
  uploadDocument, handleChangeFile, submitApplication,
}: ModalUploadFilesProps) => {
  const [fetching, setFetching] = useState(true);
  const [extendedFiles, setExtendedFiles] = useState<ExtendedDocumentInformationType>(
    {} as ExtendedDocumentInformationType,
  );

  const getAndUploadDocuments = async () => {
    try {
      const documents = await getDocumentsInformation();
      const { apiCallNames, apiCalls } = mapToExtendedFileAndApiCall(
        documents,
        uploadDocument,
        setExtendedFiles,
      );
      setFetching(false);

      const results = await Promise.allSettled(apiCalls);
      results.forEach((result, index) => {
        handleUploadFile(apiCallNames[index], result.status === 'fulfilled', setExtendedFiles);
      });
    } catch (error) {
      logger.error(error as Error);
      setFetching(false);
    }
  };

  useEffect(() => {
    getAndUploadDocuments();
  }, []);

  useEffect(
    () => {
      if (!isEmptyObject(extendedFiles) && Object.values(extendedFiles)
        .every((file) => file === null || file.state === FileState.COMPLETED)) {
        setTimeout(() => submitApplication(), 1000);
      }
    },
    [extendedFiles.applicationAndAgreement?.state,
      extendedFiles.equipmentForm?.state,
      extendedFiles.nonProfitEvidence?.state,
      extendedFiles.other?.state,
      extendedFiles.processingStatements?.state,
      extendedFiles.proofOfBusiness?.state,
      extendedFiles.voidCheck?.state,
    ],
  );

  if (fetching) return <Spinner />;

  return (
    <Modal fixedTop>
      <div className={styles.modalContainer}>

        <div className={styles.titleAndCrossContainer}>
          <p className={classnames(styles.semiBold, 'text__body__large__textNeutral40')}>
            {title}
          </p>
          <button type="button" onClick={handleButtonClose}>
            <Cross className={styles.crossIcon} />
          </button>
        </div>
        <div className={styles.uploadItemWrapper}>
          {Object.entries(extendedFiles).map(([key, file]) => {
            if (file === null) return null;
            return (
              <div key={key}>
                <p className={classnames(styles.fileTitles, 'text__body__small__textNeutral40')}>{t(`${translPrefix}.${key}`)}</p>
                <UploadItemCompact
                  removeFn={() => {}}
                  t={t}
                  filename={extendedFiles[key as keyof ExtendedDocumentInformationType]!.filename}
                  fileState={file.state}
                  url={file.url}
                  retries={file.retries}
                  handleRetry={() => retryUploadFile(key, setExtendedFiles, uploadDocument)}
                  handleChangeFile={() => handleChangeFile(key)}
                  disableFileRemoval
                  progressBarRefreshRate={getRandomInt(100, 500)}
                />
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export { ModalUploadFiles };
