import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';
import { ReactComponent as ChevronUp } from 'assets/icons/chevron-up.svg';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { classnames } from '@mapix/common/src/helpers/utils';
import { useClickOutside } from '@mapix/common/src/hooks/click-outside';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { ModalFilterItem } from '../filter-modal/filter-modal';
import styles from './filters-applied.module.scss';

type FiltersAppliedProps = {
  filters: ModalFilterItem[],
  clearFn: () => void,
  removeOneFilterFn: (filters: ModalFilterItem) => void,
  prefix: string,
};

const FiltersApplied = ({
  filters, clearFn, removeOneFilterFn, prefix,
}: FiltersAppliedProps) => {
  const filterPrefix = `${prefix}.filters`;
  const [show, setShow] = useState(false);
  const myRef = useRef(null);
  const { t } = useTranslation();

  useClickOutside(myRef, () => setShow(false));

  return (
    <div ref={myRef} className={styles.filterContainer}>
      <button
        className={classnames(styles.buttonContainer, 'row align-justify-center')}
        type="button"
        onClick={() => setShow(!show)}
      >
        <div className={classnames(styles.number, 'text__button__small__primary80')}>{filters.length}</div>
        <div className="text__button__small__primary60">{t(`${filterPrefix}.applied`)}</div>
        {show ? (
          <ChevronUp
            className={styles.chevron}
          />
        ) : <ChevronDown className={styles.chevron} />}
      </button>
      {show && (
        <div className={styles.content}>
          <div className={styles.chipContainer}>
            {filters.map((filter) => (
              <button key={filter.code} className={styles.chip} type="button" onClick={() => removeOneFilterFn(filter)}>
                <div className="text__button__small__primary80">
                  {t(`${filterPrefix}.${filter.parentType}.${filter.code}`)}
                </div>
                <Cross className={styles.cross} />
              </button>
            ))}
          </div>

          <div className={styles.clear}>
            <Button buttonStyle={ButtonStyle.Link} onClick={clearFn}>
              {t(`${filterPrefix}.clear`)}
            </Button>
          </div>

        </div>
      )}
    </div>
  );
};

export { FiltersApplied };
