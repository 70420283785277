import { PaymentInformationStatus } from '../enums';
import { classnames } from '../../helpers/utils';
import { ReactComponent as Check } from '../../assets/icons/check-filled.svg';
import { ReactComponent as ChevronRight } from '../../assets/icons/chevron-right.svg';
import styles from './payment-info-progress-bar.module.scss';

type ProgressBarProps = {
  title: string,
  status: PaymentInformationStatus,
  percentage: number,
  t: (text: string) => string,
  chevronFn: () => void,
};

const translPrefix = 'paymentInformation';

const PaymentInfoProgressBar = ({
  title, status, percentage, t, chevronFn,
}: ProgressBarProps) => (
  <div className={styles.containerProgressBar}>

    <div className={styles.titleContainer}>
      <div className={classnames('text__body__large__textNeutral40', styles.title)}>{title}</div>
      <ChevronRight className={styles.chevronIcon} onClick={chevronFn} />
    </div>

    <div className={styles.progressBar}>
      <div className={styles.containerStyles}>
        <div style={{ width: `${percentage}%` }} className={classnames(styles.fillerStyles)} />
      </div>
      {status === PaymentInformationStatus.COMPLETED && (<Check className={styles.checkIcon} />)}
    </div>

    <div className={styles.statusContainer}>
      <div className="text__body__small__textNeutral40">
        {t(`${translPrefix}.status.${status}`)}
      </div>
      {(status !== PaymentInformationStatus.COMPLETED) && (
        <div className="text__body__small__textNeutral40">
          {`${percentage}% ${t(`${translPrefix}.status.${PaymentInformationStatus.COMPLETED}`).toLocaleLowerCase()}`}
        </div>
      )}
    </div>

  </div>
);

export { PaymentInfoProgressBar };
