import { Modal } from '@mapix/common/src/common/modal';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { ReactComponent as RentalDetail } from 'assets/images/rental-detail.svg';
import { useTranslation } from 'react-i18next';
import { classnames } from '@mapix/common/src/helpers/utils';
import { Button } from '@mapix/common/src/common/button';
import styles from './rental-details-modal.module.scss';

type RentalDetailsModalProps = {
  paymentDate: string,
  closeFn: () => void
  manuallyPaid: boolean,
};

const translPrefix = 'rentalDetailsModal';
const RentalDetailsModal = ({ paymentDate, closeFn, manuallyPaid }: RentalDetailsModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal>
      <div className={styles.container}>
        <button className={styles.closeButton} type="button" onClick={closeFn}>
          <Cross className={styles.close} />
        </button>
        <div className={styles.row}>
          <RentalDetail />
        </div>

        <div className={classnames(styles.title, 'text__heading5__textNeutral50')}>
          {t(`${translPrefix}.title`)}
        </div>

        <div className={classnames(styles.paymentDate, 'text__body__medium__textNeutral50')}>
          <p>
            {t(`${translPrefix}.paymentDate`)}
            :
            {' '}
            {paymentDate}
          </p>
          <p className={styles.paymentMargin}>
            {manuallyPaid ? t(`${translPrefix}.manually`) : t(`${translPrefix}.nuvei`)}

          </p>
        </div>

        <Button className={styles.button} onClick={closeFn}>
          <div className="text__button__large__textNeutral10">
            {t(`${translPrefix}.ok`)}
          </div>
        </Button>
      </div>
    </Modal>
  );
};

export { RentalDetailsModal };
