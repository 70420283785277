class Storage {
  static isAvailable() {
    try {
      return window.localStorage
        // @ts-ignore
        && window.localStorage.setItem
        // @ts-ignore
        && window.localStorage.removeItem
        // @ts-ignore
        && window.localStorage.getItem;
    } catch (error) {
      return false;
    }
  }

  static get(key: string) {
    const obj = window.localStorage.getItem(key);
    // @ts-ignore
    if (this.isAvailable && obj) {
      return JSON.parse(obj);
    }
    return undefined;
  }

  static set(key: string, item: any) {
    if (item === undefined) {
      return;
    }
    // @ts-ignore
    if (this.isAvailable) {
      window.localStorage.setItem(key, JSON.stringify(item));
    }
  }

  static remove(key: string) {
    // @ts-ignore
    if (this.isAvailable) {
      window.localStorage.removeItem(key);
    }
  }
}

enum StorageKeys {
  RedirectWhenLogged = 'redirectWhenLogged',
  PropertyKey = 'propertyKey',
}

export { Storage, StorageKeys };
