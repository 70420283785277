import { Services } from 'models/services';
import { getFullAddress } from 'helpers/utils';
import { PropertyAddress } from './property-address';
import { PropertyOwner } from './property-owner';

class
LeaseDetail {
  id: number;

  type: string;

  address: string;

  buildingName: string;

  unitNumber: string;

  unitId?: number;

  rental: string;

  monthlyRental: number;

  condoFees: number | null;

  startDate: string;

  expirationDate: string;

  ownerName: string;

  tenantInfo: PropertyOwner;

  ownerInfo?: PropertyOwner;

  otherOwnerInfo?: PropertyOwner;

  status: string;

  unitPhotos: FileType[];

  tenantIdPhotos: FileType[];

  leaseCopy: FileType | null;

  services: Services;

  currency: string;

  endDate?: string | null;

  size: string;

  propertyCondition: string;

  propertyStatus: string | null;

  propertyId: number;

  constructor(params: SerializedDetailedLease | null | undefined) {
    this.id = params?.id || 0;
    this.type = params?.property?.type || '';
    this.address = params?.property.address ? getFullAddress(new PropertyAddress(params.property.address)) : '';
    this.buildingName = params?.property.buildingName || '';
    this.unitNumber = params?.unit?.unitNumber || '';
    this.rental = params?.monthlyRental ? `$ ${params.monthlyRental}` : '';
    this.monthlyRental = params?.monthlyRental || 0;
    this.condoFees = params?.fees || null;
    this.startDate = params?.startDate || '';
    this.expirationDate = params?.expirationDate || '';
    this.tenantInfo = params?.tenant || new PropertyOwner(null);
    this.ownerName = params?.property?.owner?.name || '';
    this.status = params?.status || '';
    this.ownerInfo = params?.property?.owner || new PropertyOwner(null);
    this.otherOwnerInfo = params?.property?.otherOwner || new PropertyOwner(null);
    this.unitPhotos = params?.unitPhotos || [];
    this.tenantIdPhotos = params?.tenantIdPhotos || [];
    this.leaseCopy = params?.leaseCopy || null;
    this.services = new Services(params?.services || null);
    this.currency = params?.currency || '';
    this.endDate = params?.endDate || null;
    this.size = params?.size || '';
    this.propertyCondition = params?.property?.condition || '';
    this.propertyStatus = params?.property?.status || null;
    this.unitId = params?.unit?.id;
    this.propertyId = params?.property?.id || 0;
  }
}

export { LeaseDetail };
