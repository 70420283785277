import { Lease } from 'models/lease';
import { PropertyStatus } from 'common/enums';
import { PropertyAddress } from 'models/property-address';
import { getFullAddress } from 'helpers/utils';

class Unit {
  id: number;

  unitNumber: string;

  status: PropertyStatus;

  lease: Lease | null;

  address: PropertyAddress | null;

  fullAddress: string;

  newTasksCount: number;

  inProgressTasksCount: number;

  overdueRentalsCount: number;

  condition: string | null;

  constructor(params: SerializedUnit | null) {
    this.id = params?.id || 0;
    this.unitNumber = params?.unitNumber || '';
    this.status = params?.status as PropertyStatus || PropertyStatus.Available;
    this.lease = params?.lease ? new Lease(params.lease || null) : null;
    this.address = params?.address ? new PropertyAddress(params.address) : null;
    this.fullAddress = params?.address ? getFullAddress(params.address) : '';
    this.newTasksCount = params?.newTasksCount || 0;
    this.inProgressTasksCount = params?.inProgressTasksCount || 0;
    this.overdueRentalsCount = params?.overdueRentalsCount || 0;
    this.address = params?.address ? new PropertyAddress(params.address) : null;
    this.condition = params?.condition || null;
  }
}

export { Unit };
