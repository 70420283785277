import { useParams } from 'react-router-dom';
import { classnames } from '@mapix/common/src/helpers/utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import {
  BuildingInfo, CondoInfo, ContactInfo, LeaseInfo,
} from 'common/detail-read-only-forms/';
import { goToPage, RouteName } from 'routes';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { Spinner } from '@mapix/common/src/common/spinner';
import { Breadcrumbs, BreadcrumbsItem } from '@mapix/common/src/common/breadcrumbs';
import { LeaseDetail } from 'models/lease-detail';
import { FilesInfo } from 'common/detail-read-only-forms/files-info';
import { PropertyOwner } from 'models/property-owner';
import { ReactComponent as PlusCircle } from 'assets/icons/plus-circle.svg';
import { LeaseController } from 'networking/controllers/lease-controller';

import styles from './lease-detailed.module.scss';

const translPrefix = 'leasedetail';

const LeaseDetailed = () => {
  const [lease, setLease] = useState<LeaseDetail>(new LeaseDetail(null));
  const [code, setCode] = useState('READY');

  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();

  const breadcrumbs = (breadcrumbStarter: string, leaseDetail: string): BreadcrumbsItem[] => [
    {
      name: breadcrumbStarter,
      goToPageFn: () => goToPage(RouteName.Leases),
    },
    {
      name: leaseDetail,
      goToPageFn: () => null,
    },
  ];

  const getLease = async () => {
    setCode('READY');
    try {
      const fetchedLease = await LeaseController.getDetailedLease(Number(id));
      if (fetchedLease !== undefined) {
        setLease(fetchedLease);
      }
      setCode('READY');
    } catch (err: any) {
      if (err.status === 404) {
        goToPage(RouteName.ErrorPage);
      }
      setCode('ERROR');
    }
  };

  useEffect(() => {
    getLease();
  }, []);

  if (!lease) {
    return (
      <div>
        {code === 'ERROR' && (
          <ErrorMessage message={t('error.errorMessage')} handleClose={() => getLease()} />
        )}
        <Spinner />
      </div>
    );
  }

  const goToExtendLease = () => {
    if (lease.unitId && lease.propertyId) {
      goToPage(RouteName.ExtendLeaseUnit, { id: lease.propertyId, unitId: lease.unitId });
    } else if (lease.propertyId) {
      goToPage(RouteName.ExtendLease, { id: lease.propertyId });
    }
  };

  return (
    <div className={styles.container}>

      <Breadcrumbs values={breadcrumbs('Leases', 'Lease Detail')} />

      <div className={styles.detail}>

        <div className="text__heading4__textNeutral50">
          {t(`${translPrefix}.title`)}
        </div>
        <div className={classnames(styles.addressSubtitle, 'text__heading5__textNeutral50')}>
          {`${lease?.type === 'Building' ? t('createTask.type.building') : t('createTask.type.condoHouse')} ${lease?.address}`}
        </div>

        <div className={styles.buttonRow}>
          <div className={classnames(styles.contentTitle, 'text__body__large__textNeutral50')}>
            {lease?.status === 'ongoing' ? t(`${translPrefix}.ongoing`) : t(`${translPrefix}.expired`)}
          </div>
          <div className={styles.buttonsContainer}>
            {lease.propertyStatus !== 'rented' && lease?.propertyCondition !== 'archived' && ['ended', 'expired'].includes(lease?.status) && (
            <Button
              onClick={() => { goToPage(RouteName.RenewLease, { id: lease.id }); }}
              buttonStyle={ButtonStyle.Secondary}
            >
              {t(`${translPrefix}.buttons.renew`)}
            </Button>
            )}

            {['ongoing', 'expired'].includes(lease?.status) && (
            <>
              <Button
                className={styles.endButton}
                buttonStyle={ButtonStyle.Secondary}
                onClick={() => { goToPage(RouteName.EndLease, { id: lease.id }); }}
              >
                {t(`${translPrefix}.buttons.end`)}
              </Button>
              <Button
                className={styles.propertyButton}
                buttonStyle={ButtonStyle.Primary}
                onClick={goToExtendLease}
              >
                <div className="row align-justify-center">
                  <PlusCircle className={styles.plusIcon} />
                  {t(`${translPrefix}.buttons.extend`)}
                </div>
              </Button>
            </>
            )}

          </div>
        </div>
        <div className={styles.line} />

        <div className={classnames(styles.contentTitle, 'text__body__large__textNeutral50')}>
          {t(`${translPrefix}.locationGeneralData`)}
        </div>
        {lease.type === 'Building' ? (
          <BuildingInfo
            buildingName={lease.buildingName}
            fullAddress={lease.address}
            unitNumber={lease.unitNumber.toString()}
            fromLeases
          />
        ) : (<CondoInfo fullAddress={lease.address} />)}
        <div className={styles.line} />

        <div className={classnames(styles.contentTitle, 'text__body__large__textNeutral50')}>
          {t(`${translPrefix}.contactInfo`)}
        </div>
        <ContactInfo
          owner={lease?.tenantInfo ? lease.tenantInfo : new PropertyOwner(null)}
          isUnit
        />
        <div className={styles.line} />

        <div className={classnames(styles.contentTitle, 'text__body__large__textNeutral50')}>
          {t(`${translPrefix}.leaseInfo`)}
        </div>
        <LeaseInfo leaseDetail={lease} />
        <div className={styles.line} />

        <FilesInfo
          unitPhotos={lease.unitPhotos}
          leaseCopy={lease.leaseCopy}
          tenantPhotos={lease.tenantIdPhotos}
        />
        <div className={styles.line} />

      </div>
    </div>
  );
};

export { LeaseDetailed };
