import { PillColor, TaskStatus, TaskType } from '@mapix/common/src/common/enums';
import { Pill } from '@mapix/common/src/common/pill';
import { Tab } from '@mapix/common/src/common/tab';
import { classnames, getPillColorByTaskStatus, resolveAddress } from '@mapix/common/src/helpers/utils';
import { NewTask } from 'networking/types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EditIcon } from '@mapix/common/src/assets/icons/edit.svg';
import { Link } from 'react-router-dom';
import styles from '../new-task-detail.module.scss';

type HeaderProps = {
  setCurrentTab: (option: string) => void,
  currentTab: string,
  newTask: NewTask,
  translPrefix: string,
  taskStatus: TaskStatus | '',
  showAlert: (option: string) => boolean,
};

export enum TaskDetailTabs {
  Status = 'status',
  Rate = 'rate',
  TaskView = 'taskView',
  Applications = 'applications',
}

const getTabs = (taskStatus: TaskStatus | '', taskType: TaskType): string[] => {
  switch (taskStatus) {
    case TaskStatus.NEGOTIATING:
    {
      const negotiatingTabs = [TaskDetailTabs.Status, TaskDetailTabs.Rate,
        TaskDetailTabs.TaskView];

      if (taskType === TaskType.PublicTask) {
        // negotiatingTabs.push(TaskDetailTabs.Applications); // TODO TBD
        return negotiatingTabs;
      }
      return negotiatingTabs;
    }

    case TaskStatus.IN_PROGRESS:
    case TaskStatus.SOLVED:
      return [TaskDetailTabs.Status, TaskDetailTabs.Rate];
    case TaskStatus.UNASSIGNED:
    case TaskStatus.UNPUBLISHED:
      return [TaskDetailTabs.Status, TaskDetailTabs.TaskView];
    case TaskStatus.RECEIVING_APPLICATIONS:
      return [TaskDetailTabs.Status, TaskDetailTabs.TaskView, TaskDetailTabs.Applications];
    default:
      return [TaskDetailTabs.Status];
  }
};

const canEditTask = (newTask: NewTask) => {
  if (newTask.taskType === TaskType.PublicTask) {
    return newTask.status === 'receiving_applications' && newTask.applications === 0;
  }
  if (newTask.taskType === TaskType.PrivateTask) {
    return newTask.status === 'waiting_for_contractor_assignation';
  }
  if (newTask.taskType === TaskType.UnpublishedTask) {
    return true;
  }
  return false;
};

const showEditButton = (text: string, id: number, buttonClassName: string = '') => (
  <Link
    to={`/edit-new-task/${id}`}
    className={classnames('text__body__medium__primary50', styles.modifyButton, buttonClassName)}
  >
    {`${text} `}
    <EditIcon className={styles.editIcon} />
  </Link>
);

const Header = ({
  currentTab, newTask, setCurrentTab, translPrefix, taskStatus, showAlert,
}: HeaderProps) => {
  const { t } = useTranslation();

  const clickTab = (option: TaskDetailTabs) => {
    setCurrentTab(option);
  };

  const getPillContentByStatus = (status: TaskStatus) => {
    if (status === TaskStatus.RECEIVING_APPLICATIONS) {
      return TaskStatus.UNASSIGNED;
    }

    if (status === TaskStatus.WAITING_MANAGER_CANCELLED_PERCENTAGE_APPROVAL
      || status === TaskStatus.WAITING_CONTRACTOR_CANCELLED_PERCENTAGE_APPROVAL) {
      return TaskStatus.CANCELLED;
    }

    return status;
  };

  const isWaitingCancellationApproval = (status: TaskStatus) => (
    status === TaskStatus.WAITING_MANAGER_CANCELLED_PERCENTAGE_APPROVAL
    || status === TaskStatus.WAITING_CONTRACTOR_CANCELLED_PERCENTAGE_APPROVAL
  );

  const showWaitingApprovalPill = (status: TaskStatus) => {
    const getWaitingApprovalPillContent = (sta: TaskStatus) => (
      sta === TaskStatus.WAITING_MANAGER_CANCELLED_PERCENTAGE_APPROVAL
        ? 'waiting_for_your_approval'
        : 'waiting_for_contractor_approval'
    );

    return (
      <Pill
        content={t(`taskStatus.${getWaitingApprovalPillContent(status)}`)}
        color={PillColor.Yellow}
        textStyle={classnames(styles.warningPill, 'text__body__small__textNeutral10')}
      />
    );
  };

  return (
    <>
      <div className={styles.titlePillWrapper}>
        <span className="text__heading4__textNeutral50">
          {`${newTask.category} - ${newTask.name}`}
        </span>
        <Pill
          content={t(`taskStatus.${getPillContentByStatus(taskStatus as TaskStatus)}`)}
          color={getPillColorByTaskStatus(taskStatus as TaskStatus)}
          textStyle={classnames(styles.pill, 'text__body__small__textNeutral10')}
        />

        {isWaitingCancellationApproval(taskStatus as TaskStatus)
          && showWaitingApprovalPill(taskStatus as TaskStatus)}

      </div>
      <span className="text__heading5__textNeutral50">
        {`${newTask.buildingName || t('condoHouse')} - ${
          resolveAddress(newTask.address)}${newTask.address.unitNumber
          ? ` - Unit ${newTask.address.unitNumber}` : ''}`}
      </span>
      <div className={styles.actionsHeader}>
        <div className={styles.tabsWrapper}>
          {getTabs(taskStatus, newTask.taskType as TaskType).map((option) => (
            <div key={option}>
              <Tab
                onClickFn={() => clickTab(option as TaskDetailTabs)}
                name={t(`${translPrefix}.tabs.${option}.tabName`)}
                isActive={currentTab === option}
                alert={showAlert(option)}
              />
            </div>
          ))}
        </div>
        {canEditTask(newTask) && showEditButton(t(`${translPrefix}.tabs.status.modify`), newTask.id, 'mt-10')}
      </div>
    </>
  );
};

export { Header };
