import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { constants } from 'config/constants';
import { ApiError } from 'models/api-error';
import { ApiErrorSerializer } from 'networking/serializers/api-error-serializer';
import pRetry from 'p-retry';
import { API_ROUTES } from './api-routes';

enum HttpMethod {
  Get = 'get',
  Post = 'post',
  Patch = 'patch',
  Put = 'put',
  Delete = 'delete',
}

type Headers = {
  [key: string]: string,
};

type ApiServiceConfig = AxiosRequestConfig & {
  body?: {
    [key: string]: any,
  },
};

class ApiServiceClass {
  axios: AxiosInstance;

  private addedHeaders: Headers;

  constructor() {
    this.axios = axios.create({
      baseURL: constants.apiBaseURL,
      withCredentials: true,
    });
    this.addedHeaders = {};
  }

  async _sendRequest<ReturnType>(method: HttpMethod, url: string, config: ApiServiceConfig = {}) {
    try {
      const updatedConfig = { ...config };
      updatedConfig.headers = { ...this.addedHeaders, ...(config.headers || {}) };
      if (method === HttpMethod.Get || method === HttpMethod.Delete) {
        return await this.axios[method]<ReturnType>(url, updatedConfig);
      }
      const body = updatedConfig.body || {};
      delete updatedConfig.body;
      return await this.axios[method]<ReturnType>(url, body, updatedConfig);
    } catch (error: any) {
      if (error.response && error.response.data) {
        throw new ApiError({
          ...ApiErrorSerializer.deSerialize(error.response.data),
          http_status: error.response.status,
        });
      }
      throw new ApiError({
        http_status: null,
        error_code: null,
        message: error.message,
        description: null,
        details: null,
      });
    }
  }

  setHeaders(newHeaders: Headers) {
    Object.assign(this.addedHeaders, newHeaders);
  }

  get<ReturnType>(url: string, params = {}, config = {}) {
    return this.retryRequest<ReturnType>(HttpMethod.Get, url, { ...config, params });
  }

  post<ReturnType>(url: string, body = {}, config = {}) {
    return this.retryRequest<ReturnType>(HttpMethod.Post, url, { ...config, body });
  }

  patch<ReturnType>(url: string, body = {}, config = {}) {
    return this.retryRequest<ReturnType>(HttpMethod.Patch, url, { ...config, body });
  }

  put<ReturnType>(url: string, body = {}, config = {}) {
    return this.retryRequest<ReturnType>(HttpMethod.Put, url, { ...config, body });
  }

  delete<ReturnType>(url: string, params = {}, config = {}) {
    return this.retryRequest<ReturnType>(HttpMethod.Delete, url, { ...config, params });
  }

  async retryRequest<ReturnType>(method: HttpMethod, url: string, config = {}) {
    return pRetry(() => this._sendRequest<ReturnType>(method, url, config), {
      onFailedAttempt: async (error: any) => {
        if (error.status === 403) {
          const getToken = await this.axios.get(API_ROUTES.GET_TOKEN);
          const token = getToken.data.csrf_token;
          this.setHeaders({ 'X-CSRF-Token': token });
        } else {
          throw error;
        }
      },
      retries: 1,
    });
  }
}

const ApiService = new ApiServiceClass();

export { ApiService, HttpMethod };
