import { RawApiError } from '@mapix/common/src/types';

class ApiErrorSerializer {
  static deSerialize(data: RawApiError) {
    return {
      message: data.message || null,
      error_code: data.error_code || null,
      description: data.description || null,
      details: data.details || null,
    };
  }
}

export { ApiErrorSerializer };
