import styles from './splash-screen.module.scss';
import logo from '../../../public/splashscreen-logo.png';
import splashscreenGif from '../../../public/splashscreen.gif';

const SplashScreen = () => (
  <div className={styles.container}>
    <img src={logo} className={styles.logo} alt="logo" />
    <img src={splashscreenGif} className={styles.gif} alt="loading gif" />
  </div>
);

export { SplashScreen };
