class SimpleUnit {
  id: number;

  unitNumber: string;

  constructor(params: SerializedSimpleUnit) {
    this.id = params.id;
    this.unitNumber = params.unitNumber;
  }
}

export { SimpleUnit };
