class Contractor {
  id?: number;

  category: string;

  propertyId?: number;

  name: string;

  lastName?: string;

  phoneNumber: string;

  countryCode: string;

  email: string;

  constructor(params: SerializedTaskContractor | SerializedContractor | null) {
    this.id = params?.id;
    this.propertyId = params?.propertyId;
    this.category = params?.category || '';
    this.name = params?.name || '';
    this.lastName = params?.lastName || '';
    this.phoneNumber = params?.phoneNumber || '';
    this.email = params?.email || '';
    this.countryCode = params?.countryCode || '';
  }
}

export { Contractor };
