import { RadioButtonMobile } from '../radio-button-mobile';
import { classnames, HTMLValidationError } from '../../helpers/utils';
import styles from './form-binary-question.module.scss';
import { useMediaQuery } from '../../hooks/use-media-query';
import { Breakpoints } from '../enums';

type FormBinaryQuestionProps = {
  name: string,
  question: string,
  onChangeFn: () => void,
  value: boolean,
  formErrors: HTMLValidationError
  t: (text: string) => string,
  containerClass?: string,
};

const FormBinaryQuestion = ({
  question, onChangeFn, value, t, formErrors, containerClass, name,
}: FormBinaryQuestionProps) => {
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  return (
    <div className={containerClass}>
      <div className={classnames(`text__body__${mobile ? 'large' : 'medium'}__textNeutral40`, styles.question)}>
        {question}
      </div>
      <div className={styles.radioButtonContainer}>

        <RadioButtonMobile
          name={name}
          onChangeFn={onChangeFn}
          labelClass={classnames('text__body__medium__textNeutral40', styles.radioLabel)}
          labelContent={t('yes')}
          checked={value}
          formError={formErrors}
        />
        <RadioButtonMobile
          name={name}
          onChangeFn={() => onChangeFn()}
          labelClass={classnames('text__body__medium__textNeutral40', styles.radioLabel)}
          labelContent={t('no')}
          checked={!value}
          formError={formErrors}
        />
      </div>
    </div>

  );
};

export { FormBinaryQuestion };
