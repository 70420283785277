import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import styles from './modal.module.scss';

let modalRoot = document.querySelector('#modal-root') as HTMLElement;

type ModalProps = {
  children: JSX.Element
  withBackground?: boolean
  fixedTop?: boolean
};

const Modal = ({ children, withBackground = true, fixedTop = false }: ModalProps) => {
  const el = useRef(document.createElement('div'));

  useEffect(() => {
    // Use this in case CRA throws an error about react-hooks/exhaustive-deps
    const { current } = el;

    if (!modalRoot) {
      modalRoot = document.createElement('div');
      modalRoot.setAttribute('id', 'modal-root');
      document.body.appendChild(modalRoot);
    }

    modalRoot.appendChild(current);
    return () => {
      modalRoot.removeChild(current);
    };
  }, []);

  return createPortal(
    <div className={withBackground ? styles.modalContainerWithBackground : styles.modalContainer}>
      <div className={fixedTop ? styles.contentFixedTop : styles.content}>
        {children}
      </div>
    </div>,
    el.current,
  );
};

export { Modal };
