import { SetStateAction } from 'react';
import { DocumentsInformationType, PaymentInformationSummary } from '../../types';
import { Breakpoints } from '../enums';
import { PaymentInfoProgressBar } from '../payment-info-progress-bar';
import { classnames } from '../../helpers/utils';
import { useMediaQuery } from '../../hooks/use-media-query';

import styles from './payment-information.module.scss';
import { ModalAccentColor, ModalResult } from '../modal-result';
import { ReactComponent as User } from '../../assets/icons/user.svg';
import { ModalUploadFiles } from './modal-upload-files';

export enum ModalState {
  NONE = 'none',
  UPLOAD_FILES = 'uploadFilesModal',
  SUCCESS = 'successModal',
  ERROR = 'errorModal',
}

type PaymentInformationProps = {
  t: (text: string) => string,
  paymentInfo: PaymentInformationSummary,
  dbaInfoFn: () => void,
  legalBuisnessInfoFn: () => void,
  ownersOrOfficersFn: () => void,
  bankingInfoFn: () => void,
  salesProfileFn: () => void,
  documentsFn: () => void,
  pricingFn: () => void,
  setShowModal: (show: SetStateAction<ModalState>) => void,
  showModal: ModalState,
  submitApplication: () => Promise<void>,
  getDocumentsInformation: () => Promise<DocumentsInformationType>,
  uploadDocument: (key: string) => Promise<string>,
  handleChangeFile: (key: string) => void,
  handleCloseModalResult: () => void,
};

const translPrefix = 'paymentInformation';

const translPrefixUploadFiles = `${translPrefix}.documentsInformation.browseFiles.titles`;

const PaymentInformation = ({
  t, paymentInfo, dbaInfoFn,
  legalBuisnessInfoFn, ownersOrOfficersFn, bankingInfoFn,
  salesProfileFn, documentsFn, pricingFn, setShowModal, showModal,
  getDocumentsInformation, uploadDocument, handleChangeFile,
  submitApplication, handleCloseModalResult,
}: PaymentInformationProps) => {
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  if (showModal === ModalState.SUCCESS || showModal === ModalState.ERROR) {
    return (
      <ModalResult
        buttonTextRight={t('ok')}
        Icon={User}
        title={t(`${translPrefix}.${showModal}.title`)}
        content={t(`${translPrefix}.${showModal}.subtitle`)}
        withBackground
        modalAccentColor={showModal === ModalState.SUCCESS
          ? ModalAccentColor.GREEN : ModalAccentColor.RED}
        handleButtonClose={() => handleCloseModalResult()}
        handleButtonRight={() => handleCloseModalResult()}
      />
    );
  }

  return (
    <>
      <div className={styles.container}>

        <div className={mobile ? 'text__heading2__textNeutral50' : 'text__heading4__textNeutral40'}>
          {t(`${translPrefix}.title`)}
        </div>
        <div className={classnames(styles.subtitle, mobile ? 'text__body__large__textNeutral50' : 'text__body__medium__textNeutral50')}>
          {t(`${translPrefix}.subtitle`)}
        </div>

        <div className={styles.content}>
          <div className={styles.item}>
            <PaymentInfoProgressBar
              percentage={paymentInfo.dbaInformation.percentage}
              title={t(`${translPrefix}.dba`)}
              status={paymentInfo.dbaInformation.status}
              t={t}
              chevronFn={dbaInfoFn}
            />
          </div>

          <div className={styles.item}>
            <PaymentInfoProgressBar
              percentage={paymentInfo.legalInformation.percentage}
              title={t(`${translPrefix}.legalBuisness`)}
              status={paymentInfo.legalInformation.status}
              t={t}
              chevronFn={legalBuisnessInfoFn}
            />
          </div>

          <div className={styles.item}>
            <PaymentInfoProgressBar
              percentage={paymentInfo.ownersOrOfficers.percentage}
              title={t(`${translPrefix}.ownersOrOfficers`)}
              status={paymentInfo.ownersOrOfficers.status}
              t={t}
              chevronFn={ownersOrOfficersFn}
            />
          </div>

          <div className={styles.item}>
            <PaymentInfoProgressBar
              percentage={paymentInfo.electronicAuthorization.percentage}
              title={t(`${translPrefix}.bankingInfo`)}
              status={paymentInfo.electronicAuthorization.status}
              t={t}
              chevronFn={bankingInfoFn}
            />
          </div>

          <div className={styles.item}>
            <PaymentInfoProgressBar
              percentage={paymentInfo.salesProfile.percentage}
              title={t(`${translPrefix}.salesProfile`)}
              status={paymentInfo.salesProfile.status}
              t={t}
              chevronFn={salesProfileFn}
            />
          </div>

          <div className={styles.item}>
            <PaymentInfoProgressBar
              percentage={paymentInfo.documentInformation.percentage}
              title={t(`${translPrefix}.documents`)}
              status={paymentInfo.documentInformation.status}
              t={t}
              chevronFn={documentsFn}
            />
          </div>

          <div className={styles.item}>
            <PaymentInfoProgressBar
              percentage={paymentInfo.pricing.percentage}
              title={t(`${translPrefix}.pricing`)}
              status={paymentInfo.pricing.status}
              t={t}
              chevronFn={pricingFn}
            />
          </div>
        </div>
      </div>
      {showModal === ModalState.UPLOAD_FILES && (
      <ModalUploadFiles
        translPrefix={translPrefixUploadFiles}
        t={t}
        title={t(`${translPrefix}.uploadFilesModal.title`)}
        handleButtonClose={() => setShowModal(ModalState.NONE)}
        getDocumentsInformation={getDocumentsInformation}
        uploadDocument={uploadDocument}
        handleChangeFile={handleChangeFile}
        submitApplication={submitApplication}
      />
      )}
    </>
  );
};

export { PaymentInformation };
