import {
  PrivateRequest,
  RawPrivateRequest,
} from 'networking/types';
import { TypeOfCostSerializer } from '@mapix/common/src/networking/serializers/type-of-cost-serializer';
import { RawRequestEvent } from '@mapix/common/src/types';
import { MessageSerializer } from './message-serializer';
import { NewTaskSerializer } from './new-task-serializer';
import { RequestSerializer } from './request-serializer';

class PrivateRequestSerializer {
  static deSerializePrivateRequest(data: RawPrivateRequest): PrivateRequest {
    return {
      id: data.id,
      status: data.status,
      createdAt: data.created_at,
      reasonForRejection: data.reason_for_rejection || undefined,
      estimatedEndDate: data.estimated_end_date,
      startDate: data.start_date,
      negotiatingStartDate: data.negotiating_start_date,
      managerMessage: data.manager_message || undefined,
      contractorMessage: data.contractor_message,
      newTask: NewTaskSerializer.deSerializeNewTask(data.new_task),
      contractor: NewTaskSerializer.deSerializeBasicUserFields(data.contractor),
      privateRequestEvents: data.private_request_events.map(
        (event: RawRequestEvent) => RequestSerializer.deSerializeRequestEvent(event),
      ),
      taskMessages: MessageSerializer.deSerializeMessages(data.task_messages),
      manager: NewTaskSerializer.deSerializeBasicUserFields(data.manager),
      ...TypeOfCostSerializer.deSerializeTypeOfCost(data),
    };
  }
}

export { PrivateRequestSerializer };
