import {
  classnames, getDisplayFileSize, HTMLValidationError,
} from '@mapix/common/src/helpers/utils';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import {
  BuildingArea,
} from 'common/enums';
import {
  FileExtension, FileState, Priority, TaskType,
} from '@mapix/common/src/common/enums';
import { Select } from '@mapix/common/src/common/select';
import { TextArea } from '@mapix/common/src/common/textarea';
import { UnitNumberInput } from 'common/unit-number-input';
import { Specialization } from '@mapix/common/src/types/specialization';
import { SimpleUnit } from 'models/simple-unit';
import { FileUploadButton } from 'common/file-upload-button';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import { constants } from 'config/constants';
import { ReactComponent as Alert } from 'assets/icons/alertFilled.svg';
import { RadioButtonMobile } from '@mapix/common/src/common/radio-button-mobile';
import { logger } from '@mapix/common/src/helpers/logger';
import { ExtendedFile } from '@mapix/common/src/types';
import { ResourcesController } from 'networking/controllers/resources-controller';
import { ReactComponent as Info } from '@mapix/common/src/assets/icons/info24.svg';
import { UploadItem } from 'common/image-upload/upload-item';
import { ModalInformation } from '@mapix/common/src/common/modal-information';
import { SelectCategory } from 'common/select-category';
import { ImageOverlay, ImageOverlayObject } from '@mapix/common/src/common/image-overlay/image-overlay';
import styles from './create-edit-new-task-container-step-2.module.scss';

const {
  imageMaxSize, MAX_PHOTO_AMOUNT,
} = constants;

type Action =
  | { type: 'CHANGE_FIELD', value: string | number | Specialization, field: string }
  | { type: 'TASK_PHOTOS', taskPhotos: FileType[] }
  | { type: 'UNIT_SELECTED', specificUnit: number | null, unitNumber: string };

type CreateEditNewTaskContainerStep2Props = {
  fn: (action: Action) => void,
  createTaskInfo: CreateNewTaskState,
  formErrors: HTMLValidationError,
};

const translPrefix = 'createTask';

const createTaskArea = `${translPrefix}.concernedArea`;

const initOverlayState: ImageOverlayObject = {
  show: false,
  index: 0,
  photos: [],
};

const CreateEditNewTaskContainerStep2 = ({
  fn, createTaskInfo, formErrors,
}: CreateEditNewTaskContainerStep2Props) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const [pendingFiles, setPendingFiles] = useState<FileType[]>([]);
  const [fileError, setFileError] = useState('');

  const [overlayState, setOverlayState] = useState<ImageOverlayObject>(initOverlayState);

  const { taskPhotos } = createTaskInfo;

  const handleChange = (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
    option: string,
  ) => {
    fn({
      type: 'CHANGE_FIELD', field: option, value: event.target.value,
    });
  };

  const isDisabledRadioButton = (item: string, taskData: CreateNewTaskState) => {
    const { taskActualstate, publicRequestsCount } = taskData;

    if (!taskActualstate) {
      return false;
    }

    if (taskActualstate === TaskType.PrivateTask) {
      return item !== TaskType.PrivateTask;
    }
    if (taskActualstate === TaskType.PublicTask) {
      if (publicRequestsCount > 0) {
        return item !== TaskType.PublicTask;
      }
      if (publicRequestsCount === 0) {
        return false;
      }
    }

    return false;
  };

  const onClickSelect = (option: string | Specialization, objField: string) => {
    fn({
      type: 'CHANGE_FIELD', field: objField, value: option,
    });
  };

  const onChangeRadioButton = (item: string) => {
    onClickSelect(item, 'area');
    if (item === 'common') {
      fn({
        type: 'UNIT_SELECTED', unitNumber: '', specificUnit: null,
      });
    }
  };

  const handleUnitChange = (unit: SimpleUnit | null) => {
    fn({
      type: 'UNIT_SELECTED', unitNumber: unit?.unitNumber || '', specificUnit: unit?.id || null,
    });
  };

  const showFileError = () => (
    <div className={styles.fileErrorContainer}>
      <p className={classnames('text__body__semi__bold__small__textNeutral40', styles.bold)}>
        {fileError}
      </p>
      <Alert className={styles.alertIcon} />
    </div>
  );

  const removeFileFromListByUrl = (files: FileType[], fileToRemove: FileType) => (
    files.filter((f: FileType) => f.url !== fileToRemove.url)
  );

  const handleUploadNewFile = async (currentFile: File) => {
    const file: ExtendedFile = {
      filename: currentFile.name,
      state: FileState.LOADING,
      resource: currentFile,
      url: '',
    };

    setPendingFiles((prevState) => [...prevState, file]);

    try {
      const result = await ResourcesController.uploadFileV2(file);

      const uploadedFile: ExtendedFile = {
        state: FileState.COMPLETED,
        filename: file.filename,
        url: result,
      };

      fn({
        type: 'TASK_PHOTOS', taskPhotos: [...taskPhotos, uploadedFile],
      });

      const pendingFilesUpdated = removeFileFromListByUrl(pendingFiles, uploadedFile);
      setPendingFiles(pendingFilesUpdated);
    } catch (ex: any) {
      logger.error(ex);
    }
  };

  const showOverlay = (index: number, photos: FileType[]) => {
    setOverlayState({ index, photos, show: true });
  };

  const hideOverlay = () => {
    setOverlayState((prevState) => ({ ...prevState, show: false }));
  };

  const showImageOverlay = () => (
    <ImageOverlay
      photos={overlayState.photos}
      selectedIndex={overlayState.index}
      closeFn={hideOverlay}
      t={t}
    />
  );

  const removeFile = (file: FileType) => {
    const pendingFilesUpdated = removeFileFromListByUrl(taskPhotos, file);

    fn({
      type: 'TASK_PHOTOS', taskPhotos: pendingFilesUpdated,
    });
  };

  const showPendingFiles = () => (
    pendingFiles?.map((file) => (
      <UploadItem
        removeFn={() => {}}
        crossIcon
        filename={file.filename}
        loading
        isPdf={false}
        t={t}
      />
    ))
  );

  const showFetchedFiles = () => (
    taskPhotos.map((file, index) => (
      <div className="mr-20" key={file.url + file.filename}>
        <UploadItem
          onClickFn={() => showOverlay(index, taskPhotos)}
          removeFn={() => removeFile(file)}
          loading={false}
          filename={file.filename}
          url={file.url}
          isPdf={false}
          crossIcon
          t={t}
        />
      </div>
    ))
  );

  return (
    <>
      {overlayState.show && showImageOverlay()}

      {createTaskInfo.buildingType && (
        <>
          <div className={classnames(styles.firstMargin, 'text__heading6__textNeutral50')}>{t(`${translPrefix}.area`)}</div>
          <div className={styles.checkboxContainer}>
            <form className={styles.form}>
              <div className={styles.area}>
                {Object.keys(BuildingArea).map((item) => (
                  <RadioButtonMobile
                    key={item}
                    max={1}
                    onChangeFn={() => onChangeRadioButton(item)}
                    maxLength={1}
                    containerClass={styles.checkbox}
                    labelClass={classnames('text__body__medium__textNeutral40', styles.label)}
                    labelContent={t(`${createTaskArea}.${(item)}`)}
                    name="area"
                    checked={item === createTaskInfo.area}
                  />
                ))}
              </div>

            </form>
            <UnitNumberInput
              propertyId={createTaskInfo.propertyId as number}
              disabled={createTaskInfo.area !== 'specific'}
              prefix="createTask.unit"
              onSelectUnitFn={handleUnitChange}
              query={createTaskInfo.unitNumber}
              required={createTaskInfo.buildingType && createTaskInfo.area === 'specific'}
              formError={formErrors}
            />
          </div>
          {formErrors.area && !createTaskInfo.area && (<span className="text__body__tiny__danger50">{formErrors.area}</span>)}

        </>
      )}
      <div className={classnames('text__heading6__textNeutral50', styles.firstMargin)}>{t(`${translPrefix}.breakdown.title`)}</div>
      <div className="row">
        <SelectCategory
          categoryContainerClass={styles.inputLarge}
          otherCategoryContainerClass={styles.inputLarge}
          selectCategory={(category: Specialization) => onClickSelect(category, 'category')}
          setOtherCategory={(category: string) => onClickSelect(category, 'otherCategory')}
          category={createTaskInfo.category}
          formErrors={formErrors}
          otherCategory={createTaskInfo.otherCategory}

        />

        <Input
          id="name"
          name="name"
          label={t(`${translPrefix}.breakdown.placeholders.taskName`)}
          placeholder={t(`${translPrefix}.breakdown.placeholders.taskName`)}
          helperText={t(`${translPrefix}.breakdown.helperTexts.taskName`)}
          value={createTaskInfo.name}
          onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event, 'name')}
          required
          t={t}
          containerClass={styles.inputLarge}
          inputStyle={InputStyle.FORM}
          formError={formErrors}
        />
      </div>

      <TextArea
        required
        id="description"
        placeholder={t(`${translPrefix}.breakdown.placeholders.description`)}
        value={createTaskInfo.description}
        maxLength={500}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleChange(e, 'description')}
        containerClass={styles.inputDescription}
        formError={formErrors}
        t={t}
      />

      <div className={classnames('text__heading6__textNeutral50', styles.sectionTitle)}>
        {t(`${translPrefix}.priority.title`)}
      </div>
      <Select
        options={Object.values(Priority)}
        onClickOption={(option) => onClickSelect(option, 'priority')}
        optionTextClass={classnames(styles.options, 'text__body__small__textNeutral50')}
        required
        id="priority"
        value={createTaskInfo.priority ? `${t(`taskPriority.${createTaskInfo.priority}`)}` : ''}
        label={t(`${translPrefix}.priority.select`)}
        placeholder={t(`${translPrefix}.priority.select`)}
        inputStyle={InputStyle.FORM}
        containerClass={styles.inputLarge}
        formError={formErrors}
        translationPrefix="taskPriority"
        t={t}
      />

      <div className={styles.sectionTitleWrapper}>
        <span className="text__heading6__textNeutral50">{t(`${translPrefix}.taskType.title`)}</span>
        <button onClick={() => setShowModal(true)} type="button">
          <Info className={styles.info} />
        </button>
      </div>

      <div className="row">
        {Object.values(TaskType).map((item) => (
          <RadioButtonMobile
            key={item}
            max={1}
            onChangeFn={() => onClickSelect(item, 'taskType')}
            maxLength={1}
            containerClass={styles.checkbox}
            disabled={isDisabledRadioButton(item, createTaskInfo)}
            labelClass={classnames(
              'text__body__medium__textNeutral40',
              styles.label,
            )}
            labelContent={t(`${translPrefix}.taskType.${item}`)}
            name="taskType"
            checked={createTaskInfo.taskType === item}
          />
        ))}
      </div>
      {formErrors.taskType && !createTaskInfo.taskType && (<span className="text__body__tiny__danger50">{formErrors.taskType}</span>)}

      <div className={classnames(styles.sectionTitle, 'text__heading6__textNeutral50')}>{t(`${translPrefix}.photos.title`)}</div>

      <div className="mb-10 text__body__small__textNeutral40">
        {t('createTask.photos.size', { size: getDisplayFileSize(imageMaxSize), amount: MAX_PHOTO_AMOUNT })}
      </div>

      {taskPhotos.length < MAX_PHOTO_AMOUNT && (
        <FileUploadButton
          className={styles.fileUploadButton}
          icon={<ArrowUp />}
          text={t('createTask.photos.select')}
          supportedExtensions={[FileExtension.Jpg, FileExtension.Jpeg, FileExtension.Png]}
          maxFileSizeInMb={imageMaxSize}
          notifyNewFile={(file: File) => handleUploadNewFile(file)}
          notifyErrorFileMessage={(errorMessage: string) => setFileError(errorMessage)}
        />
      )}

      {fileError && showFileError()}

      <div className={styles.filesContainer}>
        {!!taskPhotos.length && showFetchedFiles()}

        {!!pendingFiles.length && showPendingFiles()}
      </div>

      {showModal && (
      <ModalInformation
        handleButtonClose={() => setShowModal(false)}
        handleButtonRight={() => setShowModal(false)}
        buttonsWrapperClass={styles.modalButtonWrapper}
        buttonRightClassStyle={styles.modalButton}
        buttonTextRight={t('ok')}
        containerClass={styles.modalContainer}
      >
        <>
          <div className={classnames('text__heading5__textNeutral40', styles.modalTitle)}>
            {t(`${translPrefix}.modal.title`)}

          </div>
          {['public', 'private', 'unpublished'].map((item) => (
            <p className="text__body__medium__textNeutral50">
              <span className={styles.semibold}>{`${t(`${translPrefix}.taskType.${item}_task`)}: `}</span>
              <span>{t(`${translPrefix}.modal.${item}Body`)}</span>
            </p>
          ))}
        </>
      </ModalInformation>
      )}
    </>
  );
};

export { CreateEditNewTaskContainerStep2 };
