import { useTranslation } from 'react-i18next';
import { SupportedLanguages } from '../../i18n/utils';
import { TermsOfUseEn } from './terms-of-use-en';
import { TermsOfUseFr } from './terms-of-use-fr';

const TermsOfUse = () => {
  const { i18n } = useTranslation();
  return (
    <div>
      {
        (i18n.language === SupportedLanguages.French)
          ? (<TermsOfUseFr />)
          : <TermsOfUseEn />
      }
    </div>
  );
};

export { TermsOfUse };
