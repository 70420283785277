import React, {
  ChangeEvent, MouseEventHandler, useEffect, useReducer, useState,
} from 'react';
import { Table } from 'common/table';
import { Tab } from '@mapix/common/src/common/tab';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import { Spinner } from '@mapix/common/src/common/spinner';
import { Pagination } from '@mapix/common/src/common/paginator';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { EmptyState } from 'common/empty-state';
import { ReactComponent as NoResultsIcon } from 'assets/icons/search-no-results-solid.svg';
import { LeaseController } from 'networking/controllers/lease-controller';
import { getFiltersByUrl, groupFilters } from 'helpers/utils';
import { classnames } from '@mapix/common/src/helpers/utils';
import { useTranslation } from 'react-i18next';
import { OptionsDate } from 'common/filter-by-date/filter-by-date';
import { DateCodes } from 'common/enums';
import { FilterByDate } from 'common/filter-by-date';
import { goToPage, RouteName } from 'routes';
import { ReactComponent as File } from 'assets/icons/file.svg';
import { LeaseTableItem } from 'models/lease-table-item';
import { MoreIcon } from 'common/more-icon';
import { ResendInvitation } from 'common/resend-invitation';
import {
  initDateFilter, initialState, LeaseTableReducer, LeaseTabs,
} from './lease-table-reducer';
import styles from './lease-table.module.scss';

const dateOptions: OptionsDate[] = [
  { code: DateCodes.All },
  { code: DateCodes.CustomDate },
];

const translPrefix = 'leases';
const LeaseTable = () => {
  const params = new URLSearchParams(window.location.search);
  const [state, dispatch] = useReducer(
    LeaseTableReducer,
    initialState(getFiltersByUrl(params.toString())),
  );
  const [idLease, setIdLease] = useState(0);
  const { t } = useTranslation();

  const iconsOptionsEnded = [t(`${translPrefix}.options.renewLease`)];
  const iconsOptionsOngoing = [t(`${translPrefix}.options.endLease`), t(`${translPrefix}.options.inviteTenant`)];

  const tabSelected = () => {
    if (state.currentTab === 'Buildings') return 'Building';
    if (state.currentTab === 'CondosAndHouses') return 'CondoHouse';
    return '';
  };

  const getLeases = async () => {
    dispatch({ type: 'FETCHING' });
    try {
      const response = await LeaseController
        .getLeases(
          state.paginator?.currentPage || 1,
          10,
          state.query,
          tabSelected(),
          state.dateFilter,
        );
      dispatch({ type: 'LEASES_FETCHED', paginator: response });
    } catch (err) {
      dispatch({ type: 'ERROR' });
    }
  };

  const clickPagination = (nextPage: number) => {
    if (nextPage !== state.paginator.currentPage) {
      dispatch({ type: 'PAGE_CHANGED', newPage: nextPage });
    }
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'SEARCH_QUERY', query: e.target.value });
  };

  const applyDate = (startDate: string, endDate: string, code: string) => {
    const dateFilter = { startDate, endDate, code };
    groupFilters(state.currentTab, dateFilter);
    dispatch({ type: 'APPLY_DATE_FILTERS', dateFilter });
  };

  const getMoreIcon = (options: string[], optionsFn: MouseEventHandler<HTMLButtonElement>[]) => (
    <MoreIcon iconClass={styles.getMoreIcon} key="more-icon" options={options} optionsFn={optionsFn} />
  );

  const tabChange = (tab: string) => {
    groupFilters(tab, initDateFilter);
    dispatch({ type: 'TAB_CHANGED', newTab: tab });
  };

  const getIcons = (item: LeaseTableItem) => {
    const ChevronRightComponent = (
      <ChevronRight
        className={styles.icons}
        key="chevron"
        onClick={() => goToPage(RouteName.LeaseDetail, { id: item.id })}
      />
    );

    const iconsFnOnGoing = [
      () => { goToPage(RouteName.RenewLease, { id: idLease }); },
      () => { goToPage(RouteName.EndLease, { id: idLease }); },
      () => dispatch({ type: 'SHOW_RESEND_MODAL', currentLeaseId: item.id, tenantEmail: item.tenantEmail }),
    ];
    const iconsFnEnded = [
      () => { goToPage(RouteName.RenewLease, { id: idLease }); },
    ];

    const icons = [ChevronRightComponent];
    if (item.status === 'ongoing') {
      icons.unshift(getMoreIcon(iconsOptionsOngoing, iconsFnOnGoing));
    }

    if (item.status === 'expired') {
      icons.unshift(getMoreIcon(iconsOptionsEnded, iconsFnEnded));
    }

    return icons;
  };

  useEffect(() => {
    getLeases();
  }, [state.query, state.currentTab, state.dateFilter, state.paginator?.currentPage]);

  const getTable = () => {
    if (!state.paginator || state.code === 'FETCHING') {
      return (
        <div className={styles.spinner}><Spinner fixed={false} /></div>
      );
    }

    if ((state.query !== '' || state.dateFilter !== initDateFilter) && state.paginator.results.length === 0) {
      return (
        <div className={styles.emptyState}>
          <EmptyState
            Icon={NoResultsIcon}
            title={t('emptyState.search')}
          />
        </div>
      );
    }

    if (state.paginator.results.length === 0) {
      return (
        <div className={styles.emptyState}>
          <EmptyState
            Icon={File}
            title={t('emptyState.leases.title')}
            iconStyle={styles.fileIcon}
          />
        </div>
      );
    }

    return (
      <>
        <Table
          headerNames={state.data.dataProperties}
          data={state.paginator.results}
          dataProperties={state.data.dataProperties}
          dashboardName={translPrefix}
          uniqueId="id"
          iconCell={getIcons}
          setId={setIdLease}
          rowClick={(item: LeaseTableItem) => goToPage(RouteName.LeaseDetail, { id: item.id })}
        />

        {state.paginator.lastPage > 1
          && (
            <div className={styles.paginationContainer}>
              <Pagination
                pageLimit={state.paginator.lastPage}
                onClickFn={(newPage: number) => clickPagination(newPage)}
                currentPage={state.paginator.currentPage}
              />
            </div>
          )}
      </>
    );
  };

  return (
    <div className={styles.dashboardContainer}>

      {state.showResend && state.currentLeaseId && (
        <ResendInvitation
          closeFn={() => dispatch({ type: 'CLOSE_RESEND_MODAL' })}
          leaseId={state.currentLeaseId}
          email={state.tenantEmail}
        />
      )}

      <div className="text__heading4__textNeutral40">
        {t(`${translPrefix}.title`)}
      </div>
      <div className={classnames(styles.subtitle, 'text__body__small__textNeutral30')}>
        {t(`${translPrefix}.subTitle`)}
      </div>

      <div className="row">
        {Object.values(LeaseTabs).map((tab) => (
          <div key={tab} className={styles.tabContainer}>
            <Tab
              name={t(`${translPrefix}.tabs.${tab}`)}
              isActive={state.currentTab === tab}
              onClickFn={() => tabChange(tab)}
            />
          </div>
        ))}
      </div>

      <div className={classnames(styles.filterRow, 'row fullWidth')}>
        <Input
          id="search"
          containerClass={styles.input}
          inputStyle={InputStyle.REGULAR}
          withSearchIcon
          placeholder={t(state.data.placeholderText)}
          onChange={onChangeInput}
          value={state.query}
          t={t}
        />

        <div className={styles.dateFilter}>
          <FilterByDate
            onClickOption={applyDate}
            dateFilter={(params.toString()) ? state.dateFilter : initDateFilter}
            options={dateOptions}
            key={state.currentTab}
          />
        </div>

      </div>

      {getTable()}
    </div>
  );
};

export { LeaseTable };
