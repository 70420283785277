import { CancelledByType, RawNewTaskStatus } from '@mapix/common/src/common/enums';
import { NewTask } from 'networking/types';
import { basicUserFieldsInitialState } from './basic-user-fields-initial-state';

export const newTaskInitialState: NewTask = {
  id: 0,
  status: RawNewTaskStatus.IN_PROGRESS,
  address: {
    city: '',
    country: '',
    civicNumber: '',
    streetName: '',
    province: '',
    zipCode: '',
    unitNumber: '',
  },
  description: '',
  buildingName: '',
  category: '',
  createdAt: '',
  name: '',
  newTaskEvents: [],
  inProgressManagerValidation: false,
  inProgressContractorValidation: false,
  inProgressRejectionExpiration: '',
  currentServerTime: '',
  priority: '',
  managerLastName: '',
  managerName: '',
  managerProfilePhoto: null,
  property: {
    id: 0,
    type: '',
    condition: '',
    address: {
      streetName: '',
      country: '',
      province: '',
      city: '',
      zipCode: '',
      cityId: 0,
      lat: 0,
      long: 0,
    },
    owner: {
      name: '',
      phoneNumber: '',
      email: '',
      countryCode: '',
    },
  },
  unit: {
    id: 0,
    unitNumber: '',
    status: '',
    lease: null,
    address: null,
  },
  startDate: '',
  solvedDate: '',
  taskMessages: [],
  solvedManagerValidation: false,
  solvedContractorValidation: false,
  solvedRejectionExpiration: '',
  inProgressDate: '',
  applications: 0,
  publicRequestsCount: 0,
  activeRequestAssignedId: 0,
  firstTimeInProgressRejectedManagerSeen: false,
  firstTimeSolvedRejectedManagerSeen: false,
  messageForInProgressMovementRejection: '',
  messageForSolvedMovementRejection: '',
  contractor: basicUserFieldsInitialState,
  contractorAssignedName: '',
  contractorAssignedLastName: '',
  taskType: 'private_task',
  noticeCancellationManagerSeen: false,
  noticeCancellationContractorSeen: false,
  negotiatingStartDate: '',
  cancelledDate: '',
  cancellationMessage: 'message cancelation',
  cancellationPercentage: 10,
  cancelledBy: CancelledByType.CancelledByManager,
  reasonForCancellation: 'reason cancelation',
  rejectedInProgressMovementBy: '',
  rejectedSolvedMovementBy: '',
  typeOfCost: 'budget',
  budget: 0,
  amountOfHours: 0,
  tenantLastName: '',
  tenantName: '',
};
