import { Unit } from 'models/unit';
import { useTranslation } from 'react-i18next';
import styles from 'pages/property-detail/property-detail.module.scss';
import { Condition, PropertyStatus } from 'common/enums';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { ReactComponent as EditPencil } from 'assets/icons/edit-black.svg';
import { classnames } from '@mapix/common/src/helpers/utils';
import { ContactInfo, LeaseInfo, UnitInfo } from 'common/detail-read-only-forms';
import { FilesInfo } from 'common/detail-read-only-forms/files-info';
import { goToPage, RouteName } from 'routes';

type UnitInfoTabProps = {
  unit: Unit,
  buildingName: string,
  fullAddress: string,
  propertyId: number,
};

const translPrefix = 'propertyDetail';
const UnitGeneralInfoTab = ({
  unit, buildingName, fullAddress, propertyId,
}: UnitInfoTabProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.buttonRow}>
        {unit.status === PropertyStatus.Rented && (
          <>
            <Button
              buttonStyle={ButtonStyle.Secondary}
              onClick={() => {
                goToPage(RouteName.RenewLease, { id: Number(unit.lease?.id) });
              }}
            >
              <div className="text__button__medium__primary60">
                {t(`${translPrefix}.buttons.renewLease`)}
              </div>
            </Button>
            <Button
              buttonStyle={ButtonStyle.Secondary}
              onClick={() => { goToPage(RouteName.EndLease, { id: Number(unit.lease?.id) }); }}
            >
              <div className="text__button__medium__primary60">
                {t(`${translPrefix}.buttons.endLease`)}
              </div>
            </Button>

            <Button
              onClick={() => {
                goToPage(RouteName.EditUnit, {
                  propertyId,
                  unitId: unit.id,
                });
              }}
              className={styles.button}
            >
              {t(`${translPrefix}.buttons.edit`)}
              <EditPencil className={styles.icon} />
            </Button>

          </>
        )}

        {unit.status === PropertyStatus.Available && unit.condition !== Condition.Archived && (
          <Button
            buttonStyle={ButtonStyle.Secondary}
            onClick={() => goToPage(RouteName.SetUnitAsRented, { propertyId, unitId: unit.id })}
          >
            <div className="text__button__medium__primary60">
              {t(`${translPrefix}.buttons.setRented`)}
            </div>
          </Button>
        )}
      </div>

      <div className={classnames(styles.contentTitle, 'text__body__large__textNeutral50')}>
        {t(`${translPrefix}.infoTab.statusTitle`)}
        {' '}
        {unit.status}
      </div>

      <div className={styles.line} />

      <div className={classnames(styles.contentTitle, 'text__body__large__textNeutral50')}>
        {t(`${translPrefix}.infoTab.locationTitle`)}
      </div>

      <UnitInfo
        buildingName={buildingName}
        fullAddress={fullAddress}
        unitNumber={unit.unitNumber}
      />

      <div className={styles.line} />

      <div className={classnames(styles.contentTitle, 'text__body__large__textNeutral50')}>
        {t(`${translPrefix}.infoTab.contactTitle`)}
      </div>

      {unit.status === PropertyStatus.Rented && unit.lease && (
        <>

          <ContactInfo owner={unit.lease.tenant} isUnit />

          <div className={styles.line} />

          <div className={classnames(styles.contentTitle, 'text__body__large__textNeutral50')}>
            {t(`${translPrefix}.infoTab.leaseTitle`)}
          </div>

          <LeaseInfo leaseDetail={unit.lease} />

          <div className={styles.line} />

          <div className={classnames(styles.contentTitle, 'text__body__large__textNeutral50')}>
            {t(`${translPrefix}.infoTab.filesTitle`)}
          </div>

          <FilesInfo
            unitPhotos={unit.lease.unitPhotos}
            leaseCopy={unit.lease.leaseCopy}
            tenantPhotos={unit.lease.tenantIdPhotos}
          />
        </>
      )}

    </>
  );
};

export { UnitGeneralInfoTab };
