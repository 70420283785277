import React from 'react';

import { PasswordRecovery as PasswordRecoveryCommon } from '@mapix/common/src/common/password-recovery';
import { useTranslation } from 'react-i18next';
import { UserController } from 'networking/controllers/user-controller';
import {
  goToPage,
  RouteName,
} from '../../routes';

const PasswordRecovery = () => {
  const { t } = useTranslation();

  return (
    <div>
      <PasswordRecoveryCommon
        t={t}
        translPrefix="passwordRecovery"
        forgotPassword={UserController.forgotPassword}
        checkGoogleUserError
        goToLogin={() => goToPage(RouteName.Login)}
      />
    </div>
  );
};

export { PasswordRecovery };
