import { useRef, useState } from 'react';
import { useClickOutside } from '@mapix/common/src/hooks/click-outside';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'assets/icons/chevron-up.svg';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useTranslation } from 'react-i18next';
import { DateRange } from 'react-date-range';
import { DateCodes } from 'common/enums';
import styles from './filter-by-date.module.scss';

type DateFilter = {
  startDate: string,
  endDate: string,
  code: string,
};

type OptionsDate = {
  code: string,
};

type FilterByDateProps = {
  onClickOption: (startDate: string, endDate: string, code: string) => void,
  mobile?: boolean,
  options?: OptionsDate[],
  dateFilter?: DateFilter
};

type DateType = {
  startDate: string,
  endDate: string,
};

const dateFilters = [
  { code: DateCodes.All },
  { code: DateCodes.Last7Days },
  { code: DateCodes.LastMonth },
  { code: DateCodes.Last3Months },
  { code: DateCodes.Last6Months },
  { code: DateCodes.CustomDate },
];

const FilterByDate = ({
  onClickOption, mobile = false,
  options = dateFilters, dateFilter,
}: FilterByDateProps) => {
  const [show, setShow] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string>(dateFilter?.code || DateCodes.All);
  const containerRef = useRef(null);
  const { t } = useTranslation();
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  useClickOutside(containerRef, () => setShow(false));
  useClickOutside(containerRef, () => setShowCalendar(false));

  const parseDate = (date: Date, separator = '-') => {
    const dd = date.getDate();
    const mm = date.getMonth() + 1;
    const yyyy = date.getFullYear();
    let ddString = '';
    let mmString = '';
    if (dd < 10) {
      ddString = `0${dd}`;
    } else {
      ddString = dd.toString();
    }
    if (mm < 10) {
      mmString = `0${mm}`;
    } else {
      mmString = mm.toString();
    }
    return `${yyyy}${separator}${mmString}${separator}${ddString}`;
  };

  const getDates = (option: string): DateType => {
    const today = new Date();
    const newDate = new Date(today);
    switch (option) {
      case 'last7Days':
      {
        newDate.setDate(newDate.getDate() - 7);
        return { startDate: parseDate(newDate), endDate: '' };
      }
      case 'lastMonth':
      {
        newDate.setDate(newDate.getDate() - 31);
        return { startDate: parseDate(newDate), endDate: '' };
      }
      case 'last3months':
      {
        newDate.setDate(newDate.getDate() - 93);
        return { startDate: parseDate(newDate), endDate: '' };
      }
      case 'last6months':
      {
        newDate.setDate(newDate.getDate() - 186);
        return { startDate: parseDate(newDate), endDate: '' };
      }
      default:
        return { startDate: '', endDate: '' };
    }
  };

  const onClick = (option: string) => {
    setShow(false);
    setSelectedValue(option);
    const { startDate, endDate } = getDates(option);
    if (option === 'customDate') {
      setSelectionRange((prevState) => (
        { ...prevState, startDate: new Date(), endDate: new Date() }));
      setShowCalendar(true);
    } else {
      onClickOption(startDate, endDate, option);
    }
  };

  const handleChangeCalendar = (ranges: any) => {
    const newDate = { ...selectionRange };
    newDate.startDate = ranges.selection.startDate;
    newDate.endDate = ranges.selection.endDate;
    setSelectionRange(newDate);
    onClickOption(parseDate(newDate.startDate), parseDate(newDate.endDate), 'customDate');
  };

  return (

    <div ref={containerRef} className={styles.selectContainer}>
      <Button
        buttonStyle={(mobile && selectedValue === 'customDate')
          ? ButtonStyle.secondaryBackgroundPrimary20
          : ButtonStyle.Secondary}
        onClick={() => setShow((prevState) => (!prevState))}
        className={styles.button}
      >
        <div className={styles.buttonContent}>
          {
            selectedValue === 'customDate' && !mobile

              ? (`${dateFilter?.startDate || parseDate((selectionRange.startDate), '/')} - ${dateFilter?.endDate || parseDate(selectionRange.endDate, '/')}`)
              : t(`claims.filterDate.${selectedValue}`)
          }
          {!show
            ? <ChevronDown className={styles.chevron} />
            : <ChevronUp className={styles.chevron} /> }
        </div>
      </Button>
      { show
      && (
      <div className={styles.content}>
        {options.map((option) => (
          <button key={option.code} onClick={() => onClick(option.code)} type="button" className={styles.item}>
            <div className="text__body__small__textNeutral50">
              {t(`claims.filterDate.${option.code}`)}
            </div>
          </button>
        ))}
      </div>
      )}
      {showCalendar
       && (
       <>
         <div className={mobile ? styles.calendarContentMobile : styles.calendarContent}>
           <DateRange
             ranges={[selectionRange]}
             onChange={handleChangeCalendar}
             showMonthAndYearPickers={false}
             months={mobile ? 1 : 2}
             showDateDisplay={false}
             direction="horizontal"
             rangeColors={['#654FBB']}
             className={mobile ? styles.calendarMobile : ''}
           />
         </div>

       </>
       )}
    </div>
  );
};

export { FilterByDate };
export type { DateFilter, OptionsDate };
