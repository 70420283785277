import { RawNewTaskStatus, Specializations } from '@mapix/common/src/common/enums';
import { initAddress } from '@mapix/common/src/initial-states/address-initial-state';
import { NewClaim } from 'networking/types/claim-auxiliar';

export const newClaimInitialState: NewClaim = {
  address: initAddress,
  category: Specializations.Carpentry,
  createdAt: '',
  description: '',
  id: 0,
  name: '',
  otherCategory: '',
  photos: [],
  propertyId: 0,
  startDate: '',
  status: RawNewTaskStatus.AWAITING,
  buildingName: '',
  estimatedEndDate: '',
  cancelledDate: '',
  contractor: null,
};
