import { Input, InputStyle } from '@mapix/common/src/common/input';
import {
  addError,
  checkHTMLErrors, classnames, isEmptyObject,
} from '@mapix/common/src/helpers/utils';
import {
  ChangeEvent, FormEvent, useContext, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { ButtonType } from '@mapix/common/src/common/button/button';
import { UserController } from 'networking/controllers/user-controller';
import { Spinner } from '@mapix/common/src/common/spinner';
import { Breakpoints } from 'common/enums';
import { appActions, AppContext } from 'context';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { PhoneInput } from '@mapix/common/src/common/phone-input';
import styles from './user-data.module.scss';

type FormType = {
  [key: string]: string,
  email: string,
  name: string,
  lastName: string,
  mainPhoneNumber: string,
  additionalPhoneNumber: string,
  mainCountryCode: string,
  additionalCountryCode: string,
};

type UserDataProps = {
  save: () => void,
};

const UserData = ({ save }: UserDataProps) => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AppContext);
  const [fetching, setFetching] = useState(false);
  const [showError, setShowError] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [phoneErrors, setPhoneErrors] = useState({});
  const [formData, setFormData] = useState<FormType>({
    name: state.user.name || '',
    lastName: state.user.lastName || '',
    email: state.user.email,
    mainPhoneNumber: state.user.phoneNumber || '',
    additionalPhoneNumber: state.user.additionalPhoneNumber || '',
    mainCountryCode: state.user.countryCode || '',
    additionalCountryCode: state.user.additionalCountryCode || '',
  });
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, id } = event.target;
    const newData = { ...formData };
    newData[id] = value;
    setFormData(newData);
  };
  const mobile = window.matchMedia(`(max-width: ${Breakpoints.sm}px)`).matches;

  const onChangePhoneNumber = (
    phone: string,
    countryCode: string,
    phoneProperty: string,
    countryProperty: string,
  ) => {
    setFormData((prevState) => (
      { ...prevState, [phoneProperty]: phone, [countryProperty]: countryCode }));
  };

  const handleSave = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    if (!target.checkValidity() || !formData.mainPhoneNumber) {
      if (!formData.mainPhoneNumber) {
        addError(t('error.emptyField'), 'mainPhoneNumber', setPhoneErrors);
      }
      setFormErrors(checkHTMLErrors(target));
      return;
    }

    if (!isEmptyObject(phoneErrors)) {
      return;
    }

    try {
      const user: UserEdit = {
        phoneNumber: formData.mainPhoneNumber,
        additionalPhoneNumber: formData.additionalPhoneNumber,
        name: formData.name,
        lastName: formData.lastName,
        type: formData.type,
        countryCode: formData.mainCountryCode,
        additionalCountryCode: formData.additionalCountryCode,
      };
      setFetching(true);
      const newInfo = await UserController.editProfile(user);
      setFetching(false);
      dispatch({ type: appActions.USER_LOGGED, user: newInfo });
      save();
    } catch (ex) {
      setShowError(true);
      setFetching(false);
    }
  };

  if (fetching) {
    return (
      <Spinner />
    );
  }

  return (
    <div className={styles.container}>
      {showError
      && <ErrorMessage message={t('error.errorMessage')} handleClose={() => setShowError(false)} />}
      <form onSubmit={handleSave} noValidate>
        <div className={styles.topContainer}>
          <p className={mobile ? 'text__body__large__textNeutral50' : 'text__heading5__textNeutral50'}>
            {t('userData.title')}
          </p>
        </div>
        <p className={classnames(styles.subtitle, mobile ? 'text__body__small__textNeutral50' : 'text__heading6__textNeutral50')}>
          {t('userData.subtitle')}
        </p>
        <div className={styles.data}>
          <div>
            <div className={styles.column}>
              <Input
                id="name"
                containerClass={styles.input}
                label={t('userData.name')}
                placeholder={t('userData.name')}
                inputStyle={InputStyle.FORM}
                value={formData.name}
                onChange={handleChange}
                helperText={t('userData.yourName')}
                required
                formError={formErrors}
                t={t}
              />
            </div>
            <div className={classnames(styles.column, styles.secondRow)}>
              <PhoneInput
                id="mainPhoneNumber"
                containerClass={styles.input}
                label={t('userData.mainPhoneNumber')}
                countryCode={formData.mainCountryCode}
                phoneWithoutCode={formData.mainPhoneNumber}
                helperText={t('userData.mainPhoneNumber')}
                required
                formError={{ ...formErrors, ...phoneErrors }}
                onChangePhoneNumber={(phoneNumber, countryCode) => onChangePhoneNumber(phoneNumber, countryCode, 'mainPhoneNumber', 'mainCountryCode')}
                setPhoneErrors={setPhoneErrors}
                validatePhone={UserController.phoneValidation}
                t={t}
              />
            </div>
            <div className={classnames(styles.column, styles.secondRow)}>
              <Input
                id="email"
                type="email"
                containerClass={styles.input}
                label={t('userData.email')}
                placeholder={t('userData.email')}
                inputStyle={InputStyle.FORM}
                value={formData.email}
                onChange={handleChange}
                helperText={t('userData.yourEmail')}
                disabled
                t={t}
              />
            </div>
          </div>
          <div className={styles.secondColumn}>
            <div className={styles.column}>
              <Input
                id="lastName"
                containerClass={styles.input}
                label={t('userData.lastName')}
                placeholder={t('userData.lastName')}
                inputStyle={InputStyle.FORM}
                value={formData.lastName}
                onChange={handleChange}
                helperText={t('userData.yourLastName')}
                required
                formError={formErrors}
                t={t}
              />
            </div>
            <div className={classnames(styles.column, styles.secondRow)}>
              <PhoneInput
                id="additionalPhoneNumber"
                containerClass={styles.input}
                label={t('userData.additionalPhoneNumber')}
                countryCode={formData.additionalCountryCode}
                phoneWithoutCode={formData.additionalPhoneNumber}
                onChangePhoneNumber={(phoneNumber, countryCode) => onChangePhoneNumber(phoneNumber, countryCode, 'additionalPhoneNumber', 'additionalCountryCode')}
                setPhoneErrors={setPhoneErrors}
                validatePhone={UserController.phoneValidation}
                t={t}
                formError={{ ...formErrors, ...phoneErrors }}
              />
            </div>
          </div>
        </div>
        <div className={styles.center}>
          <Button
            className={styles.buttonSave}
            buttonStyle={ButtonStyle.Primary}
            buttonType={ButtonType.Submit}
          >
            <div className="text__button__medium__textNeutral10">{t('userData.save')}</div>
          </Button>
        </div>
      </form>
    </div>
  );
};

export { UserData };
