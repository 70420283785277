import React, { FormEvent } from 'react';
import { useMediaQuery } from '../../hooks/use-media-query';
import { Breakpoints } from '../enums';
import { ReactComponent as Cross } from '../../assets/icons/cross.svg';
import { Button, ButtonStyle, ButtonType } from '../button';
import { classnames } from '../../helpers/utils';
import { Modal } from '../modal';
import styles from './modal-information.module.scss';

type ModalProps = {
  title?: string,
  body?: string | React.ReactElement,
  children?: JSX.Element
  buttonTextCenter?: string,
  buttonTextRight?: string,
  buttonRightClassStyle?: string,
  buttonsWrapperClass?: string,
  containerClass?: string,
  handleButtonCenter?: () => void,
  handleButtonRight: () => void,
  handleButtonClose: () => void,
  fixedTop?: boolean,
  disableButtonRight?: boolean,
  onSubmit?: (e: FormEvent<HTMLFormElement>) => Promise<void>,
  buttonStyleCenter?: ButtonStyle,
};

const ModalInformation = ({
  title = '', body = '', buttonTextCenter = '', buttonTextRight = '',
  buttonRightClassStyle = '', handleButtonCenter, handleButtonRight,
  handleButtonClose, children, fixedTop = false, disableButtonRight = false,
  onSubmit, buttonsWrapperClass = '', containerClass = '',
  buttonStyleCenter,
}: ModalProps) => {
  const handleContainerClass = classnames(styles.modalContainer, containerClass);

  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const innerContent = (
    <>
      <div className={styles.titleAndCrossContainer}>
        <p className={classnames(
          styles.semiBold,
          `text__body__${mobile ? 'large' : 'medium'}__textNeutral40`,
        )}
        >
          {title}
        </p>
        <button type="button" onClick={handleButtonClose}>
          <Cross className={styles.crossIcon} />
        </button>
      </div>

      <div className={styles.center}>
        { body && (
          <div className={classnames(
            styles.body,
            `text__body__${mobile ? 'medium' : 'small'}__textNeutral40`,
          )}
          >
            {body}

          </div>
        )}

        {children}

        <div className={classnames(styles.buttonsWrapper, buttonsWrapperClass)}>
          {handleButtonCenter && (
            <Button
              buttonStyle={buttonStyleCenter || ButtonStyle.GhostSecondary}
              className={classnames('text__button__small__secondary90', styles.cancel)}
              onClick={handleButtonCenter}
            >
              {buttonTextCenter}
            </Button>
          )}

          <Button
            buttonStyle={ButtonStyle.Primary}
            onClick={handleButtonRight}
            className={classnames('text__button__small__textNeutral10', buttonRightClassStyle, styles.buttonRight)}
            disabled={disableButtonRight}
            buttonType={onSubmit ? ButtonType.Submit : ButtonType.Button}
          >
            {buttonTextRight}
          </Button>
        </div>
      </div>
    </>
  );

  return (
    <Modal fixedTop={fixedTop}>
      {onSubmit ? (
        <form className={handleContainerClass} noValidate onSubmit={onSubmit}>
          {innerContent}
        </form>
      ) : (
        <div className={handleContainerClass}>
          {innerContent}
        </div>
      )}
    </Modal>
  );
};

export { ModalInformation };
