import {
  RawCanadaProvinces,
  RawTypeOfOwnership,
  RawBusinessDescription,
  LegalBusinessInfoType,
  RawLegalBusinessInfo,
  DBAinformationType,
  RawTimezones,
  RawPaymentInformation,
  RawDBAinformation,
  OwnersOrOfficersType,
  RawOwnersOrOfficers,
  SalesProfileType,
  RawSalesProfile,
  ElectronicDebitCreditAuthorizationType,
  RawElectronicDebitCreditAuthorization,
  RawBankInstitutions,
  DocumentsInformationType,
  RawDocumentsInformation,
} from '@mapix/common/src/types';
import { PaymentInformationSerializer } from 'networking/serializers/payment-information-serializer';
import { ApiService } from '../api-service';
import { API_ROUTES } from '../api-routes';

class PaymentInformationController {
  static async getPaymentInformation() {
    const result = await ApiService.get<RawPaymentInformation>(API_ROUTES.PAYMENT_INFORMATION);
    return PaymentInformationSerializer.deSerializePaymentInformation(result.data);
  }

  static async getTimezones() {
    const result = await ApiService.get<RawTimezones>(API_ROUTES.TIMEZONES);
    return PaymentInformationSerializer.deSerializeTimezones(result.data);
  }

  static async getCanadaProvinces() {
    const result = await ApiService.get<RawCanadaProvinces>(API_ROUTES.GET_CANADA_PROVINCES);
    return PaymentInformationSerializer.deserializeCanadaProvinces(result.data);
  }

  // Legal business information
  static async newLegalBusinessInfo(legalInformation: LegalBusinessInfoType) {
    const serializedLegalInformation = PaymentInformationSerializer.serializeNewLegalBusinessInfo(
      legalInformation,
    );
    return ApiService.post<RawLegalBusinessInfo>(
      API_ROUTES.LEGAL_BUSINESS_INFO,
      serializedLegalInformation,
    );
  }

  static async getLegalBusinessInfo() {
    const result = await ApiService.get<RawLegalBusinessInfo>(API_ROUTES.LEGAL_BUSINESS_INFO);
    return PaymentInformationSerializer.deserializeLegalBusinessInfo(result.data);
  }

  static async editLegalBusinessInfo(legalInformation: LegalBusinessInfoType) {
    const serializedLegalInformation = PaymentInformationSerializer.serializeNewLegalBusinessInfo(
      legalInformation,
    );
    return ApiService.patch<RawLegalBusinessInfo>(
      API_ROUTES.LEGAL_BUSINESS_INFO,
      serializedLegalInformation,
    );
  }

  // DBA information
  static async createDBAInformation(dba: DBAinformationType) {
    const serializedDBAInformation = PaymentInformationSerializer.serializeDBAInformation(
      dba,
    );
    return ApiService.post(
      API_ROUTES.DBA_INFORMATION,
      serializedDBAInformation,
    );
  }

  static async updateDBAInformation(dba: DBAinformationType) {
    const serializedDBAInformation = PaymentInformationSerializer.serializeDBAInformation(
      dba,
    );
    return ApiService.patch(
      API_ROUTES.DBA_INFORMATION,
      serializedDBAInformation,
    );
  }

  static async getDBAInformation() {
    const response = await ApiService.patch<RawDBAinformation>(API_ROUTES.DBA_INFORMATION);
    return PaymentInformationSerializer.deSerializeDBAInformation(
      response.data as RawDBAinformation,
    );
  }

  // Owners or officers
  static async createOwnersOrOfficers(ownersOrOfficers: OwnersOrOfficersType) {
    const serializedOwnersOrOfficers = PaymentInformationSerializer.serializeOwnersOrOfficers(
      ownersOrOfficers,
    );
    return ApiService.post(
      API_ROUTES.OWNERS_OR_OFFICERS,
      serializedOwnersOrOfficers,
    );
  }

  static async updateOwnersOrOfficers(ownersOrOfficers: OwnersOrOfficersType) {
    const serializedOwnersOrOfficers = PaymentInformationSerializer.serializeOwnersOrOfficers(
      ownersOrOfficers,
    );
    return ApiService.patch(
      API_ROUTES.OWNERS_OR_OFFICERS,
      serializedOwnersOrOfficers,
    );
  }

  static async getOwnersOrOfficers() {
    const response = await ApiService.patch<RawOwnersOrOfficers>(API_ROUTES.OWNERS_OR_OFFICERS);
    return PaymentInformationSerializer.deSerializeOwnersOrOfficers(
      response.data as RawOwnersOrOfficers,
    );
  }

  // Sales profile
  static async createSalesProfile(salesProfile: SalesProfileType) {
    const serializedSalesProfile = PaymentInformationSerializer.serializeSalesProfile(
      salesProfile,
    );
    return ApiService.post(
      API_ROUTES.SALES_PROFILE,
      serializedSalesProfile,
    );
  }

  static async updateSalesProfile(salesProfile: SalesProfileType) {
    const serializedSalesProfile = PaymentInformationSerializer.serializeSalesProfile(
      salesProfile,
    );
    return ApiService.patch(
      API_ROUTES.SALES_PROFILE,
      serializedSalesProfile,
    );
  }

  static async getSalesProfile() {
    const response = await ApiService.patch<RawSalesProfile>(API_ROUTES.SALES_PROFILE);
    return PaymentInformationSerializer.deSerializeSalesProfile(
      response.data as RawSalesProfile,
    );
  }

  // ElectronicDebitCreditAuthorization

  static async createElectronicDebitCreditAuthorization(
    electronicAuthorization: ElectronicDebitCreditAuthorizationType,
  ) {
    const serializedElectronicAuthorization = PaymentInformationSerializer
      .serializeElectronicDebitCreditAuthorization(
        electronicAuthorization,
      );
    return ApiService.post(
      API_ROUTES.ELECTRONIC_AUTHORIZATION,
      serializedElectronicAuthorization,
    );
  }

  static async updateElectronicDebitCreditAuthorization(
    electronicAuthorization: ElectronicDebitCreditAuthorizationType,
  ) {
    const serializedElectronicAuthorization = PaymentInformationSerializer
      .serializeElectronicDebitCreditAuthorization(
        electronicAuthorization,
      );
    return ApiService.patch(
      API_ROUTES.ELECTRONIC_AUTHORIZATION,
      serializedElectronicAuthorization,
    );
  }

  static async getElectronicDebitCreditAuthorization() {
    const response = await ApiService.patch<RawElectronicDebitCreditAuthorization>(
      API_ROUTES.ELECTRONIC_AUTHORIZATION,
    );
    return PaymentInformationSerializer.deSerializeElectronicDebitCreditAuthorization(
      response.data as RawElectronicDebitCreditAuthorization,
    );
  }

  // Documents information
  static async createDocumentsInformation(documentsInformation: DocumentsInformationType) {
    const serializedDocumentsInformation = PaymentInformationSerializer
      .serializeDocumentsInformation(documentsInformation);
    return ApiService.post(
      API_ROUTES.DOCUMENTS_INFORMATION,
      serializedDocumentsInformation,
    );
  }

  static async updateDocumentsInformation(documentsInformation: DocumentsInformationType) {
    const serializedDocumentsInformation = PaymentInformationSerializer
      .serializeDocumentsInformation(documentsInformation);
    return ApiService.patch(
      API_ROUTES.DOCUMENTS_INFORMATION,
      serializedDocumentsInformation,
    );
  }

  static async getDocumentsInformation() {
    const response = await ApiService.get<RawDocumentsInformation>(
      API_ROUTES.DOCUMENTS_INFORMATION,
    );
    return PaymentInformationSerializer.deSerializeDocumentsInformation(
      response.data as RawDocumentsInformation,
    );
  }

  // Final upload document

  static async uploadDocument(name: keyof DocumentsInformationType) {
    const serializedName = PaymentInformationSerializer.serializeDocumentName(name);
    const result = await ApiService.post<string>(API_ROUTES.UPLOAD_DOCUMENT(serializedName));
    return result.data;
  }

  static async createApplication() {
    const result = await ApiService.post<RawPaymentInformation>(API_ROUTES.PAYMENT_INFORMATION);
    return PaymentInformationSerializer.deSerializeCreateApplication(result.data);
  }

  static async submitApplication() {
    const result = await ApiService.post(API_ROUTES.PAYMENT_INFORMATION_SUBMIT);
    return result.data;
  }

  // End Payment information pages
  static async getTypeOfOwnership() {
    const result = await ApiService.get<RawTypeOfOwnership>(API_ROUTES.GET_TYPE_OF_OWNERSHIP);
    return PaymentInformationSerializer.deserializeTypeOfOwnership(result.data);
  }

  static async getBusinessDescriptions() {
    const result = await ApiService.get<
    RawBusinessDescription>(API_ROUTES.GET_BUSINESS_DESCRIPTIONS);
    return PaymentInformationSerializer.deserializeBusinessDescriptions(result.data);
  }

  static async getBankInstitutions() {
    const result = await ApiService.get<
    RawBankInstitutions>(API_ROUTES.BANK_INSTITUTIONS);
    return PaymentInformationSerializer.deserializeBankInstitutions(result.data);
  }
}
export { PaymentInformationController };
