import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { ButtonType } from '@mapix/common/src/common/button/button';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { classnames } from '@mapix/common/src/helpers/utils';
import styles from './create.module.scss';

type CreateFooterProps = {
  className?: string,
  backName?: string,
  showBack?: boolean,
  backFn?: () => void,
  withBackIcon?: boolean,
  nextName: string,
  nextFn?: () => void,
  nextButtonType?: ButtonType,
  nextButtonStyle?: string,
  disableNextCondition?: boolean,
  Icon?: any,
  iconStyle?: string,
  secondButtonName?: string,
  secondButtonFn?: () => void,
  SecondIconButton?: any,
  secondIconButtonStyle?: string,
  rejectButton?: boolean,
};

const CreateFooter = ({
  showBack = false, nextName, backFn, nextFn,
  nextButtonType = ButtonType.Button, secondButtonName = '', secondButtonFn, iconStyle = '',
  Icon = ChevronRight, withBackIcon = true, backName = 'back', disableNextCondition = false,
  SecondIconButton, secondIconButtonStyle = '', rejectButton = false, className = '',
  nextButtonStyle = '',
}: CreateFooterProps) => {
  const { t } = useTranslation();

  return (

    <div className={classnames(className, styles.footer, showBack ? styles.backAndNextButton : '')}>
      <hr className={styles.divider} />
      <div className={styles.footerContent}>

        {showBack
    && (
    <Button
      className={classnames(styles.button, styles.left)}
      onClick={backFn}
      buttonType={ButtonType.Submit}
      buttonStyle={ButtonStyle.GhostSecondary}
    >
      {withBackIcon && (
      <ChevronLeft className={classnames(styles.chevron, styles.left)} />)}
      <div className="text__button__large__primary40">{t(backName)}</div>
    </Button>
    )}
        <div className={classnames(styles.rightButtons, showBack ? styles.rightButtonContainer : '')}>
          {secondButtonName
      && (
        <Button
          className={classnames(styles.button, styles.second)}
          onClick={secondButtonFn}
          buttonStyle={rejectButton ? ButtonStyle.Red : ButtonStyle.Secondary}
        >
          <div className={rejectButton ? 'text__button__large__danger50' : 'text__button__large__primary40'}>{secondButtonName}</div>
          {SecondIconButton
            ? <SecondIconButton className={classnames(secondIconButtonStyle || styles.right)} />
            : null}
        </Button>
      )}
          <Button
            className={classnames(styles.button, styles.right, nextButtonStyle)}
            onClick={nextFn}
            buttonType={nextButtonType}
            buttonStyle={ButtonStyle.Primary}
            disabled={disableNextCondition}
          >
            <div className={`${disableNextCondition ? 'text__button__large__textNeutral20'
              : 'text__button__large__textNeutral10'}`}
            >
              {nextName}
            </div>
            {Icon && !disableNextCondition
          && (<Icon className={classnames(styles.chevron, iconStyle || styles.right)} />)}
          </Button>
        </div>
      </div>
    </div>
  );
};

export { CreateFooter };
