// Based on https://react-leaflet.js.org/docs/example-draggable-marker/

import {
  useMemo, useRef,
} from 'react';

import { Marker } from 'react-leaflet';
import { pinIcon } from '../map-pin-icon/pin-icon';
import { Coordinates } from '../../types';

type DraggableMarkerProps = {
  center: Coordinates,
  setCenter?: (center: Coordinates) => void,
};

const DraggableMarker = ({ center, setCenter }: DraggableMarkerProps) => {
  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker: any = markerRef.current;
        if (marker != null && setCenter) {
          const coords = marker.getLatLng();
          setCenter([coords.lat, coords.lng]);
        }
      },
    }),
    [],
  );

  return (
    <Marker
      draggable
      eventHandlers={eventHandlers}
      position={center}
      ref={markerRef}
      icon={pinIcon()}
    />
  );
};

export { DraggableMarker };
