import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { DateCodes, RentalsStatus } from 'common/enums';
import { FilterByDate } from 'common/filter-by-date';
import { FiltersApplied } from 'common/filters-applied';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import { MoreIcon } from 'common/more-icon';
import { Pagination } from '@mapix/common/src/common/paginator';
import { Spinner } from '@mapix/common/src/common/spinner';
import { EmptyState } from 'common/empty-state';
import { ReactComponent as Card } from 'assets/icons/card.svg';
import { ReactComponent as PlusCircle } from 'assets/icons/plus-circle.svg';
import { Table } from 'common/table';
import { logger } from 'helpers/logger';
import { RentalDashboard } from 'models/rental-dashboard';
import { ReactComponent as Filter } from 'assets/icons/filter.svg';
import {
  ChangeEvent, useEffect, useReducer, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { RentalDetailsModal } from 'common/rental-details-modal';
import { RentalsController } from 'networking/controllers/rentals-controller';
import { ReactComponent as Info } from '@mapix/common/src/assets/icons/info.svg';
import { FilterModal, ModalFilterItem } from 'common/filter-modal/filter-modal';
import { Modal } from '@mapix/common/src/common/modal';
import { getFiltersByUrl, groupFilters } from 'helpers/utils';
import { addOrRemoveFromArrayObject, classnames } from '@mapix/common/src/helpers/utils';
import { OptionsDate } from 'common/filter-by-date/filter-by-date';
import { initDateFilter } from 'common/bills-table/bills-table-reducer';
import { goToPage, RouteName } from 'routes';
import { ModalInformation } from '@mapix/common/src/common/modal-information';
import { CommonReducer, initialStateBuilding } from './common-reducer';
import styles from './rentals-dashboard.module.scss';

type CommonDashboardProps = {
  building?: boolean,
  tableHeaders?: string[],
  tableDataProperties?: string[],
  searchText?: string,
  id?: number,
  fromCondoTab?: boolean,
  unitId?: number,
  showCreateRental?: boolean
};

const dateOptions: OptionsDate[] = [
  { code: DateCodes.All },
  { code: DateCodes.CustomDate },
];

const mapTableContent = (
  rentalDashboard: RentalDashboard[],
  toggleInfoModal: () => void,
) => rentalDashboard.map((item) => ({
  ...item,
  billedAmount: (item.fullMonth && !item.extensionBorderRental) ? item.billedAmount : (
    <div className={styles.billedAmount}>
      {item.billedAmount}
      <button onClick={toggleInfoModal} type="button">
        <Info className={styles.info} />
      </button>
    </div>
  ),
}));

const CommonDashboard = ({
  building, fromCondoTab, tableDataProperties = [], tableHeaders = [],
  searchText = 'rentalsDashboard.buildingTab.search', id, unitId, showCreateRental = false,
}: CommonDashboardProps) => {
  const params = new URLSearchParams(window.location.search);

  const [fetching, setFetching] = useState(false);
  const [errorServer, setErrorServer] = useState(false);
  const [state, dispatch] = useReducer(CommonReducer, initialStateBuilding(
    tableHeaders,
    tableDataProperties,
    building,
    getFiltersByUrl(params.toString()),
  ));

  const { t } = useTranslation();

  const getIcons = (item: RentalDashboard) => {
    const moreFns = [() => goToPage(RouteName.EditRental, { id: item.id })];
    if (item.status === RentalsStatus.Overdue || item.status === RentalsStatus.Pending) {
      return (
        [
          <div key="right-elements" className={styles.tableRightElements}>
            <MoreIcon
              iconClass={styles.icons}
              options={[t('rentalsDashboard.edit')]}
              optionsFn={moreFns}
            />
          </div>,
        ]);
    }
    return (
      [
        <div key="right-elements" className={styles.tableRightElements}>
          <Button
            onClick={() => dispatch({ type: 'SHOW_DETAIL', rental: item })}
            className={styles.details}
            buttonStyle={ButtonStyle.Link}
          >
            <div className="text__body__medium__secondary90">
              {t('rentalsDashboard.condoTab.seeDetails')}
            </div>
          </Button>
          <MoreIcon
            iconClass={styles.icons}
            options={[t('rentalsDashboard.edit')]}
            optionsFn={moreFns}
          />
        </div>,
      ]);
  };

  const getRentals = async () => {
    try {
      setFetching(true);
      const response = await RentalsController
        .getRentals(
          state.paginator?.currentPage || 1,
          10,
          state.dateFilter,
          building ? '' : 'CondoHouse',
          state.query,
          state.appliedFilters,
        );
      dispatch({ type: 'RENTALS_FETCHED', paginator: response });
      setFetching(false);
    } catch (err) {
      setErrorServer(true);
      setFetching(false);
      logger.error(err as Error);
    }
  };

  const getPropertyRentals = async () => {
    if (id) {
      try {
        setFetching(true);
        const response = await RentalsController
          .getPropertyRentals(
            id,
            state.paginator?.currentPage || 1,
            10,
            state.dateFilter,
            state.query,
            state.appliedFilters,
          );
        dispatch({ type: 'RENTALS_FETCHED', paginator: response });
        setFetching(false);
      } catch (err) {
        setErrorServer(true);
        setFetching(false);
        logger.error(err as Error);
      }
    }
  };

  const getUnitRentals = async () => {
    if (id && unitId) {
      try {
        setFetching(true);
        const response = await RentalsController
          .getUnitRentals(
            id,
            unitId,
            state.paginator?.currentPage || 1,
            10,
            state.dateFilter,
            state.query,
            state.appliedFilters,
          );
        dispatch({ type: 'RENTALS_FETCHED', paginator: response });
        setFetching(false);
      } catch (err) {
        setErrorServer(true);
        setFetching(false);
        logger.error(err as Error);
      }
    }
  };

  useEffect(() => {
    if (!state.showEdit) {
      if (id && unitId) {
        getUnitRentals();
      } else if (id) {
        getPropertyRentals();
      } else {
        getRentals();
      }
    }
  }, [state.paginator.currentPage, state.input, state.showEdit,
    state.appliedFilters, state.dateFilter]);

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'INPUT_CHANGED', input: e.target.value });
    dispatch({ type: 'SEARCH_QUERY', query: e.target.value });
  };

  const clickPagination = (nextPage: number) => {
    if (nextPage !== state.paginator.currentPage) {
      dispatch({ type: 'PAGE_CHANGED', newPage: nextPage });
    }
  };

  const clearFilters = () => {
    groupFilters('', state.dateFilter, []);
    dispatch({ type: 'CLEAR_FILTERS' });
  };

  const applyFilters = (updatedFilters: ModalFilterItem[]) => {
    groupFilters('CondoHouse', state.dateFilter, updatedFilters);
    dispatch({ type: 'APPLY_FILTERS', filters: updatedFilters });
  };

  const toggleFilterModal = () => {
    dispatch({ type: 'TOGGLE_FILTER_MODAL' });
  };

  const toggleInfoModal = () => {
    dispatch({ type: 'TOGGLE_INFO_MODAL' });
  };

  const removeFilter = (filter: ModalFilterItem) => {
    const updatedFilters = addOrRemoveFromArrayObject(state.appliedFilters, filter, 'code');
    groupFilters('CondoHouse', state.dateFilter, updatedFilters);
    dispatch({ type: 'APPLY_FILTERS', filters: updatedFilters });
  };

  const applyDate = (startDate: string, endDate: string, code: string) => {
    const dateFilter = { startDate, endDate, code };
    groupFilters('CondoHouse', dateFilter, state.appliedFilters);
    dispatch({ type: 'APPLY_DATE_FILTERS', dateFilter });
  };

  const goToCreateRentals = () => {
    if (unitId && id) {
      goToPage(RouteName.ExtendLeaseUnit, { id, unitId });
    } else if (id) {
      goToPage(RouteName.ExtendLease, { id });
    }
  };

  const getTable = () => {
    if (!state.paginator || fetching) {
      return (
        <div className={styles.spinner}><Spinner fixed={false} /></div>
      );
    }

    if (state.paginator.results.length === 0) {
      return (
        <div className={styles.emptyState}>
          <EmptyState
            Icon={Card}
            title={fromCondoTab ? t('rentalsDashboard.noRentals') : t('rentalsDashboard.noPropertyRentals')}
            iconStyle={styles.cardIcon}
          />
        </div>
      );
    }
    return (
      <>
        <div className={styles.table}>
          <Table
            headerNames={state.tableHeaders}
            data={mapTableContent(state.paginator.results, toggleInfoModal)}
            dataProperties={state.tableDataProperties}
            dashboardName="rentalsDashboard"
            iconCell={getIcons}
            uniqueId="id"
            clickable={false}
            cellClassCondition={RentalsStatus.Overdue}
            cellClass={styles.overdue}
          />
        </div>
        {state.paginator.lastPage > 1
    && (
      <div className={styles.paginationContainer}>
        <Pagination
          pageLimit={state.paginator.lastPage}
          onClickFn={(newPage: number) => clickPagination(newPage)}
          currentPage={state.paginator.currentPage}
        />
      </div>
    )}
      </>
    );
  };

  return (
    <div className={styles.table}>
      {errorServer
          && <ErrorMessage message={t('error.errorMessage')} handleClose={() => setErrorServer(false)} />}

      {state.showDetails && state.rental && state.rental.paidOn && (
      <RentalDetailsModal
        paymentDate={state.rental.paidOn}
        closeFn={() => dispatch({ type: 'HIDE_DETAIL' })}
        manuallyPaid={state.rental.manuallyPaid}
      />
      )}

      {state.infoModal && (
      <ModalInformation
        handleButtonClose={toggleInfoModal}
        handleButtonRight={toggleInfoModal}
        buttonsWrapperClass={styles.modalButtonWrapper}
        buttonRightClassStyle={styles.modalButton}
        buttonTextRight={t('ok')}
      >
        <div className={classnames('text__body__medium__textNeutral50', styles.modalText)}>
          {t('rentalsDashboard.infoModalText')}
        </div>
      </ModalInformation>
      )}

      {state.filterModal && (
        <Modal>
          <FilterModal
            appliedFilters={state.appliedFilters}
            filters={state.filters}
            closeFn={toggleFilterModal}
            applyFn={applyFilters}
            prefix="rentalsDashboard"
          />
        </Modal>
      )}

      <div className={styles.filterRow}>
        <Input
          id="filter"
          containerClass={styles.input}
          inputStyle={InputStyle.REGULAR}
          withSearchIcon
          placeholder={t(searchText)}
          onChange={onChangeInput}
          value={state.input}
          t={t}
        />
        <Button buttonStyle={ButtonStyle.Secondary} onClick={toggleFilterModal}>
          <div className="row justify-between">
            <div className="text__button__medium__primary60">
              {t('bills.buttons.filter')}
            </div>
            <Filter className={styles.filterIcon} />
          </div>
        </Button>

        {state.appliedFilters.length > 0
            && (
              <FiltersApplied
                filters={state.appliedFilters}
                clearFn={clearFilters}
                removeOneFilterFn={removeFilter}
                prefix="rentalsDashboard"
              />
            )}

        <div className={styles.dateFilter}>
          <FilterByDate
            dateFilter={(params.toString()) ? state.dateFilter : initDateFilter}
            onClickOption={applyDate}
            options={dateOptions}
          />
        </div>
        {showCreateRental && (
        <Button
          className={styles.propertyButton}
          buttonStyle={ButtonStyle.Primary}
          onClick={goToCreateRentals}
        >
          <div className="row align-justify-center">
            <PlusCircle className={styles.plusIcon} />
            {t('rentalsDashboard.extendLease')}
          </div>
        </Button>
        )}
      </div>
      {getTable()}
    </div>
  );
};

export { CommonDashboard };
