class ClaimUnit {
  id: number;

  unitNumber: string;

  status: string;

  constructor(params: SerializedClaimUnit | null) {
    this.id = params?.id || -1;
    this.unitNumber = params?.unitNumber || '';
    this.status = params?.status || '';
  }
}

export { ClaimUnit };
