import { Services } from 'models/services';

class ServicesSerializer {
  static deSerialize(data: RawServices): SerializedServices {
    return {
      other: data.other,
      furnished: data.furnished,
      acceptsPets: data.accepts_pets,
      parkingSlot: data.parking_slot,
      electricity: data.electricity,
      stove: data.stove,
      heating: data.heating,
      refrigerator: data.refrigerator,
      water: data.water,
    };
  }

  static serialize(data: Services): RawServices {
    return {
      other: data.other,
      furnished: data.furnished,
      accepts_pets: data.acceptsPets,
      parking_slot: data.parkingSlot,
      electricity: data.electricity,
      stove: data.stove,
      heating: data.heating,
      refrigerator: data.refrigerator,
      water: data.water,
    };
  }
}

export { ServicesSerializer };
