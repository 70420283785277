import { Input, InputStyle } from '@mapix/common/src/common/input';
import { ChangeEvent, SetStateAction } from 'react';
import { Select } from '@mapix/common/src/common/select';
import { camelCaseToStringWithSpaces } from 'helpers/utils';
import { classnames, getDisplayFileSize, HTMLValidationError } from '@mapix/common/src/helpers/utils';
import { Condition, Currency, UnitSizes } from 'common/enums';
import { Checkbox } from '@mapix/common/src/common/checkbox';
import { Services } from 'models/services';
import { FileUpload } from 'common/image-upload';
import { useTranslation } from 'react-i18next';
import { Lease } from 'models/lease';
import { Tooltip } from '@mapix/common/src/common/tooltip';
import { ReactComponent as Info } from 'assets/icons/info.svg';
import { ReactComponent as AlertFilled } from 'assets/icons/alert-circle-filled.svg';
import { constants } from 'config/constants';
import { InputDate } from '@mapix/common/src/common/input-date';
import dayjs from 'dayjs';
import { PhoneInput } from '@mapix/common/src/common/phone-input';

import { UserController } from 'networking/controllers/user-controller';
import styles from './create-property-building.module.scss';

type LeaseFormProps = {
  lease: Lease,
  onChangeLeaseFn: (e: ChangeEvent<HTMLInputElement>) => void,
  onChangeTenantFn?: (e: ChangeEvent<HTMLInputElement>) => void,
  onChangePhoneNumber: (phoneNumber: string, countryCode: string) => void
  onClickServiceFn: (service: string) => void,
  onChangeFilesFn: (field: string, files: FileType[]) => void,
  onClickSelectFn: (field: string, value: string | number) => void,
  onChangeOtherText: (e: ChangeEvent<HTMLInputElement>) => void,
  onChangeYearlyMonthly: (e: ChangeEvent<HTMLInputElement>) => void,
  onChangeDateFn: (value: string, option: string) => void,
  setPhoneErrors: (value: SetStateAction<HTMLValidationError>) => void,
  formErrors: HTMLValidationError
  otherText: string,
  isCondo?: boolean,
  propertyAvailable?: boolean,
  disableEdit?: boolean,
};

const translPrefix = 'units';
const leaseTranslPrefix = 'units.lease';
const serviceTranslPrefix = 'services';

const {
  imageMaxSize, pdfMaxSize, MAX_PHOTO_AMOUNT,
} = constants;

const LeaseForm = ({
  lease, onChangeLeaseFn, onChangeTenantFn, onChangeFilesFn, formErrors, onClickServiceFn,
  onClickSelectFn, onChangeOtherText, otherText, onChangeYearlyMonthly, isCondo = false,
  onChangeDateFn, onChangePhoneNumber, propertyAvailable = false, setPhoneErrors,
  disableEdit = false,
}: LeaseFormProps) => {
  const { t } = useTranslation();

  const disabledCondition = !propertyAvailable && lease.status !== Condition.Draft;

  return (
    <div className={styles.formContainer}>
      <div className={styles.row}>
        <InputDate
          containerClass={styles.doubleSpecInput}
          id="startDate"
          name="startDate"
          label={t(`${leaseTranslPrefix}.startDate`)}
          placeholder={t(`${leaseTranslPrefix}.startDate`)}
          value={lease.startDate !== '' ? dayjs(lease.startDate).toDate() : undefined}
          formErrors={formErrors}
          onChangeFn={(date: Date) => onChangeDateFn(dayjs(date).format('YYYY-MM-DD'), 'startDate')}
          max={lease.expirationDate ? dayjs(lease.expirationDate).toDate() : undefined}
          disabled={disabledCondition}
          helperText="DD/MM/YYYY"
          required
          t={t}
        />
        <InputDate
          containerClass={styles.doubleSpecInput}
          id="expirationDate"
          name="expirationDate"
          label={t(`${leaseTranslPrefix}.endDate`)}
          placeholder={t(`${leaseTranslPrefix}.endDate`)}
          value={lease.expirationDate !== '' ? dayjs(lease.expirationDate).toDate() : undefined}
          formErrors={formErrors}
          onChangeFn={(date: Date) => onChangeDateFn(dayjs(date).format('YYYY-MM-DD'), 'expirationDate')}
          min={lease.startDate ? dayjs(lease.startDate).toDate() : undefined}
          disabled={disabledCondition}
          helperText="DD/MM/YYYY"
          required
          t={t}
        />
        <Tooltip
          textComponent={<Info className={styles.infoIcon} />}
          hoverComponent={(
            <div className="text__body__small__surface10">
              {t(`${leaseTranslPrefix}.tooltipDueDate${isCondo ? 'Condo' : ''}`)}
            </div>
                )}
          containerWidth="483px"
        />
      </div>

      <div className={styles.row}>
        <Select
          options={Object.values(Currency)}
          onClickOption={(option) => onClickSelectFn('currency', option)}
          optionTextClass={classnames(styles.options, 'text__body__small__textNeutral50')}
          required
          id="currency"
          label={t(`${leaseTranslPrefix}.currency`)}
          placeholder={t(`${leaseTranslPrefix}.currency`)}
          value={lease.currency}
          inputStyle={InputStyle.FORM}
          containerClass={classnames(styles.input, styles.select, styles.small)}
          formError={formErrors}
          disabled={disabledCondition}
          t={t}
        />

        <Input
          required
          id="monthlyRental"
          name="monthlyRental"
          label={isCondo ? t(`${leaseTranslPrefix}.condoMonthlyLabel`) : t(`${leaseTranslPrefix}.monthlyRental`)}
          placeholder={isCondo ? t(`${leaseTranslPrefix}.condoMonthlyLabel`) : t(`${leaseTranslPrefix}.monthlyRental`)}
          value={lease.monthlyRental || Math.floor(lease.yearlyRental / 12) || ''}
          onChange={onChangeYearlyMonthly}
          inputStyle={InputStyle.FORM}
          containerClass={styles.input}
          formError={formErrors}
          disabled={!!lease.yearlyRental || (!propertyAvailable
            && !(lease.status === Condition.Draft))}
          t={t}
        />

        {isCondo
          ? (
            <Input
              required
              id="fees"
              name="fees"
              label={t(`${leaseTranslPrefix}.feeLabel`)}
              placeholder={t(`${leaseTranslPrefix}.feeLabel`)}
              value={lease.fees || ''}
              onChange={onChangeLeaseFn}
              inputStyle={InputStyle.FORM}
              containerClass={styles.input}
              formError={formErrors}
              disabled={disabledCondition}
              t={t}
            />
          )
          : (
            <>
              <Input
                required
                id="yearlyRental"
                name="yearlyRental"
                label={t(`${leaseTranslPrefix}.yearlyRental`)}
                placeholder={t(`${leaseTranslPrefix}.yearlyRental`)}
                value={lease.monthlyRental * 12 || lease.yearlyRental || ''}
                type="number"
                onChange={onChangeYearlyMonthly}
                inputStyle={InputStyle.FORM}
                containerClass={styles.input}
                disabled={!!lease.monthlyRental || (!propertyAvailable
                  && !(lease.status === Condition.Draft))}
                formError={formErrors}
                t={t}
              />
              { !disabledCondition && (
              <Tooltip
                textComponent={<Info className={styles.infoIcon} />}
                hoverComponent={(
                  <div className="text__body__small__surface10">
                    {t(`${leaseTranslPrefix}.tooltipRental`)}
                  </div>
                )}
              />
              )}
            </>
          )}
      </div>

      <div className={styles.row}>
        <Select
          options={Object.values(UnitSizes)}
          onClickOption={(option) => onClickSelectFn('size', option)}
          optionTextClass={classnames(styles.options, 'text__body__small__textNeutral50')}
          required
          id="size"
          label={t(`${leaseTranslPrefix}.unitSize`)}
          placeholder={t(`${leaseTranslPrefix}.unitSize`)}
          value={lease.size}
          containerClass={classnames(styles.doubleSpecInput, styles.select)}
          formError={formErrors}
          disabled={disabledCondition}
          t={t}
        />
      </div>

      <div className="text__body__medium__textNeutral40">{t(`${leaseTranslPrefix}.serviceLabel`)}</div>

      <div className={styles.checkboxContainer}>
        {/* Uses Service class keys to quickly toggle that value */}
        {Object.keys(lease.services).filter((item) => item !== 'other').map((service) => (
          <Checkbox
            key={service}
            onChangeFn={() => onClickServiceFn(service as keyof Services)}
            checked={!!lease.services[service as keyof Services]}
            containerClass={styles.checkbox}
            labelClass="text__body__medium__textNeutral40"
            labelContent={t(`${serviceTranslPrefix}.${camelCaseToStringWithSpaces(service)}`)}
            disabled={disabledCondition}
          />
        ))}
        <div className="row">
          <Checkbox
            onChangeFn={() => onClickServiceFn('other')}
            checked={!!lease.services.other}
            containerClass={styles.checkbox}
            labelClass="text__body__medium__textNeutral40"
            labelContent={t(`${serviceTranslPrefix}.other`)}
            disabled={disabledCondition}
          />
          {lease.services.other && (
            <Input
              required
              id="other"
              helperText={t(`${serviceTranslPrefix}.otherText`)}
              value={otherText}
              type="text"
              onChange={onChangeOtherText}
              inputStyle={InputStyle.FORM}
              containerClass={styles.input}
              formError={formErrors}
              disabled={disabledCondition}
              t={t}
            />
          )}
        </div>
      </div>

      <div className="text__heading6__textNeutral50">{t(`${leaseTranslPrefix}.tenantTitle`)}</div>
      {disableEdit && (
        <div className={classnames(styles.leaseSubtitle, 'text__body__medium__textNeutral50')}>
          <AlertFilled className={styles.alertIcon} />
          {t(`${leaseTranslPrefix}.tenantSubtitle`)}
        </div>
      )}
      <div className={styles.row}>
        <Input
          required
          id="name"
          name="name"
          label={t(`${leaseTranslPrefix}.tenantName`)}
          placeholder={t(`${leaseTranslPrefix}.tenantName`)}
          value={lease.tenant.name}
          type="text"
          onChange={onChangeTenantFn}
          inputStyle={InputStyle.FORM}
          containerClass={styles.input}
          formError={formErrors}
          disabled={disableEdit}
          t={t}
        />

        <Input
          required
          id="lastname"
          name="lastname"
          label={t(`${leaseTranslPrefix}.tenantLastname`)}
          placeholder={t(`${leaseTranslPrefix}.tenantLastname`)}
          value={lease.tenant.lastname}
          type="text"
          onChange={onChangeTenantFn}
          inputStyle={InputStyle.FORM}
          containerClass={styles.input}
          formError={formErrors}
          disabled={disableEdit}
          t={t}
        />

        <PhoneInput
          required
          id="phoneNumber"
          label={t(`${leaseTranslPrefix}.tenantPhone`)}
          helperText={t(`${leaseTranslPrefix}.tenantPhoneHelper`)}
          countryCode={lease.tenant.countryCode}
          phoneWithoutCode={lease.tenant.phoneNumber}
          onChangePhoneNumber={onChangePhoneNumber}
          containerClass={styles.input}
          formError={formErrors}
          disabled={disableEdit}
          setPhoneErrors={setPhoneErrors}
          t={t}
          validatePhone={UserController.phoneValidation}

        />

        <Input
          required
          id="email"
          name="email"
          label={t(`${leaseTranslPrefix}.tenantEmail`)}
          placeholder={t(`${leaseTranslPrefix}.tenantEmail`)}
          helperText={t(`${leaseTranslPrefix}.tenantEmailHelper`)}
          value={lease.tenant.email}
          type="email"
          onChange={onChangeTenantFn}
          inputStyle={InputStyle.FORM}
          containerClass={styles.input}
          formError={formErrors}
          disabled={disableEdit}
          t={t}
        />

      </div>
      <div className="text__heading6__textNeutral50">{t(`${translPrefix}.filesTitle`)}</div>

      <FileUpload
        fetchedFiles={lease.leaseCopy ? [lease.leaseCopy] : null}
        fileAddedFn={((files: FileType[]) => onChangeFilesFn('pdf', files))}
        title={t(`${translPrefix}.leaseFile`)}
        restrictionText={`${t(`${translPrefix}.leaseLimit`, { size: getDisplayFileSize(pdfMaxSize) })}`}
        isPDF
        disabled={disabledCondition}
      />

      <FileUpload
        fetchedFiles={lease.unitPhotos ? lease.unitPhotos : []}
        fileAddedFn={((files: FileType[]) => { onChangeFilesFn('unitPhotos', files); })}
        title={t(`${translPrefix}.photosFile`)}
        restrictionText={`${t(`${translPrefix}.photosLimit`, { size: getDisplayFileSize(imageMaxSize), amount: MAX_PHOTO_AMOUNT })}`}
        disabled={(disabledCondition)}
      />

      <FileUpload
        fetchedFiles={lease.tenantIdPhotos ? lease.tenantIdPhotos : []}
        fileAddedFn={((files: FileType[]) => onChangeFilesFn('tenantIdPhotos', files))}
        title={t(`${translPrefix}.tenantPhotos`)}
        restrictionText={`${t(`${translPrefix}.photosLimit`, { size: getDisplayFileSize(imageMaxSize), amount: MAX_PHOTO_AMOUNT })}`}
        disabled={(disabledCondition)}
      />

    </div>
  );
};

export { LeaseForm };
