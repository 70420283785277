import {
  useState, ChangeEvent, SyntheticEvent, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import { Button, ButtonStyle, ButtonType } from '@mapix/common/src/common/button';
import { logger } from 'helpers/logger';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { appActions, AppContext } from 'context';
import { Spinner } from '@mapix/common/src/common/spinner';
import { classnames, isPasswordValid } from '@mapix/common/src/helpers/utils';
import { goToPage, RouteName } from 'routes';
import { UserController } from 'networking/controllers/user-controller';
import { useHistory } from 'react-router-dom';
import { SessionController } from 'networking/controllers/session-controller';
import { Users } from 'common/enums';
import styles from './activate-account.module.scss';

type FormType = {
  [key: string]: string,
  password: string,
  repeatPassword: string,
};
const ActivateAccount = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AppContext);
  const translPrefix = 'activateAccount';
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get('email');
  const tenantInvitationToken = urlParams.get('tenant_invitation_token');
  const [fetching, setFetching] = useState(false);
  const [errorServer, setErrorServer] = useState(false);
  const [formNumber, setFormNumber] = useState(1);
  const [formData, setFormData] = useState<FormType>({
    password: '',
    repeatPassword: '',
  });
  const [errorMessage, setErrorMessage] = useState<FormType>({
    password: '',
    repeatPassword: '',
  });

  const handleLogin = async (event: any) => {
    event.preventDefault();
    const userSend: User = {
      email: email || '',
      password: formData.password,
    };
    try {
      setFetching(true);
      const userResult = await SessionController.login(userSend);
      dispatch({ type: appActions.USER_LOGGED, user: userResult });
      setFetching(false);
      if (state.redirectWhenLogged !== '') {
        const route = Object.entries(RouteName)
          .find(([, val]) => val === state.redirectWhenLogged)?.[1];
        dispatch({ type: appActions.REMOVE_POST_AUTH_REDIRECT });
        if (route) {
          goToPage(route);
        } else if (userResult.type === Users.Tenant) {
          goToPage(RouteName.HomeTenant);
        } else {
          goToPage(RouteName.Properties);
        }
      } else if (userResult.type === Users.Tenant) {
        goToPage(RouteName.HomeTenant);
      } else {
        goToPage(RouteName.Properties);
      }
    } catch (ex: any) {
      setFetching(false);
      setErrorServer(true);
    }
  };

  const activateAccount = async (event: SyntheticEvent) => {
    event.preventDefault();
    const errorData = {
      password: '',
      repeatPassword: '',
    };
    if (formData.password === '') {
      errorData.password = (t('login.emptyField'));
    } else if (!isPasswordValid(formData.password)) {
      errorData.password = (t('registerForm.helperPassword'));
    }
    if (formData.repeatPassword === '') {
      errorData.repeatPassword = (t('login.emptyField'));
    } else if (formData.password !== formData.repeatPassword) {
      errorData.repeatPassword = (t('registerForm.passwordMatch'));
    }
    if (!errorData.password && !errorData.repeatPassword) {
      try {
        setFetching(true);
        const data: ActivateAccount = {
          email: email || '',
          newPassword: formData.password,
          tenantInvitationToken: tenantInvitationToken || '',
        };
        UserController.activateAccount(data);
        setFetching(false);
        setFormNumber(2);
      } catch (e) {
        setErrorServer(true);
        setFetching(false);
        logger.error(e as Error);
      }
    }
    setErrorMessage(errorData);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, id } = event.target;
    const newData = { ...formData };
    const errorData = { ...errorMessage };
    newData[id] = value;
    errorData[id] = '';
    setErrorMessage(errorData);
    setFormData(newData);
  };

  if (fetching) {
    return (
      <div className={styles.spinner}><Spinner /></div>
    );
  }

  return (
    <div className={styles.container}>
      {errorServer
          && <ErrorMessage message={t('error.errorMessage')} handleClose={() => setErrorServer(false)} />}
      { formNumber === 1
        ? (
          <form onSubmit={activateAccount}>
            <div className={styles.content}>
              <p className="text__heading5__textNeutral50">{t(`${translPrefix}.title`)}</p>
              <p className={classnames(styles.subtitle, 'text__body__medium__textNeutral50')}>{t(`${translPrefix}.subtitle`)}</p>
              <Input
                id="password"
                containerClass={styles.password}
                label={t('password.password')}
                placeholder={t('password.password')}
                inputStyle={InputStyle.FORM}
                type="password"
                value={formData.password}
                onChange={handleChange}
                error={!!errorMessage.password}
                helperText={errorMessage.password || t('password.helper')}
                t={t}
                required
              />
              <Input
                id="repeatPassword"
                containerClass={styles.input}
                label={t('password.repeatPassword')}
                placeholder={t('password.repeatPassword')}
                inputStyle={InputStyle.FORM}
                type="password"
                value={formData.repeatPassword}
                onChange={handleChange}
                error={!!errorMessage.repeatPassword}
                helperText={errorMessage.repeatPassword}
                t={t}
                required
              />
              <Button
                className={styles.button}
                buttonStyle={ButtonStyle.PrimaryGradient}
                buttonType={ButtonType.Submit}
              >
                {t(`${translPrefix}.create`)}
              </Button>
              <a
                className={classnames(styles.buttonLink, 'text__body__medium__primary')}
                href="##"
                onClick={() => history.goBack()}
              >
                {t(`${translPrefix}.cancel`)}
              </a>
            </div>
          </form>
        )
        : (
          <div className={styles.content}>
            <p className="text__body__medium__textNeutral50">
              {t(`${translPrefix}.success`)}
            </p>
            <a
              className={classnames(styles.buttonLink, 'text__body__medium__primary')}
              href="##"
              onClick={handleLogin}
            >
              {t(`${translPrefix}.goToHomePage`)}
            </a>
          </div>
        )}
    </div>
  );
};

export { ActivateAccount };
