import React, {
  ChangeEvent, Fragment, useEffect, useState,
} from 'react';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import styles from 'common/image-upload/file-upload.module.scss';
import { addOrRemoveFromArrayObject, classnames } from '@mapix/common/src/helpers/utils';
import { ReactComponent as Alert } from 'assets/icons/alertFilled.svg';
import { ResourcesController } from 'networking/controllers/resources-controller';
import { UploadItem } from 'common/image-upload/upload-item';
import { useTranslation } from 'react-i18next';
import { FileModel } from 'models/file-model';
import { ImageOverlay } from '@mapix/common/src/common/image-overlay';
import { ImageOverlayObject } from '@mapix/common/src/common/image-overlay/image-overlay';
import { constants } from 'config/constants';

type ImageUploadProps = {
  title: string,
  titleClass?: string
  restrictionText: string,
  isPDF?: boolean
  fileAddedFn: (files: FileType[]) => void,
  fetchedFiles: FileType[] | null,
  disabled?: boolean,
};

const initOverlayState: ImageOverlayObject = {
  show: false,
  index: 0,
  photos: [],
};

enum UploadError {
  Server,
  FileSize,
  FileLimit,
  NoError,
}

const {
  imageMaxSize, pdfMaxSize, MAX_PHOTO_AMOUNT,
} = constants;

const translPrefix = 'createGeneric';
const FileUpload = ({
  title, titleClass = '', restrictionText, isPDF = false, fileAddedFn, fetchedFiles, disabled = false,
}: ImageUploadProps) => {
  const [files, setFiles] = useState<SerializedFile[]>([]);
  const [error, setError] = useState({
    errorName: UploadError.NoError,
    filename: '',
  });
  const [pendingFiles, setPendingFiles] = useState<SerializedFile[]>([]);
  const [overlayState, setOverlayState] = useState<ImageOverlayObject>(initOverlayState);

  const { t } = useTranslation();

  const showOverlay = (index: number, photos: FileType[]) => {
    setOverlayState({ index, photos, show: true });
  };

  const hideOverlay = () => {
    setOverlayState((prevState) => ({ ...prevState, show: false }));
  };

  const convertToFileUpload = (editFiles: FileType[]) => editFiles.map((file) => {
    const converted: SerializedFile = {
      filename: isPDF ? 'Uploaded lease copy' : '',
      loading: false,
      url: file.url,
    };
    return converted;
  });

  const convertToResourceLink = () => {
    fileAddedFn(files.map((item) => {
      const resourceItem: FileType = {
        filename: item.filename,
        url: item.url,
      };
      return resourceItem;
    }));
  };

  useEffect(() => {
    if (fetchedFiles && fetchedFiles.length > 0) {
      setFiles(convertToFileUpload(fetchedFiles));
    }
  }, []);

  const onFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file: SerializedFile = {
        filename: e.target.files[0].name,
        loading: true,
        resource: e.target.files[0],
        url: '',
      };
      if ((file.resource && file.resource?.size > imageMaxSize && !isPDF)
      || (file.resource && file.resource?.size > pdfMaxSize && isPDF)) {
        setError({ errorName: UploadError.FileSize, filename: file.filename });
      } else if (fetchedFiles && !isPDF && (fetchedFiles?.length >= MAX_PHOTO_AMOUNT)) {
        setError({ errorName: UploadError.FileLimit, filename: '' });
      } else {
        setError({ errorName: UploadError.NoError, filename: '' });

        setPendingFiles((prevState) => [...prevState, file]);
        try {
          const result = await ResourcesController.uploadFile(new FileModel(file));
          file.loading = false;
          file.url = result;
          setFiles((prevState) => [...prevState, file]);
        } catch (ex) {
          setError({ errorName: UploadError.Server, filename: file.filename });
        }
      }
    }
  };

  /* Needed to update after files useState (since useState doesn't have a callback to call after
  being used) */
  useEffect(() => {
    if (pendingFiles.length > 0) {
      const updatePending = [...pendingFiles];
      updatePending.pop();
      setPendingFiles(updatePending);
    }
  }, [files]);

  useEffect(() => {
    convertToResourceLink();
  }, [files]);

  const removeFile = (item: SerializedFile) => {
    const newFiles = addOrRemoveFromArrayObject(files, item, 'url');
    setFiles(newFiles);
    fileAddedFn(newFiles);
  };

  const getErrorMessage = (errorType: UploadError) => {
    switch (errorType) {
      case UploadError.FileSize:
        return t('error.fileSize', {
          fileName: error.filename,
          maxSize: (isPDF ? pdfMaxSize : imageMaxSize) / 1000000,
        });
      case UploadError.FileLimit:
        return t('error.fileLimit', { maxFiles: MAX_PHOTO_AMOUNT });
      default:
        return t('error.fileServer');
    }
  };

  const disableUpload = disabled || (!!fetchedFiles && fetchedFiles.length >= MAX_PHOTO_AMOUNT);

  return (
    <div className="column">

      {overlayState.show && (
        <ImageOverlay
          photos={overlayState.photos}
          selectedIndex={overlayState.index}
          closeFn={hideOverlay}
          t={t}
        />
      )}

      <div className={titleClass || 'text__body__medium__textNeutral50'}>{title}</div>
      <div className="text__body__small__textNeutral40">
        *
        {restrictionText}
      </div>
      <label
        htmlFor={title}
        className={
          disableUpload
            ? classnames(styles.inputLabel, 'row align-justify-center text__body__medium__textNeutral20')
            : classnames(styles.inputLabel, 'row align-justify-center text__body__medium__primary40')
          }
      >
        {isPDF ? <>{t(`${translPrefix}.selectPdf`)}</>
          : <>{t(`${translPrefix}.selectPhoto`)}</>}
        <ArrowUp className={disableUpload ? styles.iconDisabled : styles.icon} />
        <input
          accept={isPDF ? 'application/pdf' : 'image/jpeg, image/png'}
          onChange={onFileUpload}
          id={title}
          className={styles.input}
          type="file"
          disabled={disableUpload}
        />
      </label>
      {
        (error.errorName !== UploadError.NoError) && (

        <div className={styles.row}>
          <p className={classnames('text__body__small__textNeutral40', styles.bold)}>
            {getErrorMessage(error.errorName)}
          </p>
          <Alert className={styles.alertIcon} />
        </div>

        )
      }
      <div className={styles.photoRow}>

        {files.map((file, index) => (
          <Fragment key={file.url + file.filename}>
            <UploadItem
              onClickFn={() => showOverlay(index, files)}
              removeFn={() => removeFile(file)}
              isPdf={isPDF}
              loading={file.loading}
              filename={file.filename}
              url={file.url}
              crossIcon={!disabled}
              t={t}
            />
          </Fragment>
        ))}
        {pendingFiles.map((file) => (
          <div key={file.filename}>
            <UploadItem
              removeFn={() => removeFile(file)}
              isPdf={isPDF}
              loading={file.loading}
              filename={file.filename}
              crossIcon={!disabled}
              t={t}
            />
          </div>
        ))}
      </div>

    </div>
  );
};

export { FileUpload };
