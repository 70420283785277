import { ReactComponent as Cross } from '@mapix/common/src/assets/icons/cross.svg';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { Modal } from '@mapix/common/src/common/modal';
import { addOrRemoveFromStateArrayString, classnames } from '@mapix/common/src/helpers/utils';
import { CheckboxFilters } from 'common/checkbox-filters';
import { useState } from 'react';
import styles from './modal-with-filters.module.scss';

type ModalWithFiltersProps = {
  title: string,
  onClose: () => void,
  checkboxesOptions: string[],
  checkboxesSelected: string[],
  textButtonLeft: string,
  onClickButtonLeft: () => void,
  textButtonRight: string,
  onClickButtonRight: (filters: string[]) => void,
};

const ModalWithFilters = ({
  title, onClose, checkboxesOptions, checkboxesSelected,
  textButtonLeft, onClickButtonLeft, textButtonRight, onClickButtonRight,
}: ModalWithFiltersProps) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>(checkboxesSelected);

  const onClickCheckbox = (checkbox: string) => {
    addOrRemoveFromStateArrayString(checkbox, setSelectedOptions);
  };

  return (
    <Modal>
      <div className={styles.modal}>
        <div className={styles.titleAndCrossContainer}>
          <p className="text__heading5__textNeutral50">
            {title}
          </p>
          <button type="button" onClick={onClose}>
            <Cross className={styles.crossIcon} />
          </button>
        </div>
        <CheckboxFilters
          checkboxesOptions={checkboxesOptions}
          checkboxesSelected={selectedOptions}
          onClickCheckbox={onClickCheckbox}
        />
        <div className={styles.buttonContainer}>
          <Button
            buttonStyle={ButtonStyle.Secondary}
            className={classnames('text__body__large__secondary80')}
            onClick={onClickButtonLeft}
          >
            {textButtonLeft}
          </Button>
          <Button
            buttonStyle={ButtonStyle.Primary}
            className={classnames('text__body__large__secondary80')}
            onClick={() => onClickButtonRight(selectedOptions)}
          >
            {textButtonRight}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export { ModalWithFilters };
