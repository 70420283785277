import { Lease } from 'models/lease';
import { Contractor } from 'models/contractor';
import { getFullAddress } from 'helpers/utils';
import { PropertyAddress } from './property-address';
import { PropertyOwner } from './property-owner';

class Property {
  id: number;

  type: string;

  condition: string;

  address: PropertyAddress;

  owner: PropertyOwner;

  otherOwner?: PropertyOwner;

  buildingName: string;

  unitsCount: number;

  lease?: Lease;

  status?: string;

  contractors: Contractor[];

  fullAddress: string;

  occupancyRate: number;

  rentedUnitsCount: number;

  newTasksCount: number;

  inProgressTasksCount: number;

  overdueRentals: number;

  constructor(params: SerializedProperty | null | undefined) {
    this.id = params?.id || 0;
    this.type = params?.type || '';
    this.condition = params?.condition || '';
    this.buildingName = params?.buildingName || '';
    this.unitsCount = params?.unitsCount || 0;
    this.address = new PropertyAddress(params?.address);
    this.owner = new PropertyOwner(params?.owner);
    this.otherOwner = new PropertyOwner(params?.otherOwner);
    this.lease = params?.lease ? new Lease(params.lease) : undefined;
    this.status = params?.status;
    this.contractors = params?.contractors
      ? params.contractors.map((contractor) => new Contractor(contractor))
      : [];
    this.fullAddress = params?.address ? getFullAddress(params?.address) : '';
    this.occupancyRate = params?.occupancyRate ? Math.round(params.occupancyRate) : 0;
    this.rentedUnitsCount = params?.rentedUnitsCount || 0;
    this.newTasksCount = params?.newTasksCount || 0;
    this.inProgressTasksCount = params?.inProgressTasksCount || 0;
    this.overdueRentals = params?.overdueRentalsCount || 0;
  }
}

export { Property };
