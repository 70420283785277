// @ts-nocheck
import { useTranslation } from 'react-i18next';
import { Modal } from '@mapix/common/src/common/modal';
import { ReactComponent as BillPayment } from 'assets/images/bill-payment.svg';
import { InputDate } from '@mapix/common/src/common/input-date';
import dayjs from 'dayjs';
import { FormEvent, useState } from 'react';
import { checkHTMLErrors, classnames, HTMLValidationError } from '@mapix/common/src/helpers/utils';
import { Button, ButtonStyle, ButtonType } from '@mapix/common/src/common/button';
import { logger } from 'helpers/logger';
import { ModalResult } from 'common/modal-result';
import { ReactComponent as Dollar } from 'assets/icons/dollar.svg';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { RentalsController } from 'networking/controllers/rentals-controller';
import styles from './register-rental-payment.module.scss';

type RegisterRentalPaymentProps = {
  closeFn: () => void,
  rentalId: number,
  paymentFn?: (date: string) => void,
};

type RegisterRentalPaymentState = {
  successModal: boolean,
  formErrors: HTMLValidationError,
  paymentDate?: string,
  error: boolean
};

const initState: RegisterRentalPaymentState = {
  successModal: false,
  formErrors: {},
  error: false,
};

const translPrefix = 'registerRentalPayment';
const RegisterRentalPayment = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  closeFn, rentalId, paymentFn,
}: RegisterRentalPaymentProps) => {
  const [state, setState] = useState<RegisterRentalPaymentState>(initState);
  const { t } = useTranslation();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const target = e.target as HTMLFormElement;
    if (!target.checkValidity()) {
      setState((prevState) => ({ ...prevState, formErrors: checkHTMLErrors(target) }));
    } else if (paymentFn) {
      paymentFn(state.paymentDate);
      closeFn();
    } else {
      try {
        setState((prevState) => ({ ...prevState, successModal: true }));
        const serializedPatch: SerializedPatchRental = {
          paymentDate: state.paymentDate,
        };
        await RentalsController.editRental(rentalId, serializedPatch);
      } catch (err: any) {
        logger.error(err);
        setState((prevState) => ({ ...prevState, error: true }));
      }
    }
  };

  const changeDate = (date: Date) => {
    setState((prevState) => (
      { ...prevState, paymentDate: dayjs(date).format('YYYY-MM-DD') }));
  };

  return (
    <>
      {state.successModal ? (
        <ModalResult
          title={t(`${translPrefix}.successTitle`)}
          subtitle={t(`${translPrefix}.successSubtitle`)}
          Icon={Dollar}
          withCheckIcon
          buttonTextRight={t(`${translPrefix}.successOk`)}
          handleButtonRight={closeFn}
          handleButtonClose={closeFn}
        />
      )
        : (
          <Modal>
            <div className={styles.paymentContainer}>
              <button className={styles.closeButton} type="button" onClick={closeFn}>
                <Cross className={styles.closeIcon} />
              </button>

              <BillPayment className={styles.billImage} />

              <div className="text__heading5__textNeutral50">{t(`${translPrefix}.title`)}</div>

              <form className={styles.formContainer} noValidate onSubmit={onSubmit}>
                <div className={classnames(styles.formTitle, 'text__heading6__texNeutral50')}>
                  {t(`${translPrefix}.paymentDateTitle`)}
                </div>

                <InputDate
                  id="paymentDate"
                  name="paymentDate"
                  label={t(`${translPrefix}.paymentDatePlaceholder`)}
                  placeholder={t(`${translPrefix}.paymentDatePlaceholder`)}
                  onChangeFn={changeDate}
                  formErrors={state.formErrors}
                  required
                  value={state.paymentDate ? dayjs(state.paymentDate).toDate() : undefined}
                  helperText={t(`${translPrefix}.paymentDateHelper`)}
                  containerClass={styles.input}
                />

                <div className={styles.buttonRow}>
                  <Button
                    onClick={closeFn}
                    className={styles.largeButton}
                    buttonStyle={ButtonStyle.Secondary}
                  >
                    {t(`${translPrefix}.cancel`)}
                  </Button>
                  <Button
                    className={styles.largeButton}
                    buttonType={ButtonType.Submit}
                    buttonStyle={ButtonStyle.Primary}
                  >
                    {t(`${translPrefix}.confirm`)}
                  </Button>
                </div>

                {state.error && (
                <div className="text__body__medium__danger50">{t(`${translPrefix}.error`)}</div>
                )}

              </form>

            </div>
          </Modal>
        )}
    </>
  );
};

export { RegisterRentalPayment };
