import { Lease } from 'models/lease';

class UnitOfAProperty {
  id: number;

  unitNumber: string;

  status: string;

  lease?: Lease;

  tenant?: string;

  tenantContact?: string;

  condition: string;

  constructor(params: SerializedUnitOfAProperty) {
    this.id = params.id;
    this.unitNumber = params.unitNumber;
    this.status = params.status;
    this.lease = params?.lease ? new Lease(params.lease) : undefined;
    this.tenant = params.lease ? this.lease?.tenant.name : undefined;
    this.tenantContact = params.lease ? `${this.lease?.tenant.countryCode}${this.lease?.tenant.phoneNumber}` : undefined;
    this.condition = params.condition;
  }
}

export { UnitOfAProperty };
