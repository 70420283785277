import { checkHTMLErrors, HTMLValidationError } from '@mapix/common/src/helpers/utils';
import React, { Dispatch, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { TaskType } from '@mapix/common/src/common/enums';
import { CreateFooter } from 'common/creation';
import { ButtonType } from '@mapix/common/src/common/button';
import { CreateEditNewTaskContainerStep2 } from 'common/create-edit-new-task-container-step-2';
import { Action } from './create-edit-new-task-reducer';

type CreateNewTaskStep2Props = {
  dispatch: Dispatch<Action>,
  createTaskInfo: CreateNewTaskState,
  formErrors: HTMLValidationError,
  isValidUnitNumber: boolean,
  fromPropertyDashboard: boolean,
  isEdit: boolean,
  submitUnpublishedTask: () => void,
  submitPublicTask: () => void,
  editTask: () => void,
};

const CreateNewTaskStep2 = ({
  dispatch, createTaskInfo, isValidUnitNumber, formErrors, fromPropertyDashboard = false, isEdit,
  submitUnpublishedTask, submitPublicTask, editTask,
}: CreateNewTaskStep2Props) => {
  const { t } = useTranslation();

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    dispatch({ type: 'FORM_ERROR', errors: {} });

    if (!target.checkValidity() || !isValidUnitNumber
    || !createTaskInfo.taskType || (!createTaskInfo.area && createTaskInfo.buildingType)) {
      dispatch({
        type: 'FORM_ERROR',
        errors: {
          ...checkHTMLErrors(target),
          unitNumbersInput: isValidUnitNumber ? '' : t('createTask.concernedArea.selectValidUnit'),
          taskType: createTaskInfo.taskType ? '' : t('error.selectOption'),
          area: createTaskInfo.area ? '' : t('error.selectOption'),
        },
      });

      return;
    }
    switch (createTaskInfo.taskType) {
      case TaskType.UnpublishedTask:
        if (!isEdit) {
          submitUnpublishedTask();
          break;
        }
        editTask();
        break;
      case TaskType.PrivateTask:
        dispatch({ type: 'GO_NEXT' });
        break;
      case TaskType.PublicTask:
        if (!isEdit) {
          submitPublicTask();
          break;
        }
        editTask();
        break;
      default:
        break;
    }
  };

  return (
    <form onSubmit={onSubmit} noValidate>
      <CreateEditNewTaskContainerStep2
        fn={dispatch}
        createTaskInfo={createTaskInfo}
        formErrors={formErrors}
      />
      <CreateFooter
        nextButtonType={ButtonType.Submit}
        showBack={!fromPropertyDashboard}
        nextName={t('createGeneric.next')}
        backFn={() => dispatch({ type: 'GO_BACK' })}
      />
    </form>
  );
};

export { CreateNewTaskStep2 };
