import { Paginator } from 'models/paginator';
import { PropertyTableItem } from 'models/property-table-item';
import { ModalFilterItem } from 'common/filter-modal/filter-modal';

/* For the headers, we add an extra empty element to handle the action column */
const headerCondo = ['address', 'buildingName', 'landlord'];

type RentalsData = {
  dataProperties: string[],
};

type TaskState = {
  data: RentalsData,
  paginator: Paginator<PropertyTableItem>,
  query: string,
  input: string,
  filters: ModalFilterItem[]
};

type Action =
  | { type: 'PAGE_CHANGED', newPage: number }
  | { type: 'PROPERTIES_FETCHED', paginator: Paginator<PropertyTableItem> }
  | { type: 'SEARCH_QUERY', query: string }
  | { type: 'INPUT_CHANGED', input: string };

const changePage = (
  currentPage: number,
  paginator: Paginator<PropertyTableItem>,
): Paginator<PropertyTableItem> => {
  const newPaginator = { ...paginator };
  newPaginator.currentPage = currentPage;
  return newPaginator;
};

const initialState = {
  data: {
    dataProperties: headerCondo,
  },
  paginator: new Paginator(null, []),
  query: '',
  input: '',
  filters: [{ name: 'Properties Active', code: 'active', parentType: 'conditions' }],
};

function BuildingTabReducer(state: TaskState, action: Action): TaskState {
  switch (action.type) {
    case 'PROPERTIES_FETCHED':
      return {
        ...state,
        paginator: action.paginator,
      };
    case 'PAGE_CHANGED':
      return {
        ...state,
        paginator: changePage(action.newPage, state.paginator),
      };
    case 'INPUT_CHANGED':
      return {
        ...state,
        input: action.input,
      };
    case 'SEARCH_QUERY':
      return {
        ...state,
        query: action.query,
        paginator: changePage(1, state.paginator),
      };
    default:
      return {
        ...state,
      };
  }
}

export { BuildingTabReducer, initialState };
