import { MaintenanceTableItem } from 'models/maintenance-table-item';
import { Paginator } from '../../../../models/paginator';
import { ModalFilterItem, ModalFilterObject } from '../../../../common/filter-modal/filter-modal';
import { MaintenanceFilters } from '../../../maintenance/maintenance-filters';

type TaskChange = {
  filters: ModalFilterObject[],
};

type SelectedValueFilter = {
  startDate: string,
  endDate: string,
  code: string,
};

type TaskState = {
  data: TaskChange,
  paginator: Paginator<MaintenanceTableItem>,
  appliedFilters: ModalFilterItem[],
  code: string,
  show: boolean,
  input: string,
  query: string,
  filterDate: SelectedValueFilter,
};

type Action =
  | { type: 'PAGE_CHANGED', newPage: number }
  | { type: 'MODAL_VISIBILITY', show: boolean }
  | { type: 'APPLY_FILTERS', filters: ModalFilterItem[] }
  | { type: 'CLEAR_FILTERS' }
  | { type: 'APPLY_FILTER_DATE', filter: SelectedValueFilter }
  | { type: 'TASKS_FETCHED', paginator: Paginator<MaintenanceTableItem> }
  | { type: 'SEARCH_QUERY', query: string }
  | { type: 'INPUT_CHANGED', input: string };

const changePage = (
  currentPage: number,
  paginator: Paginator<MaintenanceTableItem>,
): Paginator<MaintenanceTableItem> => {
  const newPaginator = { ...paginator };
  newPaginator.currentPage = currentPage;
  return newPaginator;
};

const initialState = {
  data: {
    filters: MaintenanceFilters.tasksOfProperty,

  },
  code: 'FETCHING',
  paginator: new Paginator(null, []),
  appliedFilters: [],
  show: false,
  query: '',
  input: '',
  filterDate: {
    startDate: '',
    endDate: '',
    code: 'all',
  },
};

function MaintenanceTaskReducer(state: TaskState, action: Action): TaskState {
  switch (action.type) {
    case 'TASKS_FETCHED':
      return {
        ...state,
        paginator: action.paginator,
        code: 'READY',
      };
    case 'PAGE_CHANGED':
      return {
        ...state,
        paginator: changePage(action.newPage, state.paginator),
        code: 'FETCHING',
      };
    case 'MODAL_VISIBILITY':
      return {
        ...state,
        show: action.show,
      };
    case 'APPLY_FILTERS':
      return {
        ...state,
        show: false,
        appliedFilters: action.filters,
      };
    case 'CLEAR_FILTERS':
      return {
        ...state,
        appliedFilters: [],
        filterDate: {
          startDate: '',
          endDate: '',
          code: 'all',
        },
      };
    case 'APPLY_FILTER_DATE':
      return {
        ...state,
        filterDate: action.filter,
      };
    case 'INPUT_CHANGED':
      return {
        ...state,
        input: action.input,
      };
    case 'SEARCH_QUERY':
      return {
        ...state,
        query: action.query,
        code: 'FETCHING',
      };
    default:
      return {
        ...state,
      };
  }
}

export { MaintenanceTaskReducer, initialState };
