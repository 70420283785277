import { RouteName, goToPage } from 'routes';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, BreadcrumbsItem } from '@mapix/common/src/common/breadcrumbs';
import { isPrivateTaskWaitingConfirmation, parseTaskStatus } from 'helpers/utils';
import { NewTask } from 'networking/types';
import { TaskStatus, TaskType } from '@mapix/common/src/common/enums';
import { MaintenanceTabs } from 'common/enums';
import { Footer, Header, TaskDetailTabs } from './components';
import styles from './new-task-detail.module.scss';

type NewTaskDetailProps = {
  newTask: NewTask,
  currentTab: TaskDetailTabs,
  setCurrentTab: (tab: string) => void,
  getTabContent: () => JSX.Element | null,
  showAlert?: (option: string) => boolean,
  disableLeftButton?: boolean,
  disableRightButton?: boolean,
  withTooltip?: boolean,
  textButtonRight: string,
  textButtonLeft: string,
  translPrefix: string,
  toolTipText?: string,
  leftButtonFn: () => void,
  rightButtonFn?: () => void,
};

const getTabToRedirect = (taskStatus: TaskStatus) => {
  switch (taskStatus) {
    case TaskStatus.NEGOTIATING:
      return MaintenanceTabs.Negotiating;
    case TaskStatus.IN_PROGRESS:
      return MaintenanceTabs.InProgress;
    case TaskStatus.SOLVED:
      return MaintenanceTabs.Solved;
    case TaskStatus.CANCELLED:
    case TaskStatus.WAITING_MANAGER_CANCELLED_PERCENTAGE_APPROVAL:
    case TaskStatus.WAITING_CONTRACTOR_CANCELLED_PERCENTAGE_APPROVAL:
      return MaintenanceTabs.Cancelled;
    default:
      return MaintenanceTabs.Unassigned;
  }
};

const NewTaskDetail = ({
  newTask, currentTab, setCurrentTab, getTabContent, showAlert = () => false,
  disableLeftButton, disableRightButton, withTooltip, textButtonRight, textButtonLeft, toolTipText = '',
  translPrefix, leftButtonFn, rightButtonFn,
}: NewTaskDetailProps) => {
  const breadcrumbs = (
    t: (text: string) => string,
    taskName: string,
    translate: string,
    redirectionTab: MaintenanceTabs,
  ): BreadcrumbsItem[] => [
    {
      name: t(`${translate}.maintenance`),
      goToPageFn: () => goToPage(RouteName.Maintenance, {}, { tab: redirectionTab }),
    },
    {
      name: taskName,
      goToPageFn: () => {},
    },
    {
      name: t(`${translate}.${currentTab}`),
      goToPageFn: () => null,
    },
  ];

  const { t } = useTranslation();

  const taskStatus = parseTaskStatus(newTask.status);

  const showFooter = () => (
    (taskStatus !== TaskStatus.RECEIVING_APPLICATIONS)
    && (taskStatus !== TaskStatus.WAITING_CONTRACTOR_CANCELLED_PERCENTAGE_APPROVAL)
    && (newTask.taskType !== TaskType.UnpublishedTask)
  );

  return (
    <div className={styles.containerWrapper}>
      <Breadcrumbs
        values={breadcrumbs(t, newTask.name, `${translPrefix}.breadcrumbs`, getTabToRedirect(taskStatus as TaskStatus))}
      />

      <div className={styles.container}>
        <Header
          newTask={newTask}
          translPrefix={translPrefix}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          taskStatus={taskStatus}
          showAlert={showAlert}
        />

        {getTabContent()}

        {showFooter() && (
          <Footer
            translPrefix={translPrefix}
            taskStatus={taskStatus}
            disableLeftButton={disableLeftButton}
            disableRightButton={disableRightButton}
            textButtonLeft={textButtonLeft}
            textButtonRight={textButtonRight}
            withTooltip={withTooltip}
            toolTipText={toolTipText}
            isPrivateTaskWaitingConfirmation={
              isPrivateTaskWaitingConfirmation(newTask.taskType, newTask.status)
          }
            leftButtonFn={leftButtonFn}
            rightButtonFn={rightButtonFn}
          />
        )}
      </div>
    </div>

  );
};

export { NewTaskDetail };
