import { ButtonClose } from '@mapix/common/src/common/button-close';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import { RadioButtonMobile } from '@mapix/common/src/common/radio-button-mobile';
import { TextArea } from '@mapix/common/src/common/textarea';
import { HTMLValidationError, classnames } from '@mapix/common/src/helpers/utils';
import { useMediaQuery } from '@mapix/common/src/hooks/use-media-query';
import { Specialization } from '@mapix/common/src/types';
import { Breakpoints, BuildingArea } from 'common/enums';
import { FileUpload } from 'common/image-upload';
import { SelectCategory } from 'common/select-category';
import { constants } from 'config/constants';
import { AppContext } from 'context';
import { CreateClaimData } from 'networking/types/claim-auxiliar';
import { ChangeEvent, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteName, goToPage } from 'routes';
import styles from './create-edit-claim.module.scss';

const translPrefix = 'createClaim';

type CommonFormProps = {
  claim: CreateClaimData,
  setClaim: (value: SetStateAction<CreateClaimData>) => void,
  formErrors: HTMLValidationError,
  edit?: boolean,
  setBuildingCommonArea?: (buildingCommonArea: boolean) => void,
  buildingCommonArea?: boolean,
};

const {
  imageMaxSize, MAX_PHOTO_AMOUNT,
} = constants;

const CommonForm = ({
  claim, setClaim, formErrors, edit = false, setBuildingCommonArea, buildingCommonArea = false,
}: CommonFormProps) => {
  const { t } = useTranslation();
  const { state } = useContext(AppContext);
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const handleChange = (event: ChangeEvent<HTMLInputElement>
  | ChangeEvent<HTMLTextAreaElement>) => {
    setClaim((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
  };

  const onClickSelect = (option: Specialization | string, objField: string) => {
    const newClaim = {
      ...claim,
      [objField]: option,
    };
    setClaim(newClaim);
  };

  const setPhotos = (photos: FileType[]) => {
    const newClaim = {
      ...claim,
    };
    newClaim.photos = photos;
    setClaim(newClaim);
  };

  const setArea = (area: string) => {
    if (setBuildingCommonArea !== undefined) {
      setBuildingCommonArea(area === 'common');
    }
  };

  const getCheckedValue = (item: string) => {
    if (item === 'common') {
      return buildingCommonArea;
    }
    return !buildingCommonArea;
  };

  return (
    <>
      <div className={styles.topContainer}>
        <div className={styles.column}>
          <div className={mobile ? 'text__heading1__textNeutral40' : 'text__heading4__textNeutral50'}>
            {edit ? t('editClaim.title') : t('createClaim.title')}
          </div>
          <div className={classnames(styles.subtitle, mobile ? 'text__body__large__textNeutral40' : 'text__heading5__textNeutral50')}>
            {`${t('createClaim.for')}: ${state.property.fullAddress}`}
          </div>
        </div>
        <div className={styles.button}>
          <ButtonClose closeFn={() => goToPage(RouteName.Claims)} closeText={t('myProfile.close')} />
        </div>
      </div>
      <>
        {state.property.unitId
        && setBuildingCommonArea !== undefined && (
        <>

          {mobile
            && <div className={classnames('text__heading3__textNeutral50', styles.info)}>{t('createClaim.info')}</div>}
          <div className={classnames(styles.firstMargin, mobile ? 'text__body__large__textNeutral40'
            : 'text__heading6__textNeutral50')}
          >
            {t('createClaim.area.title')}
          </div>
          <div className={styles.checkboxContainer}>
            <div className={styles.area}>
              {Object.keys(BuildingArea).map((item) => (
                <RadioButtonMobile
                  checked={getCheckedValue(item)}
                  key={item}
                  max={1}
                  onChangeFn={() => setArea(item)}
                  className={styles.input}
                  maxLength={1}
                  containerClass={styles.checkbox}
                  labelClass="text__body__medium__textNeutral40 ml-8"
                  labelContent={t(`createClaim.area.${(item)}`)}
                  name="area"
                />
              ))}
            </div>
          </div>
        </>
        )}
      </>
      {mobile && !edit
      && <div className={classnames(styles.subtitleMobileMargin, styles.bold, 'text__heading3__textNeutral50')}>{t('createClaim.claimInfo')}</div>}
      {!mobile
      && <div className={classnames('text__heading6__textNeutral50', styles.firstMargin)}>{t('createClaim.claim')}</div>}

      <div className={mobile ? 'column' : 'row'}>

        <SelectCategory
          categoryContainerClass={classnames(styles.input, styles.large, 'mr-40', styles.inputMargin)}
          otherCategoryContainerClass={classnames(styles.input, styles.large, 'mr-40', styles.inputMargin)}
          selectCategory={(category: Specialization) => onClickSelect(category, 'category')}
          setOtherCategory={(category: string) => onClickSelect(category, 'otherCategory')}
          category={claim.category}
          formErrors={formErrors}
          otherCategory={claim.otherCategory}
        />

        <Input
          id="name"
          name="name"
          label={t(`${translPrefix}.nameInput.placeholder`)}
          placeholder={t(`${translPrefix}.nameInput.placeholder`)}
          helperText={t(`${translPrefix}.nameInput.helperText`)}
          value={claim.name}
          onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event)}
          required
          t={t}
          containerClass={classnames(styles.input, styles.large, 'mr-40', styles.inputMargin)}
          inputStyle={InputStyle.FORM}
          formError={formErrors}
        />
      </div>

      {!mobile
      && <div className={classnames(styles.margin, 'text__heading6__textNeutral50')}>{t('createClaim.description')}</div>}
      <TextArea
        required
        id="description"
        name="description"
        placeholder={t('createClaim.descriptionPlaceholder')}
        value={claim?.description}
        maxLength={500}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleChange(e)}
        containerClass={classnames(styles.input, styles.description, styles.inputMargin)}
        formError={formErrors}
        t={t}
      />
      <div className={classnames(styles.margin, mobile
        ? (classnames(styles.bold, 'text__heading3__textNeutral50'))
        : 'text__heading6__textNeutral50')}
      >
        {t('createClaim.photos.title')}
      </div>
      <FileUpload
        fetchedFiles={claim?.photos || []}
        fileAddedFn={(files: FileType[]) => setPhotos(files)}
        title={t('createTask.photos.description')}
        restrictionText={t('createClaim.photos.size', { size: imageMaxSize / (1000 * 1000), amount: MAX_PHOTO_AMOUNT })}
      />
    </>
  );
};

export { CommonForm };
