class EndLeaseSerializer {
  static serializeRentalActions(data: SerializedRentalActions): RawRentalActions {
    return {
      delete: data.delete,
      keep_status: data.keepStatus,
      register_payment: data.registerPayment,
    };
  }

  static serializeEndLease(data: SerializedEndLease): RawEndLease {
    return {
      end_date: data.endDate,
      rental_actions: EndLeaseSerializer.serializeRentalActions(data.rentalActions),
    };
  }
}

export { EndLeaseSerializer };
