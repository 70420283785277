class CountrySerializer {
  static deSerializeResponse(data: RawCountriesResponse): SerializedCountriesResponse {
    const response: SerializedCountriesResponse = {};
    Object.keys(data).forEach((key) => {
      response[key] = this.deSerializeCountry(data[key]);
    });
    return response;
  }

  static deSerializeCountry(country: RawCountry): SerializedCountry {
    return {
      fullName: country.full_name,
      provinces: country.provinces,
    };
  }
}

export { CountrySerializer };
