import { useTranslation } from 'react-i18next';
import { DBAinformation as DBAinformationCommon } from '@mapix/common/src/common/payment-information/pages/dba-information';
import { FormEvent, useEffect, useState } from 'react';
import { UserController } from 'networking/controllers/user-controller';
import type { DBAinformationType, RawDBAinformation, RawErrorFields } from '@mapix/common/src/types';
import { logger } from '@mapix/common/src/helpers/logger';
import { Spinner } from '@mapix/common/src/common/spinner';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { goToPage, RouteName } from 'routes';
import { checkHTMLErrors, isEmptyObject, range } from '@mapix/common/src/helpers/utils';
import { ApiError, ErrorStatus } from 'models/api-error';
import { PaymentInformationController } from 'networking/controllers/payment-information-controller';
import { PaymentInformationSerializer } from 'networking/serializers/payment-information-serializer';

const initDBAinformation = (): DBAinformationType => ({
  nameOfBusiness: '',
  civicNumber: '',
  streetName: '',
  unit: '',
  city: '',
  province: '',
  postalCode: '',
  primaryPhoneNumber: '',
  primaryCountryCode: '',
  timezone: '',
  productOrService: '',
  lessThanOneYearInBusiness: false,
  yearsInBusiness: '',
  monthsInBusiness: '',
  emailCustomerService: '',
  emailStatement: '',
  authorizedContact: '',
  customerServicePhoneNumber: '',
  customerServiceCountryCode: '',
  visaOrMastercard: false,
  visaOrMastercardRiskProgram: '',
  visaOrMastercardDate: '',
  previousProcessor: false,
  mailingAddress: '',
});

const DBAinformation = () => {
  // TODO payment information remove this redirection
  goToPage(RouteName.ErrorPage);

  const { t } = useTranslation();
  const [DBAInformation, setDBAInformation] = useState<DBAinformationType>(initDBAinformation());
  const [formErrors, setFormErrors] = useState({});
  const [phoneErrors, setPhoneErrors] = useState({});
  const [fetching, setFetching] = useState(true);
  const [showError, setShowError] = useState(false);
  const [emptyForm, setEmptyForm] = useState(false);
  const [sameAsLegalInfo, setSameAsLegalInfo] = useState(false);

  const setSameAsLegalInformation = async () => {
    if (!sameAsLegalInfo) {
      setFetching(true);
      try {
        const result = await PaymentInformationController.getLegalBusinessInfo();
        setDBAInformation((prevState) => ({
          ...prevState,
          province: result.province ? result.province : prevState.province,
          civicNumber: result.civicNumber ? result.civicNumber : prevState.civicNumber,
          streetName: result.streetName ? result.streetName : prevState.streetName,
          unit: result.unit ? result.unit : prevState.unit,
          city: result.city ? result.city : prevState.city,
          postalCode: result.postalCode ? result.postalCode : prevState.postalCode,
          primaryPhoneNumber: result.primaryPhoneNumber
            ? result.primaryPhoneNumber : prevState.primaryPhoneNumber,
          primaryCountryCode: result.primaryCountryCode
            ? result.primaryCountryCode : prevState.primaryCountryCode,
        }));
        setSameAsLegalInfo(true);
      } catch (error) {
        logger.log(error);
      } finally {
        setFetching(false);
      }
    } else {
      setSameAsLegalInfo(false);
    }
  };

  const [selectOptions, setSelectOptions] = useState({
    province: [] as string[],
    timezone: [] as string[],
    monthsInBusiness: range(11),
    mailingAddress: ['corporate', 'location'],
  });

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    if (!target.checkValidity()) {
      setFormErrors(checkHTMLErrors(target));
      return;
    }

    if (!isEmptyObject(phoneErrors) || !isEmptyObject(formErrors)) {
      return;
    }

    setFetching(true);
    try {
      if (emptyForm) {
        await PaymentInformationController.createDBAInformation(DBAInformation);
      } else {
        await PaymentInformationController.updateDBAInformation(DBAInformation);
      }
      setFetching(false);
      goToPage(RouteName.ManagerPaymentInformation);
    } catch (error) {
      if (error instanceof ApiError && typeof error.details === 'object') {
        setFormErrors(PaymentInformationSerializer
          .deSerializeDBAInformationErrors(error.details as RawErrorFields<RawDBAinformation>, t));
      } else {
        setShowError(true);
        logger.error(error as Error);
      }
      setFetching(false);
    }
  };

  const getDBAinformation = async () => {
    try {
      const actualDBAInformation = await PaymentInformationController.getDBAInformation();
      setDBAInformation(actualDBAInformation);
    } catch (e) {
      if (e instanceof ApiError && e.status === ErrorStatus.NotFound) {
        setEmptyForm(true);
      } else {
        logger.error(e as Error);
        setShowError(true);
      }
    }
    setFetching(false);
  };

  const getProvinces = async () => {
    try {
      const provinces = await PaymentInformationController.getCanadaProvinces();
      setSelectOptions((prevState) => ({ ...prevState, province: provinces }));
    } catch (e) {
      logger.error(e as Error);
      setShowError(true);
    }
  };

  const getTimezones = async () => {
    try {
      const timezones = await PaymentInformationController.getTimezones();
      setSelectOptions((prevState) => ({ ...prevState, timezone: timezones }));
    } catch (e) {
      logger.error(e as Error);
      setShowError(true);
    }
  };

  useEffect(() => {
    getDBAinformation();
    getProvinces();
    getTimezones();
  }, []);

  if (fetching) {
    return (<Spinner />);
  }

  return (
    <>
      {showError
      && (
        <ErrorMessage
          message={t('error.errorMessage')}
          handleClose={() => setShowError(false)}
        />
      )}
      <DBAinformationCommon
        t={t}
        backFn={() => goToPage(RouteName.ManagerPaymentInformation)}
        DBAInformation={DBAInformation}
        setDBAInformation={setDBAInformation}
        formErrors={{ ...formErrors, ...phoneErrors }}
        setPhoneErrors={setPhoneErrors}
        validatePhone={UserController.phoneValidation}
        onSubmit={onSubmit}
        setFormErrors={setFormErrors}
        selectOptions={selectOptions}
        setSameAsLegalInformation={setSameAsLegalInformation}
        sameAsLegalInfo={sameAsLegalInfo}
        goToHomeFn={() => goToPage(RouteName.Home)}
        goToMyProfileFn={() => goToPage(RouteName.MyProfile)}
        goToPaymentInformationFn={() => goToPage(RouteName.ManagerPaymentInformation)}
      />
    </>
  );
};

export { DBAinformation };
