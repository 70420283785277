import { PropertyAddressSerializer } from 'networking/serializers/property-address-serializer';
import { ContractorSerializer } from 'networking/serializers/contractor-serializer';
import { PropertyOwnerSerializer } from 'networking/serializers/property-owner-serializer';
import { UnitSerializer } from 'networking/serializers/unit-serializer';
import { LeaseSerializer } from 'networking/serializers/lease-serializer';

class DetailedPropertySerializer {
  static deSerialize(data: RawDetailedProperty): SerializedDetailedProperty {
    return {
      buildingName: data.building_name,
      condition: data.condition,
      id: data.id,
      type: data.type,
      contractors: data.contractors
        ? data.contractors.map((contractor) => ContractorSerializer.deSerialize(contractor))
        : undefined,
      otherOwner: data.other_owner
        ? PropertyOwnerSerializer.deSerialize(data.other_owner) : undefined,
      owner: data.owner ? PropertyOwnerSerializer.deSerialize(data.owner) : undefined,
      address: data.address ? PropertyAddressSerializer.deSerialize(data.address) : undefined,
      units: data.units ? data.units.map((unit) => UnitSerializer.deSerialize(unit)) : undefined,
      lease: data.lease ? LeaseSerializer.deSerialize(data.lease) : undefined,
      status: data.status,
      unitsCount: data.units_count,
      occupancyRate: data.occupancy_rate,
    };
  }
}

export { DetailedPropertySerializer };
