/* eslint-disable react/jsx-props-no-spreading */
import { ComponentType } from 'react';
import { Auth } from './auth';

const withAuth = <TOriginalProps extends {}>(
  Component: ComponentType<TOriginalProps>, userType?: string,
) => (
    (props: TOriginalProps) => (
      <Auth userType={userType}>
        <Component {...props} />
      </Auth>
    )
  );

export { withAuth };
