import { Paginator } from 'models/paginator';
import { LeaseTableItem } from 'models/lease-table-item';
import { DateFilter } from 'common/filter-by-date/filter-by-date';
import { DateCodes } from 'common/enums';

enum LeaseTabs {
  All = 'All',
  Buildings = 'Buildings',
  CondosAndHouses = 'CondosAndHouses',
}

const leaseAllProperties = ['type', 'address', 'unitNumber', 'rental', 'condoFees', 'startDate', 'expDate', 'ownerName', 'tenantName', 'status'];
const leaseBuildingProperties = ['address', 'buildingName', 'unitNumber', 'rental', 'startDate', 'expDate', 'ownerName', 'tenantName', 'status'];
const leaseCondosAndHousesProperties = ['address', 'condoFees', 'startDate', 'expDate', 'ownerName', 'tenantName', 'status'];

type LeaseChange = {
  placeholderText: string,
  dataProperties: string[],
};

type LeaseState = {
  currentTab: string,
  data: LeaseChange,
  query: string,
  paginator: Paginator<LeaseTableItem>,
  code: string,
  headers: string[],
  error: boolean,
  dateFilter: DateFilter,
  currentLeaseId?: number,
  showResend: boolean,
  tenantEmail: string,
};

type URLFilters = {
  dateFilters: DateFilter,
  tab: string | undefined,
};

const initDateFilter = {
  startDate: '',
  endDate: '',
  code: DateCodes.All,
};

function switchTab(tab: string): LeaseChange {
  switch (tab) {
    case LeaseTabs.All:
      return {
        placeholderText: 'leases.placeholders.all',
        dataProperties: leaseAllProperties,
      };
    case LeaseTabs.Buildings:
      return {
        placeholderText: 'leases.placeholders.buildings',
        dataProperties: leaseBuildingProperties,
      };
    case LeaseTabs.CondosAndHouses:
      return {
        placeholderText: 'leases.placeholders.condoshouses',
        dataProperties: leaseCondosAndHousesProperties,
      };
    default:
      throw new Error('Invalid tab');
  }
}

const initialState = (urlFilters?: URLFilters) => ({
  currentTab: urlFilters?.tab || LeaseTabs.All,
  data: switchTab(urlFilters?.tab || LeaseTabs.All),
  code: 'FETCHING',
  query: '',
  headers: leaseAllProperties,
  paginator: new Paginator(null, []),
  error: false,
  show: false,
  dateFilter: urlFilters?.dateFilters || initDateFilter,
  showResend: false,
  tenantEmail: '',
});

type Action =
| { type: 'FETCHING' }
| { type: 'ERROR' }
| { type: 'TAB_CHANGED', newTab: string }
| { type: 'PAGE_CHANGED', newPage: number }
| { type: 'SEARCH_QUERY', query: string }
| { type: 'SHOW_RESEND_MODAL', currentLeaseId: number, tenantEmail: string }
| { type: 'CLOSE_RESEND_MODAL' }
| { type: 'LEASES_FETCHED', paginator: Paginator<LeaseTableItem> }
| { type: 'APPLY_DATE_FILTERS', dateFilter: DateFilter };

const changePage = (
  currentPage: number,
  paginator: Paginator<LeaseTableItem>,
): Paginator<LeaseTableItem> => {
  const newPaginator = { ...paginator };
  newPaginator.currentPage = currentPage;
  return newPaginator;
};

function LeaseTableReducer(state: LeaseState, action: Action): LeaseState {
  switch (action.type) {
    case 'FETCHING':
      return {
        ...state,
        code: action.type,
      };
    case 'ERROR':
      return {
        ...state,
        error: !state.error,
      };
    case 'TAB_CHANGED':
      return {
        ...state,
        data: switchTab(action.newTab),
        currentTab: action.newTab,
        paginator: changePage(1, state.paginator),
        code: 'FETCHING',
        dateFilter: initDateFilter,
      };
    case 'LEASES_FETCHED':
      return {
        ...state,
        paginator: action.paginator,
        code: 'READY',
      };
    case 'PAGE_CHANGED':
      return {
        ...state,
        paginator: changePage(action.newPage, state.paginator),
        code: 'FETCHING',
      };
    case 'SEARCH_QUERY':
      return {
        ...state,
        query: action.query,
        code: 'FETCHING',
        paginator: changePage(1, state.paginator),
      };
    case 'APPLY_DATE_FILTERS':
      return {
        ...state,
        dateFilter: action.dateFilter,
        paginator: changePage(1, state.paginator),
      };
    case 'SHOW_RESEND_MODAL':
      return {
        ...state,
        currentLeaseId: action.currentLeaseId,
        tenantEmail: action.tenantEmail,
        showResend: true,
      };
    case 'CLOSE_RESEND_MODAL':
      return {
        ...state,
        showResend: false,
      };
    default:
      return {
        ...state,
      };
  }
}

export {
  LeaseTableReducer, LeaseTabs, initialState, initDateFilter,
};
