import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { classnames } from '../../helpers/utils';
import { ReactComponent as Close } from '../../assets/icons/close.svg';
import { ReactComponent as Alert } from '../../assets/icons/alert.svg';
import styles from './error-message.module.scss';

type ErrorProps = {
  message: string,
  handleClose: () => void,
  errorStyle?: string,
};

let errorDiv = document.querySelector('#error') as HTMLElement;

const ErrorMessage = ({ message, handleClose, errorStyle = '' }: ErrorProps) => {
  const el = useRef(document.createElement('div'));
  useEffect(() => {
    const { current } = el;

    if (!errorDiv) {
      errorDiv = document.createElement('div');
      errorDiv.setAttribute('id', 'error');
      document.body.appendChild(errorDiv);
    }

    errorDiv.appendChild(current);
    return () => {
      errorDiv.removeChild(current);
    };
  }, []);
  return createPortal(
    <div className={classnames(styles.container, errorStyle)}>
      <Alert className={styles.icon} />
      <p className="text__body__small__danger50">{message}</p>
      <button type="button">
        <Close className={styles.close} onClick={handleClose} />
      </button>
    </div>,
    el.current,
  );
};

export { ErrorMessage };
