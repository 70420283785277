import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Property } from 'models/property';
import { goToPage, RouteName } from 'routes';
import { Breadcrumbs, BreadcrumbsItem } from '@mapix/common/src/common/breadcrumbs';
import { classnames } from '@mapix/common/src/helpers/utils';
import { DetailCard, DetailCardItem } from 'common/detail-card/detail-card';
import { Tab } from '@mapix/common/src/common/tab';
import { Condition } from 'common/enums';
import { BuildingInfoTab } from 'pages/property-detail/building-tabs/building-info-tab';
import { BillsTable } from 'common/bills-table';
import { CommonDashboard } from 'pages/rentals-dashboard/common-dashboard';
import { UnitInfoTab } from 'pages/property-detail/building-tabs/unit-info-tab/unit-info-tab';
import styles from './property-detail.module.scss';
import { MaintenanceTasksTab } from './common-tabs/maintenance-tab/maintenance-tasks-tab';
import { ContractorsTab } from './common-tabs/contractor-tab/contractors-tab';

const breadcrumbs = (propertyAddress: string, breadcrumbStarter: string): BreadcrumbsItem[] => [
  {
    name: breadcrumbStarter,
    goToPageFn: () => goToPage(RouteName.Properties),
  },
  {
    name: propertyAddress,
    goToPageFn: () => null,
  },
];

const tabs = [
  'generalInfo', 'unitsInfo', 'rentals',
];

const billHeaders = ['affectedAreaDisplay', 'amount', 'category', 'taskId', 'dueDate', 'paidOn', 'status'];
const rentalHeaders = ['unitBuilding', 'tenant', 'billedAmount', 'dueDate', 'paidOn', 'status'];
const rentalDataProperties = ['unit', 'tenant', 'billedAmount', 'dueDate', 'paidOn', 'status'];

const translPrefix = 'propertyDetail';

const getPanelItems = (property: Property, t: (value: string) => string): DetailCardItem[] => ([
  {
    indicator: property.newTasksCount.toString(),
    indicatorClass: classnames(styles.task, 'text__heading5__primary70'),
    title: t(`${translPrefix}.cards.taskTitle`),
    subTitle: t(`${translPrefix}.cards.taskSubTitle`),
    titleClass: 'text__body__large__primary70',
  },
  {
    indicator: property.inProgressTasksCount.toString(),
    indicatorClass: classnames(styles.inProgressTask, 'text__heading5__info40'),
    title: t(`${translPrefix}.cards.taskInProgressTitle`),
    subTitle: t(`${translPrefix}.cards.taskInProgressSubTitle`),
    titleClass: 'text__body__large__info40',
  },
  {
    indicator: property.overdueRentals.toString(),
    indicatorClass: classnames(styles.overdue, 'text__heading5__yellow50'),
    title: t(`${translPrefix}.cards.overdueTitle`),
    subTitle: t(`${translPrefix}.cards.overdueSubTitle`),
    titleClass: 'text__body__large__yellow50',
  },
  {
    isOccupancy: true,
    indicator: property.occupancyRate
      ? `${property.occupancyRate}%`
      : 'N/A',
    indicatorClass: classnames(styles.occupancy, 'text__heading5__tertiary50'),
    title: t(`${translPrefix}.cards.occupancyTitle`),
    subTitle: t(`${translPrefix}.cards.occupancySubTitle`),
    titleClass: 'text__body__large__tertiary50',
  },
]);

type BuildingDetailProps = {
  property: Property,
};

const BuildingDetail = ({ property }: BuildingDetailProps) => {
  const [tab, setTab] = useState<string>('generalInfo');

  const { t } = useTranslation();

  const changeTab = (newTab: string) => {
    setTab(newTab);
  };

  return (
    <div className={styles.detailContainer}>

      <Breadcrumbs values={breadcrumbs(`${property.type} - ${property.fullAddress}`, t(`${translPrefix}.breadcrumbStarter`))} />

      <div className={styles.dashContainer}>

        <div className="text__heading4__textNeutral40">
          {`${t(`propertyName.${property.type}`)} - ${property.fullAddress}`}
        </div>

        <div className={classnames(styles.buildingInfo, 'text__body__small__textNeutral30')}>
          {t(`${translPrefix}.info`)}
        </div>

        {property.condition === Condition.Active && (
          <div className={classnames(styles.cardsContainer, 'row')}>
            {getPanelItems(property, t).map((item) => (
              <DetailCard key={item.title} item={item} cardWidth="25%" />
            ))}
          </div>
        )}

        <div className={styles.tabContainer}>
          {tabs.map((itemTab) => (
            <Tab
              key={itemTab}
              name={t(`${translPrefix}.tabs.${itemTab}`)}
              isActive={tab === itemTab}
              onClickFn={() => changeTab(itemTab)}
            />
          ))}
        </div>

        {tab === 'generalInfo' && <BuildingInfoTab property={property} />}
        {tab === 'unitsInfo' && (
        <UnitInfoTab
          id={property.id}
          condition={property.condition}
          unitsCount={property.unitsCount}
        />
        )}
        {tab === 'maintenance' && (
        <MaintenanceTasksTab
          id={property.id}
          type="Building"
          propertyCondition={property.condition}
        />
        )}
        {tab === 'bills' && (
        <BillsTable
          fixedDataProperties={billHeaders}
          fixedTableHeader={billHeaders}
          propertyId={property.id}
          propertyCondition={property.condition}
          fromPropertyDashboard
        />
        )}
        {tab === 'rentals' && (
          <CommonDashboard
            tableDataProperties={rentalDataProperties}
            tableHeaders={rentalHeaders}
            building
            searchText="rentalsDashboard.buildingTab.buildingSearch"
            id={property.id}
          />
        )}
        {tab === 'contractors' && (
        <ContractorsTab
          id={property.id}
          propertyCondition={property.condition}
        />
        )}

      </div>

    </div>
  );
};

export { BuildingDetail };
