import React from 'react';
import { useTranslation } from 'react-i18next';
import { FileUpload } from 'common/image-upload';
import { classnames, getDisplayFileSize } from '@mapix/common/src/helpers/utils';
import { constants } from 'config/constants';
import styles from './renew-lease.module.scss';

type FilesUploadProps = {
  unitPhotos: FileType[],
  tenantIdPhotos: FileType[],
  leaseCopy: FileType | null,
  fileAddedFn: (option: string, files: FileType[]) => void,
};

const {
  imageMaxSize, MAX_PHOTO_AMOUNT,
} = constants;

const translPrefix = 'renewLease';
const FilesUpload = ({
  unitPhotos, tenantIdPhotos, leaseCopy, fileAddedFn,
}: FilesUploadProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <div className={classnames(styles.subtitle, 'text__heading6__textNeutral50')}>{t(`${translPrefix}.files.title`)}</div>

        <FileUpload
          fetchedFiles={leaseCopy ? [leaseCopy] : null}
          fileAddedFn={(files: FileType[]) => fileAddedFn('leaseCopy', files)}
          title={t(`${translPrefix}.files.leaseFile`)}
          restrictionText={t(`${translPrefix}.files.leaseLimit`)}
          isPDF
        />

        <FileUpload
          fetchedFiles={unitPhotos}
          fileAddedFn={(files: FileType[]) => fileAddedFn('unitPhotos', files)}
          title={t(`${translPrefix}.files.photosFile`)}
          restrictionText={t(`${translPrefix}.files.photosLimit`, { size: getDisplayFileSize(imageMaxSize), amount: MAX_PHOTO_AMOUNT })}
        />

        <FileUpload
          fetchedFiles={tenantIdPhotos}
          fileAddedFn={((files: FileType[]) => fileAddedFn('tenantIdPhotos', files))}
          title={t(`${translPrefix}.files.tenantPhotos`)}
          restrictionText={t(`${translPrefix}.files.tenantLimit`, { size: getDisplayFileSize(imageMaxSize), amount: MAX_PHOTO_AMOUNT })}
        />
      </div>
    </>
  );
};

export { FilesUpload };
