import { LayoutType, withLayout } from 'hocs/with-layout';
import { withAuth } from 'hocs/auth/with-auth';
import { Users } from 'common/enums';
import { CreateClaim } from './create-claim';
import { EditClaim } from './edit-claim';

const WrapAuth = withAuth(CreateClaim, Users.Tenant);
const WrappedCreateClaim = withLayout(LayoutType.OnlyHeader, WrapAuth);

const WrapAuthEdit = withAuth(EditClaim, Users.Tenant);
const WrappedEditClaim = withLayout(LayoutType.OnlyHeader, WrapAuthEdit);

export { WrappedEditClaim as EditClaim };
export { WrappedCreateClaim as CreateClaim };
