import { classnames } from '@mapix/common/src/helpers/utils';
import styles from './terms-of-use.module.scss';

const TermsOfUseFr = () => (
  <div className={styles.container}>
    <p className="text__body__medium__textNeutral50">
      Last update: 6/10/2020
    </p>
    <h2 className={classnames(styles.title, 'text__heading2__textNeutral50')}>
      General Terms and Conditions
    </h2>
    <p className="text__body__medium__textNeutral50">
      This agreement is a binding legal agreement between PAIR IT
      TECHNOLOGIES INC. (
      <b>“Mapix”</b>
      ,
      {' '}
      <b>“we”</b>
      ) and an individual user
      (
      <b>“you”</b>
      ) of the of the platform solution offered by Mapix (the
      {' '}
      <b>“Platform”</b>
      ).
    </p>
    <p className="text__body__medium__textNeutral50">
      The Platform is subject to additional terms and conditions set forth in the
      license agreement between Mapix and its Client (in most cases your employer)
      authorizing you to use the Platform (
      <b>“Client”</b>
      ). You represent and warrant to us
      that you are authorized by our Client to use the Platform.
    </p>
    <p className={classnames(styles.bold, 'text__body__medium__textNeutral50')}>
      If you have any questions, comments or feedback, please do not hesitate to contact Mapix:
    </p>
    <div className={styles.withoutSpace}>
      <p className={classnames(styles.bold, 'text__body__medium__textNeutral50')}>
        PAIR IT TECHNOLOGIES INC.
      </p>
      <p className="text__body__medium__textNeutral50">
        1050, Côte du Beaver Hall, #1500
      </p>
      <p className="text__body__medium__textNeutral50">
        Montreal (Quebec) Canada H2Z 0A5
      </p>
      <p className="text__body__medium__textNeutral50">
        info@mapix.io
      </p>
    </div>
    <h4 className="text__heading4__textNeutral50">
      1. Purpose of Platform
    </h4>
    <p className="text__body__medium__textNeutral50">
      The platform allows you to manage real estate, namely, market properties, sell
      and rent properties, search for potential buyers and lessors, obtain
      statistics and reports, and other features included in the plan chosen
      by our Client (the “Plan”), as the case may be (the “Purpose”).
    </p>
    <h4 className="text__heading4__textNeutral50">
      2. Grant of Licence
    </h4>
    <p className="text__body__medium__textNeutral50">
      Mapix remains the sole and exclusive owner of all right, title and
      interest in and to the Platform, including all intellectual property
      rights therein. Mapix hereby grants you a limited, revocable, non-sublicensable,
      non-exclusive, non-transferable right to use the Platform for the Purpose, only as
      authorized by our Client, subject to the terms and conditions of this agreement,
      and to the terms and conditions of our agreement with our Client.
    </p>
    <p className="text__body__medium__textNeutral50">
      Mapix also grants you the right to reproduce the material and documents available
      online relating to the use of the Platform (the “Documentation”) for the sole purpose
      of helping you use the Platform in accordance with this agreement.
    </p>
    <p className="text__body__medium__textNeutral50">
      The Platform and Documentation are licenced, not sold or assigned. This Agreement
      does not grant you any rights to (i) the source code, object code or any other embodiment
      of the Platform and (ii) Mapix’s trademarks and logos. Mapix reserves all rights not
      expressly granted under this agreement.
    </p>
    <h4 className="text__heading4__textNeutral50">
      3. Prohibited Use
    </h4>
    <p className="text__body__medium__textNeutral50">
      You may not:
    </p>
    <p className="text__body__medium__textNeutral50">
      a) Use the Platform other than for the Purpose or for any illegal purpose or
      in any way that contravenes laws and regulations applicable in Canada, the
      United States or in any jurisdiction where the Platform is used;
    </p>
    <p className="text__body__medium__textNeutral50">
      b) Use the Platform in a manner that unreasonably imposes a disproportionate load
      on the logical and physical infrastructure hosting the Platform.
    </p>
    <p className="text__body__medium__textNeutral50">
      c) Use the Platform to create, post or share content that is inappropriate, illegal
      or unrelated to your work or services for our Client;
    </p>
    <p className="text__body__medium__textNeutral50">
      d) Use the Platform to infringe or facilitate the infringement of any third-party
      intellectual property right.
    </p>
    <p className="text__body__medium__textNeutral50">
      e) Reproduce, transmit or provide access to the Platform for the benefit of any
      person who is not an authorized user;
    </p>
    <p className="text__body__medium__textNeutral50">
      f) Loan, sell, or otherwise distribute all or any part of the Platform or Documentation
    </p>
    <p className="text__body__medium__textNeutral50">
      g) Reverse engineer, decompile or disassemble the Platform in whole or in part,
      or create any derivative works from or sublicense any rights in the Platform or
      attempt to do so or provide your assistance to any effort of a third party to do so;
    </p>
    <p className="text__body__medium__textNeutral50">
      h) Modify or alter in any manner the Platform or the Documentation or take part
      in any such an effort initiated by a third party; andaccess or attempt to access
      to the source code of the Platform.
    </p>
    <h4 className="text__heading4__textNeutral50">
      4. Technical Support
    </h4>
    <p className="text__body__medium__textNeutral50">
      This agreement does not entitle you to any technical support or other professional
      services from Mapix. Mapix’s obligations regarding technical support, if any, are
      covered in its agreement with its Client.
    </p>
    <h4 className="text__heading4__textNeutral50">
      5. User Content
    </h4>
    <p className="text__body__medium__textNeutral50">
      You acknowledge that Mapix is authorized to copy, adapt, broadcast, modify and otherwise
      use in any way (“Use”) any content, works or materials that you create using
      the Platform or post to the Platform (“Content”). To the extent that you own
      any rights in or to Content, you grant Mapix a universal license, without
      any restriction and royalty-free, to Use the Content and you irrevocably
      waive any moral or attribution rights that may exist with respect to your
      Content. You warrant that Mapix’s Use of your Content for the Purpose will
      not infringe on the intellectual property rights of third parties.
    </p>
    <p className="text__body__medium__textNeutral50">
      To the extent that any Content incorporates your image or likeness, you
      waive any right to oppose the use of your image or likeness for the Purpose.
    </p>
    <h4 className="text__heading4__textNeutral50">
      6. Improvements
    </h4>
    <p className="text__body__medium__textNeutral50">
      You acknowledge and agree that Mapix is the sole owner of the Platform and
      Documentation, including improvements made based on the feedback you may
      provide about the Platform or Documentation (“Improvements”). You hereby
      agree you will make no claims of intellectual property rights to such
      Improvements or to such modified Platform, programs, data structure or
      other intellectual property created pursuant to Improvements, and where
      Mapix is not by law the first owner of such Improvements, you hereby
      assign and transfers to Mapix any and all right, title and interest
      you have or may have in the Improvements.
    </p>
    <h4 className="text__heading4__textNeutral50">
      7. Access to the Platform
    </h4>
    <p className="text__body__medium__textNeutral50">
      Although Mapix wishes to make the Platform always available, we cannot
      guarantee that you may have access to the Platform without interruption. We
      may disable the Platform from time to time. The Platform may also be
      modified, updated, interrupted, suspended, or discontinued at any time
      without notice or liability. Mapix shall not be liable if all or any
      part of the Platform is unavailable at any time, for any period of
      time. Pair It cannot and do not make any representations or
      warranties with respect to the devices you use to access or
      use the Platform, including with respect to device compatibility.
    </p>
    <h4 className="text__heading4__textNeutral50">
      8. Limitation of Liability
    </h4>
    <p className="text__body__medium__textNeutral50">
      To the fullest extent permitted by law, In no event will Pair
      It be held liable to you for any loss or damage, direct
      or indirect, consequential or incidental, resulting from your use
      of the Platform and documentation, your inability to use the Platform
      or documentation or any issue related to access to a Wi-Fi or wireless
      network, including, without limitation, the loss or alteration of data
      or loss of profit. This exclusion of the liability of PAIR IT will prevail even
      when you advise of the possibility of such damages or losses.
    </p>
    <h4 className="text__heading4__textNeutral50">
      9. No Warranty
    </h4>
    <p className="text__body__medium__textNeutral50">
      The platform and documentation are provided to you &quot;as is&quot;
      and pair it makes no express
      warranties to you and disclaims all implied warranties with respect to platform and
      documentation.
    </p>
    <p className="text__body__medium__textNeutral50">
      You accept responsibility for the use of the platform and the results obtained therefrom. you
      agree that, as part of your use of the platform, you are responsible for any direct and/or
      indirect consequences or damages resulting from your use of the platform.
    </p>
    <h4 className="text__heading4__textNeutral50">
      10. Indemnification
    </h4>
    <p className="text__body__medium__textNeutral50">
      You agree to indemnify and hold harmless Pair It and its affiliates, employees and
      representatives for any damages or losses (including reasonable legal counsel fees and
      expenses) arising from your use of the Platform in breach of this agreement.
    </p>
    <h4 className="text__heading4__textNeutral50">
      11. Personal Data
    </h4>
    <p className="text__body__medium__textNeutral50">
      You acknowledge that Pair It may monitor your use of the Platform for security purposes, to
      improve the Platform, for the Purpose and of other purposes determined by its Client.
    </p>
    <p className="text__body__medium__textNeutral50">
      You agree that Pair It may from time to time collect information or data from you and use
      such data in compliance with Pair It’s Privacy Policy.
    </p>
    <h4 className="text__heading4__textNeutral50">
      12. Export Controls
    </h4>
    <p className="text__body__medium__textNeutral50">
      Export laws and regulations of Canada and any other relevant local export laws and regulations
      may apply to the Platform. In such case, you agree that such export control laws govern your
      use of the Platform (including technical data) and any services deliverables provided under
      this Agreement, and you agree to comply with all such export laws and regulations (including
      &quot;deemed export&quot; and &quot;deemed re-export&quot; regulations). You agree that no
      data, information, program and/or materials resulting from the Platform and
      Documentation will be exported, directly or indirectly, in violation of these laws, or
      will be used for any purpose prohibited by these laws.
    </p>
    <h4 className="text__heading4__textNeutral50">
      13. Term and Termination
    </h4>
    <p className="text__body__medium__textNeutral50">
      This agreement shall continue until (i) it is terminated by Pair It in accordance with
      the agreement between Pair It and its Client, or (ii) it is terminated by the Client
      or the Client no longer authorizes you to use the Platform.
    </p>
    <p className="text__body__medium__textNeutral50">
      If you breach any of the terms and conditions of this agreement, this agreement and
      your right to use the Platform shall be automatically terminated.
    </p>
    <p className="text__body__medium__textNeutral50">
      Upon termination of this agreement, you undertake to discontinue your use of the
      Platform and the Documentation and to destroy any copies made by you, as the
      case may be. The undertakings, covenants or obligations set forth in
      Sections 5, 8, 9, 10, 11, 14 and 15 shall survive the termination of
      this agreement indefinitely.
    </p>
    <h4 className="text__heading4__textNeutral50">
      14. No Waiver
    </h4>
    <p className="text__body__medium__textNeutral50">
      If Mapix fails, at any time during the term of this agreement, to insist upon
      strict performance of any of your obligations under this agreement, or if Mapix
      fails to exercise any of the rights or remedies to which it is entitled under
      this agreement, this shall not constitute a waiver of such rights or remedies and
      shall not relieve you from compliance with such obligations.
    </p>
    <h4 className="text__heading4__textNeutral50">
      15. Governing Law
    </h4>
    <p className="text__body__medium__textNeutral50">
      This agreement is governed and will be construed in accordance with the laws
      applicable and in force in the Province of Quebec, Canada. Any dispute between
      you and Mapix regarding this agreement shall be brought exclusively before
      the competent courts of the Province of Québec, sitting in the district of Montréal.
    </p>
    <h4 className="text__heading4__textNeutral50">
      16. Language
    </h4>
    <p className="text__body__medium__textNeutral50">
      The parties confirm hereby that they each require that this agreement and
      all documents and notices in connection therewith be drawn up in English.
    </p>
  </div>
);

export { TermsOfUseFr };
