import { classnames } from '../../helpers/utils';
import { ReactComponent as Close } from '../../assets/icons/close.svg';
import { Button, ButtonStyle } from '../button';
import styles from '../button/button.module.scss';

type ButtonCloseProps = {
  closeFn: () => void,
  closeText: string,
  className?: string,
};

const ButtonClose = ({ closeFn, closeText, className = '' }: ButtonCloseProps) => (
  <Button
    onClick={closeFn}
    className={classnames(styles.closeButton, className)}
    buttonStyle={ButtonStyle.Secondary}
  >
    <div
      className="text__body__medium__primary60 row align-justify-center"
    >
      {closeText}
      <Close className={styles.closeIcon} />
    </div>
  </Button>
);

export { ButtonClose };
