import {
  useReducer, useEffect, useState, useRef,
} from 'react';
import { CreateHeader, CreateFooter } from 'common/creation';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Tool } from 'assets/icons/tool.svg';
import { ModalResult } from 'common/modal-result';
import { classnames } from '@mapix/common/src/helpers/utils';
import { MaintenanceController } from 'networking/controllers/maintenance-controller';
import { logger } from 'helpers/logger';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { Spinner } from '@mapix/common/src/common/spinner';
import { PropertyController } from 'networking/controllers/property-controller';
import { Property } from 'models/property';
import { Task } from 'models/task';
import { AssignContractorContainer } from 'common/assign-contractor-container-v2';
import { useHistory, useParams } from 'react-router-dom';
import { RawNewTaskStatus, TaskType } from '@mapix/common/src/common/enums';
import { RouteName, goToPage } from 'routes';
import { getCityCommaProvinceId } from 'helpers/utils';
import { NewTask } from 'networking/types';
import { CreateNewTaskStep1 } from './create-edit-new-task-step-1';
import { CreateNewTaskStep2 } from './create-edit-new-task-step-2';
import { CreateNewTaskReducer, initialState } from './create-edit-new-task-reducer';
import styles from './create-edit-new-task.module.scss';

const translPrefix = 'createTask';
const translError = 'error';

type ParamType = {
  id: string,
};

type CreateTaskState = {
  code: string,
  showError: boolean,
  property?: Property,
  task?: Task,
};

const initialStepState: CreateTaskState = {
  code: 'INITIAL',
  showError: false,
};

const mapTaskDataToAcceptClaim = (taskData: CreateNewTaskData): AcceptClaim => ({
  description: taskData.description,
  name: taskData.name,
  priority: taskData.priority,
  taskType: taskData.taskType,
  contractorId: taskData.contractorId ? Number(taskData.contractorId) : undefined,
  contractorMessage: taskData.contractorMessage,
});

const CreateEditNewTask = () => {
  const params = new URLSearchParams(window.location.search);
  const propertyId = params.get('propertyId');
  const { id } = useParams<ParamType>();
  const history = useHistory();

  const [state, dispatch] = useReducer(CreateNewTaskReducer, initialState(Number(propertyId)));
  const [stepState, setStepState] = useState(initialStepState);
  const [fromPropertyDashboard, setFromPropertyDashboard] = useState(false);
  const { t } = useTranslation();

  const taskCreated = useRef<NewTask | null>(null);

  const closeButton = () => {
    history.goBack();
  };

  const close = () => {
    if (!id || !state.isClaim) {
      history.goBack();
      return;
    }

    if (taskCreated.current?.taskType === TaskType.PrivateTask) {
      goToPage(RouteName.PrivateRequestDetail, {
        id: taskCreated.current?.activeRequestAssignedId,
      });
      return;
    }

    if (taskCreated.current?.taskType === TaskType.UnpublishedTask
      || taskCreated.current?.taskType === TaskType.PublicTask) {
      goToPage(RouteName.UnpublishedAndUnassignedTaskDetail, { id: taskCreated.current?.id });
    }
  };

  const setFinishModal = () => {
    if (id) {
      if (state.taskType === TaskType.PrivateTask) {
        dispatch({ type: 'SHOW_SPINNER' });
      } else {
        dispatch({ type: 'FINISH_MODAL_EDIT_TASK' });
      }

      return;
    }
    switch (state.taskType) {
      case TaskType.UnpublishedTask:
        dispatch({ type: 'FINISH_MODAL_ASSIGN_LATER' });
        break;
      case TaskType.PrivateTask:
        dispatch({ type: 'FINISH_MODAL_ASSIGN_TASK' });
        break;
      case TaskType.PublicTask:
        dispatch({ type: 'FINISH_MODAL_PUBLIC_TASK' });
        break;
      default:
        break;
    }
  };

  const toggleErrorModal = () => {
    setStepState((prevState) => ({ ...prevState, showError: !stepState.showError }));
  };

  const setContractorData = (field: string, value: string) => {
    dispatch({
      type: 'CHANGE_FIELD', field, value,
    });
  };

  const saveTask = async (task: CreateNewTaskState) => {
    const taskRequestData: CreateNewTaskData = {
      propertyId: task.propertyId,
      unitId: task.specificUnit || '',
      contractorId: task.contractorId || '',
      name: task.name,
      category: task.category,
      otherCategory: task.otherCategory,
      priority: task.priority,
      description: task.description,
      taskType: task.taskType,
      taskPhotos: task.taskPhotos,
      contractorMessage: task.infoContractor,
    };

    try {
      if (state.isClaim) {
        const taskResponse = await MaintenanceController
          .acceptClaim(Number(id), mapTaskDataToAcceptClaim(taskRequestData));
        taskCreated.current = taskResponse;
        if (taskRequestData.taskType === TaskType.PrivateTask) {
          dispatch({ type: 'FINISH_MODAL_EDIT_PRIVATE_TASK', newTask: taskResponse });
        }
      } else if (id) {
        const taskResponse = await MaintenanceController.updateNewTask(taskRequestData, Number(id));
        taskCreated.current = taskResponse;
        if (taskRequestData.taskType === TaskType.PrivateTask) {
          dispatch({ type: 'FINISH_MODAL_EDIT_PRIVATE_TASK', newTask: taskResponse });
        }
      } else {
        const taskResponse = await MaintenanceController.createNewTask(taskRequestData);
        taskCreated.current = taskResponse;
      }
    } catch (err) {
      dispatch({ type: 'FINISH_ERROR' });
      logger.error(err as Error);
    }
  };

  const getProperty = async () => {
    setStepState((prevState) => ({ ...prevState, code: 'FETCHING' }));
    try {
      const fetchedProperty = await PropertyController.getDetailedProperty(propertyId!);
      setStepState((prevState) => (
        {
          ...prevState,
          code: 'FROM_DASHBOARD',
        }));
      dispatch({
        type: 'STEPS', steps: ['Complete task info', 'Assign to a contractor'],
      });
      dispatch({ type: 'GO_NEXT' });
      const location = getCityCommaProvinceId(
        fetchedProperty.address.city,
        fetchedProperty.address.provinceId,
      );
      const {
        type, buildingName, id: idProperty, address: { fullAddress: address },
      } = fetchedProperty;
      dispatch({
        type: 'PROPERTY_CHANGED',
        propertyAddress: address || '',
        buildingType: type === 'Building',
        propertyId: idProperty,
        location,
        buildingName,
      });
    } catch (err: any) {
      logger.error(err);
      toggleErrorModal();
    }
  };

  const canEditTask = (taskData: NewTask) => {
    if (taskData.taskType === TaskType.PublicTask) {
      return taskData.status === RawNewTaskStatus.RECEIVING_APPLICATIONS
        && taskData.applications === 0;
    }
    if (taskData.taskType === TaskType.PrivateTask) {
      return taskData.status === RawNewTaskStatus.WAITING_FOR_CONTRACTOR_ASSIGNATION;
    }
    if (taskData.taskType === TaskType.UnpublishedTask) {
      return true;
    }

    const isClaim = () => !taskData.taskType;
    if (isClaim()) {
      return true;
    }
    return false;
  };

  const getTaskInformation = async () => {
    try {
      const fetchedTask = await MaintenanceController.getNewTask(id);
      dispatch({ type: 'SET_NEW_TASK', taskData: fetchedTask, isClaim: !fetchedTask.taskType });
      if (!canEditTask(fetchedTask)) {
        dispatch({ type: 'MODAL_CANNOT_EDIT_TASK' });
      }
    } catch (err: any) {
      logger.error(err);
      toggleErrorModal();
    }
  };

  useEffect(() => {
    if (state.finish) {
      saveTask(state);
    }
  }, [state.finish]);

  useEffect(() => {
    if (id) {
      getTaskInformation();
    }
    if (propertyId) {
      setFromPropertyDashboard(true);
      getProperty();
    }
  }, []);

  if (stepState.code === 'FETCHING') {
    return (<Spinner />);
  }

  return (
    <div className={styles.container}>
      <CreateHeader
        closeText={t('createGeneric.close')}
        title={t(`createTask.${id ? 'editTitle' : 'title'}`)}
        closeFn={closeButton}
        currentStep={state.step}
        stepper={state.steps.map((step) => t(step))}
      />
      {state.step >= 1 && (
      <div className={classnames(styles.addressSubtitle, 'text__heading5__textNeutral50')}>
        {`${state.buildingName ? `${state.buildingName} -` : t(`${translPrefix}.type.condoHouse`)} ${state.propertyAddress}`}
      </div>
      )}
      {state.errorModal
      && (
      <ErrorMessage
        handleClose={() => dispatch({ type: 'CLOSE_ERROR' })}
        message={t(`${translError}.errorMessage`)}
      />
      )}
      {state.modalAssignTask
      && (
      <>
        { (state.contractorId) ? (
          <ModalResult
            title={t(`${translPrefix}.modalTitle`)}
            subtitle={t(`${translPrefix}.modalContractorAssigned`)}
            Icon={Tool}
            withCheckIcon
            buttonTextRight={t(`${translPrefix}.modalButton`)}
            handleButtonRight={close}
            handleButtonClose={close}
          />
        ) : (
          <ModalResult
            title={t(`${translPrefix}.modalAssignError.title`)}
            subtitle={t(`${translPrefix}.modalAssignError.subtitle`)}
            Icon={Tool}
            iconStyle={styles.iconStyle}
            buttonTextRight={t(`${translPrefix}.modalButton`)}
            handleButtonRight={() => dispatch({ type: 'MODAL_ASSIGN_TASK', modalAssignTask: false })}
            handleButtonClose={() => dispatch({ type: 'MODAL_ASSIGN_TASK', modalAssignTask: false })}
          />
        )}
      </>
      )}
      {
        state.modalAssignLater && (
          <ModalResult
            title={t(`${translPrefix}.modalTitle`)}
            subtitle={t(`${translPrefix}.modalContractorNotAssigned`)}
            Icon={Tool}
            withCheckIcon
            buttonTextRight={t(`${translPrefix}.modalButton`)}
            handleButtonRight={close}
            handleButtonClose={close}
          />
        )
      }
      {
        state.modalPublicTask && (
          <ModalResult
            title={t(`${translPrefix}.modalTitle`)}
            subtitle={t(`${translPrefix}.modalContractorPublicTaskCreated`)}
            Icon={Tool}
            withCheckIcon
            buttonTextRight={t(`${translPrefix}.modalButton`)}
            handleButtonRight={close}
            handleButtonClose={close}
          />
        )
      }
      {
        state.modalEditTask && (
          <ModalResult
            title={t(`${translPrefix}.modalTitleEdit`)}
            Icon={Tool}
            withCheckIcon
            buttonTextRight={t(`${translPrefix}.modalButton`)}
            handleButtonRight={close}
            handleButtonClose={close}
          />
        )
      }
      {
        state.modalEditPrivateTask && (
          <ModalResult
            title={t(`${translPrefix}.modalTitleEdit`)}
            Icon={Tool}
            withCheckIcon
            buttonTextRight={t(`${translPrefix}.modalButton`)}
            handleButtonRight={
              () => goToPage(RouteName.PrivateRequestDetail, { id: state.privateRequestId })
            }
            handleButtonClose={close}
          />
        )
      }
      {
        state.modalCannotEditTask && (
          <ModalResult
            title={t(`${translPrefix}.modalTitleCannotEdit`)}
            Icon={Tool}
            withCheckIcon
            buttonTextRight={t(`${translPrefix}.modalButton`)}
            handleButtonRight={() => history.goBack()}
            handleButtonClose={close}
          />
        )
      }
      {
        state.showSpinner && (
          <Spinner />
        )
      }
      {(state.step === 0)
      && (
      <CreateNewTaskStep1
        dispatch={dispatch}
        propertyAddress={state.propertyAddress}
        show={state.show}
        query={state.query}
        data={state.data}
        isEdit={!!id}
        selectedPropertyId={state.propertyId}
      />
      )}

      {state.step === 1
      && (
      <CreateNewTaskStep2
        dispatch={dispatch}
        formErrors={state.formErrors}
        createTaskInfo={state}
        isValidUnitNumber={state.area !== 'specific' || !!state.unitNumber}
        fromPropertyDashboard={fromPropertyDashboard}
        submitUnpublishedTask={setFinishModal}
        submitPublicTask={setFinishModal}
        isEdit={!!id}
        editTask={setFinishModal}
      />
      )}

      {state.step === 2
      && (
        <>
          <AssignContractorContainer
            category={state.category.name}
            propertyId={state.propertyId as number}
            onChange={setContractorData}
            infoContractor={state.infoContractor}
            location={state.location}
          />
          <CreateFooter
            nextName={t('createTask.footer.assignTask')}
            showBack
            Icon={null}
            backFn={() => dispatch({ type: 'GO_BACK' })}
            nextFn={setFinishModal}
            disableNextCondition={!state.contractorId}
          />
        </>
      )}

    </div>
  );
};

export { CreateEditNewTask };
