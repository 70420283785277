import { CancellationReasons, RawCancellationReasons } from '../../types';

class NewTaskSerializer {
  static deSerializeTaskCancellationReasons(
    data: RawCancellationReasons,
  ): CancellationReasons {
    return { cancellationReasons: data.cancellation_reasons };
  }
}

export { NewTaskSerializer };
