import { Input, InputStyle } from '@mapix/common/src/common/input';
import {
  checkHTMLErrors, classnames, HTMLValidationError, isPasswordValid,
} from '@mapix/common/src/helpers/utils';
import React, {
  ChangeEvent, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { ButtonType } from '@mapix/common/src/common/button/button';
import { ReactComponent as Lock } from 'assets/icons/lock.svg';
import { UserController } from 'networking/controllers/user-controller';
import { Spinner } from '@mapix/common/src/common/spinner';
import { Breakpoints } from 'common/enums';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { ModalResult } from 'common/modal-result';
import { ApiError, ErrorCode } from 'models/api-error';
import styles from './change-password.module.scss';

type FormType = {
  [key: string]: string,
  currentPassword: string,
  newPassword: string,
};

type ChangePasswordProps = {
  close: () => void,
};

const translPrefix = 'changePassword';

const ChangePassword = ({ close }: ChangePasswordProps) => {
  const { t } = useTranslation();
  const [fetching, setFetching] = useState(false);
  const [showError, setShowError] = useState(false);
  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});
  const [successModal, setSuccessModal] = useState(false);
  const [formData, setFormData] = useState<FormType>({
    currentPassword: '',
    newPassword: '',
    repeatNewPassword: '',
  });
  const [errorMessage, setErrorMessage] = useState<FormType>({
    currentPassword: '',
    newPassword: '',
    repeatNewPassword: '',
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, id } = event.target;
    const newData = { ...formData };
    const errorData = { ...errorMessage };
    errorData[id] = '';
    newData[id] = value;
    setErrorMessage(errorData);
    setFormData(newData);
  };
  const mobile = window.matchMedia(`(max-width: ${Breakpoints.sm}px)`).matches;

  const handleSave = async (event: any) => {
    event.preventDefault();
    const errorData = {
      currentPassword: '',
      newPassword: '',
      repeatNewPassword: '',
    };
    const target = event.target as HTMLFormElement;
    setFormErrors(checkHTMLErrors(target));
    if (target.checkValidity()) {
      if (!isPasswordValid(formData.newPassword)) {
        errorData.newPassword = (t('password.helper'));
      }
      if (formData.newPassword !== formData.repeatNewPassword) {
        errorData.repeatNewPassword = (t('password.mustMatch'));
      }
      if (!errorData.newPassword && !errorData.repeatNewPassword) {
        try {
          const data: UpdatePassword = {
            currentPassword: formData.currentPassword,
            newPassword: formData.newPassword,
          };
          setFetching(true);
          await UserController.updatePassword(data);
          setFetching(false);
          setSuccessModal(true);
        } catch (e: any) {
          const error = e as ApiError;
          if (error.code! === ErrorCode.InvalidCredentials) {
            errorData.currentPassword = (t('changePassword.invalidCredentials'));
          } else {
            setShowError(true);
          }
          setFetching(false);
        }
      }
      setErrorMessage(errorData);
    }
  };

  if (fetching) {
    return (
      <Spinner />
    );
  }

  if (successModal) {
    return (
      <ModalResult
        title={t(`${translPrefix}.successModal.title`)}
        Icon={Lock}
        buttonTextRight={t(`${translPrefix}.successModal.ok`)}
        handleButtonClose={close}
        handleButtonRight={close}
        withCheckIcon
      />
    );
  }

  return (
    <div className={styles.container}>
      {showError
      && <ErrorMessage message={t('error.errorMessage')} handleClose={() => setShowError(false)} />}
      <form onSubmit={handleSave} noValidate>
        <div className={styles.crossContainer}>
          <button type="button" onClick={close}>
            <Cross className={styles.crossIcon} />
          </button>
        </div>
        <p className={mobile ? 'text__body__large__textNeutral50' : 'text__heading5__textNeutral50'}>
          {t(`${translPrefix}.title`)}
        </p>
        <div className={styles.data}>
          <div className={styles.password}>
            <p className={classnames(styles.subtitle, mobile ? 'text__body__small__textNeutral50' : 'text__body__medium__textNeutral50')}>
              {t(`${translPrefix}.passwordTitle`)}
            </p>
            <Input
              id="currentPassword"
              containerClass={styles.input}
              label={t(`${translPrefix}.currentPassword`)}
              placeholder={t(`${translPrefix}.currentPassword`)}
              inputStyle={InputStyle.FORM}
              value={formData.currentPassword}
              onChange={handleChange}
              type="password"
              error={!!errorMessage.currentPassword}
              formError={formErrors}
              helperText={errorMessage.currentPassword}
              required
              t={t}
            />
          </div>
          <div className={styles.newPassword}>
            <p className={classnames(styles.subtitle, mobile ? 'text__body__small__textNeutral50' : 'text__body__medium__textNeutral50')}>
              {t(`${translPrefix}.newPasswordTitle`)}
            </p>
            <Input
              id="newPassword"
              containerClass={styles.input}
              label={t(`${translPrefix}.newPassword`)}
              placeholder={t(`${translPrefix}.newPassword`)}
              inputStyle={InputStyle.FORM}
              value={formData.newPassword}
              onChange={handleChange}
              helperText={errorMessage.newPassword || t('password.helper')}
              type="password"
              formError={formErrors}
              error={!!errorMessage.newPassword}
              required
              t={t}
            />
            <Input
              id="repeatNewPassword"
              containerClass={classnames(styles.input, styles.repeatPassword)}
              label={t(`${translPrefix}.repeatNewPassword`)}
              placeholder={t(`${translPrefix}.repeatNewPassword`)}
              inputStyle={InputStyle.FORM}
              value={formData.repeatNewPassword}
              onChange={handleChange}
              type="password"
              formError={formErrors}
              helperText={errorMessage.repeatNewPassword}
              error={!!errorMessage.repeatNewPassword}
              required
              t={t}
            />
          </div>
          <div className={styles.center}>
            <Button
              className={styles.buttonSave}
              buttonStyle={ButtonStyle.PrimaryGradient}
              buttonType={ButtonType.Submit}
            >
              <div className="text__button__medium__textNeutral10">{t(`${translPrefix}.resetButton`)}</div>
            </Button>
            <button
              type="button"
              className={classnames(styles.link, 'text__body__medium__primary40')}
              onClick={close}
            >
              {t(`${translPrefix}.cancel`)}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export { ChangePassword };
