import {
  ContractorSpecialization,
  RawContractorSpecialization,
  RawSpecialization,
  RawUpdateSpecializations,
  Specialization,
  UpdateSpecializations,
} from '../../types/specialization';

class SpecializationSerializer {
  static deSerializeSpecialization(data: RawSpecialization): Specialization {
    return {
      name: data.name,
      id: data.id,
    };
  }

  static deSerializeAllSpecializations(data: RawSpecialization[]): Specialization[] {
    return data.map(
      (specialization: RawSpecialization) => this.deSerializeSpecialization(specialization),
    );
  }

  static deSerializeContractorSpecialization(data: RawContractorSpecialization):
  ContractorSpecialization {
    return {
      id: data.id,
      otherSpecializationName: data.other_specialization_name,
      specialization: data.specialization,
    };
  }

  static deSerializeAllContractorSpecialization(
    data: RawContractorSpecialization[],
  ): ContractorSpecialization[] {
    return data.map(
      (specialization: RawContractorSpecialization) => (
        this.deSerializeContractorSpecialization(specialization)
      ),
    );
  }

  static serializeUpdateSpecializations(
    data: UpdateSpecializations,
  ): RawUpdateSpecializations {
    return {
      areas_of_specialization: data.areasOfSpecialization,
      other_areas: data.otherAreas,
      hourly_rate_average: data.hourlyRateAverage,
    };
  }
}

export { SpecializationSerializer };
