import React, { useEffect, useReducer, useState } from 'react';
import { CreateFooter, CreateHeader } from 'common/creation';
import { useTranslation } from 'react-i18next';
import { classnames } from '@mapix/common/src/helpers/utils';
import { ReactComponent as Tool } from 'assets/icons/tool.svg';
import { ModalResult } from 'common/modal-result';
import { MaintenanceController } from 'networking/controllers/maintenance-controller';
import { logger } from 'helpers/logger';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { Spinner } from '@mapix/common/src/common/spinner';
import { Property } from 'models/property';
import { Task } from 'models/task';
import { AssignContractorContainer } from 'common/assign-contractor-container';
import { useHistory, useParams } from 'react-router-dom';
import { ReactComponent as AlertFilled } from 'assets/icons/alertFilled.svg';
import { EditTaskReducer, initialState } from './edit-task-reducer';
import { EditTaskStep2 } from './edit-task-step-2';
import styles from './edit-task.module.scss';

const translPrefix = 'createTask';
const translError = 'error';

type ParamType = {
  id: string,
};

type CreateTaskState = {
  code: string,
  showError: boolean,
  property?: Property,
  task?: Task,
};

const initialStepState: CreateTaskState = {
  code: 'INITIAL',
  showError: false,
};
const EditTask = () => {
  const { id } = useParams<ParamType>();
  const params = new URLSearchParams(window.location.search);
  const propertyId = params.get('propertyId');

  const history = useHistory();
  const [stepState, setStepState] = useState(initialStepState);
  const [state, dispatch] = useReducer(EditTaskReducer, initialState(Number(propertyId)));
  const { t } = useTranslation();

  const close = () => {
    history.goBack();
  };

  const chooseFinishModal = () => {
    if (state.assignLater) {
      dispatch({ type: 'FINISH_MODAL_ASSIGN_LATER' });
    } else if (state.email || state.sms) {
      dispatch({ type: 'FINISH_MODAL_ASSIGN_TASK' });
    } else {
      dispatch({ type: 'OPTION_NOTIFY_NOT_SELECTED', optionNotifyNotSelected: true });
    }
  };

  const setContractorData = (field: string, value: string | boolean) => {
    dispatch({
      type: 'CHANGE_FIELD', field, value,
    });
  };

  const setContactMethod = (field: string, value: boolean) => {
    dispatch({
      type: 'CHANGE_CONTACT_METHOD', field, value,
    });
  };

  const startFinishFlow = (assignLater: boolean) => {
    if (assignLater) {
      dispatch({ type: 'OPTION_NOTIFY_NOT_SELECTED', optionNotifyNotSelected: false });
      setContactMethod('email', false);
    }
    dispatch({ type: 'START_FINISH_FLOW', assignLater });
  };

  const saveEditTask = async (newTask: SerializedCreateTask) => {
    try {
      await MaintenanceController.saveEditedTask(newTask, id);
      chooseFinishModal();
    } catch (err) {
      dispatch({ type: 'FINISH_ERROR' });
      logger.error(err as Error);
    }
  };

  const toggleErrorModal = () => {
    setStepState((prevState) => ({ ...prevState, showError: !stepState.showError }));
  };

  const getTaskDetail = async (taskId: string) => {
    try {
      setStepState((prevState) => ({ ...prevState, code: 'FETCHING' }));
      const taskDetail = await MaintenanceController.getTaskDetail(taskId);
      setStepState((prevState) => ({ ...prevState, code: 'TASK FETCHED' }));
      dispatch({
        type: 'TASK_FETCHED', taskData: taskDetail,
      });
    } catch (err: any) {
      logger.error(err);
      toggleErrorModal();
    }
  };

  useEffect(() => {
    if (state.finish) {
      const task: SerializedCreateTask = {
        category: state.taskCategory,
        startDate: state.issueDate,
        description: state.description,
        priority: state.priority,
        unitId: state.specificUnit ? state.specificUnit : undefined,
        contractorId: (state.contractorId && !state.modalAssignLater && !state.assignLater)
          ? state.contractorId : null,
        additionalInformation: (state.infoContractor && !state.modalAssignLater)
          ? state.infoContractor : undefined,
        email: state.modalAssignLater ? false : state.email,
        sms: state.modalAssignLater ? false : state.sms,
        propertyId: state.propertyId,
        taskPhoto: state.taskPhoto,
      };
      saveEditTask(task);
    }
  }, [state.finish]);

  useEffect(() => {
    if (id) {
      getTaskDetail(id);
    }
  }, []);

  if (stepState.code === 'FETCHING') {
    return (<Spinner />);
  }

  return (
    <div className={styles.container}>
      <CreateHeader
        closeText={t('createGeneric.close')}
        title={t('createTask.editTitle')}
        closeFn={close}
        currentStep={state.step}
        stepper={state.steps}
      />
      {state.errorModal
      && (
      <ErrorMessage
        handleClose={() => dispatch({ type: 'CLOSE_ERROR' })}
        message={t(`${translError}.errorMessage`)}
      />
      )}
      {state.modalAssignTask
      && (
      <>
        { (state.contractorId) && (
        <ModalResult
          title={t(`${translPrefix}.modalTitleEdit`)}
          subtitle={t(`${translPrefix}.modalContractorAssigned`)}
          Icon={Tool}
          withCheckIcon
          buttonTextRight={t(`${translPrefix}.modalButton`)}
          handleButtonRight={close}
          handleButtonClose={close}
        />
        )}
        { (!state.contractorId) && (
        <ModalResult
          title={t(`${translPrefix}.modalAssignError.title`)}
          subtitle={t(`${translPrefix}.modalAssignError.subtitle`)}
          Icon={Tool}
          iconStyle={styles.iconStyle}
          buttonTextRight={t(`${translPrefix}.modalButton`)}
          handleButtonRight={() => dispatch({ type: 'MODAL_ASSIGN_TASK', modalAssignTask: false })}
          handleButtonClose={() => dispatch({ type: 'MODAL_ASSIGN_TASK', modalAssignTask: false })}
        />
        )}
      </>
      )}
      {
        state.modalAssignLater && (
          <ModalResult
            title={t(`${translPrefix}.modalTitleEdit`)}
            subtitle={t(`${translPrefix}.modalContractorNotAssigned`)}
            Icon={Tool}
            withCheckIcon
            buttonTextRight={t(`${translPrefix}.modalButton`)}
            handleButtonRight={close}
            handleButtonClose={close}
          />
        )
      }

      {state.step === 0
      && (
      <EditTaskStep2
        dispatch={dispatch}
        propertyAddress={state.propertyAddress}
        taskCategory={state.taskCategory}
        buildingType={state.buildingType}
        priority={state.priority}
        issueDate={state.issueDate}
        description={state.description}
        formErrors={state.formErrors}
        area={state.area}
        taskPhoto={state.taskPhoto}
        propertyId={state.propertyId}
        unitNumber={state.unitNumber}
      />
      )}

      {state.step === 1
      && (
        <>
          <AssignContractorContainer
            category={state.taskCategory}
            propertyId={state.propertyId}
            onChange={setContractorData}
            infoContractor={state.infoContractor}
            onChangeContactMethod={setContactMethod}
            contractorId={Number(state.contractorId)}
            sms={state.sms}
            email={state.email}
          />
          {state.optionNotifyNotSelected && (
          <div className={classnames(styles.alertNotifyOptions, 'text__body__small__danger50')}>
            <AlertFilled className={styles.alertNotifyIcon} />
            {t(`${translPrefix}.alertSelectNotifyOptions`)}
          </div>
          )}
          <CreateFooter
            nextName={t('createTask.footer.assignTask')}
            showBack
            Icon={null}
            secondButtonName={t('createTask.footer.assignLater')}
            backFn={() => dispatch({ type: 'GO_BACK' })}
            nextFn={() => startFinishFlow(false)}
            secondButtonFn={() => startFinishFlow(true)}
          />
        </>
      )}

    </div>
  );
};

export { EditTask };
