import { UnitOfAProperty } from 'models/unit-of-a-property';
import { Paginator } from '../../../../models/paginator';
import { ModalFilterItem, ModalFilterObject } from '../../../../common/filter-modal/filter-modal';

const filters: ModalFilterObject[] = [
  {
    title: 'Status',
    type: 'status',
    items: [
      { name: 'Rented', code: 'rented', parentType: 'status' },
      { name: 'Available', code: 'available', parentType: 'status' },
    ],
  },
];

type UnitState = {
  filters: ModalFilterObject[],
  paginator: Paginator<UnitOfAProperty>,
  appliedFilters: ModalFilterItem[],
  code: string,
  show: boolean,
  input: string,
  query: string,
  unitStatus: string,
  unitId: number,
  leaseId: number | undefined,
  showArchive: boolean,
  showUnarchive: boolean,
  successfullyArchived: boolean,
  successfullyUnarchived: boolean,
};

type Action =
  | { type: 'PAGE_CHANGED', newPage: number }
  | { type: 'MODAL_VISIBILITY', show: boolean }
  | { type: 'APPLY_FILTERS', filters: ModalFilterItem[] }
  | { type: 'CLEAR_FILTERS' }
  | { type: 'UNITS_FETCHED', paginator: Paginator<UnitOfAProperty> }
  | { type: 'SEARCH_QUERY', query: string }
  | { type: 'UNIT_DATA', leaseId: number | undefined, unitStatus: string, unitId: number }
  | { type: 'SHOW_ARCHIVE', showArchive: boolean }
  | { type: 'SHOW_UNARCHIVE', showUnarchive: boolean }
  | { type: 'SUCCESSFULLY_ARCHIVED', successfullyArchived: boolean }
  | { type: 'SUCCESSFULLY_UNARCHIVED', successfullyUnarchived: boolean }
  | { type: 'INPUT_CHANGED', input: string };

const changePage = (
  currentPage: number,
  paginator: Paginator<UnitOfAProperty>,
): Paginator<UnitOfAProperty> => {
  const newPaginator = { ...paginator };
  newPaginator.currentPage = currentPage;
  return newPaginator;
};

const initialState = {
  code: 'FETCHING',
  paginator: new Paginator(null, []),
  appliedFilters: [],
  show: false,
  query: '',
  input: '',
  filters,
  unitStatus: '',
  leaseId: 0,
  unitId: 0,
  showArchive: false,
  successfullyArchived: false,
  showUnarchive: false,
  successfullyUnarchived: false,
};

function UnitInfoTabReducer(state: UnitState, action: Action): UnitState {
  switch (action.type) {
    case 'UNITS_FETCHED':
      return {
        ...state,
        paginator: action.paginator,
        code: 'READY',
      };
    case 'PAGE_CHANGED':
      return {
        ...state,
        paginator: changePage(action.newPage, state.paginator),
        code: 'FETCHING',
      };
    case 'MODAL_VISIBILITY':
      return {
        ...state,
        show: action.show,
      };
    case 'APPLY_FILTERS':
      return {
        ...state,
        show: false,
        appliedFilters: action.filters,
      };
    case 'CLEAR_FILTERS':
      return {
        ...state,
        appliedFilters: [],
      };
    case 'INPUT_CHANGED':
      return {
        ...state,
        input: action.input,
      };
    case 'SEARCH_QUERY':
      return {
        ...state,
        query: action.query,
        code: 'FETCHING',
      };
    case 'UNIT_DATA':
      return {
        ...state,
        unitStatus: action.unitStatus,
        leaseId: action.leaseId,
        unitId: action.unitId,
      };
    case 'SHOW_ARCHIVE':
      return {
        ...state,
        showArchive: action.showArchive,
      };
    case 'SHOW_UNARCHIVE':
      return {
        ...state,
        showUnarchive: action.showUnarchive,
      };
    case 'SUCCESSFULLY_ARCHIVED':
      return {
        ...state,
        successfullyArchived: action.successfullyArchived,
      };
    case 'SUCCESSFULLY_UNARCHIVED':
      return {
        ...state,
        successfullyUnarchived: action.successfullyUnarchived,
      };
    default:
      return {
        ...state,
      };
  }
}

export { UnitInfoTabReducer, initialState };
