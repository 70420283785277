import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { classnames } from '@mapix/common/src/helpers/utils';
import { RegisterForm } from 'common/register-form';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { UserController } from 'networking/controllers/user-controller';
import { SessionController } from 'networking/controllers/session-controller';
import { AppLink, goToPage, RouteName } from 'routes';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { Breakpoints } from 'common/enums';
import { Spinner } from '@mapix/common/src/common/spinner';
import { useMediaQuery } from '@mapix/common/src/hooks/use-media-query';
import styles from './register-tenant.module.scss';

const RegisterTenant = () => {
  const [errorServer, setErrorServer] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [existEmail, setExistEmail] = useState('');
  const { t } = useTranslation();
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  const urlParams = new URLSearchParams(window.location.search);
  const [showError, setShowError] = useState('');

  const errorCode = urlParams.get('error_code');

  const handleRegister = async (user: User) => {
    try {
      const userNew = { ...user };
      userNew.type = 'Tenant';
      userNew.condition = 'active';
      userNew.invite = false;
      setFetching(true);
      await UserController.register(userNew);
      await SessionController.login(user);
      setFetching(false);
      goToPage(RouteName.HomeTenant, {}, { showModal: true });
    } catch (exception: any) {
      setFetching(false);
      setErrorServer(true);
      setExistEmail(user.email);
    }
  };

  useEffect(() => {
    if (errorCode === '4023') {
      setShowError('incorrectUserType');
    }
  }, []);

  if (fetching) {
    return (
      <div className={styles.spinner}>
        <Spinner fixed={false} />
      </div>
    );
  }
  return (
    <div className={globalStyles.initialContainer}>
      {showError && (
        <ErrorMessage
          message={t(`login.${showError}`)}
          handleClose={() => { setShowError(''); }}
          errorStyle={styles.errorMessage}
        />
      )}
      {errorServer && (
      <ErrorMessage
        message={`${t('register.error.title')} ${existEmail}${t('register.error.title2')}`}
        handleClose={() => { setErrorServer(false); }}
        errorStyle={styles.errorMessage}
      />
      )}
      <div className={styles.content}>
        <div className={styles.titleBox}>
          <p className={classnames(styles.title, mobile ? 'text__heading2__textNeutral40' : 'text__heading3__textNeutral40')}>
            {t('initialpage.title')}
            {' '}
            <span className={styles.green}>Mapix</span>
            .
          </p>
          <p className={classnames(styles.subtitle, mobile ? 'text__body__large__textNeutral40' : 'text__heading4__textNeutral40')}>
            {t('initialpage.subtitle')}
            .
          </p>
        </div>

        <div className={styles.registerForm}>
          <RegisterForm registerFn={handleRegister} />
        </div>

        <div className={styles.bottomText}>
          <p className={classnames(styles.littleMargin, mobile ? 'text__body__medium__textBody' : 'text__body__large__textBody')}>
            {t('register.currentlyAs')}
            {' '}
            <span className={styles.greenBold}>{t('register.tenant')}</span>
            .
          </p>
          <div className={styles.row}>
            <p className={classnames(styles.title, mobile ? 'text__body__small__textBody' : 'text__body__medium__textBody')}>
              {t('register.areYouManager')}
            </p>
            &nbsp;
            <AppLink
              className={classnames(styles.buttonLink, mobile ? 'text__body__small__textBody' : 'text__body__medium__textBody')}
              routeName={RouteName.RegisterManager}
            >
              {t('register.registerAsRealEstate')}
            </AppLink>

          </div>
        </div>
      </div>
    </div>
  );
};

export { RegisterTenant };
