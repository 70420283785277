import { useTranslation } from 'react-i18next';
import { classnames } from '@mapix/common/src/helpers/utils';
import { BillsTableV2 } from 'common/bills-tableV2';
import styles from './bills-table-containerV2.module.scss';

const translPrefix = 'bills';
const BillsTableContainerV2 = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.billsContainer}>

      <div className={classnames(styles.title, 'text__heading4__textNeutral40')}>
        {t(`${translPrefix}.title`)}
      </div>
      <div className={classnames(styles.subTitle, 'text__body__small__textNeutral30')}>
        {t(`${translPrefix}.subTitle`)}
      </div>

      <BillsTableV2 withTabs />

    </div>
  );
};

export { BillsTableContainerV2 };
