import { getFullAddress } from 'helpers/utils';
import { PropertyAddress } from './property-address';
import { PropertyOwner } from './property-owner';

class PropertyClaim {
  id: number;

  type: string;

  condition: string;

  address: PropertyAddress;

  owner: PropertyOwner;

  otherOwner?: PropertyOwner;

  buildingName: string;

  unitsCount?: number;

  status?: string;

  fullAddress: string;

  constructor(params: SerializedPropertyClaim | null) {
    this.id = params?.id || 0;
    this.type = params?.type || '';
    this.condition = params?.condition || '';
    this.buildingName = params?.buildingName || '';
    this.unitsCount = params?.unitsCount || 0;
    this.address = new PropertyAddress(params?.address);
    this.owner = new PropertyOwner(params?.owner);
    this.otherOwner = new PropertyOwner(params?.otherOwner);
    this.status = params?.status;
    this.fullAddress = params?.address ? getFullAddress(params?.address) : '';
  }
}

export { PropertyClaim };
