import { classnames } from '../../helpers/utils';
import styles from './switch-input.module.scss';
import { Switch } from '../switch';
import { Breakpoints } from '../enums';
import { useMediaQuery } from '../../hooks/use-media-query';

type SwitchInputProps = {
  text: string,
  onChangeFn: () => void,
  value: boolean,
  containerClass?: string,
};

const SwitchInput = ({
  text, onChangeFn, value, containerClass,
}: SwitchInputProps) => {
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  return (
    <div className={classnames(styles.flex, containerClass || '')}>
      <span className={classnames(`text__body__${mobile ? 'large' : 'medium'}__textNeutral40`, styles.marginRight)}>
        {text}
      </span>
      <Switch
        isToggled={value}
        switchToggled={onChangeFn}
      />
    </div>

  );
};

export { SwitchInput };
