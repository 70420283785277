import { FormEvent, useEffect, useState } from 'react';
import { Spinner } from '../spinner';
import { CompletionPriceAndComments } from '../completion-price-and-comments';
import { HTMLValidationError, checkHTMLErrors, classnames } from '../../helpers/utils';
import { initCancelTaskData } from '../../initial-states/cancel-task-initial-state';
import { CancelTaskData, CancellationReasons } from '../../types';
import { ModalInformation } from '../modal-information';
import { useMediaQuery } from '../../hooks/use-media-query';
import { Breakpoints } from '../enums';
import { Select } from '../select';
import { logger } from '../../helpers/logger';

const translPrefix = 'modalCancelTask';

type ModalCancelTaskProps = {
  cancelData: CancelTaskData | null;
  handleClose: () => void;
  notifyActionTask: (data: CancelTaskData) => void;
  totalTaskPrice: number;
  cancellationReasonsApiCall: () => Promise<CancellationReasons>;
  isModifying?: boolean;
  t: (text: string) => string;
};

const ModalCancelTask = ({
  t,
  handleClose,
  notifyActionTask,
  cancelData,
  totalTaskPrice,
  isModifying = false,
  cancellationReasonsApiCall,
}: ModalCancelTaskProps) => {
  const [cancelTaskData, setCancelTaskData] = useState<CancelTaskData>(
    cancelData || initCancelTaskData,
  );

  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});
  const [fetching, setFetching] = useState(true);
  const [selectOptions, setSelectOptions] = useState<string[]>([]);

  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const target = event.target as HTMLFormElement;

    if (!target.checkValidity()) {
      setFormErrors(checkHTMLErrors(target));
      return;
    }

    notifyActionTask(cancelTaskData);
  };

  const getCancellationReasons = async () => {
    try {
      const { cancellationReasons } = await cancellationReasonsApiCall();
      setSelectOptions(cancellationReasons);
    } catch (error) {
      logger.log(error);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    getCancellationReasons();
  }, []);

  const showSelectReasonOptions = () => (
    <Select
      containerClass="mt-24 mb-36"
      label={t(`${translPrefix}.reason`)}
      placeholder={t(`${translPrefix}.reason`)}
      value={cancelTaskData.reasonForCancellation ? t(`reasonOptions.${cancelTaskData.reasonForCancellation}`) : ''}
      id="reasonForCancellation"
      onClickOption={(value) => setCancelTaskData(
        (prevState) => ({ ...prevState, reasonForCancellation: value }),
      )}
      options={selectOptions}
      t={t}
      formError={formErrors}
      required
      translationPrefix="reasonOptions"
    />
  );

  const showWarningModifyingCancelledTask = () => (
    <div className={classnames('mt-15', 'mb-20', mobile
      ? 'text__body__small__textNeutral30' : 'text__body__xsmall__textNeutral30')}
    >
      {t(`${translPrefix}.warningModifyingCancelledTask`)}
    </div>
  );

  if (fetching) return <Spinner />;

  return (
    <ModalInformation
      handleButtonClose={handleClose}
      handleButtonRight={() => {}}
      buttonTextRight={t('submit')}
      title={isModifying ? t(`${translPrefix}.modifyingTitle`) : t(`${translPrefix}.title`)}
      onSubmit={onSubmit}
    >
      <>
        {isModifying ? showWarningModifyingCancelledTask() : showSelectReasonOptions()}

        <CompletionPriceAndComments
          completion={cancelTaskData.cancellationPercentage}
          onChangeCompletion={(completion: number | '') => setCancelTaskData(
            (prevState) => ({ ...prevState, cancellationPercentage: completion }),
          )}
          formErrors={formErrors}
          t={t}
          totalTaskPrice={totalTaskPrice}
          comments={cancelTaskData.comments}
          onChangeComments={(comments: string) => setCancelTaskData(
            (prevState) => ({ ...prevState, comments }),
          )}
        />
      </>
    </ModalInformation>
  );
};

export { ModalCancelTask };
