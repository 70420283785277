import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const MetaTags = () => {
  const { t } = useTranslation();
  return (
    <Helmet>
      <title>Mapix</title>
      <meta name="description" content={t('meta.description')} />
    </Helmet>
  );
};

export { MetaTags };
