import { FormEvent, SetStateAction } from 'react';
import { BrowseFiles } from '../../browse-files';
import type {
  DocumentsInformationType, PaymentInformationState, FileType, ExtendedFile,
} from '../../../types';
import { HTMLValidationError, getDisplayFileSize, mapFileToFileArray } from '../../../helpers/utils';
import { PaymentInformationTemplate } from '../../payment-information-template';
import styles from '../payment-information.module.scss';

type DocumentsInformationProps = {
  t: (text: string, object?: {}) => string,
  backFn: () => void,
  documentsInformation: DocumentsInformationType,
  setDocumentsInformation: any,
  formErrors: HTMLValidationError,
  onSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>,
  setFormErrors: (value: SetStateAction<HTMLValidationError>) => void,
  translPrefix: string,
  imageMaxSize: number,
  pdfMaxSize: number,
  uploadFile: (file: ExtendedFile) => Promise<string>,
  goToHomeFn: () => void,
  goToMyProfileFn: () => void,
  goToPaymentInformationFn: () => void,
};

const DocumentsInformation = ({
  t, backFn, documentsInformation, setDocumentsInformation, formErrors,
  onSubmit, setFormErrors, translPrefix, imageMaxSize, pdfMaxSize, uploadFile,
  goToHomeFn, goToMyProfileFn, goToPaymentInformationFn,
}: DocumentsInformationProps) => {
  const addFile = (file: FileType | null, key: string) => {
    setDocumentsInformation(
      (prevState: SetStateAction<PaymentInformationState>) => ({
        ...prevState,
        [key]: file,
      }),
    );
  };

  const plainHelperText: { [key: string]: boolean } = {
    applicationAndAgreement: true,
    proofOfBusiness: true,
    voidCheck: true,
    processingStatements: true,
  };

  const actualHelperText = (key: string) => `${
    (key === 'processingStatements') ? `${t('desirable')} - ` : ''}${
    t(`${translPrefix}.browseFiles.helperText`, { maxSize: getDisplayFileSize(imageMaxSize) })}`;

  return (
    <PaymentInformationTemplate
      t={t}
      backFn={backFn}
      title={t(`${translPrefix}.title`)}
      subtitle={t(`${translPrefix}.subtitle`)}
      onSubmit={onSubmit}
      goToHomeFn={goToHomeFn}
      goToMyProfileFn={goToMyProfileFn}
      goToPaymentInformationFn={goToPaymentInformationFn}
    >

      {Object.keys(documentsInformation).map((key) => (
        <div className={styles.browseFilesWrapper} key={key}>
          <BrowseFiles
            id={key}
            title={t(`${translPrefix}.browseFiles.titles.${key}`)}
            fetchedFiles={mapFileToFileArray(
              documentsInformation[key as keyof DocumentsInformationType],
            )}
            addFile={(file: FileType) => addFile(file, key)}
            removeFile={() => addFile({
              filename: '',
              url: '',
            }, key)}
            uploadFile={uploadFile}
            t={t}
            imageMaxSize={imageMaxSize}
            pdfMaxSize={pdfMaxSize}
            disabled={!!documentsInformation[key as keyof DocumentsInformationType]?.url}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            onlyOneFile
            helperText={actualHelperText(key)}
            plainHelperText={plainHelperText?.[key] || false}
          />
        </div>
      ))}
    </PaymentInformationTemplate>
  );
};

export { DocumentsInformation };
