import { FunctionComponent, SVGProps } from 'react';
import { classnames } from '@mapix/common/src/helpers/utils';
import styles from './create-property.module.scss';

type CreatePropertyCardProps = {
  ImgSvg: FunctionComponent<SVGProps<SVGSVGElement>>,
  title: string,
  subTitle: string
  onClickFn: () => void,
};

const CreatePropertyCard = ({
  ImgSvg, title, subTitle, onClickFn,
}: CreatePropertyCardProps) => (
  <button
    type="button"
    onClick={onClickFn}
    className={classnames(styles.card, 'column align-center')}
  >
    <ImgSvg />
    <div className={classnames(styles.title, 'text__heading6__textNeutral40')}>{title}</div>
    <div className="text__body__small__textNeutral40">{subTitle}</div>
  </button>
);

export { CreatePropertyCard };
