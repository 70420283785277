/* eslint-disable react/jsx-props-no-spreading */
import dayjs from 'dayjs';
import { ReactNode } from 'react';
import { Tooltip, TooltipPosition } from '../tooltip';
import { ReactComponent as Info } from '../../assets/icons/info.svg';
import { useMediaQuery } from '../../hooks/use-media-query';
import { RentalTenant } from '../../types';
import { Breakpoints, PaymentStates } from '../enums';
import { classnames } from '../../helpers/utils';
import styles from './rent-info.module.scss';

type RentInfoProps = {
  rental: RentalTenant | null,
  translPrefix: string,
  t: (text: string) => string,
  payModal?: boolean,
  toggleShowInfoModal: () => void,
  separatorMoreMargin?: boolean,
};

const createChip = (
  translPrefix: string,
  t: (text: string) => string,
  paymentState: PaymentStates | string,
  days?: number,
) => {
  const classes = [styles.chipContainer];
  let text = 'onTime';
  switch (paymentState) {
    case PaymentStates.Late: {
      classes.push(styles.danger, 'text__body__small__danger50 bold');
      text = Math.abs(Number(days)) === 1 ? 'singleLate' : 'late';
      break;
    }
    case PaymentStates.PaidLate: {
      classes.push(styles.paid, 'text__body__small__success50 bold');
      text = 'paidLate';
      break;
    }
    case PaymentStates.PaidOnTime: {
      classes.push(styles.paid, 'text__body__small__success50 bold');
      text = 'paidOnTime';
      break;
    }
    default:
      classes.push(styles.onTime, 'text__body__small__info50 bold');
  }
  return (
    <div className={classnames(...classes)}>
      {days ? `${Math.abs(Number(days))} ` : ''}
      {t(`${translPrefix}.${text}`)}
    </div>
  );
};

const getChip = (
  translPrefix: string,
  t: (text: string) => string,
  payment: RentalTenant | null,
): ReactNode => {
  if (payment?.paymentStatus === PaymentStates.Late) {
    const dueDate = dayjs(payment.dueDate);
    const today = dayjs();
    const days = dueDate.diff(today, 'days');

    return createChip(translPrefix, t, payment.paymentStatus, days);
  }
  return createChip(translPrefix, t, payment?.paymentStatus || '');
};

const getTitle = (mobile: boolean, payModal?: boolean) => {
  if (mobile) {
    return classnames(styles.rentTitle, 'text__heading3__textBody');
  }
  return classnames(
    styles.rentTitle,
    payModal ? 'text__heading5__textBody' : 'text__heading4__primary70',
  );
};

const RentInfo = ({
  rental, translPrefix, t, payModal, toggleShowInfoModal, separatorMoreMargin,
}: RentInfoProps) => {
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  return (
    <div className={classnames(styles.paymentCard, payModal ? styles.payModal : '')}>
      <div className={styles.row}>

        <div className="column">

          <div className={styles.rentContainer}>
            <div className={getTitle(mobile, payModal)}>
              {t(`${translPrefix}.rent`)}
            </div>
            {getChip(translPrefix, t, rental)}
          </div>

          <div className={styles.row}>
            <div className="text__body__small__textNeutral40 bold">
              {t(`${translPrefix}.amount`)}
            </div>
            <div className="text__body__small__textNeutral40">
              &nbsp;
              $
              {rental?.amount}
            </div>
            {(!rental?.fullMonth || rental?.extensionBorderRental) && (mobile ? (
              <button onClick={() => toggleShowInfoModal()} type="button" className={styles.infoButton}>
                <Info className={styles.infoIcon} />
              </button>
            ) : (
              <Tooltip
                textComponent={<Info className={styles.infoIcon} />}
                hoverComponent={(
                  <div className="text__body__small__surface10">
                    {t(`${translPrefix}.amountTooltip`)}
                  </div>
                )}
                containerWidth="294px"
                position={TooltipPosition.Top}
              />
            ))}

            <div className={separatorMoreMargin && !mobile
              ? styles.separatorMoreMargin : styles.separator}
            />

            <div className="text__body__small__textNeutral40 bold">
              {t(`${translPrefix}.dueDate`)}
            </div>
            <div className="text__body__small__textNeutral40">
              &nbsp;
              {rental?.dueDate}
            </div>
          </div>

        </div>

      </div>
    </div>

  );
};

export { RentInfo };
