import { Breakpoints } from 'common/enums';
import { SelectProperty } from 'common/select-property';
import { AppContext } from 'context';
import { classnames } from '@mapix/common/src/helpers/utils';
import { useMediaQuery } from '@mapix/common/src/hooks/use-media-query';
import { ReactComponent as Check } from 'assets/icons/simple-check.svg';
import { Services } from 'models/services';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@mapix/common/src/common/spinner';
import { RouteName } from 'routes';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { Menu } from 'common/menu';
import { logger } from 'helpers/logger';
import { PropertyController } from 'networking/controllers/property-controller';
import { PropertyTenant } from 'models/property-tenant';
import dayjs from 'dayjs';
import styles from './my-property-tenant.module.scss';

type List = {
  name: string,
  route: RouteName,
};

const MyPropertyTenant = () => {
  const { t } = useTranslation();
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  const [property, setProperty] = useState<PropertyTenant | null>(new PropertyTenant(null));
  const [fetching, setFetching] = useState(false);
  const [errorServer, setErrorServer] = useState(false);
  const listPath: List[] = [
    { name: t('myProfile.home'), route: RouteName.HomeTenant },
    { name: t('myProperty.title'), route: RouteName.MyProfile },
  ];

  const { state } = useContext(AppContext);

  const getProperty = async () => {
    try {
      setFetching(true);
      const { id, unitId } = state.property;
      let propertyInfo;
      if (unitId) {
        propertyInfo = await PropertyController.getUnitPropertyFromTenant(unitId, id);
      } else {
        propertyInfo = await PropertyController.getCondoPropertyFromTenant(id);
      }
      setProperty(propertyInfo);
      setFetching(false);
    } catch (err) {
      setErrorServer(true);
      setFetching(false);
      logger.error(err as Error);
    }
  };

  useEffect(() => {
    getProperty();
  }, [state.property.id, state.property.unitId]);

  if (!property || fetching) {
    return (<Spinner />);
  }

  return (
    <div className={styles.container}>
      {errorServer
      && <ErrorMessage message={t('error.errorMessage')} handleClose={() => setErrorServer(false)} />}
      <Menu list={listPath} className={styles.menu} />
      <p className={mobile ? 'text__heading1__textNeutral40' : 'text__heading4__textNeutral40'}>
        {t('myProperty.title')}
      </p>
      { !mobile && (
      <p className={classnames(styles.subtitle, 'text__body__small__textNeutral40')}>
        {t('myProperty.subtitle')}
      </p>
      )}
      <div className={styles.showingContainer}>
        <p className={classnames(styles.showing, mobile ? 'text__body__medium__textNeutral40' : 'text__body__small__textNeutral50')}>
          {`${t('claims.showing')}: `}
        </p>
        <div className={styles.select}>
          <SelectProperty />
        </div>
      </div>
      <div className={styles.data}>
        <div className={styles.card}>
          <p className={classnames(
            styles.title,
            mobile ? 'text__heading3__textNeutral50' : 'text__body__large__textNeutral50',
          )}
          >
            {t('myProperty.generalInfo.title')}
          </p>
          <p className={classnames(
            styles.subtitle,
            styles.margin,
            mobile ? 'text__heading4__textNeutral40' : 'text__body__medium__textNeutral40',
          )}
          >
            {`${t('myProperty.generalInfo.subtitle')}: `}
          </p>
          <p className="text__body__medium__textNeutral50">
            {`${property?.address.civicNumber} ${property?.address.streetName} ${property?.address.city} ${property?.address.province} ${property?.address.zipCode}`}
          </p>
        </div>
        {!mobile && <div className={styles.divider} />}
        <div className={styles.card}>
          <p className={classnames(
            styles.title,
            mobile ? 'text__heading3__textNeutral50' : 'text__body__large__textNeutral50',
          )}
          >
            {t('myProperty.contactInfo.title')}
          </p>
          <div className={styles.rowContent}>
            <div className={styles.column}>
              <p className={classnames(
                styles.subtitle,
                mobile ? 'text__heading4__textNeutral40' : 'text__body__medium__textNeutral40',
              )}
              >
                {`${t('myProperty.contactInfo.manager')}: `}
              </p>
              <p className="text__body__medium__textNeutral50">
                {property.owner.name}
              </p>
            </div>
            {mobile && <div className={styles.column} /> }
            <div className={styles.column}>
              <p className={classnames(
                styles.subtitle,
                mobile ? 'text__heading4__textNeutral40' : 'text__body__medium__textNeutral40',
              )}
              >
                {`${t('myProperty.contactInfo.phone')}: `}
              </p>
              <p className={classnames(styles.underlined, 'text__body__medium__textNeutral50')}>
                {property.owner.phoneNumber}
              </p>
            </div>
            <div className={styles.column}>
              <p className={classnames(
                styles.subtitle,
                mobile ? 'text__heading4__textNeutral40' : 'text__body__medium__textNeutral40',
              )}
              >
                {`${t('myProperty.contactInfo.email')}: `}
              </p>
              <p className={classnames(styles.underlined, 'text__body__medium__textNeutral50')}>
                {property.owner.email}
              </p>
            </div>
          </div>
        </div>
        {!mobile && <div className={styles.divider} />}
        <div className={styles.card}>
          <p className={classnames(
            styles.title,
            mobile ? 'text__heading3__textNeutral50' : 'text__body__large__textNeutral50',
          )}
          >
            {t('myProperty.leaseInfo.title')}
          </p>
          <div className={styles.rowContent}>
            <div className={styles.column}>
              <p className={classnames(
                styles.subtitle,
                mobile ? 'text__heading4__textNeutral40' : 'text__body__medium__textNeutral40',
              )}
              >
                {`${t('myProperty.leaseInfo.status')}: `}
              </p>
              <p className="text__body__medium__textNeutral50">
                {t(`myProperty.leaseStatus.${property.lease?.status}`)}
              </p>
            </div>
            <div className={styles.column}>
              <p className={classnames(
                styles.subtitle,
                mobile ? 'text__heading4__textNeutral40' : 'text__body__medium__textNeutral40',
              )}
              >
                {`${t('myProperty.leaseInfo.startDate')}: `}
              </p>
              <p className="text__body__medium__textNeutral50">
                {property.lease?.startDate}
              </p>
            </div>
            <div className={styles.column}>
              <p className={classnames(
                styles.subtitle,
                mobile ? 'text__heading4__textNeutral40' : 'text__body__medium__textNeutral40',
              )}
              >
                {`${t('myProperty.leaseInfo.expDate')}: `}
              </p>
              <p className="text__body__medium__textNeutral50">
                {property.lease?.expirationDate}
              </p>
            </div>
            {!mobile && <div className={styles.column} />}
          </div>
          <div className={classnames(styles.margin, styles.rowContent)}>
            <div className={styles.column}>
              <p className={classnames(
                styles.subtitle,
                mobile ? 'text__heading4__textNeutral40' : 'text__body__medium__textNeutral40',
              )}
              >
                {`${t('myProperty.leaseInfo.monthlyRental')}: `}
              </p>
              <p className="text__body__medium__textNeutral50">
                {`$${property.lease?.monthlyRental}`}
              </p>
            </div>
            <div className={styles.column}>
              <p className={classnames(
                styles.subtitle,
                mobile ? 'text__heading4__textNeutral40' : 'text__body__medium__textNeutral40',
              )}
              >
                {`${t('myProperty.leaseInfo.yearlyRental')}: `}
              </p>
              <p className="text__body__medium__textNeutral50">
                {`$${Number(property.lease?.monthlyRental) * 12}`}
              </p>
            </div>
            <div className={styles.column}>
              <p className={classnames(
                styles.subtitle,
                mobile ? 'text__heading4__textNeutral40' : 'text__body__medium__textNeutral40',
              )}
              >
                {`${t('myProperty.leaseInfo.dueDate')}: `}
              </p>
              <p className="text__body__medium__textNeutral50">
                {dayjs(property.lease?.expirationDate).format('DD')}
              </p>
            </div>
          </div>
          <div className={styles.column}>
            <p className={classnames(
              styles.subtitle,
              styles.margin,
              mobile ? 'text__heading4__textNeutral40' : 'text__body__medium__textNeutral40',
            )}
            >
              {`${t('myProperty.leaseInfo.included')}: `}
            </p>
            {' '}
            <div className={styles.services}>
              {property.lease && Object.keys(property.lease.services)
                .map((item) => (property.lease?.services[item as keyof Services]) && (
                  <div className={styles.row} key={item}>
                    <Check className={styles.check} />
                    <p className="text__body__medium__textNeutral50">
                      {item !== 'other'
                        ? t(`myProperty.services.${item}`)
                        : (`${t('myProperty.services.other')}:
                        ${property.lease.services.other}`)}
                    </p>
                  </div>
                ))}
            </div>
            { property.lease?.leaseCopy && (
            <a
              href={property.lease?.leaseCopy.url}
              target="_blank"
              rel="noreferrer"
              className={classnames(styles.buttonLease, 'text__body__medium__primary40')}
            >
              {t('myProperty.leaseInfo.seeLeaseFile')}
            </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { MyPropertyTenant };
