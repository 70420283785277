import { checkHTMLErrors, HTMLValidationError } from '@mapix/common/src/helpers/utils';
import React, { Dispatch, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateFooter } from 'common/creation';
import { ButtonType } from '@mapix/common/src/common/button';
import { CreateEditTaskContainerStep2 } from 'common/create-edit-task-container-step-2';
import { Action } from './edit-task-reducer';

type EditTaskStep2Props = {
  dispatch: Dispatch<Action>,
  propertyAddress: string,
  buildingType: boolean | null,
  taskCategory: string,
  priority: string,
  issueDate: string,
  description: string,
  area: string,
  formErrors: HTMLValidationError,
  taskPhoto: FileType[],
  propertyId: number,
  unitNumber: string,
};

const EditTaskStep2 = ({
  dispatch, propertyAddress, buildingType, taskCategory, unitNumber,
  priority, issueDate, area, description, formErrors, taskPhoto,
  propertyId,
}: EditTaskStep2Props) => {
  const { t } = useTranslation();

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    if (!target.checkValidity()) {
      dispatch({ type: 'FORM_ERROR', errors: checkHTMLErrors(target) });
    } else {
      dispatch({ type: 'GO_NEXT' });
    }
  };

  return (
    <form onSubmit={onSubmit} noValidate>
      <CreateEditTaskContainerStep2
        fn={dispatch}
        propertyAddress={propertyAddress}
        buildingType={buildingType}
        taskCategory={taskCategory}
        priority={priority}
        issueDate={issueDate}
        description={description}
        area={area}
        formErrors={formErrors}
        taskPhoto={taskPhoto}
        propertyId={propertyId}
        unitNumber={unitNumber}
      />
      <CreateFooter
        nextButtonType={ButtonType.Submit}
        nextName={t('createGeneric.next')}
        backFn={() => dispatch({ type: 'GO_BACK' })}
      />
    </form>
  );
};

export { EditTaskStep2 };
