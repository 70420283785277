import { useTranslation } from 'react-i18next';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import { HTMLValidationError } from '@mapix/common/src/helpers/utils';
import { ChangeEvent, SetStateAction } from 'react';
import { PropertyOwner } from 'models/property-owner';
import { PhoneInput } from '@mapix/common/src/common/phone-input';
import { UserController } from 'networking/controllers/user-controller';
import styles from './form.module.scss';

type OwnerFormProps = {
  formErrors: HTMLValidationError,
  onChange: (e: ChangeEvent<HTMLInputElement>, type: string) => void,
  owner: PropertyOwner,
  translPrefix: string,
  isRequired?: boolean
  idPrefix: string,
  field: string,
  onChangePhoneNumber: (phoneNumber: string, countryCode: string, field: string) => void,
  setPhoneErrors: (value: SetStateAction<HTMLValidationError>) => void,
};

const OwnerForm = ({
  formErrors, onChange, owner, translPrefix, isRequired = false, idPrefix,
  field, onChangePhoneNumber, setPhoneErrors,
}: OwnerFormProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.formContainer}>
      <div className={styles.row}>
        <Input
          required={isRequired}
          type="text"
          id={`${idPrefix}-name`}
          name="name"
          label={t(`${translPrefix}.nameLabel`)}
          placeholder={t(`${translPrefix}.nameLabel`)}
          helperText={t(`${translPrefix}.nameHelper`)}
          formError={formErrors}
          value={owner.name}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, field)}
          inputStyle={InputStyle.FORM}
          containerClass={styles.input}
          t={t}
        />

        <PhoneInput
          required={isRequired}
          id={`${idPrefix}-phoneNumber`}
          label={t(`${translPrefix}.phoneLabel`)}
          helperText={t(`${translPrefix}.phoneHelper`)}
          formError={formErrors}
          countryCode={owner.countryCode}
          phoneWithoutCode={owner.phoneNumber}
          onChangePhoneNumber={(phoneNumber, countryCode) => onChangePhoneNumber(
            phoneNumber,
            countryCode,
            `${idPrefix}-phoneNumber`,
          )}
          containerClass={styles.input}
          t={t}
          setPhoneErrors={setPhoneErrors}
          validatePhone={UserController.phoneValidation}
        />

        <Input
          required={isRequired}
          type="email"
          id={`${idPrefix}-email`}
          name="email"
          label={t(`${translPrefix}.emailLabel`)}
          placeholder={t(`${translPrefix}.emailLabel`)}
          helperText={t(`${translPrefix}.emailHelper`)}
          formError={formErrors}
          value={owner.email}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, field)}
          inputStyle={InputStyle.FORM}
          containerClass={styles.input}
          t={t}
        />

      </div>
    </div>
  );
};

export { OwnerForm };
