import { getFullAddress } from 'helpers/utils';

class EndLeaseTableItem {
  rentalAmount: number;

  dueDate: string;

  paidOn?: string;

  rentalId: number;

  status: string;

  address: string;

  constructor(params: SerializedRental) {
    this.rentalAmount = params.rentalAmount;
    this.dueDate = params.dueDate;
    this.paidOn = params.paymentDate || '-';
    this.rentalId = params.id;
    this.status = params.status;
    this.address = getFullAddress(params.address);
  }
}

export { EndLeaseTableItem };
