import { classnames } from '@mapix/common/src/helpers/utils';
import { useTranslation } from 'react-i18next';
import styles from './table.module.scss';

type TableHeaderProps = {
  names: string[],
  dashboardName: string,
  hasIcons?: boolean
};

const TableHeader = ({ dashboardName, names, hasIcons = true }: TableHeaderProps) => {
  const { t } = useTranslation();
  return (
    <thead className={styles.header}>
      <tr className={styles.row}>
        {names.map((name) => (
          <th
            key={name}
            className={classnames(styles.cell, 'text__body__overline__textNeutral40')}
          >
            {name !== '' ? t(`${dashboardName}.headers.${name}`) : ''}
          </th>
        ))}
        {/* Buttons cell */}
        {hasIcons && (<th aria-label="Button header" className={classnames(styles.cell, styles.icons)} />)}
      </tr>
    </thead>
  );
};

export { TableHeader };
