import { PaginatorContainer } from '@mapix/common/src/types';
import { NewBillIndex } from 'networking/types/new-bill';

enum BillsTabs {
  Overdue = 'overdue',
  Due = 'due',
  Paid = 'paid',
}

const billsOverdueHeaders: string[] = ['property', 'address', 'affectedArea', 'category',
  'task', 'dueDate', 'amount'];
const billsDueHeaders: string[] = ['property', 'address', 'affectedArea', 'category',
  'task', 'dueDate', 'amount'];
const billsPaidHeaders: string[] = ['property', 'address', 'affectedArea', 'category',
  'task', 'dueDate', 'paidOn', 'amount'];

type BillState = {
  code: string,
  tab: string,
  query: string,
  headers: string[],
  paginator: PaginatorContainer<NewBillIndex>,
  error: boolean,
  selectedBill?: NewBillIndex,
  billAddress: string,
  billPropertyType: string,
  billPropertyId: number,
  billId: number,
  registerPayment: boolean,
  showMakePaymentModal: boolean,
  showBillDetailModal: boolean,
};

const switchTab = (tab: string): string[] => {
  switch (tab) {
    case BillsTabs.Overdue:
      return billsOverdueHeaders;
    case BillsTabs.Due:
      return billsDueHeaders;
    case BillsTabs.Paid:
      return billsPaidHeaders;
    default:
      return billsOverdueHeaders;
  }
};

const initPaginator = (): PaginatorContainer<NewBillIndex> => ({
  navigation: {
    currentPage: 1,
    lastPage: 0,
    pageSize: 10,
    total: 0,
  },
  results: [] as NewBillIndex[],
});

const initialState = (): BillState => ({
  code: 'FETCHING',
  tab: BillsTabs.Overdue,
  query: '',
  headers: switchTab(BillsTabs.Overdue),
  paginator: initPaginator(),
  error: false,
  selectedBill: undefined,
  billAddress: '',
  billPropertyType: '',
  billPropertyId: 0,
  billId: 0,
  registerPayment: false,
  showMakePaymentModal: false,
  showBillDetailModal: false,
});

type Action =
  | { type: 'FETCHING' }
  | { type: 'ERROR' }
  | { type: 'TAB_CHANGED', newTab: string }
  | { type: 'SEARCH_QUERY', query: string }
  | { type: 'SHOW_BILL', selectedBill: NewBillIndex }
  | { type: 'CLOSE_REGISTER_PAYMENT' }
  | { type: 'CLOSE_DELETE_BILL' }
  | { type: 'SHOW_DELETE_BILL', billId: number }
  | { type: 'SHOW_REGISTER_PAYMENT', selectedBill: NewBillIndex }
  | { type: 'BILLS_FETCHED', paginator: PaginatorContainer<NewBillIndex> }
  | { type: 'SHOW_MAKE_PAYMENT_MODAL', show: boolean, selectedBill?: NewBillIndex }
  | { type: 'SHOW_BILL_DETAIL_MODAL', show: boolean, bill?: NewBillIndex }
  | { type: 'PAGE_CHANGED', newPage: number };

const changePage = (
  currentPage: number,
  paginator: PaginatorContainer<NewBillIndex>,
): PaginatorContainer<NewBillIndex> => {
  const newPaginator = { ...paginator };
  newPaginator.navigation.currentPage = currentPage;
  return newPaginator;
};

function BillsTableReducer(state: BillState, action: Action): BillState {
  switch (action.type) {
    case 'TAB_CHANGED':
      return {
        ...state,
        tab: action.newTab,
        headers: switchTab(action.newTab),
      };
    case 'FETCHING':
      return {
        ...state,
        code: action.type,
      };
    case 'ERROR':
      return {
        ...state,
        error: !state.error,
      };
    case 'BILLS_FETCHED':
      return {
        ...state,
        paginator: action.paginator,
        code: 'READY',
      };
    case 'SEARCH_QUERY':
      return {
        ...state,
        query: action.query,
        paginator: changePage(1, state.paginator),
      };
    case 'CLOSE_REGISTER_PAYMENT':
      return {
        ...state,
        registerPayment: false,
      };
    case 'PAGE_CHANGED':
      return {
        ...state,
        paginator: changePage(action.newPage, state.paginator),
        code: 'FETCHING',
      };
    case 'SHOW_MAKE_PAYMENT_MODAL':
      return {
        ...state,
        selectedBill: action.selectedBill,
        showMakePaymentModal: action.show,
      };
    case 'SHOW_BILL_DETAIL_MODAL':
      return {
        ...state,
        showBillDetailModal: action.show,
        selectedBill: action.bill || undefined,
      };
    default:
      return {
        ...state,
      };
  }
}

export {
  BillsTableReducer, initialState, BillsTabs,
};
