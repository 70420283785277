import { FormEvent, SetStateAction } from 'react';
import type {
  ElectronicDebitCreditAuthorizationType,
  ElectronicDebitCreditAuthorizationWithDrawal,
  ElectronicDebitCreditAuthorizationDeposit,
} from '../../../types';
import { classnames, HTMLValidationError, postalCodeRegex } from '../../../helpers/utils';
import { PaymentInformationTemplate } from '../../payment-information-template';
import {
  PaymentInformationFormGenerator, Restrictions, SpecialInputs, SpecialInputType,
} from '../payment-information-form-generator';
import { ReactComponent as Card } from '../../../assets/icons/card.svg';
import styles from '../payment-information.module.scss';

const specialInputs = (
  t: (text: string) => string,
  translPrefix: string,
): { [key: string]: SpecialInputType } => ({
  differentAccountDepositsWithdrawals: {
    type: SpecialInputs.BINARY_QUESTION,
    text: t(`${translPrefix}.texts.differentAccountDepositsWithdrawals`),
  },
});

const helpertexts = (t: (text: string) => string, translPrefix: string) => ({
  postalCode: t(`${translPrefix}.helperTexts.postalCode`),
  withdrawalPostalCode: t(`${translPrefix}.helperTexts.withdrawalPostalCode`),
});

const postalCodeRestrictions = () => ({
  maxLength: 15,
  // [a-zA-Z][0-9][a-zA-Z]\ [0-9][a-zA-Z][0-9]
  regexp: postalCodeRegex(),
});

const transitNumberRestrictions = () => ({
  maxLength: 15,
  regexp: /^\d{5}$/,
});

const restrictions = (): Restrictions => ({
  transit: transitNumberRestrictions(),
  withdrawalTransit: transitNumberRestrictions(),
  account: {
    maxLength: 15,
    regexp: /^\d{5,13}$/,
  },
  postalCode: postalCodeRestrictions(),
  withdrawalPostalCode: postalCodeRestrictions(),
});

type ElectronicDebitCreditAuthorizationProps = {
  t: (text: string) => string,
  backFn: () => void,
  electronicDebitCreditAuthorization: ElectronicDebitCreditAuthorizationType,
  setElectronicDebitCreditAuthorization: (
    value: SetStateAction<any>) => void,
  formErrors: HTMLValidationError,
  onSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>,
  setFormErrors: (value: SetStateAction<HTMLValidationError>) => void,
  translPrefix: string,
  filteredSelectOptions: { [key: string]: string[] },
  goToHomeFn: () => void,
  goToMyProfileFn: () => void,
  goToPaymentInformationFn: () => void,
};

const ElectronicDebitCreditAuthorization = ({
  t, backFn, electronicDebitCreditAuthorization, setElectronicDebitCreditAuthorization, formErrors,
  onSubmit, setFormErrors, translPrefix, filteredSelectOptions,
  goToHomeFn, goToMyProfileFn, goToPaymentInformationFn,
}: ElectronicDebitCreditAuthorizationProps) => {
  const partialState: ElectronicDebitCreditAuthorizationDeposit = {
    differentAccountDepositsWithdrawals: electronicDebitCreditAuthorization
      .differentAccountDepositsWithdrawals,
    accountHolderName: electronicDebitCreditAuthorization.accountHolderName,
    institution: electronicDebitCreditAuthorization.institution,
    transit: electronicDebitCreditAuthorization.transit,
    account: electronicDebitCreditAuthorization.account,
    postalCode: electronicDebitCreditAuthorization.postalCode,
  };

  const partialStateWithdrawal: ElectronicDebitCreditAuthorizationWithDrawal = {
    withdrawalAccountHolderName: electronicDebitCreditAuthorization
      .withdrawalAccountHolderName,
    withdrawalInstitution: electronicDebitCreditAuthorization
      .withdrawalInstitution,
    withdrawalTransit: electronicDebitCreditAuthorization.withdrawalTransit,
    withdrawalPostalCode: electronicDebitCreditAuthorization.withdrawalPostalCode,
  };

  return (
    <PaymentInformationTemplate
      t={t}
      backFn={backFn}
      title={t(`${translPrefix}.title`)}
      onSubmit={onSubmit}
      goToHomeFn={goToHomeFn}
      goToMyProfileFn={goToMyProfileFn}
      goToPaymentInformationFn={goToPaymentInformationFn}
    >
      <div className={styles.cardContainer}>
        <Card />
      </div>
      <PaymentInformationFormGenerator
        t={t}
        state={partialState}
        translPrefix={translPrefix}
        setState={setElectronicDebitCreditAuthorization}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        specialInputs={specialInputs(t, translPrefix)}
        restrictions={restrictions()}
        filteredSelectOptions={filteredSelectOptions}
        helpertexts={helpertexts(t, translPrefix)}
        noTranslation={{ institution: true }}
      />

      { electronicDebitCreditAuthorization.differentAccountDepositsWithdrawals && (
      <>
        <div className={classnames(styles.allRow, 'text__body__large__textNeutral40')}>
          {t(`${translPrefix}.withdrawalAccount`)}
        </div>

        <PaymentInformationFormGenerator
          t={t}
          state={partialStateWithdrawal}
          translPrefix={translPrefix}
          setState={setElectronicDebitCreditAuthorization}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          specialInputs={specialInputs(t, translPrefix)}
          restrictions={restrictions()}
          filteredSelectOptions={filteredSelectOptions}
          helpertexts={helpertexts(t, translPrefix)}
          noTranslation={{ withdrawalInstitution: true }}
        />
      </>
      )}
    </PaymentInformationTemplate>
  );
};

export { ElectronicDebitCreditAuthorization };
