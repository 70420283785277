import { ProgressBar } from '@mapix/common/src/common/progress-bar';
import { ReactComponent as Image } from 'assets/icons/image.svg';
import { ReactComponent as Close } from 'assets/icons/close-filled.svg';
import { classnames } from '@mapix/common/src/helpers/utils';
import styles from './file-upload.module.scss';

type UploadItemProps = {
  isPdf: boolean,
  url?: string,
  loading: boolean,
  filename?: string,
  removeFn: () => void,
  onClickFn?: () => void,
  crossIcon?: boolean,
  t: (text: string) => string,
};

const UploadItem = ({
  isPdf, url = '', loading, filename = '', removeFn, onClickFn = () => null, crossIcon, t,
}: UploadItemProps) => (
  <>
    {isPdf
      ? (
        <>
          {loading
            ? <ProgressBar loading={loading} t={t} />
            : (
              <div className="row align-justify-center">
                <a href={url} target="_blank" rel="noreferrer">{filename}</a>
                <button onClick={() => removeFn()} type="button">
                  <Close className={classnames(styles.cross, styles.pdf)} />
                </button>
              </div>
            )}
        </>
      )
      : (
        <>
          {loading
            ? (
              <div className="column">
                <ProgressBar loading={loading} t={t} />
                <div className={styles.imagePlaceholder}>
                  <Image className={styles.img} />
                </div>
              </div>
            )
            : (
              <div className={styles.uploadedImageContainer}>
                {(crossIcon) && (
                <button onClick={() => removeFn()} className={styles.buttonCross} type="button">
                  <Close className={styles.cross} />
                </button>
                )}
                <img
                  role="presentation"
                  className={styles.image}
                  src={url}
                  alt="Unit"
                  onClick={onClickFn}
                />
              </div>
            )}
        </>
      )}
  </>
);

export { UploadItem };
