import { TypeOfCostSerializer } from '@mapix/common/src/networking/serializers/type-of-cost-serializer';
import {
  RawRejectRequest, RawRequestEvent, RejectRequest, RequestEvent,
} from '@mapix/common/src/types';

class RequestSerializer {
  static serializeRejectRequestData = (data: RejectRequest): RawRejectRequest => ({
    reason_for_rejection: data.reasonForRejection,
  });

  static deSerializeRequestEvent = (data: RawRequestEvent): RequestEvent => ({
    id: data.id,
    createdAt: data.created_at,
    eventType: data.event_type,
    messageToContractor: data.message_to_contractor || undefined,
    messageToManager: data.message_to_manager || undefined,
    ...TypeOfCostSerializer.deSerializeOldTypeOfCost(data),
  });
}

export { RequestSerializer };
