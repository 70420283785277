import { classnames } from '../../helpers/utils';
import { ReactComponent as Carpentry } from '../../assets/icons/contractor-categories/carpentry.svg';
import { ReactComponent as ConcreteSpecialist } from '../../assets/icons/contractor-categories/concrete-specialist.svg';
import { ReactComponent as Demolition } from '../../assets/icons/contractor-categories/demolition.svg';
import { ReactComponent as Drywaller } from '../../assets/icons/contractor-categories/drywaller.svg';
import { ReactComponent as Electricity } from '../../assets/icons/contractor-categories/electricity.svg';
import { ReactComponent as ElevatorAndOtherLiftingDevices } from '../../assets/icons/contractor-categories/elevator-and-other-lifting-devices.svg';
import { ReactComponent as Excavator } from '../../assets/icons/contractor-categories/excavator.svg';
import { ReactComponent as FloorLaying } from '../../assets/icons/contractor-categories/floor-laying.svg';
import { ReactComponent as GardeningAndLandscape } from '../../assets/icons/contractor-categories/gardening-and-landscape.svg';
import { ReactComponent as GeneralContractor } from '../../assets/icons/contractor-categories/general-contractor.svg';
import { ReactComponent as GlassAndGlazing } from '../../assets/icons/contractor-categories/glass-and-glazing.svg';
import { ReactComponent as Handyman } from '../../assets/icons/contractor-categories/handyman.svg';
import { ReactComponent as HeatingAndAirConditioning } from '../../assets/icons/contractor-categories/heating-and-air-conditioning.svg';
import { ReactComponent as Masonry } from '../../assets/icons/contractor-categories/masonry.svg';
import { ReactComponent as Painting } from '../../assets/icons/contractor-categories/painting.svg';
import { ReactComponent as Plaster } from '../../assets/icons/contractor-categories/plaster.svg';
import { ReactComponent as Plumbing } from '../../assets/icons/contractor-categories/plumbing.svg';
import { ReactComponent as Roofing } from '../../assets/icons/contractor-categories/roofing.svg';
import { ReactComponent as TileSetting } from '../../assets/icons/contractor-categories/tile-setting.svg';
import { ReactComponent as WallpaperInstaller } from '../../assets/icons/contractor-categories/wallpaper-installer.svg';

import { IconColor, Specializations } from '../enums';

import styles from './contractor-category-icon.module.scss';

const handleClassname = (color?: IconColor, className?: string) => {
  const finalClassname = [className || ''];
  switch (color) {
    case IconColor.Yellow:
      finalClassname.push(styles.yellow);
      break;
    case IconColor.Red:
      finalClassname.push(styles.red);
      break;
    default:
      finalClassname.push(styles.purple);
  }
  return classnames(...finalClassname);
};

type ContractorCategoryIconProps = {
  category: string;
  color?: IconColor;
  className?: string;
};

const ContractorCategoryIcon = (
  {
    category, color, className,
  }: ContractorCategoryIconProps,
) => {
  let Icon;
  switch (category) {
    case Specializations.Carpentry:
      Icon = Carpentry;
      break;
    case Specializations.ConcreteSpecialist:
      Icon = ConcreteSpecialist;
      break;
    case Specializations.Demolition:
      Icon = Demolition;
      break;
    case Specializations.Drywaller:
      Icon = Drywaller;
      break;
    case Specializations.Electricity:
      Icon = Electricity;
      break;
    case Specializations.ElevatorsAndOtherLiftingDevices:
      Icon = ElevatorAndOtherLiftingDevices;
      break;
    case Specializations.Excavator:
      Icon = Excavator;
      break;
    case Specializations.FloorLaying:
      Icon = FloorLaying;
      break;
    case Specializations.GardeningAndLandscape:
      Icon = GardeningAndLandscape;
      break;
    case Specializations.GeneralContractor:
      Icon = GeneralContractor;
      break;
    case Specializations.GlassAndGlazing:
      Icon = GlassAndGlazing;
      break;
    case Specializations.Handyman:
      Icon = Handyman;
      break;
    case Specializations.HeatingAndAirConditioning:
      Icon = HeatingAndAirConditioning;
      break;
    case Specializations.Masonry:
      Icon = Masonry;
      break;
    case Specializations.Painting:
      Icon = Painting;
      break;
    case Specializations.Plaster:
      Icon = Plaster;
      break;
    case Specializations.Plumbing:
      Icon = Plumbing;
      break;
    case Specializations.Roofing:
      Icon = Roofing;
      break;
    case Specializations.TileSetting:
      Icon = TileSetting;
      break;
    case Specializations.WallpaperInstaller:
      Icon = WallpaperInstaller;
      break;
    default:
      Icon = WallpaperInstaller;
      break;
  }
  return <Icon className={handleClassname(color, className)} />;
};

export { ContractorCategoryIcon };
