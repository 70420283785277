import { classnames } from '../../helpers/utils';
import styles from './white-box-titled.module.scss';

type DateFragmentProps = {
  title: string,
  content: string,
  mobile: boolean,
  containerClass?: string,
};

const WhiteBoxTitled = ({
  title, content, mobile, containerClass = '',
}: DateFragmentProps) => (
  <div className={classnames(styles.container, containerClass)}>
    <div className={classnames(
      styles.taskStatus,
      `text__body__${mobile ? 'large' : 'medium'}__textNeutral50`,
    )}
    >
      {title}
    </div>
    <div className={classnames(
      styles.whiteBox,
      `text__body__${mobile ? 'medium' : 'small'}__secondary90`,
    )}
    >
      {content}
    </div>
  </div>
);

export { WhiteBoxTitled };
