import { ReactComponent as Cross } from '@mapix/common/src/assets/icons/cross.svg';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { Modal } from '@mapix/common/src/common/modal';
import { classnames, getTimeZoneFormat } from '@mapix/common/src/helpers/utils';
import { NewBillDetail } from 'networking/types/bill-detail';
import { useTranslation } from 'react-i18next';
import { RouteName, goToPage } from 'routes';

import { RawNewBillStatus } from '@mapix/common/src/common/enums';
import { logger } from 'helpers/logger';
import { BillsController } from 'networking/controllers/bills-controller';
import { useEffect, useState } from 'react';
import { Spinner } from '@mapix/common/src/common/spinner';
import { initBillDetail } from 'initial-states/new-bill-detail-initial-state';
import styles from './bill-detail-modal.module.scss';

type BillDetailModalProps = {
  handleButtonClose: () => void,
  billId: number,
};

const translPrefix = 'billDetailModal';

const billTextRow = (detail: string, amount: number, calculation?: string) => (
  <div className="mb-8">
    <span className="text__body__small__textNeutral40">{`${detail}:`}</span>
    <span className={classnames('text__body__medium__secondary90', styles.price)}>
      {`$${amount}`}
    </span>
    {calculation && (
      <span className="text__body__small__textNeutral30">
        {calculation}
      </span>
    )}
  </div>
);

const BillDetailModal = ({
  handleButtonClose, billId,
}: BillDetailModalProps) => {
  const { t } = useTranslation();

  const [billDetail, setBillDetail] = useState<NewBillDetail>(initBillDetail);
  const [fetching, setFetching] = useState<boolean>(true);

  const getBill = async () => {
    try {
      const actualBill = await BillsController.getBillV2(billId);
      setBillDetail(actualBill);
      setFetching(false);
    } catch (error) {
      logger.error(error as Error);
    }
  };

  useEffect(() => {
    getBill();
  }, []);

  if (fetching) return <Spinner />;

  return (
    <Modal>
      <div className={styles.modalContainer}>
        <div className={styles.titleAndCrossContainer}>
          <p className={classnames(
            styles.semiBold,
            'text__body__medium__textNeutral40',
          )}
          >
            {t(`${translPrefix}.title`)}
          </p>
          <button type="button" onClick={handleButtonClose}>
            <Cross className={styles.crossIcon} />
          </button>
        </div>

        <div className={styles.taskAndCost}>
          <div>
            <span className="text__body__small__textNeutral30">{`${t(`${translPrefix}.task`)}:`}</span>

            <Button
              buttonStyle={ButtonStyle.Link}
              className="text__body__small__primary50"
              onClick={() => goToPage(RouteName.TaskDetail, { id: `${billDetail?.task?.id}` })}
            >
              {`${billDetail.task.name} (${billDetail.task.id})`}
            </Button>
          </div>

          <div
            className={classnames(
              styles.totalAmount,
              'text__body__medium__primary50',
            )}
          >
            {`$${billDetail.totalAmount}`}
          </div>
        </div>

        {billTextRow(
          t(billDetail?.task?.typeOfCost === 'budget' ? `${translPrefix}.budget` : `${translPrefix}.rate`),
          billDetail.billedAmount,
          billDetail?.task?.typeOfCost === 'hourlyRate'
            ? ` ($${billDetail?.task?.hourlyRate}×${billDetail?.task?.amountOfHours} ${t(`${translPrefix}.hours`)})` : '',
        )}
        {billTextRow(t(`${translPrefix}.processingFees`), billDetail.processingFees)}
        {billDetail.gst && billTextRow(t(`${translPrefix}.gst`), billDetail.gst)}
        {billDetail.pst && billTextRow(t(`${translPrefix}.pst`), billDetail.pst)}
        {billDetail.hst && billTextRow(t(`${translPrefix}.hst`), billDetail.hst)}
        {billDetail.qst && billTextRow(t(`${translPrefix}.qst`), billDetail.qst)}

        {billDetail.status === RawNewBillStatus.PAID && (
        <div className={styles.paidOn}>
          <span className="text__body__medium__textNeutral40">
            {`${t(`${translPrefix}.paidOn`)} ${getTimeZoneFormat(billDetail.paymentDate, 'DD/MM/YYYY')
            } ${t(`${translPrefix}.by`)} ${`${billDetail.managerName} ${billDetail.managerLastName}`}
            `}
          </span>

        </div>
        )}
      </div>
    </Modal>
  );
};

export { BillDetailModal };
