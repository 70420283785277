import { PillColor, Specializations } from '@mapix/common/src/common/enums';
import { City, TypeOfCost } from '@mapix/common/src/types';
import { useTranslation } from 'react-i18next';
import { AvatarOrInitials } from '@mapix/common/src/common/avatar-or-initials';
import { ContractorCategoryIcon } from '@mapix/common/src/common/contractor-category-icon';
import { Pill } from '@mapix/common/src/common/pill';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { classnames, getTimeZoneFormat } from '@mapix/common/src/helpers/utils';
import { ArraySurplusOrganizer } from 'common/array-surplus-organizer';
import styles from './application-card.module.scss';

type ApplicationCardProps = {
  name: string;
  lastName: string;
  photoUrl?: string;
  specializations: Specializations[];
  cities: City[];
  certifications: string[];
  startDate: string;
  typeOfCost: TypeOfCost;
  message: string;
  handleAssign: () => void;
  handleReject: () => void;
};

const translPrefix = 'applicationCard';

const ApplicationCard = ({
  name, lastName, photoUrl, specializations, cities, certifications, startDate,
  typeOfCost, message, handleAssign, handleReject,
}: ApplicationCardProps) => {
  const { t } = useTranslation();

  const mapToPill = (certification: string) => (
    <Pill
      content={certification}
      color={PillColor.Green}
      textStyle="text__body__xsmall__textNeutral10"
      containerClass={styles.pill}
      key={certification}
    />
  );

  const mapToCategoryIcon = (category: string) => (
    <ContractorCategoryIcon category={category} key={category} />
  );

  return (
    <div className={styles.container}>
      <div className={styles.topSectionWrapper}>
        <AvatarOrInitials
          firstName={name}
          lastName={lastName}
          url={photoUrl}
          className={styles.avatarOrInitials}
        />
        <div className={styles.basicInfoWrapper}>
          <div className="text__body__medium__textNeutral50">
            {`${name} ${lastName}`}
          </div>
          { cities.map((city) => (
            <div className="text__body__xsmall__textNeutral30" key={`${city.name}-${city.provinceId}-${city.country}`}>
              { `${city.name}, ${city.provinceId}, ${city.country}` }
            </div>
          ))}
          <ArraySurplusOrganizer
            array={specializations}
            maxIcons={5}
            organizerFn={mapToCategoryIcon}
            plusContainerClass={styles.plusContainerSpecializations}
          />
          <ArraySurplusOrganizer
            array={certifications}
            maxIcons={2}
            organizerFn={mapToPill}
            plusContainerClass={styles.plusContainerCertifications}
          />
        </div>
      </div>
      <div className={styles.middleSectionWrapper}>
        <div className="text__body__xsmall__textNeutral40">
          {`${t(`${translPrefix}.startDate`)}: ${getTimeZoneFormat(startDate, 'MMMM Do, YYYY')}`}
        </div>
        <div className="text__body__xsmall__textNeutral40">
          {`${t(`${translPrefix}.estimatedTime`)}: ${
            typeOfCost.amountOfHours ? `${typeOfCost.amountOfHours} ${t(`${translPrefix}.hours`)}` : '-'
          }`}
        </div>
        <div className="text__body__xsmall__textNeutral40">
          {`${t(`${translPrefix}.message`)}: ${message}`}
        </div>
        <div>
          <span className="text__body__small__textNeutral50">

            {`${t(`${translPrefix}.${typeOfCost.typeOfCost === 'budget' ? 'fixedRate' : 'hourlyRate'}`)}: `}
          </span>
          <span className={classnames('text__body__small__primary40', 'semiBold')}>
            {`$${typeOfCost.typeOfCost === 'budget' ? typeOfCost.budget : typeOfCost.hourlyRate}`}
          </span>
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button
          onClick={handleAssign}
          buttonStyle={ButtonStyle.Secondary}
          className="text__button__small__primary60 mr-2"
        >
          {t('assign')}
        </Button>
        <Button
          onClick={handleReject}
          buttonStyle={ButtonStyle.GhostSecondary}
          className="text__button__small__secondary90"
        >
          {t('reject')}
        </Button>
      </div>
    </div>
  );
};

export { ApplicationCard };
