import { EditRental } from 'common/edit-rental';
import { ActivateAccount } from 'pages/activate-account/activate-account';
import { AddBill } from 'pages/add-bill';
import { AssignContractorV2 } from 'pages/assign-contractor-v2';
import { BillsTableContainerV2 } from 'pages/bills-tableV2';
import { ClaimDetail } from 'pages/claim-detail';
import { Claims } from 'pages/claims';
import { ContractorTaskDetail } from 'pages/contractor-task-detail';
import { CreateClaim, EditClaim } from 'pages/create-edit-claim';
import { CreateEditContractor } from 'pages/create-edit-contractor';
import { CreateEditNewTask } from 'pages/create-edit-new-task';
import { CreateProperty } from 'pages/create-property';
import { CreateTask } from 'pages/create-task';
import { CreateUnit } from 'pages/create-unit';
import { EditProfile } from 'pages/edit-profile';
import { EditProperty } from 'pages/edit-property';
import { EditTask } from 'pages/edit-task';
import { EditUnit } from 'pages/edit-unit';
import { ExtendLease } from 'pages/extend-lease';
import { Home } from 'pages/home';
import { HomeTenant } from 'pages/home-tenant';
import { InitialRegister } from 'pages/initial-register';
import { LeaseDetailed } from 'pages/lease-detailed';
import { LeaseTable } from 'pages/leases';
import { Login } from 'pages/login';
import { MakePaymentPage } from 'pages/make-payment-page';
import { ManagerPaymentInformation } from 'pages/manager-payment-information';
import { DBAinformation } from 'pages/manager-payment-information/pages/dba-information';
import { DocumentsInformation } from 'pages/manager-payment-information/pages/documents-information';
import { ElectronicDebitCreditAuthorization } from 'pages/manager-payment-information/pages/electronic-debit-credit-authorization';
import { LegalBusinessInformation } from 'pages/manager-payment-information/pages/legal-business';
import { OwnersOrOfficers } from 'pages/manager-payment-information/pages/owners-or-officers';
import { SalesProfile } from 'pages/manager-payment-information/pages/sales-profile';
import { MyProfile } from 'pages/my-profile';
import { MyPropertyTenant } from 'pages/my-property-tenant';
import { PasswordRecovery } from 'pages/password-recovery';
import { PaymentsTenant } from 'pages/payments-tenant';
import { PrivacyPolicy } from 'pages/privacy-policy';
import { PrivateRequestDetail } from 'pages/private-request-detail';
import { PropertyDetail } from 'pages/property-detail';
import { PublicRequestDetail } from 'pages/public-request-detail';
import { RegisterManager } from 'pages/register-manager';
import { RegisterTenant } from 'pages/register-tenant';
import { RenewLease } from 'pages/renew-lease';
import { RentalsDashboard } from 'pages/rentals-dashboard';
import { RentalDashboardBuilding } from 'pages/rentals-dashboard/building';
import { ResetPassword } from 'pages/reset-password';
import { SetAsRented } from 'pages/set-as-rented';
import { SetUnitsAsRented } from 'pages/set-unit-as-rented';
import { TaskDetailClaim } from 'pages/task-detail-claim';
import { TaskDetailV2 } from 'pages/task-detailV2';
import { TermsOfUse } from 'pages/terms-of-use';
import { UnpublishedAndUnassignedTaskDetail } from 'pages/unpublished-and-unassigned-task-detail';
import { VerifyAccount } from 'pages/verify-account';
import { RouteName } from 'routes/routes';
import { EndLease } from './pages/end-lease';
import { ErrorPage } from './pages/error-page';
import { MaintenanceDashboardV2 } from './pages/maintenanceV2';
import { PropertyDashboard } from './pages/properties';
import { VerifyUser } from './pages/verify-user';

const routeConfig = [
  {
    name: RouteName.Home,
    component: Home,
  },
  {
    name: RouteName.Properties,
    component: PropertyDashboard,
  },
  {
    name: RouteName.Maintenance,
    component: MaintenanceDashboardV2,
  },
  {
    name: RouteName.InitialRegister,
    component: InitialRegister,
  },
  {
    name: RouteName.Login,
    component: Login,
  },
  {
    name: RouteName.CreateProperties,
    component: CreateProperty,
  },
  {
    name: RouteName.RegisterTenant,
    component: RegisterTenant,
  },
  {
    name: RouteName.RegisterManager,
    component: RegisterManager,
  },
  {
    name: RouteName.MyProfile,
    component: MyProfile,
  },
  {
    name: RouteName.PasswordRecovery,
    component: PasswordRecovery,
  },
  {
    name: RouteName.ResetPassword,
    component: ResetPassword,
  },
  {
    name: RouteName.EditProfile,
    component: EditProfile,
  },
  {
    name: RouteName.TaskDetail,
    component: TaskDetailV2,
  },
  {
    name: RouteName.TaskDetailClaim,
    component: TaskDetailClaim,
  },
  {
    name: RouteName.PrivacyPolicy,
    component: PrivacyPolicy,
  },
  {
    name: RouteName.TermsOfUse,
    component: TermsOfUse,
  },
  {
    name: RouteName.PropertyDetail,
    component: PropertyDetail,
  },
  {
    name: RouteName.EditProperty,
    component: EditProperty,
  },
  {
    name: RouteName.UnitDetail,
    component: PropertyDetail,
  },
  {
    name: RouteName.HomeTenant,
    component: HomeTenant,
  },
  {
    name: RouteName.PaymentsTenant,
    component: PaymentsTenant,
  },
  {
    name: RouteName.EditUnit,
    component: EditUnit,
  },
  {
    name: RouteName.MyPropertyTenant,
    component: MyPropertyTenant,
  },
  {
    name: RouteName.BillsTable,
    component: BillsTableContainerV2,
  },
  {
    name: RouteName.Claims,
    component: Claims,
  },
  {
    name: RouteName.CreateTask,
    component: CreateTask,
  },
  {
    name: RouteName.CreateNewTask,
    component: CreateEditNewTask,
  },
  {
    name: RouteName.EditNewTask,
    component: CreateEditNewTask,
  },
  {
    name: RouteName.AssignContractor,
    component: AssignContractorV2,
  },
  {
    name: RouteName.CreateClaim,
    component: CreateClaim,
  },
  {
    name: RouteName.CreateClaimUnit,
    component: CreateClaim,
  },
  {
    name: RouteName.EditClaim,
    component: EditClaim,
  },
  {
    name: RouteName.ContractorTaskDetail,
    component: ContractorTaskDetail,
  },
  {
    name: RouteName.ClaimDetail,
    component: ClaimDetail,
  },
  {
    name: RouteName.RentalDashboard,
    component: RentalsDashboard,
  },
  {
    name: RouteName.RentalBuildingDashboard,
    component: RentalDashboardBuilding,
  },
  {
    name: RouteName.Leases,
    component: LeaseTable,
  },
  {
    name: RouteName.LeaseDetail,
    component: LeaseDetailed,
  },
  {
    name: RouteName.AddBill,
    component: AddBill,
  },
  {
    name: RouteName.EndLease,
    component: EndLease,
  },
  {
    name: RouteName.RenewLease,
    component: RenewLease,
  },
  {
    name: RouteName.CreateContractor,
    component: CreateEditContractor,
  },
  {
    name: RouteName.EditContractor,
    component: CreateEditContractor,
  },
  {
    name: RouteName.CreateUnit,
    component: CreateUnit,
  },
  {
    name: RouteName.SetAsRented,
    component: SetAsRented,
  },
  {
    name: RouteName.ExtendLease,
    component: ExtendLease,
  },
  {
    name: RouteName.EditRental,
    component: EditRental,
  },
  {
    name: RouteName.EditTask,
    component: EditTask,
  },
  {
    name: RouteName.ActivateAccount,
    component: ActivateAccount,
  },
  {
    name: RouteName.VerifyUser,
    component: VerifyUser,
  },
  {
    name: RouteName.VerifyAccount,
    component: VerifyAccount,
  },
  {
    name: RouteName.ExtendLeaseUnit,
    component: ExtendLease,
  },
  {
    name: RouteName.SetUnitAsRented,
    component: SetUnitsAsRented,
  },
  {
    name: RouteName.ManagerPaymentInformation,
    component: ManagerPaymentInformation,
  },
  {
    name: RouteName.DBAinformation,
    component: DBAinformation,
  },
  {
    name: RouteName.SalesProfile,
    component: SalesProfile,
  },
  {
    name: RouteName.ElectronicDebitCreditAuthorization,
    component: ElectronicDebitCreditAuthorization,
  },
  {
    name: RouteName.LegalBusinessInformation,
    component: LegalBusinessInformation,
  },
  {
    name: RouteName.OwnersOrOfficers,
    component: OwnersOrOfficers,
  },
  {
    name: RouteName.DocumentsInformation,
    component: DocumentsInformation,
  },
  {
    name: RouteName.MakePaymentPage,
    component: MakePaymentPage,
  },
  {
    name: RouteName.PrivateRequestDetail,
    component: PrivateRequestDetail,
  },
  {
    name: RouteName.PublicRequestDetail,
    component: PublicRequestDetail,
  },
  {
    name: RouteName.UnpublishedAndUnassignedTaskDetail,
    component: UnpublishedAndUnassignedTaskDetail,
  },
  {
    name: RouteName.ErrorPage,
    component: ErrorPage,
  },
];

export { routeConfig };
