import { ButtonClose } from '@mapix/common/src/common/button-close';
import { Stepper } from '@mapix/common/src/common/stepper';
import styles from './create.module.scss';

type CreateHeaderProps = {
  closeFn: () => void,
  title: string,
  stepper?: string[]
  currentStep?: number
  closeText: string,
};

const CreateHeader = ({
  closeFn, title, stepper = [], currentStep = 0, closeText,
}: CreateHeaderProps) => (
  <>
    <div className="row">
      {stepper.length > 0 && <Stepper steps={stepper} currentStep={currentStep} />}
      <div className={styles.marginLeft}>
        <ButtonClose closeFn={closeFn} closeText={closeText} />
      </div>
    </div>

    <div className="text__heading4__textNeutral50">{title}</div>
  </>
);

export { CreateHeader };
