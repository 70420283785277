import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@mapix/common/src/common/spinner';
import { CreateFooter } from 'common/creation';
import { logger } from 'helpers/logger';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { ModalResult } from 'common/modal-result';
import { ReactComponent as Tool } from 'assets/icons/tool.svg';
import { classnames, resolveAddress } from '@mapix/common/src/helpers/utils';
import { MaintenanceController } from 'networking/controllers/maintenance-controller';
import { goToPage, RouteName } from 'routes';
import { AssignContractorContainer } from 'common/assign-contractor-container-v2';
import { useParams } from 'react-router-dom';
import { ContractorController } from 'networking/controllers/contractor-controller';
import { getCityCommaProvinceId } from 'helpers/utils';
import styles from './assign-contractor.module.scss';

type ParamType = {
  taskId: string,
};

type AssignContractorData = {
  address: string,
  category: string,
  propertyId: number | '',
  contractorId: number | '',
  infoContractor: string,
  location: string,
};

const initAssignContractor: AssignContractorData = {
  address: '',
  category: '',
  propertyId: '',
  contractorId: '',
  infoContractor: '',
  location: '',
};

const AssignContractor = () => {
  const { taskId } = useParams<ParamType>();
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);
  const [assignContractorData, setAssignContractorData] = useState<
  AssignContractorData>(initAssignContractor);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { t } = useTranslation();

  const translPrefix = 'createTask';

  const onChangeContractor = (field: string, value: string) => {
    setAssignContractorData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const onSubmit = async () => {
    if (!assignContractorData.contractorId) {
      setShowErrorModal(true);
      return;
    }

    try {
      const assignContractor: AssignContractor = {
        contractorId: Number(assignContractorData.contractorId),
        contractorMessage: assignContractorData.infoContractor,
      };
      await ContractorController.assignContractor(Number(taskId), assignContractor);
      setShowSuccessModal(true);
    } catch (err: any) {
      logger.error(err);
      setError(true);
    }
  };

  const getTaskDetail = async () => {
    try {
      setFetching(true);
      const task = await MaintenanceController.getNewTask(taskId);
      const taskData: AssignContractorData = {
        address: resolveAddress(task.address),
        category: task.category,
        propertyId: task.property.id!,
        contractorId: '',
        infoContractor: '',
        location: getCityCommaProvinceId(task.address.city, task.address.provinceId),
      };
      setAssignContractorData(taskData);
      setFetching(false);
    } catch (err: any) {
      logger.error(err);
      setError(true);
    }
  };

  useEffect(() => {
    getTaskDetail();
  }, []);

  if (fetching || !assignContractorData) {
    return (<Spinner />);
  }

  return (
    <div className={styles.container}>
      {error
      && (
      <ErrorMessage
        handleClose={() => setError(false)}
        message={t('error.errorMessage')}
      />
      )}
      { (showErrorModal && (
      <ModalResult
        title={t(`${translPrefix}.modalAssignError.title`)}
        subtitle={t(`${translPrefix}.modalAssignError.subtitle`)}
        Icon={Tool}
        iconStyle={styles.iconStyle}
        buttonTextRight={t(`${translPrefix}.modalButton`)}
        handleButtonRight={() => setShowErrorModal(false)}
        handleButtonClose={() => setShowErrorModal(false)}
      />
      ))}
      { (showSuccessModal) && (
      <ModalResult
        title={t(`${translPrefix}.modalTitleEdit`)}
        subtitle={t(`${translPrefix}.modalContractorAssigned`)}
        Icon={Tool}
        withCheckIcon
        buttonTextRight={t(`${translPrefix}.modalButton`)}
        handleButtonRight={() => goToPage(RouteName.Maintenance)}
        handleButtonClose={() => goToPage(RouteName.Maintenance)}
      />
      )}
      <div className="text__heading4__textNeutral50">
        {t(`${translPrefix}.assignAnotherContractor`)}
      </div>
      <div className={classnames(styles.addressSubtitle, 'text__heading5__textNeutral50')}>
        {assignContractorData.address}
      </div>
      <AssignContractorContainer
        category={assignContractorData.category}
        propertyId={Number(assignContractorData.propertyId)}
        onChange={onChangeContractor}
        infoContractor={assignContractorData.infoContractor}
        location={assignContractorData.location}

      />
      <CreateFooter
        nextName={t('createTask.footer.assignTask')}
        Icon={null}
        secondButtonName={t('createTask.footer.cancel')}
        nextFn={onSubmit}
        secondButtonFn={() => (
          goToPage(RouteName.UnpublishedAndUnassignedTaskDetail, { id: taskId })
        )}
      />
    </div>
  );
};

export { AssignContractor };
