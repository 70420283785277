import { MaintenanceController } from 'networking/controllers/maintenance-controller';
import { Spinner } from '@mapix/common/src/common/spinner';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { NewTask } from 'networking/types';
import { ErrorType } from '@mapix/common/src/common/error-page';
import { logger } from 'helpers/logger';
import { RouteName, goToPage } from 'routes';
import { useTranslation } from 'react-i18next';
import { UnassignedTaskDetailRow } from 'common/new-task-detail/tabs/status/unassigned-task-detail-row';
import { TaskViewTab } from 'common/new-task-detail/tabs/task-view';
import { NewTaskDetail, TaskDetailTabs } from 'common/new-task-detail';
import { TaskDatesOverview } from '@mapix/common/src/common/task-dates-overview';
import { ModalInformation } from '@mapix/common/src/common/modal-information';
import { ButtonStyle } from '@mapix/common/src/common/button';
import { RawNewTaskStatus, TaskStatus } from '@mapix/common/src/common/enums';
import { parseTaskStatus } from 'helpers/utils';
import { ApplicationsTab } from 'common/new-task-detail/tabs/applications/applications-tab';
import { MarkAsSolvedModal } from './mark-as-solved-modal';
import styles from './unpublished-and-unassigned-task-detail.module.scss';

type ParamType = {
  id: string,
};

enum ModalState {
  None = 'none',
  ReviewEdit = 'reviewEdit',
  AssignContractor = 'assignContractor',
  MarkAsSolved = 'markedAsSolved',
}

const translPrefix = 'newTaskDetail';

const UnpublishedAndUnassignedTaskDetail = () => {
  const [fetching, setFetching] = useState(true);
  const [newTask, setNewTask] = useState<NewTask>({} as NewTask);
  const [currentTab, setCurrentTab] = useState<string>(TaskDetailTabs.Status);
  const [modalState, setModalState] = useState<ModalState>(ModalState.None);

  const { id } = useParams<ParamType>();

  const { t, i18n } = useTranslation();

  const isTaskUnpublihedOrUnassigned = (status: RawNewTaskStatus) => (
    parseTaskStatus(status) === TaskStatus.UNPUBLISHED // private task
    || parseTaskStatus(status) === TaskStatus.UNASSIGNED // private task
    || parseTaskStatus(status) === TaskStatus.RECEIVING_APPLICATIONS // public task
  );

  const getTask = async () => {
    try {
      const actualTask = await MaintenanceController.getNewTask(id);
      setNewTask(actualTask);

      if (!isTaskUnpublihedOrUnassigned(actualTask.status)) {
        goToPage(RouteName.ErrorPage);
      }

      setFetching(false);
    } catch (err: any) {
      logger.log(err);
      goToPage(RouteName.ErrorPage, {}, { code: ErrorType.ServerError });
    }
  };

  useEffect(() => {
    getTask();
  }, []);

  if (fetching) {
    return <Spinner />;
  }

  const getContent = () => {
    switch (currentTab) {
      case TaskDetailTabs.Status:
        return (
          <div className={styles.tabsContainer}>
            <UnassignedTaskDetailRow
              newTask={newTask}
              translPrefix={`${translPrefix}.tabs.status`}
              t={t}
            />
            <hr className={styles.divider} />
            <TaskDatesOverview
              dates={{ created: newTask.createdAt }}
              translPrefix={`${translPrefix}.tabs.status`}
              t={t}
              language={i18n.language}
            />
          </div>
        );

      case TaskDetailTabs.TaskView:
        return (
          <TaskViewTab
            className={styles.tabsContainer}
            task={newTask}
            translPrefix={`${translPrefix}.tabs.taskView`}
            t={t}
          />
        );
      case TaskDetailTabs.Applications:
        return (
          <ApplicationsTab
            taskId={newTask.id}
            refreshTask={getTask}
          />
        );
      default:
        return null;
    }
  };

  const getTextButtonLeftByTaskStatus = (status: RawNewTaskStatus) => {
    if (parseTaskStatus(status) === TaskStatus.UNPUBLISHED) {
      return t(`${translPrefix}.footer.publishTask`);
    }

    if (parseTaskStatus(status) === TaskStatus.UNASSIGNED) {
      return t(`${translPrefix}.footer.assignContractor`);
    }

    if (parseTaskStatus(status) === TaskStatus.RECEIVING_APPLICATIONS) {
      return '';
    }

    return '';
  };

  const getLeftActionByTaskStatus = (status: RawNewTaskStatus) => {
    if (parseTaskStatus(status) === TaskStatus.UNPUBLISHED) {
      setModalState(ModalState.ReviewEdit);
    } else {
      goToPage(RouteName.AssignContractor, { taskId: Number(newTask.id) });
    }
  };

  const getTextButtonRightByTaskStatus = (status: RawNewTaskStatus) => {
    if (parseTaskStatus(status) === TaskStatus.UNPUBLISHED) {
      return t(`${translPrefix}.footer.markAsSolved`);
    }

    return '';
  };

  const getRightActionByTaskStatus = (status: RawNewTaskStatus) => {
    if (parseTaskStatus(status) === TaskStatus.UNPUBLISHED) {
      // TODO: mark as solved action
    }
  };

  const showAlert = (option: string) => (option === TaskDetailTabs.Applications
    && newTask.status === RawNewTaskStatus.RECEIVING_APPLICATIONS
    && newTask.applications > 0
  );

  return (
    <>
      <NewTaskDetail
        currentTab={currentTab as TaskDetailTabs}
        newTask={newTask}
        getTabContent={getContent}
        setCurrentTab={setCurrentTab}
        translPrefix={translPrefix}
        textButtonRight={getTextButtonRightByTaskStatus(newTask.status)}
        textButtonLeft={getTextButtonLeftByTaskStatus(newTask.status)}
        leftButtonFn={() => getLeftActionByTaskStatus(newTask.status)}
        rightButtonFn={() => getRightActionByTaskStatus(newTask.status)}
        showAlert={showAlert}
      />

      {modalState === ModalState.ReviewEdit && (
      <ModalInformation
        handleButtonClose={() => setModalState(ModalState.None)}
        handleButtonRight={() => goToPage(RouteName.EditNewTask, { id })}
        handleButtonCenter={modalState === ModalState.ReviewEdit
          ? () => setModalState(ModalState.AssignContractor)
          : () => {}}
        title={t(`${translPrefix}.publishTaskModal.title`)}
        body={t(`${translPrefix}.publishTaskModal.reviewOrEditTask`)}
        buttonTextCenter={t('no')}
        buttonTextRight={t('yes')}
        containerClass={styles.modalContainer}
        buttonStyleCenter={ButtonStyle.Secondary}
        buttonsWrapperClass={styles.buttonsWrapperSlim}
      />

      )}
      {modalState === ModalState.AssignContractor && (
      <ModalInformation
        handleButtonClose={() => setModalState(ModalState.None)}
        handleButtonRight={() => {}}
        handleButtonCenter={() => {}}
        title={t(`${translPrefix}.publishTaskModal.title`)}
        body={t(`${translPrefix}.publishTaskModal.assignContractor`)}
        buttonTextCenter={t('taskType.private_task')}
        buttonTextRight={t('taskType.public_task')}
        containerClass={styles.modalContainer}
        buttonsWrapperClass={styles.buttonsWrapper}
        buttonStyleCenter={ButtonStyle.Secondary}
      />

      )}
      {modalState === ModalState.MarkAsSolved && (
      <MarkAsSolvedModal
        translPrefix={translPrefix}
        handleButtonClose={() => setModalState(ModalState.None)}
      />
      )}
    </>
  );
};

export { UnpublishedAndUnassignedTaskDetail };
