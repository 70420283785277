import { RoleSelection } from 'common/role-selection';
import { goToPage, RouteName } from 'routes';

const InitialRegister = () => {
  const handleClickTenant = () => {
    goToPage(RouteName.RegisterTenant);
  };
  const handleClickManager = () => {
    goToPage(RouteName.RegisterManager);
  };

  return (
    <RoleSelection
      handleClickManager={handleClickManager}
      handleClickTenant={handleClickTenant}
    />
  );
};

export { InitialRegister };
