import { PropertyAddress } from 'models/property-address';
import { PropertyOwner } from 'models/property-owner';
import { Contractor } from 'models/contractor';
import { Unit } from 'models/unit';
import { Lease } from 'models/lease';
import { getFullAddress } from 'helpers/utils';

class DetailedDraftProperty {
  id: number;

  type: string;

  condition: string;

  buildingName: string;

  status?: string;

  address: PropertyAddress;

  owner: PropertyOwner;

  otherOwner: PropertyOwner;

  contractors: Contractor[];

  units: Unit[];

  lease?: Lease;

  unitsCount: number;

  fullAddress: string;

  occupancyRate?: number;

  constructor(params: SerializedDetailedProperty) {
    this.id = params.id;
    this.type = params.type;
    this.condition = params.condition || '';
    this.buildingName = params.buildingName || '';
    this.address = params.address ? new PropertyAddress(params.address) : new PropertyAddress();
    this.owner = params.owner ? new PropertyOwner(params.owner) : new PropertyOwner(null);
    this.otherOwner = params.otherOwner ? new PropertyOwner(params.otherOwner)
      : new PropertyOwner(null);
    this.contractors = params.contractors
      ? params.contractors.map((contractor) => new Contractor(contractor)) : [];
    this.units = params.units ? params.units.map((unit) => new Unit(unit)) : [];
    this.lease = params.lease ? new Lease(params.lease) : undefined;
    this.unitsCount = params.unitsCount || 0;
    this.status = params.status;

    this.fullAddress = getFullAddress(this.address);
  }
}

export { DetailedDraftProperty };
