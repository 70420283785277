class MaintenanceUnitSerializer {
  static deSerialize(data: RawTaskUnit): SerializedTaskUnit {
    return {
      id: data.id,
      unitNumber: data.unit_number,
      status: data.status,
      unitPhotos: data.unit_photos,
    };
  }
}

export { MaintenanceUnitSerializer };
