import { getFullAddress } from 'helpers/utils';
import { PropertyOwner } from 'models/property-owner';

class RentalDashboard {
  address: string;

  landlord: string;

  tenant: string;

  rentalAmount: number;

  dueDate: string;

  leaseExpirationDate: string;

  paidOn?: string;

  status: string;

  unit: string;

  id: number;

  currency: string;

  billedAmount: string;

  fees: string;

  rawRental: string;

  manuallyPaid: boolean;

  fullMonth: boolean;

  extensionBorderRental: boolean;

  constructor(params: SerializedRental) {
    this.address = getFullAddress(params.address);
    this.landlord = new PropertyOwner(params.owner).name;
    this.tenant = new PropertyOwner(params.tenant).name;
    this.rentalAmount = params.rentalAmount;
    this.dueDate = params.dueDate;
    this.leaseExpirationDate = params.leaseExpirationDate;
    this.paidOn = params.paymentDate || '-';
    this.status = params.status;
    this.unit = String(params.unitNumber || '-');
    this.id = params.id;
    this.currency = params.currency;
    this.fees = `${this.currency} ${params.fees}`;
    this.rawRental = `${this.currency} ${params.rentalAmount - params.fees}`;
    this.billedAmount = `${this.currency} ${this.rentalAmount}`;
    this.manuallyPaid = params.manuallyPaid;
    this.fullMonth = params.fullMonth;
    this.extensionBorderRental = params.extensionBorderRental;
  }
}

export { RentalDashboard };
