import { Breakpoints } from 'common/enums';
import { classnames } from '../../helpers/utils';
import { AvatarOrInitials } from '../avatar-or-initials';
import styles from './simple-message.module.scss';
import { useMediaQuery } from '../../hooks/use-media-query';

export type MessageProps = {
  text: string,
  senderName?: string,
  senderLastName?: string,
  senderUrl?: string,
  containerClass?: string,
  hideAvatar?: boolean;
  textClass?: string;
  title?: string;
  titleClass?: string;
  messageClass?: string;
};

const SimpleMessage = ({
  text, hideAvatar = false, senderName = '', senderLastName = '', senderUrl, containerClass = '',
  textClass = '', title, titleClass = '', messageClass = '',
}: MessageProps) => {
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  return (
    <div className={containerClass}>
      {title && (
        <div className={classnames(titleClass, `${mobile
          ? 'text__body__large__textNeutral50' : 'text__body__medium__textNeutral50'} mb-8`)}
        >
          {title}
        </div>
      )}
      <div className={classnames(styles.messageContainer, messageClass)}>

        {!hideAvatar && (
        <AvatarOrInitials
          firstName={senderName}
          lastName={senderLastName}
          className={styles.avatar}
          url={senderUrl}
        />
        )}

        <div className={classnames(styles.body, 'text__body__small__textNeutral40', textClass)}>
          {text}
        </div>
      </div>
    </div>

  );
};

export { SimpleMessage };
