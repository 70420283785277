import { FormEvent, SetStateAction } from 'react';
import type { PaymentInformationState } from '../../../types';
import { asciiWithoutSomeSpecialCharacters, HTMLValidationError, postalCodeRegex } from '../../../helpers/utils';
import { PaymentInformationTemplate } from '../../payment-information-template';
import { PaymentInformationFormGenerator, Restrictions, SpecialInputs } from '../payment-information-form-generator';
import { Select } from '../../select';

const translPrefix = 'paymentInformation.ownersOrOfficersInfo';

const helperTextsFn = (t: (text: string) => string) => ({
  dateOfBirth: t(`${translPrefix}.helperTexts.dateOfBirth`),
  postalCode: t(`${translPrefix}.helperTexts.postalCode`),
});

const restrictions = (): Restrictions => ({
  name: {
    // ^[a-zA-Z\s-]+$
    regexp: /^[a-zA-Z\s-]+$/,
    maxLength: 100,
  },
  lastName: {
    maxLength: 100,
    regexp: asciiWithoutSomeSpecialCharacters(),
  },
  percentOwnership: {
    // ^(100|100\.0*|(\d{0,2}(\.\d+)?))$
    maxLength: 15,
    regexp: /^(100|100\.0*|(\d{0,2}(\.\d+)?))$/,
  },
  email: {
    maxLength: 100,
  },
  civicNumber: {
    // ^([0-9]).*$
    regexp: /^([0-9]).*$/,
    maxLength: 100,
  },
  city: {
    maxLength: 30,
  },
  postalCode: {
    maxLength: 15,
    // [a-zA-Z][0-9][a-zA-Z]\ [0-9][a-zA-Z][0-9]
    regexp: postalCodeRegex(),
  },
  sin: {
    // ^\d{9}$
    regexp: /^\d{9}$/,
  },
  driversLic: {
    maxLength: 30,
  },
});

const specialInputs = () => ({
  dateOfBirth: {
    type: SpecialInputs.DATE,
  },
});
const optionalInputsFn = () => ({
  address: true,
  unit: true,
  driversLic: true,
  driversLicProvince: true,
});

type OwnersOrOfficersProps = {
  t: (text: string) => string,
  backFn: () => void,
  ownersOrOfficers: PaymentInformationState,
  setOwnersOrOfficers: any,
  formErrors: HTMLValidationError,
  validatePhone: (phoneNumber: string, countryCode: string) => Promise<RawPhoneValidation>
  setPhoneErrors: (value: SetStateAction<HTMLValidationError>) => void,
  setFormErrors: (value: SetStateAction<HTMLValidationError>) => void,
  onSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>,
  selectOptions: { [key: string]: string[] },
  addressSameAs: string,
  handleSelectAddressSameAs: (option: string) => void,
  goToHomeFn: () => void,
  goToMyProfileFn: () => void,
  goToPaymentInformationFn: () => void,
};

const noTranslation = { province: true, driversLicProvince: true };

const OwnersOrOfficers = ({
  t, backFn, ownersOrOfficers, setOwnersOrOfficers, formErrors, validatePhone, setPhoneErrors,
  setFormErrors, onSubmit, selectOptions, addressSameAs, handleSelectAddressSameAs,
  goToHomeFn, goToMyProfileFn, goToPaymentInformationFn,
}: OwnersOrOfficersProps) => (
  <PaymentInformationTemplate
    t={t}
    backFn={backFn}
    title={t(`${translPrefix}.title`)}
    onSubmit={onSubmit}
    goToHomeFn={goToHomeFn}
    goToMyProfileFn={goToMyProfileFn}
    goToPaymentInformationFn={goToPaymentInformationFn}
  >

    <Select
      id="address"
      onClickOption={(option) => handleSelectAddressSameAs(option)}
      value={addressSameAs ? t(`${translPrefix}.selectOptions.address.${addressSameAs}`) : ''}
      options={selectOptions.address}
      placeholder={t(`${translPrefix}.placeholders.address`)}
      label={t(`${translPrefix}.placeholders.address`)}
      helperText={t('selectOption')}
      formError={formErrors}
      translationPrefix={`${translPrefix}.selectOptions.address`}
      t={t}
    />

    <PaymentInformationFormGenerator
      t={t}
      state={ownersOrOfficers}
      selectOptions={selectOptions}
      translPrefix={translPrefix}
      setState={setOwnersOrOfficers}
      formErrors={formErrors}
      validatePhone={validatePhone}
      setPhoneErrors={setPhoneErrors}
      optionalInputs={optionalInputsFn()}
      helpertexts={helperTextsFn(t)}
      specialInputs={specialInputs()}
      restrictions={restrictions()}
      setFormErrors={setFormErrors}
      noTranslation={noTranslation}
    />

  </PaymentInformationTemplate>
);

export { OwnersOrOfficers };
