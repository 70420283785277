import {
  ChangeEvent, MouseEventHandler, useEffect, useReducer, useState,
} from 'react';
import { addOrRemoveFromArrayObject, classnames } from '@mapix/common/src/helpers/utils';
import { getFiltersByUrl, groupFilters } from 'helpers/utils';
import { Tab } from '@mapix/common/src/common/tab';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { ReactComponent as Filter } from 'assets/icons/filter.svg';
import { ReactComponent as PlusCircle } from 'assets/icons/plus-circle.svg';
import { ReactComponent as PropertyIcon } from 'assets/icons/property.svg';
import { ReactComponent as NoResultsIcon } from 'assets/icons/search-no-results-solid.svg';
import { Table } from 'common/table';
import { logger } from 'helpers/logger';
import { PropertyController } from 'networking/controllers/property-controller';
import { Pagination } from '@mapix/common/src/common/paginator';
import { Spinner } from '@mapix/common/src/common/spinner';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as Archive } from 'assets/icons/archive.svg';
import { MoreIcon } from 'common/more-icon';
import { Modal } from '@mapix/common/src/common/modal';
import { FilterModal } from 'common/filter-modal';
import { ModalFilterItem } from 'common/filter-modal/filter-modal';
import { FiltersApplied } from 'common/filters-applied';
import { goToPage, RouteName } from 'routes';
import { UserData } from 'pages/user-data';
import { ModalResult } from 'common/modal-result';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { PropertyTableItem } from 'models/property-table-item';
import { Condition, PropertyStatus, PropertyType } from 'common/enums';
import { EmptyState } from 'common/empty-state';
import { ReactComponent as Trash } from 'assets/icons/thrash.svg';
import styles from './property-table.module.scss';
import { initialState, PropertyTableReducer, PropertyTabs } from './property-table-reducer';

type PropertyArchive = {
  id?: number,
  type?: string,
  address?: SerializedPropertyAddress,
  lease?: SerializedLease,
  status?: string,
};

type ModalState = {
  showArchive: boolean,
  showPropertyArchive: boolean
  showUnarchive: boolean,
  showActivated: boolean,
  showDeleteDraft: boolean,
};

const initialModalState = {
  showArchive: false,
  showPropertyArchive: false,
  showUnarchive: false,
  showActivated: false,
  showDeleteDraft: false,
};

const translPrefix = 'propertyDashboard';
const PropertyTable = () => {
  const params = new URLSearchParams(window.location.search);
  const [state, dispatch] = useReducer(
    PropertyTableReducer,
    initialState(getFiltersByUrl(params.toString())),
  );

  const [modalState, setModalState] = useState<ModalState>(initialModalState);

  const [showError, setShowError] = useState(false);
  const [property, setProperty] = useState<PropertyArchive>();
  const [fetching, setFetching] = useState(false);
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const [showUserData, setShowUserData] = useState(false);

  const iconsOptionsArchived = [t(`${translPrefix}.options.unarchive`)];
  const iconsOptionsActiveCondo = [t(`${translPrefix}.options.edit`), t(`${translPrefix}.options.archive`)];
  const iconsOptionsActiveBuilding = [t(`${translPrefix}.options.edit`)];
  const iconsOptionsDraft = [t(`${translPrefix}.options.delete`), t(`${translPrefix}.options.edit`)];

  useEffect(() => {
    if (urlParams.get('showModal')) {
      setShowUserData(true);
    } else {
      setShowUserData(false);
    }
  }, [urlParams.get('showModal')]);

  const detailOrEdit = (item: PropertyTableItem) => {
    if (item.id) {
      if (item.condition === Condition.Draft) {
        goToPage(RouteName.CreateProperties, {}, { id: item.id });
      } else {
        goToPage(RouteName.PropertyDetail, { id: item.id });
      }
    }
  };

  const getMoreIcon = (options: string[], optionsFn: MouseEventHandler<HTMLButtonElement>[]) => (
    <MoreIcon iconClass={styles.icons} key="more-icon" options={options} optionsFn={optionsFn} />
  );

  const getIcons = (item: PropertyTableItem) => {
    const ChevronRightComponent = (
      <ChevronRight
        className={styles.icons}
        key="chevron"
        onClick={() => detailOrEdit(item)}
      />
    );

    /* TODO missing implementation */
    const iconsFnActiveCondo = [() => goToPage(
      RouteName.EditProperty,
      { id: item && item.id ? item.id : null },
    ),
    () => {
      setProperty((prevState) => ({ ...prevState, lease: item.lease, status: item.status }));
      setModalState((prevState) => ({ ...prevState, showArchive: true }));
    }];
    const iconsFnActiveBuilding = [() => goToPage(
      RouteName.EditProperty,
      { id: item && item.id ? item.id : null },
    )];
    const iconsFnArchived = [
      () => setModalState((prevState) => ({ ...prevState, showUnarchive: true }))];
    const iconsFnDraft = [
      () => setModalState(((prevState) => ({ ...prevState, showDeleteDraft: true }))),
      () => goToPage(RouteName.CreateProperties, {}, { id: item && item.id ? item.id : null }),
    ];

    if (item.condition !== 'undefined') {
      const icons = [ChevronRightComponent];
      // if the condition is active, it means that i wanna show the 'archive' option, otherwise not
      if (item.condition === 'active') {
        if (item.type === PropertyType.Building) {
          icons.unshift(getMoreIcon(iconsOptionsActiveBuilding, iconsFnActiveBuilding));
        } else {
          icons.unshift(getMoreIcon(iconsOptionsActiveCondo, iconsFnActiveCondo));
        }
      }

      if (item.condition === 'archived') {
        icons.unshift(getMoreIcon(iconsOptionsArchived, iconsFnArchived));
      }

      if (item.condition === 'draft') {
        icons.unshift(getMoreIcon(iconsOptionsDraft, iconsFnDraft));
      }
      return icons;
    }
    return [];
  };

  const getProperties = async () => {
    try {
      setFetching(true);
      const response = await PropertyController
        .getProperties(
          state.paginator?.currentPage || 1,
          10,
          state.currentTab,
          state.query,
          state.appliedFilters,
        );
      setFetching(false);
      dispatch({ type: 'PROPERTIES_FETCHED', paginator: response });
    } catch (err) {
      setFetching(false);
      logger.error(err as Error);
    }
  };

  useEffect(() => {
    if (!modalState.showDeleteDraft) {
      getProperties();
    }
  }, [state.currentTab, state.paginator?.currentPage, state.query, state.appliedFilters,
    modalState.showDeleteDraft]);

  const clickTab = (option: string) => {
    groupFilters(option, undefined, []);
    dispatch({ type: 'TAB_CHANGED', newTab: option });
  };

  const clickPagination = (nextPage: number) => {
    if (nextPage !== state.paginator.currentPage) {
      dispatch({ type: 'PAGE_CHANGED', newPage: nextPage });
    }
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 2 || e.target.value === '') {
      dispatch({ type: 'SEARCH_QUERY', query: e.target.value });
    }
    dispatch({ type: 'INPUT_CHANGED', input: e.target.value });
  };

  const showModal = () => {
    dispatch({ type: 'MODAL_VISIBILITY', show: !state.show });
  };

  const applyFilters = (updatedFilters: ModalFilterItem[]) => {
    groupFilters(state.currentTab, undefined, updatedFilters);
    dispatch({ type: 'APPLY_FILTERS', filters: updatedFilters });
  };

  const clearFilters = () => {
    groupFilters(state.currentTab, undefined, []);
    dispatch({ type: 'CLEAR_FILTERS' });
  };

  const removeFilter = (filter: ModalFilterItem) => {
    const updatedFilters = addOrRemoveFromArrayObject(state.appliedFilters, filter, 'code');
    groupFilters(state.currentTab, undefined, updatedFilters);
    dispatch({ type: 'APPLY_FILTERS', filters: updatedFilters });
  };

  const setIdProperty = (id: number) => {
    setProperty((prevState) => ({ ...prevState, id }));
  };

  const archiveProperty = async () => {
    if (property?.status === PropertyStatus.Available) {
      setModalState((prevState) => ({ ...prevState, showArchive: false }));
      if (property?.id) {
        try {
          setFetching(true);
          const result = await PropertyController.archiveProperty(property.id);
          const newProperty = {
            ...property,
            id: result.id,
            type: result.type,
            address: result.address,
            lease: result.lease,
            status: result.status,
          };
          setProperty(newProperty);
          setFetching(false);
          setModalState((prevState) => ({ ...prevState, showPropertyArchive: true }));
          getProperties();
        } catch (error: any) {
          setFetching(false);
          setShowError(true);
        }
      }
    } else {
      goToPage(RouteName.EndLease, { id: Number(property?.lease?.id) }, { endAndArchive: true });
    }
  };

  const unarchiveProperty = async () => {
    setModalState((prevState) => ({ ...prevState, showUnarchive: false }));
    if (property?.id) {
      try {
        setFetching(true);
        const result = await PropertyController.unarchiveProperty(property.id);
        const newProperty = {
          ...property,
          id: result.id,
          type: result.type,
          address: result.address,
          status: result.status,
        };
        setProperty(newProperty);
        setFetching(false);
        getProperties();
        setModalState((prevState) => ({ ...prevState, showActivated: true }));
      } catch (error: any) {
        setFetching(false);
        setShowError(true);
      }
    }
  };

  const deleteDraft = async () => {
    if (property?.id) {
      try {
        await PropertyController.deleteDraftProperty(property.id);
        setModalState((prevState) => ({ ...prevState, showDeleteDraft: false }));
      } catch (err: any) {
        logger.error(err);
        setShowError(true);
      }
    }
  };

  const isDraft = (item: PropertyTableItem) => {
    if (item.condition === Condition.Draft) {
      return 'text__body__small__textNeutral30';
    }
    return 'text__body__small__textNeutral40';
  };

  const getTable = () => {
    if (!state.paginator || state.code === 'FETCHING' || fetching) {
      return (
        <div className={styles.spinner}><Spinner fixed={false} /></div>
      );
    }

    if (state.paginator.results.length === 0 && (state.appliedFilters.length > 0
    || state.input)) {
      return (
        <div className={styles.emptyState}>
          <EmptyState
            Icon={NoResultsIcon}
            title={t('emptyState.property.withFilters.title')}
            subtitle={t('emptyState.property.withFilters.subtitle')}
            handleOnClick={clearFilters}
          />
        </div>
      );
    }
    if (state.paginator.results.length === 0) {
      return (
        <div className={styles.emptyState}>
          <EmptyState
            Icon={PropertyIcon}
            title={t('emptyState.property.title')}
            subtitle={t('emptyState.property.subtitle')}
            handleOnClick={() => goToPage(RouteName.CreateProperties)}
            withPlusIcon
          />
        </div>
      );
    }

    return (
      <>
        <Table
          textClassFn={isDraft}
          headerNames={state.data.dataProperties}
          data={state.paginator.results}
          dataProperties={state.data.dataProperties}
          dashboardName="propertyDashboard"
          iconCell={getIcons}
          uniqueId="id"
          setId={setIdProperty}
          rowClick={detailOrEdit}
        />

        {state.paginator.lastPage > 1
                && (
                  <div className={styles.paginationContainer}>
                    <Pagination
                      pageLimit={state.paginator.lastPage}
                      onClickFn={(newPage: number) => clickPagination(newPage)}
                      currentPage={state.paginator.currentPage}
                    />
                  </div>
                )}
      </>
    );
  };

  return (
    <>
      {showError
      && <ErrorMessage message={t('error.errorMessage')} handleClose={() => setShowError(false)} />}
      {showUserData && (
      <Modal>
        <UserData save={() => goToPage(RouteName.Properties)} />
      </Modal>
      )}
      <div className={styles.dashboardContainer}>
        {modalState.showArchive && (
        <ModalResult
          title={t('archive.archiveThis')}
          subtitle={property?.status === PropertyStatus.Available ? t('archive.information') : t('archive.rentedPropertyInformation')}
          buttonTextLeft={t('archive.cancel')}
          buttonTextRight={property?.status === PropertyStatus.Available ? t('archive.yesArchive') : t('archive.archiveAndEndLease')}
          handleButtonLeft={() => setModalState((prevState) => (
            { ...prevState, showArchive: false }))}
          handleButtonRight={() => archiveProperty()}
          handleButtonClose={() => setModalState((prevState) => (
            { ...prevState, showArchive: false }))}
          Icon={Archive}
          iconStyle={styles.archiveIconBlue}
        />
        )}
        {modalState.showPropertyArchive && (
        <ModalResult
          title={t('archive.propertyArchived')}
          subtitle={`${property?.type} - ${property?.address?.streetName}, ${property?.address?.city}, 
          ${property?.address?.province}, ${property?.address?.zipCode}`}
          buttonTextRight={t('archive.ok')}
          handleButtonRight={() => setModalState((prevState) => (
            { ...prevState, showPropertyArchive: false }))}
          handleButtonClose={() => setModalState((prevState) => (
            { ...prevState, showPropertyArchive: false }))}
          withCheckIcon
          Icon={Archive}
        />
        )}
        {modalState.showUnarchive && (
        <ModalResult
          title={t('unarchive.title')}
          subtitle={t('unarchive.subtitle')}
          buttonTextLeft={t('unarchive.cancel')}
          buttonTextRight={t('unarchive.yesUnarchive')}
          handleButtonLeft={() => setModalState((prevState) => (
            { ...prevState, showUnarchive: false }))}
          handleButtonRight={() => unarchiveProperty()}
          handleButtonClose={() => setModalState((prevState) => (
            { ...prevState, showUnarchive: false }))}
          Icon={Archive}
          iconStyle={styles.archiveIconBlue}
        />
        )}
        {modalState.showActivated && (
        <ModalResult
          title={t('unarchive.activeModal.title')}
          subtitle={`${property?.type} - ${property?.address?.streetName}, ${property?.address?.city}, 
          ${property?.address?.province}, ${property?.address?.zipCode}`}
          buttonTextRight={t('unarchive.activeModal.ok')}
          handleButtonRight={() => setModalState((prevState) => (
            { ...prevState, showActivated: false }))}
          handleButtonClose={() => setModalState((prevState) => (
            { ...prevState, showActivated: false }))}
          withCheckIcon
          Icon={Archive}
        />
        )}

        {modalState.showDeleteDraft && (
          <ModalResult
            title={t(`${translPrefix}.deleteDraft.title`)}
            Icon={Trash}
            iconStyle={styles.iconDelete}
            buttonTextRight={t(`${translPrefix}.deleteDraft.delete`)}
            handleButtonRight={deleteDraft}
            buttonTextLeft={t(`${translPrefix}.deleteDraft.cancel`)}
            handleButtonLeft={() => setModalState(
              (prevState) => ({ ...prevState, showDeleteDraft: false }),
            )}
            handleButtonClose={() => setModalState(
              (prevState) => ({ ...prevState, showDeleteDraft: false }),
            )}
          />
        )}

        {state.show && (
        <Modal>
          <FilterModal
            appliedFilters={state.appliedFilters}
            filters={state.data.filters}
            closeFn={showModal}
            applyFn={applyFilters}
            prefix="propertyDashboard"
          />
        </Modal>
        )}

        <div className="text__heading4__textNeutral40">
          {t('propertyDashboard.title')}
        </div>
        <p className={classnames(styles.subtitle, 'text__body__small__textNeutral30')}>
          {t('propertyDashboard.subTitle')}
        </p>

        <div className="row">
          {Object.entries(PropertyTabs).map(([k, v]) => (
            <div key={k} className={styles.tabContainer}>
              <Tab
                onClickFn={() => clickTab(k)}
                name={t(`propertyDashboard.tabs.${v.toLowerCase()}`)}
                isActive={state.currentTab === k}
              />
            </div>
          ))}
        </div>

        <div className={classnames(styles.filterRow, 'row fullWidth')}>
          <Input
            id="search"
            containerClass={styles.input}
            inputStyle={InputStyle.REGULAR}
            withSearchIcon
            placeholder={t(state.data.placeholderText)}
            value={state.input}
            onChange={onChangeInput}
            t={t}
          />

          <Button onClick={() => showModal()} buttonStyle={ButtonStyle.Secondary}>
            <div className="row justify-between">
              <div className="text__button__medium__primary60">
                {t('propertyDashboard.buttons.filter')}
              </div>
              <Filter className={styles.filterIcon} />
            </div>
          </Button>

          {state.appliedFilters.length > 0
        && (
        <FiltersApplied
          filters={state.appliedFilters}
          clearFn={clearFilters}
          removeOneFilterFn={removeFilter}
          prefix="propertyDashboard"
        />
        )}

          <Button
            onClick={() => goToPage(RouteName.CreateProperties)}
            className={styles.propertyButton}
            buttonStyle={ButtonStyle.Primary}
          >
            <div className="row align-justify-center">
              <PlusCircle className={styles.plusIcon} />
              {t('propertyDashboard.buttons.addProperty')}
            </div>
          </Button>
        </div>
        {getTable()}
      </div>
    </>
  );
};

export { PropertyTable };
