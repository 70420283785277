import React,
{
  ChangeEvent, useState,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'common/table';
import { classnames } from '@mapix/common/src/helpers/utils';
import { PaginatorContainer } from '@mapix/common/src/types';
import { TextArea } from '@mapix/common/src/common/textarea';
import { logger } from 'helpers/logger';
import { ContractorSpecialization } from '@mapix/common/src/types/specialization';
import { Pagination } from '@mapix/common/src/common/paginator';
import { AvatarOrInitials } from '@mapix/common/src/common/avatar-or-initials';
import { RadioButtonMobile } from '@mapix/common/src/common/radio-button-mobile';
import { ContractorCategoryIcon } from '@mapix/common/src/common/contractor-category-icon';
import { Input } from '@mapix/common/src/common/input';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { ContractorController } from 'networking/controllers/contractor-controller';
import { Spinner } from '@mapix/common/src/common/spinner';
import styles from './assign-contractor-container.module.scss';

const MAX_CATEGORIES_DISPLAYED = 4;

const translPrefix = 'createTask';

const headers = ['', 'name', 'location', 'categories'];
const contractorProperties = ['name', 'location', 'categories'];

type ContractorTableDisplay = {
  name: JSX.Element,
  location: string,
  categories: JSX.Element,
  id: number,
};

const resolveLastCharecters = (length: number, index: number, t: (text: string) => string) => {
  if (index < Math.min(MAX_CATEGORIES_DISPLAYED, length) - 1) return ',';
  if (length === MAX_CATEGORIES_DISPLAYED) return '...';
  if (length >= MAX_CATEGORIES_DISPLAYED) {
    const remanent = length - MAX_CATEGORIES_DISPLAYED;
    return `... & ${remanent} ${t(`${translPrefix}.other${remanent === 1 ? '' : 's'}`)}`;
  }
  return '';
};

const mapContractorData = (
  contractor: ContractorMinimal,
  location: string,
  t: (text: string) => string,
): ContractorTableDisplay => ({
  name: (
    <div className={styles.imageAndNameWrapper}>
      <AvatarOrInitials
        firstName={contractor.name}
        lastName={contractor.lastName}
        className={styles.avatar}
        url={contractor?.profilePhoto?.url}
      />
      <div>{`${contractor.name} ${contractor.lastName}`}</div>
    </div>
  ),
  location,
  id: contractor.id,
  categories: (
    <div className="row">
      {contractor.specializations.slice(0, 4).map((category: ContractorSpecialization, index) => (
        <div className={styles.categoryWrapper}>
          <ContractorCategoryIcon
            category={category.specialization.name}
            className={styles.categoryIcon}
          />
          <div key={category.id} className="text__body__small__textNeutral40">
            {`${t(`specializations.${category.specialization.name}`)}${resolveLastCharecters(contractor.specializations.length, index, t)}`}
          </div>
        </div>
      ))}
    </div>
  ),
});

type AssignContractorContainerProps = {
  category: string,
  propertyId: number,
  contractorId?: number,
  infoContractor: string,
  onChange: (field: string, value: string) => void,
  location: string,
};

const initPaginator = (): PaginatorContainer<ContractorMinimal> => ({
  navigation: {
    currentPage: 1,
    lastPage: 0,
    pageSize: 5,
    total: 0,
  },
  results: [] as ContractorMinimal[],
});

const AssignContractorContainer = ({
  category, propertyId, onChange, contractorId, infoContractor, location,
}: AssignContractorContainerProps) => {
  const [actualContractorId, setActualContractorId] = useState(contractorId);
  const [fetching, setFetching] = useState(true);
  const [paginator, setPaginator] = useState<PaginatorContainer<ContractorMinimal>>(
    initPaginator(),
  );
  const { t } = useTranslation();

  const clickPagination = (nextPage: number) => {
    if (nextPage !== paginator.navigation.currentPage) {
      setPaginator((prevState) => ({
        ...prevState,
        navigation: {
          ...prevState.navigation, currentPage: nextPage,
        },
      }));
    }
  };

  const onClickSelect = (option: string, objField: string) => {
    setActualContractorId(Number(option));
    onChange(objField, option);
  };

  const getContractors = async () => {
    try {
      const response = await ContractorController
        .getContractors(paginator?.navigation.currentPage || 1, 5, propertyId, category);
      setPaginator(response);
      setFetching(false);
    } catch (err) {
      logger.error(err as Error);
    }
  };

  const emptyState = (
    <td className={styles.emptyState}>
      {fetching ? <Spinner /> : (
        <>
          <div className={classnames('text__body__large__textNeutral40', styles.text)}>
            {t(`${translPrefix}.emptyStateV2`)}
          </div>
          <Button
            buttonStyle={ButtonStyle.Primary}
            onClick={getContractors}
          >
            {t(`${translPrefix}.tryAgain`)}
          </Button>
        </>
      )}
    </td>
  );

  useEffect(() => {
    if (propertyId) {
      getContractors();
    }
  }, [paginator?.navigation.currentPage]);

  const iconCells = (item: ContractorMinimal) => [
    <RadioButtonMobile
      onChangeFn={() => {
        onClickSelect(item && item.id ? item.id.toString() : '', 'contractorId');
      }}
      checked={item.id === actualContractorId}
      name="selectContractor"
    />];

  return (
    <div>
      <div className={classnames(styles.firstMargin, 'text__heading6__textNeutral50')}>
        {`${t(`${translPrefix}.subtitleStep3`)}${category.toLowerCase()}${t(`${translPrefix}.task`)}`}
      </div>
      <Input
        id="search"
        t={t}
        withSearchIcon
        placeholder={t(`${translPrefix}.searchInput`)}
        onChange={() => {}}
        containerClass={styles.searchInput}
        textStyle="text__body__xsmall__textNeutral30"
      />
      <>
        <Table
          headerNames={headers}
          data={paginator.results.map((cont) => mapContractorData(cont, location, t))}
          dataProperties={contractorProperties}
          dashboardName="contractors"
          uniqueId="id"
          iconCellLeft={iconCells}
          iconCellLeftClass={styles.iconCellLeftClass}
          emptyState={emptyState}
        />
        {paginator.navigation.lastPage > 1
          && (
            <div className={styles.paginationContainer}>
              <Pagination
                pageLimit={paginator.navigation.lastPage}
                onClickFn={(newPage: number) => clickPagination(newPage)}
                currentPage={paginator.navigation.currentPage}
              />
            </div>
          )}
      </>
      {!!paginator.results.length && (
        <>
          <div className={classnames(styles.margin, 'text__heading6__textNeutral50')}>
            {t(`${translPrefix}.infoForContractor.addAdditionalInfo`)}
          </div>
          <TextArea
            id="infoContractor"
            value={infoContractor}
            type="text"
            maxLength={200}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onChange('infoContractor', e.target.value)}
            containerClass={styles.description}
            t={t}
          />
        </>
      )}
    </div>
  );
};

export { AssignContractorContainer };
