import { useCallback, useState } from 'react';
import { useDebounceEffect } from '../use-debounce';
import { SearchParams, getCoordinates, Coordinates } from './geocoding';

export const useGeocoding = (): [Coordinates | null, (address: SearchParams) => void] => {
  const [coordinates, setCoordinates] = useState<Coordinates | null>(null);
  const [debouncedSearchParams, setDebouncedSearchParams] = useState<SearchParams | null>(null);

  useDebounceEffect(() => {
    if (debouncedSearchParams != null) {
      getCoordinates(debouncedSearchParams).then((result) => {
        setCoordinates(result);
      });
    }
  }, [
    debouncedSearchParams?.amenity || '',
    debouncedSearchParams?.city || '',
    debouncedSearchParams?.country || '',
    debouncedSearchParams?.county || '',
    debouncedSearchParams?.postalcode || '',
    debouncedSearchParams?.state || '',
    debouncedSearchParams?.street || '',
    debouncedSearchParams?.q || '',
    // Delay should be kept at 1000 or higher to comply with one request per second limit
  ], 1100);

  const setLocation = useCallback((searchParams: SearchParams) => {
    setDebouncedSearchParams(searchParams);
  }, []);

  return [coordinates, setLocation];
};
