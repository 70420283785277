import { useTranslation } from 'react-i18next';
import { ButtonClose } from '@mapix/common/src/common/button-close';
import { useHistory, useParams } from 'react-router-dom';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import {
  checkHTMLErrors, classnames, HTMLValidationError, removeError,
} from '@mapix/common/src/helpers/utils';
import {
  ChangeEvent, FormEvent, useEffect, useState,
} from 'react';
import { InputDate } from '@mapix/common/src/common/input-date';
import dayjs from 'dayjs';
import { CreateFooter } from 'common/creation';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { ButtonType } from '@mapix/common/src/common/button';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { ModalResult } from 'common/modal-result';
import { ReactComponent as CheckCircle } from 'assets/icons/check-circle.svg';
import { PropertyController } from 'networking/controllers/property-controller';
import { RentalsController } from 'networking/controllers/rentals-controller';
import { logger } from 'helpers/logger';
import { goToPage, RouteName } from 'routes';
import { Spinner } from '@mapix/common/src/common/spinner';
import { ReactComponent as Info } from 'assets/icons/info.svg';
import { Tooltip } from '@mapix/common/src/common/tooltip';
import { Lease } from 'models/lease';
import styles from './extend-lease.module.scss';

type EditState = {
  showError: boolean,
  showModal: boolean
};

type PropertyData = {
  address: string,
  currency: string,
  leaseId: number,
  minExpirationDate: Date,
};

const initState: EditState = {
  showError: false,
  showModal: false,
};

const initProperty: PropertyData = {
  address: '',
  currency: '',
  leaseId: 0,
  minExpirationDate: new Date(),
};

const initExtendLeaseData: ExtendLeaseData = {
  extensionDate: '',
  fees: 0,
  rentalAmount: 0,
};

type ParamType = {
  id: string,
  unitId: string,
};

const getMinExpirationDate = (lease: Lease) => dayjs(
  lease.extensionDate ?? lease.expirationDate,
).add(1, 'day');

const translPrefix = 'editRental';
const ExtendLease = () => {
  const [extendLease, setExtendLease] = useState<ExtendLeaseData>(initExtendLeaseData);
  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});
  const [state, setState] = useState<EditState>(initState);
  const [propertyData, setPropertyData] = useState<PropertyData>(initProperty);

  const { id, unitId } = useParams<ParamType>();
  const propertyId = Number(id);

  const { t } = useTranslation();
  const history = useHistory();

  const getUnit = async () => {
    try {
      const unitResponse = await PropertyController.getUnit(propertyId, Number(unitId));

      /* Can't create rentals on unit with no ongoing lease */
      if (!unitResponse.lease) {
        goToPage(RouteName.ErrorPage);
      } else {
        const minExpirationDate = getMinExpirationDate(unitResponse.lease);
        setPropertyData({
          currency: unitResponse.lease.currency,
          address: unitResponse.fullAddress,
          leaseId: unitResponse.lease.id,
          minExpirationDate: minExpirationDate.toDate(),
        });
        setExtendLease((prevState) => ({
          ...prevState,
          extensionDate: minExpirationDate.toString(),
        }));
      }
    } catch (err: any) {
      logger.error(err);
      setState((prevState) => ({ ...prevState, showError: true }));
    }
  };

  const getRental = async () => {
    try {
      const property = await PropertyController.getDetailedProperty(propertyId);

      /* Can't create rentals on a condo with no ongoing lease */
      if (!property.lease) {
        /* goToPage(RouteName.ErrorPage); */
      } else {
        const minExpirationDate = getMinExpirationDate(property.lease);
        setPropertyData({
          currency: property.lease.currency,
          address: property.fullAddress,
          leaseId: property.lease.id,
          minExpirationDate: minExpirationDate.toDate(),
        });
        setExtendLease((prevState) => ({
          ...prevState,
          extensionDate: minExpirationDate.toString(),
        }));
      }
    } catch (err: any) {
      logger.error(err);
      setState((prevState) => ({ ...prevState, showError: true }));
    }
  };

  useEffect(() => {
    if (unitId) {
      getUnit();
    } else {
      getRental();
    }
  }, []);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as HTMLFormElement;
    if (!target.checkValidity()) {
      setFormErrors(checkHTMLErrors(target));
      return;
    }

    try {
      await RentalsController.extendLease(extendLease, propertyData.leaseId);
      setState((prevState) => ({ ...prevState, showModal: true }));
    } catch (err: any) {
      logger.error(err);
      setState((prevState) => ({ ...prevState, showError: true }));
    }
  };

  if (!propertyData.leaseId) {
    return (
      <div className={styles.spinner}>
        <Spinner fixed={false} />
      </div>
    );
  }

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    removeError(e.target.name, setFormErrors);
    setExtendLease({
      ...extendLease,
      [e.target.name]: +e.target.value,
    });
  };

  return (
    <form noValidate onSubmit={onSubmit} className={styles.container}>

      {state.showError
      && (
        <ErrorMessage
          message={t('error.errorMessage')}
          handleClose={() => setState((prevState) => ({ ...prevState, showError: false }))}
        />
      )}

      {state.showModal && (
        <ModalResult
          title={t(`${translPrefix}.modal.titleCreate`)}
          subtitle={t(`${translPrefix}.modal.subtitle`)}
          Icon={CheckCircle}
          buttonTextRight={t(`${translPrefix}.modal.ok`)}
          handleButtonRight={() => history.goBack()}
          handleButtonClose={() => history.goBack()}
          withCheckIcon
        />
      )}

      <div className={styles.close}>
        <ButtonClose closeFn={() => history.goBack()} closeText={t(`${translPrefix}.close`)} />
      </div>
      <div className={classnames(styles.title, 'text__heading4__textNeutral50')}>
        {t(`${translPrefix}.extendLeaseTitle`)}
      </div>
      <div className={classnames(styles.subtitle, 'text__heading5__textNeutral50')}>
        {unitId ? t(`${translPrefix}.unit`) : t(`${translPrefix}.condo`)}
        {' '}
        -
        {' '}
        {propertyData.address}
      </div>

      <div className={styles.formContainer}>
        <div className={styles.inputRow}>
          <Input
            id="rentalAmount"
            name="rentalAmount"
            label={t(`${translPrefix}.monthlyRentalAmount`)}
            placeholder={t(`${translPrefix}.monthlyRentalAmount`)}
            value={extendLease.rentalAmount || ''}
            type="number"
            onChange={handleInput}
            min={1}
            inputStyle={InputStyle.FORM}
            containerClass={styles.input}
            formError={formErrors}
            helperText={propertyData.currency}
            t={t}
            required
          />

          {!unitId && (
          <Input
            id="fees"
            name="fees"
            label={t(`${translPrefix}.fees`)}
            placeholder={t(`${translPrefix}.fees`)}
            value={extendLease.fees || ''}
            type="number"
            onChange={handleInput}
            inputStyle={InputStyle.FORM}
            containerClass={styles.input}
            formError={formErrors}
            helperText={propertyData.currency}
            t={t}
            required
          />
          )}

          <InputDate
            id="extensionDate"
            name="extensionDate"
            placeholder={t(`${translPrefix}.extensionDate`)}
            label={t(`${translPrefix}.extensionDate`)}
            value={extendLease.extensionDate
              ? dayjs(extendLease.extensionDate).toDate() : undefined}
            formErrors={formErrors}
            onChangeFn={(date: Date) => setExtendLease(
              (prevState) => ({ ...prevState, extensionDate: dayjs(date).format('YYYY-MM-DD') }),
            )}
            helperText="DD/MM/YYYY"
            containerClass={styles.input}
            min={propertyData.minExpirationDate}
            t={t}
            required
          />
          <Tooltip
            textComponent={<Info className={styles.infoIcon} />}
            hoverComponent={(
              <div className="text__body__small__surface10">
                {t(`${translPrefix}.tooltipDueDate${!unitId ? 'Condo' : ''}`)}
              </div>
                )}
            containerWidth="483px"
          />

        </div>
      </div>

      <CreateFooter
        nextName={t(`${translPrefix}.save`)}
        Icon={Check}
        iconStyle={styles.check}
        nextButtonType={ButtonType.Submit}
      />

    </form>
  );
};

export { ExtendLease };
