// @ts-nocheck

const loadScript = (src) => new Promise((resolve, reject) => {
  const scriptElem = Object.assign(document.createElement('script'), {
    type: 'text/javascript',
    defer: true,
    src,
    onerror: (e) => {
      reject(e);
    },
  });
  scriptElem.onload = () => {
    resolve();
  };
  scriptElem.id = 'safecharge';
  document.body.appendChild(scriptElem);
});

const getSafeCharge = async (merchantId, merchantSiteId, safeChargeEnv) => {
  if (document.getElementById('safecharge') === null) {
    await loadScript('https://cdn.safecharge.com/safecharge_resources/v1/websdk/safecharge.js');
  }
  const safecharge = window.SafeCharge({
    env: safeChargeEnv,
    merchantId,
    merchantSiteId,
  });
  return safecharge;
};

export { getSafeCharge };
