import React, { useRef } from 'react';
import { classnames, getAvatarText } from '../../helpers/utils';
import styles from './avatar-or-initials.module.scss';

export enum AvatarSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

type AvatarOrInitialsProps = {
  url?: string,
  firstName: string,
  lastName: string,
  size?: AvatarSize,
  className?: string,
};

const handleClass = (size?: string, className?: string) => {
  switch (size) {
    case AvatarSize.Small:
      return styles.avatarSmall;
    case AvatarSize.Medium:
      return styles.avatarMedium;
    case AvatarSize.Large:
      return styles.avatarLarge;
    default:
      return classnames(styles.avatar, className || '');
  }
};

const AvatarOrInitials = ({
  url, firstName, lastName, size, className,
}: AvatarOrInitialsProps) => {
  const containerRef = useRef(null);

  return (
    <div
      className={handleClass(size, className)}
    >
      {(url) ? (
        <img
          ref={containerRef}
          src={url}
          alt={`${firstName} ${lastName} avatar`}
          className={styles.image}
        />

      ) : (
        <div className={classnames('text__body__large__textNeutral10', styles.initials)}>
          {getAvatarText(firstName, lastName)}
        </div>
      )}
    </div>
  );
};

export { AvatarOrInitials };
