import { City, PaginatorContainer, RawCity } from '@mapix/common/src/types';
import { CitySerializer } from '@mapix/common/src/networking/serializers/city-serializer';
import { PaginatorSerializer } from './paginator-serializer';

class ResourcesSerializer {
  static deSerializeFileType(data: RawFile): FileType {
    return {
      filename: data.filename,
      url: data.url,
    };
  }

  static serializeFileType(data: FileType): RawFile {
    return {
      filename: data.filename,
      url: data.url,
    };
  }

  static deSerializePaginatedCities = (
    data: RawPaginatorContainer<RawCity>,
  ): PaginatorContainer<City> => ({
    navigation: PaginatorSerializer.deSerialize(data.navigation),
    results: CitySerializer.deSerializeCities(data.results),
  });
}

export { ResourcesSerializer };
