class PropertyAddress {
  civicNumber?: number;

  streetName: string;

  country: string;

  province: string;

  provinceId?: string;

  city: string;

  cityId: number;

  lat: number;

  long: number;

  zipCode: string;

  unitNumber?: number;

  fullAddress?: string;

  constructor(params?: SerializedPropertyAddress | null) {
    this.city = params?.city || '';
    this.civicNumber = params?.civicNumber;
    this.streetName = params?.streetName || '';
    this.country = params?.country || 'Canada';
    this.province = params?.province || '';
    this.zipCode = params?.zipCode || '';
    this.unitNumber = params?.unitNumber;
    this.fullAddress = (`${params?.civicNumber} ${params?.streetName} ${params?.unitNumber ? params?.unitNumber : ''}, ${params?.city}, ${params?.country}`);
    this.lat = params?.lat || 0;
    this.long = params?.long || 0;
    this.cityId = params?.cityId || 0;
    this.provinceId = params?.provinceId || '';
  }
}

export { PropertyAddress };
