import React from 'react';
import { Button, ButtonStyle } from '../button';
import { classnames } from '../../helpers/utils';
import { ReactComponent as SearchNoResults } from '../../assets/icons/search-no-results.svg';
import styles from './error-page.module.scss';

type ErrorPageProps = {
  errorCode: ErrorType,
  t: (text: string) => string,
  goBack: () => void,
};

enum ErrorType {
  NotFound = 404,
  ServerError = 500,
}

const tranlsPrefix = 'errorPage';

const ErrorPage = ({
  errorCode, t, goBack,
}: ErrorPageProps) => (
  <div className={styles.pageContainer}>
    <SearchNoResults className={styles.searchNoResults} />
    <div className={styles.errorInformationWrapper}>
      <div className={classnames('text__heading1__textNeutral10', styles.errorCode)}>{errorCode}</div>
      <div className={classnames('text__heading2__textNeutral50', styles.errorName)}>{t(`${tranlsPrefix}.${errorCode}.errorName`)}</div>
      <div className={classnames('text__heading4__textNeutral50', styles.errorDescription)}>{t(`${tranlsPrefix}.${errorCode}.errorDescription`)}</div>
      <Button
        buttonStyle={ButtonStyle.Primary}
        onClick={goBack}
        className={classnames('text__button__medium__textNeutral10', styles.goBack)}
      >
        {t('goBack')}
      </Button>
    </div>
  </div>
);
export { ErrorPage, ErrorType };
