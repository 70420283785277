import { Property } from 'models/property';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, BreadcrumbsItem } from '@mapix/common/src/common/breadcrumbs';
import { goToPage, RouteName } from 'routes';
import styles from 'pages/property-detail/property-detail.module.scss';
import { getDetailCardMonth, getExpirationTitle } from 'helpers/utils';
import { classnames } from '@mapix/common/src/helpers/utils';
import { DetailCard } from 'common/detail-card';
import { Tab } from '@mapix/common/src/common/tab';
import { Condition, PropertyStatus } from 'common/enums';
import { DetailCardItem } from 'common/detail-card/detail-card';
import { CondoInfoTab } from 'pages/property-detail/condo-tabs/condo-info-tab';
import { BillsTable } from 'common/bills-table';
import { CommonDashboard } from 'pages/rentals-dashboard/common-dashboard';
import { MaintenanceTasksTab } from './common-tabs/maintenance-tab/maintenance-tasks-tab';
import { ContractorsTab } from './common-tabs/contractor-tab/contractors-tab';

const breadcrumbs = (propertyAddress: string, breadcrumbStarter: string): BreadcrumbsItem[] => [
  {
    name: breadcrumbStarter,
    goToPageFn: () => goToPage(RouteName.Properties),
  },
  {
    name: propertyAddress,
    goToPageFn: () => null,
  },
];

const billHeaders = ['billedAmount', 'taskCategory', 'taskId', 'dueDate', 'paidOn', 'status'];
const billDataProperties = ['amount', 'category', 'taskId', 'dueDate', 'paidOn', 'status'];
const rentalHeaders = ['rental', 'fees', 'tenant', 'dueDate', 'paidOn', 'status', 'total'];
const rentalDataProperties = ['rawRental', 'fees', 'tenant', 'dueDate', 'paidOn', 'status', 'billedAmount'];

const tabs = [
  'generalInfo', 'rentals',
];

const translPrefix = 'propertyDetail';
const getPanelItems = (property: Property, t: (value: string) => string): DetailCardItem[] => {
  const availableTasks = {
    indicator: property.inProgressTasksCount.toString(),
    indicatorClass: classnames(styles.inProgressTask, 'text__heading5__info40'),
    title: t(`${translPrefix}.cards.taskInProgressTitle`),
    subTitle: t(`${translPrefix}.cards.taskInProgressSubTitle`),
    titleClass: 'text__body__large__info40',
  };

  if (property.status === PropertyStatus.Available) {
    return [availableTasks];
  }
  return ([
    {
      indicator: property.newTasksCount.toString(),
      indicatorClass: classnames(styles.task, 'text__heading5__primary70'),
      title: t(`${translPrefix}.cards.taskTitle`),
      subTitle: t(`${translPrefix}.cards.taskSubTitle`),
      titleClass: 'text__body__large__primary70',
    },
    availableTasks,
    {
      indicator: property.overdueRentals.toString(),
      indicatorClass: classnames(styles.overdue, 'text__heading5__yellow50'),
      title: t(`${translPrefix}.cards.overdueTitle`),
      subTitle: t(`${translPrefix}.cards.overdueSubTitle`),
      titleClass: 'text__body__large__yellow50',
    },
    {
      isDate: true,
      monthClass: 'text__body__tiny__success40',
      month: getDetailCardMonth(property.lease?.expirationDate).substr(0, 3),
      indicator: property.lease?.expirationDate.split('-')[2] || 'N/A',
      indicatorClass: classnames(styles.occupancy, 'text__body__large__success40'),
      title: getExpirationTitle(property.lease?.expirationDate),
      subTitle: t(`${translPrefix}.cards.expirationSubTitle`),
      titleClass: 'text__body__large__success50',
    },
  ]);
};

type CondoDetailProps = {
  property: Property,
};
const CondoDetail = ({ property }: CondoDetailProps) => {
  const [tab, setTab] = useState<string>('generalInfo');

  const { t } = useTranslation();

  const changeTab = (newTab: string) => {
    setTab(newTab);
  };

  return (
    <div className={styles.detailContainer}>

      <Breadcrumbs values={breadcrumbs(`${property.type} - ${property.fullAddress}`, t(`${translPrefix}.breadcrumbStarter`))} />

      <div className={styles.dashContainer}>

        <div className="text__heading4__textNeutral40">
          {`${t(`propertyName.${property.type}`)} - ${property.fullAddress}`}
        </div>

        <div className={classnames(styles.buildingInfo, 'text__body__small__textNeutral30')}>
          {t(`${translPrefix}.infoCondo`)}
        </div>

        {property.condition !== Condition.Archived && (
          <div className={classnames(styles.cardsContainer, 'row')}>
            {getPanelItems(property, t).map((item) => (
              <DetailCard key={item.title} item={item} cardWidth="25%" />
            ))}
          </div>
        )}

        <div className={styles.tabContainer}>
          {tabs.map((itemTab) => (
            <Tab
              key={itemTab}
              name={t(`${translPrefix}.tabs.${itemTab}`)}
              isActive={tab === itemTab}
              onClickFn={() => changeTab(itemTab)}
            />
          ))}
        </div>

        {tab === 'generalInfo' && <CondoInfoTab property={property} />}
        {tab === 'maintenance' && (
        <MaintenanceTasksTab
          id={property.id}
          type="CondoHouse"
          propertyCondition={property.condition}
        />
        )}
        {tab === 'bills' && (
        <BillsTable
          fixedDataProperties={billDataProperties}
          fixedTableHeader={billHeaders}
          propertyId={property.id}
          propertyCondition={property.condition}
          fromPropertyDashboard
        />
        )}

        {tab === 'rentals' && (
          <CommonDashboard
            searchText="rentalsDashboard.buildingTab.condoSearch"
            tableHeaders={rentalHeaders}
            tableDataProperties={rentalDataProperties}
            id={property.id}
            showCreateRental={!!property.lease}
          />
        )}

        {tab === 'contractors' && (
        <ContractorsTab
          id={property.id}
          propertyCondition={property.condition}
        />
        )}

      </div>
    </div>
  );
};

export { CondoDetail };
