import { useTranslation } from 'react-i18next';
import { classnames } from '@mapix/common/src/helpers/utils';

import { useState } from 'react';
import { ImageOverlayObject } from '@mapix/common/src/common/image-overlay/image-overlay';
import { ImageOverlay } from '@mapix/common/src/common/image-overlay';

import styles from './read-only-forms.module.scss';

type PhotosInfoProps = {
  unitPhotos: FileType[],
  leaseCopy: FileType | null,
  tenantPhotos: FileType[]
};

const initOverlayState: ImageOverlayObject = {
  show: false,
  index: 0,
  photos: [],
};

const translPrefix = 'filesDetail';
const FilesInfo = ({ unitPhotos, leaseCopy, tenantPhotos }: PhotosInfoProps) => {
  const [overlayState, setOverlayState] = useState<ImageOverlayObject>(initOverlayState);
  const { t } = useTranslation();

  const showOverlay = (index: number, photos: FileType[]) => {
    setOverlayState({ index, photos, show: true });
  };

  const hideOverlay = () => {
    setOverlayState((prevState) => ({ ...prevState, show: false }));
  };

  return (
    <>
      {overlayState.show && (
        <ImageOverlay
          photos={overlayState.photos}
          selectedIndex={overlayState.index}
          closeFn={hideOverlay}
          t={t}
        />
      )}
      <div className={styles.container}>
        <div className={styles.itemBlock}>
          <div className={classnames(styles.photoTitle, 'text__body__medium__textNeutral40')}>
            {t(`${translPrefix}.lease`)}
          </div>
          {leaseCopy ? (
            <a href={leaseCopy.url} target="_blank" rel="noreferrer">{leaseCopy.filename}</a>
          )
            : (
              <p className={classnames(styles.italic, 'text__body__medium__textNeutral40')}>
                {t(`${translPrefix}.noLeaseCopy`)}
              </p>
            )}
        </div>
      </div>

      <div className={styles.container}>
        <div className={classnames(styles.itemBlock, styles.photos)}>
          <div className={classnames(styles.photoTitle, 'text__body__medium__textNeutral40')}>
            {t(`${translPrefix}.property`)}
          </div>

          {unitPhotos.length > 0
            ? (
              <div className={styles.photoRow}>
                {unitPhotos.map((photo, index) => (
                  <img
                    role="presentation"
                    onClick={() => showOverlay(index, unitPhotos)}
                    key={photo.url}
                    className={styles.img}
                    src={photo.url}
                    alt="Unit"
                  />
                ))}
              </div>
            )
            : (
              <p className={classnames(styles.italic, 'text__body__medium__textNeutral40')}>
                {t(`${translPrefix}.noPropertyPhotos`)}
              </p>
            )}

        </div>
      </div>

      <div className={styles.container}>
        <div className={classnames(styles.itemBlock, styles.photos)}>
          <div className={classnames(styles.photoTitle, 'text__body__medium__textNeutral40')}>
            {t(`${translPrefix}.tenant`)}
          </div>

          {tenantPhotos.length > 0
            ? (
              <div className={styles.photoRow}>
                {tenantPhotos.map((photo, index) => (
                  <img
                    role="presentation"
                    onClick={() => showOverlay(index, tenantPhotos)}
                    key={photo.url}
                    className={styles.img}
                    src={photo.url}
                    alt="Tenant id"
                  />
                ))}
              </div>
            )
            : (
              <p className={classnames(styles.italic, 'text__body__medium__textNeutral40')}>
                {t(`${translPrefix}.noTenantPhotos`)}
              </p>
            )}

        </div>
      </div>

    </>
  );
};

export { FilesInfo };
