import { BasicUserFields } from '../../types';
import { classnames } from '../../helpers/utils';
import { SimpleMessage } from '../simple-message';
import styles from './cancellation-info-card.module.scss';

type CancellationInfoCardProps = {
  cancelledBy: BasicUserFields;
  message: string;
  completion: number;
  payment: number;
  oldCompletion?: number;
  oldPayment?: number;
  translPrefix: string;
  t: (text: string) => string;
  className?: string;
};

const CancellationInfoCard = ({
  cancelledBy,
  message,
  completion,
  payment,
  oldCompletion,
  oldPayment,
  translPrefix,
  t,
  className = '',
}: CancellationInfoCardProps) => (
  <div className={classnames(styles.cancellationInfoCardContainer, 'text__body__small__textNeutral40', className)}>
    <SimpleMessage
      senderName={cancelledBy.name}
      senderLastName={cancelledBy.lastName}
      senderUrl={cancelledBy.profilePhoto?.url}
      text={message}
    />

    <div className="mt-10">
      <span>
        {t(`${translPrefix}.completion`)}
      </span>
      {oldCompletion && (
      <span className={classnames('text__body__medium__warning50', 'bold', styles.textDashed)}>
        {` ${oldCompletion}%`}
      </span>
      )}
      <span className={classnames('text__body__medium__primary50', 'bold', 'mt-10')}>
        &nbsp;
        {` ${completion}%`}
      </span>
    </div>

    <div className="mt-10">
      <span>
        {t(`${translPrefix}.payment`)}
      </span>
      {oldPayment && (
      <span className={classnames('text__body__medium__warning50', 'bold', styles.textDashed)}>
        {` $${oldPayment}  `}
      </span>
      )}
      <span className={classnames('text__body__medium__primary50', 'bold')}>
        &nbsp;
        {` $${payment}`}
      </span>
    </div>

    <div className="mt-8 text__body__small__textNeutral30">
      {t(`${translPrefix}.calculationDisclaimer`)}
    </div>
  </div>
);

export { CancellationInfoCard };
