import { TypeOfCostSerializer } from './type-of-cost-serializer';
import {
  BillIndex, NewTaskForBill, RawBillIndex, RawNewTaskForBill,
} from '../../types';
import { AddressSerializer } from './address-serializer';

class BillSerializer {
  static deSerializeBillIndex(rawBill: RawBillIndex): BillIndex {
    return {
      id: rawBill.id,
      dueDate: rawBill.due_date,
      amount: rawBill.amount,
      newTaskId: rawBill.new_task_id,
      taskName: rawBill.task_name,
      category: rawBill.category,
      description: rawBill.description,
      buildingName: rawBill.building_name,
      address: AddressSerializer.deSerializeAddress(rawBill.address),
      payable: rawBill.payable,
    };
  }

  static deSerializeBillIndexArray(rawBills: RawBillIndex[]): BillIndex[] {
    return rawBills.map((rawBill) => this.deSerializeBillIndex(rawBill));
  }

  static deSerializeNewTaskForBill(data: RawNewTaskForBill): NewTaskForBill {
    return {
      id: data.id,
      name: data.name,
      category: data.category,
      ...TypeOfCostSerializer.deSerializeTypeOfCost(data),
    };
  }
}

export { BillSerializer };
