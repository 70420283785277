class MaintenanceContractorSerializer {
  static deSerialize(data: RawTaskContractor): SerializedTaskContractor {
    return {
      id: data.id,
      propertyId: data.property_id,
      category: data.category,
      phoneNumber: data.phone_number,
      name: data.name,
      lastName: data.last_name,
      email: data.email,
      countryCode: data.country_code,
    };
  }
}

export { MaintenanceContractorSerializer };
