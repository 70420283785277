import { User as ModelUser } from 'models/user';
import { API_ROUTES } from 'networking/api-routes';
import { UserSerializer } from 'networking/serializers/user-serializer';
import { ApiService } from '../api-service';

class UserController {
  static async me() {
    const result = await ApiService.get<UserFromBackend>(API_ROUTES.USER);
    return new ModelUser(UserSerializer.deSerialize(result.data));
  }

  static async register(user: User) {
    const serializedUser = UserSerializer.serialize(user);
    return ApiService.post<UserFromBackend>(API_ROUTES.REGISTER, serializedUser);
  }

  static async forgotPassword(email: string) {
    return ApiService.get(API_ROUTES.PASSWORD, { email });
  }

  static async resetPassword(data: ResetPasswordType) {
    const serializedData = UserSerializer.serializeResetPassword(data);
    const res = await ApiService.post(API_ROUTES.PASSWORD, serializedData);
    return res;
  }

  static async editProfile(user: UserEdit) {
    const serializedUser = UserSerializer.serializeEditProfile(user);
    const result = await ApiService.put<UserFromBackend>(API_ROUTES.USER, serializedUser);
    return UserSerializer.deSerialize(result.data);
  }

  static async updatePassword(data: UpdatePassword) {
    const serializedData = UserSerializer.serializeUpdatePassword(data);
    return ApiService.put<RawUpdatePassword>(API_ROUTES.PASSWORD, serializedData);
  }

  static async resendInvitation(invitation: SerializedInvitation) {
    const serialized = UserSerializer.serializeInvitation(invitation);
    return ApiService.post<UserFromBackend>(API_ROUTES.RESEND_INVITATION, serialized);
  }

  static async activateAccount(data: ActivateAccount) {
    const serializedData = UserSerializer.serializeActivateAccount(data);
    return ApiService.post<RawActivateAccount>(API_ROUTES.ACTIVATE_ACCOUNT, serializedData);
  }

  static async resendConfirmEmail() {
    return ApiService.post(API_ROUTES.RESEND_CONFIRMATION_EMAIL);
  }

  static async verifyAccount(data: VerifyAccount) {
    const serializedData = UserSerializer.serializeVerifyAccount(data);
    return ApiService.post<RawVerifyAccount>(API_ROUTES.VERIFY_ACCOUNT, serializedData);
  }

  static async phoneValidation(remainingPhoneNumber: string, countryCode: string) {
    const serializedData = UserSerializer.serializePhone(
      { countryCode, phoneNumber: remainingPhoneNumber },
    );
    const response = await ApiService.get<RawPhoneValidation>(
      API_ROUTES.CHECK_PHONE_NUMBER,
      serializedData,
    );
    return response.data;
  }

  static async availableEmailValidation(email: string) {
    return ApiService.get(API_ROUTES.AVAILABLE_EMAIL, { email });
  }
}
export { UserController };
