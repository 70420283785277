import { useTranslation } from 'react-i18next';
import { SupportedLanguages } from '../../i18n/utils';
import { PrivacyPolicyEn } from './privacy-policy-en';
import { PrivacyPolicyFr } from './privacy-policy-fr';

const PrivacyPolicy = () => {
  const { i18n } = useTranslation();
  return (
    <div>
      {
        (i18n.language === SupportedLanguages.French)
          ? (<PrivacyPolicyFr />)
          : <PrivacyPolicyEn />
      }
    </div>
  );
};

export { PrivacyPolicy };
