import { Breakpoints, MakePaymentModalState } from '@mapix/common/src/common/enums';
import { NuveiResponse } from '@mapix/common/src/common/make-payment/create-payment';
import { MakePayment } from '@mapix/common/src/common/make-payment/make-payment';
import { MakePaymentInformation, MakePaymentInformationValues } from '@mapix/common/src/common/make-payment/make-payment-information';
import styles from '@mapix/common/src/common/make-payment/make-payment.module.scss';
import { getSafeCharge } from '@mapix/common/src/common/make-payment/safe-charge';
import {
  HTMLValidationError, addError, checkHTMLErrors, isStringNumeric,
} from '@mapix/common/src/helpers/utils';
import { useMediaQuery } from '@mapix/common/src/hooks/use-media-query';

import { Modal } from '@mapix/common/src/common/modal';
import { Spinner } from '@mapix/common/src/common/spinner';
import { logger } from '@mapix/common/src/helpers/logger';
import {
  OpenOrderResponse,
  PaymentMethod,
  RentalTenant,
  UpdatePaymentRental,
} from '@mapix/common/src/types';
import { constants } from 'config/constants';
import { AppContext } from 'context';
import { CreatePaymentController } from 'networking/controllers/create-payment-controller';
import {
  FormEvent,
  useContext,
  useEffect, useState,
} from 'react';

type MakePaymentRentalProps = {
  onClose: () => void,
  t: (text: string) => string,
  rental: RentalTenant | null,
  getRentals: () => void,
  toggleShowInfoModal: () => void,
};

const translPrefix = 'paymentMethod';

const initMakePaymentInformation = (rental: RentalTenant | null) => ({
  amount: '',
  reference: '',
  currency: rental?.currency || '',
});

const MakePaymentRental = ({
  onClose, t, rental, getRentals, toggleShowInfoModal,
}: MakePaymentRentalProps) => {
  const [makePaymentInformation, setMakePaymentInformation] = useState<
  MakePaymentInformationValues>(
    initMakePaymentInformation(rental),
  );
  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});
  const [openOrderData, setOpenOrderData] = useState<OpenOrderResponse>({} as OpenOrderResponse);
  const [safeCharge, setSafeCharge] = useState<any>(null);
  const [contentToShow, setContentToShow] = useState<MakePaymentModalState>(
    MakePaymentModalState.ShowSpinner,
  );
  const [storedCards, setStoredCards] = useState<PaymentMethod[]>([]);

  const getStoredCards = async () => {
    setContentToShow(MakePaymentModalState.ShowSpinner);
    try {
      const response = await CreatePaymentController.getPaymentMethod();
      setStoredCards(response);
      setContentToShow(MakePaymentModalState.ShowMakePaymentInformationForm);
      return response;
    } catch (err) {
      logger.error(err as Error);
      return [];
    }
  };

  const updatePaymentFn = async (updatePayment: UpdatePaymentRental) => {
    try {
      await CreatePaymentController.updatePaymentRental(updatePayment);
    } catch (err) {
      logger.error(err as Error);
      setContentToShow(MakePaymentModalState.ShowGenericError);
    }
  };

  useEffect(() => {
    getStoredCards();
  }, []);

  const { state: { user: { email } } } = useContext(AppContext);

  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const onSubmitCurrencyAmount = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    if (!target.checkValidity()) {
      setFormErrors(checkHTMLErrors(target));
      return;
    }

    if (Number(makePaymentInformation.amount) === 0) {
      addError(t('error.zeroOrLess'), 'amount', setFormErrors);
      return;
    }

    if (!isStringNumeric(makePaymentInformation.amount)) {
      addError(t('error.notNumeric'), 'amount', setFormErrors);
      return;
    }
    setContentToShow(MakePaymentModalState.ShowSpinner);

    try {
      const responseOpenOrder = await CreatePaymentController.openOrderRental({
        rentalId: rental?.id!,
        currency: makePaymentInformation.currency,
        amount: makePaymentInformation.amount,
      });

      setOpenOrderData(responseOpenOrder);

      const actualSafeCharge = await getSafeCharge(
        responseOpenOrder?.merchantId,
        responseOpenOrder?.merchantSiteId,
        constants.safeChargeEnv,
      );
      setSafeCharge(actualSafeCharge);

      setContentToShow(MakePaymentModalState.ShowPaymentMethods);
    } catch (err) {
      logger.error(err as Error);
      setContentToShow(MakePaymentModalState.ShowNuveiError);
    }
  };

  const updatePayment = (response: NuveiResponse, openOrderId: number) => {
    const updatePaymentData: UpdatePaymentRental = {
      status: response.result,
      errorCode: response.errCode,
      expirationYear: response.ccExpYear,
      lastFourNumbers: response.last4Digits,
      reason: response.errorDescription,
      id: openOrderId,
      userPaymentOptionId: response.userPaymentOptionId,
      rentalId: rental?.id!,
      expirationMonth: response.ccExpMonth,
      transactionId: response.transactionId,
    };

    updatePaymentFn(updatePaymentData);
  };

  const refreshData = () => {
    getRentals();
    getStoredCards();
  };

  useEffect(() => {
    if (rental?.currency) {
      // Currency wasn't loaded in time when component is show as page (not modal)
      setMakePaymentInformation((prevState) => ({
        ...prevState,
        currency: rental?.currency,
      }));
    }
  }, [rental?.currency]);

  if (contentToShow === MakePaymentModalState.ShowSpinner) {
    return (<Spinner />);
  }

  const makePaymentInformationForm = (
    <div className={styles.container}>

      <MakePaymentInformation
        t={t}
        formErrors={formErrors}
        makePaymentInformation={makePaymentInformation}
        onCancel={onClose}
        rentalInfo={rental}
        onSubmit={onSubmitCurrencyAmount}
        setMakePaymentInformation={setMakePaymentInformation}
        toggleShowInfoModal={toggleShowInfoModal}
        onClose={onClose}
        setFormErrors={setFormErrors}
      />
    </div>

  );

  if (contentToShow === MakePaymentModalState.ShowMakePaymentInformationForm) {
    return (
      <>
        {mobile ? (makePaymentInformationForm)
          : (
            <Modal>
              {makePaymentInformationForm}
            </Modal>
          )}
      </>
    );
  }

  const deletePaymentMethod = async (id: number) => {
    await CreatePaymentController.deletePaymentMethod(id);
    await getStoredCards();
  };

  return (
    <MakePayment
      onClose={onClose}
      t={t}
      email={email}
      storedCards={storedCards}
      updatePaymentFn={updatePayment}
      refreshData={refreshData}
      deletePaymentMethod={deletePaymentMethod}
      makePaymentInformation={makePaymentInformation}
      openOrderData={openOrderData}
      safeCharge={safeCharge}
      translPrefix={translPrefix}
      setContentToShow={setContentToShow}
      contentToShow={contentToShow}
    />
  );
};

export { MakePaymentRental };
