import { ReactComponent as MoreIconSVG } from 'assets/icons/more.svg';
import { MouseEventHandler, useRef, useState } from 'react';
import { useClickOutside } from '@mapix/common/src/hooks/click-outside';
import styles from './more-icon.module.scss';

type MoreIconProps = {
  options: string[],
  optionsFn: MouseEventHandler<HTMLButtonElement>[],
  iconClass: string,
};

const MoreIcon = ({ options, optionsFn, iconClass = '' }: MoreIconProps) => {
  const [showIcons, setShowIcons] = useState<boolean>(false);
  const iconRef = useRef(null);

  useClickOutside(iconRef, () => setShowIcons(false));
  return (
    <div
      ref={iconRef}
      className={styles.iconContainer}
    >
      <MoreIconSVG onClick={() => setShowIcons(true)} className={iconClass} />

      {showIcons && (
        <div className={styles.content}>
          {options.map((option: string, index: number) => (
            <button
              type="button"
              key={option}
              onClick={optionsFn[index]}
              className={styles.item}
            >
              {option}
            </button>
          ))}
        </div>
      )}

    </div>
  );
};

export { MoreIcon };
