import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Property } from 'models/property';
import { PropertyController } from 'networking/controllers/property-controller';
import { Condition, PropertyType } from 'common/enums';
import { BuildingDetail } from 'pages/property-detail/building-detail';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@mapix/common/src/common/spinner';
import { CondoDetail } from 'pages/property-detail/condo-detail';
import { Unit } from 'models/unit';
import { UnitDetail } from 'pages/property-detail/unit-detail';
import { goToPage, RouteName } from 'routes';
import styles from './property-detail.module.scss';

const PropertyDetail = () => {
  const [property, setProperty] = useState<Property>();
  const [unit, setUnit] = useState<Unit>();
  const [code, setCode] = useState('READY');

  const { t } = useTranslation();

  const { id, unitId } = useParams<{ id: string, unitId: string }>();

  const getProperty = async () => {
    setCode('READY');
    try {
      const fetchedProperty = await PropertyController.getDetailedProperty(Number(id));
      if (fetchedProperty.condition === Condition.Draft) {
        goToPage(RouteName.ErrorPage);
      }
      setProperty(fetchedProperty);
      setCode('READY');
    } catch (err: any) {
      if (err.status === 404) {
        goToPage(RouteName.ErrorPage);
      }
      setCode('ERROR');
    }
  };

  const getUnit = async () => {
    setCode('READY');
    try {
      const fetchedProperty = await PropertyController.getDetailedProperty(Number(id));
      if (fetchedProperty.condition === Condition.Draft) {
        goToPage(RouteName.ErrorPage);
      }
      setProperty(new Property(fetchedProperty));
      const fetchedUnit = await PropertyController.getUnit(Number(id), Number(unitId));
      setUnit(new Unit(fetchedUnit));
      setCode('READY');
    } catch (err: any) {
      if (err.status === 404) {
        goToPage(RouteName.ErrorPage);
      }
      setCode('ERROR');
    }
  };

  useEffect(() => {
    if (unitId) {
      getUnit();
    } else {
      getProperty();
    }
  }, []);

  if (!property && !unit) {
    return (
      <>
        <div className={styles.erorrMessageContainer}>
          {code === 'ERROR' && (
            <ErrorMessage message={t('error.errorMessage')} handleClose={() => getProperty()} />
          )}
        </div>
        <Spinner />
      </>
    );
  }

  if (unit && property) {
    return (
      <UnitDetail
        propertyCondition={property.condition}
        unit={unit}
        propertyId={property.id}
        propertyName={property.buildingName}
        fullAddress={property.fullAddress}
      />
    );
  }

  return (
    <>
      {property && (
        <>
          {property.type === PropertyType.Building
            ? <BuildingDetail property={property} />
            : <CondoDetail property={property} />}
        </>
      )}
    </>
  );
};

export { PropertyDetail };
