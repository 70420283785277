import { ModalInformation } from '@mapix/common/src/common/modal-information';
import { useTranslation } from 'react-i18next';
import styles from './unpublished-and-unassigned-task-detail.module.scss';

type MarkAsSolvedModalProps = {
  handleButtonClose: () => void,
  translPrefix: string,
};

const MarkAsSolvedModal = ({
  handleButtonClose, translPrefix,
}: MarkAsSolvedModalProps) => {
  const { t } = useTranslation();

  return (
    <ModalInformation
      handleButtonClose={handleButtonClose}
      handleButtonRight={() => {}}
      title={t(`${translPrefix}.markAsSolved.title`)}
      buttonTextRight={t(`${translPrefix}.markAsSolved.title`)}
      containerClass={styles.modalContainer}
    >
      <>
        <div className="text__body__small__textNeutral30">
          {t(`${translPrefix}.markAsSolved.subtitle`)}
        </div>
      </>
    </ModalInformation>
  );
};

export { MarkAsSolvedModal };
