import { Paginator } from 'models/paginator';
import { addFiltersToURL } from 'helpers/utils';
import { PropertyTabs } from 'pages/properties/property-table-reducer';
import { PropertyTableItem } from 'models/property-table-item';
import { ModalFilterItem } from 'common/filter-modal/filter-modal';
import { Property } from 'models/property';
import { UnitSerializer } from 'networking/serializers/unit-serializer';
import { Unit } from 'models/unit';
import { TenantSimpleProperty } from 'models/tenant-simple-property';
import { PropertyTenantSerializer } from 'networking/serializers/property-tenant-serializer';
import { SimpleUnit } from 'models/simple-unit';
import { SimpleUnitSerializer } from 'networking/serializers/simple-unit-serializer';
import { UnitOfAPropertySerializer } from 'networking/serializers/unit-of-a-property-serializer';
import { PropertyTenant } from 'models/property-tenant';
import { MaintenancePropertySerializer } from 'networking/serializers/maintenance-property-serializer';
import { DetailedPropertySerializer } from 'networking/serializers/detailed-property-serializer';
import { DetailedDraftProperty } from 'models/detailed-draft-property';
import { Contractor } from 'models/contractor';
import { UnitOfAProperty } from 'models/unit-of-a-property';
import { ContractorSerializer } from 'networking/serializers/contractor-serializer';
import { ApiService } from '../api-service';
import { API_ROUTES } from '../api-routes';
import { PropertySerializer } from '../serializers/property-serializer';
import { PaginatorSerializer } from '../serializers/paginator-serializer';

const propertyTypeConverter = (type?: string): string => {
  switch (type) {
    case PropertyTabs.All:
      return '';
    case PropertyTabs.CondosAndHouses:
      return 'CondoHouse';
    case PropertyTabs.Buildings:
      return 'Building';
    default:
      return '';
  }
};
class PropertyController {
  static async getProperties(
    page: number,
    pageSize: number,
    type?: string,
    query?: string,
    filters?: ModalFilterItem[],
  ) {
    const url = `${API_ROUTES
      .GET_PROPERTIES(page, pageSize, propertyTypeConverter(type), query)}&${addFiltersToURL(filters)}`;
    const result = await ApiService
      .get<RawPaginatorContainer<RawProperty>>(url);
    const { results, navigation } = result.data;
    const paginator = PaginatorSerializer.deSerialize(navigation);

    return new Paginator<PropertyTableItem>(
      paginator,
      results.map((item) => new PropertyTableItem(PropertySerializer.deSerialize(item))),
    );
  }

  static savePropertyBuilding(property: SerializedCreateBuilding) {
    if (property.id) {
      return ApiService
        .put(
          API_ROUTES.MANAGE_DRAFT_PROPERTIES(property.id),
          PropertySerializer.serializeCreateBuilding(property),
        );
    }
    return ApiService
      .post(API_ROUTES.PROPERTIES, PropertySerializer.serializeCreateBuilding(property));
  }

  static savePropertyCondo(property: SerializedCreateCondo) {
    if (property.id) {
      return ApiService
        .put(
          API_ROUTES.MANAGE_DRAFT_PROPERTIES(property.id),
          PropertySerializer.serializeCreateCondo(property),
        );
    }
    return ApiService
      .post(API_ROUTES.PROPERTIES, PropertySerializer.serializeCreateCondo(property));
  }

  static async archiveProperty(id: number) {
    const result = await ApiService
      .put<RawProperty>(API_ROUTES.EDIT_PROPERTY(id), PropertySerializer.serializeArchive('archived'));
    return PropertySerializer.deSerialize(result.data);
  }

  static async archiveUnit(idProperty: number, idUnit: number) {
    const result = await ApiService
      .put<RawUnit>(API_ROUTES.GET_UNIT(idProperty, idUnit), PropertySerializer.serializeArchive('archived'));
    return UnitSerializer.deSerialize(result.data);
  }

  static async unarchiveProperty(id: number) {
    const result = await ApiService
      .put<RawProperty>(API_ROUTES.EDIT_PROPERTY(id), PropertySerializer.serializeArchive('active'));
    return PropertySerializer.deSerialize(result.data);
  }

  static async unarchiveUnit(idProperty: number, idUnit: number) {
    const result = await ApiService
      .put<RawUnit>(API_ROUTES.GET_UNIT(idProperty, idUnit), PropertySerializer.serializeArchive('active'));
    return UnitSerializer.deSerialize(result.data);
  }

  static async getDetailedProperty(id: number | string) {
    const result = await ApiService.get<RawProperty>(API_ROUTES.GET_DETAILED_PROPERTY(id));
    return new Property(PropertySerializer.deSerialize(result.data));
  }

  static async editProperty(property: Property) {
    if (property.id) {
      const result = await ApiService
        .put<RawProperty>(
        API_ROUTES.EDIT_PROPERTY(property.id),
        property.type === 'Building' ? PropertySerializer.serializeEdit(property)
          : PropertySerializer.serializeEditCondo(property),
      );
      return PropertySerializer.deSerialize(result.data);
    }
    return null;
  }

  static async getPropertiesIdsFromTenant() {
    const result = await ApiService.get<RawTenantSimpleProperty[]>(
      API_ROUTES.GET_PROPERTIES_FROM_TENANT(),
    );
    return result.data
      .map((property: RawTenantSimpleProperty) => new TenantSimpleProperty(
        PropertyTenantSerializer.deSerializePropertiesIdsFromTenant(property),
      ));
  }

  static async getCondoPropertyFromTenant(propertyId: number) {
    const result = await ApiService.get<RawPropertyTenant>(
      API_ROUTES.GET_TENAT_CONDO_PROPERTY(propertyId),
    );
    return new PropertyTenant(MaintenancePropertySerializer.deSerializeTenant(result.data));
  }

  static async getUnitPropertyFromTenant(unitId: number, propertyId: number) {
    const result = await ApiService.get<RawPropertyTenant>(
      API_ROUTES.GET_TENANT_UNIT_PROPERTY(unitId, propertyId),
    );
    return new PropertyTenant(MaintenancePropertySerializer.deSerializeTenant(result.data));
  }

  static async getUnit(idProperty: number, idUnit: number) {
    const result = await ApiService.get<RawUnit>(API_ROUTES.GET_UNIT(idProperty, idUnit));
    return new Unit(UnitSerializer.deSerialize(result.data));
  }

  static async editUnit(idProperty: number, unit: Unit) {
    const serializedUnit = UnitSerializer.serializeEdit(unit);
    const result = await ApiService.put<RawUnit>(
      API_ROUTES.GET_UNIT(idProperty, unit.id),
      serializedUnit,
    );
    return new Unit(UnitSerializer.deSerialize(result.data));
  }

  static async getActiveProperties(query?: string) {
    const result = await
    ApiService.get<RawPaginatorContainer<RawProperty>>(API_ROUTES.GET_ACTIVE_PROPERTIES(query));
    const { results } = result.data;
    return (results.map((item) => new Property(PropertySerializer.deSerialize(item))));
  }

  static async getUnits(propertyId: number) {
    const result = await ApiService
      .get<RawSimpleUnit[]>(API_ROUTES.GET_UNITS_NUMBERS_OF_PROPERTY(propertyId));
    return result.data
      .map((unit: RawSimpleUnit) => new SimpleUnit(SimpleUnitSerializer.deSerialize(unit)));
  }

  static async getDraftProperty(id: number) {
    const result = await ApiService
      .get<RawDetailedProperty>(API_ROUTES.MANAGE_DRAFT_PROPERTIES(id));

    return new DetailedDraftProperty(DetailedPropertySerializer.deSerialize(result.data));
  }

  static async deleteDraftProperty(id: number) {
    return ApiService.delete(API_ROUTES.MANAGE_DRAFT_PROPERTIES(id));
  }

  static async getContractorsOfAProperty(
    pageNumber: number,
    pageSize: number,
    id: number,
    filters?: ModalFilterItem[],
    query?: string,
  ) {
    const url = `${API_ROUTES
      .PROPERTY_CONTRACTORS(pageNumber, pageSize, id, undefined, query)}&${addFiltersToURL(filters)}`;
    const result = await ApiService.get<RawPaginatorContainer<RawContractor>>(url);
    const { results, navigation } = result.data;
    const paginator = PaginatorSerializer.deSerialize(navigation);
    return new Paginator<Contractor>(
      paginator,
      results.map((item) => new Contractor(ContractorSerializer.deSerialize(item))),
    );
  }

  static async getUnitsOfAProperty(
    pageNumber: number,
    pageSize: number,
    id: number,
    filters?: ModalFilterItem[],
    query?: string,
  ) {
    const url = `${API_ROUTES
      .GET_UNITS_OF_A_PROPERTY(pageNumber, pageSize, id, query)}&${addFiltersToURL(filters)}`;
    const result = await ApiService.get<RawPaginatorContainer<RawUnitOfAProperty>>(url);
    const { results, navigation } = result.data;
    const paginator = PaginatorSerializer.deSerialize(navigation);
    return new Paginator<UnitOfAProperty>(
      paginator,
      results.map((item) => new UnitOfAProperty(UnitOfAPropertySerializer.deSerialize(item))),
    );
  }

  static createUnit(propertyId: number, unit: Unit) {
    return ApiService
      .post(API_ROUTES.CREATE_UNIT(propertyId), UnitSerializer.serialize(unit));
  }

  static getProperty(propertyId: number) {
    return ApiService
      .get<Property>(API_ROUTES.GET_PROPERTY(propertyId));
  }
}
export { PropertyController };
