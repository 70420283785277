import { useEffect } from 'react';
import {
  Circle,
  MapContainer, Marker, TileLayer, useMap,
} from 'react-leaflet';
import { classnames } from '../../helpers/utils';
import { Coordinates } from '../../types';
import { Specializations } from '../enums';
import { pinIcon } from '../map-pin-icon/pin-icon';
import { DraggableMarker } from './draggable-marker';
import styles from './map-view.module.scss';

const circleStyle = { fillColor: '#A0BEFA', color: '#2460D7', weight: 1 };

type ActualMapProps = {
  mapCenter: Coordinates,
  dragging?: boolean,
  touchZoom?: boolean,
  containerClass?: string,
  desktopControls?: boolean,
  draggableMarker?: boolean,
  setCenter?: (coords: Coordinates) => void,
  zoom?: number,
  pinIconCategory?: Specializations,
};

type CentererProps = {
  center: Coordinates,
  zoom: number,
};

// Needed to trigger the centering of the map when center changes
const Centerer = ({ center, zoom }: CentererProps) => {
  const map = useMap();

  useEffect(() => {
    map.setZoom(zoom);
  }, [zoom]);

  useEffect(() => {
    map.setView(center);
  }, [center, map]);

  return null;
};

const ActualMap = ({
  mapCenter, dragging = false, touchZoom = false, containerClass = '',
  desktopControls = false, draggableMarker = false, setCenter, zoom = 16,
  pinIconCategory,
}: ActualMapProps) => (
  <MapContainer
    center={mapCenter}
    zoom={zoom}
    className={classnames(styles.map, containerClass)}
    dragging={dragging}
    touchZoom={touchZoom}
    zoomControl={desktopControls}
    scrollWheelZoom={desktopControls}
    doubleClickZoom={desktopControls}
  >
    <Centerer center={mapCenter} zoom={zoom} />
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    <Circle center={mapCenter} pathOptions={circleStyle} radius={60} />
    { draggableMarker ? (
      <DraggableMarker
        center={mapCenter}
        setCenter={setCenter}
      />
    ) : (
      <Marker
        position={mapCenter}
        icon={pinIcon(pinIconCategory)}
      />
    )}
  </MapContainer>
);

export { ActualMap };
