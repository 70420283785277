import { Router } from 'routes';
import { routeConfig } from 'route-components';
import 'index.scss';
import { ErrorBoundary } from 'common/error-boundary';
import { useTranslation } from 'react-i18next';
import { SplashScreen } from '@mapix/common/src/common/splash-screen';
import { AppProvider } from 'context';
import { MetaTags } from 'meta-tags';

const App = () => {
  // NOTE: `ready` will be true once translations have been loaded.
  // https://react.i18next.com/latest/usetranslation-hook#not-using-suspense
  // Look at our i18next configuration file for more information.
  const { ready } = useTranslation();

  return (
    <>
      <MetaTags />
      <ErrorBoundary>
        <AppProvider>
          {ready
            ? <Router routeConfig={routeConfig} />
            : <SplashScreen />}
        </AppProvider>
      </ErrorBoundary>
    </>
  );
};

export { App };
