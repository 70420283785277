import { CreateHeader } from 'common/creation';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddBillStep1 } from 'pages/add-bill/step-1/add-bill-step-1';
import { classnames } from '@mapix/common/src/helpers/utils';
import { Property } from 'models/property';
import { AddBillStep2 } from 'pages/add-bill/step-2/add-bill-step-2';
import { BillCreate } from 'models/bill-create';
import { BillsController } from 'networking/controllers/bills-controller';
import { logger } from 'helpers/logger';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { ModalResult } from 'common/modal-result';
import { ReactComponent as CheckCircle } from 'assets/icons/check-circle.svg';
import { ReactComponent as Pencil } from 'assets/icons/feather-icons.svg';
import { goToPage, RouteName } from 'routes';
import { useHistory } from 'react-router-dom';
import styles from './add-bill.module.scss';

type StateType = {
  step: number,
  property: Property | null
  postError: boolean,
  showFinishModal: boolean,
  showLeaveModal: boolean,
  goBackModal: boolean,
};

const initialState = {
  step: 0,
  property: null,
  postError: false,
  showFinishModal: false,
  showLeaveModal: false,
  goBackModal: false,
};

const translPrefix = 'addBill';
const steps = [`${translPrefix}.steps.select`, `${translPrefix}.steps.complete`];
const AddBill = () => {
  const [state, setState] = useState<StateType>(initialState);
  const history = useHistory();

  const { t } = useTranslation();

  const onPropertySelected = (item: Property) => {
    setState((prevState) => ({ ...prevState, property: item, step: 1 }));
  };

  const goBack = () => {
    setState((prevState) => ({ ...prevState, goBackModal: true }));
  };

  const save = async (bills: BillCreate[]) => {
    if (state.property) {
      setState((prevState) => ({ ...prevState, postError: false }));
      try {
        const serializedBills: SerializedBillCreateArray = {
          bills: bills.map((bill) => ({
            unitId: bill.unitId,
            paymentDate: bill.paymentDate,
            dueDate: bill.dueDate,
            currency: bill.currency,
            category: bill.category,
            billedAmount: bill.billedAmount,
            additionalComments: bill.additionalComments,
          })),
        };
        await BillsController.addBills(state.property.id, serializedBills);
        setState((prevState) => ({ ...prevState, showFinishModal: true }));
      } catch (err: any) {
        logger.error(err);
        setState((prevState) => ({ ...prevState, postError: true }));
      }
    }
  };

  return (
    <div className={classnames(styles.container, 'column')}>
      {state.postError
      && (
      <ErrorMessage
        message={t('error.errorMessage')}
        handleClose={() => setState((prevState) => ({ ...prevState, postError: false }))}
      />
      )}
      {state.showFinishModal && (
        <ModalResult
          title={t(`${translPrefix}.modal.successTitle`)}
          subtitle={state.property?.fullAddress}
          Icon={CheckCircle}
          buttonTextRight={t(`${translPrefix}.modal.okButton`)}
          handleButtonRight={() => goToPage(RouteName.BillsTable)}
          handleButtonClose={() => goToPage(RouteName.BillsTable)}
          withCheckIcon
        />
      )}
      {(state.showLeaveModal || state.goBackModal) && (
        <ModalResult
          title={t(`${translPrefix}.modal.notFinished`)}
          subtitle={t(`${translPrefix}.modal.notFinishedDescription`)}
          Icon={Pencil}
          buttonTextRight={t(`${translPrefix}.modal.yesDiscardButton`)}
          buttonTextLeft={t(`${translPrefix}.modal.goBackButton`)}
          handleButtonRight={(() => history.goBack())}
          handleButtonClose={() => setState(
            (prevState) => ({ ...prevState, showLeaveModal: false, goBackModal: false }),
          )}
          handleButtonLeft={() => setState(
            (prevState) => ({ ...prevState, showLeaveModal: false, goBackModal: false }),
          )}
          iconStyle={styles.leaveIcon}
        />
      )}
      <CreateHeader
        closeFn={() => setState((prevState) => ({ ...prevState, showLeaveModal: true }))}
        title={t(`${translPrefix}.addBillHeader`)}
        closeText={t(`${translPrefix}.closeHeader`)}
        stepper={steps.map((step) => t(step))}
        currentStep={state.step}
      />

      {state.step === 0 && <AddBillStep1 propertySelectedFn={onPropertySelected} />}
      {state.step === 1 && state.property && (
        <AddBillStep2
          property={state.property}
          backFn={goBack}
          showLeaveModalFn={() => setState((prevState) => (
            { ...prevState, showLeaveModal: true }))}
          nextFn={save}
        />
      )}

    </div>
  );
};

export { AddBill };
