import { ApiService } from 'networking/api-service';
import { API_ROUTES } from 'networking/api-routes';
import { CountrySerializer } from 'networking/serializers/country-serializer';
import { Country } from 'models/country';
import { ExtendedFile, RawCity, RawPaginatorContainer } from '@mapix/common/src/types';
import { FileModel } from 'models/file-model';
import { ResourcesSerializer } from 'networking/serializers/resources-serializer';

class ResourcesController {
  static async getCountries() {
    const result = await ApiService.get<RawCountriesResponse>(API_ROUTES.COUNTRIES);
    const deSerialized = CountrySerializer.deSerializeResponse(result.data);

    return Object.keys(deSerialized).map((code) => new Country(deSerialized[code], code));
  }

  static async getCities(pageNumber: number, pageSize: number, query: string) {
    const result = await ApiService.get<RawPaginatorContainer<RawCity>>(
      API_ROUTES.CITIES(pageNumber, pageSize, query),
    );
    return ResourcesSerializer.deSerializePaginatedCities(result.data);
  }

  static async uploadFile(file: FileModel) {
    const formData = new FormData();
    formData.append('filename', file.filename);
    formData.append('resource', file.resource || '');
    const result = await ApiService.post<string>(
      API_ROUTES.UPLOAD(),
      formData,
      { headers: { 'content-type': 'multipart/form-data' } },
    );
    return result.data;
  }

  static async uploadFileV2(file: ExtendedFile, nuvei?: boolean) {
    const formData = new FormData();
    formData.append('filename', file.filename);
    formData.append('resource', file.resource || '');
    const result = await ApiService.post<string>(
      API_ROUTES.UPLOAD(nuvei),
      formData,
      { headers: { 'content-type': 'multipart/form-data' } },
    );
    return result.data;
  }
}

export { ResourcesController };
