import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { Tooltip, TooltipPosition } from '@mapix/common/src/common/tooltip';
import { classnames } from '@mapix/common/src/helpers/utils';
import { TaskStatus } from '@mapix/common/src/common/enums';
import { useTranslation } from 'react-i18next';
import styles from '../new-task-detail.module.scss';

type FooterProps = {
  translPrefix: string,
  taskStatus: TaskStatus | '',
  textButtonLeft: string,
  textButtonRight: string,
  disableLeftButton?: boolean,
  disableRightButton?: boolean,
  withTooltip?: boolean
  toolTipText?: string,
  isPrivateTaskWaitingConfirmation?: boolean;
  rightButtonFn?: () => void,
  leftButtonFn: () => void,
};

const getButtonRight = (
  taskStatus: TaskStatus | '',
  disableRightButton: boolean,
  withTooltip: boolean,
  textButtonRight: string,
  toolTipText: string,
  rightButtonFn?: () => void,
) => {
  if (taskStatus === TaskStatus.UNASSIGNED
    || taskStatus === TaskStatus.SOLVED
    || taskStatus === TaskStatus.CANCELLED) return null;

  const button = (
    <Button
      buttonStyle={ButtonStyle.Primary}
      className={classnames('text__body__large__textNeutral10', styles.button)}
      onClick={rightButtonFn}
      disabled={disableRightButton}
    >
      {textButtonRight}
    </Button>
  );

  if (withTooltip) {
    return (
      <Tooltip
        textComponent={button}
        hoverComponent={toolTipText}
        position={TooltipPosition.Left}
        containerWidth="280px"
        hideTooltip={!disableRightButton}
      />
    );
  }

  return button;
};

const Footer = ({
  translPrefix, taskStatus, disableLeftButton, disableRightButton, withTooltip, textButtonRight,
  textButtonLeft, toolTipText,
  isPrivateTaskWaitingConfirmation = false, rightButtonFn, leftButtonFn,
}: FooterProps) => {
  const { t } = useTranslation();

  const getLeftButtonColor = () => {
    if (taskStatus === TaskStatus.UNPUBLISHED) return ButtonStyle.Secondary;

    if (taskStatus === TaskStatus.SOLVED
      || taskStatus === TaskStatus.CANCELLED) return ButtonStyle.Primary;

    return ButtonStyle.SecondaryOutlined;
  };

  const getWarningLeftContent = () => {
    if (isPrivateTaskWaitingConfirmation) {
      return (
        <div className={classnames('text__body__small__textNeutral30', styles.flexCenter)}>
          {t(`${translPrefix}.footer.contractorSelectionDisclaimer`)}
        </div>
      );
    }

    return '';
  };

  return (
    <>
      <div className={styles.footerPadding} />
      <div className={styles.footer}>
        <hr className={styles.divider} />
        <div className={getWarningLeftContent()
          ? styles.buttonsWrapper : styles.buttonsWrapperFlexEnd}
        >
          {getWarningLeftContent()}

          <div className={classnames('text__body__medium__textNeutral10', 'row')}>
            <Button
              buttonStyle={getLeftButtonColor()}
              className={classnames('text__body__large__secondary80', styles.button)}
              disabled={disableLeftButton}
              onClick={leftButtonFn}
            >
              {textButtonLeft}
            </Button>

            {getButtonRight(
              taskStatus,
              disableRightButton || false,
              withTooltip || false,
              textButtonRight,
              toolTipText || '',
              rightButtonFn,
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { Footer };
