import { useContext } from 'react';
import { AppContext } from 'context';
import { TaskDatesOverview } from '@mapix/common/src/common/task-dates-overview';
import { TaskUpdatesOverview } from '@mapix/common/src/common/task-updates-overview';
import { CancellationInfoCard } from '@mapix/common/src/common/cancellation-info-card';
import { CancelledByCard } from '@mapix/common/src/common/cancelled-by-card';
import { NewTask } from 'networking/types';
import { useTranslation } from 'react-i18next';
import { getContractorSpecializationsList } from 'helpers/utils';
import { ContractorCard } from 'common/contractor-card';
import {
  classnames,
  getLastChangePercentageTaskEvent,
  getTotalPriceByTypeOfCost,
  isCancelledTaskOrWaitingPercentageApproval,
} from '@mapix/common/src/helpers/utils';
import {
  CancelledByType, RawNewTaskStatus, RawUsersRejectMovement,
} from '@mapix/common/src/common/enums';
import { BasicUserFields, TaskMessage } from '@mapix/common/src/types';
import styles from './status-tab.module.scss';

type StatusTabProps = {
  className?: string;
  task: NewTask;
  translPrefix: string;
  notifyAddNewUpdate: () => void;
};

const StatusTab = ({
  task, className = '', translPrefix, notifyAddNewUpdate,
}: StatusTabProps) => {
  const { t, i18n } = useTranslation();

  const translInnerPrefix = `${translPrefix}.status`;

  const {
    state: {
      user: { name: managerName, lastName: managerLastName },
    },
  } = useContext(AppContext);

  const mapMessages = () => (
    task.taskMessages.map((message: TaskMessage) => ({
      id: message.id,
      date: message.createdAt,
      message: message.text,
      body: message.text,
      taskMessagePhotos: message.taskMessagePhotos,
      senderUrl: message.sender === 'Contractor' ? task.contractor.profilePhoto?.url : task.managerProfilePhoto?.url,
      sender: message.sender,
      senderLastName: message.sender === 'Contractor'
        ? task.contractorAssignedLastName : managerLastName,
      senderName: message.sender === 'Contractor'
        ? task.contractorAssignedName : managerName,
    }
    )));

  const hasContractorReturnedTaskToInProgress = () => (
    task.status === RawNewTaskStatus.IN_PROGRESS
      && task.rejectedSolvedMovementBy === RawUsersRejectMovement.RejectedSolvedByContractor
  );

  const getRejectedMessage = () => (
    hasContractorReturnedTaskToInProgress() ? task.messageForSolvedMovementRejection : ''
  );

  const getWarningPillContent = () => (
    hasContractorReturnedTaskToInProgress() ? t(`${translInnerPrefix}.contratorMovedTask`) : ''
  );

  const {
    negotiatingStartDate,
    inProgressDate,
    solvedDate,
    contractor,
    cancelledBy,
    cancellationPercentage,
    cancellationMessage,
    reasonForCancellation,
    managerProfilePhoto,
    newTaskEvents,
  } = task;

  const manager: BasicUserFields = {
    id: -1,
    name: managerName,
    lastName: managerLastName,
    profilePhoto: managerProfilePhoto,
  };

  const getPaymentValue = (completionPercentage: number) => (
    (getTotalPriceByTypeOfCost({ ...task! }) * Number(completionPercentage)) / 100
  );

  const getCancellerBasicFields = (canceller: CancelledByType) => (
    canceller === CancelledByType.CancelledByContractor
      ? contractor : manager
  );

  const getCancelledDate = () => {
    if (isCancelledTaskOrWaitingPercentageApproval(task.status)) {
      return task.cancelledDate;
    }

    return undefined;
  };

  const getLastPaymentValue = () => {
    const lastPercentage = getLastChangePercentageTaskEvent(newTaskEvents)?.oldPercentage;

    return lastPercentage ? getPaymentValue(lastPercentage) : undefined;
  };

  const showContractorCard = () => (
    <ContractorCard
      name={task.contractorAssignedName}
      lastName={task.contractorAssignedLastName}
      t={t}
      url={contractor.profilePhoto?.url}
      categories={getContractorSpecializationsList(contractor.specializations)}
      warningPillContent={getWarningPillContent()}
      rejectedMessage={getRejectedMessage()}
      containerClass={styles.contractorCard}
    />
  );

  const showCancelledByCard = () => (
    <CancelledByCard
      cancelledBy={getCancellerBasicFields(cancelledBy)}
      reason={t(`reasonOptions.${reasonForCancellation}`)}
      className={styles.cancelledByCard}
      t={t}
    />
  );

  const showCancellationInfoCard = () => (
    <CancellationInfoCard
      cancelledBy={getCancellerBasicFields(cancelledBy)}
      message={cancellationMessage}
      completion={cancellationPercentage}
      oldCompletion={getLastChangePercentageTaskEvent(newTaskEvents)?.oldPercentage}
      payment={getPaymentValue(cancellationPercentage)}
      oldPayment={getLastPaymentValue()}
      className={styles.cancellationInfoCard}
      translPrefix={translInnerPrefix}
      t={t}
    />
  );

  return (
    <div className={classnames(styles.statusTabContainer, className)}>
      <div className={styles.contractorAndCancellationContainer}>
        <div className={styles.contractorAndCancelledBy}>
          {contractor && showContractorCard()}

          {isCancelledTaskOrWaitingPercentageApproval(task.status) && showCancelledByCard()}
        </div>

        {isCancelledTaskOrWaitingPercentageApproval(task.status) && showCancellationInfoCard()}
      </div>

      <hr className={styles.divider} />

      <TaskDatesOverview
        dates={{
          negotiating: negotiatingStartDate,
          in_progress: inProgressDate,
          solved: solvedDate,
          cancelled: getCancelledDate(),
        }}
        translPrefix={translInnerPrefix}
        t={t}
        language={i18n.language}
      />

      <hr className={styles.divider} />

      <TaskUpdatesOverview
        t={t}
        messages={mapMessages()}
        taskStatus={task.status}
        language={i18n.language}
        translPrefix={translInnerPrefix}
        userRole="Manager"
        containerClass={styles.updates}
        notifyAddNewUpdate={notifyAddNewUpdate}
      />
    </div>
  );
};

export { StatusTab };
