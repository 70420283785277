import { AddressSerializer } from '@mapix/common/src/networking/serializers/address-serializer';
import { BillSerializer as CommonBillSerializer } from '@mapix/common/src/networking/serializers/bill-serializer';
import { PropertySerializer } from 'networking/serializers/property-serializer';
import { UnitSerializer } from 'networking/serializers/unit-serializer';
import { NewBillDetail, RawNewBillDetail } from 'networking/types/bill-detail';
import { NewBillIndex, RawNewBillIndex } from 'networking/types/new-bill';

class BillSerializer {
  static deSerialize(data: RawBill): SerializedBill {
    return {
      billedAmount: data.billed_amount,
      category: data.category,
      currency: data.currency,
      dueDate: data.due_date,
      id: data.id,
      paymentDate: data.payment_date,
      property: PropertySerializer.deSerializeSimpleProperty(data.property),
      unit: data.unit ? UnitSerializer.deSerialize(data.unit) : undefined,
      taskId: data.task_id || undefined,
      status: data.status,
      additionalComments: data.additional_comments,
    };
  }

  static deSerializeNewBill(data: RawNewBillDetail): NewBillDetail {
    return {
      billedAmount: data.amount,
      currency: data.currency,
      dueDate: data.due_date,
      id: data.id,
      paymentDate: data.payment_date,
      address: AddressSerializer.deSerializeAddress(data.address),
      additionalComments: data.additional_comments || '',
      managerLastName: data.manager_last_name,
      managerName: data.manager_name,
      buildingName: data.building_name || '',
      gst: data.gst ?? undefined,
      pst: data.pst ?? undefined,
      hst: data.hst ?? undefined,
      qst: data.qst ?? undefined,
      processingFees: data.processing_fees,
      totalAmount: data.total_amount,
      status: data.status,
      task: data.new_task
        ? CommonBillSerializer.deSerializeNewTaskForBill(data.new_task) : undefined,
    };
  }

  static deSerializeNewBillIndex(data: RawNewBillIndex): NewBillIndex {
    return {
      id: data.id,
      createdAt: data.created_at,
      address: AddressSerializer.deSerializeAddress(data.address),
      category: data.category,
      dueDate: data.due_date ?? undefined,
      totalAmount: data.total_amount,
      buildingName: data.building_name ?? undefined,
      taskId: data.new_task_id ?? undefined,
      taskName: data.task_name ?? undefined,
      currency: data.currency,
      paymentDate: data.payment_date,
      payable: data.payable,
    };
  }

  static deSerializeNewBillIndexArray(data: RawNewBillIndex[]): NewBillIndex[] {
    return data.map((item) => BillSerializer.deSerializeNewBillIndex(item));
  }
}

export { BillSerializer };
