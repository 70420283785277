import { getFullAddress } from 'helpers/utils';
import { PropertyAddress } from 'models/property-address';

class SimpleProperty {
  buildingName: string;

  address: PropertyAddress;

  id: number;

  type: string;

  fullAddress: string;

  constructor(params: SerializedSimpleProperty) {
    this.buildingName = params.buildingName;
    this.address = new PropertyAddress(params.address);
    this.id = params.id;
    this.type = params.type;
    this.fullAddress = getFullAddress(params.address);
  }
}

export { SimpleProperty };
