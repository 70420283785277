import { classnames } from '../../helpers/utils';
import { useMediaQuery } from '../../hooks/use-media-query';
import { Breakpoints } from '../enums';
import { ReactComponent as Check } from '../../assets/icons/check.svg';
import styles from './stepper.module.scss';

type StepperProps = {
  steps: string[],
  currentStep: number,
  stepperClass?: string,
};

const resolveClassBasedOnStatus = (index: number, currentStep: number, isBubble: boolean) => {
  const classes = isBubble ? [styles.bubble] : [styles.line];
  if (index < currentStep) {
    classes.push(styles.completed);
  } else if (index === currentStep) {
    classes.push(styles.active);
  } else {
    classes.push(styles.next);
  }
  return classnames(...classes);
};

const Stepper = ({ steps, currentStep, stepperClass = '' }: StepperProps) => {
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  return (
    <div className={classnames(styles.stepperContainer, stepperClass)}>
      {steps.map((step, index) => (
        <div key={step} className={styles.stepContainer}>
          <div className={resolveClassBasedOnStatus(index, currentStep, true)}>
            {index < currentStep
              ? <Check className={styles.check} />
              : <div className={mobile ? 'text__body__tiny__textNeutral10' : 'text__body__medium__textNeutral10'}>{index + 1}</div>}
          </div>
          {!mobile && index === currentStep && <div className="text__body__medium__primary">{step}</div>}
          {index !== steps.length - 1
          && <div className={resolveClassBasedOnStatus(index, currentStep, false)} />}
        </div>
      ))}
    </div>
  );
};

export { Stepper };
