import { useTranslation } from 'react-i18next';
import { FormEvent, useEffect, useState } from 'react';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { logger } from 'helpers/logger';
import { Spinner } from '@mapix/common/src/common/spinner';
import { SalesProfile as CommonSalesProfile } from '@mapix/common/src/common/payment-information/pages/sales-profile';
import type { RawSalesProfile, SalesProfileType } from '@mapix/common/src/types/payment-information';
import { goToPage, RouteName } from 'routes';
import { checkHTMLErrors, isEmptyObject } from '@mapix/common/src/helpers/utils';
import { PaymentInformationController } from 'networking/controllers/payment-information-controller';
import { ApiError, ErrorStatus } from 'models/api-error';
import { PaymentInformationSerializer } from 'networking/serializers/payment-information-serializer';
import { RawErrorFields } from '@mapix/common/src/types';

const translPrefix = 'paymentInformation.salesProfileInformation';

const initSalesProfile = (): SalesProfileType => ({
  averageVisaMonthlyVolume: '',
  averageVisaTicketSize: '',
  visaHighTicketSize: '',
  averageMCMonthlyVolume: '',
  averageMCTicketSize: '',
  MCHighTicketSize: '',
  averageDebitMonthlyVolume: '',
  averageDebitTicketSize: '',
  debitHighTicketSize: '',
});

const SalesProfile = () => {
  // TODO payment information remove this redirection
  goToPage(RouteName.ErrorPage);

  const { t } = useTranslation();
  const [fetching, setFetching] = useState(true);
  const [showError, setShowError] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [salesProfile, setSalesProfile] = useState<
  SalesProfileType>(initSalesProfile());
  const [emptyForm, setEmptyForm] = useState(false);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;

    if (!target.checkValidity()) {
      setFormErrors(checkHTMLErrors(target));
      return;
    }

    if (!isEmptyObject(formErrors)) {
      return;
    }

    setFetching(true);
    try {
      if (emptyForm) {
        await PaymentInformationController.createSalesProfile(salesProfile);
      } else {
        await PaymentInformationController.updateSalesProfile(salesProfile);
      }
      setFetching(false);
      goToPage(RouteName.ManagerPaymentInformation);
    } catch (error) {
      if (error instanceof ApiError && typeof error.details === 'object') {
        setFormErrors(PaymentInformationSerializer
          .deSerializeSalesProfileErrors(error.details as RawErrorFields<RawSalesProfile>, t));
      } else {
        setShowError(true);
        logger.error(error as Error);
      }
      setFetching(false);
    }
  };

  const getSalesProfile = async () => {
    try {
      const actualSalesProfile = await PaymentInformationController.getSalesProfile();
      setSalesProfile(actualSalesProfile);
    } catch (e) {
      if (e instanceof ApiError && e.status === ErrorStatus.NotFound) {
        setEmptyForm(true);
      } else {
        logger.error(e as Error);
        setShowError(true);
      }
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    getSalesProfile();
  }, []);

  if (fetching) {
    return (<Spinner />);
  }

  return (
    <>
      {showError
      && (
        <ErrorMessage
          message={t('error.errorMessage')}
          handleClose={() => setShowError(false)}
        />
      )}
      <CommonSalesProfile
        t={t}
        backFn={() => goToPage(RouteName.ManagerPaymentInformation)}
        salesProfile={salesProfile}
        setSalesProfile={setSalesProfile}
        formErrors={formErrors}
        onSubmit={onSubmit}
        setFormErrors={setFormErrors}
        translPrefix={translPrefix}
        goToHomeFn={() => goToPage(RouteName.Home)}
        goToMyProfileFn={() => goToPage(RouteName.MyProfile)}
        goToPaymentInformationFn={() => goToPage(RouteName.ManagerPaymentInformation)}
      />

    </>
  );
};

export { SalesProfile };
