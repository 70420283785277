import { useTranslation } from 'react-i18next';
import styles from './read-only-forms.module.scss';

type UnitInfoProps = {
  buildingName: string,
  fullAddress: string,
  unitNumber: string
};

const translPrefix = 'propertyDetail.infoTab';
const UnitInfo = ({
  buildingName, fullAddress, unitNumber,
}: UnitInfoProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.itemBlock}>
        <div className="text__body__medium__textNeutral40">
          {t(`${translPrefix}.buildingName`)}
        </div>
        <div className="text__body__medium__textNeutral50">
          {buildingName}
        </div>
      </div>

      <div className={styles.itemBlock}>
        <div className="text__body__medium__textNeutral40">
          {t(`${translPrefix}.address`)}
        </div>
        <div className="text__body__medium__textNeutral50">
          {fullAddress}
        </div>
      </div>

      <div className={styles.itemBlock}>
        <div className="text__body__medium__textNeutral40">
          {t(`${translPrefix}.unitNumber`)}
        </div>
        <div className="text__body__medium__textNeutral50">
          {unitNumber}
        </div>
      </div>

    </div>
  );
};

export { UnitInfo };
