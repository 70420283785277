/* eslint-disable react/jsx-props-no-spreading */
import { ChangeEvent, HTMLProps, ReactNode } from 'react';
import { classnames, HTMLValidationError } from '../../helpers/utils';
import styles from './radio-button.module.scss';

type RadioButtonProperties = {
  labelContent?: string | ReactNode,
  labelClass?: string,
  containerClass?: string,
  onChangeFn: (e: ChangeEvent<HTMLInputElement>) => void,
  formError?: HTMLValidationError,
  checked?: boolean,
};

const handleStyle = (checked?: boolean, disabled?: boolean) => {
  if (disabled) return styles.inputDisabled;
  if (checked) return styles.inputChecked;
  return styles.input;
};
const RadioButtonMobile = ({
  labelContent, onChangeFn, labelClass = '', containerClass = '', formError, defaultChecked, checked, ...props
}: RadioButtonProperties & HTMLProps<HTMLInputElement>) => {
  const hasFormError = !!(props.id && formError && formError[props.id]);
  return (
    <>
      <label className={classnames(styles.label, containerClass)} htmlFor={props.id}>
        <div className={handleStyle(checked, props.disabled)}>
          <div className={checked ? styles.innerCircleChecked : styles.innerCircle}>
            <input onChange={onChangeFn} checked={checked} {...props} type="radio" className={styles.radio} />
          </div>
        </div>
        <div className={labelClass}>{labelContent}</div>
      </label>
      <div
        className={hasFormError
          ? 'text__body__tiny__danger50' : 'text__body__tiny__textNeutral40'}
      >
        {props.id && hasFormError && formError[props.id]}
      </div>
    </>
  );
};

export { RadioButtonMobile };
