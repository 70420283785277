import { useTranslation } from 'react-i18next';
import { FormEvent, useEffect, useState } from 'react';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { logger } from 'helpers/logger';
import { Spinner } from '@mapix/common/src/common/spinner';
import { UserController } from 'networking/controllers/user-controller';
import { OwnersOrOfficers as CommonOwnersOrOfficers } from '@mapix/common/src/common/payment-information/pages/owners-or-officers';
import type {
  DBAinformationType, OwnersOrOfficersType, LegalBusinessInfoType, RawOwnersOrOfficers,
} from '@mapix/common/src/types/payment-information';
import { goToPage, RouteName } from 'routes';
import { addError, checkHTMLErrors, isEmptyObject } from '@mapix/common/src/helpers/utils';
import { PaymentInformationController } from 'networking/controllers/payment-information-controller';
import { ApiError, ErrorStatus } from 'models/api-error';
import { PaymentInformationSerializer } from 'networking/serializers/payment-information-serializer';
import { RawErrorFields } from '@mapix/common/src/types';
import styles from '../../manager-payment-information.module.scss';

enum AddressSameAs {
  LEGAL = 'legal',
  DBA = 'dba',
}

const initOwnersOrOfficers: OwnersOrOfficersType = {
  name: '',
  lastName: '',
  email: '',
  confirmEmail: '',
  percentOwnership: '',
  dateOfBirth: '',
  civicNumber: '',
  streetName: '',
  unit: '',
  city: '',
  province: '',
  postalCode: '',
  primaryPhoneNumber: '',
  primaryCountryCode: '',
  sin: '',
  driversLic: '',
  driversLicProvince: '',
  cellPhoneNumber: '',
  cellCountryCode: '',
};

type SelectOptionsState = {
  province: string[],
  address: string[],
  driversLicProvince: string[]
};

const initialSelectOptionsState = {
  province: [],
  address: [AddressSameAs.DBA, AddressSameAs.LEGAL],
  driversLicProvince: [],
};

const OwnersOrOfficers = () => {
  // TODO payment information remove this redirection
  goToPage(RouteName.ErrorPage);

  const { t } = useTranslation();
  const [fetching, setFetching] = useState(true);
  const [showError, setShowError] = useState(false);
  const [phoneErrors, setPhoneErrors] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [selectOptions, setSelectOptions] = useState<SelectOptionsState>(initialSelectOptionsState);
  const [ownersOrOfficers, setOwnersOrOfficers] = useState<
  OwnersOrOfficersType>(initOwnersOrOfficers);
  const [addressSameAs, setAddressSameAs] = useState('');
  const [emptyForm, setEmptyForm] = useState(false);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    if (!target.checkValidity()) {
      setFormErrors(checkHTMLErrors(target));
      return;
    }

    if (ownersOrOfficers.email !== ownersOrOfficers.confirmEmail) {
      addError(t('error.differentEmails'), 'confirmEmail', setFormErrors);
      return;
    }

    if (!isEmptyObject(phoneErrors) || !isEmptyObject(formErrors)) {
      return;
    }

    setFetching(true);
    try {
      if (emptyForm) {
        await PaymentInformationController.createOwnersOrOfficers(ownersOrOfficers);
      } else {
        await PaymentInformationController.updateOwnersOrOfficers(ownersOrOfficers);
      }
      setFetching(false);
      goToPage(RouteName.ManagerPaymentInformation);
    } catch (error) {
      if (error instanceof ApiError && typeof error.details === 'object') {
        setFormErrors(PaymentInformationSerializer.deSerializeOwnersOrOfficersErrors(
          error.details as RawErrorFields<RawOwnersOrOfficers>,
          t,
        ));
      } else {
        setShowError(true);
        logger.error(error as Error);
      }
    } finally {
      setFetching(false);
    }
  };

  const handleSelectAddressSameAs = async (option: string) => {
    setFetching(true);
    setAddressSameAs(option);
    let result: DBAinformationType | LegalBusinessInfoType;
    let mobileCountryCode: string;
    let mobilePhoneNumber: string;
    try {
      if (option === AddressSameAs.LEGAL) {
        result = await PaymentInformationController.getLegalBusinessInfo();
        // These attributes are not present on DBAInformation, so handle them differently
        mobileCountryCode = result.mobileCountryCode || '';
        mobilePhoneNumber = result.mobilePhoneNumber || '';
      } else {
        result = await PaymentInformationController.getDBAInformation();
        mobileCountryCode = '';
        mobilePhoneNumber = '';
      }
      setOwnersOrOfficers((prevState) => ({
        ...prevState,
        civicNumber: result.civicNumber,
        unit: result.unit,
        province: result.province,
        primaryPhoneNumber: result.primaryPhoneNumber,
        primaryCountryCode: result.primaryCountryCode,
        postalCode: result.postalCode,
        city: result.city,
        cellCountryCode: mobileCountryCode || prevState.cellCountryCode,
        cellPhoneNumber: mobilePhoneNumber || prevState.cellPhoneNumber,
        streetName: result.streetName,
      }));
    } catch (error) {
      logger.log(error);
    } finally {
      setFetching(false);
    }
  };

  const getCanadaProvinces = async () => {
    try {
      const result = await PaymentInformationController.getCanadaProvinces();
      setSelectOptions((prevState) => ({
        ...prevState,
        province: result,
        driversLicProvince: result,
      }));
    } catch (e) {
      logger.error(e as Error);
      setShowError(true);
    }
  };

  const getOwnersOrOfficersData = async () => {
    try {
      const actualDBAInformation = await PaymentInformationController.getOwnersOrOfficers();
      setOwnersOrOfficers(actualDBAInformation);
    } catch (e) {
      if (e instanceof ApiError && e.status === ErrorStatus.NotFound) {
        setEmptyForm(true);
      } else {
        logger.error(e as Error);
        setShowError(true);
      }
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    getOwnersOrOfficersData();
    getCanadaProvinces();
  }, []);

  if (fetching) {
    return (<Spinner />);
  }

  return (
    <div className={styles.container}>
      {showError
      && (
        <ErrorMessage
          message={t('error.errorMessage')}
          handleClose={() => setShowError(false)}
        />
      )}
      <CommonOwnersOrOfficers
        t={t}
        backFn={() => { goToPage(RouteName.ManagerPaymentInformation); }}
        ownersOrOfficers={ownersOrOfficers}
        setOwnersOrOfficers={setOwnersOrOfficers}
        formErrors={{ ...formErrors, ...phoneErrors }}
        setPhoneErrors={setPhoneErrors}
        validatePhone={UserController.phoneValidation}
        onSubmit={onSubmit}
        setFormErrors={setFormErrors}
        selectOptions={selectOptions}
        addressSameAs={addressSameAs}
        handleSelectAddressSameAs={handleSelectAddressSameAs}
        goToHomeFn={() => goToPage(RouteName.Home)}
        goToMyProfileFn={() => goToPage(RouteName.MyProfile)}
        goToPaymentInformationFn={() => goToPage(RouteName.ManagerPaymentInformation)}
      />

    </div>
  );
};

export { OwnersOrOfficers };
