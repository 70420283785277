import { Bill } from 'models/bill';
import { Paginator } from 'models/paginator';
import { ModalFilterItem, ModalFilterObject } from 'common/filter-modal/filter-modal';
import { DateFilter } from 'common/filter-by-date/filter-by-date';
import { DateCodes } from 'common/enums';
import { BillCreate } from 'models/bill-create';

enum BillsTabs {
  Overdue = 'Overdue',
  Paid = 'Paid',
  Pending = 'Pending',
}

const initDateFilter = {
  startDate: '',
  endDate: '',
  code: DateCodes.All,
};

type URLFilters = {
  appliedFilters: ModalFilterItem[],
  dateFilters: DateFilter,
  tab: string | undefined,
};

const filters: ModalFilterObject[] = [
  {
    title: 'Category',
    type: 'category',
    items: [
      { name: 'Plumbing', code: 'Plumbing', parentType: 'category' },
      { name: 'Electricity', code: 'Electricity', parentType: 'category' },
      { name: 'Extermination', code: 'Extermination', parentType: 'category' },
      { name: 'Heating repair', code: 'Heating Repair', parentType: 'category' },
      { name: 'Minor Repair', code: 'Minor Repair', parentType: 'category' },
      { name: 'Gardening', code: 'Gardening', parentType: 'category' },
      { name: 'Snow removing', code: 'Snow Removing', parentType: 'category' },
      { name: 'Superintendent', code: 'Superintendent', parentType: 'category' },
      { name: 'Other', code: 'Other', parentType: 'category' },
    ],
  },
];

const billsOverdueHeaders: string[] = ['propertyType', 'fullAddress', 'affectedAreaDisplay', 'amount', 'category',
  'taskId', 'dueDate'];
const billsPendingHeaders: string[] = ['propertyType', 'fullAddress', 'affectedAreaDisplay', 'amount', 'category',
  'taskId', 'dueDate'];
const billsPaidHeaders: string[] = ['propertyType', 'fullAddress', 'affectedAreaDisplay', 'amount', 'category',
  'taskId', 'dueDate', 'paidOn'];

type BillState = {
  code: string,
  tab: string,
  query: string,
  headers: string[],
  paginator: Paginator<Bill>,
  error: boolean,
  filters: ModalFilterObject[],
  filterModal: boolean,
  appliedFilters: ModalFilterItem[],
  dateFilter: DateFilter,
  editBill: boolean,
  selectedBill: BillCreate,
  billAddress: string,
  billPropertyType: string,
  billPropertyId: number,
  billId: number,
  registerPayment: boolean,
  deleteBill: boolean,
};

const switchTab = (tab: string): string[] => {
  switch (tab) {
    case BillsTabs.Overdue:
      return billsOverdueHeaders;
    case BillsTabs.Paid:
      return billsPaidHeaders;
    case BillsTabs.Pending:
      return billsPendingHeaders;
    default:
      return billsOverdueHeaders;
  }
};

const initialState = (fromPropertyDashboard: boolean, urlFilters?: URLFilters) => ({
  code: 'INITIAL',
  tab: urlFilters?.tab || (fromPropertyDashboard ? '' : BillsTabs.Overdue),
  query: '',
  headers: switchTab(urlFilters?.tab || BillsTabs.Overdue),
  paginator: new Paginator(null, []),
  error: false,
  filters,
  filterModal: false,
  appliedFilters: urlFilters?.appliedFilters || [],
  dateFilter: urlFilters?.dateFilters || initDateFilter,
  editBill: false,
  selectedBill: new BillCreate(),
  billAddress: '',
  billPropertyType: '',
  billPropertyId: 0,
  billId: 0,
  registerPayment: false,
  deleteBill: false,
});

type Action =
  | { type: 'FETCHING' }
  | { type: 'ERROR' }
  | { type: 'TAB_CHANGED', newTab: string }
  | { type: 'SEARCH_QUERY', query: string }
  | { type: 'TOGGLE_FILTER_MODAL', filterModal: boolean }
  | { type: 'APPLY_FILTERS', filters: ModalFilterItem[] }
  | { type: 'APPLY_DATE_FILTERS', dateFilter: DateFilter }
  | { type: 'SHOW_BILL', selectedBill: Bill }
  | { type: 'CLEAR_FILTERS' }
  | { type: 'DISMISS_BILL' }
  | { type: 'CLOSE_REGISTER_PAYMENT' }
  | { type: 'CLOSE_DELETE_BILL' }
  | { type: 'SHOW_DELETE_BILL', billId: number }
  | { type: 'SHOW_REGISTER_PAYMENT', selectedBill: Bill }
  | { type: 'BILLS_FETCHED', paginator: Paginator<Bill> }
  | { type: 'PAGE_CHANGED', newPage: number };

const mapToCreateBill = (bill: Bill): BillCreate => ({
  affectedArea: bill.affectedArea,
  additionalComments: bill.additionalComments || '',
  billedAmount: bill.billedAmount,
  category: bill.category,
  currency: bill.currency,
  dueDate: bill.dueDate,
  paymentDate: bill.paidOn,
  unitId: bill.unit?.id,
  billPaid: bill.paidOn ? 'yes' : 'no',
  taskId: bill.taskId,
  unitNumber: bill.unit?.unitNumber,
});

const changePage = (
  currentPage: number,
  paginator: Paginator<Bill>,
): Paginator<Bill> => {
  const newPaginator = { ...paginator };
  newPaginator.currentPage = currentPage;
  return newPaginator;
};

function BillsTableReducer(state: BillState, action: Action): BillState {
  switch (action.type) {
    case 'TAB_CHANGED':
      return {
        ...state,
        tab: action.newTab,
        headers: switchTab(action.newTab),
        appliedFilters: [],
        dateFilter: initDateFilter,
      };
    case 'FETCHING':
      return {
        ...state,
        code: action.type,
      };
    case 'ERROR':
      return {
        ...state,
        error: !state.error,
      };
    case 'BILLS_FETCHED':
      return {
        ...state,
        paginator: action.paginator,
        code: 'READY',
      };
    case 'SEARCH_QUERY':
      return {
        ...state,
        query: action.query,
        code: 'FETCHING',
        paginator: changePage(1, state.paginator),
      };
    case 'TOGGLE_FILTER_MODAL':
      return {
        ...state,
        filterModal: action.filterModal,
      };
    case 'APPLY_FILTERS':
      return {
        ...state,
        filterModal: false,
        appliedFilters: action.filters,
        paginator: changePage(1, state.paginator),
      };
    case 'CLEAR_FILTERS':
      return {
        ...state,
        appliedFilters: [],
        paginator: changePage(1, state.paginator),
        query: '',
      };
    case 'APPLY_DATE_FILTERS':
      return {
        ...state,
        dateFilter: action.dateFilter,
        paginator: changePage(1, state.paginator),
      };
    case 'SHOW_BILL':
      return {
        ...state,
        editBill: true,
        selectedBill: mapToCreateBill(action.selectedBill),
        billPropertyType: action.selectedBill.propertyType,
        billAddress: action.selectedBill.fullAddress,
        billPropertyId: action.selectedBill.property.id,
        billId: action.selectedBill.id,
      };
    case 'DISMISS_BILL':
      return {
        ...state,
        editBill: false,
      };
    case 'SHOW_REGISTER_PAYMENT':
      return {
        ...state,
        registerPayment: true,
        billId: action.selectedBill.id,
        selectedBill: mapToCreateBill(action.selectedBill),
      };
    case 'CLOSE_REGISTER_PAYMENT':
      return {
        ...state,
        registerPayment: false,
      };
    case 'CLOSE_DELETE_BILL':
      return {
        ...state,
        deleteBill: false,
      };
    case 'SHOW_DELETE_BILL':
      return {
        ...state,
        billId: action.billId,
        deleteBill: true,
      };
    case 'PAGE_CHANGED':
      return {
        ...state,
        paginator: changePage(action.newPage, state.paginator),
        code: 'FETCHING',
      };
    default:
      return {
        ...state,
      };
  }
}

export {
  BillsTableReducer, initialState, BillsTabs, initDateFilter,
};
