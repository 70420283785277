import { Breadcrumbs, BreadcrumbsItem } from '@mapix/common/src/common/breadcrumbs';
import { RawNewTaskStatus, TaskStatus } from '@mapix/common/src/common/enums';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { Pill } from '@mapix/common/src/common/pill';
import { Spinner } from '@mapix/common/src/common/spinner';
import { TaskDatesOverview } from '@mapix/common/src/common/task-dates-overview';
import { classnames, resolveAddress } from '@mapix/common/src/helpers/utils';
import { useMediaQuery } from '@mapix/common/src/hooks/use-media-query';
import { ContractorCard } from 'common/contractor-card';
import { Breakpoints } from 'common/enums';
import { Menu } from 'common/menu';
import { logger } from 'helpers/logger';
import { parseTaskStatusTenant, resolvePillColorTenantByStatus } from 'helpers/utils';
import { newClaimInitialState } from 'initial-states/new-claim-initial-state';
import { MaintenanceController } from 'networking/controllers/maintenance-controller';
import { NewClaim } from 'networking/types/claim-auxiliar';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RouteName, goToPage } from 'routes';
import styles from './claim-detail.module.scss';

type ParamType = {
  id: string,
};

type List = {
  name: string,
  route: RouteName,
  pathParams?: {},
};

const translPrefix = 'claimDetail';

const PillToShow = (rawTaskStatus: RawNewTaskStatus, t: (text: string) => string) => {
  const status = parseTaskStatusTenant(rawTaskStatus);

  const color = resolvePillColorTenantByStatus(status as TaskStatus);

  return (
    <Pill
      content={t(`taskStatus.${status}`)}
      color={color}
      textStyle="text__body__medium__textNeutral10"
      containerClass="ml-32"
    />
  );
};

const breadcrumbs = (myClaims: string, claimDetail: string): BreadcrumbsItem[] => [
  {
    name: myClaims,
    goToPageFn: () => goToPage(RouteName.Claims),
  },
  {
    name: claimDetail,
    goToPageFn: () => null,
  },
];

const ClaimDetail = () => {
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  const [fetching, setFetching] = useState(true);
  const [showError, setShowError] = useState(false);
  const { t, i18n: { language } } = useTranslation();
  const [claim, setClaim] = useState<NewClaim>(newClaimInitialState);
  const { id } = useParams<ParamType>();
  const [errorServer, setErrorServer] = useState(false);

  const listPath: List[] = [
    { name: t('claims.home'), route: RouteName.HomeTenant },
    { name: t('claims.title'), route: RouteName.Claims },
    { name: t('claimDetail.claimDetail'), route: RouteName.ClaimDetail, pathParams: { id: claim?.id } },
  ];

  const getClaim = async () => {
    try {
      setFetching(true);
      const result = await MaintenanceController.getNewClaim(id);
      setClaim(result);
      setFetching(false);
    } catch (err) {
      logger.error(err as Error);
      setErrorServer(true);
      setFetching(false);
    }
  };

  useEffect(() => {
    getClaim();
  }, []);

  if (fetching) {
    return (
      <Spinner />
    );
  }

  const inProgressDate = () => {
    if (![RawNewTaskStatus.NEGOTIATION_PHASE, RawNewTaskStatus.READY,
      RawNewTaskStatus.STOP_APPLICATIONS_FOR_NEGOTIATION].includes(claim.status)) {
      return claim.startDate;
    }

    return undefined;
  };

  const estimatedStartDate = () => {
    if ([RawNewTaskStatus.NEGOTIATION_PHASE, RawNewTaskStatus.READY,
      RawNewTaskStatus.STOP_APPLICATIONS_FOR_NEGOTIATION].includes(claim.status)) {
      return claim.startDate;
    }

    return undefined;
  };

  const addressToShow = `${claim.buildingName || t('condoHouse')}  - ${resolveAddress(claim.address)}`;

  return (
    <>
      <div>
        {errorServer
      && <ErrorMessage message={t('error.errorMessage')} handleClose={() => setErrorServer(false)} />}
      </div>
      <div>
        {showError
          && <ErrorMessage message={t('error.errorMessage')} handleClose={() => setShowError(false)} />}

      </div>
      <div className={styles.column}>
        {!mobile
          && (
          <Breadcrumbs values={breadcrumbs(t('claimDetail.myClaims'), t('claimDetail.claimDetail'))} />
          )}
        <div className={styles.container}>
          { mobile
          && (
          <>
            <Menu list={listPath} className={styles.menu} />
            <div className={styles.iconRight}>
              {PillToShow(claim.status, t)}
            </div>

          </>
          )}
          <div className={mobile ? styles.title : styles.row}>
            <div className={mobile ? 'text__heading1__textNeutral40' : 'text__heading4__textNeutral50'}>
              {`${claim.category} - ${claim.name} (${claim.id})`}
            </div>
            {!mobile && PillToShow(claim.status, t)}
          </div>
          {!mobile
          && (
            <div className="text__heading5__textNeutral50 mt-8">
              {addressToShow}
            </div>
          )}
          {mobile
            ? (
              <div className={styles.section}>
                <div className={classnames(styles.bold, 'text__heading3__textNeutral50')}>
                  {t('claimDetail.generalInfo')}
                </div>
                <div className={styles.content}>
                  <div className="text__heading4__textNeutral40">
                    {`${t('claimDetail.category.title')}:`}
                  </div>
                  <div className="text__body__medium__textNeutral40">
                    {t(`specializations.${claim.category}`)}
                  </div>
                </div>
                <div className={styles.content}>
                  <div className="text__heading4__textNeutral40">
                    {`${t('claimDetail.address')}:`}
                  </div>
                  <div className="text__body__medium__textNeutral40">
                    {addressToShow}
                  </div>
                </div>
                <div className={styles.content}>
                  <div className="text__heading4__textNeutral40">
                    {`${t('claimDetail.description')}:`}
                  </div>
                  <div className="text__body__medium__textNeutral40">
                    {claim.description}
                  </div>
                </div>
                {claim.reasonForRejection && (
                <div className={styles.content}>
                  <div className="text__heading4__textNeutral40">
                    {`${t('claimDetail.reasonForRejection')}:`}
                  </div>
                  <div className="text__body__medium__textNeutral40">
                    {claim.reasonForRejection || 'hello'}
                  </div>
                </div>
                )}
              </div>
            )
            : (
              <>
                <div className={styles.section}>
                  <div className="text__body__large__textNeutral50">
                    {`${t('claimDetail.category.title')}:`}
                  </div>
                  <div className={classnames(styles.margin, 'text__body__medium__textNeutral40')}>
                    {t(`specializations.${claim.category}`)}
                  </div>
                </div>
                <div className={styles.section}>
                  <div className="text__body__large__textNeutral50">
                    {`${t('taskDetail.description')}:`}
                  </div>
                  <div className={classnames(styles.margin, 'text__body__medium__textNeutral40')}>
                    { claim.description}
                  </div>
                </div>
                {claim.reasonForRejection && (
                <div className={styles.section}>
                  <div className="text__body__large__textNeutral50">
                    {`${t('taskDetail.reasonForRejection')}:`}
                  </div>
                  <div className={classnames(styles.margin, 'text__body__medium__textNeutral40')}>
                    { claim.reasonForRejection}
                  </div>
                </div>
                )}
              </>
            )}
          <div className={classnames(styles.section, 'mb-56')}>
            {mobile
              ? (
                <div className={classnames(styles.bold, 'text__heading3__textNeutral50')}>
                  {`${t('claimDetail.images')}:`}
                </div>
              )
              : (
                <div className="text__body__large__textNeutral50">
                  {`${t('taskDetail.photos')}:`}
                </div>
              )}
            <div className={styles.photos}>
              {
              claim.photos.length > 0
                ? claim.photos?.map((elem) => (
                  <img src={elem.url} alt="" className={styles.photo} />
                ))
                : (
                  <div className={classnames(styles.italic, 'text__body__medium__textNeutral40')}>
                    {t('taskDetail.emptyPhotos')}
                  </div>
                )
              }
            </div>
          </div>
          <TaskDatesOverview
            t={t}
            dates={{
              created: claim.createdAt,
              in_progress: inProgressDate(),
              solved: claim.solvedDate,
              cancelled: claim.cancelledDate,
            }}
            language={language}
            estimatedStartDate={estimatedStartDate()}
            translPrefix={translPrefix}
          />
          {claim.contractor && (
            <div className={styles.content}>
              <div className="text__body__large__textNeutral50 mt-56">
                {`${t(`${translPrefix}.assignedContractor`)}:`}
              </div>
              <ContractorCard
                categories={claim.contractor.specializations}
                lastName={claim.contractor.lastName}
                name={claim.contractor.name}
                url={claim.contractor.profilePhoto?.url}
                t={t}
                containerClass={styles.contractorCard}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export { ClaimDetail };
