import { BasicUserFields } from '../../types';
import { classnames } from '../../helpers/utils';
import styles from './cancelled-by-card.module.scss';

type CancelledByCardProps = {
  cancelledBy: BasicUserFields;
  reason: string;
  t: (text: string) => string,
  className?: string;
};

const CancelledByCard = ({
  cancelledBy, reason, t, className = '',
}: CancelledByCardProps) => (
  <div className={classnames(styles.cancelledByContainer, className)}>
    <div className="text__body__medium__textNeutral40">
      <span>
        {t('cancelledBy')}
      </span>
      <span className="bold">
        {` ${cancelledBy.name} ${cancelledBy.lastName}`}
      </span>
    </div>
    <div className="mt-12 text__body__medium__textNeutral40">
      <span>
        {`${t('reason')}: `}
      </span>
      <span>
        {` ${reason}`}
      </span>
    </div>
  </div>
);

export { CancelledByCard };
