import { Input, InputStyle } from '@mapix/common/src/common/input';
import {
  ChangeEvent, FormEvent, useEffect, useState,
} from 'react';
import { Select } from '@mapix/common/src/common/select';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { ReactComponent as Check } from 'assets/icons/check-black.svg';
import { Unit } from 'models/unit';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import {
  classnames, HTMLValidationError, checkHTMLErrors, isEmptyObject, addError,
} from '@mapix/common/src/helpers/utils';
import {
  checkNumberInput,
} from 'helpers/utils';
import { Button, ButtonStyle, ButtonType } from '@mapix/common/src/common/button';
import { Lease } from 'models/lease';
import { Services } from 'models/services';
import { useTranslation } from 'react-i18next';
import { LeaseForm } from 'pages/create-property/create-building/lease-form';
import { PropertyStatus } from 'common/enums';
import styles from './create-property-building.module.scss';

type UnitFormProps = {
  saveUnitFn: (unit: Unit) => void,
  repeated: boolean,
  fetchedUnit?: Unit,
  fromCreateUnit?: boolean,
  isSaveButtonDisabled?: boolean,
  setUnitModified?: (modified: boolean) => void,
  unitModified?: boolean,
  clearUnit?: boolean,
};

const translPrefix = 'units';
const leaseTranslPrefix = 'units.lease';

const UnitForm = ({
  saveUnitFn,
  repeated,
  fetchedUnit,
  fromCreateUnit,
  setUnitModified,
  unitModified,
  isSaveButtonDisabled = false,
  clearUnit = false,
}: UnitFormProps) => {
  const [unit, setUnit] = useState<Unit>(new Unit(null));
  const [lease, setLease] = useState<Lease>(new Lease(null));
  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});
  const [phoneErrors, setPhoneErrors] = useState<HTMLValidationError>({});

  const [otherText, setOtherText] = useState<string>('');

  const { t } = useTranslation();
  useEffect(() => {
    if (fetchedUnit) {
      setUnit(fetchedUnit);
      if (fetchedUnit.lease) {
        setLease(fetchedUnit.lease);
        setOtherText(fetchedUnit.lease.services.other);
      }
    }
  }, [fetchedUnit]);

  useEffect(() => {
    if (clearUnit) {
      setUnit(new Unit(null));
    }
  }, [clearUnit]);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;

    if (!target.checkValidity() || (!lease.tenant.phoneNumber
    && unit.status === PropertyStatus.Rented)) {
      setFormErrors(checkHTMLErrors(target));
      if (!lease.tenant.phoneNumber && unit.status === PropertyStatus.Rented) {
        addError(t('error.emptyField'), 'phoneNumber', setPhoneErrors);
      }
      return;
    }

    if (!isEmptyObject(phoneErrors)) {
      return;
    }
    if (unit.status === PropertyStatus.Rented) {
      if (otherText) {
        lease.services.other = otherText;
      }
      if (lease.yearlyRental) {
        lease.monthlyRental = Math.floor(lease.yearlyRental / 12);
      }
      unit.lease = lease;
    }
    saveUnitFn(unit);
    setUnit(new Unit(null));
    setLease(new Lease(null));
    setOtherText('');
    setFormErrors({});
    if (setUnitModified) {
      setUnitModified(false);
    }
  };

  const onClickService = (key: keyof Services) => {
    if (setUnitModified) {
      setUnitModified(true);
    }
    const modifiedService = { ...lease.services };
    /* TS seems to have problems with boolean types and using keyof
    * more here https://github.com/microsoft/TypeScript/issues/31663 */
    (modifiedService as any)[key] = !modifiedService[key];

    setLease({ ...lease, services: modifiedService });
  };

  const onChangeTenant = (e: ChangeEvent<HTMLInputElement>) => {
    if (setUnitModified) {
      setUnitModified(true);
    }
    const newTenant = { ...lease.tenant, [e.target.id]: e.target.value };
    setLease({ ...lease, tenant: newTenant });
  };

  const onChangePhoneNumber = (phoneNumber: string, countryCode: string) => {
    if (setUnitModified) {
      setUnitModified(true);
    }
    setLease((prevState) => (
      {
        ...prevState,
        tenant: {
          ...prevState.tenant, phoneNumber, countryCode,
        },
      }
    ));
  };

  const updateFiles = (field: string, files: FileType[]) => {
    if (setUnitModified) {
      setUnitModified(true);
    }
    if (field === 'pdf') {
      setLease((prevState) => ({ ...prevState, leaseCopy: files[0] }));
    } else {
      setLease((prevState) => ({ ...prevState, [field]: files }));
    }
  };

  const onChangeYearlyOrMonthly = (e: ChangeEvent<HTMLInputElement>) => {
    if (setUnitModified) {
      setUnitModified(true);
    }
    if (checkNumberInput(e)) {
      const { target } = e;
      const newLease = { ...lease };
      if (Number(target.value) === 0) {
        newLease.yearlyRental = 0;
        newLease.monthlyRental = 0;
        setLease(newLease);
      } else {
        setLease((prevState) => ({ ...prevState, [target.name]: Number(target.value) }));
      }
    }
  };

  const onChangeDateFn = (date: string, option: string) => {
    if (setUnitModified) {
      setUnitModified(true);
    }
    setLease(
      {
        ...lease,
        [option]: date,
      },
    );
  };

  const onChangeLease = (e: ChangeEvent<HTMLInputElement>) => {
    if (setUnitModified) {
      setUnitModified(true);
    }
    setLease(
      { ...lease, [e.target.name]: e.target.value },
    );
  };

  const discardUnit = () => {
    setLease(new Lease(null));
    setUnit(new Unit(null));
    setOtherText('');
    if (setUnitModified) {
      setUnitModified(false);
    }
  };

  const onChangeUnit = (name: string, option: string) => {
    setFormErrors({});
    setPhoneErrors({});
    if (setUnitModified) {
      setUnitModified(true);
    }
    setUnit({ ...unit, [name]: option });
  };

  const getButton = () => {
    if (fromCreateUnit) {
      return (
        <div className={styles.footer}>
          <Button
            buttonType={ButtonType.Submit}
            buttonStyle={ButtonStyle.Primary}
            className={classnames(styles.saveButton, 'text__button__medium__textNeutral50')}
            disabled={isSaveButtonDisabled}
          >
            <div className={styles.addUnit}>
              <div className="text__button__large__textNeutral10">{t(`${translPrefix}.addUnit`)}</div>
              <Plus className={styles.plusIcon} />
            </div>
          </Button>
        </div>
      );
    }
    if (unitModified) {
      return (
        <div className={unit.status === 'rented' ? styles.rentedUnit : ''}>
          <Button
            onClick={discardUnit}
            buttonType={ButtonType.Button}
            buttonStyle={ButtonStyle.Secondary}
            className={classnames(styles.marginDiscardUnit, styles.unitButton)}
          >
            <div className={classnames('text__button__medium__primary', styles.unitButtonAlign)}>
              <Close className={styles.closeIcon} />
              {t(`${translPrefix}.cancelAndDiscard`)}
            </div>
          </Button>
          <Button
            buttonType={ButtonType.Submit}
            buttonStyle={ButtonStyle.Primary}
            disabled={isSaveButtonDisabled}
            className={styles.unitButton}
          >
            <div className={classnames(isSaveButtonDisabled ? 'text__button__medium__textNeutral50' : 'text__button__medium__textNeutral10', styles.unitButtonAlign)}>
              <Check className={styles.checkIcon} />
              {t(`${translPrefix}.confirmAndSave`)}
            </div>
          </Button>
        </div>
      );
    }
    return null;
  };

  return (
    <form noValidate onSubmit={onSubmit} className={styles.formContainer}>
      <div className="text__heading6__textNeutral50">{t(`${translPrefix}.title`)}</div>
      <div className={styles.row}>
        <Input
          required
          min={1}
          id="unitNumber"
          name="unitNumber"
          label={t(`${translPrefix}.unitNumberLabel`)}
          placeholder={t(`${translPrefix}.unitNumberLabel`)}
          value={unit.unitNumber}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeUnit(
            e.target.name,
            e.target.value,
          )}
          inputStyle={InputStyle.FORM}
          containerClass={styles.input}
          formError={formErrors}
          error={repeated}
          helperText={repeated ? t(`${translPrefix}.unitExists`) : ''}
          t={t}
        />

        <Select
          options={Object.values(PropertyStatus).map((value) => value)}
          onClickOption={(option) => onChangeUnit('status', option)}
          optionTextClass={classnames(styles.options, 'text__body__small__textNeutral50')}
          required
          id="status"
          label={t(`${translPrefix}.selectStatus`)}
          placeholder={t(`${translPrefix}.selectStatus`)}
          value={unit.status ? `${t(`${translPrefix}.status.${unit.status}`)}` : ''}
          inputStyle={InputStyle.FORM}
          containerClass={classnames(styles.input, styles.select)}
          formError={formErrors}
          translationPrefix={`${translPrefix}.status`}
          t={t}
        />
      </div>

      {unit.status === PropertyStatus.Available || unit.status === null
        ? getButton()
        : (
          <>
            <div className="text__heading6__textNeutral50">{t(`${leaseTranslPrefix}.title`)}</div>

            <LeaseForm
              lease={lease}
              onChangeLeaseFn={(e: ChangeEvent<HTMLInputElement>) => onChangeLease(e)}
              onChangeDateFn={onChangeDateFn}
              onChangeTenantFn={onChangeTenant}
              onChangePhoneNumber={onChangePhoneNumber}
              onClickServiceFn={(service: string) => onClickService(service as keyof Services)}
              onChangeFilesFn={(
                field: string,
                files: FileType[],
              ) => updateFiles(field, files)}
              formErrors={{ ...formErrors, ...phoneErrors }}
              onChangeOtherText={(e) => setOtherText(e.target.value)}
              onClickSelectFn={(field, value) => setLease({ ...lease, [field]: value })}
              otherText={otherText}
              onChangeYearlyMonthly={onChangeYearlyOrMonthly}
              propertyAvailable
              setPhoneErrors={setPhoneErrors}
            />

            {getButton()}

          </>

        )}
    </form>
  );
};

export { UnitForm };
