import { classnames } from '@mapix/common/src/helpers/utils';
import styles from 'pages/property-detail/property-detail.module.scss';
import { CondoInfo, ContactInfo, LeaseInfo } from 'common/detail-read-only-forms';
import { Property } from 'models/property';
import { Condition, PropertyStatus } from 'common/enums';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { goToPage, RouteName } from 'routes';
import { ReactComponent as EditPencil } from 'assets/icons/edit-black.svg';
import { FilesInfo } from 'common/detail-read-only-forms/files-info';
import { useTranslation } from 'react-i18next';

type CondoInfoProps = {
  property: Property
};

const translPrefix = 'propertyDetail';
const CondoInfoTab = ({ property }: CondoInfoProps) => {
  const { t } = useTranslation();

  return (
    <>

      {property.condition === Condition.Active && (
        <>

          <div className={styles.buttonRow}>
            {property.status === PropertyStatus.Rented && (
              <>
                <Button
                  buttonStyle={ButtonStyle.Secondary}
                  onClick={() => {
                    goToPage(RouteName.RenewLease, { id: Number(property.lease?.id) });
                  }}
                >
                  <div className="text__button__medium__primary60">
                    {t(`${translPrefix}.buttons.renewLease`)}
                  </div>
                </Button>
                <Button
                  buttonStyle={ButtonStyle.Secondary}
                  onClick={() => {
                    goToPage(RouteName.EndLease, { id: Number(property.lease?.id) });
                  }}
                >
                  <div className="text__button__medium__primary60">
                    {t(`${translPrefix}.buttons.endLease`)}
                  </div>
                </Button>

              </>
            )}

            {property.status === PropertyStatus.Available && (
              <Button
                buttonStyle={ButtonStyle.Secondary}
                onClick={() => goToPage(RouteName.SetAsRented, { id: property.id })}
              >
                <div className="text__button__medium__primary60">
                  {t(`${translPrefix}.buttons.setRented`)}
                </div>
              </Button>
            )}

            <Button
              onClick={() => goToPage(RouteName.EditProperty, { id: property.id })}
              className={styles.button}
            >
              {t(`${translPrefix}.buttons.edit`)}
              <EditPencil className={styles.icon} />
            </Button>

          </div>

          <div className={classnames(styles.contentTitle, 'text__body__large__textNeutral50')}>
            {t(`${translPrefix}.infoTab.statusTitle`)}
            {' '}
            {property.status}
          </div>

          <div className={styles.line} />
        </>
      )}

      <div className={classnames(styles.contentTitle, 'text__body__large__textNeutral50')}>
        {t(`${translPrefix}.infoTab.locationTitle`)}
      </div>

      <CondoInfo fullAddress={property.fullAddress} />

      <div className={styles.line} />

      <div className={classnames(styles.contentTitle, 'text__body__large__textNeutral50')}>
        {t(`${translPrefix}.infoTab.contactTitle`)}
      </div>

      <ContactInfo owner={property.owner} otherOwner={property.otherOwner} isUnit={false} />

      {property.status === PropertyStatus.Rented && property.lease && (
        <>
          <div className={styles.line} />

          <div className={classnames(styles.contentTitle, 'text__body__large__textNeutral50')}>
            {t(`${translPrefix}.infoTab.leaseTitle`)}
          </div>

          <LeaseInfo leaseDetail={property.lease} />

          <div className={styles.line} />

          <div className={classnames(styles.contentTitle, 'text__body__large__textNeutral50')}>
            {t(`${translPrefix}.infoTab.filesTitle`)}
          </div>

          <FilesInfo
            unitPhotos={property.lease.unitPhotos}
            leaseCopy={property.lease.leaseCopy}
            tenantPhotos={property.lease.tenantIdPhotos}
          />

        </>
      )}
    </>
  );
};

export { CondoInfoTab };
