import { useTranslation } from 'react-i18next';
import { FormEvent, useEffect, useState } from 'react';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { logger } from 'helpers/logger';
import { Spinner } from '@mapix/common/src/common/spinner';
import { DocumentsInformation as CommonDocumentsInformation } from '@mapix/common/src/common/payment-information/pages/documents-information';
import type { DocumentsInformationType } from '@mapix/common/src/types/payment-information';
import { goToPage, RouteName } from 'routes';
import { checkHTMLErrors, isEmptyObject } from '@mapix/common/src/helpers/utils';
import { constants } from 'config/constants';
import { ResourcesController } from 'networking/controllers/resources-controller';
import { PaymentInformationController } from 'networking/controllers/payment-information-controller';
import { ApiError, ErrorStatus } from 'models/api-error';

const translPrefix = 'paymentInformation.documentsInformation';

const initDocumentsInformation = (): DocumentsInformationType => ({
  applicationAndAgreement: {
    filename: '',
    url: '',
  },
  proofOfBusiness: {
    filename: '',
    url: '',
  },
  voidCheck: {
    filename: '',
    url: '',
  },
  processingStatements: {
    filename: '',
    url: '',
  },
  equipmentForm: {
    filename: '',
    url: '',
  },
  nonProfitEvidence: {
    filename: '',
    url: '',
  },
  other: {
    filename: '',
    url: '',
  },
});

const DocumentsInformation = () => {
  // TODO payment information remove this redirection
  goToPage(RouteName.ErrorPage);

  const { t } = useTranslation();
  const [fetching, setFetching] = useState(true);
  const [showError, setShowError] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [documentsInformation, setDocumentsInformation] = useState<
  DocumentsInformationType>(initDocumentsInformation());
  const [emptyForm, setEmptyForm] = useState(false);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;

    if (!target.checkValidity()) {
      setFormErrors(checkHTMLErrors(target));
      return;
    }

    if (!isEmptyObject(formErrors)) {
      return;
    }

    setFetching(true);
    try {
      if (emptyForm) {
        await PaymentInformationController.createDocumentsInformation(documentsInformation);
      } else {
        await PaymentInformationController.updateDocumentsInformation(documentsInformation);
      }
      setFetching(false);
      goToPage(RouteName.ManagerPaymentInformation);
    } catch (error) {
      logger.error(error as Error);
      setShowError(true);
      setFetching(false);
    }
  };

  const getDocumentsInformation = async () => {
    try {
      const actualDocumentsInformation = await PaymentInformationController
        .getDocumentsInformation();
      setDocumentsInformation(actualDocumentsInformation);
    } catch (e) {
      if (e instanceof ApiError && e.status === ErrorStatus.NotFound) {
        setEmptyForm(true);
      } else {
        logger.error(e as Error);
        setShowError(true);
      }
    }
    setFetching(false);
  };

  useEffect(() => {
    getDocumentsInformation();
  }, []);

  if (fetching) {
    return (<Spinner />);
  }

  return (
    <>
      {showError
      && (
        <ErrorMessage
          message={t('error.errorMessage')}
          handleClose={() => setShowError(false)}
        />
      )}
      <CommonDocumentsInformation
        t={t}
        backFn={() => goToPage(RouteName.ManagerPaymentInformation)}
        documentsInformation={documentsInformation}
        setDocumentsInformation={setDocumentsInformation}
        formErrors={formErrors}
        onSubmit={onSubmit}
        setFormErrors={setFormErrors}
        translPrefix={translPrefix}
        imageMaxSize={constants.nuveiImageMaxSize}
        pdfMaxSize={constants.nuveiPdfMaxSize}
        uploadFile={ResourcesController.uploadFileV2}
        goToHomeFn={() => goToPage(RouteName.Home)}
        goToMyProfileFn={() => goToPage(RouteName.MyProfile)}
        goToPaymentInformationFn={() => goToPage(RouteName.ManagerPaymentInformation)}
      />

    </>
  );
};

export { DocumentsInformation };
