import ReactHtmlParser from 'react-html-parser';
import { classnames } from '../../helpers/utils';
import { Spinner } from '../spinner';
import styles from './filtered-select.module.scss';

type FilteredSelectContentProps = {
  options: string[],
  deferredValue: string,
  errorStyle: string,
  t: (text: string) => string,
  selectItem: (option: string) => void,
  query: string,
  code: string,
};

const preprocessQuery = (query: string) => query.replace(/(\\)+/g, '');

const convertToRegex = (query: string) => new RegExp(preprocessQuery(query), 'gi');

const makeBold = (option: string, query: string) => {
  const re = convertToRegex(query);
  return (
    option.replace(re, (match) => `<strong>${match}</strong>`)
  );
};

const FilteredSelectContent = ({
  options,
  deferredValue,
  errorStyle,
  t,
  selectItem,
  query,
  code,
}: FilteredSelectContentProps) => {
  const isStale = query !== deferredValue || code === 'FETCHING';

  if (isStale) {
    return (
      <div className={styles.spinner}>
        <Spinner fixed={false} />
      </div>
    );
  }

  const filteredOptions = options.filter(
    (option) => option.match(convertToRegex(query)),
  );

  if (filteredOptions.length === 0) {
    return (
      <div className={errorStyle}>
        {t('error.noResults')}
      </div>
    );
  }

  return (
    <>
      {filteredOptions.map((option) => (
        <button
          key={option}
          type="button"
          onClick={() => selectItem(option)}
          className={classnames(styles.item, 'text__body__medium__textNeutral50')}
        >
          <span>{ReactHtmlParser(makeBold(option, deferredValue))}</span>
        </button>
      ))}
    </>

  );
};

export { FilteredSelectContent };
