/* eslint-disable react/no-children-prop */
import { Users } from 'common/enums';
import { AppContext } from 'context';
import { useContext } from 'react';
import { AppRedirect, RouteName } from 'routes';

const Home = () => {
  const { state } = useContext(AppContext);
  if (state.user.loginRole === Users.Manager) {
    return (
      <AppRedirect routeName={RouteName.Properties} />
    );
  }
  return (
    <AppRedirect routeName={RouteName.HomeTenant} />
  );
};

export { Home };
