import { FileExtension } from '../common/enums';

export const bytesToMegabytes = (bytes: number) => bytes / 1024 ** 2;

export const formatFileExtensions = (extensions: FileExtension[]) => extensions.join(',');

export const getFileExtension = (fileName: string) => `.${fileName.split('.').at(-1)}`;

export const isValidSize = (maxSize: number, currentSize: number) => currentSize <= maxSize;

export const isSupportedExtension = (
  extension: string,
  supportedExtensions: Array<FileExtension>,
) => supportedExtensions.some((ext) => ext === extension);

export const isValidFile = async (
  fileExtension: string,
  fileSize: number,
  uploadErrors: { generic: string; fileFormat?: string; fileSize?: string },
  supportedExt: Array<FileExtension>,
  mbMaxSize: number,
): Promise<string> => {
  if (supportedExt.length
    && (!isSupportedExtension(fileExtension.toLocaleLowerCase(), supportedExt))) {
    return uploadErrors.fileFormat || uploadErrors.generic;
  }

  if (!isValidSize(mbMaxSize, bytesToMegabytes(fileSize))) {
    return uploadErrors.fileSize || uploadErrors.generic;
  }

  return '';
};
