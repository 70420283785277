import { logger } from 'helpers/logger';
import { Storage } from 'helpers/storage';

const STORAGE_LANGUAGE_KEY = 'i18next-lng';

enum SupportedLanguages {
  English = 'en',
  French = 'fr',
}

const detectLanguage = () => {
  // First check the storage
  const language = Storage.get(STORAGE_LANGUAGE_KEY) as string;
  if (language) {
    logger.log(`Language extracted from storage: ${language}`);
    return language;
  }

  // Then check the navigator
  if (!window.navigator || !window.navigator.language) {
    logger.log('Navigator not available, unable to extract language');
    return null;
  }
  // NOTE: for now we only care about the language, not the locale.
  // So if navigator.language === 'en-US' we split it so that we only return
  // 'en'.
  const navigatorLanguage = window.navigator.language.split('-')[0];

  // @ts-ignore
  const enumLanguage: SupportedLanguages | undefined = <any> SupportedLanguages[navigatorLanguage];
  if (!enumLanguage) {
    logger.log(`Found the following not compatible language from the navigator: ${navigatorLanguage}
    , setting to default language ${SupportedLanguages.English}`);
    return SupportedLanguages.English;
  }

  logger.log(`Language extracted from navigator: ${navigatorLanguage}`);
  return navigatorLanguage;
};

const saveLanguage = (language: string) => {
  Storage.set(STORAGE_LANGUAGE_KEY, language);
};

const getLanguage = () => Storage.get(STORAGE_LANGUAGE_KEY);

export {
  detectLanguage, saveLanguage, getLanguage, SupportedLanguages,
};
