import {
  ChangeEvent, useEffect, useReducer, useState,
} from 'react';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import { addOrRemoveFromArrayObject, classnames } from '@mapix/common/src/helpers/utils';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { ReactComponent as Filter } from 'assets/icons/filter.svg';
import { ReactComponent as PlusCircle } from 'assets/icons/plus-circle.svg';
import { ReactComponent as Tool } from 'assets/icons/tool.svg';
import { Table } from 'common/table';
import { logger } from 'helpers/logger';
import { MaintenanceController } from 'networking/controllers/maintenance-controller';
import { ReactComponent as NoResultsIcon } from 'assets/icons/search-no-results-solid.svg';
import { Pagination } from '@mapix/common/src/common/paginator';
import { Modal } from '@mapix/common/src/common/modal';
import { Spinner } from '@mapix/common/src/common/spinner';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { FilterModal } from 'common/filter-modal';
import { ModalFilterItem } from 'common/filter-modal/filter-modal';
import { FiltersApplied } from 'common/filters-applied';
import { useTranslation } from 'react-i18next';
import { goToPage, RouteName } from 'routes';
import { FilterByDate } from 'common/filter-by-date';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { EmptyState } from 'common/empty-state';
import { MaintenanceTableItem } from 'models/maintenance-table-item';
import styles from './maintenance-task-tab.module.scss';
import { initialState, MaintenanceTaskReducer } from './maintenance-task-tab-reducer';

type MaintenanceTasksTabProps = {
  id: number,
  type: string,
  propertyCondition?: string,
  unitId?: number,
};

const dateFilters = [
  { code: 'all' },
  { code: 'customDate' },
];

const tableHeaderBuilding = ['affectedArea', 'taskCategory', 'taskId', 'startDate', 'endDate', 'billed',
  'priority', 'status', 'assigned'];
const tableHeaderCondo = ['taskCategory', 'taskId', 'startDate', 'endDate', 'billed',
  'priority', 'status', 'assigned'];

const MaintenanceTasksTab = ({
  id, type, propertyCondition = 'active', unitId,
}: MaintenanceTasksTabProps) => {
  const [state, dispatch] = useReducer(MaintenanceTaskReducer, initialState);
  const [fetching, setFetching] = useState(false);
  const [idTask, setIdTask] = useState('');
  const [errorServer, setErrorServer] = useState(false);
  const { t } = useTranslation();

  const iconCells = () => [
    <ChevronRight
      className={styles.icons}
      key="chevron"
    />];

  useEffect(() => {
    if (idTask !== '') {
      goToPage(RouteName.TaskDetail, { id: idTask });
    }
  }, [idTask]);

  const getTasks = async () => {
    try {
      setFetching(true);
      let response;
      if (unitId) {
        response = await MaintenanceController
          .getTasksOfUnit(
            id,
            unitId,
            state.paginator?.currentPage || 1,
            10,
            state.filterDate,
            state.query,
            state.appliedFilters,
          );
      } else {
        response = await MaintenanceController
          .getTasksOfProperty(
            id,
            state.paginator?.currentPage || 1,
            10,
            state.filterDate,
            state.query,
            state.appliedFilters,
          );
      }
      dispatch({ type: 'TASKS_FETCHED', paginator: response });
      setFetching(false);
    } catch (err) {
      logger.error(err as Error);
      setFetching(false);
      setErrorServer(true);
    }
  };

  useEffect(() => {
    getTasks();
  }, [state.paginator?.currentPage, state.appliedFilters, state.filterDate, state.query]);

  const clickPagination = (nextPage: number) => {
    if (nextPage !== state.paginator.currentPage) {
      dispatch({ type: 'PAGE_CHANGED', newPage: nextPage });
    }
  };

  const showModal = () => {
    dispatch({ type: 'MODAL_VISIBILITY', show: !state.show });
  };

  const applyFilters = (updatedFilters: ModalFilterItem[]) => {
    dispatch({ type: 'APPLY_FILTERS', filters: updatedFilters });
  };

  const clearFilters = () => {
    dispatch({ type: 'CLEAR_FILTERS' });
  };

  const removeFilter = (filter: ModalFilterItem) => {
    const updatedFilters = addOrRemoveFromArrayObject(state.appliedFilters, filter, 'code');
    dispatch({ type: 'APPLY_FILTERS', filters: updatedFilters });
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'INPUT_CHANGED', input: e.target.value });
    dispatch({ type: 'SEARCH_QUERY', query: e.target.value });
  };

  const applyFilterDate = (startDate: string, endDate: string, code: string) => {
    dispatch({
      type: 'APPLY_FILTER_DATE',
      filter: {
        startDate,
        endDate,
        code,
      },
    });
  };

  const getHeader = () => {
    if (type === 'Building') {
      return tableHeaderBuilding;
    }
    if (type === 'CondoHouse') {
      return tableHeaderCondo;
    }
    if (type === 'Unit') {
      return tableHeaderCondo;
    }
    return [];
  };

  const getTable = () => {
    if (!state.paginator || state.code === 'FETCHING' || fetching) {
      return (
        <div className={styles.spinner}><Spinner fixed={false} /></div>
      );
    }
    if (state.paginator.results.length === 0 && state.appliedFilters.length > 0) {
      return (
        <div className={styles.emptyState}>
          <EmptyState
            Icon={NoResultsIcon}
            title={t('emptyState.maintenance.withFilters.title')}
            subtitle={t('emptyState.maintenance.withFilters.subtitle')}
            handleOnClick={clearFilters}
          />
        </div>
      );
    }
    if (state.paginator.results.length === 0 && state.input.trim().length > 0) {
      return (
        <div className={styles.emptyState}>
          <EmptyState
            Icon={NoResultsIcon}
            title={t('emptyState.search.title')}
          />
        </div>
      );
    }
    if (state.paginator.results.length === 0) {
      return (
        <div className={styles.emptyState}>
          <EmptyState
            Icon={Tool}
            title={t('emptyState.maintenance.noResults')}
            iconStyle={styles.toolIcon}
          />
        </div>
      );
    }
    return (
      <>
        <Table
          headerNames={getHeader()}
          data={state.paginator.results}
          dataProperties={getHeader()}
          dashboardName="maintenanceTaskTab"
          iconCell={iconCells}
          uniqueId="taskId"
          setId={setIdTask}
          rowClick={((item: MaintenanceTableItem) => setIdTask(String(item.taskId)))}
        />

        {state.paginator.lastPage > 1
          && (
            <div className={styles.paginationContainer}>
              <Pagination
                pageLimit={state.paginator.lastPage}
                onClickFn={(newPage: number) => clickPagination(newPage)}
                currentPage={state.paginator.currentPage}
              />
            </div>
          )}
      </>
    );
  };

  return (
    <div className={styles.dashboardContainer}>
      {errorServer
      && <ErrorMessage message={t('error.errorMessage')} handleClose={() => setErrorServer(false)} />}
      {state.show && (
      <Modal>
        <FilterModal
          appliedFilters={state.appliedFilters}
          filters={state.data.filters}
          closeFn={showModal}
          applyFn={applyFilters}
          prefix="maintenanceTaskTab"
        />
      </Modal>
      )}

      <div className={classnames(styles.filterRow, 'row fullWidth')}>
        <Input
          id="filter"
          containerClass={styles.input}
          inputStyle={InputStyle.REGULAR}
          withSearchIcon
          placeholder={t('maintenanceTaskTab.inputPlaceholder')}
          onChange={onChangeInput}
          value={state.input}
          t={t}
        />

        <Button onClick={() => showModal()} buttonStyle={ButtonStyle.Secondary}>
          <div className="row justify-between">
            <div className="text__button__medium__primary60">
              {t('maintenanceTaskTab.buttons.filter')}
            </div>
            <Filter className={styles.filterIcon} />
          </div>
        </Button>

        {state.appliedFilters.length > 0
        && (
        <FiltersApplied
          filters={state.appliedFilters}
          clearFn={clearFilters}
          removeOneFilterFn={removeFilter}
          prefix="maintenanceTaskTab"
        />
        )}
        <div className={styles.buttonsRight}>
          <div className={styles.filterDate}>
            <FilterByDate
              onClickOption={applyFilterDate}
              options={propertyCondition === 'archived' ? dateFilters : undefined}
            />
          </div>
          {propertyCondition === 'active'
          && (
          <Button
            className={styles.propertyButton}
            buttonStyle={ButtonStyle.Primary}
            onClick={() => goToPage(RouteName.CreateTask, {}, { id })}
          >
            <div className="row align-justify-center">
              <PlusCircle className={styles.plusIcon} />
              {t('maintenanceTaskTab.buttons.createTask')}
            </div>
          </Button>
          )}
        </div>
      </div>

      {getTable()}
    </div>
  );
};

export { MaintenanceTasksTab };
