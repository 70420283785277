import { MouseEventHandler } from 'react';
import { classnames } from '../../helpers/utils';
import { Breakpoints } from '../enums';

import { useMediaQuery } from '../../hooks/use-media-query';

import styles from './tab.module.scss';

type TabProps = {
  name: string,
  isActive: boolean,
  onClickFn: MouseEventHandler<HTMLButtonElement>,
  tabFromContractor?: boolean,
  alert?: boolean,
};

const Tab = ({
  name, isActive, onClickFn, tabFromContractor = false, alert = false,
}: TabProps) => {
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  const handleTextClass = () => {
    const classes = [styles.textContainer,
      isActive ? classnames(styles.activeText, `text__body__${mobile ? 'large' : 'medium'}__textNeutral50`)
        : `text__body__${mobile ? 'large' : 'medium'}__textNeutral40`,
      tabFromContractor ? styles.oneRowTab : '',
    ];
    return classnames(...classes);
  };

  return (
    <button type="button" onClick={onClickFn} className={styles.tabContainer}>
      <div className={handleTextClass()}>
        <>{name}</>
      </div>
      {isActive && <div className={styles.activeLine} />}
      {alert && <div className={styles.alert} />}
    </button>
  );
};

export { Tab };
