import { useEffect, useRef } from 'react';
import { Sender } from '../../types';
import { Breakpoints, RawNewTaskStatus, TaskStatus } from '../enums';
import { Message, MessageType } from '../message';
import { classnames, isCancelledTaskOrWaitingPercentageApproval } from '../../helpers/utils';
import styles from './task-updates-overview.module.scss';
import { useMediaQuery } from '../../hooks/use-media-query';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';

type TaskUpdatesOverviewProps = {
  messages: MessageType[],
  taskStatus: string,
  t: (text: string) => string,
  language: string,
  translPrefix: string,
  userRole: Sender,
  containerClass?: string,
  notifyAddNewUpdate: () => void,
};

const TaskUpdatesOverview = ({
  messages, taskStatus, t, language, translPrefix, userRole, containerClass = '', notifyAddNewUpdate,
}: TaskUpdatesOverviewProps) => {
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const messageRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (messageRef.current) {
      messageRef.current.scrollIntoView(
        {
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        },
      );
    }
  }, [messages.length]);

  const addUpdateButton = () => (
    <button
      type="button"
      className={styles.addUpdateButton}
      onClick={notifyAddNewUpdate}
    >
      <div className="text__body__medium__textNeutral40">
        {t(`${translPrefix}.addUpdate`)}
      </div>
      <div className={styles.plusIconContainer}>
        <PlusIcon className={styles.plusIcon} />
      </div>

    </button>
  );

  const showTaskMessage = (message: string) => (
    <div className={classnames('text__body__medium__textNeutral30', styles.solvedTaskMessage)}>
      {message}
    </div>
  );

  const showTaskMessageOrAddUpdateButton = () => {
    let taskMessage = '';

    if (taskStatus === TaskStatus.SOLVED) {
      taskMessage = (t(`${translPrefix}.taskSolvedMessage`));
    }

    if (isCancelledTaskOrWaitingPercentageApproval(taskStatus as RawNewTaskStatus)) {
      taskMessage = (t(`${translPrefix}.taskCancelledMessage`));
    }

    if (taskMessage) {
      return showTaskMessage(taskMessage);
    }

    return addUpdateButton();
  };

  return (
    <div className={classnames(styles.container, containerClass)}>
      <div className={styles.titleAndSearchWrapper}>
        <div>
          <div className={classnames(
            `text__${mobile ? 'heading2' : 'body__large'}__textNeutral50`,
            styles.updates,
          )}
          >
            {t(`${translPrefix}.updates`)}
          </div>
          <div className={`text__body__${mobile ? 'small' : 'medium'}__textBody`}>
            {t(`${translPrefix}.updatesSubtitle`)}
          </div>

        </div>
        {/* TODO: Search input not used for now */}
        {/* <Input
          id="search"
          t={t}
          withSearchIcon
          placeholder={t(`${translPrefix}.searchInput.placeholder`)}
          onChange={() => {}}
          containerClass={styles.searchInput}
          textStyle={`text__body__${mobile ? 'small' : 'xsmall'}__textNeutral30`}
        /> */}
      </div>
      {messages.map((message) => (
        <Message
          translPrefix={translPrefix}
          key={message.id}
          message={message}
          t={t}
          containerClass={styles.message}
          language={language}
          userRole={userRole}
        />
      ))}

      {showTaskMessageOrAddUpdateButton()}

      <div ref={messageRef} />
    </div>
  );
};

export { TaskUpdatesOverview };
