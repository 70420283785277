import { PropertyAddress } from 'models/property-address';
import { PropertyAddressSerializer } from './property-address-serializer';
import { PropertyOwnerSerializer } from './property-owner-serializer';

class RentalsSerializer {
  static deSerialize(data: RawRental): SerializedRental {
    return {
      id: data.id,
      address: new PropertyAddress(PropertyAddressSerializer.deSerialize(data.address)),
      owner: PropertyOwnerSerializer.deSerialize(data.owner),
      tenant: PropertyOwnerSerializer.deSerialize(data.tenant),
      rentalAmount: data.rental_amount,
      dueDate: data.due_date,
      leaseExpirationDate: data.lease_expiration_date,
      paymentDate: data.payment_date,
      status: data.status,
      unitNumber: data.unit_number,
      currency: data.currency,
      fees: data.fees,
      manuallyPaid: data.manually_paid,
      fullMonth: data.full_month,
      extensionBorderRental: data.extension_border_rental,
    };
  }

  static serializePatch(data: SerializedPatchRental, leaseId?: number): RawPatchRental {
    return {
      due_date: data.dueDate,
      payment_date: data.paymentDate,
      rental_amount: data.rentalAmount,
      month: data.month,
      lease_id: leaseId || undefined,
    };
  }

  static serializeExtendLease(data: ExtendLeaseData): RawExtendLeaseData {
    return {
      rental_amount: data.rentalAmount,
      fees: data.fees || null,
      extension_date: data.extensionDate,
    };
  }
}

export { RentalsSerializer };
