import { ContractorCard } from 'common/contractor-card';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { ReactComponent as Alert } from '@mapix/common/src/assets/icons/alert-filled.svg';
import { classnames } from '@mapix/common/src/helpers/utils';
import { Breakpoints } from 'common/enums';
import { useMediaQuery } from '@mapix/common/src/hooks/use-media-query';
import { getContractorSpecializationsList } from 'helpers/utils';
import { PrivateRequest, PublicRequest } from 'networking/types';
import {
  RawNewTaskStatus, RawUsersRejectMovement, TaskStatus, TaskType,
} from '@mapix/common/src/common/enums';
import styles from './status-tab.module.scss';

type ContractorDetailsProps = {
  request: PrivateRequest | PublicRequest,
  t: (key: string) => string,
  translPrefix: string,
  taskStatus: TaskStatus | '',
  changeContractorFn: () => void,
};

const ContractorDetails = ({
  request, t, translPrefix, taskStatus, changeContractorFn = () => {},
}: ContractorDetailsProps) => {
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const showChangeContractor = taskStatus === TaskStatus.NEGOTIATING
    && request.newTask.taskType === TaskType.PrivateTask;

  const showContractorMessage = taskStatus === TaskStatus.NEGOTIATING;

  const showDetailsTitle = taskStatus === TaskStatus.UNASSIGNED;

  const {
    lastName, name, profilePhoto, specializations,
  } = request.newTask.contractor;

  const wasRejectedInProgressByContractor = () => {
    const { rejectedInProgressMovementBy } = request.newTask;

    return rejectedInProgressMovementBy === RawUsersRejectMovement.RejectedInProgressByContractor;
  };

  const hasContractorReturnedTaskToNegotiating = () => (
    request.newTask.status === RawNewTaskStatus.READY && wasRejectedInProgressByContractor()
  );

  const getRejectedMessage = () => {
    if (hasContractorReturnedTaskToNegotiating()) {
      return request.newTask.messageForInProgressMovementRejection;
    }

    return '';
  };

  const getWarningPillContent = () => (
    hasContractorReturnedTaskToNegotiating() ? t(`${translPrefix}.contratorMovedTask`) : ''
  );

  return (
    <>
      {showDetailsTitle && (<hr className={styles.divider} />)}
      <div className={styles.contractorCardWrapper}>
        {showDetailsTitle && (
          <>
            <div className={classnames(
              `text__${mobile ? 'heading2' : 'body__large'}__textNeutral50`,
              styles.lighterText,
            )}
            >
              {t(`${translPrefix}.request`)}
            </div>
            <div className={classnames(
              styles.sentTo,
              `text__body__${mobile ? 'large' : 'medium'}__textNeutral50`,
            )}
            >
              {t(`${translPrefix}.sentTo`)}
            </div>
          </>
        )}
        <ContractorCard
          name={name}
          lastName={lastName}
          t={t}
          url={profilePhoto?.url}
          categories={getContractorSpecializationsList(specializations)}
          warningPillContent={getWarningPillContent()}
          rejectedMessage={getRejectedMessage()}
          containerClass={styles.contractorCard}
        />
        {showChangeContractor && (
        <div>
          <Button
            buttonStyle={ButtonStyle.Secondary}
            className={styles.button}
            onClick={() => changeContractorFn()}
          >
            {t(`${translPrefix}.changeAssignedContractor`)}
          </Button>

          <div className="row">
            <Alert className={styles.alert} />
            <div className="text__body__small__textNeutral30">
              {t(`${translPrefix}.disclaimer`)}
            </div>
          </div>
        </div>
        )}
        {showContractorMessage && (
        <div>
          <div className={classnames('text__body__medium__textNeutral50', styles.contractorMessage)}>
            {`${t('message')}:`}
          </div>
          <div className="text__body__medium__textNeutral30">{request.contractorMessage}</div>
        </div>
        )}
      </div>
    </>
  );
};

export { ContractorDetails };
