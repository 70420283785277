import { ClaimUnit } from './claim-unit';
import { Contractor } from './contractor';
import { PropertyClaim } from './property-claim';
import { TaskEvent } from './task-event';
import { User } from './user';

class Claim {
  id: number;

  category: string;

  status: string;

  description: string;

  startDate: string;

  rejectionReason?: string;

  rejectionDate: string;

  manager: User;

  contractor?: Contractor;

  property: PropertyClaim;

  unit?: ClaimUnit;

  claimEvents: TaskEvent[];

  photos: FileType[];

  constructor(params: SerializedClaim | null) {
    this.id = params?.id || -1;
    this.category = params?.category || '';
    this.status = params?.status || '';
    this.description = params?.description || '';
    this.startDate = params?.startDate || '';
    this.rejectionReason = params?.reasonToReject || '';
    this.rejectionDate = params?.rejectionDate || '';
    this.manager = new User(params?.user || null);
    this.contractor = new Contractor(params?.contractor || null);
    this.property = new PropertyClaim(params?.property || null);
    this.unit = new ClaimUnit(params?.unit || null);
    this.claimEvents = params?.claimEvents
      ? params.claimEvents.map((event) => new TaskEvent(event))
      : [];
    this.photos = params?.photos || [];
  }
}

export { Claim };
