import {
  CertificationType, ContractorCertification, RawCertificationType,
  RawContractorCertification,
} from '../../types';
import { FileSerializer } from './file-serializer';

class CertificationSerializer {
  static deserializeCertificationType(
    data: RawCertificationType,
  ): CertificationType {
    return {
      id: data.id,
      name: data.name,
    };
  }

  static deserializeContractorCertification(
    data: RawContractorCertification,
  ): ContractorCertification {
    return {
      id: data.id,
      licenseNumber: data.license_number,
      name: data.other_certification_name || undefined,
      otherInstitutionResponsible: data.other_institution_responsible || undefined,
      otherIdentification: data.other_identification || undefined,
      otherWebsite: data.other_website || undefined,
      document: data.document ? FileSerializer.deSerialize(data.document) : null,
      certification: this.deserializeCertificationType(data.certification),
    };
  }

  static deserializeContractorCertificationArray(
    data: RawContractorCertification[],
  ): ContractorCertification[] {
    return data.map((
      certification: RawContractorCertification,
    ) => this.deserializeContractorCertification(certification));
  }
}

export { CertificationSerializer };
