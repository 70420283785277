/* We might want to have this on the BE side, because if it changes it'll take two deploys just to
* update it */
const PropertyFilters = {
  building: [
    {
      title: 'Total units',
      type: 'unit_ranges',
      items: [
        { name: 'Less than 50 Units', code: '0-49', parentType: 'unit_ranges' },
        { name: '50-99 Units', code: '50-99', parentType: 'unit_ranges' },
        { name: '100-200 Units', code: '100-200', parentType: 'unit_ranges' },
        { name: 'More than 200 units', code: '201-', parentType: 'unit_ranges' },
      ],
    },
    {
      title: 'Condition',
      type: 'conditions',
      items: [
        { name: 'Active Properties', code: 'active', parentType: 'conditions' },
        { name: 'Properties Draft', code: 'draft', parentType: 'conditions' },
        { name: 'Archived Properties', code: 'archived', parentType: 'conditions' },
      ],
    },
  ],
  all: [
    {
      title: 'Condition',
      type: 'conditions',
      items: [
        { name: 'Active Properties', code: 'active', parentType: 'conditions' },
        { name: 'Properties Draft', code: 'draft', parentType: 'conditions' },
        { name: 'Archived Properties', code: 'archived', parentType: 'conditions' },
      ],
    },
  ],
  condoshouses: [
    {
      title: 'Condition',
      type: 'conditions',
      items: [
        { name: 'Active Properties', code: 'active', parentType: 'conditions' },
        { name: 'Properties Draft', code: 'draft', parentType: 'conditions' },
        { name: 'Archived Properties', code: 'archived', parentType: 'conditions' },
      ],
    },
    {
      title: 'Status',
      type: 'status',
      items: [
        { name: 'Available', code: 'available', parentType: 'status' },
        { name: 'Rented', code: 'rented', parentType: 'status' },
      ],
    },
  ],
};

export { PropertyFilters };
