/* eslint-disable react/jsx-props-no-spreading */
import { ChangeEvent, HTMLProps, ReactNode } from 'react';
import { classnames, HTMLValidationError } from '../../helpers/utils';
import styles from './checkbox.module.scss';

type CheckboxProperties = {
  labelContent: string | ReactNode,
  labelClass?: string,
  containerClass?: string,
  onChangeFn: (e: ChangeEvent<HTMLInputElement>) => void,
  formError?: HTMLValidationError,
  small?: boolean,
};

const Checkbox = ({
  labelContent, onChangeFn, labelClass = '', containerClass = '', formError,
  small = false, ...props
}: CheckboxProperties & HTMLProps<HTMLInputElement>) => {
  const hasFormError = !!(props.id && formError && formError[props.id]);
  return (
    <>
      <label className={classnames(styles.label, containerClass)} htmlFor={props.id}>
        <input onChange={onChangeFn} {...props} className={styles.checkbox} type="checkbox" />
        <span className={small ? styles.checkmarkSmall : styles.checkmark} />
        <div className={labelClass}>{labelContent}</div>
      </label>
      <div
        className={hasFormError
          ? 'text__body__tiny__danger50' : 'text__body__tiny__textNeutral40'}
      >
        {props.id && hasFormError && formError[props.id]}
      </div>
    </>
  );
};

export { Checkbox };
