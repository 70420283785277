import { PropertyOwner } from 'models/property-owner';
import { useMediaQuery } from '@mapix/common/src/hooks/use-media-query';
import { Breakpoints } from 'common/enums';
import { useTranslation } from 'react-i18next';
import { classnames } from '@mapix/common/src/helpers/utils';
import { ButtonClose } from '@mapix/common/src/common/button-close';
import styles from './contact-info.module.scss';

type ContactInfoProps = {
  owner: PropertyOwner,
  tenant?: PropertyOwner,
  closeFn: () => void,
};

const translPrefix = 'contactInfo';
const ContactInfo = ({ owner, tenant, closeFn }: ContactInfoProps) => {
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  const { t } = useTranslation();

  return (
    <div className={styles.container}>

      {!mobile && (
      <div className="row">
        <div className={styles.marginLeft}>
          <ButtonClose closeFn={closeFn} closeText={t(`${translPrefix}.close`)} />
        </div>
      </div>
      )}

      <p className={mobile ? 'text__heading3__textNeutral50 bold' : 'text__body__large__textNeutral50 bold'}>
        {t(`${translPrefix}.title`)}
      </p>

      <div className={mobile ? 'column' : 'row'}>
        <div className={styles.field}>
          <p className={classnames(styles.title, mobile ? 'text__heading4__textNeutral40' : 'text__body__medium__textNeutral40')}>
            {t(`${translPrefix}.manager`)}
          </p>

          <p className="text__body__medium__textNeutral40">{`${owner.name}${owner.lastname ? ` ${owner.lastname}` : ''}`}</p>
          {tenant?.name && <p className="text__body__medium__textNeutral40">{`${tenant.name}${tenant.lastname ? ` ${tenant.lastname}` : ''}`}</p>}
        </div>

        <div className={styles.field}>
          <p className={classnames(styles.title, mobile ? 'text__heading4__textNeutral40' : 'text__body__medium__textNeutral40')}>
            {t(`${translPrefix}.phone`)}
          </p>

          <p className={classnames(styles.underline, 'text__body__medium__textNeutral40')}>{`${owner.countryCode}${owner.phoneNumber}`}</p>
          {tenant?.phoneNumber && (
          <p className={classnames(styles.underline, 'text__body__medium__textNeutral40')}>
            {`${tenant.countryCode}${tenant.phoneNumber}`}
          </p>
          )}
        </div>

        <div className={styles.field}>
          <p className={classnames(styles.title, mobile ? 'text__heading4__textNeutral40' : 'text__body__medium__textNeutral40')}>
            {t(`${translPrefix}.email`)}
          </p>

          <p className={classnames(styles.underline, 'text__body__medium__textNeutral40')}>{owner.email}</p>
          {tenant?.email && (
          <p className={classnames(styles.underline, 'text__body__medium__textNeutral40')}>
            {tenant.email}
          </p>
          )}
        </div>

      </div>

    </div>

  );
};

export { ContactInfo };
