import {
  ChangeEvent, HTMLProps, useDeferredValue, useEffect, useRef, useState,
} from 'react';
import { useDebounceEffect } from '../../hooks/use-debounce';
import { PaginatorContainer } from '../../types';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { useMediaQuery } from '../../hooks/use-media-query';
import { Breakpoints, InputStyle } from '../enums';
import { Button, ButtonStyle } from '../button';
import { useClickOutside } from '../../hooks/click-outside';
import { logger } from '../../helpers/logger';
import { classnames } from '../../helpers/utils';
import { Input } from '../input';
import styles from './filtered-select.module.scss';
import { InputProps } from '../input/input';
import { FilteredSelectContent } from './filtered-select-content';

type FilteredSelectState = {
  code: string,
  show: boolean,
  query: string,
  selectedOption: string,
};

export enum FilteredSelectBehaivor {
  STORED_OPTIONS,
  SINGLE_API_CALL,
  PAGINATED_API_CALL,
}

type StoredOptionsProps = { behavior: FilteredSelectBehaivor.STORED_OPTIONS,
  storedOptions: string[],
  onSelectOption: (option: string) => void
};

type SingleApiCallProps = { behavior: FilteredSelectBehaivor.SINGLE_API_CALL,
  getOptionsCall?: () => Promise<string[]>
  onSelectOption: (option: string) => void };

type PaginatedApiCallProps<T> = { behavior: FilteredSelectBehaivor.PAGINATED_API_CALL,
  getOptionsCall: (pageNumber: number, pageSize: number, query: string
  ) => Promise<PaginatorContainer<T>>,
  onSelectOption: (obj: T) => void,
  mapKeyFn: (obj: T) => string,
  pageSize?: number,
};

// The component accepts either a call to manage pageable components
// or a list of options to be displayed directly

type ObjectWithId = { id: number };

type NeededInputProps = Pick<HTMLProps<HTMLInputElement> & InputProps,
'placeholder' | 'label' | 'id' | 'formError' | 'name' | 'helperText' |
'inputStyle' | 'required' | 't' | 'plainHelperText'>;

type FilteredSelectProps<T> = {
  optionClass?: string,
  query?: string,
  contentContainerClass?: string,
  searchClass?: string,
  textStyle?: string,
} & (StoredOptionsProps
| SingleApiCallProps
| PaginatedApiCallProps<T>
) & NeededInputProps;

const initState = (query?: string, storedOptions?: boolean) => ({
  code: storedOptions ? 'READY' : 'FETCHING',
  show: false,
  selectedOption: '',
  query: query || '',
});

const minQueryLength = 3;

// eslint-disable-next-line @typescript-eslint/comma-dangle
const FilteredSelect = <T extends ObjectWithId,>({
  placeholder,
  name,
  helperText,
  inputStyle = InputStyle.FORM,
  label,
  id,
  query,
  optionClass = '',
  t,
  formError,
  required,
  plainHelperText,
  contentContainerClass = '',
  searchClass = '',
  textStyle = '',
  ...props
}: FilteredSelectProps<T> & HTMLProps<HTMLInputElement> & InputProps) => {
  const [options, setOptions] = useState<string[]>([]);
  const [state, setState] = useState<FilteredSelectState>(
    initState(query, props.behavior === FilteredSelectBehaivor.STORED_OPTIONS),
  );
  const [mappedOptions, setMappedOptions] = useState<Map<string, T>>(new Map());
  const containerRef = useRef(null);

  const hideContent = () => {
    setState((prevState) => ({ ...prevState, show: false }));
  };

  const isPaginated = props.behavior === FilteredSelectBehaivor.PAGINATED_API_CALL;

  useClickOutside(containerRef, hideContent);

  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const deferredValue = useDeferredValue<string>(state.query || '');

  const getOptions = async () => {
    try {
      let backendOptions;
      let objMap;
      switch (props.behavior) {
        case FilteredSelectBehaivor.STORED_OPTIONS:
          return;
        case FilteredSelectBehaivor.SINGLE_API_CALL:
          backendOptions = await props.getOptionsCall!();
          setOptions(backendOptions);
          break;
        case FilteredSelectBehaivor.PAGINATED_API_CALL:
          // TODO implement infinite scrolling if needed
          backendOptions = await props.getOptionsCall(1, props.pageSize || 10, deferredValue || '');
          objMap = new Map<string, T>(backendOptions.results
            .map((obj) => [props.mapKeyFn(obj), obj]));
          setMappedOptions(objMap);
          setOptions(Array.from(objMap.keys()));
          break;
        default:
          throw new Error('Invalid behavior');
      }

      setState((prevState) => ({ ...prevState, code: 'READY' }));
    } catch (err: any) {
      logger.error(err);
      setState((prevState) => ({ ...prevState, code: 'ERROR' }));
    }
  };

  useDebounceEffect(() => {
    if (isPaginated && deferredValue.length >= minQueryLength) {
      getOptions();
    }
  }, [deferredValue.length]);

  // TODO: handle retry button
  const handleRetry = () => {};

  useEffect(() => {
    if (props.behavior === FilteredSelectBehaivor.STORED_OPTIONS) {
      setOptions(props.storedOptions);
    }
    if (props.behavior === FilteredSelectBehaivor.SINGLE_API_CALL) {
      getOptions();
    }
    setState((prevState) => ({ ...prevState, query: query || '' }));
  }, []);

  const showContent = () => {
    setState((prevState) => ({ ...prevState, show: true }));
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (isPaginated) {
      setState((prevState) => ({ ...prevState, code: 'FETCHING' }));
    }
    setState((prevState) => ({ ...prevState, query: e.target.value }));
  };

  const selectItem = (item: string) => {
    hideContent();
    setState((prevState) => ({ ...prevState, selectedOption: item, query: item }));

    if (isPaginated) {
      props.onSelectOption(mappedOptions.get(item)!);
    } else {
      props.onSelectOption(item);
    }
  };

  const errorStyle = classnames(
    styles.itemCenter,
    `text__heading${mobile ? 'heading4' : 'body__medium'}__textNeutral50`,
  );

  return (
    <div ref={containerRef} className={styles.inputContainer}>
      <Input
        error={state.code === 'ERROR'}
        id={id}
        placeholder={placeholder}
        containerClass={classnames(styles.inputWidth, optionClass)}
        onFocus={showContent}
        value={state.query}
        onChange={onChangeInput}
        inputStyle={inputStyle}
        t={t}
        activeSelect={state.show}
        label={label}
        formError={formError}
        name={name}
        helperText={helperText}
        RightIcon={inputStyle === InputStyle.REGULAR && (
        <SearchIcon className={classnames(styles.searchIcon, searchClass)} />)}
        plainHelperText={plainHelperText}
        required={required}
        textStyle={textStyle}
        withChevron={inputStyle !== InputStyle.REGULAR}
      >
        {state.show && (deferredValue.length >= minQueryLength
        || props.behavior !== FilteredSelectBehaivor.PAGINATED_API_CALL) && (
        <div className={classnames(styles.content, contentContainerClass)}>
          {state.code === 'ERROR' ? (
            <div className={errorStyle}>
              <div>{t('error.somethingWentWrong')}</div>
              <Button
                buttonStyle={ButtonStyle.Primary}
                className={classnames('text__button__small__textNeutral10', styles.retry)}
                onClick={handleRetry}
              >
                {t('retry')}
              </Button>
            </div>
          ) : (
            <FilteredSelectContent
              deferredValue={deferredValue}
              errorStyle={errorStyle}
              options={options}
              selectItem={selectItem}
              t={t}
              query={state.query}
              code={state.code}
            />
          )}
        </div>
        )}
      </Input>
    </div>
  );
};

export { FilteredSelect };
