import { FormEvent, SetStateAction } from 'react';
import type { SalesProfileType } from '../../../types';
import { HTMLValidationError } from '../../../helpers/utils';
import { PaymentInformationTemplate } from '../../payment-information-template';
import { PaymentInformationFormGenerator, Restrictions } from '../payment-information-form-generator';

const cardsRestrictions = {
  type: 'number',
  regexp: /^((\d{0,9})|(\d{0,9}\.\d+))$/,
};
const restrictions = (): Restrictions => ({
  averageVisaMonthlyVolume: cardsRestrictions,
  averageVisaTicketSize: cardsRestrictions,
  visaHighTicketSize: cardsRestrictions,
  averageMCMonthlyVolume: cardsRestrictions,
  averageMCTicketSize: cardsRestrictions,
  MCHighTicketSize: cardsRestrictions,
  averageDebitMonthlyVolume: cardsRestrictions,
  averageDebitTicketSize: cardsRestrictions,
  debitHighTicketSize: cardsRestrictions,
});

type SalesProfileProps = {
  t: (text: string) => string,
  backFn: () => void,
  salesProfile: SalesProfileType,
  setSalesProfile: any,
  formErrors: HTMLValidationError,
  onSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>,
  setFormErrors: (value: SetStateAction<HTMLValidationError>) => void,
  translPrefix: string,
  goToHomeFn: () => void,
  goToMyProfileFn: () => void,
  goToPaymentInformationFn: () => void,
};

const SalesProfile = ({
  t, backFn, salesProfile, setSalesProfile, formErrors,
  onSubmit, setFormErrors, translPrefix, goToHomeFn,
  goToMyProfileFn, goToPaymentInformationFn,
}: SalesProfileProps) => (
  <PaymentInformationTemplate
    t={t}
    backFn={backFn}
    title={t(`${translPrefix}.title`)}
    subtitle={t(`${translPrefix}.subtitle`)}
    onSubmit={onSubmit}
    goToHomeFn={goToHomeFn}
    goToMyProfileFn={goToMyProfileFn}
    goToPaymentInformationFn={goToPaymentInformationFn}
  >
    <PaymentInformationFormGenerator
      t={t}
      state={salesProfile}
      translPrefix={translPrefix}
      setState={setSalesProfile}
      formErrors={formErrors}
      setFormErrors={setFormErrors}
      restrictions={restrictions()}
    />
  </PaymentInformationTemplate>
);

export { SalesProfile };
