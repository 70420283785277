import { Lease } from 'models/lease';
import { Services } from 'models/services';
import { ReactComponent as Check } from 'assets/icons/check-black.svg';
import { LeaseDetail } from 'models/lease-detail';
import { useTranslation } from 'react-i18next';
import { classnames } from '@mapix/common/src/helpers/utils';
import dayjs from 'dayjs';
import styles from './read-only-forms.module.scss';

type LeaseInfoProps = {
  leaseDetail: LeaseDetail | Lease,
};

const getServicesAsList = (services: Services): string[] => {
  const availableServices: string[] = [];
  Object.keys(services).forEach((key) => {
    if (services[key as keyof Services]) {
      availableServices.push(key);
    }
  });
  return availableServices;
};

const translPrefix = 'leaseDetail';
const servicesTransl = 'services';
const LeaseInfo = ({ leaseDetail }: LeaseInfoProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.container}>
        <div className={classnames(styles.itemBlock, styles.firstRow)}>
          <div className="text__body__medium__textNeutral50">
            {t(`${translPrefix}.startDate`)}
          </div>
          <div className="text__body__medium__textNeutral50">
            {leaseDetail.startDate}
          </div>
        </div>

        <div className={styles.itemBlock}>
          <div className="text__body__medium__textNeutral40">
            {t(`${translPrefix}.expirationDate`)}
          </div>
          <div className="text__body__medium__textNeutral50">
            {leaseDetail.expirationDate}
          </div>
        </div>
      </div>

      <div className={styles.container}>
        <div className={styles.itemBlock}>
          <div className="text__body__medium__textNeutral40">
            {t(`${translPrefix}.monthlyRental`)}
          </div>
          <div className="text__body__medium__textNeutral50">
            $&nbsp;
            {`${leaseDetail.monthlyRental} ${leaseDetail.currency}`}
          </div>
        </div>

        <div className={styles.itemBlock}>
          <div className="text__body__medium__textNeutral40">
            {t(`${translPrefix}.yearlyRental`)}
          </div>
          <div className="text__body__medium__textNeutral50">
            $&nbsp;
            {`${Number(leaseDetail.monthlyRental) * 12} ${leaseDetail.currency}`}
          </div>
        </div>

        <div className={styles.itemBlock}>
          <div className="text__body__medium__textNeutral40">
            {t(`${translPrefix}.dueDate`)}
          </div>
          <div className="text__body__medium__textNeutral40">
            {dayjs(leaseDetail.startDate).format('DD')}
          </div>
        </div>

      </div>

      <div className={styles.container}>
        <div className={styles.itemBlock}>
          <div className="text__body__medium__textNeutral40">
            {t(`${translPrefix}.servicesIncluded`)}
          </div>

          <div className={styles.servicesContainer}>
            {getServicesAsList(leaseDetail?.services).map((service) => (
              <div key={service} className={styles.serviceItem}>
                <Check className={styles.check} />
                <div className="text__body__medium__textNeutral50">
                  {service === 'other' ? leaseDetail.services[service] : t(`${servicesTransl}.${service}`)}
                </div>
              </div>
            ))}
          </div>

        </div>
      </div>

    </>
  );
};

export { LeaseInfo };
