import { FormEvent, SetStateAction } from 'react';
import type { PaymentInformationState } from '../../../types';
import { asciiWithoutSomeSpecialCharacters, HTMLValidationError, postalCodeRegex } from '../../../helpers/utils';
import { PaymentInformationTemplate } from '../../payment-information-template';
import { PaymentInformationFormGenerator, Restrictions } from '../payment-information-form-generator';

const translPrefix = 'paymentInformation.legalBuisnessInformation';

const helperTextsFn = (t: (text: string) => string) => ({
  legalName: t(`${translPrefix}.helperTexts.legalName`),
  url: t(`${translPrefix}.helperTexts.url`),
  postalCode: t(`${translPrefix}.helperTexts.postalCode`),
});

const restrictions = (): Restrictions => ({
  legalName: {
    regexp: asciiWithoutSomeSpecialCharacters(),
    maxLength: 100,
  },
  civicNumber: {
    // ^([0-9]).*$
    regexp: /^([0-9]).*$/,
    maxLength: 32,
  },
  poBox: {
    maxLength: 25,
  },
  unit: {
    maxLength: 28,
  },
  city: {
    maxLength: 30,
  },
  postalCode: {
    maxLength: 15,
    regexp: postalCodeRegex(),
  },
  gstTaxId: {
    regexp: /^\d{9}$/,
  },
  url: {
    maxLength: 75,
    regexp: /^((http|https):\/\/)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?\/?([a-zA-Z0-9\-._?,/\\+&amp;amp;%$#=~])*$/,
  },
});

const optionalInputsFn = () => ({
  poBox: true,
  gstTasxId: true,
  unit: true,
  typeOfOwnership: true,
  businessDescription: true,

});

type LegalBusinessInfoProps = {
  t: (text: string) => string,
  backFn: () => void,
  legalBusinessInfo: PaymentInformationState,
  setLegalBusinessInfo: any,
  formErrors: HTMLValidationError,
  validatePhone: (phoneNumber: string, countryCode: string) => Promise<RawPhoneValidation>
  setPhoneErrors: (value: SetStateAction<HTMLValidationError>) => void,
  setFormErrors: (value: SetStateAction<HTMLValidationError>) => void,
  onSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>,
  selectOptions: { [key: string]: string[] },
  filteredSelectCalls?: { [key: string]: () => Promise<string[]> },
  goToHomeFn: () => void,
  goToMyProfileFn: () => void,
  goToPaymentInformationFn: () => void,
};

const LegalBusinessInfo = ({
  t, backFn, legalBusinessInfo, setLegalBusinessInfo, formErrors, validatePhone, setPhoneErrors,
  setFormErrors, onSubmit, selectOptions, goToHomeFn,
  goToMyProfileFn, goToPaymentInformationFn, filteredSelectCalls,

}: LegalBusinessInfoProps) => (
  <PaymentInformationTemplate
    t={t}
    backFn={backFn}
    title={t(`${translPrefix}.title`)}
    onSubmit={onSubmit}
    goToHomeFn={goToHomeFn}
    goToMyProfileFn={goToMyProfileFn}
    goToPaymentInformationFn={goToPaymentInformationFn}
  >
    <PaymentInformationFormGenerator
      t={t}
      state={legalBusinessInfo}
      selectOptions={selectOptions}
      filteredSelectCalls={filteredSelectCalls}
      translPrefix={translPrefix}
      setState={setLegalBusinessInfo}
      formErrors={formErrors}
      validatePhone={validatePhone}
      setPhoneErrors={setPhoneErrors}
      optionalInputs={optionalInputsFn()}
      helpertexts={helperTextsFn(t)}
      restrictions={restrictions()}
      setFormErrors={setFormErrors}
      noTranslation={{ province: true, businessDescription: true }}
    />
  </PaymentInformationTemplate>
);

export { LegalBusinessInfo };
