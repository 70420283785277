class SimpleUnitSerializer {
  static deSerialize(data: RawSimpleUnit): SerializedSimpleUnit {
    return {
      id: data.id,
      unitNumber: data.unit_number,
    };
  }
}

export { SimpleUnitSerializer };
