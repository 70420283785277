import { ReactComponent as Chevron } from 'assets/icons/chevron-right.svg';
import { classnames } from '@mapix/common/src/helpers/utils';
import { useTranslation } from 'react-i18next';
import { AppLink, RouteName } from 'routes';
import styles from './menu.module.scss';

type List = {
  route: RouteName,
  name: string,
  pathParams?: {},
};

type MenuProps = {
  list: List[],
  className: string,
};
const Menu = ({ list, className }: MenuProps) => {
  const { t } = useTranslation();
  const getList = () => {
    if (list.length > 0) {
      return list.map((elem, index, array) => (
        <div key={elem.name} className={styles.menuList}>
          <AppLink
            routeName={elem.route}
            pathParams={elem.pathParams}
            className={classnames(styles.listElem, index < array.length - 1 ? 'text__body__medium__textNeutral40' : 'text__body__medium__primary')}
          >
            {t(elem.name)}
          </AppLink>
          {index < array.length - 1 && <Chevron className={styles.icon} stroke="#626D71" />}
        </div>
      ));
    }
    return null;
  };
  return (
    <div className={classnames(className, styles.list)}>
      {getList()}
    </div>
  );
};

export { Menu };
export type { List };
