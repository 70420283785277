import { Fragment, useRef, useState } from 'react';
import { addOrRemoveFromArrayObject, classnames } from '@mapix/common/src/helpers/utils';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { useTranslation } from 'react-i18next';
import { useClickOutside } from '@mapix/common/src/hooks/click-outside';
import { useMediaQuery } from '@mapix/common/src/hooks/use-media-query';
import { Breakpoints } from 'common/enums';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { Checkbox } from '@mapix/common/src/common/checkbox';
import styles from './filter-modal.module.scss';

type ModalFilterItem = {
  name: string,
  code: string,
  parentType: string,
};

type ModalFilterObject = {
  title: string,
  type: string,
  items: ModalFilterItem[],
};

type FilterModalProps = {
  filters: ModalFilterObject[]
  closeFn: () => void,
  applyFn: (filters: ModalFilterItem[]) => void,
  appliedFilters: ModalFilterItem[],
  prefix: string,
};

const FilterModal = ({
  filters, closeFn, applyFn, appliedFilters, prefix,
}: FilterModalProps) => {
  const translationPrefix = `${prefix}.filters`;
  const [selectedFilters, setSelectedFilters] = useState([...appliedFilters]);
  const myRef = useRef<HTMLDivElement>(null);
  useClickOutside(myRef, closeFn);
  const { t } = useTranslation();
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const toggleSelected = (item: ModalFilterItem) => {
    setSelectedFilters(addOrRemoveFromArrayObject(selectedFilters, item, 'code'));
  };

  return (
    <div ref={myRef} className={classnames(styles.filterContainer, 'column')}>
      <button aria-label="Close" className={styles.cross} type="button" onClick={closeFn}>
        <Cross />
      </button>
      <div className={classnames(styles.title, mobile ? 'text__heading4__textNeutral50' : 'text__heading5__textNeutral50')}>
        {t(`${translationPrefix}.select`)}
      </div>
      {filters.map((filter: ModalFilterObject, index: number) => (
        <Fragment key={filter.title}>
          <div className={classnames(styles.subTitle, 'text__heading6__textNeutral50')}>
            {t(`${translationPrefix}.${filter.type}.title`)}
            :
          </div>
          <div className={styles.checkboxContainer}>
            {filter.items.map((item) => (
              <div key={item.code} className={styles.item}>
                <Checkbox
                  onChangeFn={() => toggleSelected(item)}
                  labelClass="text__body__medium__textNeutral40"
                  labelContent={t(`${translationPrefix}.${filter.type}.${item.code}`)}
                  id={item.code}
                  checked={selectedFilters.some((selected) => selected.code === item.code)}
                />
              </div>
            ))}
          </div>
          {index !== filters.length - 1 && <div className={styles.line} />}
        </Fragment>
      ))}

      <div className={styles.buttonContainer}>
        <Button onClick={closeFn} className={styles.cancel} buttonStyle={ButtonStyle.Secondary}>
          {t(`${translationPrefix}.cancel`)}
        </Button>
        <Button onClick={() => applyFn(selectedFilters)} buttonStyle={ButtonStyle.Primary}>
          {t(`${translationPrefix}.apply`)}
        </Button>
      </div>

    </div>
  );
};

export { FilterModal };
export type { ModalFilterObject, ModalFilterItem };
