import { classnames } from '@mapix/common/src/helpers/utils';
import { useTranslation } from 'react-i18next';
import { AppLink, RouteName } from 'routes';
import dayjs from 'dayjs';
import styles from './footer.module.scss';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <AppLink
          routeName={RouteName.PrivacyPolicy}
          className={classnames(styles.link, 'text__body__medium__textNeutral10')}
        >
          {t('footer.privacyPolicy')}
        </AppLink>
        <AppLink
          routeName={RouteName.TermsOfUse}
          className={classnames(styles.link, 'text__body__medium__textNeutral10')}
        >
          {t('footer.termsOfUse')}
        </AppLink>
      </div>
      <div className={styles.right}>
        <p className={classnames(styles.rights, 'text__body__medium__textNeutral10')}>
          {t('footer.rightsReserved', { year: dayjs().get('year') })}
        </p>
      </div>
    </div>
  );
};

export { Footer };
