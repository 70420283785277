import React, { ChangeEvent, useEffect, useState } from 'react';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import { Select } from '@mapix/common/src/common/select';
import { Specialization } from '@mapix/common/src/types/specialization';
import { useTranslation } from 'react-i18next';
import { MaintenanceController } from 'networking/controllers/maintenance-controller';
import { logger } from '@mapix/common/src/helpers/logger';
import { HTMLValidationError } from '@mapix/common/src/helpers/utils';

type AreasOfSpecializationProps = {
  category: Specialization
  otherCategory: string
  setOtherCategory: (spec: string) => void,
  categoryContainerClass: string,
  otherCategoryContainerClass: string,
  formErrors?: HTMLValidationError,
  selectCategory: (area: Specialization) => void,
};

const other = /^other[\w()]{0,3}$/i;
const otherSpecializationMaximumAllowedCharacters = 35;

const translPrefix = 'selectCategory';

const SelectCategory = ({
  category, categoryContainerClass, otherCategoryContainerClass,
  formErrors, selectCategory, setOtherCategory, otherCategory,
}: AreasOfSpecializationProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<string[]>([]);
  const [allPossibleSpecializations, setAllPossibleSpecializations] = useState<
  Specialization[]
  >([]);
  const showOtherAreas = other.test(category.name);
  let actualOther = 'Other';

  const findSpecializationIdByName = (
    name: string,
    specializations?: Specialization[],
  ) => (specializations?.length
    ? specializations : allPossibleSpecializations).find(
    (spec: Specialization) => spec.name === name,
  )!.id;

  const adjustSpecialization = (allSpecializations: Specialization[]) => {
    if (category.name && category.id < 1) {
      const actualId = findSpecializationIdByName(category.name, allSpecializations);
      selectCategory(
        { name: category.name, id: actualId } as Specialization,
      );
    }
  };

  const handleSpecializations = async () => {
    try {
      const allSpecializations = await MaintenanceController.getSpecializations();
      setAllPossibleSpecializations(allSpecializations);
      setOptions(allSpecializations.map((spec) => t(`specializations.${spec.name}`)));
      actualOther = options.find((spec) => other.test(spec)) || actualOther;
      adjustSpecialization(allSpecializations);
    } catch (error) {
      logger.log(error);
    }
  };

  const selectSpecialization = (specializationName: string) => {
    setOtherCategory('');
    const specializationId = findSpecializationIdByName(specializationName);
    selectCategory(
      { name: specializationName, id: specializationId } as Specialization,
    );
  };

  useEffect(() => {
    handleSpecializations();
  }, []);

  return (
    <>
      <Select
        id="category"
        options={options}
        value={category.name}
        onClickOption={selectSpecialization}
        placeholder={t(`${translPrefix}.category.placeholder`)}
        containerClass={categoryContainerClass}
        t={t}
        required
        formError={formErrors}
      />

      {showOtherAreas
      && (
      <Input
        id="other"
        type="text"
        containerClass={otherCategoryContainerClass}
        t={t}
        inputStyle={InputStyle.FORM}
        placeholder={t(`${translPrefix}.other.placeholder`)}
        helperText={t(`${translPrefix}.other.helperText`)}
        label={t(`${translPrefix}.other.placeholder`)}
        maxLength={otherSpecializationMaximumAllowedCharacters}
        required
        value={otherCategory}
        formError={formErrors}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setOtherCategory(e.target.value)}
      />
      )}

    </>
  );
};
export { SelectCategory };
