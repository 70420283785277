import { Select } from '@mapix/common/src/common/select';
import { ContractorCategories } from 'common/enums';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import { classnames, HTMLValidationError } from '@mapix/common/src/helpers/utils';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { ReactComponent as Check } from 'assets/icons/check-black.svg';
import { ModalResult } from 'common/modal-result';
import { ReactComponent as FeatherIcon } from 'assets/icons/feather-icons.svg';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useState, SetStateAction } from 'react';
import { Contractor } from 'models/contractor';
import { PhoneInput } from '@mapix/common/src/common/phone-input';
import { Button, ButtonStyle, ButtonType } from '@mapix/common/src/common/button';

import { UserController } from 'networking/controllers/user-controller';
import styles from './create-property-building.module.scss';

const translPrefix = 'contractors';

const noop = () => true;

type ContractorFormProps = {
  onClickSelectFn: (option: string) => void;
  onChangeFn: (e: ChangeEvent<HTMLInputElement>) => void;
  formErrors: HTMLValidationError;
  contractor: Contractor;
  isSaveButtonDisabled?: Boolean;
  onDiscard?: () => void;
  showButtons?: boolean;
  onChangePhoneNumber: (phoneNumber: string, countryCode: string) => void
  setPhoneErrors: (value: SetStateAction<HTMLValidationError>) => void,

};

const ContractorForm = ({
  onClickSelectFn,
  onChangeFn,
  onChangePhoneNumber,
  formErrors,
  contractor,
  isSaveButtonDisabled,
  showButtons,
  onDiscard = noop,
  setPhoneErrors,
}: ContractorFormProps) => {
  const { t } = useTranslation();
  const [showDiscardModal, setShowDiscardModal] = useState(false);

  const openModal = () => {
    setShowDiscardModal(true);
  };

  const closeModal = () => {
    setShowDiscardModal(false);
  };

  const handleDiscard = () => {
    onDiscard();
    closeModal();
  };

  return (
    <div className={styles.formContainer}>
      <div className={styles.row}>
        <div className={styles.innerWrapper}>
          <div className={styles.fieldWrapper}>
            <Select
              options={Object.values(ContractorCategories)}
              onClickOption={onClickSelectFn}
              optionTextClass={classnames(
                styles.options,
                'text__body__small__textNeutral50',
              )}
              required
              id="category"
              label={t(`${translPrefix}.categories.${contractor.category}`)}
              placeholder={t(`${translPrefix}.categoryLabel`)}
              value={contractor.category ? t(`${translPrefix}.categories.${contractor.category}`) : ''}
              inputStyle={InputStyle.FORM}
              containerClass={classnames(styles.input, styles.select)}
              formError={formErrors}
              translationPrefix={`${translPrefix}.categories`}
              t={t}
            />

            <Input
              required
              id="name"
              name="name"
              label={t(`${translPrefix}.nameLabel`)}
              placeholder={t(`${translPrefix}.nameLabel`)}
              helperText={t(`${translPrefix}.nameHelper`)}
              value={contractor.name}
              onChange={onChangeFn}
              inputStyle={InputStyle.FORM}
              containerClass={styles.input}
              formError={formErrors}
              t={t}
            />

            <PhoneInput
              required
              id="phoneNumber"
              label={t(`${translPrefix}.phoneLabel`)}
              helperText={t(`${translPrefix}.phoneHelper`)}
              countryCode={contractor.countryCode}
              phoneWithoutCode={contractor.phoneNumber}
              onChangePhoneNumber={onChangePhoneNumber}
              containerClass={styles.input}
              formError={formErrors}
              setPhoneErrors={setPhoneErrors}
              validatePhone={UserController.phoneValidation}
              t={t}
            />

            <Input
              required
              id="email"
              name="email"
              type="email"
              label={t(`${translPrefix}.emailLabel`)}
              placeholder={t(`${translPrefix}.emailLabel`)}
              helperText={t(`${translPrefix}.emailHelper`)}
              value={contractor.email}
              onChange={onChangeFn}
              inputStyle={InputStyle.FORM}
              containerClass={styles.input}
              formError={formErrors}
              t={t}
            />
          </div>

          {showButtons && (
            <div
              className={classnames(
                styles.rentedUnit,
                styles.formTitle,
                styles.buttonWrapper,
              )}
            >
              <Button
                onClick={openModal}
                buttonType={ButtonType.Button}
                buttonStyle={ButtonStyle.Secondary}
                disabled={!!isSaveButtonDisabled}
                className={styles.marginDiscardUnit}
              >
                <div
                  className={classnames(
                    'text__button__medium__primary',
                    styles.unitButtonAlign,
                  )}
                >
                  <Close className={styles.closeIcon} />
                  {t(`${translPrefix}.cancelAndDiscard`)}
                </div>
              </Button>
              <Button
                buttonType={ButtonType.Submit}
                buttonStyle={ButtonStyle.Primary}
                disabled={!!isSaveButtonDisabled}
                className={styles.marginDiscardUnit}
              >
                <div
                  className={classnames(
                    'text__button__medium__textNeutral10',
                    styles.unitButtonAlign,
                  )}
                >
                  <Check className={styles.checkIcon} />
                  {t(`${translPrefix}.confirmAndSave`)}
                </div>
              </Button>
            </div>
          )}
        </div>

        {showDiscardModal && (
          <ModalResult
            title={t(`${translPrefix}.confirmDiscard`)}
            Icon={FeatherIcon}
            buttonTextRight={t(`${translPrefix}.yesDiscard`)}
            buttonTextLeft={t(`${translPrefix}.noCancel`)}
            handleButtonLeft={closeModal}
            handleButtonRight={handleDiscard}
            handleButtonClose={closeModal}
          />
        )}
      </div>
    </div>
  );
};

export { ContractorForm };
