import { Breadcrumbs, BreadcrumbsItem } from '@mapix/common/src/common/breadcrumbs';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { PillColor, RawNewTaskStatus } from '@mapix/common/src/common/enums';
import { ModalRejection } from '@mapix/common/src/common/modal-rejection';
import { ModalAccentColor, ModalResult } from '@mapix/common/src/common/modal-result';
import { Pill } from '@mapix/common/src/common/pill';
import { Spinner } from '@mapix/common/src/common/spinner';
import {
  classnames,
  getTimeZoneFormat,
  resolveAddress,
} from '@mapix/common/src/helpers/utils';
import { TaskViewTab } from 'common/new-task-detail/tabs/task-view';
import { Table } from 'common/table';
import { logger } from 'helpers/logger';
import { newTaskInitialState } from 'initial-states/new-task-initial-state';
import { MaintenanceController } from 'networking/controllers/maintenance-controller';
import { NewTask } from 'networking/types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RouteName, goToPage } from 'routes';
import { MaintenanceTabs } from 'common/enums';
import { ReactComponent as Tool } from '../../assets/icons/tool.svg';
import styles from './task-detail-claim.module.scss';

const translPrefix = 'taskDetailClaim';

type ParamType = {
  id: string,
};
const parseAffectedArea = (task: NewTask, t: (text: string) => string) => {
  if (task) {
    return task.unit ? `${t('unit')} ${task.unit.unitNumber}` : t('commonArea');
  }
  return '-';
};

const dataProperties = ['affectedArea', 'category', 'created', 'tenantName'];

const getTabToRedirect = (taskStatus: RawNewTaskStatus) => (taskStatus === RawNewTaskStatus.REJECTED
  ? MaintenanceTabs.RejectedClaims : MaintenanceTabs.New);

const mapToTableData = (task: NewTask, t: (text: string) => string) => ({
  affectedArea: parseAffectedArea(task, t),
  category: task.category,
  created: getTimeZoneFormat(task.createdAt, 'DD/MM/YYYY'),
  tenantName: `${task.tenantName} ${task.tenantLastName}`,
});

const breadcrumbs = (
  t: (text: string) => string,
  taskName: string,
  translate: string,
  redirectionTab: MaintenanceTabs,
): BreadcrumbsItem[] => [
  {
    name: t(`${translate}.maintenance`),
    goToPageFn: () => goToPage(RouteName.Maintenance, {}, { tab: redirectionTab }),
  },
  {
    name: taskName,
    goToPageFn: () => {},
  },
  {
    name: t(`${translate}.taskView`),
    goToPageFn: () => null,
  },
];

enum ModalToShow {
  None = 'None',
  RejectClaim = 'RejectClaim',
  SuccessRejection = 'SuccessRejection',
}

const TaskDetailClaim = () => {
  const { id } = useParams<ParamType>();
  const [task, setTask] = useState<NewTask>(newTaskInitialState);
  const [fetching, setFetching] = useState(true);
  const [modalToShow, setModalToShow] = useState<ModalToShow>(ModalToShow.None);

  const { t } = useTranslation();

  const getTask = async () => {
    try {
      const taskResponse = await MaintenanceController.getNewTaskDetail(id);

      if (![RawNewTaskStatus.AWAITING, RawNewTaskStatus.REJECTED].includes(taskResponse.status)) {
        goToPage(RouteName.ErrorPage);
      }

      setTask(taskResponse);
    } catch (err: any) {
      logger.error(err);
      goToPage(RouteName.ErrorPage);
    } finally {
      setFetching(false);
    }
  };

  const onRejectClaim = async (reason: string) => {
    try {
      setFetching(true);
      await MaintenanceController.rejectClaim(task.id, { reasonForRejection: reason });
      setModalToShow(ModalToShow.SuccessRejection);
    } catch (err: any) {
      logger.error(err);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    getTask();
  }, []);

  if (fetching) return <Spinner />;

  const isNewClaim = task.status === RawNewTaskStatus.AWAITING;

  const header = () => (
    <>
      <div className={styles.titlePillWrapper}>
        <span className="text__heading4__textNeutral50">
          {`${task.category} - ${task.name}`}
        </span>
        <Pill
          content={t(`taskStatus.${task.status}`)}
          color={isNewClaim ? PillColor.Yellow : PillColor.Red}
          textStyle={classnames(styles.pill, 'text__body__small__textNeutral10')}
        />

      </div>
      <span className="text__heading5__textNeutral50">
        {`${task.buildingName || t('condoHouse')} - ${
          resolveAddress(task.address)}${task.address.unitNumber
          ? ` - Unit ${task.address.unitNumber}` : ''}`}
      </span>
    </>
  );

  const claimContent = () => (

    <>
      <div className="mt-24 mb-56">
        <Table
          uniqueId="claimTable"
          dashboardName={`${translPrefix}.claimTable`}
          data={[mapToTableData(task, t)]}
          dataProperties={dataProperties}
          headerNames={dataProperties}
        />
      </div>
      <TaskViewTab
        className={styles.tabsContainer}
        task={task}
        translPrefix={translPrefix}
        t={t}
        hideAffectedArea
      />

    </>
  );

  const leftButtonFn = () => setModalToShow(ModalToShow.RejectClaim);
  const rightButtonFn = () => goToPage(RouteName.EditNewTask, { id: task.id });

  const footer = () => (
    <div className={styles.footer}>
      <hr className={styles.divider} />
      <div className={styles.buttonsWrapper}>

        <Button
          buttonStyle={ButtonStyle.Secondary}
          className={classnames('text__body__large__secondary80', styles.button)}
          onClick={leftButtonFn}
        >
          {t(`${translPrefix}.rejectTask`)}
        </Button>
        <Button
          buttonStyle={ButtonStyle.Primary}
          className={classnames('text__body__large__secondary80', styles.button)}
          onClick={rightButtonFn}
        >
          {t(`${translPrefix}.acceptTask`)}
        </Button>
      </div>

    </div>

  );

  const handleModalToShow = () => {
    switch (modalToShow) {
      case ModalToShow.RejectClaim:
        return (
          <ModalRejection
            close={() => setModalToShow(ModalToShow.None)}
            handleButtonLeft={() => setModalToShow(ModalToShow.None)}
            onSubmit={onRejectClaim}
            translPrefix={`${translPrefix}.rejectClaim`}
          />
        );
      case ModalToShow.SuccessRejection:
        return (
          <ModalResult
            Icon={Tool}
            buttonTextRight={t('ok')}
            handleButtonRight={() => goToPage(RouteName.Maintenance)}
            handleButtonClose={() => goToPage(RouteName.Maintenance)}
            title={t(`${translPrefix}.successRejectionModal.title`)}
            content={t(`${translPrefix}.successRejectionModal.subtitle`)}
            modalAccentColor={ModalAccentColor.RED}
            withBackground
          />
        );
      default:
        return null;
    }
  };

  return (

    <div className={styles.containerWrapper}>
      <Breadcrumbs
        values={breadcrumbs(t, task.name, `${translPrefix}`, getTabToRedirect(task.status as RawNewTaskStatus))}
      />
      <div className={styles.container}>
        {header()}
        {claimContent()}
        {isNewClaim && footer()}
      </div>
      {handleModalToShow()}
    </div>
  );
};

export { TaskDetailClaim };
