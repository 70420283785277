import { Input, InputStyle } from '@mapix/common/src/common/input';
import { Table } from 'common/table';
import {
  ChangeEvent, useEffect, useReducer, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyState } from 'common/empty-state';
import { ReactComponent as Card } from 'assets/icons/card.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { Pagination } from '@mapix/common/src/common/paginator';
import { PropertyController } from 'networking/controllers/property-controller';
import { logger } from 'helpers/logger';
import { Spinner } from '@mapix/common/src/common/spinner';
import { goToPage, RouteName } from 'routes';
import { PropertyTableItem } from 'models/property-table-item';
import { PropertyTabs } from 'pages/properties/property-table-reducer';
import styles from '../rentals-dashboard.module.scss';
import { BuildingTabReducer, initialState } from './building-tab-reducer';

const iconCells = () => [
  <ChevronRight
    className={styles.icons}
    key="chevron"
  />];

const BuildingTab = () => {
  const [idBuilding, setIdBuilding] = useState('');
  const [fetching, setFetching] = useState(false);
  const [state, dispatch] = useReducer(BuildingTabReducer, initialState);
  const { t } = useTranslation();

  const getProperties = async () => {
    try {
      setFetching(true);
      const response = await PropertyController
        .getProperties(
          state.paginator?.currentPage || 1,
          10,
          PropertyTabs.Buildings,
          state.query,
          state.filters,
        );
      setFetching(false);
      dispatch({ type: 'PROPERTIES_FETCHED', paginator: response });
    } catch (err) {
      setFetching(false);
      logger.error(err as Error);
    }
  };

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'INPUT_CHANGED', input: e.target.value });
    dispatch({ type: 'SEARCH_QUERY', query: e.target.value });
  };

  const clickPagination = (nextPage: number) => {
    if (nextPage !== state.paginator.currentPage) {
      dispatch({ type: 'PAGE_CHANGED', newPage: nextPage });
    }
  };

  const getTable = () => {
    if (!state.paginator || fetching) {
      return (
        <div className={styles.spinner}><Spinner fixed={false} /></div>
      );
    }

    if (state.paginator.results.length === 0) {
      return (
        <div className={styles.emptyState}>
          <EmptyState
            Icon={Card}
            title={t('rentalsDashboard.noRentals')}
            iconStyle={styles.cardIcon}
          />
        </div>
      );
    }
    return (
      <>
        <Table
          headerNames={state.data.dataProperties}
          data={state.paginator.results}
          dataProperties={state.data.dataProperties}
          dashboardName="rentalsDashboard"
          iconCell={iconCells}
          uniqueId="id"
          setId={setIdBuilding}
          rowClick={((item: PropertyTableItem) => setIdBuilding(String(item.id)))}
        />
        {state.paginator.lastPage > 1
    && (
      <div className={styles.paginationContainer}>
        <Pagination
          pageLimit={state.paginator.lastPage}
          onClickFn={clickPagination}
          currentPage={state.paginator.currentPage}
        />
      </div>
    )}
      </>
    );
  };

  useEffect(() => {
    if (idBuilding !== '') {
      goToPage(RouteName.RentalBuildingDashboard, { idProperty: idBuilding });
    }
    getProperties();
  }, [idBuilding, state.query, state.paginator.currentPage]);

  return (
    <div className={styles.table}>
      <div className={styles.filterRow}>
        <Input
          id="filter"
          containerClass={styles.input}
          inputStyle={InputStyle.REGULAR}
          withSearchIcon
          placeholder={t('rentalsDashboard.buildingTab.search')}
          onChange={onChangeInput}
          value={state.input}
          t={t}
        />
      </div>
      {getTable()}
    </div>
  );
};

export { BuildingTab };
