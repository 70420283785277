import { RadioButton } from 'common/radio-button';
import { useState } from 'react';
import styles from './payment-methods-list.module.scss';
import { ReactComponent as Trash } from '../../../assets/icons/trash.svg';
import { ReactComponent as CreditCard } from '../../../assets/icons/credit-card.svg';
import { classnames } from '../../../helpers/utils';
import { Button, ButtonStyle } from '../../button';
import { PaymentMethod } from '../../../types';

type PaymentMethodsListProps = {
  onSelect?: (card: PaymentMethod) => void,
  onDelete?: () => void,
  card: PaymentMethod,
  t?: (text: string) => string,
  readOnly?: boolean,
  checked?: boolean,
};
const transPrefix = 'paymentMethod';
const PaymentMethodsList = ({
  onSelect = () => null, onDelete = () => null, card, t = () => '', readOnly = false, checked,
}: PaymentMethodsListProps) => {
  const [showDelete, setShowDelete] = useState<boolean>(false);

  const onChange = () => {
    onSelect(card);
  };

  return (
    <div className={showDelete ? classnames(styles.container, styles.containerAlert)
      : styles.container}
    >
      {!showDelete
        ? (
          <>
            <RadioButton readOnly={readOnly} checked={checked} name="cardGroup" onChangeFn={onChange} />
            <CreditCard className={checked ? styles.creditCardSelected : styles.creditCard} />
            <div className={classnames('text__body__large__textNeutral40', styles.asterisk)}>
              ****
            </div>
            <div className="text__body__medium__textNeutral40">
              {card.lastFourNumbers}
            </div>
            {!readOnly && <Trash className={styles.trash} onClick={() => setShowDelete(true)} />}
          </>
        )
        : (
          <>
            <div className="text__body__small__textNeutral50 bold">
              {t(`${transPrefix}.deleteText`)}
            </div>

            <>
              <Button
                onClick={() => setShowDelete(false)}
                buttonStyle={ButtonStyle.Back}
              >
                <div className="text__button__small__secondary90">
                  {t(`${transPrefix}.cancel`)}
                </div>
              </Button>

              <Button
                onClick={onDelete}
                buttonStyle={ButtonStyle.Back}
              >
                <div className="text__button__small__danger40">
                  {t(`${transPrefix}.delete`)}
                </div>
              </Button>
            </>

          </>
        )}
    </div>
  );
};

export { PaymentMethodsList };
