/**
 * Utility function to concat classNames.
 *
 * Usage: classnames('css_class1', 'css_class1')
 *
 * Can be used with objects where the keys are css classes and the
 * values are booleans that decide if classes are active or not:
 *
 * Example: classnames('input', { 'input-error': has_errors })
 *
 * @param  {...any} args
 * @returns string
 */
import { SetStateAction } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import IsToday from 'dayjs/plugin/isToday';
import IsYesterday from 'dayjs/plugin/isYesterday';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { constants } from '../config/constants';
import {
  InputStyle, PillColor, RawNewTaskEvent, RawNewTaskStatus, RawRequestStatus, TaskStatus, TaskType,
} from '../common/enums';
import {
  Address, NewTaskEventType, RequestEvent, TypeOfCost, RateEvent,
} from '../types';
import { FileType } from '../types/file';

dayjs.extend(AdvancedFormat);
dayjs.extend(IsYesterday);
dayjs.extend(IsToday);
dayjs.extend(timezone);
dayjs.extend(utc);

 type HTMLValidationError = {
   [n: string]: string
 };

type ClassnameObject = {
  [key: string]: string | boolean | number,
};

type Classname = ClassnameObject | string;

function classnames(...args: Classname[]): string {
  if (args.length === 1) {
    const [firstEntry] = args;
    if (firstEntry && typeof firstEntry === 'object') {
      /* firstEntry's keys whose value is truthy */
      const activeClasses = Object.entries(firstEntry)
        .filter(([, value]) => value).map(([key]) => key);
      return activeClasses.join(' ');
    }
    return firstEntry;
  }
  return args.filter((entry) => !!entry).map((value) => classnames(value)).join(' ');
}

const addOrRemoveFromArrayString = (list: string[], value: string) => {
  const index = list.findIndex((item) => item.toLowerCase() === value.toLowerCase());
  const newList = [...list];
  if (index > -1) {
    newList.splice(index, 1);
  } else {
    newList.push(value);
  }
  return newList;
};

const addOrRemoveFromStateArrayString = (
  option: string,
  setState: (value: SetStateAction<string[]>) => void,
) => {
  setState((prevState) => {
    let newArray = [...prevState];
    if (prevState.includes(option)) {
      newArray = prevState.filter((elem) => elem !== option);
    } else {
      newArray.push(option);
    }
    return newArray;
  });
};

/* Returns HTML5 form errors in an object accessed by the element id */
const checkHTMLErrors = (target: HTMLFormElement): HTMLValidationError => {
  const errors: HTMLValidationError = {};
  const { elements } = target;
  for (let i = 0; i < elements.length; i += 1) {
    const element = elements[i] as HTMLObjectElement;
    if (element.validationMessage && element.id) {
      errors[element.id] = element.validationMessage;
    }
  }
  return errors;
};

const addError = (
  errorMessage: string,
  propertyString: string,
  setState: (value: SetStateAction<HTMLValidationError>) => void,
) => setState(
  (prevState) => (
    { ...prevState, [propertyString]: errorMessage }),
);

const removeError = (
  propertyString: string,
  setState: (value: SetStateAction<HTMLValidationError>) => void,
) => setState(
  (prevState) => {
    const copy = { ...prevState };
    delete copy[propertyString];
    return copy;
  },
);

const addOrRemoveFromArrayObject = (list: any[], listItem: any, attribute: string) => {
  const index = list.findIndex((item) => item[attribute] === listItem[attribute]);
  const newList = [...list];
  if (index > -1) {
    newList.splice(index, 1);
  } else {
    newList.push(listItem);
  }
  return newList;
};

// First check is for any string contianing from 8 to 70 valid ascii characters
const isPasswordValid = (password: string): boolean => (/^[ -~]{8,70}$/.test(password))
                                                    // Must contain an uppercase letter
                                                    && !!(password.match(/[A-Z]/))
                                                    // Must contain an lowercase letter
                                                    && !!(password.match(/[a-z]/))
                                                    // Must contain a number
                                                    && !!(password.match(/[\d]/));

const getAvatarText = (firstName: string, lastName: string) => (
  (firstName.length && lastName.length) ? (firstName[0] + lastName[0]).toUpperCase() : ''
);

const hourOptions = () => {
  const hourArray: string[] = [];
  Array.from(Array(24).keys()).forEach((hourNumber: number) => {
    hourArray.push(`${hourNumber}:00`);
    hourArray.push(`${hourNumber}:30`);
  });
  return hourArray;
};

const emailRegex = /^\S+@\S+\.(\S){2,}$/;

const emailPattern = () => emailRegex.toString().slice(1, -1);

// This regex allows integers and decimal numbers, and admits a 0 only if the following character
// is a decimal point. It does not allow negative numbers.
const isStringNumeric = (inputValue: string) => /^(0\.\d+|0|([1-9]\d*(\.\d+)?))$/.test(inputValue);

const postalCodeRegex = (): RegExp => /^[a-zA-Z][0-9][a-zA-Z] [0-9][a-zA-Z][0-9]$/;

// Original regex: '^((?![\[;=*\]])[\x00-\x7E])+$'
// eslint-disable-next-line no-control-regex
const asciiWithoutSomeSpecialCharacters = (): RegExp => /^((?![[;=*\]])[\x00-\x7E])+$/;

const noNumbersInString = (str: string) => /^[\D]{0,}$/.test(str);

const isEmptyObject = (obj: Object) => !Object.keys(obj).length
|| Object.values(obj).every((x) => x === undefined || x === null);

const checkCreditCardType = (cardNumber: string) => {
  const visaRegEx = /^4[0-9]{12}(?:[0-9]{3})?/;
  const mastercardRegEx = /^5[1-5][0-9]{14}/;
  const amexpRegEx = /^3[47][0-9]{13}/;
  if (visaRegEx.test(cardNumber)) {
    return 'visa';
  } if (mastercardRegEx.test(cardNumber)) {
    return 'mastercard';
  } if (amexpRegEx.test(cardNumber)) {
    return 'amex';
  }
  return '';
};

const helperTextFn = (
  required: boolean,
  helperText: string,
  t: (text: string) => void,
  plainHelperText?: boolean,
  disabled?: boolean,
  inputStyle?: InputStyle,
) => {
  if (required || disabled || plainHelperText || inputStyle !== InputStyle.FORM) {
    return helperText;
  }
  if (helperText) {
    return `${t('optional')} - ${helperText}`;
  }
  return `${t('optional')}`;
};

const convertToBlob = async (dataURL: string) => (await fetch(dataURL)).blob();

const range = (totalNumbers: number, startingPoint?: number) => (
  Array.from({ length: totalNumbers }, (_, index) => index + 1 + (startingPoint || 0))
);

const mapFileToFileArray = (file: FileType | null) => (file?.url
  ? [{
    url: file?.url,
    filename: file?.filename,
  }]
  : []);

const groupByAttribute = <Type extends Record<K, any>, K extends keyof Type>(
  array: Type[], attribute: K) => array
    .reduce((group: any, object: Type) => {
      const category = object[attribute];
      const grouping = group;
      grouping[category as keyof any] = grouping[category] ?? [];
      grouping[category].push(object);
      return group;
    }, {});

const getTimeByTimezone = (date: string) => {
  const timeZone = dayjs.tz.guess();
  const serverTime = dayjs.tz(date, constants.defaultServerTimezone);
  const actualTime = dayjs(serverTime).tz(timeZone);
  return actualTime;
};

const getTimeZoneFormat = (date: string, format: string) => {
  const datejs = getTimeByTimezone(date);
  return `${datejs.format(format)}`;
};

const resolveDate = (date: string, locale: string, t: (text: string) => string, format: string) => {
  dayjs.locale(locale);
  const datejs = getTimeByTimezone(date);

  if (datejs.isYesterday()) {
    return t('dates.yesterday');
  }
  if (datejs.isToday()) {
    return t('dates.today');
  }
  return datejs.format(format).toLocaleUpperCase();
};

const resolveDateHour = (
  date: string,
  locale: string,
  t: (text: string) => string,
  format: string,
) => {
  dayjs.locale(locale);
  const datejs = getTimeByTimezone(date);

  if (datejs.isYesterday()) {
    return `${t('dates.yesterday')} - ${datejs.format('HH:mm')}`;
  }
  if (datejs.isToday()) {
    return `${t('dates.today')} - ${datejs.format('HH:mm')}`;
  }
  return datejs.format(format);
};

const parseServerError = (t: (text: string) => string, error: string | null) => (error
  ? t('error.genericValueError') : undefined);
const getDisplayFileSize = (size: number) => (size / (1024 * 1024)).toFixed(1);

const resolveAddress = (address: Address) => `${address.civicNumber} ${address.streetName} ${address.country} ${address.province}, ${address.zipCode}`;

const getRandomInt = (min: number, max: number) => Math.floor(
  Math.random() * (max - min + 1),
) + min;

const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

// get at most 'length' characters of string
const cropString = (str: string, length?: number) => {
  const defaultLength = 20;
  if (str.length > (length || defaultLength)) {
    return `${str.substring(0, length || defaultLength)}...`;
  }
  return str;
};

const getPillColorByTaskStatus = (taskStatus: TaskStatus) => {
  switch (taskStatus) {
    case TaskStatus.NEGOTIATING:
      return PillColor.Purple;
    case TaskStatus.IN_PROGRESS:
      return PillColor.Blue;
    case TaskStatus.SOLVED:
      return PillColor.Green;
    case TaskStatus.REJECTED:
    case TaskStatus.CANCELLED:
    case TaskStatus.WAITING_MANAGER_CANCELLED_PERCENTAGE_APPROVAL:
    case TaskStatus.WAITING_CONTRACTOR_CANCELLED_PERCENTAGE_APPROVAL:
      return PillColor.Red;
    case TaskStatus.APPLICATIONS:
      return PillColor.Yellow;
    default:
      return PillColor.Gray;
  }
};

const isRejectedRequest = (requestStatus: RawRequestStatus | '') => (
  requestStatus === RawRequestStatus.REJECTED_BY_CONTRACTOR
  || requestStatus === RawRequestStatus.REJECTED_BY_MANAGER
);

const isPendingRequest = (requestStatus: RawRequestStatus | '') => (
  requestStatus === RawRequestStatus.PENDING
);

const isRejectedAutomaticallyRequest = (requestStatus: RawRequestStatus | '') => (
  requestStatus === RawRequestStatus.REJECTED_AUTOMATICALLY
);

const partitionArray = <T>(array: T[], callback: (elem: T) => boolean): [T[], T[]] => {
  const matches = [] as T[];
  const nonMatches = [] as T[];

  // push each element into array depending on return value of `callback`
  array.forEach((element) => (callback(element) ? matches : nonMatches).push(element));

  return [matches, nonMatches];
};

const convertArrayToQueryParams = (query: string, values?: string[] | number[]): string => {
  let result = '';
  values?.forEach((value) => {
    result = result.concat(`&${query}[]=${value}`);
  });
  return result;
};

const getTotalPriceByTypeOfCost = (typeOfCost: TypeOfCost) => {
  if (typeOfCost.typeOfCost === 'budget') {
    return typeOfCost.budget;
  }
  if (typeOfCost.typeOfCost === 'hourlyRate') {
    return typeOfCost.hourlyRate * typeOfCost.amountOfHours;
  }

  return 0;
};

const isCancelledTaskOrWaitingPercentageApproval = (status: RawNewTaskStatus) => (
  status === RawNewTaskStatus.CANCELLED
  || status === RawNewTaskStatus.WAITING_MANAGER_CANCELLED_PERCENTAGE_APPROVAL
  || status === RawNewTaskStatus.WAITING_CONTRACTOR_CANCELLED_PERCENTAGE_APPROVAL
);

const isWaitingCancellationApproval = (status: RawNewTaskStatus) => (
  status === RawNewTaskStatus.WAITING_MANAGER_CANCELLED_PERCENTAGE_APPROVAL
  || status === RawNewTaskStatus.WAITING_CONTRACTOR_CANCELLED_PERCENTAGE_APPROVAL
);

const publicRequestReturnedToNegotiating = (taskType: TaskType, taskStatus: RawNewTaskStatus) => (
  taskType === TaskType.PublicTask && taskStatus === RawNewTaskStatus.READY
);

const getLastChangePercentageTaskEvent = (
  newTaskEvents: NewTaskEventType[],
): NewTaskEventType => {
  // eslint-disable-next-line no-plusplus
  for (let i = newTaskEvents.length - 1; i >= 0; i--) {
    if (newTaskEvents[i].eventType === RawNewTaskEvent.PERCENTAGE_CHANGED_BY_MANAGER
        || newTaskEvents[i].eventType === RawNewTaskEvent.PERCENTAGE_CHANGED_BY_CONTRACTOR) {
      return newTaskEvents[i];
    }
  }

  return undefined;
};

const getRateEventsList = (events: RequestEvent[] | NewTaskEventType[]): RateEvent[] => (
  events.map((event: RequestEvent | NewTaskEventType) => (
    {
      id: event.id,
      eventType: event.eventType,
      messageToContractor: event.messageToContractor,
      messageToManager: event.messageToManager,
      oldBudget: event.oldBudget,
      oldTypeOfCost: event.oldTypeOfCost,
      oldHourlyRate: event.oldHourlyRate,
    }))
);

export {
  classnames, addOrRemoveFromArrayString, checkHTMLErrors, helperTextFn,
  addOrRemoveFromArrayObject, isPasswordValid, emailPattern,
  hourOptions, getAvatarText, noNumbersInString, isEmptyObject,
  addError, removeError, convertToBlob, range, postalCodeRegex, mapFileToFileArray,
  asciiWithoutSomeSpecialCharacters, checkCreditCardType, groupByAttribute,
  resolveDate, resolveDateHour, parseServerError, getDisplayFileSize, resolveAddress,
  capitalizeFirstLetter, getRandomInt, emailRegex, cropString, getTimeZoneFormat,
  getPillColorByTaskStatus, partitionArray, addOrRemoveFromStateArrayString,
  isRejectedRequest, convertArrayToQueryParams, getTotalPriceByTypeOfCost,
  getRateEventsList, isCancelledTaskOrWaitingPercentageApproval, isStringNumeric,
  isWaitingCancellationApproval, getLastChangePercentageTaskEvent, isPendingRequest,
  publicRequestReturnedToNegotiating, isRejectedAutomaticallyRequest,
};
export type { HTMLValidationError };
