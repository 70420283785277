import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import LocalstorageBackend from 'i18next-localstorage-backend';
import HttpBackend from 'i18next-http-backend';

import { constants } from 'config/constants';
import { detectLanguage, SupportedLanguages } from './utils';

i18n
  .use(ChainedBackend)
  .use(initReactI18next)
  .init({
    lng: detectLanguage() || SupportedLanguages.English,
    fallbackLng: SupportedLanguages.English,
    react: {
      // NOTE: Suspense is still experimental at this point (React 17 and below)
      // so its inclusion on a project is not recommended.
      // We're delaying the render manually (but centrally).
      // Depending on when you read this, there's more information here:
      // https://reactjs.org/docs/concurrent-mode-suspense.html
      useSuspense: false,
    },
    debug: constants.i18nextDebugMode,
    backend: {
      backends: [
        LocalstorageBackend,
        HttpBackend,
      ],
      backendOptions: [
        {
          // NOTE: this expiration is only evaluated when the page is reloaded.
          expirationTime: constants.i18nextLocalstorageExpirationTime,
        },
      ],
    },
  });

export { i18n };
