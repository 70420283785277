import { PropertyOwner } from 'models/property-owner';

class PropertyOwnerSerializer {
  static deSerialize(data: RawPropertyOwner): SerializedPropertyOwner {
    return {
      email: data.email,
      name: data.name,
      lastname: data.last_name,
      phoneNumber: data.phone_number || '',
      countryCode: data.country_code || '',
      id: data.id,
    };
  }

  static serialize(data: PropertyOwner): RawPropertyOwner {
    return {
      name: data.name,
      last_name: data.lastname,
      email: data.email,
      id: data.id,
      phone_number: data.phoneNumber || null,
      country_code: data.phoneNumber ? data.countryCode : null,
    };
  }
}

export { PropertyOwnerSerializer };
