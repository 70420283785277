import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { InputStyle, RawNewTaskStatus, TaskStatus } from '@mapix/common/src/common/enums';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { Pagination } from '@mapix/common/src/common/paginator';
import { Select } from '@mapix/common/src/common/select';
import { Spinner } from '@mapix/common/src/common/spinner';
import { classnames, getTimeZoneFormat } from '@mapix/common/src/helpers/utils';
import { useMediaQuery } from '@mapix/common/src/hooks/use-media-query';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as PlusCircle } from 'assets/icons/plus-circle.svg';
import { ReactComponent as Tool } from 'assets/icons/tool.svg';
import { EmptyState } from 'common/empty-state';
import { Breakpoints } from 'common/enums';
import { Menu } from 'common/menu';
import { SelectProperty } from 'common/select-property';
import { AppContext } from 'context';
import { logger } from 'helpers/logger';
import { parseTaskStatusTenant, resolvePillColorTenantByStatus } from 'helpers/utils';
import { MaintenanceController } from 'networking/controllers/maintenance-controller';
import {
  useContext, useEffect, useReducer, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { AppLink, RouteName, goToPage } from 'routes';
import { Pill } from '@mapix/common/src/common/pill';
import {
  ClaimsReducer,
  initialState,
} from './claims-reducer';
import styles from './claims.module.scss';

type List = {
  name: string,
  route: RouteName,
};

type CardProps = {
  category: string,
  creationDate: string,
  status: TaskStatus,
  id: number,
  mobile: boolean,
  t: (key: string) => string,
};

const translPrefix = 'claims';

const getIcon = (status: TaskStatus, t: (text: string) => string) => {
  const color = resolvePillColorTenantByStatus(status as TaskStatus);

  return (
    <Pill
      content={t(`taskStatus.${status}`)}
      color={color}
      textStyle="text__body__medium__textNeutral10"
      containerClass={styles.pill}
    />
  );
};

const Card = ({
  id, category, creationDate, status, mobile, t,
}: CardProps) => (
  <>
    {mobile
      ? (
        <AppLink routeName={RouteName.ClaimDetail} pathParams={{ id }} className={styles.link}>
          <div className={styles.cardMobile}>
            <div className={styles.column}>
              <p className={classnames(styles.bold, 'text__heading3__textNeutral40')}>
                {t(`specializations.${category}`)}
              </p>
              <div className="row">
                <p className={classnames(styles.created, 'text__body__medium__textNeutral40')}>
                  {`${t('claims.created')}:`}
                </p>
                <p className="text__body__small__textNeutral40">
                  {getTimeZoneFormat(creationDate, 'DD/MM/YYYY')}
                </p>
              </div>
              {getIcon(status, t)}
            </div>
            <ChevronRight className={styles.chevron} />
          </div>
        </AppLink>
      )
      : (
        <AppLink routeName={RouteName.ClaimDetail} pathParams={{ id }} className={styles.link}>
          <div className={styles.card}>
            <div className={styles.column}>
              <div className="row align-center">
                <p className="text__heading4__textNeutral50">
                  {t(`specializations.${category}`)}
                </p>
                {getIcon(status, t)}
              </div>
              <div className="row">
                <p className={classnames(styles.created, 'text__body__small__textNeutral40')}>
                  {t('claims.created')}
                </p>
                <p className="text__body__small__textNeutral40">
                  {getTimeZoneFormat(creationDate, 'DD/MM/YYYY')}
                </p>
              </div>
            </div>
            <ChevronRight className={styles.chevron} />
          </div>
        </AppLink>
      )}
  </>
);

const Claims = () => {
  const { t } = useTranslation();
  const [stateReducer, dispatch] = useReducer(ClaimsReducer, initialState);
  const { state } = useContext(AppContext);
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  const [fetching, setFetching] = useState(false);
  const [errorServer, setErrorServer] = useState(false);

  const listPath: List[] = [
    { name: t('claims.home'), route: RouteName.HomeTenant },
    { name: t('claims.title'), route: RouteName.Claims },
  ];

  const getClaims = async () => {
    try {
      setFetching(true);
      const response = await MaintenanceController
        .getClaims(
          stateReducer.navigation?.currentPage || 1,
          4,
          state.property.id,
          state.property.unitId,
          stateReducer.status,
        );
      dispatch({ type: 'TASKS_FETCHED', paginator: response });
      setFetching(false);
    } catch (err) {
      logger.error(err as Error);
      setErrorServer(true);
      setFetching(false);
    }
  };

  useEffect(() => {
    getClaims();
  }, [stateReducer.navigation?.currentPage, stateReducer.status,
    stateReducer.query, state.property]);

  const clickPagination = (nextPage: number) => {
    if (nextPage !== stateReducer.navigation.currentPage) {
      dispatch({ type: 'PAGE_CHANGED', newPage: nextPage });
    }
  };

  const goToCreateClaim = () => {
    if (state.property.unitId) {
      goToPage(RouteName.CreateClaimUnit, {
        propertyId: Number(state.property.id),
        unitId: Number(state.property.unitId),
      });
    } else {
      goToPage(RouteName.CreateClaim, { propertyId: Number(state.property.id) });
    }
  };

  const getData = () => {
    if (stateReducer.tasks.length === 0) {
      return (
        <div className={styles.emptyState}>
          <EmptyState
            Icon={Tool}
            title={t(`emptyState.claims.${stateReducer.status ? 'titleNoMatches' : 'title'}`)}
            subtitle={t('emptyState.claims.subtitle')}
            iconStyle={styles.toolIcon}
            withPlusIcon
            handleOnClick={() => goToCreateClaim()}
          />
        </div>
      );
    }
    return (
      <div className={styles.data}>
        {stateReducer.tasks.map(
          (elem) => (
            <Card
              category={elem.category}
              creationDate={elem.createdAt}
              status={parseTaskStatusTenant(elem.status as RawNewTaskStatus) as TaskStatus}
              id={elem.id}
              mobile={mobile}
              t={t}
            />
          ),
        )}
      </div>
    );
  };

  const onClickStatusFilter = (statusFilter: string) => {
    if (statusFilter === 'remove') {
      dispatch({ type: 'STATUS_CHANGED', status: '' });
    } else {
      dispatch({ type: 'STATUS_CHANGED', status: statusFilter });
    }
  };

  const selectStatus = () => (

    <Select
      id="status"
      options={stateReducer.statusFilters}
      onClickOption={onClickStatusFilter}
      required
      label={t(`${translPrefix}.selectStatus.placeholder`)}
      placeholder={t(`${translPrefix}.selectStatus.placeholder`)}
      value={stateReducer.status ? t(`${translPrefix}.statusFilters.${stateReducer.status}`) : ''}
      inputStyle={InputStyle.REGULAR}
      containerClass={styles.selectStatus}
      t={t}
      translationPrefix={`${translPrefix}.statusFilters`}
    />

  );

  return (
    <div className={styles.container}>
      {errorServer
      && <ErrorMessage message={t('error.errorMessage')} handleClose={() => setErrorServer(false)} />}
      {mobile
      && <Menu list={listPath} className={styles.menu} />}

      <div className={mobile ? 'text__heading1__textNeutral40' : 'text__heading4__textNeutral40'}>
        {t('claims.title')}
      </div>
      <p className={classnames(styles.subtitle, 'text__body__small__textNeutral30')}>
        {t('claims.subtitle')}
      </p>
      <div className={styles.showingContainer}>
        <p className={classnames(styles.showing, mobile ? 'text__body__medium__textNeutral40' : 'text__body__small__textNeutral50')}>
          {`${t('claims.showing')}: `}
        </p>
        <div className={styles.select}>
          <SelectProperty />
        </div>
      </div>
      { mobile
        ? (
          <div className={styles.buttonsContainer}>
            <div className={styles.buttonsLeft}>
              {selectStatus()}
            </div>
            <Button
              className={styles.claimButton}
              buttonStyle={ButtonStyle.Primary}
              onClick={() => goToCreateClaim()}
            >
              <div className="row align-justify-center">
                <PlusCircle className={styles.plusIcon} />
                {t('claims.claim')}
              </div>
            </Button>
          </div>
        )
        : (
          <div className={styles.buttonsContainer}>
            {selectStatus()}
            <div className={styles.buttonsRight}>
              <Button
                className={styles.claimButton}
                buttonStyle={ButtonStyle.Primary}
                onClick={() => goToCreateClaim()}
              >
                <div className="row align-justify-center">
                  <PlusCircle className={styles.plusIcon} />
                  {t('claims.claim')}
                </div>
              </Button>
            </div>
          </div>
        )}
      { fetching
        ? <div className={styles.spinner}><Spinner fixed={false} /></div>
        : getData()}
      {stateReducer.navigation.lastPage > 1
          && (
            <div className={styles.paginationContainer}>
              <Pagination
                pageLimit={stateReducer.navigation.lastPage}
                onClickFn={(newPage: number) => clickPagination(newPage)}
                currentPage={stateReducer.navigation.currentPage}
              />
            </div>
          )}
    </div>
  );
};

export { Claims };
