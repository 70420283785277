import React from 'react';
import { ErrorType, ErrorPage as CommonErrorPage } from '@mapix/common/src/common/error-page';
import { useTranslation } from 'react-i18next';
import { goToPage, RouteName } from 'routes';

const ErrorPage = () => {
  const { t } = useTranslation();
  const params = new URLSearchParams(window.location.search);
  const urlErrorCode = params.get('error');
  const errorCode = (urlErrorCode && Object.values(ErrorType).includes(Number(urlErrorCode)))
    ? Number(urlErrorCode) : ErrorType.NotFound;

  return (
    <CommonErrorPage
      errorCode={errorCode}
      goBack={() => goToPage(RouteName.Login)}
      t={t}
    />
  );
};
export { ErrorPage, ErrorType };
