class Country {
  code: string;

  name: string;

  provinces: string[];

  constructor(params: SerializedCountry, code: string) {
    this.code = code;
    this.name = params.fullName;
    this.provinces = params.provinces;
  }
}

export { Country };
