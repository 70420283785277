import { ModalAccentColor, ModalResult } from '../modal-result';
import { ReactComponent as Tool } from '../../assets/icons/tool.svg';

type ModalProps = {
  handleButtonClose: () => void,
  t: (key: string) => string,
  handleOk: () => void
};

const translPrefix = 'genericErrorModal';

const GenericErrorModal = ({
  handleButtonClose, handleOk, t,
}: ModalProps) => (
  <ModalResult
    modalAccentColor={ModalAccentColor.RED}
    Icon={Tool}
    handleButtonRight={handleOk}
    buttonTextRight={t('ok')}
    handleButtonClose={handleButtonClose}
    title={t(`${translPrefix}.title`)}
    content={t(`${translPrefix}.subtitle`)}
    withBackground
  />
);

export { GenericErrorModal };
