import { Paginator } from 'models/paginator';
import { PropertyTableItem } from 'models/property-table-item';
import { ModalFilterItem, ModalFilterObject } from 'common/filter-modal/filter-modal';
import { PropertyFilters } from './property-filters';

enum PropertyTabs {
  All = 'All',
  Buildings = 'Buildings',
  CondosAndHouses = 'CondosAndHouses',
}

/* Here we define the order and which properties of the object are we going to use for each table
* these should match or model */
const propertyAllProperties = ['type', 'address', 'landlord', 'condition', 'status'];
const propertyBuildingProperties = ['address', 'buildingName', 'landlord', 'unitsCount', 'condition'];
const propertyCondosAndHousesProperties = ['address', 'landlord', 'tenant', 'status', 'condition'];

type PropertyChange = {
  placeholderText: string,
  dataProperties: string[],
  filters: ModalFilterObject[],
};

type PropertyState = {
  currentTab: string,
  data: PropertyChange,
  paginator: Paginator<PropertyTableItem>,
  appliedFilters: ModalFilterItem[],
  code: string,
  query: string,
  input: string,
  show: boolean,
};

type URLFilters = {
  appliedFilters: ModalFilterItem[],
  tab: string | undefined,
};

type Action =
  | { type: 'TAB_CHANGED', newTab: string }
  | { type: 'PAGE_CHANGED', newPage: number }
  | { type: 'SEARCH_QUERY', query: string }
  | { type: 'INPUT_CHANGED', input: string }
  | { type: 'MODAL_VISIBILITY', show: boolean }
  | { type: 'APPLY_FILTERS', filters: ModalFilterItem[] }
  | { type: 'CLEAR_FILTERS' }
  | { type: 'PROPERTIES_FETCHED', paginator: Paginator<PropertyTableItem> };

function switchTab(tab: string): PropertyChange {
  switch (tab) {
    case PropertyTabs.All:
      return {
        placeholderText: 'propertyDashboard.placeholders.all',
        dataProperties: propertyAllProperties,
        filters: PropertyFilters.all,
      };
    case PropertyTabs.Buildings:
      return {
        placeholderText: 'propertyDashboard.placeholders.buildings',
        dataProperties: propertyBuildingProperties,
        filters: PropertyFilters.building,
      };
    case PropertyTabs.CondosAndHouses:
      return {
        placeholderText: 'propertyDashboard.placeholders.condoshouses',
        dataProperties: propertyCondosAndHousesProperties,
        filters: PropertyFilters.condoshouses,
      };
    default:
      throw new Error('Invalid tab');
  }
}

const changePage = (
  currentPage: number,
  paginator: Paginator<PropertyTableItem>,
): Paginator<PropertyTableItem> => {
  const newPaginator = { ...paginator };
  newPaginator.currentPage = currentPage;
  return newPaginator;
};

const initialState = (urlFilters?: URLFilters) => ({
  currentTab: urlFilters?.tab || PropertyTabs.All,
  data: switchTab(urlFilters?.tab || PropertyTabs.All),
  code: 'FETCHING',
  paginator: new Paginator(null, []),
  appliedFilters: urlFilters?.appliedFilters || [],
  query: '',
  input: '',
  show: false,
});

function PropertyTableReducer(state: PropertyState, action: Action): PropertyState {
  switch (action.type) {
    case 'TAB_CHANGED':
      return {
        ...state,
        data: switchTab(action.newTab),
        currentTab: action.newTab,
        paginator: changePage(1, state.paginator),
        appliedFilters: [],
        code: 'FETCHING',
      };
    case 'PROPERTIES_FETCHED':
      return {
        ...state,
        paginator: action.paginator,
        code: 'READY',
      };
    case 'PAGE_CHANGED':
      return {
        ...state,
        paginator: changePage(action.newPage, state.paginator),
        code: 'FETCHING',
      };
    case 'INPUT_CHANGED':
      return {
        ...state,
        input: action.input,
      };
    case 'SEARCH_QUERY':
      return {
        ...state,
        query: action.query,
        code: 'FETCHING',
        paginator: changePage(1, state.paginator),
      };
    case 'MODAL_VISIBILITY':
      return {
        ...state,
        show: action.show,
      };
    case 'APPLY_FILTERS':
      return {
        ...state,
        show: false,
        appliedFilters: action.filters,
        paginator: changePage(1, state.paginator),
      };
    case 'CLEAR_FILTERS':
      return {
        ...state,
        appliedFilters: [],
        paginator: changePage(1, state.paginator),
        input: '',
        query: '',
      };
    default:
      return {
        ...state,
      };
  }
}

export { PropertyTableReducer, PropertyTabs, initialState };
