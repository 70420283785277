import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@mapix/common/src/common/spinner';

import { useParams } from 'react-router-dom';
import { PropertyController } from 'networking/controllers/property-controller';
import { Property } from 'models/property';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import styles from '../rentals-dashboard.module.scss';
import { CommonDashboard } from '../common-dashboard';

type ParamType = {
  idProperty: string,
};

const RentalDashboardBuilding = () => {
  const { idProperty } = useParams<ParamType>();
  const [property, setProperty] = useState<Property | null>();
  const [fetching, setFetching] = useState(false);
  const [errorServer, setErrorServer] = useState(false);
  const { t } = useTranslation();

  const getPropertyDetail = async () => {
    try {
      setFetching(true);
      const propertyComing = await PropertyController.getDetailedProperty(idProperty);
      setProperty(propertyComing);
      setFetching(false);
    } catch {
      setErrorServer(true);
      setFetching(false);
    }
  };

  useEffect(() => {
    getPropertyDetail();
  }, []);

  if (fetching || !property) {
    return (
      <div className={styles.spinner}><Spinner /></div>
    );
  }

  return (
    <div className={styles.container}>
      {errorServer
      && <ErrorMessage message={t('error.errorMessage')} handleClose={() => setErrorServer(false)} />}
      <div className="text__heading4__textNeutral40">
        {property?.fullAddress}
      </div>
      <CommonDashboard
        building
        id={property.id}
      />
    </div>
  );
};

export { RentalDashboardBuilding };
