import { MakePaymentModalState } from '@mapix/common/src/common/enums';
import { NuveiResponse } from '@mapix/common/src/common/make-payment/create-payment';
import { MakePayment } from '@mapix/common/src/common/make-payment/make-payment';
import { getSafeCharge } from '@mapix/common/src/common/make-payment/safe-charge';
import { constants } from 'config/constants';

import {
  OpenOrderResponse,
  PaymentMethod,
  UpdatePaymentBill,
} from '@mapix/common/src/types';
import { AppContext } from 'context';
import { logger } from 'helpers/logger';
import { CreatePaymentController } from 'networking/controllers/create-payment-controller';
import {
  useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@mapix/common/src/common/spinner';
import { NewBillIndex } from 'networking/types/new-bill';

type MakePaymentBillProps = {
  onClose: () => void,
  bill: NewBillIndex | null,
  getBills: () => void,
};

const translPrefix = 'paymentMethod';

const MakePaymentBill = ({
  onClose, bill, getBills,
}: MakePaymentBillProps) => {
  const [openOrderData, setOpenOrderData] = useState<OpenOrderResponse>({} as OpenOrderResponse);
  const [safeCharge, setSafeCharge] = useState<any>(null);
  const [contentToShow, setContentToShow] = useState<MakePaymentModalState>(
    MakePaymentModalState.ShowSpinner,
  );
  const [storedCards, setStoredCards] = useState<PaymentMethod[]>([]);

  const { t } = useTranslation();

  const getStoredCards = async () => {
    setContentToShow(MakePaymentModalState.ShowSpinner);
    try {
      const response = await CreatePaymentController.getPaymentMethod();
      setStoredCards(response);
      setContentToShow(MakePaymentModalState.ShowPaymentMethods);
    } catch (err) {
      logger.error(err as Error);
    }
  };

  const { state: { user: { email } } } = useContext(AppContext);

  const makePaymentInformation = {
    amount: bill?.totalAmount.toString() || '',
    reference: `${bill?.taskName} (${bill?.taskId})`,
    currency: bill?.currency || '',
  };

  const onSubmitCurrencyAmount = async () => {
    setContentToShow(MakePaymentModalState.ShowSpinner);

    try {
      const responseOpenOrder = await CreatePaymentController.openOrderBill({
        billId: bill?.id!,
        currency: makePaymentInformation.currency,
        amount: makePaymentInformation.amount,
      });

      setOpenOrderData(responseOpenOrder);

      const actualSafeCharge = await getSafeCharge(
        responseOpenOrder?.merchantId,
        responseOpenOrder?.merchantSiteId,
        constants.safeChargeEnv,
      );
      setSafeCharge(actualSafeCharge);

      setContentToShow(MakePaymentModalState.ShowPaymentMethods);
    } catch (err) {
      logger.error(err as Error);
      setContentToShow(MakePaymentModalState.ShowNuveiError);
    }
  };

  const updatePaymentFn = async (updatePayment: UpdatePaymentBill) => {
    try {
      await CreatePaymentController.updatePaymentBill(updatePayment);
    } catch (err) {
      logger.error(err as Error);
      setContentToShow(MakePaymentModalState.ShowGenericError);
    }
  };

  useEffect(() => {
    getStoredCards();
  }, []);

  useEffect(() => {
    onSubmitCurrencyAmount();
  }, []);

  const deletePaymentMethod = async (id: number) => {
    await CreatePaymentController.deletePaymentMethod(id);
    await getStoredCards();
  };

  const updatePayment = (response: NuveiResponse, openOrderId: number) => {
    const updatePaymentData: UpdatePaymentBill = {
      status: response.result,
      errorCode: response.errCode,
      expirationYear: response.ccExpYear,
      lastFourNumbers: response.last4Digits,
      reason: response.errorDescription,
      id: openOrderId,
      userPaymentOptionId: response.userPaymentOptionId,
      billId: bill?.id!,
      expirationMonth: response.ccExpMonth,
      transactionId: response.transactionId,
    };

    updatePaymentFn(updatePaymentData);
  };

  const refreshData = async () => {
    getBills();
    getStoredCards();
  };

  if (contentToShow === MakePaymentModalState.ShowSpinner) {
    return (<Spinner />);
  }

  return (
    <MakePayment
      onClose={onClose}
      t={t}
      email={email}
      storedCards={storedCards}
      updatePaymentFn={updatePayment}
      refreshData={refreshData}
      deletePaymentMethod={deletePaymentMethod}
      makePaymentInformation={makePaymentInformation}
      openOrderData={openOrderData}
      safeCharge={safeCharge}
      translPrefix={translPrefix}
      setContentToShow={setContentToShow}
      contentToShow={contentToShow}
      isBill
    />
  );
};

export { MakePaymentBill };
