import { ReactComponent as Users } from '@mapix/common/src/assets/icons/users.svg';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { Specializations } from '@mapix/common/src/common/enums';
import { Pagination } from '@mapix/common/src/common/paginator';
import { Spinner } from '@mapix/common/src/common/spinner';
import { classnames } from '@mapix/common/src/helpers/utils';
import { ModalAccentColor, ModalResult } from '@mapix/common/src/common/modal-result';
import { useDebounceEffect } from '@mapix/common/src/hooks/use-debounce';
import { Paginator, Specialization } from '@mapix/common/src/types';
import { ApplicationCard } from 'common/application-card/application-card';
import { ModalWithFilters } from 'common/modal-with-filters/modal-with-filters';
import { SearchFiltersAndSort } from 'common/search-filters-and-sort/search-filters-and-sort';
import { MIN_SEARCH_LENGTH } from 'config/constants';
import { logger } from 'helpers/logger';
import { MaintenanceController } from 'networking/controllers/maintenance-controller';
import { PublicRequestIndex } from 'networking/types/public-request';
import {
  FormEvent, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { RouteName, goToPage } from 'routes';
import { ReactComponent as User } from 'assets/icons/user.svg';
import styles from './applications-tab.module.scss';

type ApplicationsTabProps = {
  taskId: number,
  refreshTask: () => void,
};

type ApplicationTabFilters = {
  order: string,
  search: string,
  specializations: Specialization[]
};

const initPaginator: Paginator = {
  currentPage: 1,
  lastPage: 0,
  pageSize: 8,
  total: 0,
};

const translPrefix = 'newTaskDetail.tabs.applications';

enum ApplicationsOrderOptions {
  LOWEST_RATE = 'lowest_rate',
  MOST_RECENT = 'most_recent',
  EARLIEST_START_DATE = 'earliest_start_date',
}

const initFilters: ApplicationTabFilters = {
  order: ApplicationsOrderOptions.LOWEST_RATE,
  search: '',
  specializations: [],
};

enum ContentToShow {
  APPLICATIONS = 'applications',
  EMPTY_STATE = 'emptyState',
  SPINNER = 'spinner',
  NO_MATCHES = 'noMatches',
}

enum ModalToShowState {
  None = 'none',
  AssignPublicTaskConfirmation = 'assignPublicTaskConfirmation',
  RejectPublicTaskConfirmation = 'rejectPublicTaskConfirmation',
  FiltersToShow = 'filtersToShow',
}

const ApplicationsTab = ({ taskId, refreshTask }: ApplicationsTabProps) => {
  const [paginator, setPaginator] = useState<Paginator>(initPaginator);
  const [filters, setFilters] = useState<ApplicationTabFilters>(initFilters);
  const [resetFilterApiCall, setResetFilterApiCall] = useState(false);

  const [modalToShowState, setModalToShowState] = useState<ModalToShowState>(ModalToShowState.None);
  const [contentToShow, setContentToShow] = useState<ContentToShow>(ContentToShow.SPINNER);

  const [specializationList, setSpecializationList] = useState<
  Specialization[]>([] as Specialization[]);
  const [specializationMap, setSpecializationMap] = useState<Map<string, Specialization>>(
    new Map<string, Specialization>(),
  );

  const trimmedQuery = filters.search.trim();
  const trimmedQueryLength = trimmedQuery.length;

  const [applications,
    setApplications,
  ] = useState<PublicRequestIndex[]>([] as PublicRequestIndex[]);

  const applicationId = useRef<number>(-1);

  const onClickPagination = (nextPage: number) => {
    if (nextPage !== paginator.currentPage) {
      setPaginator((prevState) => ({ ...prevState, currentPage: nextPage }));
    }
  };

  const orderOptions = Object.values(ApplicationsOrderOptions);

  const { t } = useTranslation();

  const resolveContentToShow = (results: PublicRequestIndex[]) => {
    if (results.length) {
      setContentToShow(ContentToShow.APPLICATIONS);
    } else if (trimmedQueryLength || filters.specializations.length) {
      setContentToShow(ContentToShow.NO_MATCHES);
    } else {
      setContentToShow(ContentToShow.EMPTY_STATE);
    }
  };

  const getApplications = async () => {
    const specializationFilters = filters.specializations.length
      ? filters.specializations.map((spec) => spec.id) : undefined;

    try {
      setContentToShow(ContentToShow.SPINNER);
      const { navigation, results } = await MaintenanceController
        .getContractorApplications(
          paginator.currentPage,
          paginator.pageSize,
          taskId,
          filters.order,
          trimmedQueryLength < MIN_SEARCH_LENGTH ? '' : trimmedQuery,
          specializationFilters,
        );
      setApplications(results);
      resolveContentToShow(results);
      if (resetFilterApiCall) setResetFilterApiCall(false);
      setPaginator(navigation);
    } catch (err) {
      logger.log(err);
    }
  };

  const getSpecializations = async () => {
    try {
      const actualSpecializations = await MaintenanceController.getSpecializations();

      const removeOtherBecauseNotSupported = actualSpecializations.filter(
        (spec) => spec.name !== 'Other',
      );

      setSpecializationList(removeOtherBecauseNotSupported);
      setSpecializationMap(new Map(actualSpecializations.map((spec) => [spec.name, spec])));
    } catch (err) {
      logger.log(err);
    }
  };

  useEffect(() => {
    getSpecializations();
  }, []);

  useEffect(() => {
    getApplications();
  }, [filters.order, filters.specializations, paginator.currentPage]);

  const triggerDebauncedCall = contentToShow !== ContentToShow.SPINNER && (
    trimmedQueryLength >= MIN_SEARCH_LENGTH || resetFilterApiCall);

  const onChangeQuery = (event: FormEvent<HTMLInputElement>) => {
    const newQuery = event.currentTarget.value;
    const newTrimmedQueryLength = newQuery.trim().length;
    const userIsDeletingString = trimmedQueryLength > newTrimmedQueryLength;

    const trimmedQueryIsLessThanMinSearchLengthFromBiggerString = (
      newTrimmedQueryLength === (MIN_SEARCH_LENGTH - 1)
      && trimmedQueryLength >= 0
      && userIsDeletingString
    );
    if (trimmedQueryIsLessThanMinSearchLengthFromBiggerString) {
      setResetFilterApiCall(true);
    }
    setFilters((prevState) => ({ ...prevState, search: newQuery }));
  };

  useDebounceEffect(() => {
    if (triggerDebauncedCall) {
      getApplications();
    }
  }, [filters.search]);

  const openAssignConfirmationModal = (aplicationId: number) => {
    applicationId.current = aplicationId;
    setModalToShowState(ModalToShowState.AssignPublicTaskConfirmation);
  };

  const openRejectConfirmationModal = (aplicationId: number) => {
    applicationId.current = aplicationId;
    setModalToShowState(ModalToShowState.RejectPublicTaskConfirmation);
  };

  const closeConfirmationModalAndResetApplicationId = () => {
    applicationId.current = -1;
    setModalToShowState(ModalToShowState.None);
    setContentToShow(ContentToShow.APPLICATIONS);
  };

  const handleAssignPublicRequest = async (publicRequestId: number) => {
    try {
      setContentToShow(ContentToShow.SPINNER);

      await MaintenanceController.assignPublicTask(publicRequestId);
      goToPage(RouteName.PublicRequestDetail, { id: publicRequestId });
    } catch (err) {
      logger.log(err);
    }
  };

  const handleRejectPublicRequest = async (publicRequestId: number) => {
    try {
      setContentToShow(ContentToShow.SPINNER);

      await MaintenanceController.rejectPublicTask(
        publicRequestId,
        { reasonForRejection: undefined },
      );

      closeConfirmationModalAndResetApplicationId();
      refreshTask();
      getApplications();
    } catch (err) {
      logger.log(err);
    }
  };

  const mapApplicationToApplicationCard = (application: PublicRequestIndex) => {
    const {
      contractorMessage, startDate,
      contractor: {
        cities, certifications, name, lastName, specializations, profilePhoto,
      },
    } = application;

    const specializationNames = specializations.map(
      (specialization) => specialization.specialization.name as Specializations,
    );

    const certificationNames = certifications.map(
      (certification) => certification.certification.name,
    );

    return (
      <ApplicationCard
        key={application.id}
        cities={cities}
        certifications={certificationNames}
        handleAssign={() => openAssignConfirmationModal(application.id)}
        handleReject={() => openRejectConfirmationModal(application.id)}
        name={name}
        lastName={lastName}
        message={contractorMessage}
        photoUrl={profilePhoto?.url}
        specializations={specializationNames}
        startDate={startDate}
        typeOfCost={{ ...application }}
      />
    );
  };

  const onChangeInputOrSelect = (value: string) => (
    setFilters((prevState) => ({ ...prevState, order: value }))
  );

  const onClickApplyFilters = (specNames: string[]) => {
    const getSpecializationObjects = () => specNames.map(
      (spec) => specializationMap.get(spec) as Specialization,
    );

    setFilters((prevState) => ({
      ...prevState,
      specializations: getSpecializationObjects(),
    }));

    setModalToShowState(ModalToShowState.None);
  };

  const checkboxesOptions = specializationList.map((specialization) => specialization.name);
  const checkboxesSelected = filters.specializations.map((spec) => spec.name);

  const clearFilters = () => {
    setFilters(initFilters);
  };

  const noApplicationsState = () => (
    <div className={styles.noApplicationsState}>
      <div className={styles.usersIconContainer}>
        <Users className={styles.usersIcon} />
      </div>
      <div className="text__body__large__textNeutral40">
        {t(`${translPrefix}.noApplicationsState`)}
      </div>
    </div>
  );

  const noApplicationsForFiltersState = () => (
    <div className="row justify-center mt-44">
      <span className="text__body__large__textNeutral40">
        {t(`${translPrefix}.noApplicationsForFiltersState`)}
      </span>
      <Button
        className="text__body__large__primary50"
        buttonStyle={ButtonStyle.Link}
        onClick={clearFilters}
      >
        {t(`${translPrefix}.seeAll`)}
      </Button>
    </div>
  );

  const showCorrespondingModal = () => {
    if (modalToShowState === ModalToShowState.AssignPublicTaskConfirmation) {
      return (
        <ModalResult
          title={t(`${translPrefix}.assignPublicTaskConfirmationModal.title`)}
          content={t(`${translPrefix}.assignPublicTaskConfirmationModal.content`)}
          Icon={User}
          withBackground
          modalStyle={styles.confirmationModal}
          modalAccentColor={ModalAccentColor.PURPLE}
          buttonTextLeft={t('Cancel')}
          buttonTextRight={t('Assign')}
          handleButtonClose={() => setModalToShowState(ModalToShowState.None)}
          handleButtonLeft={() => setModalToShowState(ModalToShowState.None)}
          handleButtonRight={() => handleAssignPublicRequest(applicationId.current)}
        />
      );
    }

    if (modalToShowState === ModalToShowState.RejectPublicTaskConfirmation) {
      return (
        <ModalResult
          title={t(`${translPrefix}.rejectPublicTaskConfirmationModal.title`)}
          content={t(`${translPrefix}.rejectPublicTaskConfirmationModal.content`)}
          Icon={User}
          withBackground
          modalStyle={styles.confirmationModal}
          modalAccentColor={ModalAccentColor.RED}
          buttonTextLeft={t('Cancel')}
          buttonTextRight={t('Ok')}
          handleButtonClose={() => setModalToShowState(ModalToShowState.None)}
          handleButtonLeft={() => setModalToShowState(ModalToShowState.None)}
          handleButtonRight={() => handleRejectPublicRequest(applicationId.current)}
        />
      );
    }

    return null;
  };

  const showApplicationsState = () => (
    <div className={styles.applicationCardWrapper}>
      {applications.map(mapApplicationToApplicationCard)}
    </div>
  );

  const handleContentToShow = () => {
    if (contentToShow === ContentToShow.SPINNER) return <Spinner />;

    return (
      <>
        {contentToShow === ContentToShow.APPLICATIONS
          ? (showApplicationsState())
          : (noApplicationsForFiltersState())}
        <div className={styles.pagination}>
          <Pagination
            currentPage={paginator.currentPage}
            pageLimit={paginator.lastPage}
            onClickFn={onClickPagination}
          />
        </div>
      </>
    );
  };

  const applicationsAvailableState = () => (
    <>
      <SearchFiltersAndSort
        filterButtonText={t(`${translPrefix}.filterButtonText`)}
        onChangeSearchInput={onChangeQuery}
        onClickFilterButton={() => setModalToShowState(ModalToShowState.FiltersToShow)}
        searchInputPlaceholder={t(`${translPrefix}.searchInputPlaceholder`)}
        searchInputValue={filters.search}
        onClickOptionSelect={(option: string) => onChangeInputOrSelect(option)}
        selectOptions={orderOptions}
        selectedOption={filters.order}
        selectTranslPrefix={`${translPrefix}.selectOptions`}
      />
      {handleContentToShow()}
      {modalToShowState === ModalToShowState.FiltersToShow && (
      <ModalWithFilters
        onClose={() => setModalToShowState(ModalToShowState.None)}
        title={t(`${translPrefix}.filterModal.title`)}
        checkboxesSelected={checkboxesSelected}
        onClickButtonLeft={() => setModalToShowState(ModalToShowState.None)}
        textButtonLeft={t('cancel')}
        onClickButtonRight={onClickApplyFilters}
        textButtonRight={t(`${translPrefix}.filterModal.buttonRight`)}
        checkboxesOptions={checkboxesOptions}
      />
      )}
    </>
  );

  return (
    <>
      {showCorrespondingModal()}

      <div className={styles.applications}>
        <div className={classnames('text__body__large__textNeutral40', 'mt-16')}>
          {t(`${translPrefix}.tabName`)}
        </div>
        {contentToShow === ContentToShow.EMPTY_STATE
          ? (noApplicationsState())
          : (applicationsAvailableState())}
      </div>
    </>
  );
};

export { ApplicationsTab };
