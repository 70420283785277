import React,
{
  ChangeEvent, useState,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { RadioButton } from 'common/radio-button';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { ReactComponent as Plus } from 'assets/icons/plus.svg';
import { Contractor } from 'models/contractor';
import { Table } from 'common/table';
import { classnames } from '@mapix/common/src/helpers/utils';
import { AddContractorModal } from 'common/add-contractor-modal';
import { Checkbox } from '@mapix/common/src/common/checkbox';
import { Modal } from '@mapix/common/src/common/modal';
import { MaintenanceController } from 'networking/controllers/maintenance-controller';
import { Paginator } from 'models/paginator';
import { TextArea } from '@mapix/common/src/common/textarea';
import { logger } from 'helpers/logger';

import { Pagination } from '@mapix/common/src/common/paginator';
import { mergePhoneNumberObject } from 'helpers/utils';
import styles from './assign-contractor-container.module.scss';

const headers = ['', 'category', 'name', 'phoneNumber', 'email'];
const contractorProperties = ['category', 'name', 'phoneNumber', 'email'];
const notifications = ['SMS', 'Email'];

type AssignContractorContainerProps = {
  category: string,
  propertyId: number,
  contractorId?: number,
  infoContractor: string,
  sms: boolean | undefined,
  email: boolean | undefined,
  onChange: (field: string, value: string) => void,
  onChangeContactMethod: (field: string, value: boolean) => void,
};

const AssignContractorContainer = ({
  category, propertyId, onChange, contractorId, infoContractor,
  sms, email, onChangeContactMethod,
}: AssignContractorContainerProps) => {
  const [modalAddContractor, setModalAddContractor] = useState(false);
  const [contractor, setContractor] = useState(new Contractor(null));
  const [newContractorSelect, setNewContractorSelect] = useState(false);
  const [paginator, setPaginator] = useState(new Paginator<Contractor>(null, []));
  const { t } = useTranslation();

  const changePage = (
    currentPage: number,
    pag: Paginator<Contractor>,
  ): Paginator<Contractor> => {
    const newPaginator = { ...pag };
    newPaginator.currentPage = currentPage;
    return newPaginator;
  };

  const clickPagination = (nextPage: number) => {
    if (nextPage !== paginator.currentPage) {
      setPaginator(changePage(nextPage, paginator));
    }
  };

  const onClickSelect = (option: string, objField: string) => {
    onChange(objField, option);
  };

  const notifySelected = (item: string) => {
    if ((item).toLowerCase() === 'sms') {
      onChangeContactMethod('sms', !sms);
    } else {
      onChangeContactMethod('email', !email);
    }
  };

  const emptyState = (
    <td className={classnames(styles.emptyState, 'text__body__medium__textNeutral40')}>
      {t('createTask.emptyState')}
    </td>
  );

  const getContractors = async () => {
    try {
      const response = await MaintenanceController
        .getContractorsV1(paginator?.currentPage || 1, 5, propertyId, category);
      setPaginator(response);
    } catch (err) {
      logger.error(err as Error);
    }
  };

  useEffect(() => {
    if (propertyId) {
      getContractors();
    }
  }, [paginator?.currentPage]);

  const defaultCheckedContractor = (item: Contractor) => {
    if (item.id === contractor.id) return true;

    if (contractorId !== null) {
      if (item.id === contractorId) {
        setContractor(item);
        return true;
      }
      return false;
    }
    return (newContractorSelect ? (item.name === contractor.name) : false);
  };

  const iconCells = (item: Contractor) => [
    <RadioButton
      onChangeFn={() => {
        onClickSelect(item && item.id ? item.id.toString() : '', 'contractorId');
        onChangeContactMethod('sms', false);
        onChangeContactMethod('email', true);
        setContractor(item);
      }}
      checked={defaultCheckedContractor(item)}
      name="selectContractor"
    />];

  const newContractorAdd = (newContractor: Contractor) => {
    setContractor(newContractor);
    onClickSelect(newContractor.id ? newContractor.id.toString() : '', 'contractorId');
    setNewContractorSelect(true);
    getContractors();
  };

  const checkedBox = (item: string) => {
    if (item.toLowerCase() === 'sms') {
      return sms;
    }
    return email;
  };

  return (
    <div>
      { modalAddContractor && (
        <Modal>
          <AddContractorModal
            closeFn={() => setModalAddContractor(!modalAddContractor)}
            category={category}
            propertyId={propertyId}
            onSuccess={newContractorAdd}
          />
        </Modal>
      ) }
      <div className={classnames(styles.firstMargin, 'text__heading6__textNeutral50')}>{`${t('createTask.subtitleStep3')}${category.toLowerCase()}${t('createTask.task')}`}</div>
      <>
        <Table
          headerNames={headers}
          data={mergePhoneNumberObject(paginator.results)}
          dataProperties={contractorProperties}
          dashboardName="contractors"
          uniqueId="id"
          iconCellLeft={iconCells}
          iconCellLeftClass={styles.iconCellLeftClass}
          emptyState={emptyState}
        />
        {paginator.lastPage > 1
          && (
            <div className={styles.paginationContainer}>
              <Pagination
                pageLimit={paginator.lastPage}
                onClickFn={(newPage: number) => clickPagination(newPage)}
                currentPage={paginator.currentPage}
              />
            </div>
          )}
      </>
      <div className={styles.contractorButton}>
        <div className="text__heading6__textNeutral50">
          {t('createTask.or')}
        </div>
        <Button
          className={styles.taskButton}
          buttonStyle={ButtonStyle.Secondary}
          onClick={() => setModalAddContractor(!modalAddContractor)}
        >
          <div className="row align-justify-center">
            <Plus className={styles.plusIcon} />
            {t('createTask.addContractor')}
          </div>
        </Button>
      </div>
      <div className={classnames(styles.margin, 'text__heading6__textNeutral50')}>{t('createTask.infoForContractor.title')}</div>
      <TextArea
        id="infoContractor"
        value={infoContractor}
        type="text"
        maxLength={140}
        placeholder={t('createTask.infoForContractor.description')}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onChange('infoContractor', e.target.value)}
        containerClass={classnames(styles.input, styles.description)}
        t={t}
      />
      {contractor.id !== undefined && (
      <>
        <div className={classnames(styles.margin, 'text__heading6__textNeutral50')}>{t('createTask.notification.title')}</div>
        <div className={styles.checkboxContainer}>
          {notifications.map((item) => (
            <Checkbox
              key={item}
              onChangeFn={() => notifySelected(item)}
              containerClass={styles.checkbox}
              labelContent={`${(item)} to: ${item === 'SMS' ? `${contractor?.countryCode}${contractor?.phoneNumber}` : contractor?.email}`}
              labelClass="text__body__medium__textNeutral40"
              name="notify"
              checked={checkedBox(item)}
            />
          ))}
        </div>
      </>
      )}

    </div>
  );
};

export { AssignContractorContainer };
