import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import 'index.scss';
import { i18n } from 'i18n';
import { App } from 'app';
import { saveLanguage } from 'i18n/utils';
import { logger } from 'helpers/logger';

// Make sure to save the new language preference
// each time the user changes it.
i18n.on('languageChanged', (language) => {
  logger.log(`Storing new language preference: ${language}`);
  saveLanguage(language);
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
