import { convertArrayToQueryParams } from '@mapix/common/src/helpers/utils';

/*
  Add all API paths used on the app here so that
  they are centrally documented.
*/
const managerPrefix = 'manager';
const tenantPrefix = 'tenant';
const v1 = 'v1';
const all = 'v1/all';
const nuvei = 'v1/manager/nuvei';

const API_ROUTES = {
  // ALL
  LOGIN: `${all}/sessions`,
  GET_TOKEN: `${all}/sessions/csrf_token`,
  USER: `${all}/users/me?cb=${Date.now()}`,
  REGISTER: `${all}/users`,
  UPLOAD: (paymentPlatform?: boolean) => `${all}/resources${paymentPlatform ? '?nuvei=true' : ''}`,
  PASSWORD: `${all}/password`,
  ACTIVATE_ACCOUNT: `${all}/users/activate_account`,
  VERIFY_ACCOUNT: `${all}/users/verify_account`,
  RESEND_CONFIRMATION_EMAIL: `${all}/users/resend_verification`,
  CHECK_PHONE_NUMBER: `${all}/users/check_phone_validation`,
  AVAILABLE_EMAIL: `${all}/sessions/available_email`,
  GET_PAYMENT_METHOD: `${all}/payment_methods`,
  DELETE_PAYMENT_METHOD: (id: number) => `${all}/payment_methods/${id}`,
  GET_TASK_BY_CONTRACTOR_TOKEN: (id: string) => `${all}/task_by_token?contractor_token=${id}`,

  RESEND_INVITATION: `${v1}/${managerPrefix}/tenants/send_invitation`,
  COUNTRIES: `${v1}/${managerPrefix}/countries`,
  CITIES: (pageNumber: number, pageSize: number, query?: string) => `${all}/cities?page_num=${[pageNumber]}&page_size=${pageSize}${query ? `&search=${encodeURIComponent(query)}` : ''}`,

  // PROPERTIES
  GET_PROPERTIES: (pageNumber: number, pageSize: number, type?: string, query?: string) => `${v1}/${managerPrefix}/properties?page_num=${pageNumber}&page_size=${pageSize}${type ? `&type=${type}` : ''}${query ? `&query=${query}` : ''}`,
  GET_PROPERTY: (propertyId: number) => `${v1}/${managerPrefix}/properties/${propertyId}`,
  MANAGE_DRAFT_PROPERTIES: (id: number) => `${v1}/${managerPrefix}/properties/drafts/${id}`,
  PROPERTIES: `${v1}/${managerPrefix}/properties`,
  EDIT_PROPERTY: (id: number) => `${v1}/${managerPrefix}/properties/${id}`,
  GET_UNITS_OF_A_PROPERTY: (pageNumber: number, pageSize: number, idProperty: number, query?: string) => `${v1}/${managerPrefix}/properties/${idProperty}/units?page_num=${[pageNumber]}&page_size=${pageSize}${query ? `&query=${query}` : ''}`,
  CREATE_UNIT: (propertyId: number) => `${v1}/${managerPrefix}/properties/${propertyId}/units`,
  GET_UNIT: (idProperty: number, idUnit: number) => `${v1}/${managerPrefix}/properties/${idProperty}/units/${idUnit}`,
  GET_PROPERTIES_FROM_TENANT: () => `${v1}/${tenantPrefix}/properties`,
  GET_DETAILED_PROPERTY: (id: number | string) => `${v1}/${managerPrefix}/properties/${id}`,
  GET_ACTIVE_PROPERTIES: (query?: string) => `${v1}/${managerPrefix}/properties?conditions[]=active&${query ? `&query=${query}` : ''}`,
  GET_TENAT_CONDO_PROPERTY: (propertyId: number) => `${v1}/${tenantPrefix}/properties/${propertyId}`,
  GET_TENANT_UNIT_PROPERTY: (unitId: number, propertyId: number) => `${v1}/${tenantPrefix}/properties/${propertyId}/units/${unitId}`,
  GET_UNITS_NUMBERS_OF_PROPERTY: (idProperty: number) => `${v1}/${managerPrefix}/properties/${idProperty}/units_numbers`,

  // TASKS - CLAIMS
  GET_TASKS: (pageNumber: number, pageSize: number, type?: string, query?: string) => `${v1}/${managerPrefix}/tasks?page_num=${pageNumber}&page_size=${pageSize}${type ? `&task_status[]=${type}` : ''}${query ? `&query=${query}` : ''}`,
  GET_TASKS_OF_PROPERTY: (idProperty: number, pageNumber: number, pageSize: number, query?: string) => `${v1}/${managerPrefix}/properties/${idProperty}/tasks?page_num=${[pageNumber]}&page_size=${pageSize}${query ? `&query=${query}` : ''}`,
  GET_TASKS_OF_UNIT: (idProperty: number, unitNumber: number, pageNumber: number, pageSize: number, query?: string) => `${v1}/${managerPrefix}/properties/${idProperty}/units/${unitNumber}/tasks?page_num=${[pageNumber]}&page_size=${pageSize}${query ? `&query=${query}` : ''}`,
  GET_TASK_DETAIL: (id: string | number) => `${v1}/${managerPrefix}/tasks/${id}`,
  ADD_TASK: `${v1}/${managerPrefix}/tasks`,
  CREATE_TASK: `${v1}/create_task`,
  CANCEL_TASK: (taskId: number) => `${v1}/${tenantPrefix}/tasks/${taskId}/cancel`,
  ADD_CLAIM: `${v1}/${tenantPrefix}/tasks`,
  EDIT_CLAIM: (id: string) => `${v1}/${tenantPrefix}/tasks/${id}`,
  GET_CLAIM_DETAIL: (id: string | number) => `${v1}/${tenantPrefix}/tasks/${id}`,
  GET_CONDO_CLAIMS_FROM_TENANT: (pageNumber: number, pageSize: number, id: number, type?: string, query?: string) => `${v1}/${tenantPrefix}/properties/${id}/tasks?page_num=${[pageNumber]}&page_size=${pageSize}${type ? `&task_status[]=${type}` : ''}${query ? `&query=${query}` : ''}`,
  GET_UNIT_CLAIMS_FROM_TENANT: (pageNumber: number, pageSize: number, unitId: number, type?: string, query?: string) => `${v1}/${tenantPrefix}/units/${unitId}/tasks?page_num=${[pageNumber]}&page_size=${pageSize}${type ? `&task_status[]=${type}` : ''}${query ? `&query=${query}` : ''}`,
  GET_SPECIALIZATIONS: `${all}/specializations`,
  // V2
  GET_NEW_TASKS: (pageNumber: number, pageSize: number, type?: string, query?: string) => `${v1}/${managerPrefix}/new_tasks?page_num=${pageNumber}&page_size=${pageSize}${type ? `&status=${type}` : ''}${query ? `&search=${encodeURIComponent(query)}` : ''}`,

  // NEW TASK
  NEW_TASKS: `${v1}/${managerPrefix}/new_tasks`,
  NEW_TASK: (id: string | number) => `${v1}/${managerPrefix}/new_tasks/${id}`,
  MOVE_TASK_TO_IN_PROGRESS: (taskId: number) => `${v1}/${managerPrefix}/new_tasks/${taskId}/move_to_in_progress`,
  ACCEPT_IN_PROGRESS: (taskId: number) => `${v1}/${managerPrefix}/new_tasks/${taskId}/accept_in_progress`,
  REJECT_IN_PROGRESS: (taskId: number) => `${v1}/${managerPrefix}/new_tasks/${taskId}/reject_in_progress`,
  MOVE_TASK_TO_SOLVED: (taskId: number) => `${v1}/${managerPrefix}/new_tasks/${taskId}/move_to_solved`,
  ACCEPT_SOLVED: (taskId: number) => `${v1}/${managerPrefix}/new_tasks/${taskId}/accept_solved`,
  REJECT_SOLVED: (taskId: number) => `${v1}/${managerPrefix}/new_tasks/${taskId}/reject_solved`,
  DISABLE_IN_PROGRESS_REJECTED_FLAG: (taskId: number) => `${v1}/${managerPrefix}/new_tasks/${taskId}/disable_in_progress_rejected_flag`,
  DISABLE_SOLVED_REJECTED_FLAG: (taskId: number) => `${v1}/${managerPrefix}/new_tasks/${taskId}/disable_solved_rejected_flag`,
  MODIFY_RATE_TASK: (taskId: number) => `${v1}/${managerPrefix}/new_tasks/${taskId}/modify_rate`,
  ACCEPT_RATE_TASK: (taskId: number) => `${v1}/${managerPrefix}/new_tasks/${taskId}/accept_rate`,
  APPLICATIONS: (pageNumber: number, pageSize: number, taskId: number, order?: string, query?: string, specializations?: number[]) => `${v1}/${managerPrefix}/new_tasks/${taskId}/applications?page_num=${[pageNumber]}&page_size=${pageSize}${order ? `&order=${order}` : ''}${query ? `&search=${encodeURIComponent(query)}` : ''}${convertArrayToQueryParams('categories', specializations)}`,
  CANCELLATION_REASONS: `${all}/new_tasks/cancellation_reasons`,
  CANCEL_NEW_TASK: (taskId: number) => `${v1}/${managerPrefix}/new_tasks/${taskId}/cancel`,
  CHANGE_CANCEL_PERCENTAGE: (taskId: number) => `${v1}/${managerPrefix}/new_tasks/${taskId}/change_percentage`, // TODO peding testing
  ACCEPT_CANCELLATION_PERCENTAGE: (taskId: number) => `${v1}/${managerPrefix}/new_tasks/${taskId}/accept_percentage`,
  DISABLE_NOTICE_CANCELLATION_FLAG: (taskId: number) => `${v1}/${managerPrefix}/new_tasks/${taskId}/disable_notice_cancellation_seen_flag`, // TODO peding testing

  // NEW TASK - CLAIMS

  CLAIMS: `${v1}/${tenantPrefix}/new_tasks`,
  INDEX_CLAIMS: (pageNumber: number, pageSize: number, propertyId: number, unitId?: number, status?: string, query?: string) => `${v1}/${tenantPrefix}/new_tasks?page_num=${[pageNumber]}&page_size=${pageSize}${status ? `&status=${status}` : ''}${propertyId ? `&property_id=${propertyId}` : ''}${unitId ? `&unit_id=${unitId}` : ''}${query ? `&search=${encodeURIComponent(query)}` : ''}`,
  CLAIM: (id: string | number) => `${v1}/${tenantPrefix}/new_tasks/${id}`,
  REJECT_CLAIM: (taskId: number) => `${v1}/${managerPrefix}/new_tasks/${taskId}/reject_claim`,
  ACCEPT_CLAIM: (taskId: number) => `${v1}/${managerPrefix}/new_tasks/${taskId}/accept_claim`,

  // PRIVATE REQUEST
  GET_PRIVATE_REQUEST: (privateRequestId: string | number) => `${v1}/${managerPrefix}/private_requests/${privateRequestId}`,
  REJECT_PRIVATE_REQUEST: (privateRequestId: number) => `${v1}/${managerPrefix}/private_requests/${privateRequestId}/reject`,
  MODIFY_RATE_PRIVATE_REQUEST: (privateRequestId: number) => `${v1}/${managerPrefix}/private_requests/${privateRequestId}/modify_rate`,
  ACCEPT_RATE_PRIVATE_REQUEST: (privateRequestId: number) => `${v1}/${managerPrefix}/private_requests/${privateRequestId}/accept_rate`,
  SEND_PRIVATE_REQUEST_MESSAGE: (privateRequestId: number) => `${v1}/${managerPrefix}/private_requests/${privateRequestId}/task_message`,

  // PUBLIC REQUEST
  GET_PUBLIC_REQUEST: (publicRequestId: string | number) => `${v1}/${managerPrefix}/public_requests/${publicRequestId}`,
  ASSIGN_PUBLIC_REQUEST: (publicRequestId: number) => `${v1}/${managerPrefix}/public_requests/${publicRequestId}/assign_application`,
  REJECT_PUBLIC_REQUEST: (publicRequestId: number) => `${v1}/${managerPrefix}/public_requests/${publicRequestId}/reject`,
  SEND_PUBLIC_REQUEST_MESSAGE: (publicRequestId: number) => `${v1}/${managerPrefix}/public_requests/${publicRequestId}/task_message`,
  MODIFY_RATE_PUBLIC_REQUEST: (publicRequestId: number) => `${v1}/${managerPrefix}/public_requests/${publicRequestId}/modify_rate`,
  ACCEPT_RATE_PUBLIC_REQUEST: (publicRequestId: number) => `${v1}/${managerPrefix}/public_requests/${publicRequestId}/accept_rate`,

  // LEASES
  RENEW_LEASE: (leaseId: number) => `${v1}/${managerPrefix}/leases/${leaseId}/renew`,
  EXTEND_LEASE: (leaseId: number) => `${v1}/${managerPrefix}/leases/${leaseId}/extend`,
  GET_LEASES: (pageNumber: number, pageSize: number, query?: string, type?: string) => `${v1}/${managerPrefix}/leases?page_num=${pageNumber}&page_size=${pageSize}${type ? `&type=${type}` : ''}${query ? `&query=${query}` : ''}`,
  GET_LEASE: (leaseId: number) => `${v1}/${managerPrefix}/leases/${leaseId}`,
  END_LEASE: (leaseId: number) => `${v1}/${managerPrefix}/leases/${leaseId}/end`,
  END_LEASE_ARCHIVE_PROPERTY: (leaseId: number) => `${v1}/${managerPrefix}/leases/${leaseId}/end_archive`,
  CREATE_LEASE_PROPERTY: (propertyId: number) => `${v1}/${managerPrefix}/properties/${propertyId}/leases`,
  CREATE_UNIT_LEASE: (propertyId: number, unitId: number) => `${v1}/${managerPrefix}/properties/${propertyId}/units/${unitId}/leases`,

  // BILLS
  BILL_WITH_TASK: (propertyId: number, taskId: number) => `${v1}/${managerPrefix}/properties/${propertyId}/tasks/${taskId}/bills`,
  GET_BILLS: (pageNumber: number, pageSize: number, status?: string, query?: string) => `${v1}/${managerPrefix}/bills?page_num=${[pageNumber]}&page_size=${pageSize}${status ? `&status[]=${status}` : ''}${query ? `&query=${query}` : ''}`,
  GET_PROPERTY_BILLS: (pageNumber: number, pageSize: number, propertyId: number, status?: string, query?: string) => `${v1}/${managerPrefix}/properties/${propertyId}/bills?page_num=${[pageNumber]}&page_size=${pageSize}${status ? `&status=${status}` : ''}${query ? `&query=${query}` : ''}`,
  GET_UNIT_BILLS: (pageNumber: number, pageSize: number, unitId: number, status?: string, query?: string) => `${v1}/${managerPrefix}/units/${unitId}/bills?page_num=${[pageNumber]}&page_size=${pageSize}${status ? `&status=${status}` : ''}${query ? `&query=${query}` : ''}`,
  PROPERTY_BILL: (propertyId: number) => `${v1}/${managerPrefix}/properties/${propertyId}/bills`,
  MANAGE_BILL: (billId: number) => `${v1}/${managerPrefix}/bills/${billId}`,

  // CONTRACTORS
  ADD_CONTRACTOR: (id: number) => `${v1}/${managerPrefix}/properties/${id}/contractors`,
  PROPERTY_CONTRACTORS: (pageNumber: number, pageSize: number, id: number, category?: string, query?: string) => `${v1}/${managerPrefix}/properties/${id}/contractors?page_num=${[pageNumber]}&page_size=${pageSize}${category ? `&categories[]=${category}` : ''}${query ? `&query=${query}` : ''}`,
  GET_EDIT_CONTRACTOR: (propertyId: number, contractorId: number) => `${v1}/${managerPrefix}/properties/${propertyId}/contractors/${contractorId}`,
  ASSIGN_CONTRACTOR: (id: number) => `${v1}/${managerPrefix}/new_tasks/${id}/assign_contractor`,
  GET_CONTRACTORS_BY_PROPERTY: (pageNumber: number, pageSize: number, propertyId: number, category?: string, search?: string) => `${v1}/${managerPrefix}/contractors?page_num=${pageNumber}&page_size=${pageSize}&property_id=${propertyId}${category ? `&specialization=${category}` : ''}${search ? `&search=${encodeURIComponent(search)}` : ''}`,

  // RENTALS
  GET_RENTALS: (pageNumber: number, pageSize: number, type?: string, query?: string) => `${v1}/${managerPrefix}/rentals?page_num=${[pageNumber]}&page_size=${pageSize}${`&type=${type}`}${query ? `&query=${query}` : ''}`,
  GET_RENTALS_FROM_BUILDING: (propertyId: number, pageNumber: number, pageSize: number, query?: string) => `${v1}/${managerPrefix}/properties/${propertyId}/rentals?page_num=${[pageNumber]}&page_size=${pageSize}${query ? `&query=${query}` : ''}`,
  RENTAL_RESOURCE: (id: number) => `${v1}/${managerPrefix}/rentals/${id}`,
  RENTAL_TENANT_RESOURCE: (id: number) => `${v1}/${tenantPrefix}/rentals/${id}`,
  CREATE_RENTAL: `${v1}/${managerPrefix}/rentals/`,
  GET_PROPERTY_RENTALS: (propertyId: number, pageNumber: number, pageSize: number, query?: string) => `${v1}/${managerPrefix}/properties/${propertyId}/rentals?page_num=${[pageNumber]}&page_size=${pageSize}${query ? `&query=${query}` : ''}`,
  GET_UNIT_RENTALS: (propertyId: number, unitId: number, pageNumber: number, pageSize: number, query?: string) => `${v1}/${managerPrefix}/properties/${propertyId}/units/${unitId}/rentals?page_num=${[pageNumber]}&page_size=${pageSize}${query ? `&query=${query}` : ''}`,
  GET_RENTALS_OF_A_LEASE: (leaseId: number) => `${v1}/${managerPrefix}/leases/${leaseId}/rentals`,
  GET_TENANT_RENTALS: (propertyId: number, pageNumber: number, pageSize: number) => `${v1}/${tenantPrefix}/properties/${propertyId}/rentals?page_num=${[pageNumber]}&page_size=${pageSize}`,
  GET_TENANT_RENTALS_FROM_UNIT: (propertyId: number, unitId: number, pageNumber: number, pageSize: number) => `${v1}/${tenantPrefix}/properties/${propertyId}/units/${unitId}/rentals?page_num=${[pageNumber]}&page_size=${pageSize}`,

  // PAYMENT INFORMATION
  PAYMENT_INFORMATION: `${v1}/${managerPrefix}/payment_information`,
  LEGAL_BUSINESS_INFO: `${v1}/${managerPrefix}/payment_information/legal_information`,
  SALES_PROFILE: `${v1}/${managerPrefix}/payment_information/sales_profile`,
  OWNERS_OR_OFFICERS: `${v1}/${managerPrefix}/payment_information/owners_or_officer`,
  DBA_INFORMATION: `${v1}/${managerPrefix}/payment_information/dba_information`,
  ELECTRONIC_AUTHORIZATION: `${v1}/${managerPrefix}/payment_information/electronic_authorization`,
  DOCUMENTS_INFORMATION: `${v1}/${managerPrefix}/payment_information/document_information`,
  GET_CANADA_PROVINCES: `${nuvei}/canada_provinces`,
  TIMEZONES: `${nuvei}/timezones`,
  BANK_INSTITUTIONS: `${nuvei}/bank_institutions`,
  GET_TYPE_OF_OWNERSHIP: `${nuvei}/types_of_ownership`,
  GET_BUSINESS_DESCRIPTIONS: `${nuvei}/business_descriptions`,
  UPLOAD_DOCUMENT: (name: string) => `${v1}/${managerPrefix}/payment_information/document_information/upload_document?name=${name}`,
  PAYMENT_INFORMATION_SUBMIT: `${v1}/${managerPrefix}/payment_information/submit`,

  // NUVEI
  OPEN_ORDER: `${all}/payments`,

  // NEW BILL
  NEW_BILL: (id: number) => `${v1}/${managerPrefix}/new_bills/${id}`,
  NEW_BILLS: (pageNumber: number, pageSize: number, status?: string, query?: string) => `${v1}/${managerPrefix}/new_bills?page_num=${[pageNumber]}&page_size=${pageSize}${status ? `&status=${status}` : ''}${query ? `&search=${encodeURIComponent(query)}` : ''}`,

};

export { API_ROUTES };
