import { Property } from 'models/property';
import { useTranslation } from 'react-i18next';
import { Table } from 'common/table';
import { FormEvent, useState } from 'react';
import { BillCreate } from 'models/bill-create';
import { SimpleUnit } from 'models/simple-unit';
import { BillArea } from 'common/enums';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { checkHTMLErrors, classnames, HTMLValidationError } from '@mapix/common/src/helpers/utils';
import { Button, ButtonStyle, ButtonType } from '@mapix/common/src/common/button';
import { CreateFooter } from 'common/creation';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { BillForm } from 'common/bill-form';
import styles from '../add-bill.module.scss';

type AddBillStep2Props = {
  property: Property,
  showLeaveModalFn: () => void,
  backFn: () => void,
  nextFn: (bills: BillCreate[]) => void;
};

const headers = ['buildingArea', 'category', 'amount', 'dueDate', 'paidOn'];
const dataProperties = ['affectedArea', 'category', 'billedAmount', 'dueDate', 'paymentDate'];

const translPrefix = 'addBill';
const AddBillStep2 = ({
  property, backFn, nextFn, showLeaveModalFn,
}: AddBillStep2Props) => {
  const [bills, setBills] = useState<BillCreate[]>([]);
  const [bill, setBill] = useState<BillCreate>(new BillCreate());
  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});

  const { t } = useTranslation();

  const emptyState = (
    <div className={classnames(styles.emptyContent, 'text__body__small__textNeutral30')}>
      {t(`${translPrefix}.emptyState`)}
    </div>
  );

  const onUnitSelected = (unit: SimpleUnit | null) => {
    setBill((prevState) => ({ ...prevState, unitId: unit?.id }));
  };

  const changeBill = (option: string, field: string) => setBill(
    (prevState) => ({ ...prevState, [field]: option }),
  );

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    if (!target.checkValidity()) {
      setFormErrors(checkHTMLErrors(target));
    } else {
      if (bill.affectedArea === BillArea.CommonArea) {
        delete bill.unitId;
      }
      if (bill.billPaid === 'no') {
        delete bill.paymentDate;
      }

      setBills((prevState) => ([...prevState, bill]));
      setBill(new BillCreate());
    }
  };

  return (
    <form noValidate onSubmit={onSubmit}>
      <div className={classnames(styles.addressTitle, 'text__heading5__textNeutral50')}>
        {property.fullAddress}
      </div>

      <Table
        headerNames={headers}
        data={bills}
        dataProperties={dataProperties}
        dashboardName="bills"
        uniqueId="id"
        emptyState={emptyState}
      />

      <BillForm
        propertyType={property.type}
        propertyId={property.id}
        formErrors={formErrors}
        bill={bill}
        changeBillFn={changeBill}
        onUnitSelectedFn={onUnitSelected}
      />

      <div className={styles.buttons}>
        <Button
          onClick={showLeaveModalFn}
          buttonType={ButtonType.Button}
          buttonStyle={ButtonStyle.Secondary}
          className={classnames(styles.cancelButton, styles.addButton)}
        >
          <div className={classnames('text__button__large__primary', styles.buttonAlign)}>
            <Close className={classnames(styles.icons, styles.closeIcon)} />
            {t(`${translPrefix}.cancelDiscardButton`)}
          </div>
        </Button>

        <Button
          buttonType={ButtonType.Submit}
          className={styles.addButton}
          buttonStyle={ButtonStyle.Primary}
        >
          <div className={classnames('text__button__large__textNeutral10', styles.buttonAlign)}>
            <Check className={styles.icons} />
            {t(`${translPrefix}.confirmAndSaveButton`)}
          </div>
        </Button>
      </div>

      <CreateFooter
        nextName={t(`${translPrefix}.form.save`)}
        showBack
        backFn={backFn}
        disableNextCondition={bills.length === 0}
        Icon={Check}
        iconStyle={styles.check}
        nextFn={() => nextFn(bills)}
      />

    </form>
  );
};

export { AddBillStep2 };
