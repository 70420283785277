import { LeaseSerializer } from './lease-serializer';
import { PropertyAddressSerializer } from './property-address-serializer';
import { PropertyOwnerSerializer } from './property-owner-serializer';

class MaintenancePropertySerializer {
  static deSerialize(data: RawTaskProperty): SerializedTaskProperty {
    return {
      id: data.id,
      type: data.type,
      condition: data.condition,
      buildingName: data.building_name,
      unitsCount: data.units_count,
      address: PropertyAddressSerializer.deSerialize(data.address),
      owner: data.owner && PropertyOwnerSerializer.deSerialize(data.owner),
      otherOwner: data.other_owner && PropertyOwnerSerializer.deSerialize(data.other_owner),
    };
  }

  static deSerializeTenant(data: RawPropertyTenant): SerializedPropertyTenant {
    return {
      id: data.id,
      type: data.type,
      buildingName: data.building_name,
      address: PropertyAddressSerializer.deSerialize(data.address),
      owner: PropertyOwnerSerializer.deSerialize(data.owner),
      otherOwner: data.other_owner
        ? PropertyOwnerSerializer.deSerialize(data.other_owner) : undefined,
      lease: data.lease ? LeaseSerializer.deSerialize(data.lease) : undefined,
      unitNumber: data.unit_number,
    };
  }
}

export { MaintenancePropertySerializer };
