import { NewTaskDetail, TaskDetailTabs } from 'common/new-task-detail';
import { MaintenanceController } from 'networking/controllers/maintenance-controller';
import { Spinner } from '@mapix/common/src/common/spinner';
import { ModalRejection } from '@mapix/common/src/common/modal-rejection';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ButtonStyle } from '@mapix/common/src/common/button';
import { PrivateRequest } from 'networking/types/private-request';
import { ErrorType } from '@mapix/common/src/common/error-page';
import { logger } from 'helpers/logger';
import { RouteName, goToPage } from 'routes';
import { StatusTab } from 'common/new-task-detail/tabs/status';
import { TaskViewTab } from 'common/new-task-detail/tabs/task-view';
import { RateTab } from '@mapix/common/src/common/new-task-detail-tabs/rate-tab';
import { parseTaskStatus } from 'helpers/utils';
import { ReactComponent as Tool } from 'assets/icons/tool.svg';
import { NewTask } from 'networking/types';
import { ModalAccentColor, ModalResult } from '@mapix/common/src/common/modal-result';
import { SimpleMessage } from '@mapix/common/src/common/simple-message';
import { EntityType, RawRequestStatus, TaskStatus } from '@mapix/common/src/common/enums';
import { TaskMessageRequest } from '@mapix/common/src/types/request';
import { useTranslation } from 'react-i18next';
import { privateRequestInitialState } from 'initial-states/private-request-initial-state';
import { AddUpdateModal } from 'common/add-update-modal';
import styles from './private-request-detail.module.scss';

type ParamType = {
  id: string,
};

const translPrefix = 'newTaskDetail';

enum ModalToShowState {
  None = 'none',
  ChangeContractor = 'changeContractor',
  MoveToInProgressSuccessfull = 'moveToInProgressSuccessfull',
  AddUpdate = 'addUpdate',
  MovementToInProgressRejection = 'movementToInProgressRejection',
}

const PrivateRequestDetail = () => {
  const [fetching, setFetching] = useState(true);
  const [privateRequest, setPrivateRequest] = useState<PrivateRequest>(privateRequestInitialState);
  const [currentTab, setCurrentTab] = useState<TaskDetailTabs>(TaskDetailTabs.Status);

  const [modalToShowState, setModalToShowState] = useState<ModalToShowState>(ModalToShowState.None);

  const { id: privateRequestId } = useParams<ParamType>();

  const { t } = useTranslation();

  const hasContractorRejectedMovementToInProgress = (task: NewTask) => (
    task.firstTimeInProgressRejectedManagerSeen
  );

  const getPrivateRequest = async () => {
    try {
      const privateResponse = await MaintenanceController.getPrivateRequest(privateRequestId);
      setPrivateRequest(privateResponse);
      setFetching(false);

      if (hasContractorRejectedMovementToInProgress(privateResponse.newTask)) {
        setModalToShowState(ModalToShowState.MovementToInProgressRejection);
      }
    } catch (err: any) {
      logger.log(err);
      goToPage(RouteName.ErrorPage, { code: ErrorType.ServerError });
    }
  };

  const handleAcceptRateByManager = async () => {
    setFetching(true);
    try {
      const taskUpdated = await MaintenanceController.acceptRatePrivateRequest(
        Number(privateRequestId),
      );
      setPrivateRequest(taskUpdated);
      setFetching(false);
    } catch (err: any) {
      logger.log(err);
      goToPage(RouteName.ErrorPage, { code: ErrorType.ServerError });
    }
  };

  const taskStatus = parseTaskStatus(privateRequest.newTask.status);

  const getTabContent = () => {
    switch (currentTab) {
      case TaskDetailTabs.Status:
        return (
          <StatusTab
            className={styles.tabsContainer}
            request={privateRequest}
            taskStatus={taskStatus}
            changeContractorFn={() => setModalToShowState(ModalToShowState.ChangeContractor)}
            notifyAddNewUpdate={() => setModalToShowState(ModalToShowState.AddUpdate)}
          />
        );
      case TaskDetailTabs.Rate:
        return (
          <RateTab
            className={styles.tabsContainer}
            entityType={EntityType.Request}
            contractor={privateRequest.contractor}
            manager={privateRequest.manager}
            contractorMessage={privateRequest.contractorMessage}
            managerMessage={privateRequest.managerMessage}
            events={privateRequest.privateRequestEvents}
            requestOrTaskId={privateRequest.id}
            rawStatus={privateRequest.status}
            typeOfCost={{ ...privateRequest }}
            modifyRate={MaintenanceController.modifyRatePrivateRequest}
            notifyAcceptRate={handleAcceptRateByManager}
            refreshData={getPrivateRequest}
            t={t}
          />
        );
      case TaskDetailTabs.TaskView:
        return (
          <TaskViewTab
            className={styles.tabsContainer}
            task={privateRequest.newTask}
            translPrefix={`${translPrefix}.tabs.taskView`}
            t={t}
          />
        );
      default:
        return null;
    }
  };

  const showAlert = (option: string) => option === TaskDetailTabs.Rate
  && (privateRequest.status === RawRequestStatus.WAITING_FOR_MANAGER_APPROVAL
    || privateRequest.status === RawRequestStatus.WAITING_FOR_CONTRACTOR_APPROVAL);

  useEffect(() => {
    getPrivateRequest();
  }, []);

  const isRateAccepted = () => (
    privateRequest.status === RawRequestStatus.ACCEPTED
  );

  const disableRightButton = [RawRequestStatus.WAITING_FOR_CONTRACTOR_APPROVAL,
    RawRequestStatus.WAITING_FOR_MANAGER_APPROVAL].includes(privateRequest.status);

  const withTooltip = taskStatus === TaskStatus.NEGOTIATING;

  if (fetching) {
    return <Spinner />;
  }

  const getTextButtonLeft = () => {
    let translEntry = '';
    switch (taskStatus) {
      case TaskStatus.NEGOTIATING:
        translEntry = 'rejectTask';
        break;
      case TaskStatus.UNASSIGNED:
        translEntry = 'changeAssignedContractor';
        break;
      default:
        break;
    }
    return translEntry ? t(`${translPrefix}.footer.${translEntry}`) : '';
  };

  const getTextButtonRight = () => {
    let translEntry = '';
    switch (taskStatus) {
      case TaskStatus.NEGOTIATING:
        translEntry = 'markAsInProgress';
        break;
      default:
        break;
    }
    return translEntry ? t(`${translPrefix}.footer.${translEntry}`) : '';
  };

  const toolTipText = () => {
    let translEntry = '';
    switch (privateRequest.status) {
      case RawRequestStatus.WAITING_FOR_CONTRACTOR_APPROVAL:
        translEntry = 'waitingForContractorApproval';
        break;
      case RawRequestStatus.WAITING_FOR_MANAGER_APPROVAL:
        translEntry = 'waitingForManagerApproval';
        break;
      default:
        break;
    }
    return translEntry ? t(`${translPrefix}.footer.${translEntry}`) : '';
  };

  const getLeftButtonFn = () => {
    switch (taskStatus) {
      case TaskStatus.UNASSIGNED:
      case TaskStatus.NEGOTIATING:
        return () => setModalToShowState(ModalToShowState.ChangeContractor);
      default:
        return () => {};
    }
  };

  const modalRejectionFn = async (reason: string) => {
    const { id: taskId } = privateRequest.newTask;

    try {
      await MaintenanceController.rejectPrivateRequest(
        Number(privateRequestId),
        { reasonForRejection: reason },
      );

      goToPage(RouteName.AssignContractor, { taskId: Number(taskId) });
    } catch (err: any) {
      logger.log(err);
      goToPage(RouteName.ErrorPage, {}, { code: ErrorType.ServerError });
    }
  };

  const handleMarAskInProgress = async () => {
    const { id: taskId } = privateRequest.newTask;

    try {
      await MaintenanceController.moveTaskToInProgress(Number(taskId));
      setModalToShowState(ModalToShowState.MoveToInProgressSuccessfull);
    } catch (err: any) {
      logger.log(err);
      goToPage(RouteName.ErrorPage, {}, { code: ErrorType.ServerError });
    }
  };

  const redirectToTaskDetail = () => {
    const { id: taskId } = privateRequest.newTask;
    setModalToShowState(ModalToShowState.None);

    goToPage(RouteName.TaskDetail, { id: taskId });
  };

  const handleConfirmMessage = () => {
    setModalToShowState(ModalToShowState.None);
    getPrivateRequest();
  };

  const handleAcceptInProgressRejection = async () => {
    setModalToShowState(ModalToShowState.None);

    const { id: taskId } = privateRequest.newTask;

    try {
      await MaintenanceController.disableInProgressRejectedFlag(taskId);
    } catch (err: any) {
      logger.log(err);
      goToPage(RouteName.ErrorPage, { code: ErrorType.ServerError });
    }
  };

  const handleSendMessage = (requestId: number, messageRequest: TaskMessageRequest) => (
    MaintenanceController.sendPrivateRequestMessage(requestId, messageRequest)
  );

  const inProgressRejectionContentModal = () => {
    const { name, lastName, profilePhoto } = privateRequest.contractor;

    return (
      <>
        <div className={styles.subtitle}>
          {t(`${translPrefix}.movementToInProgresRejectionModal.subtitle`)}
        </div>
        <div>
          <SimpleMessage
            text={privateRequest.newTask.messageForInProgressMovementRejection}
            senderName={name}
            senderLastName={lastName}
            senderUrl={profilePhoto?.url}
            containerClass={styles.contractorMessageBox}
          />
        </div>
      </>
    );
  };

  const showCorrespondingModal = () => {
    if (modalToShowState === ModalToShowState.ChangeContractor) {
      return (
        <ModalRejection
          close={() => setModalToShowState(ModalToShowState.None)}
          handleButtonLeft={() => setModalToShowState(ModalToShowState.None)}
          onSubmit={modalRejectionFn}
          translPrefix={`${translPrefix}.changeContractorModal`}
        />
      );
    }

    if (modalToShowState === ModalToShowState.MoveToInProgressSuccessfull) {
      return (
        <ModalResult
          title={t(`${translPrefix}.moveToInProgressByManagerSuccessfullModal.title`)}
          content={t(`${translPrefix}.moveToInProgressByManagerSuccessfullModal.subtitle`)}
          Icon={Tool}
          withBackground
          modalAccentColor={ModalAccentColor.GREEN}
          buttonStyle={ButtonStyle.Primary}
          buttonTextRight={t(`${translPrefix}.moveToInProgressByManagerSuccessfullModal.modalButton`)}
          handleButtonRight={redirectToTaskDetail}
          handleButtonClose={redirectToTaskDetail}
        />
      );
    }

    if (modalToShowState === ModalToShowState.AddUpdate) {
      return (
        <AddUpdateModal
          requestId={Number(privateRequestId)}
          translPrefix={`${translPrefix}.addNewUpdateModal`}
          closeFn={() => setModalToShowState(ModalToShowState.None)}
          notifySendMessage={(requestId: number, messageRequest: TaskMessageRequest) => (
            handleSendMessage(requestId, messageRequest)
          )}
          notifyRefetchData={() => handleConfirmMessage()}
        />
      );
    }

    if (modalToShowState === ModalToShowState.MovementToInProgressRejection) {
      return (
        <ModalResult
          title={t(`${translPrefix}.movementToInProgresRejectionModal.title`)}
          content={inProgressRejectionContentModal()}
          cross={false}
          Icon={Tool}
          withBackground
          modalAccentColor={ModalAccentColor.YELLOW}
          buttonStyle={ButtonStyle.Primary}
          buttonTextRight={t(`${translPrefix}.movementToInProgresRejectionModal.modalButton`)}
          modalStyle={styles.inProgressRejectionModalContainer}
          handleButtonRight={handleAcceptInProgressRejection}
        />
      );
    }

    return null;
  };

  return (
    <>
      {showCorrespondingModal()}

      <NewTaskDetail
        currentTab={currentTab}
        setCurrentTab={setCurrentTab as (tab: string) => void}
        newTask={privateRequest.newTask}
        getTabContent={getTabContent}
        showAlert={showAlert}
        disableLeftButton={
          isRateAccepted()
          || privateRequest.status === RawRequestStatus.WAITING_FOR_CONTRACTOR_APPROVAL
        }
        disableRightButton={disableRightButton}
        withTooltip={withTooltip}
        textButtonLeft={getTextButtonLeft()}
        textButtonRight={getTextButtonRight()}
        translPrefix={translPrefix}
        toolTipText={toolTipText()}
        leftButtonFn={getLeftButtonFn()}
        rightButtonFn={handleMarAskInProgress}
      />
    </>
  );
};

export { PrivateRequestDetail };
