import { LeaseSerializer } from 'networking/serializers/lease-serializer';

class UnitOfAPropertySerializer {
  static deSerialize(data: RawUnitOfAProperty): SerializedUnitOfAProperty {
    return {
      id: data.id,
      unitNumber: data.unit_number,
      status: data.status,
      lease: data.lease ? LeaseSerializer.deSerialize(data.lease) : undefined,
      condition: data.condition,
    };
  }

  static serialize(data: SerializedUnitOfAProperty): RawUnitOfAProperty {
    return {
      id: data.id,
      unit_number: data.unitNumber,
      status: data.status,
      lease: data.lease ? LeaseSerializer.serialize(data.lease) : undefined,
      condition: data.condition,
    };
  }
}

export { UnitOfAPropertySerializer };
