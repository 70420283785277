import { Contractor } from './contractor';

class TaskEvent {
  eventType: string;

  createdAt: string;

  contractor: Contractor;

  id?: string;

  constructor(params: SerializedTaskEvents | null) {
    this.eventType = params?.eventType || '';
    this.createdAt = params?.createdAt || '';
    this.contractor = params?.contractor || new Contractor(null);
    this.id = `${this.eventType}-${this.createdAt}-${this.contractor.id}`;
  }
}

export { TaskEvent };
