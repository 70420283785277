/* eslint-disable react/jsx-props-no-spreading */
import { ChangeEvent, HTMLProps, ReactNode } from 'react';
import { classnames, HTMLValidationError } from '@mapix/common/src/helpers/utils';
import styles from './radio-button.module.scss';

type RadioButtonProperties = {
  labelContent?: string | ReactNode,
  labelClass?: string,
  containerClass?: string,
  onChangeFn: (e: ChangeEvent<HTMLInputElement>) => void,
  formError?: HTMLValidationError,
  defaultChecked?: boolean,
  readonly?: boolean,
};

const RadioButton = ({
  labelContent, onChangeFn, labelClass = '', containerClass = '', formError, defaultChecked,
  readOnly = false, ...props
}: RadioButtonProperties & HTMLProps<HTMLInputElement>) => {
  const hasFormError = !!(props.id && formError && formError[props.id]);
  return (
    <>
      <label className={classnames(styles.label, containerClass)} htmlFor={props.id}>
        <input
          onChange={onChangeFn}
          defaultChecked={defaultChecked || readOnly}
          {...props}
          type="radio"
        />
        <div className={labelClass}>{labelContent}</div>
      </label>
      {props.id && hasFormError && (
      <div
        className={hasFormError
          ? 'text__body__tiny__danger50' : 'text__body__tiny__textNeutral40'}
      >
        {props.id && hasFormError && formError[props.id]}
      </div>
      )}
    </>
  );
};

export { RadioButton };
