import { useTranslation } from 'react-i18next';
import { checkNumberInput } from 'helpers/utils';
import {
  classnames, HTMLValidationError, checkHTMLErrors, isEmptyObject, addError,
} from '@mapix/common/src/helpers/utils';
import { ButtonClose } from '@mapix/common/src/common/button-close';
import React, {
  ChangeEvent, FormEvent, useEffect, useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { LeaseForm } from 'pages/create-property/create-building/lease-form';
import { Lease } from 'models/lease';
import { logger } from 'helpers/logger';
import { PropertyController } from 'networking/controllers/property-controller';
import { Property } from 'models/property';
import { ReactComponent as FeatherIcon } from 'assets/icons/feather-icons.svg';
import { Services } from 'models/services';
import { Button, ButtonStyle, ButtonType } from '@mapix/common/src/common/button';
import { LeaseController } from 'networking/controllers/lease-controller';
import { goToPage, RouteName } from 'routes';
import { PropertyStatus, PropertyType } from 'common/enums';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { Spinner } from '@mapix/common/src/common/spinner';
import { ModalResult } from 'common/modal-result';
import { ReactComponent as File } from 'assets/icons/file.svg';
import styles from './set-as-rented.module.scss';

type State = {
  code: string,
  error: boolean,
  modal: boolean,
  closeConfirmation: boolean,
};

const initState: State = {
  code: 'FETCHING',
  error: false,
  modal: false,
  closeConfirmation: false,
};

const translPrefix = 'setAsRented';
const SetAsRented = () => {
  const [state, setState] = useState<State>(initState);
  const [lease, setLease] = useState<Lease>(new Lease(null));
  const [property, setProperty] = useState<Property | null>(null);
  const [otherText, setOtherText] = useState<string>('');
  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});
  const [phoneErrors, setPhoneErrors] = useState<HTMLValidationError>({});

  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const getCondo = async () => {
    try {
      const response = await PropertyController.getDetailedProperty(id);

      if (response.type === PropertyType.Building) {
        goToPage(RouteName.ErrorPage);
      }
      setProperty(response);
      setState((prevState) => ({ ...prevState, code: 'READY' }));
    } catch (err: any) {
      if (err.status === 404) {
        goToPage(RouteName.ErrorPage);
      }
      logger.error(err);
      setState((prevState) => ({ ...prevState, code: 'ERROR' }));
    }
  };

  useEffect(() => {
    getCondo();
  }, []);

  const onClickService = (key: keyof Services) => {
    const modifiedService = { ...lease.services };
    /* TS seems to have problems with boolean types and using keyof
    * more here https://github.com/microsoft/TypeScript/issues/31663 */
    (modifiedService as any)[key] = !modifiedService[key];

    setLease({ ...lease, services: modifiedService });
  };

  const onChangeTenant = (e: ChangeEvent<HTMLInputElement>) => {
    const newTenant = { ...lease.tenant, [e.target.id]: e.target.value };
    setLease({ ...lease, tenant: newTenant });
  };

  const updateFiles = (field: string, files: FileType[]) => {
    if (field === 'pdf') {
      setLease((prevState) => ({ ...prevState, leaseCopy: files[0] }));
    } else {
      setLease((prevState) => ({ ...prevState, [field]: files }));
    }
  };

  const onChangeYearlyOrMonthly = (e: ChangeEvent<HTMLInputElement>) => {
    if (checkNumberInput(e)) {
      const { target } = e;
      const newLease = { ...lease };
      if (Number(target.value) === 0) {
        newLease.yearlyRental = 0;
        newLease.monthlyRental = 0;
        setLease(newLease);
      } else {
        setLease((prevState) => ({ ...prevState, [target.name]: Number(target.value) }));
      }
    }
  };

  const onChangeLeaseFn = (e: ChangeEvent<HTMLInputElement>) => {
    if (checkNumberInput(e)) {
      setLease(
        { ...lease, [e.target.name]: e.target.value },
      );
    }
  };

  const onChangeDateFn = (date: string, option: string) => {
    setLease(
      {
        ...lease,
        [option]: date,
      },
    );
  };

  const onChangePhoneNumber = (phoneNumber: string, countryCode: string) => {
    setLease((prevState) => (
      {
        ...prevState,
        tenant: {
          ...prevState.tenant, phoneNumber, countryCode,
        },
      }
    ));
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    if (!target.checkValidity() || !lease.tenant.phoneNumber) {
      setFormErrors(checkHTMLErrors(target));
      if (!lease.tenant.phoneNumber) {
        addError(t('error.emptyField'), 'phoneNumber', setPhoneErrors);
      }
      return;
    }

    if (!isEmptyObject(phoneErrors)) {
      return;
    }

    if (otherText) {
      lease.services.other = otherText;
    }
    if (lease.yearlyRental) {
      lease.monthlyRental = Math.floor(lease.yearlyRental / 12);
    }

    if (property) {
      try {
        await LeaseController.createLease(property.id, lease);
        setState((prevState) => ({ ...prevState, modal: true }));
      } catch (err: any) {
        logger.error(err);
      }
    }
  };

  if (state.code === 'FETCHING') {
    return (<Spinner />);
  }

  return (
    <form className={styles.container} onSubmit={onSubmit} noValidate>

      {state.modal && (
      <ModalResult
        title={t(`${translPrefix}.closeConfirmation.title`)}
        subtitle={t(`${translPrefix}.closeConfirmation.subtitle`)}
        Icon={File}
        withCheckIcon
        buttonTextRight={t(`${translPrefix}.closeConfirmation.editing`)}
        handleButtonRight={() => history.goBack()}
        handleButtonClose={() => history.goBack()}
      />
      )}

      {state.closeConfirmation
      && (
        <ModalResult
          title={t(`${translPrefix}.closeConfirmation.title`)}
          subtitle={t(`${translPrefix}.closeConfirmation.subtitle`)}
          Icon={FeatherIcon}
          buttonTextRight={t(`${translPrefix}.closeConfirmation.discard`)}
          buttonTextLeft={t(`${translPrefix}.closeConfirmation.editing`)}
          handleButtonLeft={() => setState((prevState) => (
            { ...prevState, closeConfirmation: false }))}
          handleButtonRight={() => history.goBack()}
          handleButtonClose={() => setState((prevState) => (
            { ...prevState, closeConfirmation: false }))}
        />
      )}

      {state.error
      && (
      <ErrorMessage
        message={t('error.errorMessage')}
        handleClose={() => setState((prevState) => ({ ...prevState, error: false }))}
      />
      )}

      <div className={styles.header}>
        <div className="text__heading4__textNeutral50">
          {t(`${translPrefix}.title`)}
        </div>
        <ButtonClose
          closeFn={() => setState((prevState) => ({ ...prevState, closeConfirmation: true }))}
          closeText={t(`${translPrefix}.close`)}
        />
      </div>

      <div className={classnames(styles.addressSubtitle, 'text__heading5__textNeutral50')}>
        {`${t(`${translPrefix}.subtitle`)} - ${property?.fullAddress}`}
      </div>

      <div className="text__body__medium__textNeutral50 bold">
        {t(`${translPrefix}.leaseInfo`)}
      </div>

      <LeaseForm
        isCondo
        lease={lease}
        onChangeLeaseFn={onChangeLeaseFn}
        onClickServiceFn={(service: string) => onClickService(service as keyof Services)}
        onChangeFilesFn={(field: string, files: FileType[]) => updateFiles(field, files)}
        onClickSelectFn={(field, value) => setLease({ ...lease, [field]: value })}
        onChangeOtherText={(e) => setOtherText(e.target.value)}
        onChangeYearlyMonthly={onChangeYearlyOrMonthly}
        onChangeDateFn={onChangeDateFn}
        onChangePhoneNumber={onChangePhoneNumber}
        formErrors={{ ...formErrors, ...phoneErrors }}
        otherText={otherText}
        onChangeTenantFn={onChangeTenant}
        setPhoneErrors={setPhoneErrors}
        propertyAvailable={(property && property.status === PropertyStatus.Available) || undefined}
      />

      <div className={styles.footer}>
        <Button
          buttonType={ButtonType.Submit}
          buttonStyle={ButtonStyle.Primary}
          className={classnames(styles.saveButton, 'text__button__medium__textNeutral50')}
        >
          <div className="text__button__large__textNeutral10">{t(`${translPrefix}.save`)}</div>
        </Button>
      </div>

    </form>
  );
};

export { SetAsRented };
