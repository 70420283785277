import { useTranslation } from 'react-i18next';
import styles from './read-only-forms.module.scss';

type CondoInfoProps = {
  fullAddress: string,
};

const translPrefix = 'propertyDetail.infoTab';
const CondoInfo = ({ fullAddress }: CondoInfoProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.itemBlock}>
        <div className="text__body__medium__textNeutral40">
          {t(`${translPrefix}.address`)}
        </div>
        <div className="text__body__medium__textNeutral50">
          {fullAddress}
        </div>
      </div>
    </div>
  );
};

export { CondoInfo };
