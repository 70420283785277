import { SetStateAction } from 'react';
import { MakePaymentModalState } from '../enums';
import { logger } from '../../helpers/logger';
import {
  OpenOrderResponse, CardNonSensitiveData, PaymentMethod,
} from '../../types';

export type NuveiResponse = {
  result: MakePaymentResult,
  errCode: number,
  errorDescription: string,
  cancelled: string,
  transactionId: string,
  ccCardNumber: string,
  bin: string,
  last4Digits: string,
  ccExpMonth: string,
  ccExpYear: string,
  userPaymentOptionId: string,
  threeDReasonId: string,
  threeDReason: string,
  challengeCancelReasonId: string,
  isLiabilityOnIssuer: string,
  isExemptionRequestInAuthentication: string,
  challengePreferenceReason: string,
  status?: string
};

export enum MakePaymentResult {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  ERROR = 'ERROR',
}

type CreatePaymentProps = {
  safeCharge: any,
  openOrderData: OpenOrderResponse,
  email: string,
  setShowModal: (state: SetStateAction<MakePaymentModalState>) => void,
  storeCreatePayment: (response: NuveiResponse) => void,
  cardNonSensitiveData?: CardNonSensitiveData,
  scard?: any
  selectedCard?: PaymentMethod,
};

const createPayment = ({
  safeCharge,
  openOrderData,
  email,
  setShowModal,
  cardNonSensitiveData,
  storeCreatePayment,
  scard,
  selectedCard,
}: CreatePaymentProps) => {
  const body = selectedCard ? {
    userTokenId: openOrderData.userTokenId,
    paymentOption: {
      userPaymentOptionId: selectedCard.userPaymentOptionId,
      expirationMonth: selectedCard.expirationMonth,
      expirationYear: selectedCard.expirationYear,
    },

  } : {
    paymentOption: scard,
    cardHolderName: cardNonSensitiveData!.holderName,
    billingAddress: {
      email,
      country: cardNonSensitiveData!.countryAlpha2Code,
    },
  };

  try {
    safeCharge.createPayment({
      sessionToken: openOrderData.sessionToken,
      ...body,
    }, (resp: any) => {
      storeCreatePayment(resp);
    });
  } catch (e) {
    logger.error(e as Error);
    setShowModal(MakePaymentModalState.ShowNuveiError);
  }
};

export { createPayment };
