class PaginatorSerializer {
  static deSerialize(data: RawPaginator): SerializedPaginator {
    return {
      currentPage: data.current_page,
      lastPage: data.last_page,
      pageSize: data.page_size,
      total: data.total_count,
    };
  }
}

export { PaginatorSerializer };
