class BillCreateSerializer {
  static serializeArray(data: SerializedBillCreateArray): RawBillCreateArray {
    return {
      bills: data.bills.map((bill) => this.serialize(bill)),
    };
  }

  static serialize(data: SerializedBillCreate): RawBillCreate {
    return {
      additional_comments: data.additionalComments,
      billed_amount: data.billedAmount,
      category: data.category,
      currency: data.currency,
      due_date: data.dueDate,
      payment_date: data.paymentDate,
      unit_id: data.unitId || null,
    };
  }

  static serializeWithTask(data: SerializedBillWithTask): RawBillWithTask {
    return {
      billed_amount: data.billedAmount,
      currency: data.currency,
      due_date: data.dueDate,
      payment_date: data.paymentDate,
      task_id: data.taskId,
      unit_id: data.unitId,
    };
  }
}
export { BillCreateSerializer };
