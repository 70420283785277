import { checkHTMLErrors, classnames, HTMLValidationError } from '@mapix/common/src/helpers/utils';
import React,
{
  ChangeEvent, Dispatch, useEffect, useState, FormEvent, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import { logger } from 'helpers/logger';
import { useClickOutside } from '@mapix/common/src/hooks/click-outside';
import { CreateFooter } from 'common/creation';
import { ButtonType } from '@mapix/common/src/common/button';
import { Property } from 'models/property';

import { PropertyController } from 'networking/controllers/property-controller';

import { Action } from './crete-task-reducer';

import styles from './create-task.module.scss';

type CreateTaskStep1Props = {
  dispatch: Dispatch<Action>,
  propertyAddress: string,
  show: boolean,
  query: string,
  data: Property[],
};

const CreateTaskStep1 = ({
  dispatch, propertyAddress, show, query, data,
}: CreateTaskStep1Props) => {
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const [error, setError] = useState(false);
  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});

  const getActiveProperties = async () => {
    try {
      const response = await PropertyController
        .getActiveProperties(query);
      dispatch({ type: 'PROPERTIES_FETCHED', data: response });
    } catch (err) {
      logger.error(err as Error);
    }
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const target = event.target as HTMLFormElement;
    if (!target.checkValidity()) {
      setFormErrors(checkHTMLErrors(target));
    } else {
      dispatch({ type: 'GO_NEXT' });
    }
  };

  useEffect(() => {
    getActiveProperties();
    if (propertyAddress.length === 0) {
      setError(false);
    }
  }, [query, propertyAddress]);

  const showModal = () => {
    dispatch({ type: 'MODAL_VISIBILITY', show: !show });
  };

  useClickOutside(containerRef, () => showModal());

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setError(false);
    if (e.target.value.length > 1 || e.target.value === '') {
      if (data.length === 0) {
        setError(true);
        showModal();
      }
      dispatch({ type: 'SEARCH_QUERY', query: e.target.value });
    }
    dispatch({
      type: 'PROPERTY_CHANGED', propertyAddress: e.target.value, buildingType: true, propertyId: 0,
    });
  };

  const clickButton = (address: string, type: boolean, id: number) => {
    showModal();
    dispatch({
      type: 'PROPERTY_CHANGED', propertyAddress: address, buildingType: type, propertyId: id,
    });
  };

  return (
    <form ref={containerRef} onSubmit={onSubmit} noValidate>
      <p className={classnames(styles.firstMargin, 'text__heading6__textNeutral50')}>
        {t('createTask.subtitleStep1')}
      </p>
      <div className={styles.dropdown}>
        <Input
          id="property"
          containerClass={classnames(styles.input, styles.extralarge)}
          inputStyle={InputStyle.REGULAR}
          withSearchIcon
          searchClass={styles.searchColor}
          value={propertyAddress}
          placeholder={propertyAddress || t('createTask.placeholder')}
          onChange={onChangeInput}
          error={error}
          formError={formErrors}
          onClick={showModal}
          required
          t={t}
        >
          { show && !error && (
          <div id="myDropdown" className={styles.dropdownContent}>

            { data.map((item) => {
              const address = item.address.fullAddress;
              return (
                <button
                  type="button"
                  className={classnames(styles.listDropDown, 'text__body__medium__textNeutral40')}
                  onClick={() => clickButton(address || '', (item.type === 'Building'), item.id)}
                >
                  {address}
                </button>
              );
            })}
          </div>
          )}
        </Input>
      </div>
      <CreateFooter
        nextButtonType={ButtonType.Submit}
        nextName={t('createGeneric.next')}
      />
    </form>
  );
};

export { CreateTaskStep1 };
