import { classnames } from '@mapix/common/src/helpers/utils';
import { BuildingInfo, ContactInfo } from 'common/detail-read-only-forms';
import { Property } from 'models/property';
import { Condition } from 'common/enums';
import { Button } from '@mapix/common/src/common/button';
import { goToPage, RouteName } from 'routes';
import { ReactComponent as EditPencil } from 'assets/icons/edit-black.svg';
import { ReactComponent as Unarchive } from 'assets/icons/unarchive.svg';
import { useTranslation } from 'react-i18next';
import styles from '../property-detail.module.scss';

type BuildingInfoProps = {
  property: Property
};

const translPrefix = 'propertyDetail';
const BuildingInfoTab = ({ property }: BuildingInfoProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.buttonRow}>
        {property.condition === Condition.Active && (
          <Button
            onClick={() => goToPage(RouteName.EditProperty, { id: property.id })}
            className={styles.button}
          >
            {t(`${translPrefix}.buttons.edit`)}
            <EditPencil className={styles.icon} />
          </Button>
        )}

        {property.condition === Condition.Archived && (
          <Button className={styles.button}>
            <Unarchive className={styles.icon} />
            {t(`${translPrefix}.buttons.unarchive`)}
          </Button>
        )}
      </div>

      <div className={classnames(styles.contentTitle, 'text__body__large__textNeutral50')}>
        {t(`${translPrefix}.infoTab.buildingTitle`)}
      </div>

      <BuildingInfo
        buildingName={property.buildingName}
        fullAddress={property.fullAddress}
        totalUnits={property.unitsCount}
        totalRentedUnits={property.rentedUnitsCount}
      />

      <div className={styles.line} />

      <div className={classnames(styles.contentTitle, 'text__body__large__textNeutral50')}>
        {t(`${translPrefix}.infoTab.contactTitle`)}
      </div>

      <ContactInfo owner={property.owner} otherOwner={property.otherOwner} isUnit={false} />
    </>
  );
};

export { BuildingInfoTab };
