import {
  useState, ChangeEvent, FormEvent,
} from 'react';
import { useMediaQuery } from '../../hooks/use-media-query';
import { Breakpoints } from '../enums';
import {
  checkHTMLErrors, classnames, emailPattern, HTMLValidationError,
} from '../../helpers/utils';
import { ErrorMessage } from '../error-message';
import { Spinner } from '../spinner';
import { Button, ButtonStyle, ButtonType } from '../button';
import { Input, InputStyle } from '../input';
import styles from './password-recovery.module.scss';

type PasswordRecoveryProps = {
  translPrefix: string
  t: (text: string, text2?: { email: string }) => string,
  // TODO change to proper type. May be something like <AxiosResponse<unknown>>
  forgotPassword: (email: string) => Promise<any>,
  checkGoogleUserError?: boolean,
  goToLogin: () => void
};

const PasswordRecovery = ({
  translPrefix, t, forgotPassword, checkGoogleUserError = true, goToLogin,
}: PasswordRecoveryProps) => {
  const [email, setEmail] = useState('');
  const [fetching, setFetching] = useState(false);
  const [successScreen, setSuccessScreen] = useState(false);
  const [errorServer, setErrorServer] = useState(false);
  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});
  const [googleUser, setGoogleUser] = useState(false);
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const recoverPassword = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const target = event.target as HTMLFormElement;
    if (!target.checkValidity()) {
      setFormErrors(checkHTMLErrors(target));
    } else {
      try {
        setFetching(true);
        await forgotPassword(email);
        setFetching(false);
        setSuccessScreen(true);
      } catch (e: any) {
        setFetching(false);

        if (checkGoogleUserError && e.code === 4021) {
          setGoogleUser(true);
        }
        setErrorServer(true);
      }
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmail(value);
  };
  if (fetching) {
    return (
      <div className={styles.container}>
        <Spinner />
      </div>
    );
  }

  if (successScreen) {
    return (
      <div className={styles.container}>

        <div className={styles.content}>
          <p className="text__body__large__textNeutral50">
            {t(`${translPrefix}.recoveryText`, { email })}
          </p>
          <Button
            type="button"
            className={classnames(styles.buttonLink, 'text__body__large__primary')}
            buttonStyle={ButtonStyle.Link}
            onClick={goToLogin}
          >
            {t(`${translPrefix}.backToLogin`)}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {errorServer && (
      <ErrorMessage
        message={googleUser ? t('error.googleUserError') : t('error.errorMessage')}
        handleClose={() => { setErrorServer(false); }}
        errorStyle={styles.errorMessage}
      />
      )}

      <form onSubmit={recoverPassword} noValidate>
        <div className={styles.content}>
          <p className={classnames(
            mobile ? 'text__heading2__textNeutral50' : 'text__heading5__textNeutral50',
            styles.titleMargin,
          )}
          >
            {t(`${translPrefix}.title`)}

          </p>
          <p className={classnames(
            mobile ? 'text__body__large__textNeutral50' : 'text__body__medium__textNeutral50',
            styles.subtitleMargin,
          )}
          >
            {t(`${translPrefix}.subtitle`)}

          </p>
          <Input
            required
            id="email"
            containerClass={styles.input}
            label={t(`${translPrefix}.email`)}
            placeholder={t(`${translPrefix}.email`)}
            inputStyle={InputStyle.FORM}
            value={email}
            onChange={handleChange}
            formError={formErrors}
            t={t}
            pattern={emailPattern()}

          />
          <Button
            className={classnames(styles.button, mobile ? 'text__body__medium__textNeutral10' : 'text__body__small__textNeutral10')}
            buttonStyle={mobile ? ButtonStyle.Primary : ButtonStyle.PrimaryGradient}
            buttonType={ButtonType.Submit}
          >
            {t(`${translPrefix}.recoverPassword`)}
          </Button>
          <Button
            type="button"
            className={classnames(styles.buttonLink, mobile ? 'text__body__large__primary40' : 'text__body__medium__primary40')}
            buttonStyle={ButtonStyle.Link}
            onClick={goToLogin}
          >
            {t(`${translPrefix}.cancel`)}
          </Button>
        </div>
      </form>
    </div>
  );
};

export { PasswordRecovery };
