const MaintenanceFilters = {
  allTabs: [
    {
      title: 'Property type',
      type: 'property_type',
      items: [
        { name: 'Building', code: 'Building', parentType: 'property_type' },
        { name: 'Condo/House', code: 'CondoHouse', parentType: 'property_type' },
      ],
    },
    {
      title: 'Category',
      type: 'category',
      items: [
        { name: 'Plumbing', code: 'Plumbing', parentType: 'category' },
        { name: 'Electricity', code: 'Electricity', parentType: 'category' },
        { name: 'Extermination', code: 'Extermination', parentType: 'category' },
        { name: 'Heating repair', code: 'Heating Repair', parentType: 'category' },
        { name: 'Minor Repair', code: 'Minor Repair', parentType: 'category' },
        { name: 'Gardening', code: 'Gardening', parentType: 'category' },
        { name: 'Snow removing', code: 'Snow Removing', parentType: 'category' },
        { name: 'Superintendent', code: 'Superintendent', parentType: 'category' },
        { name: 'Other', code: 'Other', parentType: 'category' },
      ],
    },
  ],
  inProgress: [
    {
      title: 'Property type',
      type: 'property_type',
      items: [
        { name: 'Building', code: 'Building', parentType: 'property_type' },
        { name: 'Condo/House', code: 'CondoHouse', parentType: 'property_type' },
      ],
    },
    {
      title: 'Category',
      type: 'category',
      items: [
        { name: 'Plumbing', code: 'Plumbing', parentType: 'category' },
        { name: 'Electricity', code: 'Electricity', parentType: 'category' },
        { name: 'Extermination', code: 'Extermination', parentType: 'category' },
        { name: 'Heating repair', code: 'Heating Repair', parentType: 'category' },
        { name: 'Minor Repair', code: 'Minor Repair', parentType: 'category' },
        { name: 'Gardening', code: 'Gardening', parentType: 'category' },
        { name: 'Snow removing', code: 'Snow Removing', parentType: 'category' },
        { name: 'Superintendent', code: 'Superintendent', parentType: 'category' },
        { name: 'Other', code: 'Other', parentType: 'category' },
      ],
    },
    {
      title: 'Priority',
      type: 'priority',
      items: [
        { name: 'High', code: 'High', parentType: 'priority' },
        { name: 'Medium', code: 'Medium', parentType: 'priority' },
        { name: 'Low', code: 'Low', parentType: 'priority' },
      ],
    },
  ],
  claims: [
    {
      title: 'Status',
      type: 'status',
      items: [
        { name: 'New', code: 'New', parentType: 'status' },
        { name: 'In progress', code: 'In Progress', parentType: 'status' },
        { name: 'Solved', code: 'Solved', parentType: 'status' },
        { name: 'Rejected', code: 'Rejected', parentType: 'status' },
      ],
    },
  ],
  tasksOfProperty: [
    {
      title: 'Category',
      type: 'category',
      items: [
        { name: 'Plumbing', code: 'Plumbing', parentType: 'category' },
        { name: 'Electricity', code: 'Electricity', parentType: 'category' },
        { name: 'Extermination', code: 'Extermination', parentType: 'category' },
        { name: 'Heating repair', code: 'Heating Repair', parentType: 'category' },
        { name: 'Minor Repair', code: 'Minor Repair', parentType: 'category' },
        { name: 'Gardening', code: 'Gardening', parentType: 'category' },
        { name: 'Snow removing', code: 'Snow Removing', parentType: 'category' },
        { name: 'Superintendent', code: 'Superintendent', parentType: 'category' },
        { name: 'Other', code: 'Other', parentType: 'category' },
      ],
    },
    {
      title: 'Status',
      type: 'task_status',
      items: [
        { name: 'New', code: 'New', parentType: 'task_status' },
        { name: 'In progress', code: 'In Progress', parentType: 'task_status' },
        { name: 'Solved', code: 'Solved', parentType: 'task_status' },
        { name: 'Rejected', code: 'Rejected', parentType: 'task_status' },
      ],
    },
    {
      title: 'Priority',
      type: 'priority',
      items: [
        { name: 'High', code: 'High', parentType: 'priority' },
        { name: 'Medium', code: 'Medium', parentType: 'priority' },
        { name: 'Low', code: 'Low', parentType: 'priority' },
      ],
    },
  ],
};

export { MaintenanceFilters };
