import { Tab } from '@mapix/common/src/common/tab';
import { classnames } from '@mapix/common/src/helpers/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RentalDashboardTabs } from './common-reducer';
import styles from './rentals-dashboard.module.scss';
import { BuildingTab } from './tabs/building-tab';
import { CondoTab } from './tabs/condo-tab';

const RentalsDashboard = () => {
  const [currentTab, setCurrentTab] = useState<string>(RentalDashboardTabs.Building);
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const tabFilter = urlParams.get('tab');

  useEffect(() => {
    if (tabFilter === 'CondoHouse') {
      setCurrentTab(RentalDashboardTabs.CondoHouse);
    }
  }, []);

  const clickTab = (option: string) => {
    setCurrentTab(option);
  };

  return (
    <div className={styles.container}>
      <div className="text__heading4__textNeutral40">
        {t('rentalsDashboard.title')}
      </div>
      <p className={classnames(styles.subtitle, 'text__body__small__textNeutral30')}>
        {t('rentalsDashboard.subtitle')}
      </p>

      <div className="row">
        {Object.values(RentalDashboardTabs).map((option) => (
          <div key={option} className={styles.tabContainer}>
            <Tab
              onClickFn={() => clickTab(option)}
              name={t(`rentalsDashboard.${option}`)}
              isActive={currentTab === option}
            />
          </div>
        ))}
      </div>
      {currentTab === RentalDashboardTabs.Building
        ? <BuildingTab />
        : <CondoTab />}
    </div>
  );
};

export { RentalsDashboard };
