import React from 'react';
import styles from './button-icon-with-text-down.module.scss';

type ButtonIconWithTextDownProps = {
  onClick: () => void,
  icon: JSX.Element,
  text: string
};

const ButtonIconWithTextDown = ({ onClick, icon, text }: ButtonIconWithTextDownProps) => (
  <div className={styles.buttonTextWrapper}>
    <button
      name={text}
      type="button"
      className={styles.iconButton}
      onClick={onClick}
    >
      <div className={styles.icon}>
        {icon}
      </div>
    </button>
    <label htmlFor={text} className="text__button__small__textNeutral40">
      {text}
    </label>
  </div>
);

export { ButtonIconWithTextDown };
