export const initAddress = {
  city: '',
  country: '',
  civicNumber: '',
  streetName: '',
  province: '',
  zipCode: '',
  unitNumber: '',

};
