import { RawRequestStatus } from '@mapix/common/src/common/enums';
import { PublicRequest } from 'networking/types';
import { newTaskInitialState } from './new-task-initial-state';
import { basicUserFieldsInitialState } from './basic-user-fields-initial-state';

export const publicRequestInitialState: PublicRequest = {
  id: 0,
  status: RawRequestStatus.WAITING_FOR_MANAGER_APPROVAL,
  newTask: newTaskInitialState,
  createdAt: '',
  contractor: basicUserFieldsInitialState,
  manager: basicUserFieldsInitialState,
  contractorMessage: '',
  managerMessage: '',
  estimatedEndDate: '',
  negotiatingStartDate: '',
  publicRequestEvents: [],
  startDate: '',
  taskMessages: [],
  typeOfCost: 'budget',
  budget: 0,
  amountOfHours: 0,
  reasonForRejection: '',
};
