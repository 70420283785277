/* A simplified version of the Property model that's easier to access */
import { getFullAddress } from 'helpers/utils';
import { Lease } from './lease';

class PropertyTableItem {
  address: string;

  landlord: string;

  tenant: string | undefined;

  condition: string;

  buildingName: string;

  type: string;

  unitsCount: number | undefined;

  id: number | undefined;

  status?: string;

  lease?: SerializedLease;

  constructor(params: SerializedProperty) {
    this.landlord = params.owner?.name || '';
    this.tenant = params.lease?.tenant ? `${params.lease.tenant.name} ${params.lease.tenant.lastname || ''}` : undefined;
    this.condition = params.condition ? params.condition : '';
    this.type = params.type;
    this.buildingName = params.buildingName || '';
    this.address = getFullAddress(params.address);
    this.unitsCount = params.unitsCount;
    this.id = params.id;
    this.status = params.status;
    this.lease = params.lease ? new Lease(params.lease) : undefined;
  }
}

export { PropertyTableItem };
