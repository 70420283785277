import { constants } from 'config/constants';

class UserSerializer {
  static serialize(data: User) {
    return {
      email: data.email || '',
      password: data.password,
      type: data.type || '',
      invite: data.invite || false,
      condition: data.condition || '',
      app_name: constants.appName,
      login_role: data.loginRole || null,
    };
  }

  static deSerialize(data: UserFromBackend): UserSerialized {
    return {
      id: data.id,
      name: data.name,
      lastName: data.last_name,
      email: data.email,
      type: data.type,
      phoneNumber: data.phone_number || '',
      normalAuth: data.normal_auth,
      googleAuth: data.google_auth,
      additionalPhoneNumber: data.additional_phone_number || '',
      googleToken: data.google_token,
      invite: data.invite,
      condition: data.condition,
      active: data.active,
      countryCode: data.country_code || '',
      additionalCountryCode: data.additional_country_code || '',
      loginRole: data.login_role || '',
    };
  }

  static serializeResetPassword(data: ResetPasswordType) {
    return {
      email: data.email,
      new_password: data.newPassword,
      password_reset_token: data.passwordToken,
    };
  }

  static serializeEditProfile(data: UserEdit) {
    return {
      phone_number: data.phoneNumber || null,
      name: data.name,
      additional_phone_number: data.additionalPhoneNumber || null,
      last_name: data.lastName,
      country_code: data.phoneNumber && data.countryCode ? data.countryCode : null,
      additional_country_code: data.additionalPhoneNumber && data.additionalCountryCode
        ? data.additionalCountryCode : null,
    };
  }

  static serializeUpdatePassword(data: UpdatePassword) {
    return {
      current_password: data.currentPassword,
      new_password: data.newPassword,
    };
  }

  static serializeInvitation(data: SerializedInvitation): RawInvitation {
    return {
      email: data.email,
      lease_id: data.leaseId,
    };
  }

  static serializeActivateAccount(data: ActivateAccount) {
    return {
      email: data.email,
      new_password: data.newPassword,
      tenant_invitation_token: data.tenantInvitationToken,
    };
  }

  static serializeVerifyAccount(data: VerifyAccount) {
    return {
      email: data.email,
      email_verification_token: data.emailVerificationToken,
    };
  }

  static serializePhone(data: PhoneObject): RawPhoneObject {
    return {
      country_code: data.phoneNumber ? data.countryCode : '',
      phone_number: data.phoneNumber,
    };
  }
}

export { UserSerializer };
