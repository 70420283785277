import styles from 'pages/create-property/create-building/create-property-building.module.scss';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import { ChangeEvent } from 'react';
import { classnames, HTMLValidationError } from '@mapix/common/src/helpers/utils';
import { Select } from '@mapix/common/src/common/select';
import { useTranslation } from 'react-i18next';
import { Currency } from 'common/enums';

type RentalFormProps = {
  formErrors: HTMLValidationError,
  onChange: (value: ChangeEvent<HTMLInputElement>, type: string | number) => void,
  monthlyRental: number,
  yearlyRental: number,
  condoFees: number | null,
  currency: string,
  onClickSelect: (option: string, field: string) => void,
  isCondo: boolean,
};

const renewLeaseTranslPrefix = 'renewLease.rentalRow';
const RentalForm = ({
  formErrors, onChange, monthlyRental, yearlyRental, currency, onClickSelect, isCondo,
  condoFees,
}: RentalFormProps) => {
  const { t } = useTranslation();

  const secondInput = isCondo ? 'condoFees' : 'yearlyRental';
  return (
    <>
      <div className={styles.formContainer}>
        <div className={styles.row}>
          <Select
            options={Object.values(Currency)}
            onClickOption={(option) => onClickSelect(option, 'currency')}
            required
            id="currency"
            name="currency"
            label={t(`${renewLeaseTranslPrefix}.currency`)}
            formError={formErrors}
            value={currency}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, 'currency')}
            inputStyle={InputStyle.FORM}
            containerClass={classnames(styles.input, styles.select, styles.small)}
            t={t}
          />

          <Input
            required
            min={1}
            type="number"
            id="monthlyRental"
            name="monthlyRental"
            label={t(`${renewLeaseTranslPrefix}.monthlyRental`)}
            formError={formErrors}
            value={monthlyRental || ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, 'monthlyRental')}
            inputStyle={InputStyle.FORM}
            containerClass={classnames(styles.input, styles.small)}
            t={t}
          />

          <Input
            required
            id={secondInput}
            name={secondInput}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, secondInput)}
            inputStyle={InputStyle.FORM}
            value={isCondo ? condoFees! : yearlyRental}
            label={t(`${renewLeaseTranslPrefix}.${secondInput}`)}
            placeholder={t(`${renewLeaseTranslPrefix}.${secondInput}`)}
            formError={formErrors}
            containerClass={classnames(styles.input, styles.large)}
            t={t}
          />

        </div>
      </div>
    </>
  );
};

export { RentalForm };
