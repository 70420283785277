import { useTranslation } from 'react-i18next';
import styles from './read-only-forms.module.scss';

type BuildingInfoProps = {
  buildingName?: string,
  fullAddress: string,
  totalUnits?: number | string,
  totalRentedUnits?: number | string,
  fromLeases?: boolean,
  unitNumber?: string,
};

const translPrefix = 'propertyDetail.infoTab';
const BuildingInfo = ({
  buildingName, fullAddress, totalUnits, totalRentedUnits, unitNumber, fromLeases,
}: BuildingInfoProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.itemBlock}>
        <div className="text__body__medium__textNeutral40">
          {t(`${translPrefix}.buildingName`)}
        </div>
        <div className="text__body__medium__textNeutral50">
          {buildingName !== '' ? buildingName : '-'}
        </div>
      </div>

      <div className={styles.itemBlock}>
        <div className="text__body__medium__textNeutral40">
          {t(`${translPrefix}.address`)}
        </div>
        <div className="text__body__medium__textNeutral50">
          {fullAddress}
        </div>
      </div>

      {totalUnits && Number(totalUnits) > 0 && (
        <div className={styles.itemBlock}>
          <div className="text__body__medium__textNeutral40">
            {t(`${translPrefix}.totalUnits`)}
          </div>
          <div className="text__body__medium__textNeutral50">{totalUnits}</div>
        </div>
      )}

      {totalRentedUnits && Number(totalRentedUnits) > 0 && (
        <div className={styles.itemBlock}>
          <div className="text__body__medium__textNeutral40">
            {t(`${translPrefix}.rentedUnits`)}
          </div>
          <div className="text__body__medium__textNeutral50">
            {totalRentedUnits}
          </div>
        </div>
      )}

      { fromLeases && (
      <div className={styles.itemBlock}>
        <div className="text__body__medium__textNeutral40">
          {t(`${translPrefix}.unitNumber`)}
        </div>
        <div className="text__body__medium__textNeutral50">
          { unitNumber !== '' ? unitNumber : '-'}
        </div>
      </div>
      )}

    </div>
  );
};

export { BuildingInfo };
