import {
  ChangeEvent, Dispatch, FormEvent, useState,
} from 'react';
import {
  addError,
  checkHTMLErrors, HTMLValidationError, isEmptyObject,
} from '@mapix/common/src/helpers/utils';
import { Contractor } from 'models/contractor';
import { useTranslation } from 'react-i18next';
import { Table } from 'common/table';
import { ContractorForm } from 'pages/create-property/create-building/contractor-form';
import { ContractorCategories } from 'common/enums';
import { CreateFooter } from 'common/creation';
import { ReactComponent as Trash } from 'assets/icons/thrash.svg';
import { mergePhoneNumberObject } from 'helpers/utils';
import { Action } from './create-property-condo-reducer';
import styles from './create-property-condo.module.scss';

type CreatePropertyCondoStep3Props = {
  dispatch: Dispatch<Action>,
  contractors: Contractor[],
  isSaveButtonDisabled?: Boolean,
};

const headers = ['category', 'name', 'phoneNumber', 'email'];

const footerTranslPrefix = 'createCondo.footer';
const translPrefix = 'contractors';

const CreatePropertyCondoStep3 = ({
  dispatch,
  contractors,
  isSaveButtonDisabled,
}: CreatePropertyCondoStep3Props) => {
  const [formContractor, setFormContractor] = useState<Contractor>(new Contractor(null));
  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});
  const [phoneErrors, setPhoneErrors] = useState({});

  const { t } = useTranslation();

  const iconCells = (item: Contractor) => (
    [
      <Trash
        onClick={() => dispatch({ type: 'ADD_REMOVE_CONTRACTOR', contractor: item })}
        className={styles.trash}
      />,
    ]
  );

  const discardContractor = () => {
    setFormContractor(new Contractor(null));
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormContractor(
      (prevState) => ({ ...prevState, [e.target.name]: e.target.value }),
    );
  };

  const onChangePhoneNumber = (phoneNumber: string, countryCode: string) => {
    setFormContractor((prevState) => (
      { ...prevState, phoneNumber, countryCode }));
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    if (!target.checkValidity() || !formContractor.phoneNumber) {
      setFormErrors(checkHTMLErrors(target));
      if (!formContractor.phoneNumber) {
        addError(t('error.emptyField'), 'phoneNumber', setPhoneErrors);
      }
      return;
    }

    if (!isEmptyObject(phoneErrors)) {
      return;
    }

    dispatch({ type: 'ADD_REMOVE_CONTRACTOR', contractor: formContractor });
    setFormContractor(new Contractor(null));
    setFormErrors({});
  };

  const emptyState = (
    <div className="text__body__medium__textNeutral40">
      {t(`${translPrefix}.emptyState`)}
    </div>
  );

  const showButtons = !!(formContractor.category || formContractor.name
    || formContractor.phoneNumber || formContractor.email);

  return (
    <div className={styles.step3}>
      <Table
        headerNames={headers}
        data={mergePhoneNumberObject(contractors)}
        dataProperties={headers}
        dashboardName="contractors"
        uniqueId="email"
        emptyState={emptyState}
        iconCell={iconCells}
      />

      <form noValidate onSubmit={onSubmit}>

        <ContractorForm
          onClickSelectFn={(option) => setFormContractor(
            { ...formContractor, category: option as ContractorCategories },
          )}
          onChangeFn={onChange}
          onChangePhoneNumber={onChangePhoneNumber}
          formErrors={{ ...formErrors, ...phoneErrors }}
          contractor={formContractor}
          onDiscard={discardContractor}
          isSaveButtonDisabled={isSaveButtonDisabled}
          showButtons={showButtons}
          setPhoneErrors={setPhoneErrors}
        />
      </form>

      <CreateFooter
        disableNextCondition={showButtons}
        nextName={t(`${footerTranslPrefix}.save`)}
        showBack
        backFn={() => dispatch({ type: 'GO_BACK' })}
        nextFn={() => dispatch({ type: 'FINISH', contractors })}
      />

    </div>
  );
};

export { CreatePropertyCondoStep3 };
