import { Address, RawAddress } from '../../types';

class AddressSerializer {
  static deSerializeAddress(data: RawAddress): Address {
    return {
      civicNumber: data.civic_number,
      streetName: data.street_name,
      country: data.country,
      province: data.province,
      city: data.city,
      zipCode: data.zip_code,
      unitNumber: data.unit_number || undefined,
      provinceId: data.province_id || undefined,
    };
  }
}

export { AddressSerializer };
