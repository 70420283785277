import { Contractor } from 'models/contractor';
import { HTMLValidationError } from '@mapix/common/src/helpers/utils';
import { Task } from 'models/task';
import { getFullAddress } from 'helpers/utils';

const editSteps = ['Complete task info', 'Assign to a contractor'];

type EditTaskState = {
  propertyAddress: string,
  step: number,
  steps: string[],
  buildingType: boolean | null,
  taskCategory: string,
  issueDate: string,
  description: string,
  priority: string,
  propertyId: number,
  taskPhoto: FileType [],
  area: string,
  finish: boolean,
  specificUnit: number | null,
  unitNumber: string,
  contractors: Contractor[],
  infoContractor: string,
  notification: string,
  formErrors: HTMLValidationError,
  addContractorModal: boolean,
  contractorId: number | null,
  modalAssignLater: boolean,
  modalAssignTask: boolean,
  errorModal: boolean,
  sms: boolean,
  email: boolean,
  optionNotifyNotSelected: boolean,
  assignLater: boolean,
};

type Action =
  | { type: 'FINISH' }
  | { type: 'FINISH_MODAL_ASSIGN_LATER' }
  | { type: 'MODAL_ASSIGN_TASK', modalAssignTask: boolean }
  | { type: 'OPTION_NOTIFY_NOT_SELECTED', optionNotifyNotSelected: boolean }
  | { type: 'FINISH_MODAL_ASSIGN_TASK' }
  | { type: 'FINISH_ERROR' }
  | { type: 'CLOSE_ERROR' }
  | { type: 'START_FINISH_FLOW', assignLater: boolean }
  | { type: 'CHANGE_CONTACT_METHOD', field: string, value: boolean }
  | { type: 'CHANGE_FIELD', value: string | number | boolean, field: string }
  | { type: 'ADD_CONTRACTOR_MODAL', addContractorModal: boolean }
  | { type: 'TASK_FETCHED', taskData: Task }
  | { type: 'TASK_PHOTO', taskPhoto: FileType[] }
  | { type: 'GO_BACK' }
  | { type: 'FORM_ERROR', errors: HTMLValidationError }
  | { type: 'UNIT_SELECTED', specificUnit: number | null, unitNumber: string }
  | { type: 'GO_NEXT' };

const initialState = (propertyId?: number) => ({
  propertyAddress: '',
  step: 0,
  steps: editSteps,
  buildingType: null,
  taskCategory: '',
  issueDate: '',
  description: '',
  priority: '',
  area: '',
  finish: false,
  propertyId: propertyId || -1,
  taskPhoto: [],
  specificUnit: null,
  unitNumber: '',
  contractors: [],
  infoContractor: '',
  notification: '',
  formErrors: {},
  addContractorModal: false,
  contractorId: null,
  modalAssignLater: false,
  modalAssignTask: false,
  errorModal: false,
  email: true,
  sms: false,
  optionNotifyNotSelected: false,
  assignLater: false,
});

function EditTaskReducer(state: EditTaskState, action: Action): EditTaskState {
  switch (action.type) {
    case 'CHANGE_CONTACT_METHOD':
      return {
        ...state,
        [action.field]: action.value,
      };
    case 'TASK_FETCHED':
      return {
        ...state,
        taskCategory: action.taskData.category,
        propertyId: action.taskData.property.id,
        issueDate: action.taskData.startDate,
        description: action.taskData.description,
        priority: action.taskData.priority,
        specificUnit: action.taskData.unit ? action.taskData.unit.id : null,
        unitNumber: action.taskData.unit ? action.taskData.unit.unitNumber : '',
        contractorId: action.taskData.contractor?.id || null,
        infoContractor: action.taskData.additionalInformation,
        buildingType: action.taskData.property.type === 'Building',
        area: action.taskData.unit ? 'specific' : 'common',
        email: action.taskData.email,
        sms: action.taskData.sms,
        propertyAddress: getFullAddress(action.taskData.property.address),
      };
    case 'FORM_ERROR':
      return {
        ...state,
        formErrors: action.errors,
      };
    case 'ADD_CONTRACTOR_MODAL':
      return {
        ...state,
        addContractorModal: action.addContractorModal,
      };
    case 'GO_BACK':
      return {
        ...state,
        step: state.step - 1,
      };
    case 'GO_NEXT':
      return {
        ...state,
        step: state.step + 1,
      };
    case 'CHANGE_FIELD':
      return {
        ...state,
        [action.field]: action.value,
      };
    case 'UNIT_SELECTED':
      return {
        ...state,
        specificUnit: action.specificUnit || null,
        unitNumber: action.unitNumber,
      };
    case 'MODAL_ASSIGN_TASK':
      return {
        ...state,
        modalAssignTask: action.modalAssignTask,
      };
    case 'FINISH_MODAL_ASSIGN_TASK':
      return {
        ...state,
        modalAssignTask: true,
        finish: state.contractorId ? true : state.finish,
      };
    case 'FINISH_MODAL_ASSIGN_LATER':
      return {
        ...state,
        modalAssignLater: true,
      };
    case 'FINISH_ERROR':
      return {
        ...state,
        errorModal: true,
      };
    case 'CLOSE_ERROR':
      return {
        ...state,
        errorModal: false,
      };
    case 'START_FINISH_FLOW':
      return {
        ...state,
        finish: true,
        assignLater: action.assignLater,
      };
    case 'TASK_PHOTO':
      return {
        ...state,
        taskPhoto: action.taskPhoto,
      };
    case 'OPTION_NOTIFY_NOT_SELECTED':
      return {
        ...state,
        optionNotifyNotSelected: action.optionNotifyNotSelected,
        finish: false,
      };
    default:
      return {
        ...state,
      };
  }
}

export { EditTaskReducer, initialState };
export type { Action };
