import { classnames } from '@mapix/common/src/helpers/utils';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import styles from './detail-card.module.scss';
import 'react-circular-progressbar/dist/styles.css';

type DetailCardItem = {
  title: string,
  titleClass: string,
  indicator: string,
  indicatorClass: string,
  subTitle: string,
  isDate?: boolean,
  month?: string,
  monthClass?: string,
  isOccupancy?: boolean,
};

type DetailCardProps = {
  item: DetailCardItem,
  cardWidth: string,
};

const DetailCard = ({
  item, cardWidth,
}: DetailCardProps) => (
  <div style={{ width: cardWidth }} className={styles.cardContainer}>
    {item.isOccupancy
      ? (
        <div className={styles.occupancy}>
          <CircularProgressbarWithChildren value={Number(item.indicator)}>
            <span className={classnames(styles.occupancyLabel, 'text__heading5__primary80')}>
              {item.indicator}
            </span>
          </CircularProgressbarWithChildren>
        </div>
      )
      : (
        <div className={classnames(item.indicatorClass, styles.number, 'row align-justify-center')}>
          {item.isDate ? (
            <div className={styles.date}>
              <div>{item.indicator}</div>
              <div className={classnames(item.monthClass || '', 'bold')}>{item.month}</div>
            </div>
          ) : item.indicator}
        </div>
      )}
    <div className={styles.text}>
      <div className={item.titleClass}>{item.title}</div>
      <div className="text__body__small__textNeutral30">{item.subTitle}</div>
    </div>
  </div>
);

export { DetailCard };
export type { DetailCardItem };
