import React,
{
  useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { logger } from 'helpers/logger';
import { Unit } from 'models/unit';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { ModalResult } from 'common/modal-result';
import { ButtonClose } from '@mapix/common/src/common/button-close';
import { UnitForm } from 'pages/create-property/create-building/unit-form';
import { Spinner } from '@mapix/common/src/common/spinner';
import { ReactComponent as EditPencil } from 'assets/icons/edit-black.svg';
import { ReactComponent as Building } from 'assets/icons/building.svg';
import { PropertyController } from 'networking/controllers/property-controller';
import { useHistory, useParams } from 'react-router-dom';
import { Property } from 'models/property';
import { classnames } from '@mapix/common/src/helpers/utils';
import { ApiError } from 'models/api-error';
import styles from './create-unit.module.scss';

type ParamType = {
  propertyId: string,
};

const CreateUnit = () => {
  const { t } = useTranslation();
  const { propertyId } = useParams<ParamType>();
  const history = useHistory();

  const [property, setProperty] = useState<Property | null>(null);
  const [repeatedUnit, setRepeatedUnit] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [errorServer, setErrorServer] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const translPrefix = 'createUnit';

  const getAddress = async () => {
    try {
      setFetching(true);
      const result = await PropertyController.getDetailedProperty(Number(propertyId));
      setProperty(result);
      setFetching(false);
    } catch (err: any) {
      setFetching(false);
      setErrorServer(true);
      logger.error(err);
    }
  };

  useEffect(() => {
    getAddress();
  }, []);

  const saveUnit = async (newUnit: Unit) => {
    setRepeatedUnit(false);
    try {
      setFetching(true);
      await PropertyController.createUnit(Number(propertyId), newUnit);
      setFetching(false);
      setSuccessModal(true);
    } catch (err) {
      setFetching(false);
      if (err instanceof ApiError && err.code === 4003) {
        setRepeatedUnit(true);
      } else {
        logger.error(err as Error);
        setErrorServer(true);
      }
    }
  };

  if (fetching) {
    return (<Spinner />);
  }

  return (
    <>
      {errorServer
        && <ErrorMessage message={t('error.errorMessage')} handleClose={() => setErrorServer(false)} />}
      {closeModal
      && (
        <ModalResult
          title={t(`${translPrefix}.cancelModal.title`)}
          subtitle={t(`${translPrefix}.cancelModal.subTitle`)}
          Icon={EditPencil}
          buttonTextRight={t(`${translPrefix}.cancelModal.yes`)}
          buttonTextLeft={t(`${translPrefix}.cancelModal.goBack`)}
          handleButtonLeft={() => setCloseModal(false)}
          handleButtonRight={() => history.goBack()}
          handleButtonClose={() => setCloseModal(false)}
          iconStyle={styles.icon}
        />
      )}
      {successModal
      && (
        <ModalResult
          title={t(`${translPrefix}.successModal.title`)}
          subtitle={t(`${translPrefix}.successModal.subTitle`)}
          Icon={Building}
          withCheckIcon
          buttonTextRight={t(`${translPrefix}.successModal.ok`)}
          handleButtonRight={() => history.goBack()}
          handleButtonClose={() => history.goBack()}
        />
      )}

      <div className={styles.container}>

        <div className={styles.header}>
          <div className="text__heading4__textNeutral50">{t(`${translPrefix}.addUnit`)}</div>
          <ButtonClose closeFn={() => { setCloseModal(true); }} closeText={t(`${translPrefix}.close`)} />
        </div>

        <div className={classnames(styles.addressSubtitle, 'text__heading5__textNeutral50')}>
          {`${t(`${translPrefix}.building`)} ${property?.fullAddress}`}
        </div>

        <div className={styles.unitForm}>
          <UnitForm
            saveUnitFn={saveUnit}
            repeated={repeatedUnit}
            fromCreateUnit
          />
        </div>
      </div>
    </>
  );
};

export { CreateUnit };
