import { useTranslation } from 'react-i18next';
import { Modal } from '@mapix/common/src/common/modal';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { ReactComponent as Email } from 'assets/icons/email.svg';
import { checkHTMLErrors, classnames } from '@mapix/common/src/helpers/utils';
import { Button, ButtonStyle, ButtonType } from '@mapix/common/src/common/button';
import { FormEvent, useState } from 'react';
import { logger } from 'helpers/logger';
import { UserController } from 'networking/controllers/user-controller';
import { ModalResult } from 'common/modal-result';
import styles from './resend-invitation.module.scss';

type ResendInvitationProps = {
  closeFn: () => void,
  leaseId: number,
  email: string
};

type State = {
  error: string,
  success: boolean,
};

const initState = {
  error: '',
  success: false,
};

const translPrefix = 'invitation';
const ResendInvitation = ({ closeFn, leaseId, email }: ResendInvitationProps) => {
  const [state, setState] = useState<State>(initState);
  const { t } = useTranslation();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    if (!target.checkValidity()) {
      setState((prevState) => ({ ...prevState, formErrors: checkHTMLErrors(target) }));
    } else {
      try {
        const serialized: SerializedInvitation = {
          leaseId,
          email,
        };
        await UserController.resendInvitation(serialized);
        setState((prevState) => ({ ...prevState, success: true }));
      } catch (err: any) {
        logger.error(err);
        let msg = '';
        if (err.details === 'Tenant is already active') {
          msg = t(`${translPrefix}.alreadyActive`);
        } else if (err.details === 'Tenant was invited recently') {
          msg = t(`${translPrefix}.recently`);
        } else {
          msg = t(`${translPrefix}.generic`);
        }
        setState((prevState) => ({ ...prevState, error: msg }));
      }
    }
  };

  return (
    <>
      {state.success
        ? (
          <ModalResult
            title={t(`${translPrefix}.success`)}
            Icon={Email}
            buttonTextRight={t(`${translPrefix}.close`)}
            handleButtonRight={closeFn}
            handleButtonClose={closeFn}
            withCheckIcon
          />
        )
        : (
          <Modal>
            <div className={styles.container}>
              <button className={styles.closeButton} type="button" onClick={closeFn}>
                <Cross className={styles.closeIcon} />
              </button>

              <Email className={styles.image} />

              <div className="text__heading5__textNeutral50">{t(`${translPrefix}.title`)}</div>

              <form className={styles.formContainer} onSubmit={onSubmit}>
                <div className={styles.buttonRow}>
                  <Button
                    onClick={closeFn}
                    className={styles.largeButton}
                    buttonStyle={ButtonStyle.Secondary}
                  >
                    {t(`${translPrefix}.cancel`)}
                  </Button>
                  <Button
                    className={styles.largeButton}
                    buttonType={ButtonType.Submit}
                    buttonStyle={ButtonStyle.Primary}
                  >
                    {t(`${translPrefix}.confirm`)}
                  </Button>
                </div>

                {state.error && (
                <div className={classnames(styles.error, 'text__body__medium__danger50')}>
                  {state.error}
                </div>
                )}

              </form>

            </div>
          </Modal>
        )}
    </>
  );
};

export { ResendInvitation };
