import { useTranslation } from 'react-i18next';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { ReactComponent as Filter } from 'assets/icons/filter.svg';
import { FiltersApplied } from 'common/filters-applied';
import { FilterByDate } from 'common/filter-by-date';
import { ReactComponent as PlusCircle } from 'assets/icons/plus-circle.svg';
import { EmptyState } from 'common/empty-state';
import { ReactComponent as DollarSign } from 'assets/icons/dollar.svg';
import { Table } from 'common/table';
import {
  ChangeEvent, MouseEventHandler, useEffect, useReducer,
} from 'react';
import { BillsController } from 'networking/controllers/bills-controller';
import { FilterModal, ModalFilterItem } from 'common/filter-modal/filter-modal';
import { addOrRemoveFromArrayObject, classnames } from '@mapix/common/src/helpers/utils';
import { getFiltersByUrl, groupFilters } from 'helpers/utils';
import { OptionsDate } from 'common/filter-by-date/filter-by-date';
import {
  BillStatus, Condition, DateCodes, PropertyType,
} from 'common/enums';
import { Modal } from '@mapix/common/src/common/modal';
import { Tab } from '@mapix/common/src/common/tab';
import { MoreIcon } from 'common/more-icon';
import { Bill } from 'models/bill';
import { goToPage, RouteName } from 'routes';
import { EditBill } from 'common/edit-bill';
import { RegisterPayment } from 'common/register-payment';
import { ModalResult } from 'common/modal-result';
import { ReactComponent as Trash } from 'assets/icons/thrash.svg';
import { logger } from 'helpers/logger';
import { ReactComponent as NoResultsIcon } from 'assets/icons/search-no-results-solid.svg';
import { Pagination } from '@mapix/common/src/common/paginator';
import { ValueOf } from 'helpers/type';
import {
  BillsTableReducer, BillsTabs, initDateFilter, initialState,
} from './bills-table-reducer';
import styles from './bills-table.module.scss';

const dateOptions: OptionsDate[] = [
  { code: DateCodes.All },
  { code: DateCodes.CustomDate },
];

type BillsTableProps = {
  withTabs?: boolean,
  fixedTableHeader?: string[] | null,
  fixedDataProperties?: string[] | null,
  propertyId?: number | null,
  propertyCondition?: string | null
  hideHeader?: () => void,
  fromPropertyDashboard?: boolean,
  unitId?: number,
};

const translPrefix = 'bills';
const translPrefixBills = 'emptyState.bills.withFilters';

const parseAffectedArea = (bill: Bill, t: (text: string) => string) => {
  if (bill.property.type === PropertyType.Building) {
    return bill.unit ? `${t('unit')} ${bill.unit.unitNumber}` : t('commonArea');
  }
  return '-';
};

const addAffectedAreaDisplayValue = (bills: Bill[], t: (text: string) => string) => bills
  .map((bill) => ({ ...bill, affectedAreaDisplay: parseAffectedArea(bill, t) }));

const BillsTable = ({
  withTabs = false, fixedTableHeader = null,
  fixedDataProperties = null, propertyId = null, propertyCondition = null, hideHeader,
  fromPropertyDashboard = false, unitId,
}: BillsTableProps) => {
  const { t } = useTranslation();
  const params = new URLSearchParams(window.location.search);
  const [state, dispatch] = useReducer(
    BillsTableReducer,
    initialState(fromPropertyDashboard, getFiltersByUrl(params.toString())),
  );

  const getMoreIcon = (options: string[], optionsFn: MouseEventHandler<HTMLButtonElement>[]) => (
    <MoreIcon iconClass={styles.icons} key="more-icon" options={options} optionsFn={optionsFn} />
  );

  const editFn = (bill: Bill) => {
    dispatch({ type: 'SHOW_BILL', selectedBill: bill });
    if (hideHeader) {
      hideHeader();
    }
  };

  /* TODO Add Bill item to parameters and map the functions */
  const iconCells = (bill: Bill) => {
    const register = (
      <Button
        onClick={() => dispatch({ type: 'SHOW_REGISTER_PAYMENT', selectedBill: bill })}
        buttonStyle={ButtonStyle.Link}
      >
        <div className={classnames(styles.link, 'text__button__medium__primary40')}>
          {t(`${translPrefix}.tableActions.registerPayment`)}
        </div>
      </Button>
    );

    const options = [t(`${translPrefix}.tableActions.edit`), t(`${translPrefix}.tableActions.delete`)];

    const optionsFn = [
      () => editFn(bill),
      () => dispatch({ type: 'SHOW_DELETE_BILL', billId: bill.id }),
    ];

    if (propertyCondition === Condition.Archived) {
      return [];
    }

    if (bill.status === BillStatus.Paid) {
      return [getMoreIcon(options, optionsFn)];
    }

    return [register, getMoreIcon(options, optionsFn)];
  };

  const getBills = async () => {
    dispatch({ type: 'FETCHING' });
    try {
      let response = null;

      if (unitId) {
        response = await BillsController.getUnitBills(
          state.paginator?.currentPage || 1,
          10,
          state.query,
          state.tab,
          unitId,
          state.appliedFilters,
          state.dateFilter,
        );
      } else if (!propertyId) {
        response = await BillsController.getBills(
          state.paginator?.currentPage || 1,
          10,
          state.query,
          state.tab,
          state.dateFilter,
          state.appliedFilters,
        );
      } else {
        response = await BillsController.getPropertyBill(
          state.paginator?.currentPage || 1,
          10,
          state.query,
          state.tab,
          propertyId,
          state.appliedFilters,
          state.dateFilter,
        );
      }
      dispatch({ type: 'BILLS_FETCHED', paginator: response });
    } catch (err) {
      dispatch({ type: 'ERROR' });
    }
  };

  useEffect(() => {
    /* On modal close, fetch again */
    if (!state.registerPayment || !state.editBill || !state.deleteBill) {
      getBills();
    }
  }, [state.tab, state.query, state.appliedFilters, state.dateFilter, state.editBill,
    state.registerPayment, state.deleteBill, state.paginator.currentPage]);

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'SEARCH_QUERY', query: e.target.value });
  };

  const toggleModal = () => {
    dispatch({ type: 'TOGGLE_FILTER_MODAL', filterModal: !state.filterModal });
  };

  const applyFilters = (updatedFilters: ModalFilterItem[]) => {
    groupFilters(state.tab, state.dateFilter, updatedFilters);
    dispatch({ type: 'APPLY_FILTERS', filters: updatedFilters });
  };

  const clearFilters = () => {
    groupFilters(state.tab, state.dateFilter, []);
    dispatch({ type: 'CLEAR_FILTERS' });
  };

  const removeFilter = (filter: ModalFilterItem) => {
    const updatedFilters = addOrRemoveFromArrayObject(state.appliedFilters, filter, 'code');
    groupFilters(state.tab, state.dateFilter, updatedFilters);
    dispatch({ type: 'APPLY_FILTERS', filters: updatedFilters });
  };

  const applyDate = (startDate: string, endDate: string, code: string) => {
    const dateFilter = { startDate, endDate, code };
    groupFilters(state.tab, dateFilter, state.appliedFilters);
    dispatch({ type: 'APPLY_DATE_FILTERS', dateFilter });
  };

  const tabChange = (tab: string) => {
    groupFilters(tab, initDateFilter, []);
    dispatch({ type: 'TAB_CHANGED', newTab: tab });
  };

  const finishEditing = () => {
    dispatch({ type: 'DISMISS_BILL' });
    if (hideHeader) {
      hideHeader();
    }
  };

  const deleteBill = async () => {
    try {
      await BillsController.deleteBill(state.billId);
      dispatch({ type: 'CLOSE_DELETE_BILL' });
    } catch (err: any) {
      logger.error(err);
      dispatch({ type: 'ERROR' });
    }
  };

  const clickPagination = (nextPage: number) => {
    if (nextPage !== state.paginator.currentPage) {
      dispatch({ type: 'PAGE_CHANGED', newPage: nextPage });
    }
  };

  const getEmptyMessage = (tab: ValueOf<BillsTabs>) => {
    if (tab === BillsTabs.Overdue) {
      return t(`${translPrefixBills}.noOverdue`);
    }

    if (tab === BillsTabs.Paid) {
      return t(`${translPrefixBills}.noPaid`);
    }

    return t(`${translPrefixBills}.noPending`);
  };

  const returnEmptyState = () => {
    if (!fromPropertyDashboard && (state.filters.length > 0 || state.query)) {
      return (
        <div className={styles.emptyState}>
          <EmptyState
            Icon={NoResultsIcon}
            title={getEmptyMessage(state.tab)}
            subtitle={t('emptyState.bills.withFilters.subtitle')}
            handleOnClick={clearFilters}
          />
        </div>
      );
    } if (fromPropertyDashboard && (state.appliedFilters.length > 0 || state.query)) {
      return (
        <div className={styles.emptyState}>
          <EmptyState
            Icon={NoResultsIcon}
            title={t(`${translPrefix}.queryNotFound`)}
            subtitle={t(`${translPrefix}.emptyStateSubtitle`)}
            withPlusIcon
            handleOnClick={() => goToPage(RouteName.AddBill)}
          />
        </div>
      );
    }
    return (
      <div className={styles.emptyState}>
        <EmptyState
          Icon={DollarSign}
          title={t(`${translPrefix}.emptyStateTitle`)}
          subtitle={t(`${translPrefix}.emptyStateSubtitle`)}
          iconStyle={styles.dollarIcon}
          withPlusIcon
          handleOnClick={() => goToPage(RouteName.AddBill)}
        />
      </div>
    );
  };

  return (
    <>
      {state.editBill ? (
        <EditBill
          prevBill={state.selectedBill}
          propertyId={state.billPropertyId}
          propertyType={state.billPropertyType}
          fullAddress={state.billAddress}
          finish={finishEditing}
          close={finishEditing}
          billId={state.billId}
        />
      )
        : (
          <div className={styles.billsContainer}>
            {withTabs
              && (
                <div className="row">
                  {Object.keys(BillsTabs).map((tab) => (
                    <div key={tab} className={styles.tabContainer}>
                      <Tab
                        name={t(`${translPrefix}.tabs.${tab}`)}
                        isActive={state.tab === tab}
                        onClickFn={() => tabChange(tab)}
                      />
                    </div>
                  ))}
                </div>
              )}

            {state.registerPayment && (
              <RegisterPayment
                closeFn={() => dispatch({ type: 'CLOSE_REGISTER_PAYMENT' })}
                bill={state.selectedBill}
                billId={state.billId}
              />
            )}

            {state.deleteBill && (
              <ModalResult
                title={t(`${translPrefix}.deleteModal.title`)}
                subtitle={t(`${translPrefix}.deleteModal.subtitle`)}
                Icon={Trash}
                iconStyle={styles.iconDelete}
                buttonTextRight={t(`${translPrefix}.deleteModal.delete`)}
                buttonTextLeft={t(`${translPrefix}.deleteModal.cancel`)}
                handleButtonLeft={() => dispatch({ type: 'CLOSE_DELETE_BILL' })}
                handleButtonRight={deleteBill}
                handleButtonClose={() => dispatch({ type: 'CLOSE_DELETE_BILL' })}
              />
            )}

            {state.filterModal && (
              <Modal>
                <FilterModal
                  appliedFilters={state.appliedFilters}
                  filters={state.filters}
                  closeFn={toggleModal}
                  applyFn={applyFilters}
                  prefix="maintenanceDashboard"
                />
              </Modal>
            )}

            <div className={styles.filterRow}>
              <Input
                id="search"
                containerClass={styles.input}
                inputStyle={InputStyle.REGULAR}
                withSearchIcon
                placeholder={t(`${translPrefix}.inputPlaceholder`)}
                value={state.query}
                onChange={onChangeInput}
                t={t}
              />

              <Button buttonStyle={ButtonStyle.Secondary} onClick={toggleModal}>
                <div className="row justify-between">
                  <div className="text__button__medium__primary60">
                    {t(`${translPrefix}.buttons.filter`)}
                  </div>
                  <Filter className={styles.filterIcon} />
                </div>
              </Button>

              {state.appliedFilters.length > 0
                && (
                  <FiltersApplied
                    filters={state.appliedFilters}
                    clearFn={clearFilters}
                    removeOneFilterFn={removeFilter}
                    prefix="maintenanceDashboard"
                  />
                )}

              <div className={styles.dateFilter}>
                <FilterByDate
                  onClickOption={applyDate}
                  dateFilter={(params.toString()) ? state.dateFilter : initDateFilter}
                  options={dateOptions}
                  key={state.tab}
                />
              </div>

              {(!propertyCondition || propertyCondition !== Condition.Active)
                && (
                  <Button
                    onClick={() => goToPage(RouteName.AddBill)}
                    buttonStyle={ButtonStyle.Primary}
                    disabled={propertyCondition === Condition.Archived}
                  >
                    <div className="row align-justify-center">
                      <PlusCircle className={styles.plusIcon} />
                      {t(`${translPrefix}.buttons.addBill`)}
                    </div>
                  </Button>
                )}
            </div>

            {state.paginator.results.length === 0
              ? returnEmptyState()
              : (
                <>
                  <Table
                    headerNames={fixedTableHeader || state.headers}
                    data={addAffectedAreaDisplayValue(state.paginator.results, t)}
                    dataProperties={fixedDataProperties || state.headers}
                    dashboardName={translPrefix}
                    uniqueId="id"
                    iconCell={iconCells}
                    clickable={false}
                  />
                  {state.paginator.lastPage > 1
                    && (
                      <div className={styles.paginationContainer}>
                        <Pagination
                          pageLimit={state.paginator.lastPage}
                          onClickFn={(newPage: number) => clickPagination(newPage)}
                          currentPage={state.paginator.currentPage}
                        />
                      </div>
                    )}
                </>
              )}

          </div>
        )}
    </>
  );
};

export { BillsTable };
