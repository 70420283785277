import { classnames } from '@mapix/common/src/helpers/utils';
import { ReactComponent as Dollar } from 'assets/icons/dollar.svg';
import { ReactComponent as Tool } from 'assets/icons/tool.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as Home } from 'assets/icons/home.svg';
import React, { useContext, useState, useEffect } from 'react';
import { Breakpoints } from 'common/enums';
import { Spinner } from '@mapix/common/src/common/spinner';
import {
  AppLink, AppRedirect, RouteName, goToPage,
} from 'routes';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { EmptyState } from 'common/empty-state';
import { Modal } from '@mapix/common/src/common/modal';
import { UserData } from 'pages/user-data';
import { PropertyController } from 'networking/controllers/property-controller';
import { useTranslation } from 'react-i18next';
import { SelectProperty } from 'common/select-property';
import { AppContext } from 'context';
import styles from './home-tenant.module.scss';

type CardProps = {
  title: string, subtitle: string, titleStyle: string, subtitleStyle: string,
  iconBackgroundStyle: string, Icon: any, iconStyle: string, route: RouteName,
};

const Card = ({
  title, subtitle, titleStyle, subtitleStyle,
  iconBackgroundStyle, Icon, iconStyle, route,
}: CardProps) => (
  <AppLink className={styles.card} routeName={route}>
    <div className={iconBackgroundStyle}>
      <Icon className={iconStyle} />
    </div>
    <div className={styles.column}>
      <p className={classnames(styles.title, titleStyle)}>
        {title}
      </p>
      <p className={subtitleStyle}>
        {subtitle}
      </p>
    </div>
    <ChevronRight className={styles.chevron} />
  </AppLink>
);

const HomeTenant = () => {
  const { state } = useContext(AppContext);
  const { t } = useTranslation();
  const [errorServer, setErrorServer] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [hasProperties, setHasProperties] = useState(false);
  const mobile = window.matchMedia(`(max-width: ${Breakpoints.sm}px)`).matches;
  const urlParams = new URLSearchParams(window.location.search);
  const [showUserData, setShowUserData] = useState(false);

  const getProperties = async () => {
    try {
      setFetching(true);
      const newProperties = await PropertyController.getPropertiesIdsFromTenant();
      if (newProperties.length !== 0) {
        setHasProperties(true);
      } else {
        setHasProperties(false);
      }
      setFetching(false);
    } catch (ex) {
      setFetching(false);
      setErrorServer(true);
    }
  };

  useEffect(() => {
    getProperties();
  }, []);

  useEffect(() => {
    if (urlParams.get('showModal')) {
      setShowUserData(true);
    } else {
      setShowUserData(false);
    }
  }, [urlParams.get('showModal')]);

  if (fetching) {
    return <Spinner />;
  }

  return (
    <>
      {errorServer
    && <ErrorMessage message={t('error.errorMessage')} handleClose={() => setErrorServer(false)} />}
      {showUserData && (
      <Modal>
        <UserData save={() => goToPage(RouteName.HomeTenant)} />
      </Modal>
      )}
      {!mobile && hasProperties && !showUserData && (
      // eslint-disable-next-line react/no-children-prop
      <AppRedirect routeName={RouteName.PaymentsTenant} />
      )}
      {mobile && hasProperties && !showUserData && (
      <div className={styles.container}>
        <p className={classnames(styles.marginLeft, 'text__heading3__textNeutral40')}>
          {`${t('homeTenant.title', { name: state.user.name })}`}
        </p>
        <p className={classnames(styles.showing, 'text__body__medium__textNeutral40')}>
          Showing:
        </p>
        <SelectProperty />
        <div className={styles.data}>
          <Card
            title={t('homeTenant.payments.title')}
            subtitle={t('homeTenant.payments.subtitle')}
            titleStyle="text__heading3__primary70"
            subtitleStyle="text__body__medium__textNeutral40"
            iconBackgroundStyle={styles.iconBackgroundDollar}
            Icon={Dollar}
            iconStyle={styles.dollarIcon}
            route={RouteName.PaymentsTenant}
          />
          <Card
            title={t('homeTenant.claims.title')}
            subtitle={t('homeTenant.claims.subtitle')}
            titleStyle="text__heading3__info40"
            subtitleStyle="text__body__medium__textNeutral40"
            iconBackgroundStyle={styles.iconBackgroundClaims}
            Icon={Tool}
            iconStyle={styles.claimsIcon}
                // TODO update route when pages are ready
            route={RouteName.Claims}
          />
          <Card
            title={t('homeTenant.property.title')}
            subtitle={t('homeTenant.property.subtitle')}
            titleStyle="text__heading3__yellow50"
            subtitleStyle="text__body__medium__textNeutral40"
            iconBackgroundStyle={styles.iconBackgroundHome}
            Icon={Home}
            iconStyle={styles.iconDollar}
                // TODO update route when pages are ready
            route={RouteName.MyPropertyTenant}
          />
        </div>
      </div>
      )}
      {!hasProperties && (
      <>
        <p className={classnames(styles.marginLeft, 'text__heading3__textNeutral40')}>
          {`${t('homeTenant.title', { name: state.user.name })}`}
        </p>
        <div className={styles.emptyState}>
          <EmptyState
            Icon={Home}
            title={t('homeTenant.emptyState.title')}
            subtitleWithoutLink={t('homeTenant.emptyState.subtitle')}
            iconStyle={styles.iconHome}
          />
        </div>
      </>
      )}
    </>

  );
};

export { HomeTenant };
