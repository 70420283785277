import { useEffect, useState } from 'react';
import { FileState } from '../enums';
import { classnames } from '../../helpers/utils';
import styles from './browse-files-progress-bar.module.scss';

type ProgressBarProps = {
  fileState: FileState,
  t: (text: string) => string,
  filename: string,
  refreshRateMS?: number
};

const translPrefix = 'createGeneric';

const ProgressBar = ({
  fileState, t, filename, refreshRateMS = 100,
}: ProgressBarProps) => {
  const [width, setWidth] = useState(1);

  useEffect(() => {
    const id = setInterval(() => {
      setWidth((prevState) => {
        if (prevState >= 98) {
          clearInterval(id);
        }
        return prevState + 1;
      });
    }, refreshRateMS);
    return () => clearInterval(id);
  }, []);

  const progresBarrOrError = () => {
    switch (fileState) {
      case FileState.LOADING:
        return (
          fileState === FileState.LOADING && (
            <>
              <div className={styles.progressBar}>
                <div style={{ width: `${width}%` }} className={styles.filler} />
              </div>
              <div className={classnames(styles.updating, 'text__body__tiny__textNeutral40')}>
                {`${width}% ${t(`${translPrefix}.completed`)}`}
              </div>
            </>
          )
        );
      case FileState.FAILED:
        return (<span className="text__body__tiny__danger40">{t('error.fileUpload')}</span>);
      default:
        return null;
    }
  };

  return (
    <div className={styles.progressBarWrapper}>
      <div className="text__body__xsmall__textNeutral40">
        {filename}
      </div>
      {progresBarrOrError()}
    </div>
  );
};

export { ProgressBar };
