import {
  OpenOrderDataBill,
  OpenOrderDataRental,
  OpenOrderResponse,
  PaymentMethod,
  RawOpenOrderDataBill, RawOpenOrderDataRental,
  RawUpdatePaymentBill,
  RawUpdatePaymentRental,
  UpdatePaymentBill,
  UpdatePaymentRental,
} from '@mapix/common/src/types';

class CreatePaymentSerializer {
  static serializeOpenOrderRental(data: OpenOrderDataRental): RawOpenOrderDataRental {
    return {
      rental_id: data.rentalId,
      amount: data.amount,
      currency: data.currency,
    };
  }

  static serializeOpenOrderBill(data: OpenOrderDataBill): RawOpenOrderDataBill {
    return {
      new_bill_id: data.billId,
      amount: data.amount,
      currency: data.currency,
    };
  }

  static deserialize(data: RawOpenOrderResponse): OpenOrderResponse {
    return {
      id: data.id,
      userTokenId: data.user_token_id,
      sessionToken: data.session_token,
      merchantId: data.merchant_id,
      merchantSiteId: data.merchant_site_id,
    };
  }

  static serializeUpdatePaymentRental(data: UpdatePaymentRental): RawUpdatePaymentRental {
    return {
      id: data.id,
      status: data.status,
      error_code: data.errorCode,
      last_four_numbers: data.lastFourNumbers,
      expiration_month: data.expirationMonth,
      expiration_year: data.expirationYear,
      user_payment_option_id: data.userPaymentOptionId,
      rental_id: data.rentalId,
      reason: data.reason,
      transaction_id: data.transactionId,
    };
  }

  static serializeUpdatePaymentBill(data: UpdatePaymentBill): RawUpdatePaymentBill {
    return {
      id: data.id,
      status: data.status,
      error_code: data.errorCode,
      last_four_numbers: data.lastFourNumbers,
      expiration_month: data.expirationMonth,
      expiration_year: data.expirationYear,
      user_payment_option_id: data.userPaymentOptionId,
      new_bill_id: data.billId,
      reason: data.reason,
      transaction_id: data.transactionId,
    };
  }

  static deserializePaymentMethod(data: RawPaymentMethod): PaymentMethod {
    return {
      id: data.id,
      lastFourNumbers: data.last_four_numbers,
      expirationMonth: data.expiration_month,
      expirationYear: data.expiration_year,
      userPaymentOptionId: data.user_payment_option_id,
    };
  }
}

export { CreatePaymentSerializer };
