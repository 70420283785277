import { useReducer, useEffect, useState } from 'react';
import { CreateHeader, CreateFooter } from 'common/creation';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Tool } from 'assets/icons/tool.svg';
import { ModalResult } from 'common/modal-result';
import { classnames } from '@mapix/common/src/helpers/utils';
import { MaintenanceController } from 'networking/controllers/maintenance-controller';
import { logger } from 'helpers/logger';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { Spinner } from '@mapix/common/src/common/spinner';
import { PropertyController } from 'networking/controllers/property-controller';
import { Property } from 'models/property';
import { ReactComponent as AlertFilled } from 'assets/icons/alertFilled.svg';
import { Task } from 'models/task';
import { AssignContractorContainer } from 'common/assign-contractor-container';
import { useHistory } from 'react-router-dom';
import { CreateTaskStep1 } from './create-task-step-1';
import { CreateTaskStep2 } from './create-task-step-2';
import { CreateTaskReducer, initialState } from './crete-task-reducer';
import styles from './create-task.module.scss';

const translPrefix = 'createTask';
const translError = 'error';

type CreateTaskState = {
  code: string,
  showError: boolean,
  property?: Property,
  task?: Task,
};

const initialStepState: CreateTaskState = {
  code: 'INITIAL',
  showError: false,
};

const CreateTask = () => {
  const params = new URLSearchParams(window.location.search);
  const id = params.get('id');
  const history = useHistory();

  const [state, dispatch] = useReducer(CreateTaskReducer, initialState(Number(id)));
  const [stepState, setStepState] = useState(initialStepState);
  const [fromPropertyDashboard, setFromPropertyDashboard] = useState(false);
  const { t } = useTranslation();

  const close = () => {
    history.goBack();
  };

  const setFinishModal = (modalLater: boolean) => {
    if (modalLater) {
      dispatch({ type: 'FINISH_MODAL_ASSIGN_LATER' });
    } else if (state.email || state.sms) {
      dispatch({ type: 'FINISH_MODAL_ASSIGN_TASK' });
    } else {
      dispatch({ type: 'OPTION_NOTIFY_NOT_SELECTED', optionNotifyNotSelected: true });
    }
  };

  const toggleErrorModal = () => {
    setStepState((prevState) => ({ ...prevState, showError: !stepState.showError }));
  };

  const setContractorData = (field: string, value: string) => {
    dispatch({
      type: 'CHANGE_FIELD', field, value,
    });
  };

  const setContactMethod = (field: string, value: boolean) => {
    dispatch({
      type: 'CHANGE_CONTACT_METHOD', field, value,
    });
  };

  const assignLaterButton = () => {
    setContactMethod('email', false);
    setFinishModal(true);
  };

  const saveTask = async (task: SerializedCreateTask) => {
    try {
      await MaintenanceController.newTask(task);
    } catch (err) {
      dispatch({ type: 'FINISH_ERROR' });
      logger.error(err as Error);
    }
  };

  const getProperty = async (propertyId: string) => {
    setStepState((prevState) => ({ ...prevState, code: 'FETCHING' }));
    try {
      const fetchedProperty = await PropertyController.getDetailedProperty(propertyId);
      setStepState((prevState) => (
        {
          ...prevState,
          code: 'FROM_DASHBOARD',
        }));
      dispatch({
        type: 'STEPS', steps: ['Complete task info', 'Assign to a contractor'],
      });
      dispatch({ type: 'GO_NEXT' });
      const address = fetchedProperty.address.fullAddress;
      const idProperty = fetchedProperty.id;
      const { type } = fetchedProperty;
      dispatch({
        type: 'PROPERTY_CHANGED', propertyAddress: address || '', buildingType: type === 'Building', propertyId: idProperty,
      });
    } catch (err: any) {
      logger.error(err);
      toggleErrorModal();
    }
  };

  useEffect(() => {
    if (state.finish) {
      const task: SerializedCreateTask = {
        category: state.taskCategory,
        propertyId: state.propertyId,
        startDate: state.issueDate,
        description: state.description,
        priority: state.priority,
        unitId: state.specificUnit ? state.specificUnit : undefined,
        contractorId: (state.contractorId && !state.modalAssignLater)
          ? state.contractorId : undefined,
        additionalInformation: (state.infoContractor && !state.modalAssignLater)
          ? state.infoContractor : undefined,
        email: state.modalAssignLater ? false : state.email,
        sms: state.modalAssignLater ? false : state.sms,
        taskPhoto: state.taskPhoto,
      };
      saveTask(task);
    }
  }, [state.finish]);

  useEffect(() => {
    if (id) {
      setFromPropertyDashboard(true);
      getProperty(id);
    }
  }, []);

  if (stepState.code === 'FETCHING') {
    return (<Spinner />);
  }

  return (
    <div className={styles.container}>
      <CreateHeader
        closeText={t('createGeneric.close')}
        title={t('createTask.title')}
        closeFn={close}
        currentStep={state.step}
        stepper={state.steps.map((step) => t(step))}
      />
      {state.errorModal
      && (
      <ErrorMessage
        handleClose={() => dispatch({ type: 'CLOSE_ERROR' })}
        message={t(`${translError}.errorMessage`)}
      />
      )}
      {state.modalAssignTask
      && (
      <>
        { (state.contractorId) && (
        <ModalResult
          title={t(`${translPrefix}.modalTitle`)}
          subtitle={t(`${translPrefix}.modalContractorAssigned`)}
          Icon={Tool}
          withCheckIcon
          buttonTextRight={t(`${translPrefix}.modalButton`)}
          handleButtonRight={close}
          handleButtonClose={close}
        />
        )}
        { (!state.contractorId) && (
        <ModalResult
          title={t(`${translPrefix}.modalAssignError.title`)}
          subtitle={t(`${translPrefix}.modalAssignError.subtitle`)}
          Icon={Tool}
          iconStyle={styles.iconStyle}
          buttonTextRight={t(`${translPrefix}.modalButton`)}
          handleButtonRight={() => dispatch({ type: 'MODAL_ASSIGN_TASK', modalAssignTask: false })}
          handleButtonClose={() => dispatch({ type: 'MODAL_ASSIGN_TASK', modalAssignTask: false })}
        />
        )}
      </>
      )}
      {
        state.modalAssignLater && (
          <ModalResult
            title={t(`${translPrefix}.modalTitle`)}
            subtitle={t(`${translPrefix}.modalContractorNotAssigned`)}
            Icon={Tool}
            withCheckIcon
            buttonTextRight={t(`${translPrefix}.modalButton`)}
            handleButtonRight={close}
            handleButtonClose={close}
          />
        )
      }

      {(state.step === 0)
      && (
      <CreateTaskStep1
        dispatch={dispatch}
        propertyAddress={state.propertyAddress}
        show={state.show}
        query={state.query}
        data={state.data}
      />
      )}

      {state.step === 1
      && (
      <CreateTaskStep2
        dispatch={dispatch}
        propertyAddress={state.propertyAddress}
        taskCategory={state.taskCategory}
        buildingType={state.buildingType}
        priority={state.priority}
        issueDate={state.issueDate}
        description={state.description}
        formErrors={state.formErrors}
        area={state.area}
        taskPhoto={state.taskPhoto}
        propertyId={state.propertyId}
        unitNumber={state.unitNumber}
        fromPropertyDashboard={fromPropertyDashboard}
        isValidUnitNumber={state.area !== 'specific' || !!state.unitNumber}
      />
      )}

      {state.step === 2
      && (
        <>
          <AssignContractorContainer
            category={state.taskCategory}
            propertyId={state.propertyId}
            onChange={setContractorData}
            onChangeContactMethod={setContactMethod}
            infoContractor={state.infoContractor}
            sms={state.sms}
            email={state.email}
          />
          {state.optionNotifyNotSelected && (
          <div className={classnames(styles.alertNotifyOptions, 'text__body__small__danger50')}>
            <AlertFilled className={styles.alertNotifyIcon} />
            {t(`${translPrefix}.alertSelectNotifyOptions`)}
          </div>
          )}
          <CreateFooter
            nextName={t('createTask.footer.assignTask')}
            showBack
            Icon={null}
            secondButtonName={t('createTask.footer.assignLater')}
            backFn={() => dispatch({ type: 'GO_BACK' })}
            nextFn={() => setFinishModal(false)}
            secondButtonFn={assignLaterButton}
          />
        </>
      )}

    </div>
  );
};

export { CreateTask };
