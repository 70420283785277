import { Dispatch, useEffect, useState } from 'react';
import { Table } from 'common/table';
import { Unit } from 'models/unit';
import { UnitForm } from 'pages/create-property/create-building/unit-form';
import { CreateFooter } from 'common/creation';
import { ReactComponent as Trash } from 'assets/icons/thrash.svg';
import { useTranslation } from 'react-i18next';
import { classnames } from '@mapix/common/src/helpers/utils';
import { ReactComponent as EditPencil } from 'assets/icons/edit-black.svg';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { Action, BuildingData } from './create-property-building-reducer';
import styles from './create-property-building.module.scss';

const headers = ['unitNumber', 'status'];
type CreatePropertyBuildingStep2Props = {
  dispatch: Dispatch<Action>,
  units: Unit[],
  building: BuildingData,
};
const footerTranslPrefix = 'createBuilding.footer';
const unitTranslPrefix = 'units';

const CreatePropertyBuildingStep2 = ({ dispatch, units, building }:
CreatePropertyBuildingStep2Props) => {
  const [repeatedUnit, setRepeatedUnit] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState<Unit | undefined>();
  const [unitModified, setUnitModified] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const { t } = useTranslation();

  const handleEdit = (item: Unit) => {
    setSelectedUnit(item);
    setErrorMsg('');
  };

  const iconCells = (item: Unit) => (
    [
      <EditPencil onClick={() => handleEdit(item)} className={styles.trash} />,
      <Trash onClick={() => dispatch({ type: 'ADD_REMOVE_UNIT', unit: item })} className={styles.trash} />,
    ]
  );

  const emptyState = (
    <div className="text__body__medium__textNeutral40">
      {t(`${unitTranslPrefix}.emptyState`)}
    </div>
  );

  const checkForExcessUnits = () => {
    if (Number(building.unitsCount) === units.length) {
      setErrorMsg(t('createUnit.maxNumErrorMsg'));
    } else if (building.unitsCount < units.length) {
      setErrorMsg(t('createUnit.deleteUnitsErrorMsg'));
    } else {
      setErrorMsg('');
    }
  };

  const saveUnit = (newUnit: Unit) => {
    setRepeatedUnit(false);
    if (!selectedUnit && units.some((unit) => unit.unitNumber === newUnit.unitNumber)) {
      setRepeatedUnit(true);
    } else {
      dispatch({ type: 'ADD_REMOVE_UNIT', unit: newUnit, previousUnit: selectedUnit });
      setSelectedUnit(undefined);
    }
    checkForExcessUnits();
  };

  const handleStopEditing = () => {
    setSelectedUnit(undefined);
    checkForExcessUnits();
  };

  useEffect(() => {
    checkForExcessUnits();
  }, [building.unitsCount, units.length]);

  const invalidNumOfUnits = building.unitsCount < units.length;

  return (
    <div className={styles.step2}>
      <Table
        clickable={false}
        iconCell={iconCells}
        dashboardName="units"
        uniqueId="id"
        headerNames={headers}
        data={units}
        dataProperties={headers}
        emptyState={emptyState}
      />

      <div className={!errorMsg ? 'text__button__large__textNeutral20' : styles.unitForm}>
        <UnitForm
          fetchedUnit={selectedUnit}
          saveUnitFn={saveUnit}
          repeated={repeatedUnit}
          setUnitModified={setUnitModified}
          unitModified={unitModified}
          isSaveButtonDisabled={!!errorMsg}
          clearUnit={selectedUnit === undefined}
        />
      </div>

      {selectedUnit && (
        <div className={classnames(styles.errorContainer, 'text__body__medium__textNeutral40')}>
          <span>
            {t(`${unitTranslPrefix}.editingUnit`, { number: selectedUnit.unitNumber })}
            <Button
              onClick={() => handleStopEditing()}
              buttonStyle={ButtonStyle.Link}
            >
              {t(`${unitTranslPrefix}.stopEditing`)}
            </Button>
          </span>
        </div>
      )}

      {errorMsg && (
        <div className={classnames(styles.errorContainer, 'text__body__tiny__textNeutral40')}>
          <span>{errorMsg}</span>
        </div>
      )}

      <CreateFooter
        nextName={t(`${footerTranslPrefix}.contractors`)}
        showBack
        backFn={() => dispatch({ type: 'GO_BACK' })}
        nextFn={() => dispatch({ type: 'GO_NEXT' })}
        disableNextCondition={unitModified || invalidNumOfUnits}
      />

    </div>
  );
};

export { CreatePropertyBuildingStep2 };
