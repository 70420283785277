import { useTranslation } from 'react-i18next';
import { ButtonClose } from '@mapix/common/src/common/button-close';
import { useHistory, useParams } from 'react-router-dom';
import { Table } from 'common/table';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import { checkHTMLErrors, classnames, HTMLValidationError } from '@mapix/common/src/helpers/utils';
import {
  ChangeEvent, FormEvent, useEffect, useState,
} from 'react';
import { InputDate } from '@mapix/common/src/common/input-date';
import dayjs from 'dayjs';
import { RadioButton } from 'common/radio-button';
import { CreateFooter } from 'common/creation';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { ButtonType } from '@mapix/common/src/common/button';
import { logger } from 'helpers/logger';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { ModalResult } from 'common/modal-result';
import { ReactComponent as CheckCircle } from 'assets/icons/check-circle.svg';
import { EditRental as EditRentalModel } from 'models/edit-rental';
import { RentalDashboard } from 'models/rental-dashboard';
import { RentalsController } from 'networking/controllers/rentals-controller';
import { Spinner } from '@mapix/common/src/common/spinner';
import styles from './edit-rental.module.scss';

type ParamType = {
  id: string,
};

enum RadioAnswer {
  No = 'NO',
  Yes = 'YES',
}

type EditState = {
  radioAnswer: RadioAnswer,
  showError: boolean,
  showModal: boolean
};

const initState: EditState = {
  radioAnswer: RadioAnswer.No,
  showError: false,
  showModal: false,
};

const headers = ['unit', 'tenant', 'billedAmount', 'dueDate'];
const translPrefix = 'editRental';
const EditRental = () => {
  const [fetchedRental, setFetchedRental] = useState<RentalDashboard | null>(null);
  const [formRental, setFormRental] = useState<EditRentalModel>(new EditRentalModel(null));
  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});
  const [state, setState] = useState<EditState>(initState);

  const { id } = useParams<ParamType>();

  const { t } = useTranslation();
  const history = useHistory();

  const mapToForm = (fetched: RentalDashboard) => {
    const rental = { ...formRental };
    rental.dueDate = fetched.dueDate;
    rental.currency = fetched.currency;
    rental.rentalAmount = fetched.rentalAmount;
    if (fetched.paidOn && fetched.paidOn !== '-') {
      rental.paymentDate = fetched.paidOn;
      setState((prevState) => ({ ...prevState, radioAnswer: RadioAnswer.Yes }));
    }
    setFormRental(rental);
  };

  const getRental = async () => {
    try {
      const response = await RentalsController.getRental(Number(id));
      setFetchedRental(response);
      mapToForm(response);
    } catch (err: any) {
      logger.error(err);
    }
  };

  useEffect(() => {
    getRental();
  }, []);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormRental((prevState) => ({ ...prevState, [e.target.id]: e.target.value }));
  };

  const inputDateValue = () => {
    if (state.radioAnswer === RadioAnswer.Yes) {
      return (formRental.paymentDate ? dayjs(formRental.paymentDate).toDate() : undefined);
    }
    return undefined;
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    if (!target.checkValidity()) {
      setFormErrors(checkHTMLErrors(target));
    } else {
      try {
        const serializedPatch: SerializedPatchRental = {
          rentalAmount: formRental.rentalAmount,
          paymentDate: state.radioAnswer === RadioAnswer.No ? null
            : formRental?.paymentDate,
          dueDate: formRental.dueDate,
        };
        await RentalsController.editRental(Number(id), serializedPatch);
        setState((prevState) => ({ ...prevState, showModal: true }));
      } catch (err: any) {
        logger.error(err);
        setState((prevState) => ({ ...prevState, showError: true }));
      }
    }
  };

  if (!fetchedRental) {
    return (<Spinner />);
  }

  return (
    <form noValidate onSubmit={onSubmit} className={styles.container}>

      {state.showError
      && (
        <ErrorMessage
          message={t('error.errorMessage')}
          handleClose={() => setState((prevState) => ({ ...prevState, showError: false }))}
        />
      )}

      {state.showModal && (
        <ModalResult
          title={t(`${translPrefix}.modal.title`)}
          subtitle={t(`${translPrefix}.modal.subtitle`)}
          Icon={CheckCircle}
          buttonTextRight={t(`${translPrefix}.modal.ok`)}
          handleButtonRight={() => history.goBack()}
          handleButtonClose={() => history.goBack()}
          withCheckIcon
        />
      )}

      <div className={styles.close}>
        <ButtonClose closeFn={() => history.goBack()} closeText={t(`${translPrefix}.close`)} />
      </div>
      <div className={classnames(styles.title, 'text__heading4__textNeutral50')}>
        {t(`${translPrefix}.title`)}
      </div>
      <div className={classnames(styles.subtitle, 'text__heading5__textNeutral50')}>
        {fetchedRental?.address}
      </div>

      <Table
        headerNames={headers}
        data={[fetchedRental]}
        dataProperties={headers}
        dashboardName={translPrefix}
        uniqueId="unit"
      />

      <div className={styles.formContainer}>

        <div className={styles.inputRow}>

          <Input
            id="rentalAmount"
            name="rentalAmount"
            label={t(`${translPrefix}.rentalAmount`)}
            placeholder={t(`${translPrefix}.rentalAmount`)}
            helperText={fetchedRental.currency}
            value={formRental.rentalAmount}
            type="number"
            onChange={onChange}
            inputStyle={InputStyle.FORM}
            containerClass={styles.input}
            formError={formErrors}
            t={t}
          />

          <InputDate
            id="dueDate"
            name="dueDate"
            label={t(`${translPrefix}.dueDate`)}
            placeholder={t(`${translPrefix}.dueDate`)}
            value={dayjs(formRental.dueDate).toDate()}
            formErrors={formErrors}
            onChangeFn={(date: Date) => setFormRental((prevState) => (
              { ...prevState, dueDate: (dayjs(date).format('YYYY-MM-DD')) }))}
            helperText="DD/MM/YYYY"
            t={t}
          />

        </div>

        <p className={classnames(styles.label, 'text__heading6__textNeutral50')}>
          {t(`${translPrefix}.alreadyPaid`)}
        </p>

        <div className={styles.radioRow}>
          <RadioButton
            name="paid"
            onChangeFn={() => setState(
              (prevState) => ({ ...prevState, radioAnswer: RadioAnswer.No }),
            )}
            labelContent={t(`${translPrefix}.no`)}
            containerClass={styles.radioNo}
            checked={state.radioAnswer === RadioAnswer.No}
          />

          <RadioButton
            name="paid"
            onChangeFn={() => setState(
              (prevState) => ({ ...prevState, radioAnswer: RadioAnswer.Yes }),
            )}
            labelContent={t(`${translPrefix}.yes`)}
            containerClass={styles.radioYes}
            checked={state.radioAnswer === RadioAnswer.Yes}
          />

          <InputDate
            id="paymentDate"
            name="paymentDate"
            label={t(`${translPrefix}.paymentDate`)}
            value={inputDateValue()}
            formErrors={formErrors}
            onChangeFn={(date: Date) => setFormRental(
              (prevState) => ({ ...prevState, paymentDate: dayjs(date).format('YYYY-MM-DD') }),
            )}
            helperText="DD/MM/YYYY"
            containerClass={styles.input}
            disabled={state.radioAnswer === RadioAnswer.No}
            t={t}
          />

        </div>

      </div>

      <CreateFooter
        nextName={t(`${translPrefix}.save`)}
        Icon={Check}
        iconStyle={styles.check}
        nextButtonType={ButtonType.Submit}
      />

    </form>
  );
};

export { EditRental };
