import { useEffect, useState } from 'react';
import { classnames } from '../../helpers/utils';
import { ReactComponent as Check } from '../../assets/icons/check-filled.svg';
import styles from './progress-bar.module.scss';

type ProgressBarProps = {
  loading: boolean,
  t: (text: string) => string,
};

const translPrefix = 'createGeneric';

const ProgressBar = ({ loading, t }: ProgressBarProps) => {
  const [width, setWidth] = useState(1);

  useEffect(() => {
    const id = setInterval(() => {
      setWidth((prevState) => {
        if (prevState === 98) {
          clearInterval(id);
        }
        return prevState + 1;
      });
    }, 30);
  }, []);
  return (
    <>
      {!loading
        ? (
          <div className="row align-justify-center">
            <div className="text__body__small bold">{t(`${translPrefix}.uploaded`)}</div>
            {' '}
            <Check className={styles.check} />
          </div>
        )
        : (
          <div className={classnames(styles.progressBarContainer, 'row align-justify-center')}>
            <div className={classnames(styles.updating, 'text__body__small__textNeutral40')}>
              {t(`${translPrefix}.uploading`)}
            </div>
            <div className={styles.progressBar}>
              <div style={{ width: `${width}%` }} className={styles.filler} />
            </div>
            <div className="text__body__small__textNeutral40">
              {width}
              %
            </div>
          </div>
        )}
    </>
  );
};

export { ProgressBar };
