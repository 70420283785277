import { PropertyOwner } from 'models/property-owner';
import { Services } from 'models/services';
import { Currency } from 'common/enums';

class Lease {
  id: number;

  tenant: PropertyOwner;

  services: Services;

  startDate: string;

  endDate?: string;

  expirationDate: string;

  currency: string;

  monthlyRental: number;

  yearlyRental: number;

  fees?: number;

  size: string;

  leaseCopy: FileType | null;

  tenantIdPhotos: FileType[];

  unitPhotos: FileType [];

  status?: string;

  extensionDate: string | null;

  constructor(params: SerializedLease | null) {
    this.id = params?.id || 0;
    this.tenant = new PropertyOwner(params?.tenant || null);
    this.services = new Services(params?.services || null);
    this.startDate = params?.startDate || '';
    this.expirationDate = params?.expirationDate || '';
    this.currency = params?.currency || '';
    this.monthlyRental = params?.monthlyRental || 0;
    this.fees = params?.fees;
    this.size = params?.size || '';
    this.leaseCopy = params?.leaseCopy || null;
    this.tenantIdPhotos = params?.tenantIdPhotos || [];
    this.unitPhotos = params?.unitPhotos || [];
    this.yearlyRental = 0;
    this.status = params?.status || '';
    this.extensionDate = params?.extensionDate || null;
  }
}

export { Lease, Currency };
