import {
  ChangeEvent,
  Fragment,
  useState,
  SetStateAction,
} from 'react';
import { useMediaQuery } from '../../hooks/use-media-query';
import { ReactComponent as UploadCloud } from '../../assets/icons/upload-cloud.svg';
import styles from './browse-files.module.scss';
import { ImageOverlay, ImageOverlayObject } from '../image-overlay/image-overlay';
import { UploadItemCompact } from '../upload-item-compact';
import {
  addError, addOrRemoveFromArrayObject, classnames,
  getDisplayFileSize, helperTextFn, HTMLValidationError, removeError,
} from '../../helpers/utils';
import { FileType, ExtendedFile } from '../../types';
import { Breakpoints, FileState, InputStyle } from '../enums';

type ImageUploadProps = {
  id: string,
  title: string
  addFile: (file: FileType) => void,
  removeFile: (file?: FileType) => void,
  fetchedFiles: FileType[] | null,
  disabled?: boolean,
  required?: boolean,
  uploadFile: (file: ExtendedFile, nuvei: boolean) => Promise<string>,
  t: (text: string, object?: {}) => string,
  pdfMaxSize: number,
  imageMaxSize: number,
  onlyOneFile?: boolean,
  formErrors: HTMLValidationError,
  setFormErrors: (value: SetStateAction<HTMLValidationError>) => void,
  helperText?: string,
  plainHelperText?: boolean
};

const initOverlayState: ImageOverlayObject = {
  show: false,
  index: 0,
  photos: [],
};

const BrowseFiles = ({
  id, title = '', addFile, removeFile, fetchedFiles,
  disabled = false, required = false, uploadFile, t, pdfMaxSize, imageMaxSize, onlyOneFile,
  formErrors, setFormErrors, helperText, plainHelperText = false,
}: ImageUploadProps) => {
  const [pendingFiles, setPendingFiles] = useState<ExtendedFile[]>([]);
  const [overlayState, setOverlayState] = useState<ImageOverlayObject>(initOverlayState);
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const sizeError = (fileName: string, isPdf: boolean) => t(
    'error.fileSize',
    { fileName, maxSize: getDisplayFileSize(isPdf ? pdfMaxSize : imageMaxSize) },
  );

  const serverError = t('error.fileServer');

  const showOverlay = (index: number, photos: FileType[]) => {
    setOverlayState({ index, photos, show: true });
  };

  const hideOverlay = () => {
    setOverlayState((prevState) => ({ ...prevState, show: false }));
  };

  const cancelFileUpload = (file: FileType) => {
    setPendingFiles((prevState) => {
      const cancelledFile = prevState.find((item) => item.filename === file.filename);
      if (cancelledFile) {
        const updatedPrevState = addOrRemoveFromArrayObject(prevState, cancelledFile, 'filename');
        cancelledFile.state = FileState.CANCELLED;
        return [...updatedPrevState, cancelledFile];
      }
      return prevState;
    });
  };

  const onFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const file: ExtendedFile = {
        filename: e.target.files[0]?.name,
        state: FileState.LOADING,
        resource: e.target.files[0],
        url: '',
      };
      if ((file.resource && file.resource?.size > imageMaxSize && file.resource?.type !== 'application/pdf')
       || (file.resource && file.resource?.size > pdfMaxSize && file.resource?.type === 'application/pdf')) {
        addError(sizeError(file.filename, file.resource?.type === 'application/pdf'), id, setFormErrors);
      } else {
        removeError(id, setFormErrors);

        setPendingFiles((prevState) => [...prevState, file]);
        try {
          const result = await uploadFile(file, true);
          const uploadedFile: FileType = { filename: file.filename, url: result };
          setPendingFiles((prevState) => {
            if (prevState.find((item) => item.url === file.url)?.state !== FileState.CANCELLED) {
              addFile(uploadedFile);
            }
            return prevState.filter((item) => item !== file);
          });
        } catch (ex) {
          addError(serverError, id, setFormErrors);
        }
      }
    }
  };

  const uploadingFiles = pendingFiles.filter(
    (file) => file.state !== FileState.CANCELLED,
  )?.length === 0;

  const handleHelperTextClassname = () => classnames(
    styles.helperText,
    `text__body__${mobile ? 'tiny' : 'small'}__${formErrors?.[id] ? 'danger50' : 'textNeutral40'}`,
  );

  return (
    <div className={styles.browserContainer}>

      {overlayState.show && (
        <ImageOverlay
          photos={overlayState.photos}
          selectedIndex={overlayState.index}
          closeFn={hideOverlay}
          t={t}
        />
      )}
      {title && (
      <div className={classnames(styles.title, `text__body__${mobile ? 'small' : 'large'}__textNeutral40`)}>
        {title}
      </div>
      )}

      { ((uploadingFiles && !fetchedFiles?.length) || !onlyOneFile) && (
      <>
        <label
          className={styles.label}
          htmlFor={id}
        >

          <div className={styles.browseFiles}>
            <UploadCloud className={styles.uploadCloud} />
            <div className={classnames('text__body__medium__info30', styles.browseFilesText)}>
              {t('fileUpload.browseFiles')}
            </div>
          </div>

          <input
            accept="application/pdf, image/jpeg, image/png"
            onChange={onFileUpload}
            id={id}
            className={styles.input}
            type="file"
            disabled={disabled}
            required={required}
          />
        </label>
        <div className={handleHelperTextClassname()}>
          {formErrors?.[id]
            ? formErrors?.[id]
            : helperTextFn(required, helperText || '', t, plainHelperText, undefined, InputStyle.FORM)}
        </div>
      </>
      )}

      <div className={styles.photoRow}>
        {!!fetchedFiles?.length && fetchedFiles.map((file, index) => (
          <Fragment key={file.url + file.filename}>
            <UploadItemCompact
              onClickFn={() => showOverlay(index, fetchedFiles)}
              removeFn={() => removeFile(file)}
              filename={file.filename}
              url={file.url}
              t={t}
            />
          </Fragment>
        ))}
        {!!pendingFiles.length && pendingFiles?.map((file) => (
          <UploadItemCompact
            removeFn={() => cancelFileUpload(file)}
            fileState={file.state}
            filename={file.filename}
            t={t}
            key={file.url + file.filename}
          />
        ))}
      </div>
    </div>
  );
};

export { BrowseFiles };
