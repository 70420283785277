import { ChangeEvent } from 'react';
import { useMediaQuery } from '../../hooks/use-media-query';
import { Input } from '../input';
import { HTMLValidationError } from '../../helpers/utils';
import { Breakpoints, InputStyle } from '../enums';
import styles from './completion-price-and-comments.module.scss';
import { TextArea } from '../textarea';

type CompletionAndPriceInputsProps = {
  completion: number | '',
  onChangeCompletion: (completion: number | '') => void,
  t: (text: string) => string,
  formErrors: HTMLValidationError,
  totalTaskPrice: number,
  comments: string,
  onChangeComments: (comments: string) => void,
};

const translPrefix = 'completionPriceAndComments';

const CompletionPriceAndComments = ({
  completion, onChangeCompletion, t, formErrors, totalTaskPrice, comments,
  onChangeComments,
}: CompletionAndPriceInputsProps) => {
  const getPaymentValue = (completionPercentage: number) => (
    (totalTaskPrice * Number(completionPercentage)) / 100
  );

  const payment = completion ? getPaymentValue(Number(completion)) : '';

  const handleCompletionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onChangeCompletion(value !== '' ? Number(value) : '');
  };

  const handleCommentsChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    onChangeComments(value);
  };

  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  return (
    <>
      <div className={styles.completionAndPriceWrapper}>
        <Input
          type="number"
          step="1"
          id="completionPercentage"
          t={t}
          value={completion}
          onChange={handleCompletionChange}
          inputStyle={InputStyle.FORM}
          placeholder={t(`${translPrefix}.completion`)}
          label={t(`${translPrefix}.completion`)}
          formError={formErrors}
          required
          min={1}
          max={99}
        />
        <Input
          type="number"
          id="payment"
          t={t}
          value={payment}
          inputStyle={InputStyle.FORM}
          placeholder={t(`${translPrefix}.payment`)}
          label={t(`${translPrefix}.payment`)}
          onChange={() => {}}
          price
          disabled
        />
      </div>
      <div className={mobile
        ? 'text__body__small__textNeutral30' : 'text__body__xsmall__textNeutral30'}
      >
        {t(`${translPrefix}.automaticPaymentCalculationDisclaimer`)}
      </div>
      <TextArea
        containerClass="mt-20 mb-20"
        id="cancellationMessage"
        t={t}
        formError={formErrors}
        value={comments}
        onChange={handleCommentsChange}
        placeholder={t(`${translPrefix}.comments`)}
        label={t(`${translPrefix}.comments`)}
        required
        maxLength={200}
      />
    </>

  );
};

export { CompletionPriceAndComments };
