import { ContractorSerializer } from 'networking/serializers/contractor-serializer';
import { UnitSerializer } from 'networking/serializers/unit-serializer';
import { LeaseSerializer } from 'networking/serializers/lease-serializer';
import { Property } from 'models/property';
import { PropertyAddressSerializer } from './property-address-serializer';
import { PropertyOwnerSerializer } from './property-owner-serializer';

class PropertySerializer {
  static deSerialize(data: RawProperty): SerializedProperty {
    return {
      condition: data.condition,
      id: data.id,
      buildingName: data.building_name,
      type: data.type,
      unitsCount: data.units_count,
      status: data.status,
      address: PropertyAddressSerializer.deSerialize(data.address),
      owner: data.owner ? PropertyOwnerSerializer.deSerialize(data.owner) : undefined,
      otherOwner: data.other_owner
        ? PropertyOwnerSerializer.deSerialize(data.other_owner) : undefined,
      lease: data.lease ? LeaseSerializer.deSerialize(data.lease) : undefined,
      contractors: data.contractors
        ? data.contractors.map((contractor) => ContractorSerializer.deSerialize(contractor)) : [],
      occupancyRate: data.occupancy_rate,
      overdueRentalsCount: data.overdue_rentals_count,
      rentedUnitsCount: data.rented_units_count,
      inProgressTasksCount: data.in_progress_tasks_count,
      newTasksCount: data.new_tasks_count,
    };
  }

  static serialize(data: SerializedProperty): RawProperty {
    return {
      other_owner: data.otherOwner ? PropertyOwnerSerializer.serialize(data.otherOwner) : undefined,
      owner: data.owner ? PropertyOwnerSerializer.serialize(data.owner) : undefined,
      address: PropertyAddressSerializer.serialize(data.address),
      building_name: data.buildingName,
      units_count: data.unitsCount,
      condition: data.condition,
      type: data.type,
      lease: data.lease ? LeaseSerializer.serialize(data.lease) : undefined,
      status: data.status,
      contractors: data.contractors
        ? data.contractors.map((contractor) => ContractorSerializer.serialize(contractor)) : [],
      occupancy_rate: data.occupancyRate,
      rented_units_count: data.rentedUnitsCount,
    };
  }

  static serializeCreateBuilding(data: SerializedCreateBuilding): RawCreateBuilding {
    return {
      other_owner: data.otherOwner ? PropertyOwnerSerializer.serialize(data.otherOwner) : undefined,
      owner: PropertyOwnerSerializer.serialize(data.owner),
      address: PropertyAddressSerializer.serialize(data.address),
      building_name: data.buildingName,
      units_count: data.unitsCount,
      condition: data.condition,
      type: data.type,
      contractors: data.contractors
        ? data.contractors.map((contractor) => ContractorSerializer.serialize(contractor))
        : undefined,
      units: data.units.map((unit) => UnitSerializer.serialize(unit)),
      id: data.id,
    };
  }

  static serializeCreateCondo(data: SerializedCreateCondo): RawCreateCondo {
    return {
      other_owner: data.otherOwner ? PropertyOwnerSerializer.serialize(data.otherOwner) : undefined,
      owner: PropertyOwnerSerializer.serialize(data.owner),
      address: PropertyAddressSerializer.serialize(data.address),
      condition: data.condition,
      lease: data.lease ? LeaseSerializer.serialize(data.lease) : undefined,
      status: data.status,
      type: data.type,
      id: data.id,
      contractors: data.contractors
        ? data.contractors.map((contractor) => ContractorSerializer.serialize(contractor))
        : undefined,
    };
  }

  static serializeArchive(condition: string) {
    return {
      condition,
    };
  }

  static serializeEdit(data: Property): RawProperty {
    return {
      other_owner: data.otherOwner ? PropertyOwnerSerializer.serialize(data.otherOwner) : undefined,
      owner: PropertyOwnerSerializer.serialize(data.owner),
      id: data.id,
      address: PropertyAddressSerializer.serialize(data.address),
      building_name: data.buildingName,
      units_count: data.unitsCount,
      condition: data.condition,
      type: data.type,
      contractors: data.contractors
        ? data.contractors.map((contractor) => ContractorSerializer.serialize(contractor)) : [],
    };
  }

  static serializeEditCondo(data: Property): RawEditCondo {
    return {
      other_owner: data.otherOwner ? PropertyOwnerSerializer.serialize(data.otherOwner) : undefined,
      owner: PropertyOwnerSerializer.serialize(data.owner),
      address: PropertyAddressSerializer.serialize(data.address),
      lease: data.lease ? LeaseSerializer.serializeEditCondo(data.lease) : undefined,
    };
  }

  static deSerializeSimpleProperty(data: RawSimpleProperty): SerializedSimpleProperty {
    return {
      buildingName: data.building_name,
      address: PropertyAddressSerializer.deSerialize(data.address),
      id: data.id,
      type: data.type,
    };
  }
}

export { PropertySerializer };
