class FileModel {
  resource: File | null;

  filename: string;

  constructor(params: SerializedFile) {
    this.filename = params.filename;
    this.resource = params.resource || null;
  }
}

export { FileModel };
