const fetchVariable = (varName: string, defaultValue?: string) => {
  if (!process.env[varName] && typeof defaultValue === 'undefined') {
    throw new Error(`Mandatory environment variable ${varName} is not set.`);
  }
  return process.env[varName] || defaultValue;
};

const nodeEnv = fetchVariable('NODE_ENV');
const environment = {
  isDevelopment: nodeEnv === 'development',
  isProduction: nodeEnv === 'production',
};

const ONE_WEEK_MILLISECONDS = 7 * 24 * 60 * 60 * 1000;

export const constants = {
  apiBaseURL: fetchVariable('REACT_APP_API_BASE_URL', 'REPLACE ME'),
  i18nextDebugMode: fetchVariable('REACT_APP_I18N_DEBUG_MODE', 'false') === 'true',
  i18nextLocalstorageExpirationTime: parseInt(fetchVariable('REACT_APP_I18N_STORAGE_CACHE_EXPIRATION', String(ONE_WEEK_MILLISECONDS)) as string, 10),
  publicUrl: fetchVariable('REACT_APP_PUBLIC_URL', ''),
  environment,
  defaultServerTimezone: 'Etc/Greenwich',
};
