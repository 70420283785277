import { Modal } from '@mapix/common/src/common/modal';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { ReactComponent as Alert } from 'assets/icons/alertFilled.svg';
import { classnames } from '@mapix/common/src/helpers/utils';
import { useMediaQuery } from '@mapix/common/src/hooks/use-media-query';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { Breakpoints } from 'common/enums';
import styles from './modal-result.module.scss';

type ModalProps = {
  title: string,
  subtitle?: string,
  Icon: any,
  buttonTextLeft?: string,
  buttonTextRight: string,
  hideCornerCloseButton?: boolean,
  handleButtonLeft?: () => void,
  handleButtonRight: () => void,
  handleButtonClose?: () => void,
  withCheckIcon?: boolean,
  iconStyle?: string,
};

const ModalResult = ({
  title, subtitle, Icon, buttonTextLeft, buttonTextRight, hideCornerCloseButton = false,
  handleButtonLeft, handleButtonRight, handleButtonClose, iconStyle,
  withCheckIcon = false,
}: ModalProps) => {
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  return (
    <Modal>
      <div className={styles.modalContainer}>
        <div className={styles.crossContainer}>
          {!hideCornerCloseButton && (
            <button type="button" onClick={handleButtonClose}>
              <Cross className={styles.crossIcon} />
            </button>
          )}
        </div>
        <div className={styles.center}>
          <div className={withCheckIcon ? styles.containerIcon : styles.containerAlert}>
            <Icon className={iconStyle || styles.icon} />
            {withCheckIcon
              ? (
                <div className={styles.checkContainer}>
                  <Check />
                </div>
              )
              : (
                <div className={styles.alertContainer}>
                  <Alert className={styles.alertIcon} />
                </div>
              )}
          </div>
          <p className={mobile ? 'text__heading4__textNeutral50' : 'text__heading5__textNeutral50'}>
            {title}
          </p>
          <p className={classnames(styles.subtitle, 'text__body__medium__textNeutral40')}>
            {subtitle}
          </p>
          <div className={buttonTextLeft ? styles.buttons : styles.oneButton}>
            {!!buttonTextLeft
          && (
          <Button
            buttonStyle={ButtonStyle.Secondary}
            className={classnames(styles.button, styles.marginRight)}
            onClick={handleButtonLeft}
          >
            {buttonTextLeft}
          </Button>
          )}
            <Button
              buttonStyle={ButtonStyle.Primary}
              className={styles.button}
              onClick={handleButtonRight}
            >
              {buttonTextRight}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { ModalResult };
