import { AddressSerializer } from '@mapix/common/src/networking/serializers/address-serializer';
import { CertificationSerializer } from '@mapix/common/src/networking/serializers/certification-serializer';
import { CitySerializer } from '@mapix/common/src/networking/serializers/city-serializer';
import { FileSerializer } from '@mapix/common/src/networking/serializers/file-serializer';
import { SpecializationSerializer } from '@mapix/common/src/networking/serializers/specialization-serializer';
import { TypeOfCostSerializer } from '@mapix/common/src/networking/serializers/type-of-cost-serializer';
import {
  BasicUserFields,
  NewTaskEventType,
  RawBasicUserFields,
  RawNewTaskEventType,
} from '@mapix/common/src/types';
import {
  NewTask,
  RawNewTask,
} from 'networking/types';
import { ContractorExtended, RawContractorExtended } from 'networking/types/contractor-auxiliar';
import {
  PrivateRequestId,
  RawPrivateRequestId,
} from 'networking/types/private-request';
import { RawTaskMessageRequest, TaskMessageRequest } from '@mapix/common/src/types/request';
import { MessageSerializer } from './message-serializer';
import { PropertySerializer } from './property-serializer';
import { UnitSerializer } from './unit-serializer';
import { ResourcesSerializer } from './resources-serializer';

class NewTaskSerializer {
  static serializeNewTask(data: CreateNewTaskData): RawCreateNewTaskData {
    return {
      category_id: data.category.id,
      property_id: data.propertyId as number,
      description: data.description,
      name: data.name,
      other_category: data.otherCategory || null,
      priority: data.priority,
      task_photos: data.taskPhotos,
      task_type: data.taskType,
      unit_id: data.unitId || null,
      contractor_id: data.contractorId || null,
      contractor_message: data.contractorMessage || null,
    };
  }

  static deSerializeBasicUserFields = (data: RawBasicUserFields): BasicUserFields => ({
    id: data.id,
    name: data.name,
    lastName: data.last_name,
    profilePhoto: data.profile_photo,
  });

  static deSerializeContractorExtended = (data: RawContractorExtended): ContractorExtended => ({
    id: data.id,
    name: data.name,
    lastName: data.last_name,
    profilePhoto: data.profile_photo,
    certifications: CertificationSerializer.deserializeContractorCertificationArray(
      data.certifications,
    ),
    cities: CitySerializer.deSerializeCities(data.cities),
    specializations: SpecializationSerializer.deSerializeAllContractorSpecialization(
      data.specializations,
    ),
  });

  static deSerializeNewTaskEvent = (data: RawNewTaskEventType): NewTaskEventType => ({
    id: data.id,
    createdAt: data.created_at,
    eventType: data.event_type,
    messageToContractor: data.message_to_contractor,
    messageToManager: data.message_to_manager,
    percentageComment: data.percentage_comment,
    oldPercentage: data.old_percentage,
    ...TypeOfCostSerializer.deSerializeOldTypeOfCost(data),
  });

  static deSerializeNewTaskEvents = (data: RawNewTaskEventType[]): NewTaskEventType[] => data.map(
    (event) => this.deSerializeNewTaskEvent(event),
  );

  static deSerializePrivateRequestId = (data: RawPrivateRequestId): PrivateRequestId => ({
    id: data.id,
  });

  static deSerializeNewTask(data: RawNewTask): NewTask {
    return {
      id: data.id,
      name: data.name,
      category: data.category,
      priority: data.priority,
      description: data.description,
      status: data.status,
      buildingName: data.building_name,
      address: AddressSerializer.deSerializeAddress(data.address),
      taskPhotos: data.task_photos || undefined,
      createdAt: data.created_at,
      startDate: data.start_date,
      inProgressDate: data.in_progress_date,
      solvedDate: data.solved_date,
      applications: data.amount_of_applications,
      publicRequestsCount: data.amount_of_public_requests,
      activeRequestAssignedId: data.active_request_assigned_id,
      privateRequests: data.private_requests && data.private_requests.map(
        (pr: RawPrivateRequestId) => this.deSerializePrivateRequestId(pr),
      ),
      taskMessages: data.task_messages
        ? MessageSerializer.deSerializeMessages(data.task_messages)
        : [],
      inProgressManagerValidation: data.in_progress_manager_validation,
      inProgressContractorValidation: data.in_progress_contractor_validation,
      inProgressRejectionExpiration: data.in_progress_rejection_expiration,
      solvedManagerValidation: data.solved_manager_validation,
      solvedContractorValidation: data.solved_contractor_validation,
      solvedRejectionExpiration: data.solved_rejection_expiration,
      firstTimeInProgressRejectedManagerSeen:
            data.first_time_in_progress_rejected_manager_seen,
      firstTimeSolvedRejectedManagerSeen:
            data.first_time_solved_rejected_manager_seen,
      rejectedInProgressMovementBy: data.rejected_in_progress_movement_by,
      rejectedSolvedMovementBy: data.rejected_solved_movement_by,
      messageForInProgressMovementRejection: data.message_for_in_progress_movement_rejection,
      messageForSolvedMovementRejection: data.message_for_solved_movement_rejection,
      currentServerTime: data.current_server_time,
      newTaskEvents: this.deSerializeNewTaskEvents(data.new_task_events),
      managerProfilePhoto: data.manager_profile_photo
        ? ResourcesSerializer.serializeFileType(data.manager_profile_photo)
        : null,
      managerLastName: data.manager_last_name,
      managerName: data.manager_name,
      property: PropertySerializer.deSerialize(data.property),
      unit: data.unit ? UnitSerializer.deSerialize(data.unit) : null,
      contractor: data.contractor ? this.deSerializeContractorExtended(data.contractor) : null,
      contractorAssignedName: data.contractor_assigned_name,
      contractorAssignedLastName: data.contractor_assigned_last_name,
      noticeCancellationManagerSeen: data.notice_cancellation_manager_seen,
      noticeCancellationContractorSeen: data.notice_cancellation_contractor_seen,
      cancellationMessage: data.cancellation_message,
      cancellationPercentage: data.cancellation_percentage,
      cancelledDate: data.cancelled_date,
      cancelledBy: data.cancelled_by,
      reasonForCancellation: data.reason_for_cancellation,
      negotiatingStartDate: data.negotiating_start_date,
      taskType: data.task_type,
      tenantLastName: data.tenant_last_name,
      tenantName: data.tenant_name,
      ...TypeOfCostSerializer.deSerializeTypeOfCost(data),
    };
  }

  static serializeSendTaskMessage(data: TaskMessageRequest): RawTaskMessageRequest {
    return {
      text: data.text,
      task_message_photos: data.taskMessagePhotos.map((photo) => FileSerializer.serialize(photo)),
    };
  }
}

export { NewTaskSerializer };
