import { classnames } from '../../../helpers/utils';
import { Button, ButtonStyle, ButtonType } from '../../button';
import styles from './bottom-buttons-make-payment.module.scss';

type BottomButtonsMakePaymentProps = {
  onCancel: () => void,
  disabledButtonRight: boolean,
  textButtonRight: string,
  t: (key: string) => string,
  translPrefix: string,
  mobile: boolean,
};

const BottomButtonsMakePayment = ({
  onCancel, disabledButtonRight, textButtonRight, t, translPrefix, mobile,
}: BottomButtonsMakePaymentProps) => (
  <div className={styles.dividerAndButtonWrapper}>
    {mobile && (<hr className={styles.divider} />)}

    <div className={styles.buttonContainer}>

      <Button
        onClick={onCancel}
        className={styles.button}
        buttonStyle={mobile ? ButtonStyle.SecondaryOutlined : ButtonStyle.Secondary}
      >
        <div className={`text__button__medium__${mobile ? 'secondary80' : 'primary60'}`}>
          {t(`${translPrefix}.cancel`)}
        </div>
      </Button>

      <Button
        buttonType={ButtonType.Submit}
        className={classnames(styles.button, mobile ? styles.mobile : '')}
        buttonStyle={ButtonStyle.Primary}
        disabled={disabledButtonRight}
      >
        <div className={disabledButtonRight ? 'text__button__medium__secondary70'
          : 'text__button__medium__textNeutral10'}
        >
          {textButtonRight}
        </div>
      </Button>
    </div>
  </div>
);

export { BottomButtonsMakePayment };
