import { PropertyAddress } from 'models/property-address';
import { PropertyAddressSerializer } from './property-address-serializer';
import { PropertyOwnerSerializer } from './property-owner-serializer';

class TenantRentalsSerializer {
  static deSerialize(data: RawTenantRental): SerializedTenantRental {
    return {
      id: data.id,
      address: new PropertyAddress(PropertyAddressSerializer.deSerialize(data.address)),
      managers: data.managers.map((manager) => PropertyOwnerSerializer.deSerialize(manager)),
      rentalAmount: data.rental_amount,
      dueDate: data.due_date,
      leaseExpirationDate: data.lease_expiration_date,
      paymentDate: data.payment_date,
      status: data.status,
      currency: data.currency,
      fullMonth: data.full_month,
      extensionBorderRental: data.extension_border_rental,
      payable: data.payable,
    };
  }
}

export { TenantRentalsSerializer };
