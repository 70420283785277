import { useState } from 'react';
import { ImageOverlay } from '../image-overlay';
import { ImageOverlayObject } from '../image-overlay/image-overlay';
import { classnames, resolveDateHour } from '../../helpers/utils';
import { FileType, Sender } from '../../types';
import { AvatarOrInitials, AvatarSize } from '../avatar-or-initials';
import styles from './message.module.scss';

export type MessageType = {
  id: number;
  sender: Sender;
  body: string;
  senderName: string;
  senderLastName: string;
  senderUrl?: string;
  date: string;
  taskMessagePhotos?: FileType[];
};

type MessageProps = {
  translPrefix: string;
  message: MessageType;
  language: string;
  containerClass?: string;
  userRole: Sender;
  t: (text: string) => string;
};

const initOverlayState: ImageOverlayObject = {
  show: false,
  index: 0,
  photos: [],
};

const Message = ({
  translPrefix, message, containerClass, t, language, userRole,
}: MessageProps) => {
  const [overlayState, setOverlayState] = useState<ImageOverlayObject>(initOverlayState);
  const [isTextExpanded, setIsTextExpanded] = useState(false);

  const maximumTextLength = 100;

  const showOverlay = (index: number, photos: FileType[]) => {
    setOverlayState({ index, photos, show: true });
  };

  const hideOverlay = () => {
    setOverlayState((prevState) => ({ ...prevState, show: false }));
  };

  const {
    sender, senderName, senderLastName, senderUrl, body, taskMessagePhotos,
  } = message;

  const showSmallPhotos = (photos: FileType[]) => (
    <div className={styles.photosContainer}>
      {photos.map((photo: FileType, index) => (
        <button type="button" onClick={() => showOverlay(index, photos)}>
          <img
            src={photo.url}
            alt="MiniPhoto"
            className={styles.photo}
          />
        </button>
      ))}
    </div>
  );

  const isLongText = () => body.length > maximumTextLength;

  const showFullOrShortText = () => {
    if (isTextExpanded) return body;

    return `${body.slice(0, maximumTextLength)}...`;
  };

  const showSeeMoreOrLessButton = () => (
    <button
      type="button"
      onClick={() => setIsTextExpanded(!isTextExpanded)}
      className={classnames(styles.seeMore, 'text__body__medium__textNeutral40')}
    >
      {isTextExpanded ? t(`${translPrefix}.seeLess`) : t(`${translPrefix}.seeMore`)}
    </button>
  );

  return (
    <>
      {overlayState.show && (
        <ImageOverlay
          photos={overlayState.photos}
          selectedIndex={overlayState.index}
          closeFn={hideOverlay}
          t={t}
        />
      )}

      <div
        className={classnames(sender === userRole ? styles.container : styles.containerReverse, containerClass || '')}
      >
        <AvatarOrInitials
          firstName={senderName}
          lastName={senderLastName}
          className={styles.avatar}
          size={AvatarSize.Small}
          url={senderUrl}
        />
        <div className={styles.informationWrapper}>
          <div className={styles.messageBubble}>
            <div className={classnames(styles.body, 'text__body__medium__textNeutral40')}>
              {isLongText() ? showFullOrShortText() : body}
            </div>
            {isLongText() && showSeeMoreOrLessButton()}

            {!!taskMessagePhotos?.length && (
              <>
                <hr className={styles.divider} />
                {showSmallPhotos(taskMessagePhotos)}
              </>
            )}
          </div>
          <div className={styles.dateAndName}>
            <div className="text__body__tiny__textNeutral30">
              {resolveDateHour(message.date, language, t, 'MMMM Do, YYYY - HH:mm')}
            </div>
            <div className="text__body__tiny__textNeutral30">{`${message.senderName} ${message.senderLastName}`}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Message };
