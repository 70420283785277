class Paginator<T> {
  currentPage: number;

  lastPage: number;

  pageSize: number;

  total: number;

  results: T[];

  constructor(params: SerializedPaginator | null, data: T[]) {
    this.currentPage = params?.currentPage || 1;
    this.lastPage = params?.lastPage || 1;
    this.results = data || [];
    this.pageSize = params?.pageSize || 10;
    this.total = params?.total || 0;
  }
}

export { Paginator };
