import { useTranslation } from 'react-i18next';
import { CreateFooter } from 'common/creation';
import { Breadcrumbs, BreadcrumbsItem } from '@mapix/common/src/common/breadcrumbs';
import { ButtonType } from '@mapix/common/src/common/button';
import { PaymentInformation, ModalState } from '@mapix/common/src/common/payment-information';
import { SetStateAction, useEffect, useState } from 'react';
import { Breakpoints } from 'common/enums';
import { goToPage, RouteName } from 'routes';
import { PaymentInformationSummary, PaymentInformationStatusAndPercentage, DocumentsInformationType } from '@mapix/common/src/types';
import { ErrorType } from '@mapix/common/src/common/error-page';
import { PaymentInformationController } from 'networking/controllers/payment-information-controller';
import { logger } from 'helpers/logger';
import { useMediaQuery } from '@mapix/common/src/hooks/use-media-query';
import { Spinner } from '@mapix/common/src/common/spinner';
import { ApiError, ErrorCode, ErrorStatus } from 'models/api-error';
import { PaymentInformationStatus } from '@mapix/common/src/common/enums';
import styles from './manager-payment-information.module.scss';

const initStatusAndPercentage = (): PaymentInformationStatusAndPercentage => ({
  status: PaymentInformationStatus.PENDING,
  percentage: 0,
});

const initPaymentInformation: PaymentInformationSummary = {
  completedInfo: false,
  legalInformation: initStatusAndPercentage(),
  dbaInformation: initStatusAndPercentage(),
  ownersOrOfficers: initStatusAndPercentage(),
  electronicAuthorization: initStatusAndPercentage(),
  salesProfile: initStatusAndPercentage(),
  documentInformation: initStatusAndPercentage(),
  pricing: initStatusAndPercentage(),
};

const handleError = (
  error: ApiError,
  code: ErrorCode,
  setShowModal: (state: SetStateAction<ModalState>) => void,
) => {
  logger.log(error as Error);
  if (error instanceof ApiError && error.code === code) {
    setShowModal(ModalState.ERROR);
  } else {
    goToPage(RouteName.ErrorPage, {}, { error: ErrorType.ServerError });
  }
};

const handleSubmitButton = async (
  setShowModal: (state: SetStateAction<ModalState>) => void,
  setFetching: (state: SetStateAction<boolean>) => void,
) => {
  try {
    setFetching(true);
    await PaymentInformationController.createApplication();
    setShowModal(ModalState.UPLOAD_FILES);
  } catch (error: any) {
    handleError(error, ErrorCode.InvalidCreateApplication, setShowModal);
  } finally {
    setFetching(false);
  }
};

const submitApplication = async (
  setFetching: (state: SetStateAction<boolean>) => void,
  setShowModal: (state: SetStateAction<ModalState>) => void,
) => {
  try {
    setFetching(true);
    await PaymentInformationController.submitApplication();
    setShowModal(ModalState.SUCCESS);
  } catch (error: any) {
    handleError(error, ErrorCode.CouldNotSubmitApplication, setShowModal);
  } finally {
    setFetching(false);
  }
};

const handleCloseModalResult = (
  showModal: ModalState,
  setShowModal: (state: SetStateAction<ModalState>) => void,
) => {
  if (showModal === ModalState.SUCCESS) {
    goToPage(RouteName.Home);
  }
  setShowModal(ModalState.NONE);
};

const ManagerPaymentInformation = () => {
  // TODO payment information remove this redirection
  goToPage(RouteName.ErrorPage);

  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  const { t } = useTranslation();
  const [fetching, setFetching] = useState<boolean>(true);
  const [paymentInformation, setPaymentInformation] = useState<
  PaymentInformationSummary>(initPaymentInformation);
  const [showModal, setShowModal] = useState<ModalState>(ModalState.NONE);

  const breadcrumbs = (
    home: string,
    profile: string,
    paymentInformationTitle: string,
  ): BreadcrumbsItem[] => [
    {
      name: home,
      goToPageFn: () => goToPage(RouteName.Home),
    },
    {
      name: profile,
      goToPageFn: () => goToPage(RouteName.MyProfile),
    },
    {
      name: paymentInformationTitle,
      goToPageFn: () => null,
    },
  ];

  const getPaymentInfo = async () => {
    try {
      const backendPaymentInformation = await PaymentInformationController.getPaymentInformation();
      setPaymentInformation(backendPaymentInformation);
    } catch (e: any) {
      if (e.status !== ErrorStatus.NotFound) {
        logger.log(e);
        goToPage(RouteName.ErrorPage, {}, { error: ErrorType.ServerError });
      }
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    getPaymentInfo();
  }, []);

  if (fetching) {
    return <Spinner />;
  }

  return (
    <div className={styles.container}>
      {mobile
      && (
      <Breadcrumbs values={breadcrumbs(
        t('managerPaymentInformation.home'),
        t('managerPaymentInformation.profile'),
        t('managerPaymentInformation.paymentInformation'),
      )}
      />
      )}
      <div className={styles.options}>
        <PaymentInformation
          t={t}
          paymentInfo={paymentInformation!}
          dbaInfoFn={() => goToPage(RouteName.DBAinformation)}
          legalBuisnessInfoFn={() => goToPage(RouteName.LegalBusinessInformation)}
          ownersOrOfficersFn={() => goToPage(RouteName.OwnersOrOfficers)}
          bankingInfoFn={() => goToPage(RouteName.ElectronicDebitCreditAuthorization)}
          salesProfileFn={() => goToPage(RouteName.SalesProfile)}
          documentsFn={() => goToPage(RouteName.DocumentsInformation)}
          pricingFn={() => {}}
          showModal={showModal}
          setShowModal={setShowModal}
          getDocumentsInformation={() => PaymentInformationController.getDocumentsInformation()}
          uploadDocument={(key: string) => PaymentInformationController
            .uploadDocument(key as keyof DocumentsInformationType)}
          handleChangeFile={() => goToPage(RouteName.DocumentsInformation)}
          handleCloseModalResult={() => handleCloseModalResult(showModal, setShowModal)}
          submitApplication={() => submitApplication(setFetching, setShowModal)}
        />
      </div>
      <CreateFooter
        nextName={t('submit')}
        nextButtonType={ButtonType.Submit}
        className={styles.footer}
        disableNextCondition={!paymentInformation?.completedInfo}
        nextButtonStyle={mobile ? styles.mobileNextButton : ''}
        nextFn={() => handleSubmitButton(
          setShowModal,
          setFetching,
        )}
      />
    </div>
  );
};

export { ManagerPaymentInformation };
