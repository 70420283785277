import { ReactComponent as ChevronLeft } from '../../assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from '../../assets/icons/chevron-right.svg';
import { classnames } from '../../helpers/utils';
import styles from './pagination.module.scss';

type PaginationProps = {
  pageLimit: number,
  onClickFn: (page: number) => void,
  currentPage: number,
  containerClass?: string,
};

const isSelected = (currentPage: number, key: number) => {
  const classes = [styles.button];
  if (currentPage === key) {
    classes.push(styles.selected);
  }
  return classes;
};

const getArray = (pageLimit: number, currentPage: number) => {
  const fullArray = Array.from(Array(pageLimit).keys());

  if (currentPage - 2 < 0) {
    return fullArray.slice(0, 4);
  }

  if (currentPage + 2 > pageLimit) {
    if (currentPage + 1 > pageLimit) {
      return fullArray.slice(currentPage - 3, pageLimit);
    }
    return fullArray.slice(currentPage - 2, pageLimit);
  }

  return fullArray.slice(currentPage - 2, currentPage + 2);
};

const Pagination = ({
  pageLimit, onClickFn, currentPage, containerClass = '',
}: PaginationProps) => (
  <div className={classnames(styles.paginationContainer, containerClass)}>
    <>
      {currentPage - 1 > 0
      && <ChevronLeft onClick={() => onClickFn(currentPage - 1)} className={styles.chevron} />}

      {getArray(pageLimit, currentPage - 1).map((key) => (
        <button
          key={key}
          onClick={() => onClickFn(key + 1)}
          className={classnames(...isSelected(currentPage, key + 1))}
          type="button"
        >
          {key + 1}
        </button>
      ))}

      {currentPage + 2 < pageLimit
      && <ChevronRight onClick={() => onClickFn(currentPage + 1)} className={styles.chevron} />}
    </>
  </div>
);

export { Pagination };
