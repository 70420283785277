import { ReactComponent as User } from '@mapix/common/src/assets/icons/user16.svg';
import { Button, ButtonStyle } from '@mapix/common/src/common/button';
import { RentInfo } from '@mapix/common/src/common/rent-info';
import { useMediaQuery } from '@mapix/common/src/hooks/use-media-query';
import { ReactComponent as CheckFilled } from 'assets/icons/check-filled.svg';
import { Breakpoints, RentalsStatus } from 'common/enums';
import { RentalTenant } from 'models/rental-tenant';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, TooltipPosition } from '@mapix/common/src/common/tooltip';
import { MakePaymentRental } from 'common/make-payment-rental';
import { RouteName, goToPage } from 'routes';
import styles from './payments-tenant.module.scss';

type PaymentCardProps = {
  rental: RentalTenant,
  getRentals: () => void,
  contactFn: (rental: RentalTenant) => void,
  toggleShowInfoModal: () => void,
};

const translPrefix = 'tenantPayments.card';

const PaymentCard = ({
  rental, contactFn, toggleShowInfoModal, getRentals,
}: PaymentCardProps) => {
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  const { t } = useTranslation();
  const [paymentMethod, setPaymentMethod] = useState(false);

  const handleOnClickPayNow = () => {
    if (!mobile) {
      return setPaymentMethod(true);
    }
    return goToPage(RouteName.MakePaymentPage, { rentalId: rental.id });
  };

  const getButtonRight = (
    disabled: boolean,
  ) => {
    const button = (
      <Button
        onClick={handleOnClickPayNow}
        buttonStyle={ButtonStyle.Primary}
        className={styles.payNowButton}
        disabled={disabled}
      >
        <div className={
          disabled ? 'text__button__medium__surface30' : 'text__button__medium__surface10'
          }
        >
          {t(`${translPrefix}.payNow`)}
        </div>
      </Button>
    );

    if (disabled) {
      return (
        <Tooltip
          textComponent={button}
          spanClass={styles.spanClass}
          hoverComponent={(
            <div className={`text__body__small__surface10 ${styles.tooltip}`}>
              {t(`${translPrefix}.rentalNotPayable`)}
            </div>
          )}
          position={TooltipPosition.Left}
        />
      );
    }

    return button;
  };

  return (
    <>
      {paymentMethod && (
      <MakePaymentRental
        onClose={() => setPaymentMethod(false)}
        t={t}
        rental={rental}
        toggleShowInfoModal={toggleShowInfoModal}
        getRentals={getRentals}
      />
      )}
      <div className={styles.paymentCard}>
        {mobile
          ? (
            <>
              <RentInfo
                rental={rental}
                t={t}
                translPrefix={translPrefix}
                toggleShowInfoModal={toggleShowInfoModal}
              />

              {rental.paymentDate
                ? (
                  <div className={styles.iconContainer}>
                    <CheckFilled className={styles.icon} />
                  </div>
                )
                : (
                  <div className={styles.buttonContainer}>
                    <button className={styles.buttonText} onClick={() => contactFn(rental)} type="button">
                      <div className="row text__body__medium__primary40">
                        {t(`${translPrefix}.contactInfo`)}
                        <User className={styles.userIcon} />
                      </div>
                    </button>
                    {(rental.status !== RentalsStatus.Paid) && (
                      getButtonRight(!rental.payable)
                    )}
                  </div>
                )}

            </>
          )
          : (
            <div className={styles.cardInfo}>
              <RentInfo
                rental={rental}
                t={t}
                translPrefix={translPrefix}
                toggleShowInfoModal={toggleShowInfoModal}
                separatorMoreMargin
              />

              <div className={styles.buttonContainer}>
                {rental.paymentDate
                  ? <CheckFilled className={styles.icon} />
                  : (
                    <Button
                      onClick={() => contactFn(rental)}
                      buttonStyle={ButtonStyle.Primary}
                    >
                      <div className="text__button__medium__surface10">
                        {t(`${translPrefix}.contactInfo`)}
                      </div>
                    </Button>
                  )}
                {(rental.status !== RentalsStatus.Paid) && (
                  getButtonRight(!rental.payable)
                )}
              </div>

            </div>
          )}
      </div>
    </>
  );
};

export { PaymentCard };
