import styles from './switch.module.scss';
import { ReactComponent as Check } from '../../assets/icons/check.svg';

type SwitchProps = {
  isToggled: boolean,
  switchToggled: () => void,
};

const Switch = ({ isToggled, switchToggled }: SwitchProps) => (
  <label className={styles.toggleSwitch} htmlFor="switch">
    <input type="checkbox" checked={isToggled} onChange={switchToggled} id="switch" />
    <span className={styles.switch} />
    <Check className={styles.check} />
  </label>
);
export { Switch };
