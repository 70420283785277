import { HTMLProps, ReactNode } from 'react';
import { classnames } from '../../helpers/utils';
import styles from './button.module.scss';

enum ButtonType {
  Reset = 'reset',
  Submit = 'submit',
  Button = 'button',
}
enum ButtonStyle {
  Primary = 'Primary',
  Secondary = 'Secondary',
  SecondaryFilled = 'SecondaryFilled',
  SecondaryOutlined = 'SecondaryOutlined',
  Link = 'Link',
  PrimaryGradient = 'Primary-gradient',
  GhostSecondary = 'GhostSecondary',
  Red = 'Red',
  secondaryBackgroundPrimary20 = 'Secondary-background-primary-20',
  Language = 'Language',
  Back = 'Back',

}

type ButtonProps = {
  children: ReactNode,
  className?: string,
  buttonStyle?: ButtonStyle,
  buttonType?: ButtonType,
};

function handleClassStyle(buttonStyle: ButtonStyle, className: string) {
  const classes = [styles.buttonContainer, className];
  switch (buttonStyle) {
    /* Link is the only kind of button that does not use the button container */
    case ButtonStyle.Link:
      return classnames(className, styles.link);
    case ButtonStyle.Back:
      return classnames(className, styles.back);
    case ButtonStyle.Primary:
      classes.push(styles.primary);
      break;
    case ButtonStyle.Secondary:
      classes.push(styles.secondary);
      break;
    case ButtonStyle.SecondaryFilled:
      classes.push(styles.secondaryFilled);
      break;
    case ButtonStyle.SecondaryOutlined:
      classes.push(styles.secondaryOutlined);
      break;
    case ButtonStyle.secondaryBackgroundPrimary20:
      classes.push(styles.secondaryBackgroundPrimary20);
      break;
    case ButtonStyle.PrimaryGradient:
      classes.push(styles.primaryGradient);
      break;
    case ButtonStyle.GhostSecondary:
      classes.push(styles.ghostSecondary);
      break;
    case ButtonStyle.Red:
      classes.push(styles.redButton);
      break;
    case ButtonStyle.Language:
      classes.push(styles.languageButton);
      break;
    default:
      break;
  }
  return classnames(...classes);
}

const Button = ({
  children, className = '', buttonStyle = ButtonStyle.Primary,
  buttonType = ButtonType.Button, ...props
}: ButtonProps & HTMLProps<HTMLButtonElement>) => (
  // eslint-disable-next-line react/button-has-type,react/jsx-props-no-spreading
  <button className={handleClassStyle(buttonStyle, className)} {...props} type={buttonType}>
    {children}
  </button>
);

export { Button, ButtonStyle, ButtonType };
