import { TaskDatesOverview } from '@mapix/common/src/common/task-dates-overview';
import { TaskUpdatesOverview } from '@mapix/common/src/common/task-updates-overview';
import { TaskStatus } from '@mapix/common/src/common/enums';
import { TaskMessage, TypeOfCost } from '@mapix/common/src/types';
import { MessageType } from '@mapix/common/src/common/message';
import { PrivateRequest, PublicRequest } from 'networking/types';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'context';
import { useContext } from 'react';
import { ContractorDetails } from './contractor-details';
import { UnassignedTaskDetailRow } from './unassigned-task-detail-row';
import styles from './status-tab.module.scss';

type StatusTabProps = {
  request: PrivateRequest | PublicRequest;
  taskStatus: TaskStatus | '';
  className?: string;
  changeContractorFn: () => void;
  notifyAddNewUpdate: () => void;
};

const StatusTab = ({
  request, taskStatus, changeContractorFn, className = '', notifyAddNewUpdate,
}: StatusTabProps) => {
  const { t, i18n } = useTranslation();

  const translPrefix = 'newTaskDetail.tabs.status';

  const {
    state: {
      user: { name: manangerName, lastName: managerLastName },
    },
  } = useContext(AppContext);

  const mapMessages = (
    req: PrivateRequest | PublicRequest,
  ): MessageType[] => request.taskMessages.map((message: TaskMessage) => ({
    id: message.id,
    date: message.createdAt,
    message: message.text,
    body: message.text,
    taskMessagePhotos: message.taskMessagePhotos,
    senderUrl: message.sender === 'Contractor' ? req.contractor.profilePhoto?.url : req.manager.profilePhoto?.url,
    sender: message.sender,
    senderLastName: message.sender === 'Contractor'
      ? req.contractor.lastName : managerLastName,
    senderName: message.sender === 'Contractor'
      ? req.contractor.name : manangerName,
  }
  ));

  const isUnassigned = taskStatus === TaskStatus.UNASSIGNED;

  const showStatusUpdates = taskStatus === TaskStatus.NEGOTIATING;

  const getTypeOfCost = (): (TypeOfCost | null) => {
    switch (request.typeOfCost) {
      case 'budget':
        return {
          typeOfCost: 'budget',
          budget: request.budget,
        };
      case 'hourlyRate':
        return {
          typeOfCost: 'hourlyRate',
          hourlyRate: request.hourlyRate,
          amountOfHours: request.amountOfHours,
        };
      default:
        return null;
    }
  };

  const { newTask, startDate, negotiatingStartDate } = request;

  const getCreatedDate = () => (
    newTask.status === TaskStatus.WAITING_FOR_CONTRACTOR_APPROVAl
      ? newTask.createdAt
      : ''
  );

  return (
    <div className={className}>
      {isUnassigned && (
      <UnassignedTaskDetailRow
        t={t}
        newTask={newTask}
        translPrefix={translPrefix}
        typeOfCostObject={getTypeOfCost()}
      />
      )}
      <ContractorDetails
        request={request}
        t={t}
        translPrefix={translPrefix}
        taskStatus={taskStatus}
        changeContractorFn={changeContractorFn}
      />

      <hr className={styles.divider} />
      <TaskDatesOverview
        t={t}
        dates={{
          created: getCreatedDate(),
          negotiating: negotiatingStartDate,
        }}
        estimatedStartDate={startDate}
        language={i18n.language}
        translPrefix={translPrefix}
      />

      {showStatusUpdates && (
      <>
        <hr className={styles.divider} />
        <TaskUpdatesOverview
          t={t}
          taskStatus={newTask.status}
          messages={mapMessages(request)}
          language={i18n.language}
          translPrefix={translPrefix}
          userRole="Manager"
          containerClass={styles.updatesContainer}
          notifyAddNewUpdate={notifyAddNewUpdate}
        />
      </>
      )}
    </div>
  );
};

export { StatusTab };
