import { useState } from 'react';
import { logger } from '../../../helpers/logger';
import { useMediaQuery } from '../../../hooks/use-media-query';
import {
  EntityType,
  Breakpoints,
  RawNewTaskEvent,
  PillColor,
  RawRequestStatus,
  RawNewTaskStatus,
} from '../../enums';
import { Pill, PillIcon } from '../../pill';
import {
  capitalizeFirstLetter, classnames, getTotalPriceByTypeOfCost, isRejectedRequest,
} from '../../../helpers/utils';
import { Button, ButtonStyle } from '../../button';
import { SimpleMessage } from '../../simple-message';
import { ModifyRateModals } from '../../modify-rate-modals';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import {
  BasicUserFields, ModifyRate, RequestEvent, TypeOfCost, RateEvent,
} from '../../../types';
import styles from './rate-tab.module.scss';

type RateTabProps = {
  entityType: EntityType,
  contractor: BasicUserFields;
  manager: BasicUserFields;
  events: RateEvent[];
  requestOrTaskId: number;
  rawStatus: RawRequestStatus | RawNewTaskStatus;
  contractorMessage?: string;
  managerMessage?: string;
  typeOfCost: TypeOfCost;
  isContractor?: boolean,
  className?: string;
  t: (text: string) => string,
  refreshData: () => Promise<void>,
  modifyRate: (id: number, modifyRateData: ModifyRate) => Promise<any>,
  notifyAcceptRate: () => void;
};

const translPrefix = 'newTaskDetail.tabs.rate';

const getLastEvent = (requestEvents: RequestEvent[]) => {
  if (requestEvents.length) {
    return requestEvents[requestEvents.length - 1];
  }

  return undefined;
};

const getLastRateChangeEvent = (requestEvents: RequestEvent[]): RequestEvent | null => {
  // eslint-disable-next-line no-plusplus
  for (let i = requestEvents.length - 1; i >= 0; i--) {
    if (requestEvents[i].eventType === RawNewTaskEvent.RATE_CHANGED_BY_CONTRACTOR
        || requestEvents[i].eventType === RawNewTaskEvent.RATE_CHANGED_BY_MANAGER) {
      return requestEvents[i];
    }
  }

  return null;
};

const parseRatePillContent = (
  status: RawRequestStatus | RawNewTaskStatus,
  t: (text: string) => string,
) => {
  switch (status) {
    case RawRequestStatus.WAITING_FOR_CONTRACTOR_APPROVAL:
    case RawNewTaskStatus.WAITING_CONTRACTOR_RATE_APPROVAL:
      return t(`${translPrefix}.pills.waitingForContractorApproval`);
    case RawRequestStatus.WAITING_FOR_MANAGER_APPROVAL:
    case RawNewTaskStatus.WAITING_MANAGER_RATE_APPROVAL:
      return t(`${translPrefix}.pills.waitingForManagerApproval`);
    case RawRequestStatus.ASSIGNED:
      return t(`${translPrefix}.pills.waitingManagerAcceptRate`);
    case RawRequestStatus.ACCEPTED:
    case RawNewTaskStatus.IN_PROGRESS:
    case RawNewTaskStatus.SOLVED:
      return t(`${translPrefix}.pills.rateAccepted`);
    case RawRequestStatus.REJECTED_BY_CONTRACTOR:
    case RawRequestStatus.REJECTED_BY_MANAGER:
    case RawRequestStatus.REJECTED_AUTOMATICALLY:
      return t(`${translPrefix}.pills.rateRejected`);
    case RawRequestStatus.PENDING:
      return t(`${translPrefix}.pills.waitingApplicationApproval`);
    default:
      return '';
  }
};

const RateTab = ({
  events, modifyRate, contractorMessage, managerMessage, rawStatus,
  contractor, manager, typeOfCost, requestOrTaskId, notifyAcceptRate, t,
  isContractor, entityType = EntityType.Request, className = '', refreshData,
}: RateTabProps) => {
  const [showModifyRate, setShowModifyRate] = useState(false);

  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);

  const isRateAcceptedOrApprovedByEntityType = () => {
    if (entityType === EntityType.Request) {
      return rawStatus === RawRequestStatus.ACCEPTED;
    }

    if (entityType === EntityType.Task) {
      return rawStatus === RawNewTaskStatus.IN_PROGRESS;
    }

    return false;
  };

  const isSolvedTask = () => rawStatus === RawNewTaskStatus.SOLVED;

  const getOldRate = () => {
    if (entityType === EntityType.Request) {
      return getLastEvent(events) ? t(`$${getLastEvent(events).oldTypeOfCost === 'budget'
        ? getLastEvent(events).oldBudget : getLastEvent(events).oldHourlyRate}`)
        : undefined;
    }

    if (entityType === EntityType.Task) {
      const lastRateChangeEvent = getLastRateChangeEvent(events);

      if (lastRateChangeEvent) {
        return t(`$${lastRateChangeEvent.oldTypeOfCost === 'budget'
          ? lastRateChangeEvent.oldBudget : lastRateChangeEvent.oldHourlyRate}`);
      }
    }

    return undefined;
  };

  const getPayAmount = () => {
    const total = getTotalPriceByTypeOfCost(typeOfCost);

    return (
      <div className={classnames('mt-40', 'text__body__medium__textNeutral40')}>
        <span>
          {`${t('total')}: `}
        </span>
        <span className={classnames('bold', styles.payAmountPrice)}>
          $
          {`${total} `}
        </span>
      </div>
    );
  };

  const handleButtonClose = async () => {
    setShowModifyRate(false);
    try {
      await refreshData();
    } catch (err) {
      logger.log(err);
    }
  };

  const mustShowMessage = () => {
    if (entityType === EntityType.Request) {
      if (isRejectedRequest(rawStatus as RawRequestStatus)) return false;

      const rateChanged = getLastEvent(events);
      if (rateChanged) return true;

      if (contractorMessage || managerMessage) return true;
    }

    if (entityType === EntityType.Task) {
      if (rawStatus === RawNewTaskStatus.SOLVED) return false;

      return getLastRateChangeEvent(events);
    }

    return false;
  };

  const showUserMessage = () => {
    const showMessageToDisplay = (rateEvent: RateEvent | null) => (
      rateEvent?.messageToContractor
      || rateEvent?.messageToManager
      || contractorMessage
      || managerMessage
    );

    let event = null;

    if (entityType === EntityType.Request) {
      event = getLastEvent(events);
    }

    if (entityType === EntityType.Task) {
      event = getLastRateChangeEvent(events);
    }

    return (
      <SimpleMessage
        senderUrl={event?.eventType === RawNewTaskEvent.RATE_CHANGED_BY_CONTRACTOR
          ? contractor.profilePhoto?.url : manager.profilePhoto?.url}
        senderLastName={event?.eventType === RawNewTaskEvent.RATE_CHANGED_BY_CONTRACTOR
          ? contractor.lastName : manager.lastName}
        senderName={event?.eventType === RawNewTaskEvent.RATE_CHANGED_BY_CONTRACTOR
          ? contractor.name : manager.name}
        text={showMessageToDisplay(event)}
        containerClass={styles.message}
      />
    );
  };

  const isRateBeingNegotiating = () => {
    if (isContractor) {
      return rawStatus === RawRequestStatus.WAITING_FOR_CONTRACTOR_APPROVAL
        || rawStatus === RawNewTaskStatus.WAITING_CONTRACTOR_RATE_APPROVAL;
    }

    return rawStatus === RawRequestStatus.WAITING_FOR_MANAGER_APPROVAL
      || rawStatus === RawNewTaskStatus.WAITING_MANAGER_RATE_APPROVAL
      || rawStatus === RawRequestStatus.ASSIGNED;
  };

  const showModifiyButton = (text: string, buttonClassName: string = '') => (
    <Button
      buttonStyle={ButtonStyle.Back}
      className={classnames('text__body__medium__primary50', styles.modifyButton, buttonClassName)}
      onClick={() => setShowModifyRate(true)}
    >
      <EditIcon className={styles.editIcon} />
      {` ${text}`}
    </Button>
  );

  const showNegotiatingRateButtons = () => (
    <div className={styles.acceptModifyWrapper}>
      <Button
        buttonStyle={ButtonStyle.Primary}
        className={classnames('text__body__medium__textNeutral40', styles.accept)}
        onClick={notifyAcceptRate}
      >
        {t('accept')}
      </Button>

      {showModifiyButton(t(`${translPrefix}.modifyAndResubmit`))}
    </div>
  );

  const showModifyRateModals = () => (
    <ModifyRateModals
      handleButtonClose={handleButtonClose}
      modifyRateApiCall={(modifyRateData: ModifyRate) => (
        modifyRate(requestOrTaskId, modifyRateData)
      )}
      previousTypeOfCost={typeOfCost}
      t={t}
      confirmationModal
    />
  );

  const shouldShowModifyButton = () => {
    if (entityType === EntityType.Task) {
      if (isRateAcceptedOrApprovedByEntityType()) {
        return true;
      }
    }

    return false;
  };

  const getRatePillColor = () => {
    if (isRateAcceptedOrApprovedByEntityType() || isSolvedTask()) {
      return PillColor.Green;
    }

    if (isRejectedRequest(rawStatus as RawRequestStatus)) {
      return PillColor.Red;
    }

    return PillColor.Yellow;
  };

  const getRatePillIcon = () => {
    if (isRateAcceptedOrApprovedByEntityType() || isSolvedTask()) {
      return PillIcon.Check;
    }

    return PillIcon.Alert;
  };

  const showRateInfo = () => (
    <>
      <Pill
        textStyle={classnames('text__body__xsmall__textNeutral10', styles.ratePill)}
        content={parseRatePillContent(rawStatus, t)}
        color={getRatePillColor()}
        icon={getRatePillIcon()}
      />

      {mustShowMessage() && showUserMessage()}

      <hr className={styles.divider} />

      <div className={isRateBeingNegotiating() ? styles.column : styles.spaceBetween}>
        <div>
          <div className={styles.margin}>
            <span className="text__body__medium__textNeutral40">
              {t(typeOfCost.typeOfCost === 'budget' ? `${translPrefix}.budget` : `${translPrefix}.hourlyRate`)}
            </span>
            {getOldRate() && (
            <span className={classnames('text__body__medium__warning50', styles.priceDashed)}>
              {getOldRate()}
            </span>
            )}
            <span className={classnames('text__body__medium__primary50', styles.price)}>
              {t(`$${typeOfCost.typeOfCost === 'budget' ? typeOfCost.budget : typeOfCost.hourlyRate}`)}
            </span>
          </div>
          <div className={classnames(styles.margin, 'text__body__medium__textNeutral40')}>
            { typeOfCost.amountOfHours && (
            <span>
              {`${
                capitalizeFirstLetter(t(`${translPrefix}.hours`))
              }: ${typeOfCost.amountOfHours} ${
                typeOfCost.amountOfHours === 1 ? t(`${translPrefix}.hour`) : t(`${translPrefix}.hours`)
              }`}
            </span>
            )}
          </div>
        </div>

        {isRateBeingNegotiating()
          ? showNegotiatingRateButtons()
          : shouldShowModifyButton() && showModifiyButton(t(`${translPrefix}.modify`), 'mt-10')}
      </div>
    </>
  );

  const noRateInfo = () => (
    <div className={classnames('mt-20', 'text__body__medium__textNeutral40')}>
      {t(`${translPrefix}.noRateInformation`)}
    </div>
  );

  return (
    <>
      {showModifyRate && showModifyRateModals()}

      <div className={classnames(styles.rateTabContainer, className)}>
        <div
          className={
            classnames(
              mobile
                ? 'text__heading2__textNeutral50' : 'text__body__large__textNeutral40',
              'mt-20',
            )
          }
        >
          {t(`${translPrefix}.tabName`)}
        </div>

        {typeOfCost.typeOfCost ? showRateInfo() : noRateInfo()}

        {isSolvedTask() && (
          <>
            <hr className={classnames(styles.divider, 'mt-20')} />
            {getPayAmount()}
          </>
        )}
      </div>
    </>
  );
};

export { RateTab };
