import { Users } from 'common/enums';
import { Spinner } from '@mapix/common/src/common/spinner';
import { appActions, AppContext } from 'context';
import { UserController } from 'networking/controllers/user-controller';
import {
  ReactNode, useContext, useEffect, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { goToPage, RouteName } from 'routes';
import { routes } from 'routes/routes';
import styles from './auth.module.scss';

  type LayoutProps = {
    children: ReactNode,
    userType?: string,
  };

const Auth = ({ children, userType = '' }: LayoutProps) => {
  const [fetching, setFetching] = useState(true);
  const { state, dispatch } = useContext(AppContext);
  const location = useLocation();
  const authenticate = async () => {
    try {
      const user = await UserController.me();
      setFetching(false);
      if (!user.active) {
        goToPage(RouteName.VerifyUser);
      } else if (userType === '' || user.loginRole === userType) {
        await dispatch({ type: appActions.USER_LOGGED, user });
        if (user.lastName === '' || user.name === '' || user.email === '' || user.phoneNumber === '') {
          if (user.loginRole === Users.Tenant) {
            goToPage(RouteName.HomeTenant, {}, { showModal: true });
          } else {
            goToPage(RouteName.Properties, {}, { showModal: true });
          }
        }
      } else {
        goToPage(RouteName.ErrorPage);
      }
    } catch (e) {
      const routeName = routes.find((elem: any) => elem.path === location.pathname);
      const redirectWhenLogged = routeName?.name;
      dispatch({ type: appActions.SAVE_POST_AUTH_REDIRECT, redirectWhenLogged });
      dispatch({ type: appActions.SESSION_EXPIRED });
      goToPage(RouteName.Login);
    }
  };

  useEffect(() => {
    authenticate();
  }, []);

  if (fetching) {
    return (
      <div className={styles.spinner}>
        <Spinner fixed={false} />
      </div>
    );
  }
  if (state.logged) {
    return (
      <>
        {children}
      </>
    );
  }
  return null;
};

export { Auth };
