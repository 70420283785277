import { Spinner } from '@mapix/common/src/common/spinner';
import { Property } from 'models/property';
import { PropertyController } from 'networking/controllers/property-controller';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EditBuilding } from './edit-building';
import { EditCondo } from './edit-condo';

type ParamType = {
  id: string,
};

type ModalState = {
  showClose: boolean,
  showOk: boolean
};

const initialModalState: ModalState = {
  showClose: false,
  showOk: false,
};

const EditProperty = () => {
  const { id } = useParams<ParamType>();
  const [fetching, setFetching] = useState(false);
  const [modalState, setModalState] = useState<ModalState>(initialModalState);
  const [property, setProperty] = useState<Property>();

  const getProperty = async () => {
    try {
      setFetching(true);
      const result = await PropertyController.getDetailedProperty(Number(id));
      setProperty(result);
      setFetching(false);
    } catch (err: any) {
      setFetching(false);
    }
  };

  useEffect(() => {
    getProperty();
  }, []);

  if (fetching || !property) {
    return (<Spinner />);
  }

  return (
    <>
      {property.type === 'Building' ? (
        <EditBuilding
          comingProperty={property}
          getProperty={getProperty}
          modalState={modalState}
          setModalState={setModalState}
        />
      )
        : (
          <EditCondo
            comingProperty={property}
            getProperty={getProperty}
            modalState={modalState}
            setModalState={setModalState}
          />
        )}
    </>
  );
};

export { EditProperty };
