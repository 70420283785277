import { useTranslation } from 'react-i18next';
import { FormEvent, useEffect, useState } from 'react';
import { ErrorMessage } from '@mapix/common/src/common/error-message';
import { logger } from 'helpers/logger';
import { Spinner } from '@mapix/common/src/common/spinner';
import { UserController } from 'networking/controllers/user-controller';
import { LegalBusinessInfo as CommonLegalBusinessInfo } from '@mapix/common/src/common/payment-information/pages/legal-business';
import { goToPage, RouteName } from 'routes';
import { checkHTMLErrors, isEmptyObject } from '@mapix/common/src/helpers/utils';
import { ApiError, ErrorStatus } from 'models/api-error';
import {
  LegalBusinessInfoType, RawErrorFields, RawLegalBusinessInfo,
} from '@mapix/common/src/types';
import { PaymentInformationController } from 'networking/controllers/payment-information-controller';
import { PaymentInformationSerializer } from 'networking/serializers/payment-information-serializer';

const initLegalBusinessInfo: LegalBusinessInfoType = {
  typeOfOwnership: '',
  legalName: '',
  civicNumber: '',
  streetName: '',
  poBox: '',
  unit: '',
  city: '',
  province: '',
  postalCode: '',
  primaryPhoneNumber: '',
  gstTaxId: '',
  url: '',
  businessDescription: '',
  mobilePhoneNumber: '',
  primaryCountryCode: '',
  mobileCountryCode: '',
};

type SelectOptionsState = {
  province: string[],
  typeOfOwnership: string[],
};

const initialSelectOptionsState = {
  province: [],
  typeOfOwnership: [],
};

const filteredSelectCalls = {
  businessDescription: () => PaymentInformationController.getBusinessDescriptions(),
};

const LegalBusinessInformation = () => {
  // TODO payment information remove this condition
  goToPage(RouteName.ErrorPage);

  const { t } = useTranslation();
  const [fetching, setFetching] = useState(true);
  const [showError, setShowError] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [phoneErrors, setPhoneErrors] = useState({});
  const [emptyForm, setEmptyForm] = useState(true);
  const [selectOptions, setSelectOptions] = useState<SelectOptionsState>(initialSelectOptionsState);
  const [legalBusinessInfo, setLegalBusinessInfo] = useState<
  LegalBusinessInfoType>(initLegalBusinessInfo);

  const getCanadaProvinces = async () => {
    try {
      const result = await PaymentInformationController.getCanadaProvinces();
      setSelectOptions((prevState) => ({ ...prevState, province: result }));
    } catch (e) {
      logger.error(e as Error);
      setShowError(true);
    }
  };

  const getTypeOfOwnership = async () => {
    try {
      const result = await PaymentInformationController.getTypeOfOwnership();
      setSelectOptions((prevState) => ({ ...prevState, typeOfOwnership: result }));
    } catch (e) {
      logger.error(e as Error);
      setShowError(true);
    }
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    if (!target.checkValidity()) {
      setFormErrors(checkHTMLErrors(target));
      return;
    }

    if (!isEmptyObject(phoneErrors)) {
      return;
    }

    setFetching(true);
    try {
      if (emptyForm) {
        await PaymentInformationController.newLegalBusinessInfo(legalBusinessInfo);
      } else {
        await PaymentInformationController.editLegalBusinessInfo(legalBusinessInfo);
      }
      setFetching(false);
      goToPage(RouteName.ManagerPaymentInformation);
    } catch (error) {
      if (error instanceof ApiError && typeof error.details === 'object') {
        setFormErrors(PaymentInformationSerializer.deserializeLegalBusinessInfoErrors(
          error.details as RawErrorFields<RawLegalBusinessInfo>,
          t,
        ));
      } else {
        setShowError(true);
        logger.error(error as Error);
      }
      setFetching(false);
    }
  };

  const getLegalBuisnessData = async () => {
    try {
      const result = await PaymentInformationController.getLegalBusinessInfo();
      setLegalBusinessInfo(result);
      setEmptyForm(false);
    } catch (e) {
      // this check if there is no data loaded, so is the first time the user completes the form.
      if (e instanceof ApiError && e.status === ErrorStatus.NotFound) {
        setEmptyForm(true);
      } else {
        logger.error(e as Error);
        setShowError(true);
      }
    }
    setFetching(false);
  };

  useEffect(() => {
    getLegalBuisnessData();
    getCanadaProvinces();
    getTypeOfOwnership();
  }, []);

  if (fetching) {
    return (<Spinner />);
  }

  return (
    <>
      {showError
      && (
        <ErrorMessage
          message={t('error.errorMessage')}
          handleClose={() => setShowError(false)}
        />
      )}
      <CommonLegalBusinessInfo
        t={t}
        backFn={() => goToPage(RouteName.ManagerPaymentInformation)}
        legalBusinessInfo={legalBusinessInfo}
        setLegalBusinessInfo={setLegalBusinessInfo}
        formErrors={{ ...formErrors, ...phoneErrors }}
        setPhoneErrors={setPhoneErrors}
        validatePhone={UserController.phoneValidation}
        onSubmit={onSubmit}
        setFormErrors={setFormErrors}
        selectOptions={selectOptions}
        filteredSelectCalls={filteredSelectCalls}
        goToHomeFn={() => goToPage(RouteName.Home)}
        goToMyProfileFn={() => goToPage(RouteName.MyProfile)}
        goToPaymentInformationFn={() => goToPage(RouteName.ManagerPaymentInformation)}
      />

    </>
  );
};

export { LegalBusinessInformation };
