import { API_ROUTES } from 'networking/api-routes';
import { ApiService } from 'networking/api-service';
import { PaginatorSerializer } from 'networking/serializers/paginator-serializer';
import { Paginator } from 'models/paginator';
import { LeaseTableItem } from 'models/lease-table-item';
import { DetailedLeaseSerializer } from 'networking/serializers/detailed-lease-serializer';
import { DateFilter } from 'common/filter-by-date/filter-by-date';
import { addDateFilterToURL } from 'helpers/utils';
import { LeaseDetail } from 'models/lease-detail';
import { LeaseSerializer } from 'networking/serializers/lease-serializer';
import { EndLeaseSerializer } from 'networking/serializers/end-lease-serializer';
import { Lease } from 'models/lease';

class LeaseController {
  static async getLeases(
    page: number,
    pageSize: number,
    query: string,
    type: string,
    dateFilter?: DateFilter,
  ) {
    const url = `${API_ROUTES
      .GET_LEASES(page, pageSize, query, type)}&${addDateFilterToURL(dateFilter)}`;
    const result = await ApiService.get<RawPaginatorContainer<RawDetailedLease>>(url);
    const { results, navigation } = result.data;
    const paginator = PaginatorSerializer.deSerialize(navigation);

    return new Paginator<LeaseTableItem>(
      paginator,
      results.map((item) => new LeaseTableItem(
        DetailedLeaseSerializer.deSerializeDetailedLease(item),
      )),
    );
  }

  static async getDetailedLease(id: number) {
    const url = `${API_ROUTES.GET_LEASE(id)}`;
    const result = await ApiService.get<RawDetailedLease>(url);
    const serializedLease = DetailedLeaseSerializer.deSerializeDetailedLease(result.data);
    return new LeaseDetail(serializedLease);
  }

  static async renewLease(lease: LeaseDetail) {
    const serializedLease = DetailedLeaseSerializer.serializeRenewLease(lease);
    return ApiService.post<RawRenewLease>(
      API_ROUTES.RENEW_LEASE(lease.id),
      serializedLease,
    );
  }

  static async createLease(propertyId: number, lease: SerializedLease) {
    return ApiService.post<RawLease>(
      API_ROUTES.CREATE_LEASE_PROPERTY(propertyId),
      LeaseSerializer.serialize(lease),
    );
  }

  static async endLease(leaseId: number, rentalActions: SerializedEndLease) {
    const serializedEndLease = EndLeaseSerializer.serializeEndLease(rentalActions);
    return ApiService.post<RawEndLease>(API_ROUTES.END_LEASE(leaseId), serializedEndLease);
  }

  static async createUnitLease(propertyId: number, unitId: number, lease: SerializedLease) {
    const result = await ApiService.post<RawLease>(
      API_ROUTES.CREATE_UNIT_LEASE(propertyId, unitId),
      LeaseSerializer.serialize(lease),
    );
    const deserializedLease = LeaseSerializer.deSerialize(result.data);
    return new Lease(deserializedLease);
  }

  static async endLeaseAndArchiveProperty(leaseId: number, rentalActions: SerializedEndLease) {
    const serializedEndLease = EndLeaseSerializer.serializeEndLease(rentalActions);
    return ApiService.post<RawEndLease>(
      API_ROUTES.END_LEASE_ARCHIVE_PROPERTY(leaseId),
      serializedEndLease,
    );
  }
}

export { LeaseController };
