import { classnames, HTMLValidationError } from '@mapix/common/src/helpers/utils';
import { Property } from 'models/property';
import { LeaseForm } from 'pages/create-property/create-building/lease-form';
import { ReactComponent as AlertFilled } from 'assets/icons/alert-circle-filled.svg';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Unit } from 'models/unit';
import { Condition, PropertyStatus } from 'common/enums';
import styles from './edit-common.module.scss';

type EditCommonProps = {
  property: Property | Unit,
  setProperty: Dispatch<SetStateAction<Property>>,
  setUnit: (newUnit: Unit) => void,
  formErrors: HTMLValidationError,
  isCondo?: boolean,
  setPhoneErrors: (value: SetStateAction<HTMLValidationError>) => void,

};

const EditCommon = ({
  property, setProperty, setUnit, formErrors, isCondo = false, setPhoneErrors,
}: EditCommonProps) => {
  const { t } = useTranslation();

  const onChange = (event: ChangeEvent<HTMLInputElement>, objectToChange: string) => {
    const { value, name } = event.target;
    // casting object to see if it is a unit or a property
    if ((property as Property).type) {
      if (objectToChange === 'lease') {
        if (property.lease) {
          const newData = {
            ...property as Property,
          };
          newData.lease = {
            ...property.lease,
            [name]: value,
          };
          setProperty(newData);
        }
      } else if (objectToChange === 'service') {
        if (property.lease) {
          const newData = {
            ...property as Property,
            lease: {
              ...property.lease,
              services: {
                ...property.lease.services,
                other: value,
              },
            },
          };
          setProperty(newData);
        }
      }
    } else if ((property as Unit).unitNumber) {
      if (objectToChange === 'lease') {
        if (property.lease) {
          const newData = {
            ...property as Unit,
          };
          newData.lease = {
            ...property.lease,
            [name]: value,
          };
          setUnit(newData);
        }
      } else if (objectToChange === 'service') {
        if (property.lease) {
          const newData = {
            ...property as Unit,
            lease: {
              ...property.lease,
              services: {
                ...property.lease.services,
                other: value,
              },
            },
          };
          setUnit(newData);
        }
      }
    }
  };

  const onChangeDateFn = (date: string, objectToChange: string) => {
    if ((property as Property).type) {
      if (property.lease) {
        if (objectToChange === 'startDate') {
          const newData = {
            ...property as Property,
          };
          newData.lease = {
            ...property.lease,
            startDate: date,
          };
          setProperty(newData);
        } else if (objectToChange === 'expirationDate') {
          const newData = {
            ...property as Property,
          };
          newData.lease = {
            ...property.lease,
            expirationDate: date,
          };
          setProperty(newData);
        }
      }
    } else if ((property as Unit).unitNumber) {
      if (property.lease) {
        if (objectToChange === 'startDate') {
          const newData = {
            ...property as Unit,
          };
          newData.lease = {
            ...property.lease,
            startDate: date,
          };
          setUnit(newData);
        } else if (objectToChange === 'expirationDate') {
          const newData = {
            ...property as Unit,
          };
          newData.lease = {
            ...property.lease,
            expirationDate: date,
          };
          setUnit(newData);
        }
      }
    }
  };

  const onClickService = (key: any) => {
    if ((property as Property).type && property.lease) {
      const modifiedService: any = { ...property.lease.services };
      modifiedService[key] = !modifiedService[key];
      const newData = {
        ...property as Property,
      };
      newData.lease = {
        ...property.lease,
        services: modifiedService,
      };
      setProperty(newData);
    } else if ((property as Unit).unitNumber && property.lease) {
      const modifiedService: any = { ...property.lease.services };
      modifiedService[key] = !modifiedService[key];
      const newData = {
        ...property as Unit,
      };
      newData.lease = {
        ...property.lease,
        services: modifiedService,
      };
      setUnit(newData);
    }
  };

  const updateFiles = (field: string, files: FileType[]) => {
    if ((property as Property).type && property.lease) {
      const newData = {
        ...property as Property,
      };
      if (field === 'pdf') {
        newData.lease = {
          ...property.lease,
          leaseCopy: files[0],
        };
      } else {
        newData.lease = {
          ...property.lease,
          [field]: files.map((item) => item.url),
        };
      }
      setProperty(newData);
    } else if ((property as Unit).unitNumber && property.lease) {
      const newData = {
        ...property as Unit,
      };
      if (field === 'pdf') {
        newData.lease = {
          ...property.lease,
          leaseCopy: files[0],
        };
      } else {
        newData.lease = {
          ...property.lease,
          [field]: files.map((item) => item.url),
        };
      }
      setUnit(newData);
    }
  };

  const changeSelectLeaseForm = (field: string, value: string | number) => {
    if ((property as Property).type && property.lease) {
      const newData = {
        ...property as Property,
      };
      newData.lease = {
        ...property.lease,
        [field]: value,
      };
      setProperty(newData);
    } else if ((property as Unit).unitNumber && property.lease) {
      const newData = {
        ...property as Unit,
      };
      newData.lease = {
        ...property.lease,
        [field]: value,
      };
      setUnit(newData);
    }
  };

  const onChangePhoneNumber = (phoneNumber: string, countryCode: string) => {
    if (property.lease) {
      setProperty((prevState: Property) => ({
        ...prevState,
        lease: {
          ...prevState.lease!,
          tenant: {
            ...prevState.lease!.tenant, phoneNumber, countryCode,
          },
        },
      }));
    }
  };

  const onChangeYearlyOrMonthly = (e: ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    if ((property as Property).type && property.lease) {
      if (target.valueAsNumber === 0) {
        const newProperty = {
          ...property as Property,
          lease: {
            ...property.lease,
            yearlyRental: 0,
            monthlyRental: 0,
          },
        };
        setProperty(newProperty);
      } else {
        const newProperty = {
          ...property as Property,
          lease: {
            ...property.lease,
            [target.name]: target.valueAsNumber,
          },
        };
        setProperty(newProperty);
      }
    } else if ((property as Unit).unitNumber && property.lease) {
      if (target.valueAsNumber === 0) {
        const newProperty = {
          ...property as Unit,
          lease: {
            ...property.lease,
            yearlyRental: 0,
            monthlyRental: 0,
          },
        };
        setUnit(newProperty);
      } else {
        const newProperty = {
          ...property as Unit,
          lease: {
            ...property.lease,
            [target.name]: target.valueAsNumber,
          },
        };
        setUnit(newProperty);
      }
    }
  };

  return (
    <>
      {property.lease && (
      <>
        <div className={classnames(styles.formTitle, 'text__heading6__textNeutral50')}>
          {t('editCondo.legalInfo.title')}
        </div>
        <div className={classnames(styles.leaseSubtitle, 'text__body__medium__textNeutral50')}>
          <AlertFilled className={styles.alertIcon} />
          {t('editCondo.legalInfo.subtitle')}
        </div>
        <LeaseForm
          isCondo={isCondo}
          lease={property.lease}
          onChangeLeaseFn={(e: ChangeEvent<HTMLInputElement>) => onChange(e, 'lease')}
          onClickServiceFn={(service: string) => onClickService(service)}
          onChangeFilesFn={(field: string, files: FileType[]) => updateFiles(field, files)}
          onClickSelectFn={(field, value) => changeSelectLeaseForm(field, value)}
          onChangePhoneNumber={onChangePhoneNumber}
          onChangeOtherText={(e: ChangeEvent<HTMLInputElement>) => onChange(e, 'service')}
          formErrors={formErrors}
          otherText={property.lease.services.other}
          onChangeYearlyMonthly={onChangeYearlyOrMonthly}
          disableEdit={property.lease.status !== Condition.Draft
            && property.status !== PropertyStatus.Available}
          onChangeDateFn={onChangeDateFn}
          setPhoneErrors={setPhoneErrors}
        />

      </>
      )}
    </>
  );
};

export { EditCommon };
