import { NewTaskSerializer as NewTaskSerializerCommon } from '@mapix/common/src/networking/serializers/new-task-serializer';
import { SpecializationSerializer } from '@mapix/common/src/networking/serializers/specialization-serializer';
import {
  CancelTaskData,
  ModifyCancellationData,
  ModifyRate,
  RawCancellationReasons,
  RawRejectRequest,
  RejectRequest,
} from '@mapix/common/src/types';
import { RawTaskMessage } from '@mapix/common/src/types/private-request';
import { TaskMessageRequest } from '@mapix/common/src/types/request';
import { RawSpecialization, Specialization } from '@mapix/common/src/types/specialization';
import { ModalFilterItem } from 'common/filter-modal/filter-modal';
import { addDateAndFiltersToURL, maintenanceTypeConverter, maintenanceTypeConverterV2 } from 'helpers/utils';
import { Claim } from 'models/claim';
import { Contractor } from 'models/contractor';
import { MaintenanceTableItem } from 'models/maintenance-table-item';
import { Paginator } from 'models/paginator';
import { Task } from 'models/task';
import { TenantSimpleProperty } from 'models/tenant-simple-property';
import { ContractorSerializer } from 'networking/serializers/contractor-serializer';
import { MaintenanceSerializer } from 'networking/serializers/maintenance-serializer';
import { MessageSerializer } from 'networking/serializers/message-serializer';
import { NewTaskSerializer } from 'networking/serializers/new-task-serializer';
import { PrivateRequestSerializer } from 'networking/serializers/private-request-serializer';
import { PublicRequestSerializer } from 'networking/serializers/public-request-serializer';
import { RequestSerializer } from 'networking/serializers/request-serializer';
import { RawNewTask, RawPrivateRequest } from 'networking/types';
import { CreateClaimData, RawNewClaim } from 'networking/types/claim-auxiliar';
import { RawPublicRequest, RawPublicRequestIndex } from 'networking/types/public-request';
import { API_ROUTES } from '../api-routes';
import { ApiService } from '../api-service';
import { PaginatorSerializer } from '../serializers/paginator-serializer';

type SelectedValueFilter = {
  startDate: string,
  endDate: string,
  code: string,
};

class MaintenanceController {
  static async getTasks(
    page: number,
    pageSize: number,
    filterDate: SelectedValueFilter,
    type?: string,
    query?: string,
    filters?: ModalFilterItem[],
  ) {
    const url = `${API_ROUTES
      .GET_TASKS(page, pageSize, maintenanceTypeConverter(type), query)}&${addDateAndFiltersToURL(filterDate, filters)}`;
    const result = await ApiService.get<RawPaginatorContainer<RawTask>>(url);
    const { results, navigation } = result.data;
    const paginator = PaginatorSerializer.deSerialize(navigation);
    return new Paginator<MaintenanceTableItem>(
      paginator,
      results.map((item) => new MaintenanceTableItem(MaintenanceSerializer.deSerialize(item))),
    );
  }

  static async getTaskDetail(id: string) {
    const result = await ApiService.get<ViewTaskBackend>(API_ROUTES
      .GET_TASK_DETAIL(id));
    const serializedTask = MaintenanceSerializer.deSerializeTask(result.data);
    return new Task(serializedTask);
  }

  static async editTask(task: SolveATask) {
    const serializedTask = MaintenanceSerializer.serializeSolveATask(task);
    const result = await ApiService.put<ViewTaskBackend>(API_ROUTES
      .GET_TASK_DETAIL(serializedTask.id), serializedTask);
    const deSerializedTask = MaintenanceSerializer.deSerializeTask(result.data);
    return new Task(deSerializedTask);
  }

  static async acceptTask(task: AcceptATask) {
    const serializedTask = MaintenanceSerializer.serializeAcceptTask(task);
    const result = await ApiService.put<ViewTaskBackend>(API_ROUTES
      .GET_TASK_DETAIL(serializedTask.id), serializedTask);
    const deSerializedTask = MaintenanceSerializer.deSerializeTask(result.data);
    return new Task(deSerializedTask);
  }

  static async cancelTask(id: number) {
    const result = await ApiService.put<ViewTaskBackend>(API_ROUTES
      .GET_TASK_DETAIL(id), {
      id,
      status: 'Rejected',
    });
    const deSerializedTask = MaintenanceSerializer.deSerializeTask(result.data);
    return new Task(deSerializedTask);
  }

  static async rejectTask(data: RejectTask) {
    const serializedTask = MaintenanceSerializer.serializeRejectTask(data);
    const result = await ApiService.put<ViewTaskBackend>(API_ROUTES
      .GET_TASK_DETAIL(serializedTask.id), serializedTask);
    const deSerializedTask = MaintenanceSerializer.deSerializeTask(result.data);
    return new Task(deSerializedTask);
  }

  static async cancelAssignement(id: number) {
    const result = await ApiService.put<ViewTaskBackend>(API_ROUTES
      .GET_TASK_DETAIL(id), {
      contractor_id: null,
    });
    const deSerializedTask = MaintenanceSerializer.deSerializeTask(result.data);
    return new Task(deSerializedTask);
  }

  static async getClaimsOfTenant(
    page: number,
    pageSize: number,
    filterDate: SelectedValueFilter,
    property: TenantSimpleProperty,
    query?: string,
    filters?: ModalFilterItem[],
  ) {
    let url;
    if (property.unitId) {
      url = `${API_ROUTES
        .GET_UNIT_CLAIMS_FROM_TENANT(page, pageSize, property.unitId, query)}&${addDateAndFiltersToURL(filterDate, filters)}`;
    } else {
      url = `${API_ROUTES
        .GET_CONDO_CLAIMS_FROM_TENANT(page, pageSize, property.id, query)}&${addDateAndFiltersToURL(filterDate, filters)}`;
    }
    const result = await ApiService.get<RawPaginatorContainer<ViewTaskBackend>>(url);
    const { results, navigation } = result.data;
    const paginator = PaginatorSerializer.deSerialize(navigation);
    return new Paginator<Task>(
      paginator,
      results.map((item) => new Task(MaintenanceSerializer.deSerializeTask(item))),
    );
  }

  static async addContractor(id: number, contractor: Contractor) {
    const serializedContractor = ContractorSerializer.serialize(contractor);
    const result = await ApiService.post<RawContractor>(API_ROUTES
      .ADD_CONTRACTOR(id), serializedContractor);
    const deserializedContractor = ContractorSerializer.deSerialize(result.data);
    return new Contractor(deserializedContractor);
  }

  static async newTask(task: SerializedCreateTask) {
    const serializedTask = MaintenanceSerializer.serializeTask(task);
    return ApiService.post<RawContractor>(API_ROUTES.ADD_TASK, serializedTask);
  }

  static async getTasksOfProperty(
    idProperty: number,
    page: number,
    pageSize: number,
    filterDate: SelectedValueFilter,
    query?: string,
    filters?: ModalFilterItem[],
  ) {
    const url = `${API_ROUTES
      .GET_TASKS_OF_PROPERTY(idProperty, page, pageSize, query)}&${addDateAndFiltersToURL(filterDate, filters)}`;
    const result = await ApiService.get<RawPaginatorContainer<RawTask>>(url);
    const { results, navigation } = result.data;
    const paginator = PaginatorSerializer.deSerialize(navigation);
    return new Paginator<MaintenanceTableItem>(
      paginator,
      results.map((item) => new MaintenanceTableItem(MaintenanceSerializer.deSerialize(item))),
    );
  }

  static async getTasksOfUnit(
    idProperty: number,
    idUnit: number,
    page: number,
    pageSize: number,
    filterDate: SelectedValueFilter,
    query?: string,
    filters?: ModalFilterItem[],
  ) {
    const url = `${API_ROUTES
      .GET_TASKS_OF_UNIT(idProperty, idUnit, page, pageSize, query)}&${addDateAndFiltersToURL(filterDate, filters)}`;
    const result = await ApiService.get<RawPaginatorContainer<RawTask>>(url);
    const { results, navigation } = result.data;
    const paginator = PaginatorSerializer.deSerialize(navigation);
    return new Paginator<MaintenanceTableItem>(
      paginator,
      results.map((item) => new MaintenanceTableItem(MaintenanceSerializer.deSerialize(item))),
    );
  }

  static async editContractor(id: number, task: SerializedCreateTask) {
    const serializedTask = MaintenanceSerializer.serializeTask(task);
    const result = await ApiService.put<ViewTaskBackend>(API_ROUTES
      .GET_TASK_DETAIL(id), serializedTask);
    const deSerializedTask = MaintenanceSerializer.deSerializeTask(result.data);
    return new Task(deSerializedTask);
  }

  static async getContractorsV1(
    pageNumber: number,
    pageSize: number,
    id: number,
    category?: string,
  ) {
    const result = await ApiService.get<RawPaginatorContainer<RawContractor>>(API_ROUTES
      .PROPERTY_CONTRACTORS(pageNumber, pageSize, id, category));
    const { results, navigation } = result.data;
    const paginator = PaginatorSerializer.deSerialize(navigation);
    return new Paginator<Contractor>(
      paginator,
      results.map((item) => new Contractor(ContractorSerializer.deSerialize(item))),
    );
  }

  static async newClaim(claim: CreateClaimData) {
    const serializedClaim = MaintenanceSerializer.serializeCreateClaim(claim);
    return ApiService.post<RawCreateClaimData>(API_ROUTES.CLAIMS, serializedClaim);
  }

  static async getClaims(
    pageNumber: number,
    pageSize: number,
    propertyId: number,
    unitId?: number,
    status?: string,
  ) {
    const url = API_ROUTES.INDEX_CLAIMS(pageNumber, pageSize, propertyId, unitId, status);

    const result = await ApiService.get<RawPaginatorContainer<RawNewClaim>>(url);
    return {
      navigation: PaginatorSerializer.deSerialize(result.data.navigation),
      results: MaintenanceSerializer.deSerializeNewClaimArray(result.data.results),
    };
  }

  static async editClaim(claimId: string, claim: SerializedEditClaim) {
    const serializedClaim = MaintenanceSerializer.serializeEditClaim(claim);
    return ApiService.put<RawCreateClaimData>(API_ROUTES.EDIT_CLAIM(claimId), serializedClaim);
  }

  static async getClaim(id: string) {
    const result = await ApiService.get<ViewTaskBackend>(API_ROUTES
      .GET_CLAIM_DETAIL(id));
    const serializedTask = MaintenanceSerializer.deSerializeTask(result.data);
    return new Task(serializedTask);
  }

  static async getTaskDetailByContractorToken(id: string) {
    const result = await ApiService.get<ViewTaskBackend>(API_ROUTES
      .GET_TASK_BY_CONTRACTOR_TOKEN(id));
    const serializedTask = MaintenanceSerializer.deSerializeContractrTaskDetail(result.data);
    return serializedTask;
  }

  static async getDetailedClaim(id: string) {
    const result = await ApiService.get<RawClaim>(API_ROUTES
      .GET_CLAIM_DETAIL(id));
    const serializedTask = MaintenanceSerializer.deSerializeClaim(result.data);
    return new Claim(serializedTask);
  }

  static async getNewClaim(id: string) {
    const result = await ApiService.get<RawNewClaim>(API_ROUTES
      .CLAIM(id));
    return MaintenanceSerializer.deSerializeNewClaim(result.data);
  }

  static async cancelClaim(id: number) {
    const result = await ApiService.post<RawClaim>(API_ROUTES
      .CANCEL_TASK(id));
    const deSerializedClaim = MaintenanceSerializer.deSerializeClaim(result.data);
    return new Claim(deSerializedClaim);
  }

  static async saveEditedTask(data: SerializedCreateTask, taskId: string) {
    const serializedTask = MaintenanceSerializer.serializeTask(data);
    const result = await ApiService.put<ViewTaskBackend>(API_ROUTES
      .GET_TASK_DETAIL(taskId), serializedTask);
    const deSerializedTask = MaintenanceSerializer.deSerializeTask(result.data);
    return new Task(deSerializedTask);
  }

  static async getSpecializations(): Promise<Specialization[]> {
    const result = await ApiService.get<RawSpecialization[]>(API_ROUTES.GET_SPECIALIZATIONS);
    return SpecializationSerializer.deSerializeAllSpecializations(result.data);
  }

  // New task

  static async createNewTask(task: CreateNewTaskData) {
    const serializedTask = NewTaskSerializer.serializeNewTask(task);
    const result = await ApiService.post<RawNewTask>(API_ROUTES.NEW_TASKS, serializedTask);

    return NewTaskSerializer.deSerializeNewTask(result.data);
  }

  static async updateNewTask(task: CreateNewTaskData, id: number) {
    const serializedTask = NewTaskSerializer.serializeNewTask(task);
    const result = await ApiService.put<RawNewTask>(API_ROUTES.NEW_TASK(id), serializedTask);
    return NewTaskSerializer.deSerializeNewTask(result.data);
  }

  static async getPrivateRequest(id: string) {
    const result = await ApiService.get<RawPrivateRequest>(API_ROUTES
      .GET_PRIVATE_REQUEST(id));
    return PrivateRequestSerializer.deSerializePrivateRequest(result.data);
  }

  static async getNewTask(id: string) {
    const result = await ApiService.get<RawNewTask>(API_ROUTES
      .NEW_TASK(id));
    return NewTaskSerializer.deSerializeNewTask(result.data);
  }

  static async rejectPrivateRequest(id: number, data: RejectRequest) {
    const serializedData = RequestSerializer.serializeRejectRequestData(data);
    const result = await ApiService
      .post<RawRejectRequest>(API_ROUTES.REJECT_PRIVATE_REQUEST(id), serializedData);
    return result;
  }

  static async modifyRatePrivateRequest(id: number, data: ModifyRate) {
    const serializedData = MaintenanceSerializer.serializeModifyRate(data);
    const result = await ApiService
      .post<RawPrivateRequest>(API_ROUTES.MODIFY_RATE_PRIVATE_REQUEST(id), serializedData);
    return PrivateRequestSerializer.deSerializePrivateRequest(result.data);
  }

  static async modifyRatePublicRequest(id: number, data: ModifyRate) {
    const serializedData = MaintenanceSerializer.serializeModifyRate(data);
    const result = await ApiService
      .post<RawPublicRequest>(API_ROUTES.MODIFY_RATE_PUBLIC_REQUEST(id), serializedData);
    return PublicRequestSerializer.deSerializePublicRequest(result.data);
  }

  // ----------------- V2 ----------------
  static async getNewTasks(
    page: number,
    pageSize: number,
    filterDate: SelectedValueFilter,
    type?: string,
    query?: string,
    filters?: ModalFilterItem[],
  ) {
    const url = `${API_ROUTES
      .GET_NEW_TASKS(page, pageSize, maintenanceTypeConverterV2(type), query)}&${addDateAndFiltersToURL(filterDate, filters)}`;
    const result = await ApiService.get<RawPaginatorContainer<RawTask>>(url);
    const { results, navigation } = result.data;

    const paginator = PaginatorSerializer.deSerialize(navigation);

    return new Paginator<MaintenanceTableItem>(
      paginator,
      results.map((item) => new MaintenanceTableItem(MaintenanceSerializer.deSerialize(item))),
    );
  }

  static async getNewTaskDetail(id: string) {
    const result = await ApiService.get<RawNewTask>(API_ROUTES
      .NEW_TASK(id));

    return NewTaskSerializer.deSerializeNewTask(result.data);
  }

  static async acceptRatePrivateRequest(privateRequestId: number) {
    const result = await ApiService
      .post<RawPrivateRequest>(API_ROUTES.ACCEPT_RATE_PRIVATE_REQUEST(privateRequestId));

    return PrivateRequestSerializer.deSerializePrivateRequest(result.data);
  }

  static async acceptRatePublicRequest(publicRequestId: number) {
    const result = await ApiService
      .post<RawPublicRequest>(API_ROUTES.ACCEPT_RATE_PUBLIC_REQUEST(publicRequestId));

    return PublicRequestSerializer.deSerializePublicRequest(result.data);
  }

  static async moveTaskToInProgress(taskId: number) {
    const result = await ApiService
      .post<RawNewTask>(API_ROUTES.MOVE_TASK_TO_IN_PROGRESS(taskId));

    return NewTaskSerializer.deSerializeNewTask(result.data);
  }

  static async acceptTaskToInProgress(taskId: number) {
    const result = await ApiService
      .post<RawNewTask>(API_ROUTES.ACCEPT_IN_PROGRESS(taskId));

    return NewTaskSerializer.deSerializeNewTask(result.data);
  }

  static async acceptTaskToSolved(taskId: number) {
    const result = await ApiService
      .post<RawNewTask>(API_ROUTES.ACCEPT_SOLVED(taskId));

    return NewTaskSerializer.deSerializeNewTask(result.data);
  }

  static async rejectTaskToInProgress(taskId: number, message: string) {
    const serializedData = MaintenanceSerializer.serializeRejectTaskInProgressData({
      messageForInProgressMovementRejection: message,
    });

    const result = await ApiService
      .post<RawNewTask>(API_ROUTES.REJECT_IN_PROGRESS(taskId), serializedData);

    return NewTaskSerializer.deSerializeNewTask(result.data);
  }

  static async rejectTaskToSolved(taskId: number, message: string) {
    const serializedData = MaintenanceSerializer.serializeRejectTaskSolvedData({
      messageForSolvedMovementRejection: message,
    });

    const result = await ApiService
      .post<RawNewTask>(API_ROUTES.REJECT_SOLVED(taskId), serializedData);

    return NewTaskSerializer.deSerializeNewTask(result.data);
  }

  static async modifyRateTask(taskId: number, data: ModifyRate) {
    const serializedData = MaintenanceSerializer.serializeModifyRate(data);

    const result = await ApiService
      .post<RawNewTask>(API_ROUTES.MODIFY_RATE_TASK(taskId), serializedData);

    return NewTaskSerializer.deSerializeNewTask(result.data);
  }

  static async acceptRateTask(taskId: number) {
    const result = await ApiService
      .post<RawNewTask>(API_ROUTES.ACCEPT_RATE_TASK(taskId));

    return NewTaskSerializer.deSerializeNewTask(result.data);
  }

  static async sendPrivateRequestMessage(requestId: number, data: TaskMessageRequest) {
    const serializedData = NewTaskSerializer.serializeSendTaskMessage(data);

    const result = await ApiService
      .post<RawTaskMessage>(API_ROUTES.SEND_PRIVATE_REQUEST_MESSAGE(requestId), serializedData);

    return MessageSerializer.deSerializeMessage(result.data);
  }

  static async sendPublicRequestMessage(requestId: number, data: TaskMessageRequest) {
    const serializedData = NewTaskSerializer.serializeSendTaskMessage(data);

    const result = await ApiService
      .post<RawTaskMessage>(API_ROUTES.SEND_PUBLIC_REQUEST_MESSAGE(requestId), serializedData);

    return MessageSerializer.deSerializeMessage(result.data);
  }

  static async moveTaskToSolved(taskId: number) {
    const result = await ApiService
      .post<RawNewTask>(API_ROUTES.MOVE_TASK_TO_SOLVED(taskId));

    return NewTaskSerializer.deSerializeNewTask(result.data);
  }

  static async disableInProgressRejectedFlag(taskId: number) {
    const result = await ApiService.post<RawNewTask>(API_ROUTES
      .DISABLE_IN_PROGRESS_REJECTED_FLAG(taskId));
    return NewTaskSerializer.deSerializeNewTask(result.data);
  }

  static async disableSolvedRejectedFlag(taskId: number) {
    const result = await ApiService.post<RawNewTask>(API_ROUTES
      .DISABLE_SOLVED_REJECTED_FLAG(taskId));
    return NewTaskSerializer.deSerializeNewTask(result.data);
  }

  static async assignPublicTask(publicRequestId: number) {
    const result = await ApiService.post<RawPublicRequest>(API_ROUTES
      .ASSIGN_PUBLIC_REQUEST(publicRequestId));

    return PublicRequestSerializer.deSerializePublicRequest(result.data);
  }

  static async rejectPublicTask(publicRequestId: number, data: RejectRequest) {
    const serializedData = RequestSerializer.serializeRejectRequestData(data);

    const result = await ApiService.post<RawPublicRequest>(API_ROUTES
      .REJECT_PUBLIC_REQUEST(publicRequestId), serializedData);

    return PublicRequestSerializer.deSerializePublicRequest(result.data);
  }

  static async getContractorApplications(
    pageNumber: number,
    pageSize: number,
    taskId: number,
    order?: string,
    query?: string,
    specializations?: number[],
  ) {
    const result = await ApiService.get<RawPaginatorContainer<RawPublicRequestIndex>>(API_ROUTES
      .APPLICATIONS(pageNumber, pageSize, taskId, order, query, specializations));

    return {
      navigation: PaginatorSerializer.deSerialize(result.data.navigation),
      results: PublicRequestSerializer.deSerializePublicRequestIndexArray(result.data.results),
    };
  }

  static async getPublicRequest(id: string) {
    const result = await ApiService.get<RawPublicRequest>(API_ROUTES.GET_PUBLIC_REQUEST(id));

    return PublicRequestSerializer.deSerializePublicRequest(result.data);
  }

  static async cancelNewTask(taskId: number, data: CancelTaskData) {
    const serializedData = MaintenanceSerializer.serializeCancelTaskData(data);
    const result = await ApiService
      .post<RawNewTask>(API_ROUTES.CANCEL_NEW_TASK(taskId), serializedData);
    return NewTaskSerializer.deSerializeNewTask(result.data);
  }

  static async changeCancelPercentage(taskId: number, data: ModifyCancellationData) {
    const serializedData = MaintenanceSerializer.serializeModifyCancellationTaskData(data);
    const result = await ApiService
      .post<RawNewTask>(API_ROUTES.CHANGE_CANCEL_PERCENTAGE(taskId), serializedData);

    return NewTaskSerializer.deSerializeNewTask(result.data);
  }

  static async getCancellationReasons() {
    const result = await ApiService.get<RawCancellationReasons>(API_ROUTES
      .CANCELLATION_REASONS);

    return NewTaskSerializerCommon.deSerializeTaskCancellationReasons(result.data);
  }

  static async acceptCancellationPercentage(taskId: number) {
    const result = await ApiService.post<RawNewTask>(
      API_ROUTES.ACCEPT_CANCELLATION_PERCENTAGE(taskId),
    );

    return NewTaskSerializer.deSerializeNewTask(result.data);
  }

  static async disableNoticeCancellationFlag(taskId: number) {
    const result = await ApiService.post<RawNewTask>(
      API_ROUTES.DISABLE_NOTICE_CANCELLATION_FLAG(taskId),
    );

    return NewTaskSerializer.deSerializeNewTask(result.data);
  }

  static async rejectClaim(claimId: number, data: RejectClaim) {
    const serializedData = RequestSerializer.serializeRejectRequestData(data);

    const result = await ApiService.post<RawNewTask>(API_ROUTES
      .REJECT_CLAIM(claimId), serializedData);

    return NewTaskSerializer.deSerializeNewTask(result.data);
  }

  static async acceptClaim(claimId: number, data: AcceptClaim) {
    const serializedAcceptClaim = MaintenanceSerializer.serializeAcceptClaimData(data);
    const result = await ApiService.post<RawNewTask>(API_ROUTES
      .ACCEPT_CLAIM(claimId), serializedAcceptClaim);

    return NewTaskSerializer.deSerializeNewTask(result.data);
  }
}

export { MaintenanceController };
