import { useRef, useState } from 'react';
import { Modal } from '../modal';
import { classnames } from '../../helpers/utils';
import { ButtonClose } from '../button-close';
import { ReactComponent as ChevronLeft } from '../../assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from '../../assets/icons/chevron-right.svg';
import styles from './image-overlay.module.scss';
import { useMediaQuery } from '../../hooks/use-media-query';
import { Breakpoints } from '../enums';
import { ReactComponent as Close } from '../../assets/icons/close.svg';
import { FileType } from '../../types';

type ImageOverlayProps = {
  photos: FileType[],
  selectedIndex: number,
  closeFn: () => void,
  t: (text: string) => string,

};

type ImageOverlayObject = {
  show: boolean,
  index: number,
  photos: FileType[],
};

const translPrefix = 'pictureModal';
const ImageOverlay = ({
  photos, selectedIndex, closeFn, t,
}: ImageOverlayProps) => {
  const [currentIndex, setIndex] = useState(selectedIndex);
  const containerRef = useRef(null);

  const currentPhoto = photos[currentIndex];

  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  return (

    <Modal>
      <>
        <div className={styles.closeButtonContainer}>
          {mobile
            ? (
              <button
                type="button"
                onClick={closeFn}
                className={styles.closeButton}
              >
                <Close />
              </button>

            ) : (
              <ButtonClose
                className={styles.closeButton}
                closeFn={closeFn}
                closeText={t(`${translPrefix}.close`)}
              />
            )}
        </div>
        {currentIndex !== 0
          && (
          <button
            type="button"
            onClick={() => setIndex(currentIndex - 1)}
            className={classnames(styles.circle, styles.left)}
          >
            <ChevronLeft className={styles.chevron} />
          </button>
          )}
        <img
          ref={containerRef}
          role="presentation"
          key={currentPhoto.url}
          className={styles.overlayImage}
          src={currentPhoto.url}
          alt={currentPhoto.filename}
        />
        {currentIndex !== photos.length - 1 && (
          <button
            type="button"
            onClick={() => setIndex(currentIndex + 1)}
            className={classnames(styles.circle, styles.right)}
          >
            <ChevronRight className={styles.chevron} />
          </button>
        )}
      </>
    </Modal>
  );
};

export { ImageOverlay };
export type { ImageOverlayObject };
