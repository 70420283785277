import { useTranslation } from 'react-i18next';
import { ChangeEvent, FormEvent, useState } from 'react';
import { checkHTMLErrors, classnames, HTMLValidationError } from '@mapix/common/src/helpers/utils';
import { Modal } from '@mapix/common/src/common/modal';
import { TextArea } from '@mapix/common/src/common/textarea';
import { logger } from 'helpers/logger';
import { Spinner } from '@mapix/common/src/common/spinner';
import { Button, ButtonStyle, ButtonType } from '@mapix/common/src/common/button';
import styles from './modal-keep-task-as-previous-status.module.scss';

type KeepTaskAsPreviousStatusModalProps = {
  title: string;
  content: string | React.ReactElement;
  textLeftButton: string;
  textRightButton: string;
  rejectTaskFn: (message: string) => Promise<void>;
  successFn: () => void;
  closeFn: () => void;
};

type StateData = {
  message: string;
};

const initState: StateData = {
  message: '',
};

const KeepTaskAsPreviousStatusModal = ({
  title, content, textLeftButton, textRightButton, rejectTaskFn, successFn, closeFn,
}: KeepTaskAsPreviousStatusModalProps) => {
  const [state, setState] = useState<StateData>(initState);
  const [formErrors, setFormErrors] = useState<HTMLValidationError>({});
  const [loading, setLoading] = useState(false);

  const onChange = (field: string, value: string) => {
    setState((prevState) => ({ ...prevState, [field]: value }));
  };

  const { t } = useTranslation();

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const target = event.target as HTMLFormElement;

    if (!target.checkValidity()) {
      setFormErrors(checkHTMLErrors(target));
      return;
    }

    try {
      setLoading(true);

      await rejectTaskFn(state.message);

      successFn();
    } catch (err: any) {
      logger.error(err);
    } finally {
      setLoading(false);
    }
  };

  const showSmallSpinner = () => (
    <div className={styles.spinnerContainer}>
      <Spinner small fixed={false} />
    </div>
  );

  const showConfirmButton = () => (
    <Button buttonType={ButtonType.Submit} className={classnames('text__body__large__textNeutral40', styles.button)}>
      {textRightButton}
    </Button>
  );

  return (
    <Modal>
      <form onSubmit={onSubmit} noValidate className={styles.modalContainer}>
        <div className={classnames(styles.title, 'text__heading6__textNeutral50')}>{title}</div>
        <div className="text__body__medium__textNeutral40">{content}</div>

        <div className="mt-20">
          <TextArea
            id="message"
            required
            value={state.message}
            type="text"
            maxLength={200}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onChange('message', e.target.value)}
            containerClass={styles.description}
            formError={formErrors}
            t={t}
          />
        </div>

        <div className={styles.buttons}>
          <Button
            buttonStyle={ButtonStyle.GhostSecondary}
            className={classnames('mr-20', 'text__body__large__textNeutral40', styles.button)}
            onClick={() => closeFn()}
          >
            {textLeftButton}
          </Button>
          {loading ? showSmallSpinner() : showConfirmButton()}
        </div>
      </form>
    </Modal>
  );
};

export { KeepTaskAsPreviousStatusModal };
