import { getFullAddress } from 'helpers/utils';
import { PropertyAddress } from './property-address';

class
LeaseTableItem {
  id: number;

  type: string;

  address: string;

  buildingName: string;

  unitNumber: string;

  rental: string;

  monthlyRental: number;

  condoFees: string;

  startDate: string;

  expDate: string;

  ownerName: string;

  tenantName: string;

  status: string;

  tenantEmail: string;

  constructor(params: SerializedDetailedLease | null | undefined) {
    this.id = params?.id || 0;
    this.type = params?.property?.type || '';
    this.address = params?.property.address ? getFullAddress(new PropertyAddress(params.property.address)) : '';
    this.buildingName = params?.property.buildingName || '';
    this.unitNumber = params?.unit?.unitNumber || '';
    this.rental = params?.monthlyRental ? `$ ${params.monthlyRental}` : '';
    this.monthlyRental = params?.monthlyRental || 0;
    this.condoFees = params?.fees ? `$ ${params.fees}` : '';
    this.startDate = params?.startDate || '';
    this.expDate = params?.expirationDate || '';
    this.tenantName = params?.tenant?.name || '';
    this.tenantEmail = params?.tenant?.email || '';
    this.ownerName = params?.property?.owner?.name || '';
    this.status = params?.status || '';
  }
}

export { LeaseTableItem };
