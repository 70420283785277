import { ApiService } from 'networking/api-service';
import { API_ROUTES } from 'networking/api-routes';
import { ModalFilterItem } from 'common/filter-modal/filter-modal';
import { addDateAndFiltersToURL, addDateFilterToURL } from 'helpers/utils';
import { PaginatorSerializer } from 'networking/serializers/paginator-serializer';
import { RentalDashboard } from 'models/rental-dashboard';
import { Paginator } from 'models/paginator';
import { RentalsSerializer } from 'networking/serializers/rentals-serializer';
import { DateFilter } from 'common/filter-by-date/filter-by-date';
import { RentalTenant } from 'models/rental-tenant';
import { TenantRentalsSerializer } from 'networking/serializers/tenant-rentals-serializer';
import { EndLeaseTableItem } from 'models/end-lease-table-item';

class RentalsController {
  static async getRentals(
    page: number,
    pageSize: number,
    dateFilter: DateFilter,
    type?: string,
    query?: string,
    filters?: ModalFilterItem[],
  ) {
    const url = `${API_ROUTES
      .GET_RENTALS(page, pageSize, type, query)}&${addDateAndFiltersToURL(dateFilter, filters)}`;
    const result = await ApiService.get<RawPaginatorContainer<RawRental>>(url);
    const { results, navigation } = result.data;
    const paginator = PaginatorSerializer.deSerialize(navigation);
    return new Paginator<RentalDashboard>(
      paginator,
      results.map((item) => new RentalDashboard(RentalsSerializer.deSerialize(item))),
    );
  }

  static async getRentalsFromBuilding(
    propertyId: number,
    page: number,
    pageSize: number,
    dateFilter: DateFilter,
    query?: string,
    filters?: ModalFilterItem[],
  ) {
    const url = `${API_ROUTES
      .GET_RENTALS_FROM_BUILDING(propertyId, page, pageSize, query)}&${addDateAndFiltersToURL(dateFilter, filters)}`;
    const result = await ApiService.get<RawPaginatorContainer<RawRental>>(url);
    const { results, navigation } = result.data;
    const paginator = PaginatorSerializer.deSerialize(navigation);
    return new Paginator<RentalDashboard>(
      paginator,
      results.map((item) => new RentalDashboard(RentalsSerializer.deSerialize(item))),
    );
  }

  static async getRental(id: number) {
    const result = await ApiService.get<RawRental>(API_ROUTES.RENTAL_RESOURCE(id));
    return new RentalDashboard(RentalsSerializer.deSerialize(result.data));
  }

  static async getTenantRental(id: number) {
    const result = await ApiService.get<RawTenantRental>(API_ROUTES.RENTAL_TENANT_RESOURCE(id));
    return TenantRentalsSerializer.deSerialize(result.data);
  }

  static async editRental(id: number, rental: SerializedPatchRental) {
    return ApiService.patch<RawPatchRental>(
      API_ROUTES.RENTAL_RESOURCE(id),
      RentalsSerializer.serializePatch(rental),
    );
  }

  static async getPropertyRentals(
    id: number,
    page: number,
    pageSize: number,
    dateFilter: DateFilter,
    query?: string,
    filters?: ModalFilterItem[],
  ) {
    const url = `${API_ROUTES
      .GET_PROPERTY_RENTALS(id, page, pageSize, query)}&${addDateAndFiltersToURL(dateFilter, filters)}`;
    const result = await ApiService.get<RawPaginatorContainer<RawRental>>(url);
    const { results, navigation } = result.data;
    const paginator = PaginatorSerializer.deSerialize(navigation);
    return new Paginator<RentalDashboard>(
      paginator,
      results.map((item) => new RentalDashboard(RentalsSerializer.deSerialize(item))),
    );
  }

  static async getUnitRentals(
    id: number,
    unitId: number,
    page: number,
    pageSize: number,
    dateFilter: DateFilter,
    query?: string,
    filters?: ModalFilterItem[],
  ) {
    const url = `${API_ROUTES
      .GET_UNIT_RENTALS(id, unitId, page, pageSize, query)}&${addDateAndFiltersToURL(dateFilter, filters)}`;
    const result = await ApiService.get<RawPaginatorContainer<RawRental>>(url);
    const { results, navigation } = result.data;
    const paginator = PaginatorSerializer.deSerialize(navigation);
    return new Paginator<RentalDashboard>(
      paginator,
      results.map((item) => new RentalDashboard(RentalsSerializer.deSerialize(item))),
    );
  }

  static async getTenantRentals(
    propertyId: number,
    page: number,
    pageSize: number,
    dateFilter: DateFilter,
    unitId?: number,
  ) {
    const url = unitId
      ? `${API_ROUTES
        .GET_TENANT_RENTALS_FROM_UNIT(propertyId, unitId, page, pageSize)}&${addDateFilterToURL(dateFilter)}`
      : `${API_ROUTES
        .GET_TENANT_RENTALS(propertyId, page, pageSize)}&${addDateFilterToURL(dateFilter)}`;

    const result = await ApiService.get<RawPaginatorContainer<RawTenantRental>>(url);
    const { results, navigation } = result.data;
    const paginator = PaginatorSerializer.deSerialize(navigation);
    return new Paginator<RentalTenant>(
      paginator,
      results.map((item) => new RentalTenant(TenantRentalsSerializer.deSerialize(item))),
    );
  }

  static async getRentalsOfALease(leaseId: number) {
    const result = await ApiService.get<RawRental[]>(API_ROUTES.GET_RENTALS_OF_A_LEASE(leaseId));
    return result.data.map((item) => new EndLeaseTableItem(RentalsSerializer.deSerialize(item)));
  }

  static async extendLease(leaseExtension: ExtendLeaseData, leaseId: number) {
    await ApiService.post<RawRental>(
      API_ROUTES.EXTEND_LEASE(leaseId),
      RentalsSerializer.serializeExtendLease(leaseExtension),
    );
  }
}

export { RentalsController };
