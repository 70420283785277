import { createBrowserHistory } from 'history';
import { ComponentType } from 'react';

const history = createBrowserHistory();

enum RouteName {
  Home = 'home',
  ErrorPage = 'error-page',
  Properties = 'properties',
  Maintenance = 'maintenance',
  InitialRegister = 'initial-register',
  Login = 'login',
  CreateProperties = 'createProperties',
  RegisterTenant = 'register-tenant',
  RegisterManager = 'register-manager',
  MyProfile = 'my-profile',
  PasswordRecovery = 'password-recovery',
  ResetPassword = 'reset-password',
  EditProfile = 'edit-profile',
  TaskDetail = 'task-detail',
  PrivacyPolicy = 'privacy-policy',
  TermsOfUse = 'terms-of-use',
  PropertyDetail = 'property-detail',
  EditProperty = 'edit-property',
  UnitDetail = 'unit-detail',
  HomeTenant = 'home-tenant',
  PaymentsTenant = 'payments-tenant',
  EditUnit = 'edit-unit',
  BillsTable = 'bills',
  Claims = 'claims-tenant',
  MyPropertyTenant = 'my-property',
  CreateTask = 'create-task',
  CreateNewTask = 'create-new-task',
  EditNewTask = 'edit-new-task',
  AssignContractor = 'assign-contractor',
  CreateClaim = 'create-claim',
  CreateClaimUnit = 'create-claim-unit',
  EditClaim = 'edit-claim',
  ContractorTaskDetail = 'contractor-task-detail',
  AddBill = 'add-bill',
  ClaimDetail = 'claim-detail',
  RentalDashboard = 'rentals',
  RentalBuildingDashboard = 'rentals-unit',
  Leases = 'leases',
  LeaseDetail = 'lease-detail',
  EndLease = 'end-lease',
  RenewLease = 'renew-lease',
  CreateContractor = 'create-contractor',
  EditContractor = 'edit-contractor',
  CreateUnit = 'create-unit',
  SetAsRented = 'set-as-rented',
  ExtendLease = 'extend-lease',
  EditRental = 'edit-rental',
  EditTask = 'edit-task',
  ActivateAccount = 'activate-acount',
  VerifyUser = 'verify-user',
  VerifyAccount = 'verify-account',
  ExtendLeaseUnit = 'extend-lease-unit',
  SetUnitAsRented = 'set-unit-as-rented',
  ManagerPaymentInformation = 'manager-payment-information',
  DBAinformation = 'dba-information',
  SalesProfile = 'sales-profile',
  ElectronicDebitCreditAuthorization = 'electronic-debt-credit-authorization',
  LegalBusinessInformation = 'legal-business-information',
  OwnersOrOfficers = 'owners-or-officers',
  DocumentsInformation = 'documents-information',
  MakePaymentPage = 'make-payment',
  PrivateRequestDetail = 'private-request-detail',
  PublicRequestDetail = 'public-request-detail',
  UnpublishedAndUnassignedTaskDetail = 'unpublished-and-unassigned-task-detail',
  NewTaskDetail = 'new-task-detail',
  TaskDetailClaim = 'task-detail-claim',

}

type Route = {
  name: RouteName,
  path: string,
  exact?: boolean,
  pathParams?: string[],
  component?: ComponentType,
};

const PATH_PARAM_REGEX = /:[^/]+/gi;
/**
  Analyzes the path defined for `route` and
  returns a copy of the route with a new attribute
  `pathParams` which is a list of strings that correspond to the path params.

  @param {object} route - Object that represents a route.

  @return {object} updated route with the new attribute.

  @example

    determineRouteParams({ name: 'product', path: '/product/:id', component: ProductPage })
*/
const determineRouteParams = (route: Route): Route => {
  const newRoute = { ...route };
  const { path } = newRoute;
  // Extract the names of the parameters
  const pathMatch = path.match(PATH_PARAM_REGEX) || [];
  const pathParams = pathMatch.map((param) => param.slice(1));
  newRoute.pathParams = pathParams || [];
  return newRoute;
};

const routes = [
  {
    name: RouteName.Home,
    path: '/',
    exact: true,
  },
  {
    name: RouteName.Properties,
    path: '/properties',
    exact: true,
  },
  {
    name: RouteName.Maintenance,
    path: '/maintenance',
  },
  {
    name: RouteName.InitialRegister,
    path: '/initial-register',
    exact: true,
  },
  {
    name: RouteName.Login,
    path: '/login',
    exact: true,
  },
  {
    name: RouteName.CreateProperties,
    path: '/properties/create',
  },
  {
    name: RouteName.RegisterTenant,
    path: '/register-tenant',
    exact: true,
  },
  {
    name: RouteName.RegisterManager,
    path: '/register-manager',
    exact: true,
  },
  {
    name: RouteName.MyProfile,
    path: '/my-profile',
    exact: true,
  },
  {
    name: RouteName.PasswordRecovery,
    path: '/password-recovery',
    exact: true,
  },
  {
    name: RouteName.ResetPassword,
    path: '/reset-password',
    exact: true,
  },
  {
    name: RouteName.EditProfile,
    path: '/edit-profile',
    exact: true,
  },
  {
    name: RouteName.TaskDetail,
    path: '/task-detail/:id',
  },
  {
    name: RouteName.TaskDetailClaim,
    path: '/task-detail-claim/:id',
  },
  {
    name: RouteName.CreateTask,
    path: '/create-task',
    exact: true,
  },
  {
    name: RouteName.CreateNewTask,
    path: '/create-new-task',
    exact: true,
  },
  {
    name: RouteName.EditNewTask,
    path: '/edit-new-task/:id',
    exact: true,
  },
  {
    name: RouteName.PrivacyPolicy,
    path: '/privacy-policy',
    exact: true,
  },
  {
    name: RouteName.PropertyDetail,
    path: '/property-detail/:id',
    exact: true,
  },
  {
    name: RouteName.SetAsRented,
    path: '/property-detail/:id/set-as-rented',
    exact: true,
  },
  {
    name: RouteName.TermsOfUse,
    path: '/terms-of-use',
    exact: true,
  },
  {
    name: RouteName.EditProperty,
    path: '/edit-property/:id',
    exact: true,
  },
  {
    name: RouteName.HomeTenant,
    path: '/home-tenant',
    exact: true,
  },
  {
    name: RouteName.PaymentsTenant,
    path: '/payments-tenant',
    exact: true,
  },
  {
    name: RouteName.UnitDetail,
    path: '/property-detail/:id/unit/:unitId',
    exact: true,
  },
  {
    name: RouteName.EditUnit,
    path: '/edit-unit/:propertyId/:unitId',
    exact: true,
  },
  {
    name: RouteName.MyPropertyTenant,
    path: '/my-property',
  },
  {
    name: RouteName.AssignContractor,
    path: '/assign-contractor/:taskId',
    exact: true,
  },
  {
    name: RouteName.Claims,
    path: '/claims-tenant',
    exact: true,
  },
  {
    name: RouteName.BillsTable,
    path: '/bills',
    exact: true,
  },
  {
    name: RouteName.Claims,
    path: '/claims-tenant',
    exact: true,
  },
  {
    name: RouteName.CreateClaim,
    path: '/tasks/:propertyId',
    exact: true,
  },
  {
    name: RouteName.CreateClaimUnit,
    path: '/tasks/:propertyId/:unitId',
    exact: true,
  },
  {
    name: RouteName.EditClaim,
    path: '/claims/edit-claim/:claimId',
  },
  {
    name: RouteName.ContractorTaskDetail,
    path: '/contractor-task-detail/:contractorToken',
    exact: true,
  },
  {
    name: RouteName.ClaimDetail,
    path: '/claim-detail/:id',
  },
  {
    name: RouteName.RentalBuildingDashboard,
    path: '/properties/:idProperty/rentals',
    exact: true,
  },
  {
    name: RouteName.RentalDashboard,
    path: '/rentals',
  },
  {
    name: RouteName.Leases,
    path: '/leases',
    exact: true,
  },
  {
    name: RouteName.LeaseDetail,
    path: '/lease-detail/:id',
  },
  {
    name: RouteName.AddBill,
    path: '/add-bill',
    exact: true,
  },
  {
    name: RouteName.EndLease,
    path: '/end-lease/:id',
    exact: true,
  },
  {
    name: RouteName.RenewLease,
    path: '/renew-lease/:id',
    exact: true,
  },
  {
    name: RouteName.CreateContractor,
    path: '/create-contractor/:propertyId',
    exact: true,
  },
  {
    name: RouteName.EditContractor,
    path: '/edit-contractor/:propertyId/:contractorId',
    exact: true,
  },
  {
    name: RouteName.CreateUnit,
    path: '/create-unit/:propertyId',
    exact: true,
  },
  {
    name: RouteName.ExtendLease,
    path: '/property-detail/:id/extend-lease',
  },
  {
    name: RouteName.EditRental,
    path: '/edit-rental/:id',
  },
  {
    name: RouteName.EditTask,
    path: '/edit-task/:id',
    exact: true,
  },
  {
    name: RouteName.ActivateAccount,
    path: '/activate-account',
  },
  {
    name: RouteName.VerifyUser,
    path: '/verify',
    exact: true,
  },
  {
    name: RouteName.VerifyAccount,
    path: '/verify-account',
  },
  {
    name: RouteName.ExtendLeaseUnit,
    path: '/property-detail/:id/unit/:unitId/extend-lease',
  },
  {
    name: RouteName.SetUnitAsRented,
    path: '/set-unit-as-rented/:propertyId/:unitId',
  },
  {
    name: RouteName.ManagerPaymentInformation,
    path: '/manager-payment-information',
  },
  {
    name: RouteName.DBAinformation,
    path: '/dba-information',
  },
  {
    name: RouteName.SalesProfile,
    path: '/sales-profile',
  },
  {
    name: RouteName.ElectronicDebitCreditAuthorization,
    path: '/electronic-debit-credit-authorization',
  },
  {
    name: RouteName.LegalBusinessInformation,
    path: '/legal-business-information',
  },
  {
    name: RouteName.OwnersOrOfficers,
    path: '/owners-or-officers',
  },
  {
    name: RouteName.DocumentsInformation,
    path: '/documents-information',
  },
  {
    name: RouteName.MakePaymentPage,
    path: '/make-payment/:rentalId',
  },
  {
    name: RouteName.PrivateRequestDetail,
    path: '/private-request-detail/:id',
  },
  {
    name: RouteName.PublicRequestDetail,
    path: '/public-request-detail/:id',
  },
  {
    name: RouteName.UnpublishedAndUnassignedTaskDetail,
    path: '/unpublished-and-unassigned-task-detail/:id',
  },
  {
    name: RouteName.ErrorPage,
    path: '/*',
  },
  {
    name: RouteName.ErrorPage,
    path: '*',
  },
].map(determineRouteParams);

export {
  determineRouteParams,
  history,
  RouteName,
  routes,
};
