import {
  OldTypeOfCost, RawOldTypeOfCost, RawTypeOfCost, TypeOfCost,
} from '../../types';

class TypeOfCostSerializer {
  static serializeTypeOfCost = (data: TypeOfCost): RawTypeOfCost => {
    switch (data.typeOfCost) {
      case 'budget':
        return {
          type_of_cost: 'budget',
          budget: data.budget,
          amount_of_hours: data.amountOfHours || null,
        };
      case 'hourlyRate':
        return {
          type_of_cost: 'hourly_rate',
          hourly_rate: data.hourlyRate,
          amount_of_hours: data.amountOfHours,
        };
      default:
        throw new Error('Invalid type of cost');
    }
  };

  static deSerializeTypeOfCost = (data: RawTypeOfCost): TypeOfCost => {
    switch (data.type_of_cost) {
      case 'budget':
        return {
          typeOfCost: 'budget',
          budget: data.budget,
          amountOfHours: data.amount_of_hours || undefined,
        };
      case 'hourly_rate':
        return {
          typeOfCost: 'hourlyRate',
          hourlyRate: data.hourly_rate,
          amountOfHours: data.amount_of_hours,
        };
      case null:
        return {
          budget: null,
          typeOfCost: null,
          hourlyRate: null,
          amountOfHours: null,
        };
      default:
        throw new Error('Invalid type of cost');
    }
  };

  static deSerializeOldTypeOfCost = (data: RawOldTypeOfCost): OldTypeOfCost => {
    switch (data.old_type_of_cost) {
      case 'budget':
        return {
          oldTypeOfCost: 'budget',
          oldBudget: data.old_budget,
          oldAmountOfHours: data.old_amount_of_hours || undefined,
        };
      case 'hourly_rate':
        return {
          oldTypeOfCost: 'hourlyRate',
          oldHourlyRate: data.old_hourly_rate,
          oldAmountOfHours: data.old_amount_of_hours,
        };
      case null:
        return {
          oldBudget: null,
          oldTypeOfCost: null,
          oldHourlyRate: null,
          oldAmountOfHours: null,
        };
      default:
        throw new Error('Invalid old type of cost');
    }
  };
}

export { TypeOfCostSerializer };
