import { BillArea } from 'common/enums';

class BillCreate {
  category: string;

  dueDate: string;

  unitId?: number;

  billedAmount: number;

  currency: string;

  additionalComments: string;

  paymentDate?: string;

  affectedArea?: string;

  billPaid?: string;

  taskId?: number;

  unitNumber?: string;

  constructor() {
    this.category = '';
    this.dueDate = '';
    this.unitId = 0;
    this.unitNumber = undefined;
    this.billedAmount = 0;
    this.currency = 'USD';
    this.additionalComments = '';
    this.paymentDate = '';
    this.billPaid = 'no';
    this.taskId = undefined;
    this.affectedArea = BillArea.CommonArea;
  }
}

export { BillCreate };
