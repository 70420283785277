import { useTranslation } from 'react-i18next';
import { Input, InputStyle } from '@mapix/common/src/common/input';
import { PropertyController } from 'networking/controllers/property-controller';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Property } from 'models/property';
import { logger } from 'helpers/logger';
import { classnames } from '@mapix/common/src/helpers/utils';
import { CreateFooter } from 'common/creation';
import { ButtonType } from '@mapix/common/src/common/button';
import styles from '../add-bill.module.scss';

type StateType = {
  code: string,
  query: string,
  show: boolean
};

const initialState = {
  code: 'INITIAL',
  query: '',
  show: false,
};

type AddBillStep1Props = {
  propertySelectedFn: (item: Property) => void
};

const translPrefix = 'addBill';
const AddBillStep1 = ({ propertySelectedFn }: AddBillStep1Props) => {
  const [state, setState] = useState<StateType>(initialState);
  const [properties, setProperties] = useState<Property[]>([]);
  const [selectedProperty, setSelectedProperty] = useState<Property | null>(null);

  const { t } = useTranslation();

  const getActiveProperties = async () => {
    try {
      const response = await PropertyController.getActiveProperties(state.query);
      setProperties(response);
    } catch (err: any) {
      logger.error(err);
      setState((prevState) => ({ ...prevState, code: 'ERROR_FETCHING' }));
    }
  };

  useEffect(() => {
    if (state.query.length > 2 || state.query === '') {
      getActiveProperties();
    }
  }, [state.query]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({ ...prevState, query: e.target.value }));
  };

  const showContent = () => {
    setState((prevState) => ({ ...prevState, show: true }));
  };

  const hideContent = () => {
    setState((prevState) => ({ ...prevState, show: false }));
  };

  const onClick = (item: Property) => {
    setSelectedProperty(item);
    setState((prevState) => ({ ...prevState, query: item.fullAddress }));
  };

  return (
    <>
      <div className={classnames(styles.queryLabel, 'text__heading6__textNeutral50')}>
        {t(`${translPrefix}.matchProperty`)}
      </div>

      <div className={styles.inputContainer}>
        <Input
          inputStyle={InputStyle.REGULAR}
          withSearchIcon
          searchClass={styles.searchColor}
          id="propertyFinder"
          containerClass={styles.largeInput}
          onChange={onChange}
          placeholder={t(`${translPrefix}.placeholder`)}
          error={state.code === 'NOT_FOUND'}
          helperText={state.code === 'NOT_FOUND' ? t('createTask.error') : ''}
          onFocus={() => showContent()}
          onBlur={() => hideContent()}
          value={state.query}
          t={t}
        />
        {state.show && (
          <div className={styles.dropdownContent}>
            {properties.map((property) => (
              <button
                key={property.id}
                onMouseDown={() => onClick(property)}
                type="button"
                className={classnames(styles.item, 'text__body__medium__textNeutral40')}
              >
                {property.fullAddress}
              </button>
            ))}
          </div>
        )}
      </div>

      <CreateFooter
        disableNextCondition={!selectedProperty}
        nextButtonType={ButtonType.Button}
        nextName={t('createGeneric.next')}
        nextFn={selectedProperty ? () => propertySelectedFn(selectedProperty) : () => null}
      />
    </>
  );
};

export { AddBillStep1 };
