import { checkHTMLErrors, HTMLValidationError } from '@mapix/common/src/helpers/utils';
import React, { Dispatch, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateFooter } from 'common/creation';
import { ButtonType } from '@mapix/common/src/common/button';
import { CreateEditTaskContainerStep2 } from 'common/create-edit-task-container-step-2';
import { Action } from './crete-task-reducer';

type CreateTaskStep2Props = {
  dispatch: Dispatch<Action>,
  propertyAddress: string,
  buildingType: boolean,
  taskCategory: string,
  priority: string,
  issueDate: string,
  description: string,
  area: string,
  formErrors: HTMLValidationError,
  taskPhoto: FileType[],
  propertyId: number,
  unitNumber: string,
  fromPropertyDashboard?: boolean,
  isValidUnitNumber: boolean,
};

const CreateTaskStep2 = ({
  dispatch, propertyAddress, buildingType, taskCategory, unitNumber,
  priority, issueDate, area, description, formErrors, taskPhoto,
  propertyId, fromPropertyDashboard = false, isValidUnitNumber,
}: CreateTaskStep2Props) => {
  const { t } = useTranslation();

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as HTMLFormElement;
    if (!target.checkValidity() || !isValidUnitNumber) {
      dispatch({
        type: 'FORM_ERROR',
        errors: {
          ...checkHTMLErrors(target),
          unitNumbersInput: isValidUnitNumber ? '' : t('createTask.concernedArea.selectValidUnit'),
        },
      });
    } else {
      dispatch({ type: 'GO_NEXT' });
    }
  };

  return (
    <form onSubmit={onSubmit} noValidate>
      <CreateEditTaskContainerStep2
        fn={dispatch}
        propertyAddress={propertyAddress}
        buildingType={buildingType}
        taskCategory={taskCategory}
        priority={priority}
        issueDate={issueDate}
        description={description}
        area={area}
        formErrors={formErrors}
        taskPhoto={taskPhoto}
        propertyId={propertyId}
        unitNumber={unitNumber}
      />
      <CreateFooter
        nextButtonType={ButtonType.Submit}
        showBack={!fromPropertyDashboard}
        nextName={t('createGeneric.next')}
        backFn={() => dispatch({ type: 'GO_BACK' })}
      />
    </form>
  );
};

export { CreateTaskStep2 };
