import { City, RawCity } from '../../types';

class CitySerializer {
  static deSerializeCity: (data: RawCity) => City = (data) => ({
    id: data.id,
    name: data.name,
    province: data.province,
    provinceId: data.province_id,
    country: data.country,
    lat: Number(data.lat),
    long: Number(data.long),
  });

  static deSerializeCities = (data: RawCity[]): City[] => (
    data.map(CitySerializer.deSerializeCity));
}

export { CitySerializer };
