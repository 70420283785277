import { Contractor } from 'models/contractor';
import { HTMLValidationError } from '@mapix/common/src/helpers/utils';
import { Property } from 'models/property';
import { Task } from 'models/task';

const translPrefix = 'createTask';

const createSteps = [`${translPrefix}.steps.property`, `${translPrefix}.steps.taskInfo`, `${translPrefix}.steps.contractor`];

type CreateTaskState = {
  query: string,
  propertyAddress: string,
  show: boolean,
  data: Property[],
  step: number,
  steps: string[],
  buildingType: boolean,
  taskCategory: string,
  issueDate: string,
  description: string,
  priority: string,
  propertyId: number,
  taskPhoto: FileType[],
  area: string,
  finish: boolean,
  specificUnit: number | null,
  unitNumber: string,
  contractors: Contractor[],
  infoContractor: string,
  notification: string,
  formErrors: HTMLValidationError,
  addContractorModal: boolean,
  contractorId: number | null,
  modalAssignLater: boolean,
  modalAssignTask: boolean,
  errorModal: boolean,
  sms: boolean,
  email: boolean,
  optionNotifyNotSelected: boolean,
};

type Action =
  | { type: 'SEARCH_QUERY', query: string }
  | { type: 'STEPS', steps: string [] }
  | { type: 'FINISH' }
  | { type: 'FINISH_MODAL_ASSIGN_LATER' }
  | { type: 'MODAL_ASSIGN_TASK', modalAssignTask: boolean }
  | { type: 'OPTION_NOTIFY_NOT_SELECTED', optionNotifyNotSelected: boolean }
  | { type: 'FINISH_MODAL_ASSIGN_TASK' }
  | { type: 'FINISH_ERROR' }
  | { type: 'CLOSE_ERROR' }
  | { type: 'PROPERTY_CHANGED', propertyAddress: string, buildingType: boolean, propertyId: number }
  | { type: 'CHANGE_FIELD', value: string | number, field: string }
  | { type: 'MODAL_VISIBILITY', show: boolean }
  | { type: 'ADD_CONTRACTOR_MODAL', addContractorModal: boolean }
  | { type: 'PROPERTIES_FETCHED', data: Property[] }
  | { type: 'TASK_FETCHED', taskData: Task }
  | { type: 'TASK_PHOTO', taskPhoto: FileType[] }
  | { type: 'GO_BACK' }
  | { type: 'CHANGE_CONTACT_METHOD', field: string, value: boolean }
  | { type: 'FORM_ERROR', errors: HTMLValidationError }
  | { type: 'UNIT_SELECTED', specificUnit: number | null, unitNumber: string }
  | { type: 'GO_NEXT' };

const initialState = (propertyId?: number) => ({
  query: '',
  propertyAddress: '',
  show: false,
  data: [],
  step: 0,
  steps: createSteps,
  buildingType: true,
  taskCategory: '',
  issueDate: '',
  description: '',
  priority: '',
  area: '',
  finish: false,
  propertyId: propertyId || -1,
  taskPhoto: [],
  specificUnit: null,
  unitNumber: '',
  contractors: [],
  infoContractor: '',
  notification: '',
  formErrors: {},
  addContractorModal: false,
  contractorId: null,
  modalAssignLater: false,
  modalAssignTask: false,
  errorModal: false,
  sms: false,
  email: true,
  optionNotifyNotSelected: false,
});

function CreateTaskReducer(state: CreateTaskState, action: Action): CreateTaskState {
  switch (action.type) {
    case 'PROPERTIES_FETCHED':
      return {
        ...state,
        data: action.data,
      };
    case 'CHANGE_CONTACT_METHOD':
      return {
        ...state,
        [action.field]: action.value,
      };
    case 'TASK_FETCHED':
      return {
        ...state,
        taskCategory: action.taskData.category,
        propertyId: action.taskData.property.id,
        issueDate: action.taskData.startDate,
        description: action.taskData.description,
        priority: action.taskData.priority,
        specificUnit: action.taskData.unit?.id || null,
        contractorId: action.taskData.contractor?.id || null,
        email: action.taskData.email,
        sms: action.taskData.sms,
        infoContractor: action.taskData.additionalInformation,
        buildingType: action.taskData.property.type === 'Building',
        propertyAddress: `${action.taskData.property.address.civicNumber} ${action.taskData.property.address.streetName} ${action.taskData.property.address.unitNumber ? action.taskData.property.address.unitNumber : ''}, ${action.taskData.property.address.city}, ${action.taskData.property.address.country}`,
      };
    case 'PROPERTY_CHANGED':
      return {
        ...state,
        propertyAddress: action.propertyAddress,
        buildingType: action.buildingType,
        propertyId: action.propertyId,
      };
    case 'FORM_ERROR':
      return {
        ...state,
        formErrors: action.errors,
      };
    case 'SEARCH_QUERY':
      return {
        ...state,
        query: action.query,
      };
    case 'MODAL_VISIBILITY':
      return {
        ...state,
        show: action.show,
      };
    case 'STEPS':
      return {
        ...state,
        steps: action.steps,
      };
    case 'ADD_CONTRACTOR_MODAL':
      return {
        ...state,
        addContractorModal: action.addContractorModal,
      };
    case 'GO_BACK':
      return {
        ...state,
        step: state.step - 1,
      };
    case 'GO_NEXT':
      return {
        ...state,
        step: state.step + 1,
      };
    case 'CHANGE_FIELD':
      return {
        ...state,
        [action.field]: action.value,
      };
    case 'UNIT_SELECTED':
      return {
        ...state,
        specificUnit: action.specificUnit,
        unitNumber: action.unitNumber,
        formErrors: { ...state.formErrors, unitNumbersInput: '' },
      };
    case 'FINISH':
      return {
        ...state,
        finish: true,
      };
    case 'MODAL_ASSIGN_TASK':
      return {
        ...state,
        modalAssignTask: action.modalAssignTask,
      };
    case 'FINISH_MODAL_ASSIGN_TASK':
      return {
        ...state,
        modalAssignTask: true,
        finish: state.contractorId ? true : state.finish,
      };
    case 'FINISH_MODAL_ASSIGN_LATER':
      return {
        ...state,
        modalAssignLater: true,
        finish: true,
      };
    case 'FINISH_ERROR':
      return {
        ...state,
        errorModal: true,
      };
    case 'CLOSE_ERROR':
      return {
        ...state,
        errorModal: false,
      };
    case 'TASK_PHOTO':
      return {
        ...state,
        taskPhoto: action.taskPhoto,
      };
    case 'OPTION_NOTIFY_NOT_SELECTED':
      return {
        ...state,
        optionNotifyNotSelected: action.optionNotifyNotSelected,
      };
    default:
      return {
        ...state,
      };
  }
}

export { CreateTaskReducer, initialState };
export type { Action };
